<div class="container-fluid">
  <a class="position-fixed fixed-bottom btn btn-badge" title="Create New Record" routerLink="/customer/new">+</a>

  <div class="row border">
    <search-bar class="w-100" [(searchString)]="searchString" (search)="doSearch($event)"></search-bar>

    <div *ngIf="!results"><loading></loading></div>

    <search-result *ngIf="results" [results]="searchResults" class="w-100" (newPage)="getPage($event)" (selected)="onSelect($event)">
      <thead header-template>
        <tr class="text-uppercase text-muted">
          <th (click)="sortBy('name')">
            Name
            <i [ngClass]="getSortIconFor('name')"></i>
          </th>
          <th (click)="sortBy('num')">
            Customer Number
            <i [ngClass]="getSortIconFor('num')"></i>
          </th>
          <th (click)="sortBy('address')">
            Billing Address
            <i [ngClass]="getSortIconFor('address')"></i>
          </th>
          <th (click)="sortBy('phone')">
            Primary Phone
            <i [ngClass]="getSortIconFor('phone')"></i>
          </th>
          <th (click)="sortBy('status')">
            Order Status
            <i [ngClass]="getSortIconFor('status')"></i>
          </th>
        </tr>
      </thead>
      <ng-template let-row>
        <td>{{row.businessName}}</td>
        <td class="text-muted">C-{{row.customerNum | number:'8.0' | noComma}}</td>
        <td>{{row.mailingAddress}}</td>
        <td>{{row.primaryPhoneNumber}}</td>
        <td>
          <span *ngIf="row.orderStatus.count>0" class="badge open-orders status" [ngClass]="getRequiredColor(row.orderStatus.earliest)">
            <b>{{row.orderStatus.count}}</b> Open Order<span *ngIf="row.orderStatus.count>1">s</span>
          </span>
        </td>
      </ng-template>
    </search-result>
  </div>

</div>
