import { AfterViewInit, ChangeDetectionStrategy, Component, HostBinding, HostListener, OnDestroy, OnInit, TemplateRef, ViewChild, Inject, ChangeDetectorRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { User } from '../../../common/resources/user';
import { NavigationService } from '../../../common/services/navigation.service';
import { UserService } from '../../../common/services/user.service';
import { UtilityService } from '../../../common/services/utility.service';
import { Customer, CustomerContact } from '../../../customer/resources/customer';
import { Order, OrderDocument, OrderProduct, OrderSegmentProductReviewStatus, OrderStatus } from '../../resources/order';
import { OrderService } from '../../services/order.service';
import { Product } from '../../resources/product';
import { MatTab, MatTabGroup } from '@angular/material/tabs';
import { OrderDetailService, fetchLatest } from './order-detail.service';
import { RFQDoneConfirmationDialog, RejectReasoningDialog, StatusConfirmationDialog } from '../order-detail/order-detail.component';
import { VirtualDocument } from '../../../common/resources/virtual-document';
import { ProductHierarchySortComponent } from '../../../planning/components/product-hierarchy-sort/product-hierarchy-sort.component';
import { distinctUntilChanged, map, take } from 'rxjs/operators';
import { NgForm } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { DOCUMENT } from '@angular/common';
import { BehaviorSubject, Observable } from 'rxjs';
import { EstimateProgressService } from '../../services/estimate-progress.service';
import { StationService } from '../../services/station.service';
import { humanFileSize } from '../../../../../util/humanFileSize';

@Component({
  selector: 'order-detail-new',
  templateUrl: './order-detail-new.component.html',
  styleUrls: ['./order-detail-new.component.less'],
  providers: [OrderDetailService, EstimateProgressService],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OrderDetailNewComponent implements OnInit, AfterViewInit, OnDestroy {

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private orderService: OrderService,
    private dialog: MatDialog,
    private router: Router,
    private route: ActivatedRoute,
    private navService: NavigationService,
    private utilitySvc: UtilityService,
    private userSvc: UserService,
    public service: OrderDetailService,
    public progressService: EstimateProgressService,
    private stationService: StationService,
    private cdr: ChangeDetectorRef
  ) {
    this.id = this.route.snapshot.paramMap.get("id");
  }

  public get emptyGuid(): string {
    return UtilityService.emptyGuid;
  }

  public id: string;
  public get selectedTab() {
    return this.service.mainTab;
  }
  public set selectedTab(val: number) {
    this.service.mainTab = val;
  } 
  public get productSelectedTab() {
    return this.service.productTab;
  }
  public set productSelectedTab(val: number) {
    this.service.productTab = val;
  } 

  public set loading(item: boolean) { this.service.loading$.next(item) }

  public get record() { return this.service.order }
  public set record(item: Order) { this.service.order = item }

  public get editing() { return this.service.editing }
  public set editing(value: boolean) { this.service.editing = value }

  private async getDetail() {
    this.record = null;
    if (this.id == "new" || this.id == UtilityService.emptyGuid) {
      this.editing = true;
      const u = await this.userSvc.userReplaySubject.pipe(take(1)).toPromise();
      if (!u || !u.userId) return;

      this.record = <Order>{
        orderSegmentId: UtilityService.emptyGuid,
        status: 0,
        discriminator: "RFQ",
        assignedTo: this.userSvc.userData.userId, //If you're creating it, you don't need to assign it to yourself
        assignedUser: this.userSvc.userData,
        documents: [],
        products: [],
        createdDate: new Date(),
        creatorId: this.userSvc.userData.userId,
        creator: this.userSvc.userData,
      };
      this.service.startingQuotes.next({});
      this.cdr.detectChanges();
    } else {
      this.loading = true;
      this.stationService.getStationList();
      const detail = await this.orderService.getDetail(this.id).toPromise();
      const tree = await this.orderService.getProductTree(detail.orderSegmentId).toPromise();
      this.record = detail;
      // Reorder based on sort property
      const ospSorted = detail.products.sort((a, b) => a.sort - b.sort);
      tree.sort((productA, productB) => {
        const indexA = ospSorted.findIndex(os => os.productProductId === productA.productId);
        const indexB = ospSorted.findIndex(os => os.productProductId === productB.productId);
        return indexA - indexB;
      });
      this.service.productsReset.next(JSON.parse(JSON.stringify(tree)));
      // this.service.productsReset.next(tree);
      this.service.onProductsChange(tree);
      this.service.initStartingQuotes();
      this.loading = false;
      this.route.fragment.pipe(take(1)).subscribe(fragment => {
        if (fragment) this.service.navigateToSubItem(fragment);
      })
    }
    this.setPageTitle();
    this.progressService.syncEstimatingTaskList();
  }

  private setPageTitle(): void {
    this.navService.setPageTitle(this.record.discriminator + " Detail");
    this.navService.pushBreadcrumb(
      this.record.orderNumber || "New " + this.record.discriminator
    );
  }

  public isEditable(status: number): boolean {
    if (this.record.discriminator === 'RFQ' && this.record.status === OrderStatus.AWAITING_REVIEW && this.service.canApproveRFQ()) return true;
    return (
      !(this.record.discriminator === 'RFQ' && this.record.status === OrderStatus.AWAITING_REVIEW) &&
      (this.record == null || this.record.discriminator != "SalesOrder") &&
      OrderStatus.isEditable(status)
    );
  }

  async ngOnInit() {
    this.getDetail();
  }

  public get products() {
    return this.record.products?.map(p => p.product);
  }

  public get selectedProductId() { return this.service.selectedProductId }
  public set selectedProductId(value: string) { this.service.selectedProductId = value }

  public onCreated(created: Product) {
    this.selectedProductId = created.productId;
    this.service.addNewId(created.productId);
    this.productSelectedTab = 0;
  }

  private tabGroup: MatTabGroup;
  @ViewChild('tabGroup', { static: false }) set tabGroupSetter(content: MatTabGroup) {
    if (content) {
      this.tabGroup = content;
      this.service.mainTabBodyElement = (this.tabGroup?._elementRef?.nativeElement as HTMLElement)?.querySelector('.mat-tab-body-content');
    }
  }
  public onTabGroupClick(event: MouseEvent) {
    const tgt = event.target as HTMLElement;
    const el: HTMLElement = this.tabGroup._elementRef.nativeElement;
    const headerEl = el.querySelector(':scope > .mat-tab-header')
    if (headerEl.contains(tgt) &&
    (tgt.classList.contains('mat-tab-label') ||
    tgt.classList.contains('mat-tab-label-content'))
    ) {
      this.selectedProductId = null;
    }
  }

  public get selectedProduct() {
    return this.service.selectedProduct;
  }

  public async checkValidation() {
    const error = await this.service.validateAll();
    if (error) {
      this.selectedTab = 0;
      if (error.screen !== 'productList') {
        // First set the product to the error product
        this.selectedProductId = error.productId ?? null;
        if (error.productId) this.service.hierarchySort?.expandTo(error.productId);
        // Then set the appropriate item selections
        if (error.screen === "purchasedItem") this.service.selectedPurchasedItemId = error.itemId;
        else if (error.screen === "workflowStep") this.service.selectedWorkflowStepId = error.itemId;
        // Then set the screen
        if (error.screen === 'form') this.productSelectedTab = 0;
        else if (error.screen === 'material') this.productSelectedTab = 2;
        else if (error.screen === 'workflowStep') this.productSelectedTab = 3;
        else if (error.screen === 'purchasedItem') this.productSelectedTab = 4;
      }     
      this.utilitySvc.showAlert('Error saving order', error.error).then(() => {
        if (error.formField) {
          setTimeout(() => {
            const i = this.document.querySelector(`[name="${error.formField}"]`) as HTMLInputElement;
            if (i) i.focus();
          });
        }
      });
      return false;
    } else return true;
  }

  public async save(refresh = true) {
    const validated = await this.checkValidation();
    if (!validated) return;
    let toList = false;
    if (
      this.record.discriminator === "RFQ" &&
      this.record.status !== OrderStatus.AWAITING_REVIEW
    ) {
      const dialogRef = this.utilitySvc.dialogService.open(
        RFQDoneConfirmationDialog,
        { disableClose: true }
      );
      const result = await dialogRef.afterClosed().toPromise();
      this.record.statusNote = result.note;
      if (!result.ready) {
        this.record.status = OrderStatus.IN_PROCESS;
      }
      else {
        this.record.status = OrderStatus.AWAITING_REVIEW;
        // we don't want to kick them back since they want to be able to see the RFQ number
        // toList = true;
      }
    }
    let keepEditing = false;
    if (
      this.record.discriminator === "Estimate" ||
      (this.record.discriminator === "RFQ" && this.record.status === 1)
    ) {
      keepEditing = true;
    }
    this.loading = true;
    this.editing = false;
    try {
      const id = await this.doSave();
      if (!id) throw new Error('failed to save');
      this.service.resetMetadata();
      if (toList) {
        this.router.navigate(["/rfqs/"]);
      } if (this.id === 'new') {
        this.id = id;
        this.router.navigate(["/order/newUi", id]);
      } else if (keepEditing) {
        await this.getDetail();
        this.editing = true;
        this.service.setOldData();
        setTimeout(() => this.service.dirty = false, 500);
      }
      if (!keepEditing && refresh && !toList) this.getDetail();
    } catch (e) {
      this.editing = true;
      this.loading = false;
    }
  }

  public canApproveEstimate() {
    return this.userSvc.canAccess('EstimateManager');
  }

  public async sendEstimateToApproval() {
    const unresolvedReviews = this.record.products.filter(p => p.reviewStatus === OrderSegmentProductReviewStatus.Incomplete);
    const dialogRef = this.utilitySvc.dialogService.open(
      StatusConfirmationDialog,
      { disableClose: true, data: { unresolvedReviews, incomplete: this.progressService.getAllIncompleteSimpleTasksByProduct() }  }
    );
    const result = await dialogRef.afterClosed().toPromise();
    this.record.statusNote = result.note;
    if (!result.ready) this.record.status = OrderStatus.IN_PROCESS;
    else this.record.status = OrderStatus.AWAITING_REVIEW;
    try {
      const r = await this.doSave();
      if (!r) throw new Error('failed to save');
      this.getDetail();
    } catch (e) {
      this.editing = true;
      this.loading = false;
    }
  }

  public convertDocuments(docs: OrderDocument[]): VirtualDocument[] {
    return docs.map(d => d.document);
  }

  public updateDocuments(docs: VirtualDocument[]) {
    this.record.documents = docs.map(d => ({
      documentDocumentId: d.documentId,
      orderSegmentOrderSegmentId: this.record.orderSegmentId,
      document: d
    }));
  }

  public async goToOldUI() {
    if (this.editing) {
      const r = await this.utilitySvc.showConfirmationPromise('Are you sure?', 'Any unsaved changes will be lost.');
      if (!r) return;
    }
    this.route.data.pipe(take(1)).subscribe(data => {
      const { discriminator } = data;
      const localStorageKey = `order-detail-${discriminator}-new-ui`;
      localStorage.setItem(localStorageKey, JSON.stringify(false));
      this.router.navigate(['/', discriminator, 'oldUi', this.id]);
    })
  }

  @HostBinding('attr.tabindex')
  public tabindex = 0;

  @HostListener('document:keydown.control.s', ['$event'])
  public onCtrlS(event: KeyboardEvent) {
    event.preventDefault();
    if (this.editing) this.save();
  }

  public async viewPdf() {
    this.service.startQuotePreview();
  }
  public focusPdf() {
    if (this.service.quotePreviewWindow)
      this.service.quotePreviewWindow.focus();
  }

  ngOnDestroy(): void {
    this.service.closeQuotePreviewWindow();
  }

  public rejectOrder(): void {
    this.utilitySvc.showConfirmation(
      "Reject This Order?",
      "<p>Are you sure you want to reject this order?</p><p class='text-muted'>Rejecting an order sends it back for additional information, clairification, etc.</p>",
      (r) => {
        if (r) {
          const dialogRef = this.utilitySvc.dialogService.open(
            RejectReasoningDialog,
            { disableClose: true }
          );
          dialogRef.afterClosed().subscribe((result) => {
            this.loading = true;
            this.orderService
              .rejectOrder(this.record, result.note)
              .subscribe((_) => {
                this.getDetail();
                this.loading = false;
              });
          });
        }
      }
    );
  }

  public async approveEstimate() {
    const r = await this.utilitySvc.showConfirmationPromise(
      "Approve This Estimate?",
      "<p>Please confirm that you want to approve this estimate in its current state.</p>"
    );
    if (!r) return; 
    this.loading = true;
    const lineItems = await this.service.generateLineItems();
    this.orderService.approveEstimateClientside(this.record, lineItems).subscribe((_) => {
      this.getDetail();
      this.loading = false;
    });
  }

  public get canBeClosed(): boolean {
    switch (this.record.status) {
      case OrderStatus.CLOSED:
      case OrderStatus.HISTORY:
      case OrderStatus.FULFILLED:
      case OrderStatus.APPROVED:
        return false;
      default: return true;
    }
  }

  @ViewChild("closeOrderDialogTemplate", { static: true }) closeOrderDialogTemplate: TemplateRef<any>;
  public async closeOrder() {
    const dialogRef = this.utilitySvc.dialogService.open(this.closeOrderDialogTemplate, {
      data: {
        reason: ""
      }
    });
    const r = await dialogRef.afterClosed().toPromise();
    if (!r) return;
    this.loading = true;
    this.orderService
      .closeOrder(this.record, r.reason)
      .subscribe((_) => {
        // this.getDetail();
        this.loading = false;
        this.route.data.pipe(take(1)).subscribe(data => {
          const { discriminator } = data;
          this.router.navigate(['/', discriminator]);
        })
      });
  }

  public get discriminator() {
    switch (this.record.discriminator) {
      case 'RMAEstimate':
        return 'Estimate';
      case 'SalesOrder':
        return 'Order'
      default:
        return this.record.discriminator;
    }
  }

  @ViewChild('form') form: NgForm;
  ngAfterViewInit(): void {
    this.form.valueChanges.pipe(
      distinctUntilChanged(),
      map(() => this.record)
    ).subscribe(this.service.orderEdited)
  }

  public notifyEdited() {
    this.service.orderEdited.next(this.record);
  }

  @ViewChild('backupTemplate') backupTemplate: TemplateRef<any>;
  private async doSave() {
    const quotesMap = await fetchLatest(this.service.allQuotes);
    this.record.products = await this.service.getProductsLatestValues();
    const payload = {
      orderSegment: this.record,
      newItemIds: this.service.newIds,
      quotesMap,
      newMaterialsMap: this.service.productsWithInputMaterial,
      newOPSpecs: this.service.newOPSpecs,
      movedProductIds: Array.from(this.service.movedProductIds),
    }
    const backupJson = JSON.stringify(payload);
    const result = await this.orderService.fullSave(payload).toPromise();
    if (!result) {
      // Something went wrong if result is null
      const filename = `BACKUP-${this.record.discriminator}-${this.userSvc.userData.fullName}-${new Date().toLocaleString()}`;
      const textEncoder = new TextEncoder();
      const fileSizeNumber = textEncoder.encode(backupJson).length ?? 0;
      const fileSize = humanFileSize(fileSizeNumber);

      let encodedUri = `data:application/json;charset=utf-8,${encodeURI(backupJson)}`;

      this.dialog.open(this.backupTemplate, {
        disableClose: true,
        maxWidth: "50vw",
        data: {
          filename, fileSize, encodedUri
        }
      })
    }
    return result;
  }

  public async approveRFQ() {
    const r = await this.utilitySvc.showConfirmationPromise('Approve RFQ?', 'It will be sent to Estimating.');
    if (!r) return;
    else {
      this.loading = true;
      const r = await this.doSave();
      if (!r) {
        this.loading = false;
        return;
      }
      await this.orderService.quoteToEstimate(this.record).toPromise();
      this.router.navigate(["/rfqApproval"]);
    }
  }
  @ViewChild('rejectRfqTemplate') rejectRfqTemplate: TemplateRef<any>;
  public async rejectRFQ() {
    const dialogRef = this.utilitySvc.dialogService.open(
      this.rejectRfqTemplate,
      { disableClose: true, data: { note: '' } }
    );
    const result = await dialogRef.afterClosed().toPromise();
    if (!result) return;
    this.record.statusNote = result;
    this.record.status = OrderStatus.REJECTED;
    this.loading = true;
    const r = await this.doSave();
    if (!r) {
      this.loading = false;
      return;
    }
    this.router.navigate(["/rfqApproval"]);
  }

  public allProductsReviewed() {
    return this.record?.products?.every(p => p.reviewStatus > 0);
  }

  public async cloneRFQ() {
    const r = await this.utilitySvc.showConfirmationPromise(`Clone ${this.record.orderNumber}?`, 'A new RFQ will be created with the same part numbers.');
    if (!r) return;
    this.loading = true;
    const id = await this.orderService.cloneRFQ(this.record.orderSegmentId).toPromise();
    this.loading = false;
    this.router.navigate(["/rfqs/", id]);
  }


}
