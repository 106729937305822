import { EmployeeShiftAssignment } from './employee-shift-assignment';


export class ShiftAssignmentRecurrence {

  shiftAssignmentRecurrenceId: number;
  repeat: 'daily' | 'weekly' | 'monthly' | 'yearly';
  repeatInterval: number;

  weekDays?: string;
  recurrenceEndOption?: string;
  countMax?: number;
  startDate?: Date;
  untilDate?: Date;

  employeeShiftAssignments: EmployeeShiftAssignment[];
}


export class RecurrenceFrequency {
  value: string;
  text: string;
}

