<search-list-new
  matSort
  [defaultSort]="{
    active: 'orderNumber',
    direction: 'desc'
  }"
  [showManagerToggle]="showManagerToggle"
  [displayedColumns]="displayedColumns"
  [resultGetter]="search.bind(this)"
  (itemSelected)="onSelect($event)"
  [fieldSearchFields]="[
    { field: 'customer', code: 'customer' },
    { field: 'orderNumber', code: '#' },
    { field: 'customerRfqnumber', code: 'rfq' },
    { field: 'partNumber', code: 'pn' },
    { field: 'partNumber', code: 'part' },
    { field: 'assignedUser', code: 'user' },
    { field: 'assignedUser', code: 'assigned' }
  ]"
  [fieldSearchNames]="{
    customer: 'Customer',
    orderNumber: orderNumberTitle + ' #',
    customerRfqnumber: 'Customer RFQ#',
    partNumber: 'Part Number',
    assignedUser: 'Assigned To'
  }"
  [localStorageId]="localStorageId"
  [isManager]="isManager"
  [filterParams]="filterParams"
  [defaultFilters]="defaultFilters"
  [groupableFields]="[null, { name: 'customer', title: 'Customer' }, { name: 'status', title: 'Status' }]"
  [alwaysManager]="alwaysManager"
>
  <ng-content select="[preSearchContent]" preSearchContent></ng-content>
  <button floatingButton *ngIf="showAdd" mat-fab class="fab-primary" color="primary" routerLink="/rfqs/new">
    <mat-icon>add</mat-icon>
  </button>
  <ng-container matColumnDef="customer">
    <mat-header-cell *matHeaderCellDef mat-sort-header="customer" style="flex-basis: 8%">Customer</mat-header-cell>
    <mat-cell *matCellDef="let row" style="flex-basis: 8%">
      <span class="font-weight-medium mat-light-text">
        {{row.businessName}}
      </span>
    </mat-cell>
  </ng-container>
  <ng-container matColumnDef="quoteNumber">
    <mat-header-cell *matHeaderCellDef mat-sort-header="quoteNumber" class="number-cell">Quote #</mat-header-cell>
    <mat-cell *matCellDef="let row" class="number-cell">
      <span class="font-weight-bold">
        {{row.quoteNumber}}
      </span>
    </mat-cell>
  </ng-container>
  <ng-container matColumnDef="orderNumber">
    <mat-header-cell *matHeaderCellDef mat-sort-header="orderNumber" class="number-cell">{{ orderNumberTitle }} #</mat-header-cell>
    <mat-cell *matCellDef="let row" class="number-cell">
      <span class="font-weight-bold">
        {{row.orderNumber}}
      </span>
    </mat-cell>
  </ng-container>
  <ng-container matColumnDef="estimateNumber">
    <mat-header-cell *matHeaderCellDef mat-sort-header="estimateNumber" class="number-cell">Estimate #</mat-header-cell>
    <mat-cell *matCellDef="let row" class="number-cell">
      <span class="font-weight-bold" *ngIf="row.estimateNumber; else none">{{row.estimateNumber}}</span>
    </mat-cell>
  </ng-container>
  <ng-container matColumnDef="customerRfqnumber">
    <mat-header-cell *matHeaderCellDef mat-sort-header="customerRfqnumber" style="flex-basis: 8%">Customer RFQ#</mat-header-cell>
    <mat-cell *matCellDef="let row" style="flex-basis: 8%">
      <span *ngIf="row.customerRfqnumber; else none">{{row.customerRfqnumber}}</span>
    </mat-cell>
  </ng-container>
  <ng-container matColumnDef="partHistory">
    <mat-header-cell *matHeaderCellDef mat-sort-header="partHistory" style="flex-basis: 8%">
      Part Priority
      <mat-icon class="ml-1" [matTooltip]="'Only the highest priority among parts in the ' + (orderNumberTitle === 'RFQ' ? orderNumberTitle : (orderNumberTitle | lowercase)) + ' is shown.'" matTooltipPosition="after">info</mat-icon>
    </mat-header-cell>
    <mat-cell *matCellDef="let row" style="flex-basis: 8%">
      <mat-chip class="button-text" [ngClass]="getQuoteHistoryChipClass(row.partHistory)">
        {{ getQuoteHistoryText(row.partHistory) }}
      </mat-chip>
    </mat-cell>
  </ng-container>
  <ng-container matColumnDef="hasIncomplete">
    <mat-header-cell *matHeaderCellDef style="flex-basis: 8%">
    &nbsp;
    </mat-header-cell>
    <mat-cell *matCellDef="let row" style="flex-basis: 8%">
      <mat-chip class="button-text bg-warning text-dark" *ngIf="row.reviewStatus === 1">
        Incomplete
      </mat-chip>
    </mat-cell>
  </ng-container>
  <ng-container matColumnDef="hasOnHold">
    <mat-header-cell *matHeaderCellDef style="flex-basis: 8%">
    &nbsp;
    </mat-header-cell>
    <mat-cell *matCellDef="let row" style="flex-basis: 8%">
      <mat-chip class="button-text bg-primary text-white" *ngIf="row.reviewStatus === 2">
        On Hold
      </mat-chip>
    </mat-cell>
  </ng-container>
  <ng-container matColumnDef="estimateProgress">
    <mat-header-cell *matHeaderCellDef style="flex-grow: 0; flex-basis: 7.5%;">Progress</mat-header-cell>
    <mat-cell *matCellDef="let row" style="flex-grow: 0; flex-basis: 7.5%; padding-right: 1em">
      <div *ngLet="getProgress(row) as progress" class="estimate-progress-bar">
        <ng-container *ngFor="let status of possibleProgressStatuses">
          <div *ngIf="progress[status] > 0"
            [ngClass]="getProgressColorClass(status)"
            [ngStyle]="{
              width: ((progress[status] / progress.total) * 100) + '%'
            }"
            matTooltipPosition="above"
            [matTooltip]="((progress[status] / progress.total) * 100 | number: '1.0-1') + '% ' + getProgressStatusText(status)"
          >&nbsp;
          </div>
        </ng-container>
      </div>
    </mat-cell>
  </ng-container>
  <ng-container matColumnDef="assigned">
    <mat-header-cell *matHeaderCellDef mat-sort-header="assigned">Assigned To</mat-header-cell>
    <mat-cell *matCellDef="let row">
      <span *ngIf="row.assignedTo" class="d-flex align-items-center text-muted font-weight-bold">
        <mat-icon class="mr-1">person</mat-icon><span>{{ row.fullName }}</span>
      </span>
      <span *ngIf="!row.assignedTo" class="d-flex align-items-center text-danger font-weight-bold">
        <mat-icon class="mr-1">person_off</mat-icon><span>Unassigned</span>
      </span>
    </mat-cell>
  </ng-container>
  <ng-container matColumnDef="received">
    <mat-header-cell *matHeaderCellDef mat-sort-header="received" style="flex-grow: 0; flex-basis: 7.5%;">Received</mat-header-cell>
    <mat-cell *matCellDef="let row" style="flex-grow: 0; flex-basis: 7.5%;">
      <span *ngIf="row.receivedDate; else none">{{row.receivedDate | date }}</span>
    </mat-cell>
  </ng-container>
  <ng-container matColumnDef="required">
    <mat-header-cell *matHeaderCellDef mat-sort-header="required" style="flex-grow: 0; flex-basis: 7.5%;">Required</mat-header-cell>
    <mat-cell *matCellDef="let row" style="flex-grow: 0; flex-basis: 7.5%;">
      <span *ngIf="row.requiredDate; else none">{{row.requiredDate | date }}</span>
    </mat-cell>
  </ng-container>
  <ng-container matColumnDef="estimatedEstimateCompletionDate">
    <mat-header-cell *matHeaderCellDef mat-sort-header="estimatedEstimateCompletionDate" style="flex-grow: 0; flex-basis: 7.5%;">Est. Estimate Completion Date</mat-header-cell>
    <mat-cell *matCellDef="let row" style="flex-grow: 0; flex-basis: 7.5%;">
      <span [ngClass]="{'bg-warning text-dark': row.estimatedEstimateCompletionDate > row.requiredDate}" *ngIf="row.estimatedEstimateCompletionDate; else none">{{row.estimatedEstimateCompletionDate | date }}</span>
    </mat-cell>
  </ng-container>
  <ng-container matColumnDef="status">
    <mat-header-cell *matHeaderCellDef mat-sort-header="status" style="flex-basis: 6%; padding-right: 72px;">Status</mat-header-cell>
    <mat-cell *matCellDef="let row" style="flex-basis: 6%">
      <mat-chip class="status button-text" disableRipple [ngClass]="getStatusColorClass(row.status)">
        {{getStatusText(row.status)}}
      </mat-chip>
    </mat-cell>
  </ng-container>
  <ng-container matColumnDef="createdDate">
    <mat-header-cell *matHeaderCellDef style="flex-grow: 0; flex-basis: 6%;">Created</mat-header-cell>
    <mat-cell *matCellDef="let row" style="flex-basis: 5%">
      <span *ngIf="row.createdDate; else none">{{row.createdDate | date }}</span>
    </mat-cell>
  </ng-container>
  <ng-container matColumnDef="rfqApprovalDate">
    <mat-header-cell *matHeaderCellDef style="flex-grow: 0; flex-basis: 5%;">RFQ Approved</mat-header-cell>
    <mat-cell *matCellDef="let row" style="flex-basis: 5%">
      <span *ngIf="row.rfqApprovalDate; else none">{{row.rfqApprovalDate | date }}</span>
    </mat-cell>
  </ng-container>
  <ng-container matColumnDef="estimateApprovalDate">
    <mat-header-cell *matHeaderCellDef style="flex-grow: 0; flex-basis: 5%;">Estimate Approved</mat-header-cell>
    <mat-cell *matCellDef="let row" style="flex-basis: 5%">
      <span *ngIf="row.estimateApprovalDate; else none">{{row.estimateApprovalDate | date }}</span>
    </mat-cell>
  </ng-container>
  <ng-container matColumnDef="customerTotal">
    <mat-header-cell *matHeaderCellDef style="flex-grow: 0; flex-basis: 7.5%;">Customer Revenue</mat-header-cell>
    <mat-cell *matCellDef="let row" style="flex-basis: 7.5%">
      <span *ngIf="row.customerTotal; else none">{{row.customerTotal | number: '1.2-2' }}</span>
    </mat-cell>
  </ng-container>
  <ng-container matColumnDef="customerPercentTotal">
    <mat-header-cell *matHeaderCellDef style="flex-grow: 0; flex-basis: 4%;">%</mat-header-cell>
    <mat-cell *matCellDef="let row" style="flex-basis: 4%">
      <span *ngIf="row.customerPercentTotal; else none">{{row.customerPercentTotal | number:'1.2-2' }}%</span>
    </mat-cell>
  </ng-container>
  <ng-container matColumnDef="estimateLate">
    <mat-header-cell *matHeaderCellDef style="flex-grow: 0; flex-basis: 2%;">Late</mat-header-cell>
    <mat-cell *matCellDef="let row" style="flex-basis: 2%">
      <span *ngIf="row.requiredDate; else none">{{getDaysLate(row.requiredDate) | number: '1.0-0'}}</span>
    </mat-cell>
  </ng-container>
  <ng-container matColumnDef="daysToQuote">
    <mat-header-cell *matHeaderCellDef style="flex-grow: 0; flex-basis: 3%;">Days To Quote</mat-header-cell>
    <mat-cell *matCellDef="let row" style="flex-basis: 3%">
      <span *ngIf="row.requiredDate && row.receivedDate; else none">{{getDaysBetween(row.receivedDate, row.requiredDate) | number: '1.0-0'}}</span>
    </mat-cell>
  </ng-container>

  <ng-template #groupHeaderTemplate let-item>
    <mat-chip *ngIf="item.groupField === 'status'; else normalHeader" class="status button-text" disableRipple [ngClass]="getStatusColorClass(toNumber(item.groupName))">
      {{getStatusText(toNumber(item.groupName))}}
    </mat-chip>
    <ng-template #normalHeader>
      <span class="group-header">{{ item.groupName }}</span>
    </ng-template>
  </ng-template>
</search-list-new>

<ng-template #none>
  <span class="text-muted">—</span>
</ng-template>