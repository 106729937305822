import { Component, Input, OnInit, ViewEncapsulation, ViewChild, EventEmitter } from '@angular/core';
import { User } from '../../../common/resources/user';
import { UserService } from '../../../common/services/user.service';
import { DocumentService } from '../../../common/services/document.service';
import { UtilityService } from '../../../common/services/utility.service';
import { NavigationService } from '../../../common/services/navigation.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Channel } from '../../../notification/resources/notificationChannel'
import { NotificationConfiguration } from '../../../notification/resources/notificationConfiguration'
import { NotificationRecipientGroup } from '../../../notification/resources/notificationRecipientGroup'
import { UntypedFormControl, FormGroup, UntypedFormBuilder } from '@angular/forms';
import { NotificationPreferredChannelService } from '../../../../cots/notification/services/notification-preferred-channel.service'
import { notification_preferred_channel_Constants } from '../../../notification/notification_preferred_channel_Constants'
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Subscription, Observable } from 'rxjs';


@Component({
  selector: 'notification-preferred-channel',
  templateUrl: './notification-preferred-channel.component.html',
  styleUrls: ['./notification-preferred-channel.component.css'],
  encapsulation: ViewEncapsulation.None,
})

export class NotificationPreferredChannelComponent implements OnInit {

  @Input() editable: boolean = false;
  @Input() user: User;
   
  public channelsForm = new UntypedFormControl();
  public lChannels: Channel[];
   
  public typeNameForm = new UntypedFormControl();
  public lNotificationConfiguration: NotificationConfiguration[];
  public columnsToDisplay: string[] = ['TypeName', 'Action', 'NotificationPreferredChannels'];
  public dataSource: MatTableDataSource<NotificationConfiguration>;

  @ViewChild(MatPaginator) paginator: MatPaginator;

  public notificationTypeSubscribedToWasFound = true;

  @Input() eventsObservable: Observable<void>;
  private eventsSubscription: Subscription;


  constructor(private route: ActivatedRoute, private router: Router,
    private notificationPreferredChannelService: NotificationPreferredChannelService,
    private fb: UntypedFormBuilder, private _formBuilder: UntypedFormBuilder  ) {
  }

  ngOnInit() {
    this.eventsSubscription = this.eventsObservable.subscribe(() => {
      this.SaveChanges();
    });

    this.getData();   
  }

  ngOnDestroy() {
    this.eventsSubscription.unsubscribe();
  }

  private getData(): void {

    if (this.user && this.user.userId) {
      this.notificationPreferredChannelService.getNotificationConfigurationsAlreadySetUpAndRecipientIsSubscribedToWithPreferredChannels(this.user.userId)
        .subscribe(data => {
          this.lNotificationConfiguration = data;

          this.dataSource = new MatTableDataSource<NotificationConfiguration>(this.lNotificationConfiguration);

          setTimeout(() => this.dataSource.paginator = this.paginator, 2);

          if (this.lNotificationConfiguration != null && this.lNotificationConfiguration.length > 0) {
            console.log("this.lNotificationConfiguration has data !");
          }
          else {
            this.notificationTypeSubscribedToWasFound = false;
            console.log("this.lNotificationConfiguration has NO data !!!");
          }
        });

      this.notificationPreferredChannelService.getAllNotificationChannels()
        .subscribe(data => {
          this.lChannels = data;
        });
    }
    
  }
   
  compareFnChannels(o1: Channel, o2: string): boolean {
    let result = false;

    if (o1 && o2) {
      result = o1.notificationChannelId.toString() === o2;
    }
    return result;
  }
   
  onSelectionChangeChannels(notificationConfiguration, selectedChannels) {

    var arrPreferredChannelIds: string[] = [];
    selectedChannels.forEach(x => {
      if (x.notificationChannelId)
        arrPreferredChannelIds.push(x.notificationChannelId.toString());
    });

    notificationConfiguration.notificationRecipientGroup_PreferredChannelsIds = arrPreferredChannelIds;
    //console.log("onSelectionChangeChannels() - selectedChannels= " + JSON.stringify(selectedChannels));
  }

  public doFilter = (value: string) => {
    this.dataSource.filter = value.trim().toLocaleLowerCase();
  }


  public SaveChanges(): void {
    //console.log("SaveChanges() - this.lNotificationConfiguration= " + JSON.stringify(this.lNotificationConfiguration) );

    this.notificationPreferredChannelService.updateNotificationConfigurationPreferredChannels(this.user.userId, this.lNotificationConfiguration)
      .subscribe(data => {
        //this.router.navigate([this.router.url]);
      },
        err => {
          console.log("Error: JSON.stringify(err)");
        }
      );
  }



}
