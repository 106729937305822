import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { AutocompleteTypeComponent } from '../../../common/formly-autocomplete-type/formly-autocomplete-type.component';
import { UserService } from '../../../common/services/user.service';
import { map, shareReplay } from 'rxjs/operators';
import { User } from '../../../common/resources/user';
import { Observable } from 'rxjs';
import { MicroTicket } from '../../resources/microticket';

@Component({
  selector: 'new-microticket',
  templateUrl: './new-microticket.component.html',
  styleUrls: ['./new-microticket.component.less']
})
export class NewMicroticketComponent<TSubItem> implements OnInit {

  @Input() relatedTicketId: string;
  @Input() relatedTicketName: string;
  @Input() filterKey: string;

  @Input() subItemName: string = 'Subitem';
  @Input() subItems: Observable<TSubItem[]> = null;
  @Input() subItemDisplayWith: (i: TSubItem) => string = (x) => 'No String Function';
  @Input() subItemCompareWith: (i: TSubItem) => any = (x) => x;

  @Input() isNew = false;

  @Input() fixedButtons = false;

  constructor(private userSvc: UserService, ) { }

  form = new FormGroup({});
  model = { email: 'email@gmail.com' };
  fields: FormlyFieldConfig[]

  ngOnInit() {
    const userNames$ = this.userSvc.listUserNames().pipe(shareReplay(1));
    
    this.fields = [
    {
      fieldGroupClassName: 'row w-100',
      fieldGroup: [
        {
          className: 'col-8',
          key: 'assignedUserId',
          type: 'autocomplete',
          props: {
            required: true,
            label: 'Assigned User',
            filter: (term: string) => this.userSvc.search(term).pipe(map(x => x.results)),
            displayWith: (user: User) => user.fullName,
            compareWith: (user: User) => user.userId
          },
        },
        {
          className: 'col-4',
          key: 'dueDate',
          type: 'datepicker',
          props: {
            label: 'Due Date',
            required: false,
          },
        }
      ]
    },
    { fieldGroupClassName: 'row w-100',
      fieldGroup: [
        {
          className: 'col-12',
          key: 'carbonCopiesList',
          type: 'chiplist',
          props: {
            required: false,
            label: 'CC/Carbon Copy',
            filter: (term: string) => this.userSvc.search(term).pipe(map(x => x.results)),
            displayWith: (id: string) => userNames$.pipe(
              map((users) => users.find(u => u.userId === id)),
              map((user) => user?.fullName ?? 'Unknown User')
            ),
            compareWith: (user: User) => user.userId
          },
          defaultValue: []
        },
      ]
    },
    {
      fieldGroupClassName: 'row w-100',
      fieldGroup: [
        {
          className: 'col-12',
          key: 'text',
          type: 'textarea',
          props: {
            label: 'Ticket Info',
            required: true,
            rows: 4
          }
        }
      ]
    },
    ...[
      this.subItems ? {
        fieldGroupClassName: 'row w-100',
        fieldGroup: [
          {
            className: 'col-4 ml-auto',
            key: 'relatedSubItem',
            type: 'autocomplete',
            props: {
            label: 'Associated Part (optional)',
            filter: (term: string) => this.subItems.pipe(
              map(results => term.trim() ? results.filter(item => 
                this.subItemDisplayWith(item).toLowerCase().includes(term.trim().toLowerCase())
              ) : results.slice())
            ),
            displayWith: this.subItemDisplayWith,
            compareWith: this.subItemCompareWith
          },
          }
        ]
    } : undefined].filter(x => !!x)
  ];
  }

  @Output() cancel = new EventEmitter<void>();
  public doCancel() {
    this.cancel.emit();
  }

  @Output() save = new EventEmitter<any>();
  public doSave(data) {
    console.log(data);
    this.save.emit(data);
  }

  public initialize(data: MicroTicket) {
    this.form.reset(data);
  }
}
