<div>
  <div class="small text-muted text-center">Existing Quotes</div>
  <div class="container-fluid border main small p-1 mt-2 mb-2" style="max-height: 500px;
  overflow-y: scroll;">
    <div class="row" *ngIf="!data; else results">
      <div class="col text-center">
        <i class="fas fa-2x fa-circle-notch fa-spin text-primary"></i>
      </div>
    </div>
    <ng-template #results>
      <div class="row" *ngIf="data.length==0">
        <div class="col text-center text-muted font-italic">-- No Existing Quotes --</div>
      </div>
      <div class="row font-weight-bold" *ngIf="data.length>0" style="position: sticky;
        top: -4px;
        background-color: #eeeeee;
        z-index: 9;">
        <div class="col">&nbsp;</div>
        <div class="col">Vendor</div>
        <div class="col">Cost</div>
        <div class="col-2 px-0">Lead Time</div>
        <div class="col-2" *ngIf="editable && isPurchasingManager()">&nbsp;</div>
      </div>
      <div class="row m-0 pricing" *ngFor="let bid of data" [ngClass]="{
        'row-expired': quoteIsExpired(bid),
        'row-selected': bid.materialBidId==selectionId
      }">
        <div class="col-1 px-1">
          <input *ngIf="!quoteIsExpired(bid)" [disabled]="!editable || quoteIsExpired(bid)" [checked]="bid.materialBidId==selectionId" type="radio" name="existing-order-selection"
            [value]="bid.materialBidId" (change)="setSelection(bid)" />
          <button *ngIf="quoteIsExpired(bid) && editable" class="btn btn-sm btn-info mr-1" (click)="copy.emit(bid)"><i class="fas fa-copy"></i></button>
        </div>
        <div class="col name-row">
          {{bid.vendor.name}}
        </div>
        <div class="col text-right">
          {{((bid.perItemBid || (bid.totalBid/bid.qty)) | currency) || '--'}}
        </div>
        <div class="col-2 text-right">
          {{bid.leadTimeDays}}d
        </div>
        <div class="col text-right pr-0" *ngIf="editable && isPurchasingManager()">
          <button class="btn btn-sm btn-primary mr-1" (click)="edit.emit(bid)" [class.invisible]="bid.materialBidId==selectionId"><i class="fas fa-edit"></i></button>
          <button class="btn btn-sm btn-danger" (click)="delete.emit(bid)"><i class="fas fa-trash"></i></button>
        </div>
      </div>
      <div class="row" *ngIf="!showExpired && expired?.length > 0">
        <div class="col">
          <button mat-flat-button class="bg-primary text-white small w-100" (click)="showExpiredQuotes()">
            <mat-icon matPrefix>add</mat-icon>
            Show Expired Quotes
          </button>
        </div>
      </div>
    </ng-template>
  </div>
</div>
