<div>

  <div class="p-3">
    <customer-search [disabled]="false" [(selectedItem)]="customer" (selectedItemChange)="onCustomerChange()"></customer-search>
  </div>

  <div class="p-3 w-100 d-flex">
    <button mat-raised-button class="mat-button text-white mr-2" (click)="readClipboard()" [disabled]="!customer" [class.bg-primary]="customer">
      <mat-icon matPrefix>content_paste</mat-icon>
      Paste Part Numbers From Clipboard
    </button>
    <button mat-raised-button class="mat-button bg-info text-white" color="info" (click)="clearAll()" [disabled]="dataArray.length == 0">
      <mat-icon matPrefix>clear_all</mat-icon>
      Clear All
    </button>
    <button mat-raised-button class="mat-button bg-success text-white ml-auto" color="info" (click)="copyLeadTimes()" [disabled]="dataArray.length == 0">
      <mat-icon matPrefix>content_copy</mat-icon>
      Copy Lead Times
    </button>
  </div>


  <table mat-table [dataSource]="dataArray" #dataTable="matTable" class="w-100">
    <ng-container matColumnDef="statusIcon">
      <th mat-header-cell *matHeaderCellDef style="width: 64px;">&nbsp;</th>
      <td mat-cell *matCellDef="let item">
        <i class="fa fa-spin fa-circle-notch text-primary invisible" *ngIf="item.state === 'unloaded'"></i>
        <i class="fa fa-spin fa-circle-notch text-primary" *ngIf="item.state === 'loading'"></i>
        <i class="fa fa-times text-danger" *ngIf="item.state === 'failed'"></i>
        <i class="fa fa-check text-success" *ngIf="item.state === 'done'"></i>
      </td>
    </ng-container>

    <ng-container matColumnDef="partNumber">
      <th mat-header-cell *matHeaderCellDef> Item </th>
      <td mat-cell *matCellDef="let item">
        {{item.partNumber}}
      </td>
    </ng-container>

    <ng-container matColumnDef="description">
      <th mat-header-cell *matHeaderCellDef> Description </th>
      <td mat-cell *matCellDef="let item">
        {{item.product && item.product.description}}
      </td>
    </ng-container>

    <ng-container matColumnDef="revision">
      <th mat-header-cell *matHeaderCellDef> Rev </th>
      <td mat-cell *matCellDef="let item">
        {{item.product && item.product.revision}}
      </td>
    </ng-container>

    <ng-container matColumnDef="leadTime">
      <th mat-header-cell *matHeaderCellDef> Lead Time (working days) </th>
      <td mat-cell *matCellDef="let item"> {{item.leadTime}} </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>


</div>