import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MatTable } from '@angular/material/table';
import { Router } from '@angular/router';
import { NavigationService } from '../../../common/services/navigation.service';
import { UtilityService } from '../../../common/services/utility.service';
import { Customer } from '../../../customer/resources/customer';
import { Product } from '../../resources/product';
import { OrderService } from '../../services/order.service';
import { NgModel } from '@angular/forms';
import * as XLSX from 'xlsx';
import { COMMA, ENTER, SPACE } from '@angular/cdk/keycodes';
import { MatChipInputEvent } from '@angular/material/chips';

function tableToJson(tableHtml: string) {

  const div = document.createElement('div');
  div.innerHTML = tableHtml.trim();
  const table = div.firstChild as HTMLTableElement;

  var data = [];

  // first row needs to be headers
  var headers = [];
  for (var i = 0; i < table.rows[0].cells.length; i++) {
    headers[i] = table.rows[0].cells[i].innerText.trim().replace(/\n/gi, ' ');
  }

  // go through cells
  for (var i = 1; i < table.rows.length; i++) {

    var tableRow = table.rows[i];
    var rowData = {};

    for (var j = 0; j < tableRow.cells.length; j++) {

      rowData[headers[j]] = tableRow.cells[j].innerText;

    }

    data.push(rowData);
  }

  return data;
}



@Component({
  selector: 'new-batch-rfq',
  templateUrl: './new-batch-rfq.component.html',
  styleUrls: ['./new-batch-rfq.component.less']
})
export class NewBatchRfqComponent implements OnInit {

  @ViewChild('itemsTable', { static: true }) itemsTable: MatTable<any>;

  constructor(private orderSvc: OrderService, private navService: NavigationService, private utilitySvc: UtilityService, private router: Router) { }

  ngOnInit() {
    this.navService.setPageTitle("Sales");
    this.navService.pushBreadcrumb(
      'New Batch RFQ'
    );
  }

  public customer: Customer;
  public companyId: string;

  public parsedItems: {
    product: Product | null;
    partNumber: string;
    rev: string;
    description: string;
    quantities: number[];
    loading: boolean;
  }[] = [];

  public parseMap = {
    'Name': 'partNumber',
    'Part Number': 'partNumber',
    'Part': 'partNumber',
    'PN': 'partNumber',
    'Part #': 'partNumber',
    'Description': 'description',
    'Notes': 'description',
    'Revision': 'rev',
    'Rev': 'rev'
  }

  @ViewChild('fileInput', { static: true }) fileInput: ElementRef<HTMLInputElement>;
  @ViewChild('fileNameModel', { static: true }) fileNameModel: NgModel;
  public file: File | null;
  public onFileChange() {
    const files = this.fileInput && this.fileInput.nativeElement.files;
    console.log(files)
    if (files.length > 0) {
      this.file = files[0];
      this.fileNameModel.control.setValue(this.file.name);
      this.parseFileInitial(this.file);
    }
  }
  public openFileInput() {
    this.fileInput && this.fileInput.nativeElement.click();
  }

  public fileParseError = false;
  public parsedSheets: {[key: string]: any}[][];
  public async parseFileInitial(file: File) {
    this.parsedSheets = null;
    this.fileParseError = false;
    // @ts-ignore
    const buffer = await file.arrayBuffer();
    try {
      const parsed = XLSX.read(buffer);
      this.parsedSheets = Object.values(parsed.Sheets).map(s => XLSX.utils.sheet_to_json(s, { defval: null }));
      console.log(this.parsedSheets);
    } catch (e) {
      this.fileParseError = true;
      console.log(e);
    }
  }

  public get totalParsedRows() {
    return this.parsedSheets && this.parsedSheets.flat().length;
  }

  private parseKey(parseString: string, target: string) {
    const sanitized = target
        .replace(/[^a-zA-Z0-9\s]+/gi, '')
        .replace(/\s+/gi, ' ')
        .trim();
      const keywords = parseString.split(' ');
        // check that all keywords are in the sanitized table header name
        if (keywords.every(kw => sanitized.toUpperCase().includes(kw.toUpperCase())))
          return true;
      return false;
  }

  public async parseFinal() {
    let unmappedKeys = [];
    this.parsedItems = this.parsedSheets.flat().map(r => {
      let out = { partNumber: null, rev: null, description: null };
      let qties: number[] = [];
      for (const objectKey in r) {
        if (Object.prototype.hasOwnProperty.call(r, objectKey)) {
          if (/(qty|quantity)/gi.test(objectKey)) {
            const parsed = parseInt(r[objectKey]);
            if (!isNaN(parsed)) qties.push(parsed);
            continue;
          }
          let couldParse = false;
          for (const parseString in this.parseMap) {
            if (this.parseKey(parseString, objectKey)) {
              out[this.parseMap[parseString]] = r[objectKey];
              couldParse = true;
            }
          }
          if (!couldParse) unmappedKeys.push(objectKey);
        }
      }
      return {
        product: null,
        partNumber: out.partNumber,
        rev: out.rev || '-',
        description: out.description || '',
        quantities: qties,
        loading: false,
      }
    });

    if (unmappedKeys.length > 0) {
      this.utilitySvc.showAlert('Unparsed Columns', `
        <p>Parts were parsed succesfully, but the following columns could not be matched to any part fields:</p>
        <p>${unmappedKeys.map(k => `<b>${k}</b>`).join(', ')}</p>
      `);
    }

    this.itemsTable.renderRows();

    

    this.parsedItems.forEach(i => {
      i.loading = true;
      this.orderSvc.searchProducts(i.partNumber).subscribe(r => {
        i.loading = false;
        const match = r.find(p => {
          return i.partNumber.trim() === p.partNumber.trim() &&
                 i.rev === p.revision &&
                 p.productStandard &&
                 p.productStandard.isFinal
          })
        if (match) {
          i.partNumber = match.partNumber;
          i.rev = match.revision;
          if (!i.description) i.description = match.description;
          i.product = match;
        }
      })
    })

  }

  public displayedColumns = [
    'status',
    'partNumber',
    'rev',
    'quantities',
    'description'
  ]

  public get allLoaded() {
    return this.parsedItems.every(i => !i.loading)
  }

  public async confirm() {
    const r = await this.utilitySvc.showConfirmationPromise('Are you sure?', 'This will create the RFQ along with any products marked with <span class="text-warning mat-icon material-icons d-inline-flex" role="img" aria-hidden="true">warning</mat-icon>.');
    if (!r) return;
    const order = await this.orderSvc.createBatchRFQ(this.customer, this.companyId, this.parsedItems).toPromise();
    this.router.navigate(['/quoting', order.orderSegmentId])
  }

  readonly separatorKeyCodes = [ENTER, COMMA, SPACE] as const;

  public addQuantity(item: NewBatchRfqComponent['parsedItems'][0], event: MatChipInputEvent) {
    const qty = (event.value || '').trim();
    const parsed = parseInt(qty);
    // Clear the input value
    event.chipInput!.clear();
    if (isNaN(parsed)) return;
    item.quantities = [...item.quantities, parsed];
  }

  public removeQuantity(item: NewBatchRfqComponent['parsedItems'][0], qty: number) {
    item.quantities = item.quantities.filter(x => x !== qty);
  }


}

