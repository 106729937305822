import {
  Component,
  AfterViewInit,
  Input,
  EventEmitter,
  Output,
  ViewChild,
  SimpleChanges,
} from "@angular/core";
import { FloorService } from "../../../floor/services/floor.service";
import { Department } from "../../../floor/resources/building";
import { MatFormField } from "@angular/material/form-field";

@Component({
  selector: "planning-step-notes",
  templateUrl: "./planning-step-notes.component.html",
  styleUrls: ["./planning-step-notes.component.less"],
})
export class PlanningStepNotesComponent implements AfterViewInit {
  @Input() editing: boolean = true;
  @Input() prefix: string;
  @Input() stepNotes: string;
  @Output() stepNotesChange = new EventEmitter<string>();

  private parseValue = (v) => v.endsWith('px') ? parseInt(v.slice(0, -2), 10) : 0;

  @ViewChild('formField') formField: MatFormField;
  private textarea: HTMLTextAreaElement;

  ngAfterViewInit(): void {
    const el = (this.formField._elementRef.nativeElement as HTMLElement);
    this.textarea = el.querySelector('textarea');
    this.calculateLineNumbers();
  }

  private calculateNumLines(str: string) {
    const textareaStyles = window.getComputedStyle(this.textarea);
    const font = `${textareaStyles.fontSize} ${textareaStyles.fontFamily}`;
    const paddingLeft = this.parseValue(textareaStyles.paddingLeft);
    const paddingRight = this.parseValue(textareaStyles.paddingRight);
    const textareaWidth = this.textarea.getBoundingClientRect().width - paddingLeft - paddingRight;

    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');
    context.font = font;

    const words = str.split(' ');
    let lineCount = 0;
    let currentLine = '';
    for (let i = 0; i < words.length; i++) {
        const wordWidth = context.measureText(words[i] + ' ').width;
        const lineWidth = context.measureText(currentLine).width;

        if (lineWidth + wordWidth > textareaWidth) {
            lineCount++;
            currentLine = words[i] + ' ';
        } else {
            currentLine += words[i] + ' ';
        }
    }

    if (currentLine.trim() !== '') {
        lineCount++;
    }

    return lineCount;
  }

  public lineNumbers: string[] = [];

  public calculateLineNumbers() {
    const lines = this.stepNotes.split("\n");
    const numLines = lines.map((line) => this.calculateNumLines(line));

    let lineNumbers: string[] = [];
    let i = 1;
    while (numLines.length > 0) {
      const numLinesOfSentence = numLines.shift();
      lineNumbers.push(i.toString());
      if (numLinesOfSentence > 1) {
        Array(numLinesOfSentence - 1)
          .fill("")
          .forEach((_) => lineNumbers.push(""));
      }
      i++;
    }

    this.lineNumbers = lineNumbers;
  }

  public printLineNumber(s: string) {
    if (!s) return '&nbsp';
    else if (this.prefix) return `${this.prefix}-${s}`;
    else return s;
  }

}
