import { Component, Input, OnInit } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { ShippingService } from '../../services/shipping.service';
import { NavigationService } from '../../../common/services/navigation.service';
import { UtilityService } from '../../../common/services/utility.service';

@Component({
  selector: 'shipping-split-ticket',
  templateUrl: './shipping-split-ticket.component.html',
  styleUrls: ['./shipping-split-ticket.component.less']
})
export class ShippingSplitTicketComponent implements OnInit {
  @Input() ticketId: string;
  @Input() totalQuantityRequired: number;
  @Input() sidenav: MatSidenav;

  public newQuantity: number;
  public saving: boolean = false;

  constructor(navService: NavigationService, private shippingService: ShippingService, private utilitySvc: UtilityService) {
    navService.pushBreadcrumb("Split Ticket");
  }

  private getData(): void {
    this.newQuantity = this.totalQuantityRequired;
  }

  public get remainingQty(): number {
    return this.totalQuantityRequired - this.newQuantity;
  }

  public cancel(): void {
    this.utilitySvc.showConfirmation("Are you Sure?",
      "<p>If you cancel now, no changes will be saved.</p>",
      (r => {
        if (r == true) {
          this.sidenav.close();
        }
      }));
  }

  public save(): void {
    if (isNaN(this.newQuantity) || this.newQuantity < 1 || this.newQuantity > this.totalQuantityRequired
      || this.remainingQty < 1 || this.remainingQty > this.totalQuantityRequired) {
      this.utilitySvc.showAlert("Check Quantities","<p>Please check your quantities!</p><p class='text-muted'>Negative quantities, zero quantities, or quantities larger than the 'Total' quantity are not allowed. Please check your input.</p>");
      return;
    }

    this.saving = true;
    this.shippingService.splitTicket(this.ticketId, this.newQuantity).subscribe(_ => {
      this.saving = false;
      this.sidenav.close();
    });
  }

  ngOnInit() {
    this.getData();
  }
}
