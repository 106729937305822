<table class='table search-results table-striped table-borderless table-hover thead-light'>
  <ng-content select="[header-template]"></ng-content>
  <tbody>
    <tr *ngIf="results.results.length==0">
      <td colspan="100%" class="text-center text-muted">
        <em *ngIf="emptySetMessage; else defaultMessage">
          <h4>{{emptySetMessage}}</h4>
        </em>
        <ng-template #defaultMessage>
          <em>
            <h4>-- No Results --</h4>
            <p class="small"><i class="fas fa-lg fa-fw fa-info-circle text-info"></i>Check your spelling, try changing search terms.</p>
          </em>
        </ng-template>
      </td>
    </tr>
    <tr *ngFor="let result of results.results" (click)="selectResult(result)">
      <ng-template [ngTemplateOutlet]="itemTemplate" [ngTemplateOutletContext]="{ $implicit: result }"></ng-template>
    </tr>
  </tbody>
</table>

<search-pager [results]="results" (newPage)="getPage($event)"></search-pager>
