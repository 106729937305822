<h2 matDialogTitle>Generate Part Quote Report</h2>
<mat-dialog-content>
    <!-- <div class="form-group">
        <div>
            <mat-checkbox [(ngModel)]="showBlocked">Show Blocked Quotes (awaiting estimate, etc.)</mat-checkbox>
        </div>
    </div> -->
    <!-- <div class="form-group">
        <label>Issue Date Range</label>
        <div class="row">
            <div class="col-6">
                <date-picker #issuedFromPicker [appearance]="'outline'" [placeholder]="'From'" [editable]="true" [(date)]="issuedFrom"></date-picker>
            </div>
            <div class="col-6">
                <date-picker #issuedToPicker [appearance]="'outline'" [placeholder]="'To'" [editable]="true" [(date)]="issuedTo" [min]="issuedFrom ? issuedFrom : undefined"></date-picker>
            </div>
        </div>
    </div> -->
    <div class="form-group">
        <label>Part Numbers</label>
        <div>
            <mat-form-field class="w-100" appearance="outline">
                <mat-chip-list #chipList aria-label="Parts selection">
                    <mat-chip color="primary" class="part-chip"
                        *ngFor="let part of selectedParts"
                    >
                        {{part.partNumber}}
                        <button mat-icon-button matChipRemove (click)="removePart(part)">
                            <mat-icon>cancel</mat-icon>
                        </button>
                    </mat-chip>
                    <input
                        [placeholder]="selectedParts.length === 0 ? 'All Part Numbers' : ''"
                        [formControl]="searchControl"
                        [matAutocomplete]="auto"
                        [matChipInputFor]="chipList"
                        #chipInput
                    >
                </mat-chip-list>
                <i matSuffix [class.invisible]="!loading" class="text-primary fas fa-spin fa-2x fa-circle-notch"></i>
                <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event.option.value)">
                    <mat-option *ngFor="let part of filteredParts | async" [value]="part">
                        <span [innerHTML]="part.partNumber | highlight: searchControl.value"></span>
                    </mat-option>
                </mat-autocomplete>
            </mat-form-field>
        </div>
    </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-button [matDialogClose]="{
        issuedFrom: issuedFrom,
        issuedTo: issuedTo,
        selectedParts: selectedParts
    }">Generate Report</button>
    <button mat-button [matDialogClose]="null">Cancel</button>
</mat-dialog-actions>