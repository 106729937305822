import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { SearchList } from '../../../common/components/search-list/search-list';
import { Product, SimilarPartScore } from '../../resources/product';
import { OrderService } from '../../services/order.service';

@Component({
  selector: 'similar-part-overlay',
  templateUrl: './similar-part-overlay.component.html',
  styleUrls: ['./similar-part-overlay.component.less']
})
export class SimilarPartOverlayComponent extends SearchList<SimilarPartScore> implements OnInit, OnChanges {
  @Input() product: Product;
  @Input() isOpen: boolean = false;
  @Output() assign: EventEmitter<string> = new EventEmitter<string>();
  @Output() preview: EventEmitter<string> = new EventEmitter<string>();
  @Output() isOpenChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  public results: SimilarPartScore[] = null;

  constructor(private orderSvc: OrderService) {
    super('SimilarPartOverlayComponent');
  }

  public toggleWindow(): void {
    this.isOpen = !this.isOpen;
    this.isOpenChange.emit(this.isOpen);

    this.refresh();
  }

  public refresh(): void {
    this.results = null;

    if (this.isOpen) {
      this.orderSvc.getSimilarProducts(this.product, this.pageNumber).subscribe(r => {
        this.results = r.results;
        this.searchResults = r;
      });
    }
  }

  protected search(): void {
    this.refresh();
  }

  public assignPart(e: Event, productId: string): void {
    e.preventDefault();
    e.stopImmediatePropagation();

    this.assign.emit(productId);

    this.toggleWindow();
  }

  public onSelect(record: SimilarPartScore): void {
    this.preview.emit(record.productId);
  }

  ngOnInit(): void {
    this.refresh();
  }

  ngOnChanges(_: SimpleChanges): void {
    //Empty - just having using this interface does what we want
  }
}
