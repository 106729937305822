import { Component, OnInit, Input } from '@angular/core';
import { WorkOrderOverview } from '../../../../planning/resources/work-order';

@Component({
  selector: 'oor-preplanning',
  templateUrl: './oor-preplanning.component.html',
  styleUrls: ['./oor-preplanning.component.less']
})
export class OorPreplanningComponent implements OnInit {

  @Input() record: WorkOrderOverview;

  constructor() { }

  ngOnInit(): void {
  }
  

}
