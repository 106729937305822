import { Component, Input, OnInit, Output, EventEmitter, ViewChild, AfterViewInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { Station } from '../../../../../resources/station';
import { StationService } from '../../../../../services/station.service';
import { Observable, combineLatest } from 'rxjs';
import { NgModel } from '@angular/forms';
import { distinctUntilChanged, map, shareReplay, startWith } from 'rxjs/operators';

@Component({
  selector: 'product-workflow-station-list',
  templateUrl: './product-workflow-station-list.component.html',
  styleUrls: ['./product-workflow-station-list.component.less']
})
export class ProductWorkflowStationListComponent implements AfterViewInit {

  public stationData: Observable<Station[]>;

  constructor(public stationService: StationService) { }

  @ViewChild('filterModel') filterModel: NgModel;
  ngAfterViewInit(): void {
    this.stationData = combineLatest([
      this.filterModel.valueChanges.pipe(
        startWith(''),
        distinctUntilChanged()
      ),
      this.stationService.stations.pipe(startWith([] as Station[]), map(sl => sl.filter(s => !s.isDeleted)))
    ]).pipe(
      map(([search, stations]) => {
        if (!search || typeof search !== 'string') return stations;
        const trimmed = search.trim();
        return stations.filter(s =>
          s.name.toUpperCase().includes(trimmed.toUpperCase())
        );
      }),
      map(s => {
        return s.slice(0, 50);
      }),
      shareReplay(1)
    )
  }

  @Output() stationDblClick = new EventEmitter<Station>();

}
