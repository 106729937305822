import IWidgetData from './interface'
import orderStatusInfoWidgets from './orderStatusInfo'
import floorWidgets from './floorAlerts';
import messagesWidgets from './companyMessages';

const widgets: IWidgetData[] = [
  ...orderStatusInfoWidgets,
  ...floorWidgets,
  ...messagesWidgets
]

export default widgets;
