import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FieldType } from '@ngx-formly/material';
import { FieldTypeConfig } from '@ngx-formly/core';
import { Observable } from 'rxjs';
import { shareReplay, startWith, switchMap, take, tap } from 'rxjs/operators';
import { MatAutocomplete, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { NgModel } from '@angular/forms';

@Component({
  selector: 'formly-autocomplete-type',
  template: `
    <input
      #searchField
      matInput
      [(ngModel)]="searchText"
      #searchModel="ngModel"
      [matAutocomplete]="auto"
      #autoTrigger="matAutocompleteTrigger"
      [matAutocompleteNoDisplay]="autoTrigger"
      [formlyAttributes]="field"
      [placeholder]="props.placeholder"
      [errorStateMatcher]="errorStateMatcher"
      (blur)="onBlur($event)"
    />
    <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayWith.bind(this)" (optionSelected)="selected($event)" compare>
      <mat-option *ngFor="let value of filter | async" [value]="value">
        {{ displayWith(value) }}
      </mat-option>
    </mat-autocomplete>
    <mat-progress-bar class="progress-bar" mode="indeterminate" *ngIf="loading"></mat-progress-bar>
  `,
  styles: [`
    .progress-bar {
      position: absolute;
      bottom: 0;
      left: -8px;
      width: calc(100% + 16px) !important;
      border-radius: 9px;
    }
  `]
})
export class AutocompleteTypeComponent extends FieldType<FieldTypeConfig> implements AfterViewInit {
  filter: Observable<any>;
  filterLast: Observable<any>;
  public searchText = '';
  loading = false;

  @ViewChild('searchModel') searchModel: NgModel;
  ngAfterViewInit() {
    this.filter = this.searchModel.control.valueChanges.pipe(
      startWith(''),
      tap(() => this.loading = true),
      switchMap((term) => this.props.filter(term)),
      tap(() => this.loading = false),
    );
    this.filterLast = this.filter.pipe(shareReplay(1));

    this.filter.pipe(take(1)).subscribe((v: any[]) => {
      if (this.formControl.value) {
        const selected = v.find(x => this.compareWith(x) === this.formControl.value);
        if (selected) {
          this.searchText = this.displayWith(selected);
          setTimeout(() => {
            this.searchField.nativeElement.value = this.displayWith(selected);
          });
          this.formControl.markAsTouched();
        }
      }
    });

    this.searchModel.statusChanges.subscribe(() => {
      if (this.searchModel.control.touched) this.formControl.markAsTouched();
      if (this.searchModel.control.dirty) this.formControl.markAsDirty();
    })
    this.formControl.statusChanges.subscribe(() => {
      this.searchModel.control.setErrors(this.formControl.errors);
    })
  }

  public displayWith(item: any) {
    if (!this.props.displayWith) return item;
    if (!item) return item;
    else return this.props.displayWith(item);
  }

  public compareWith(item: any) {
    if (!this.props.compareWith) return item;
    if (!item) return item;
    else return this.props.compareWith(item);
  }

  public setValue(value: any) {
    this.formControl.setValue(this.compareWith(value));
  }

  @ViewChild('searchField') searchField: ElementRef<HTMLInputElement>;
  public selected(event: MatAutocompleteSelectedEvent) {
    const value = event?.option?.value;
    this.setValue(value);
    this.searchField.nativeElement.value = this.displayWith(value);
  }

  public onBlur(event: FocusEvent) {
    const rt = event.relatedTarget as HTMLElement;
    if (rt && rt.classList?.contains('mat-option')) {
      return;
    }
    const value = this.searchText;

    this.filterLast.pipe(take(1)).subscribe(currentItems => {
      let matchingOptions = currentItems.find(
        (option) => this.displayWith(option) == value
      );
      if (!matchingOptions) {
        this.setValue(undefined);
        this.searchText = '';
      }
    })

  }
}