import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { MessageService } from '../../common/services/message.service';
import { ErrorHandlerService } from '../../common/services/errorHandler.service'
import { httpService } from '../../common/services/http.service';
import { SearchResult } from '../../common/resources/search-result';
import { RMADispositionLot, RMATicket, RMATicketDocument } from '../resources/rma-ticket';
import { catchError, tap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { MessageType } from '../../common/resources/message';
import { VirtualDocument } from '../../common/resources/virtual-document';
import { UtilityService } from '../../common/services/utility.service';
import { Order } from '../../order/resources/order';

@Injectable({
  providedIn: 'root',
})
export class RMAService extends httpService {
  private apiBase: string = 'api/rmaticket';
  private apiUrl: string;

  constructor(errorHandler: ErrorHandlerService, private messages: MessageService, private http: HttpClient, @Inject('BASE_URL') private baseUrl: string) {
    super(errorHandler, messages);
    this.serviceName = "RMA";

    this.apiUrl = this.baseUrl + this.apiBase;
  }

  public searchSales(searchString?: string, page?: number, sortBy?: string, sortDirection?: string, managerView?: boolean): Observable<SearchResult<RMATicket>> {
    let params = new HttpParams({
      fromObject: { searchText: searchString, pageIndex: (page || 0).toString(), orderBy: sortBy || "name", direction: sortDirection || "asc", forAllUsers: managerView ? 'true' : 'false' }
    });
    return this.http.get<SearchResult<RMATicket>>(this.apiUrl + '/searchSales', { params }).pipe(
      catchError(this.handleError<any>("Get RMA Sales Tickets Search Results", null))
    );
  }

  public getDetail(id: string): Observable<RMATicket> {
    return this.http.get<RMATicket>(this.apiUrl + '/' + id).pipe(
      catchError(this.handleError<any>("Get RMA Ticket Detail", null))
    );
  }

  public save(_item: RMATicket): Observable<RMATicket> {
    const item: RMATicket = JSON.parse(JSON.stringify(_item));
    item.customer = null;
    item.product = null;
    item.workOrder = null;
    item.documents = null;
    item.customerPurchaseOrder = null;
    item.salesPerson = null;
    item.qualityAssignee = null;

    if (item.rmaTicketId == UtilityService.emptyGuid) {
      //New Item
      return this.http.post<RMATicket>(this.apiUrl + '/new', item).pipe(
        catchError(this.handleError<any>("Create RMA Ticket", null))
      );
    }
    else {
      return this.http.post<RMATicket>(this.apiUrl, item).pipe(
        catchError(this.handleError<any>("Save RMA Ticket", null))
      );
    }
  }

  public getMatchingWorkOrders(productId: string = '', customerId: string, customerPONumber: string = '') {
    return this.http.get<any>(this.apiUrl + '/getMatchingWorkOrders', { params: {
      productId, customerId, customerPONumber
    } }).pipe(
      catchError(this.handleError<any>("Get Matching Work Orders for RMA", null))
    );
  }

  public addDocuments(ticket: RMATicket, documents: VirtualDocument[]): Observable<RMATicketDocument[]> {
    return this.http.post<RMATicketDocument[]>(this.apiUrl + '/adddocuments?rmaTicketId=' + ticket.rmaTicketId, documents.map(d => d.documentId)).pipe(
      tap(_ => this.messages.add("RMA Service: Documents Updated", MessageType.SUCCESS, true)),
      catchError(this.handleError<any>("Add Documents to RMA Ticket", null))
    );
  }

  public removeDocument(ticket: RMATicket, document: VirtualDocument): Observable<any> {
    return this.http.get<any>(this.apiUrl + '/removedocument?rmaTicketId=' + ticket.rmaTicketId + '&documentId=' + document.documentId).pipe(
      tap(_ => this.messages.add("RMA Service: Document Removed Successfully", MessageType.SUCCESS, true)),
      catchError(this.handleError<any>("Remove Document from RMA Ticket", null))
    );
  }

  public removeLotDocument(lot: RMADispositionLot, document: VirtualDocument): Observable<any> {
    return this.http.get<any>(this.apiUrl + '/removelotdocument?rmaTicketId=' + lot.rmaDispositionLotId + '&documentId=' + document.documentId).pipe(
      tap(_ => this.messages.add("RMA Service: Document Removed Successfully", MessageType.SUCCESS, true)),
      catchError(this.handleError<any>("Remove Document from RMA Lot", null))
    );
  }

  public updateDispositions(ticket: RMATicket, lots: RMADispositionLot[]): Observable<RMATicket> {
    return this.http.post<RMATicketDocument[]>(this.apiUrl + '/updateDispositions/' + ticket.rmaTicketId, lots).pipe(
      tap(_ => this.messages.add("RMA Service: Dispositions Updated", MessageType.SUCCESS, true)),
      catchError(this.handleError<any>("Add Dispositions to RMA Ticket", null))
    );
  }

  public sendToQuality(ticket: RMATicket): Observable<void> {
    return this.http.post<void>(this.apiUrl + '/sendToQuality/' + ticket.rmaTicketId, null).pipe(
      tap(_ => this.messages.add("RMA Service: Ticket Sent to Quality Successfully", MessageType.SUCCESS, true)),
      catchError(this.handleError<any>("Send RMA Ticket to Quality", null))
    );
  }

  public setAsShipped(ticket: RMATicket, shippingInfo: {
    shippingCarrierId?: string;
    trackingNumber: string;
    shippingMethod?: string;
    departureDate?: Date;
    arrivalDate?: Date;
    destinationAddressId: string;
  }): Observable<void> {
    return this.http.post<void>(this.apiUrl + '/setAsShipped/' + ticket.rmaTicketId, shippingInfo).pipe(
      tap(_ => this.messages.add("RMA Service: Ticket Marked as Shipped Successfully", MessageType.SUCCESS, true)),
      catchError(this.handleError<any>("Mark RMA Ticket as Shipped", null))
    );
  }

  public getCustomerLabel(ticket: RMATicket): Observable<VirtualDocument> {
    return this.http.get<Document>(this.apiUrl + '/customerLabel/' + ticket.rmaTicketId).pipe(
      catchError(this.handleError<any>("Get RMA Label", null))
    );
  }

  public getLotOrderSegment(lot: RMADispositionLot) {
    return this.http.get<Order>(this.apiUrl + '/getLotOrderSegment/' + lot.rmaDispositionLotId).pipe(
      catchError(this.handleError<any>("Get Order For RMA Disposition Lot", null))
    );
  }

  public getDebitValue(workOrderId: string, quantity: number): Observable<number> {
    return this.http.get<Document>(this.apiUrl + '/getDebitValue/' + workOrderId + '?quantity=' + quantity).pipe(
      catchError(this.handleError<any>("Get RMA Debit Quantity", null))
    );
  }

}
