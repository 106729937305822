import { Component, OnInit, ElementRef, ViewChild, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import * as pbi from 'powerbi-client';
import { UserRole } from '../../../common/resources/user';
import { NavigationService } from '../../../common/services/navigation.service';
import { UserService } from '../../../common/services/user.service';
import { UtilityService } from '../../../common/services/utility.service';
import { ReportEmbed } from '../../resources/report-embed';
import { ReportEmbedService } from '../../services/report-embed.service';

@Component({
  selector: 'report-embed-detail',
  templateUrl: './report-embed-detail.component.html',
  styleUrls: ['./report-embed-detail.component.less']
})
export class ReportEmbedDetailComponent implements OnInit {
  // Report details
  private report: ReportEmbed;

  // Container to contain the actual embedded report
  @ViewChild('reportEmbedContainer') container: ElementRef;

  // PowerBI client
  private powerbi;

  // Determines whether the editor is showing
  public editing: boolean = false;

  // Permissions groups
  public availableGroups: UserRole[];

  constructor(private reportEmbedService: ReportEmbedService, private navService: NavigationService, private userService: UserService, private route: ActivatedRoute) {
    this.powerbi = new pbi.service.Service(pbi.factories.hpmFactory, pbi.factories.wpmpFactory, pbi.factories.routerFactory);
  }

  ngOnInit() {
    this.report = <ReportEmbed> {
      reportEmbedId: this.route.snapshot.paramMap.get('id'),
      reportId: null,
      reportTitle: null,
    };

    this.setPageTitle();

    if (this.userService.loaded) {
      this.availableGroups = this.userService.roles;
    }
    else {
      this.userService.rolesLoaded.subscribe(
        _ => this.availableGroups = this.userService.roles
      );
    }

    if (this.report.reportEmbedId !== 'new') {
      // We have a report id, go ahead and embed it
      this.renderEmbed();
    } else {
      // New report, empty the guid
      this.report.reportEmbedId = UtilityService.emptyGuid;

      // Show the editor
      this.editing = true;
    }
  }

  private setPageTitle(): void {
    this.navService.setPageTitle("Report Embed");
    var breadcrumb = this.report.reportEmbedId === 'new' ? "New Report Embed" : this.report.reportTitle;
    this.navService.pushBreadcrumb(breadcrumb);
  }

  startEditing() {
    this.editing = true;
  }

  stopEditing() {
    this.editing = false;
    this.renderEmbed();
  }

  saveChanges() {
    this.reportEmbedService.save(this.report).subscribe((item) => {
      // Close editor
      this.editing = false;

      // Re-render the embed, since it will have disappeared
      this.renderEmbed();
    });
  }

  renderEmbed() {
    // Retrieve the id to get the title
    this.reportEmbedService.getDetail(this.report.reportEmbedId).subscribe((report) => {
      this.report = report;
      this.setPageTitle();
    })
    // Retrieve the embed parameters from the backend
    this.reportEmbedService.getEmbedParams(this.report.reportEmbedId).subscribe((detail) => {
      const config = {
        type: 'report',
        tokenType: pbi.models.TokenType.Embed,
        accessToken: detail.embedToken.token,
        embedUrl: detail.embedReports[0].embedUrl,
        id: detail.embedReports[0].reportId,
        permissions: pbi.models.Permissions.Read,
        settings: {
          filterPaneEnabled: true,
          navContentPaneEnabled: true,
        }
      };

      // Use the parameters to embed the report
      this.powerbi.embed(this.container.nativeElement, config);
    });
  }

}
