<div class="row m-0 h-100-t">
  <div class="col-3 h-100-t p-0 d-flex flex-column mat-elevation-z6">
    <div class="mat-dialog-title p-3 mb-0">
      Associated Parts
    </div>
    <mat-list class="pt-0 flex-grow-1 position-relative" style="height: 0; overflow-y: scroll;">
      <mat-list-item *ngFor="let prod of record.products" class="border-bottom bg-white list-item cursor-pointer"
        [class.bg-primary]="selectedProductId === prod.productProductId"
        [class.text-white]="selectedProductId === prod.productProductId"
        (click)="selectedProductId = prod.productProductId">
        <div class="w-100 text-ellipsis d-flex align-items-center mat-small-header">
          <span>{{ prod.product.partNumber }} Rev. {{ prod.product.revision }}</span>
          <mat-chip class="ml-auto bg-dark text-white" style="font-size: 0.95em">
            {{ prod.product.quantitiesMap.length }} qt{{ prod.product.quantitiesMap.length === 1 ? 'y' : 'ies' }}
          </mat-chip>
          <mat-chip class="ml-2 bg-dark text-white" style="font-size: 0.95em" *ngLet="this.service.getProductLeadTimeObservable(prod.productProductId) | async as val">
            <mat-icon svgIcon="calendar-clock" matPrefix class="mr-1"></mat-icon>
            <div  *ngIf="val !== null; else loading">
              {{ val }} day{{val === 1 ? '' : 's'}}
            </div>
          </mat-chip>
        </div>
      </mat-list-item>
      <div *ngIf="record.products.length === 0" class="w-100 text-muted text-center position-absolute small p-4"
        style="pointer-events: none;">
        No products found.
      </div>
    </mat-list>
  </div>
  <div class="col-9 p-0 py-3 d-flex flex-column h-100-t" *ngIf="selectedProduct">
    <div class="px-3 pt-0 pb-3 mb-0 d-flex align-items-center">
      <div class="mat-dialog-title mb-0">{{ selectedProduct.partNumber }} Rev. {{ selectedProduct.revision }}</div>
      <button mat-flat-button color="primary" class="button-text ml-2" (click)="openProduct(selectedProduct)">
        <mat-icon svgIcon="open-in-new" matPrefix></mat-icon>
        Open Part
      </button>
    </div>
    <div class="row m-0 flex-grow-1" style="height: 0;">
        <div class="col-7 px-3 h-100-t">
          <order-detail-quantity-table [product]="selectedProduct"></order-detail-quantity-table>
        </div>
        <div class="col-5 px-3 h-100-t">
          <order-detail-lead-time-breakdown [product]="selectedProduct"></order-detail-lead-time-breakdown>
        </div>
    </div>
  </div>
</div>

<ng-template #loading>
  Loading...
</ng-template>