<div class="row m-0 p-3 mt-3" ngForm #form="ngForm">
  <div class="col-12 mb-3" *ngIf="service.getIncompleteAssemblyInfo(product) as reviewInfo">
    <div class="border rounded p-3"
      [class.border-warning]="reviewInfo.status == 1"
      [class.border-success]="reviewInfo.status == 3"
    >
      <div class="d-flex align-items-center font-weight-medium">
          <mat-icon class="mr-2 text-warning" svgIcon="alert" *ngIf="reviewInfo.status == 1"></mat-icon>
          <span *ngIf="reviewInfo.status == 1">This assembly has been marked by a reviewer as missing info</span>
          <span *ngIf="reviewInfo.status == 3">This assembly was marked by a reviewer as missing info, but has been corrected</span>
          <button mat-stroked-button *ngIf="reviewInfo.status == 1 && !editing" class="ml-auto" (click)="service.markAsCorrected(this.product)">Mark as Corrected</button>
      </div>
      <p class="small mb-0 mt-2">
        {{ reviewInfo.note }}
      </p>
    </div>
  </div>
  <div class="col-4 mb-2">
    <mat-form-field appearance="outline" class="w-100">
      <mat-label>Part Number</mat-label>
      <input matInput [readonly]="!editing" type="text" [(ngModel)]="product.partNumber" name="partNumber" class="font-weight-bold" required>
    </mat-form-field>
  </div>
  <div class="col-2">
    <mat-form-field appearance="outline" class="w-100">
      <mat-label>Revision</mat-label>
      <input matInput [readonly]="!editing" type="text" [(ngModel)]="product.revision" name="revision" required>
    </mat-form-field>
  </div>
  <div class="col-3" *ngIf="!product.parentAssemblyId">
    <product-quantity-input [(ngModel)]="product.quantitiesMap" name="quantitiesMap" [readonly]="!editing" [required]="true"></product-quantity-input>
  </div>
  <div class="col-3" *ngIf="product.parentAssemblyId">
    <mat-form-field appearance="outline" class="w-100">
      <mat-label>Quantity (per unit of upper assembly)</mat-label>
      <input matInput [readonly]="!editing" type="number" [(ngModel)]="product.quantityAsChild" name="quantityAsChild" required>
    </mat-form-field>
  </div>
  <div class="col-3" style="
  display: flex;
    padding-top: 4px;
    padding-bottom: 13px;">
    <button mat-raised-button color="primary" class="button-text w-100" [disabled]="editing"
    [matTooltip]="editing ? 'Please save your changes first.' : undefined"
    *ngIf="!product.productStandard"
    (click)="makePartCanonical()"
    >
      Make Part Canonical
    </button>
    <button mat-stroked-button class="button-text border-success text-success w-100" style="pointer-events: none;"
    *ngIf="product.productStandard"
    >
      <mat-icon matPrefix>check</mat-icon>
      Is Canonical Part
    </button>
  </div>
  <div class="col-4">
    <mat-form-field appearance="outline" class="w-100">
      <mat-label>Customer Quote Line No.</mat-label>
      <input matInput [readonly]="!editing" type="text" [(ngModel)]="product.customerQuoteLineNo" name="customerQuoteLineNo">
    </mat-form-field>
  </div>
  <div class="col-3">
    <date-picker appearance="outline" class="w-100" placeholder="Date Required" [(ngModel)]="product.dateRequired" name="dateRequired"
      [editable]="true" [readonly]="!editing"></date-picker>
  </div>
  <div class="col-3 mb-2" *ngLet="leadTime$ | async as leadTime">
    <mat-form-field appearance="outline" class="w-100" floatLabel="always">
      <mat-label>Lead Time</mat-label>
      <input matInput readonly type="text" [ngModel]="(leadTime !== null) ? (leadTime + ' days') : ''" placeholder="Loading..." [ngModelOptions]="{standalone: true}" required>
    </mat-form-field>
  </div>
  <div class="col-12 mb-2">
    <mat-form-field appearance="outline" class="w-100" floatLabel="always">
      <mat-label>Description</mat-label>
      <textarea [readonly]="!editing" matInput [(ngModel)]="product.description" name="projectDescription" rows="2" placeholder="Part description (optional) - will appear on invoice."></textarea>
    </mat-form-field>
  </div>
  <div class="col-6">
    <mat-form-field appearance="outline" class="w-100" floatLabel="always">
      <mat-label>Public Notes</mat-label>
      <textarea [readonly]="!editing" matInput [(ngModel)]="product.note" name="publicNotes" rows="8" placeholder="Client visible notes can go here"></textarea>
    </mat-form-field>
  </div>
  <div class="col-6 mb-2">
    <mat-form-field appearance="outline" class="w-100" floatLabel="always">
      <mat-label>Private Notes</mat-label>
      <textarea [readonly]="!editing" matInput [(ngModel)]="product.privateNote" name="privateNotes" rows="8" placeholder="Internal-only notes can go here"></textarea>
    </mat-form-field>
  </div>
  <div class="col-12">
    <div class="mat-dialog-title">Related Microtickets for PN</div>
  </div>
  <div class="col-12">
    <microticket-subitem-viewer
      [relatedTicketId]="record.salesProcessId"
      [relatedTicketName]="service.nameForMicrotickets"
      [relatedTicketType]="'SalesProcess'"
      [filterKey]="service.microticketFilterKey"
      [subItem]="service.generateSubItemNavigationId(product.productId, 'product')"
      (loadingChange)="service.loading = $event"
      [microTickets]="service.sharedMicroticketSubject"
    ></microticket-subitem-viewer>
  </div>
</div>