<loading *ngIf="saving" content="Saving..."></loading>
<loading *ngIf="!myResourcesEmployee" content="Loading..."></loading>

<div *ngIf="true">

  <div class="container-fluid">
    <div class="row">
      <div class="col-4">
        <label>Department</label>
        <mat-select [(value)]="selectedDepartment" placeholder="Select Department...">
          <mat-option value="0">All</mat-option>
          <mat-option *ngFor="let department of departments" [value]="department.departmentId">
            {{department.name}}
          </mat-option>
        </mat-select>
      </div>
      <div class="col-4">
        <label>Building</label>
        <mat-select [(value)]="selectedBuilding" placeholder="Select Building...">
          <mat-option value="0">All</mat-option>
          <mat-option *ngFor="let building of buildings" [value]="building.departmentId">
            {{building.name}}
          </mat-option>
        </mat-select>
      </div>
    </div>
  </div>

  <div class="row form-group">
    <div class="col-12">
      <search-bar class="w-100 noMarginTopBottom" [(searchString)]="searchString" (search)="doSearch($event)" [showManagerBar]="false"></search-bar>
    </div>
    <div class="col-12">
      <div class="md-switching-timeline-view-cont">

        <mbsc-eventcalendar id="employeeShiftAssignment" [data]="arrAssignedMbscCalendarEvent" [options]="calendarOptions" [view]="calView" [resources]="myResourcesEmployee" [invalid]="myInvalids" [extendDefaultEvent]="defaultShift"
                            [headerTemplate]="myHeaderTemplate" [resourceTemplate]="myResourceTemplate" (onEventCreateFailed)="eventUpdateFail($event)" (onEventUpdateFailed)="eventUpdateFail($event)"
                            [scheduleEventContentTemplate]="myScheduleEventContentTemplate" [theme]="windows">

          <ng-template #myHeaderTemplate>
            <div class="my-calendar-nav-class">
              <mbsc-calendar-nav class="md-work-week-nav"></mbsc-calendar-nav>
            </div>
            <div class="md-work-week-picker">
              <mbsc-segmented-group [(ngModel)]="view" (change)="changeView()">
                <mbsc-segmented value="day">Day</mbsc-segmented>
                <mbsc-segmented value="workweek">Work week</mbsc-segmented>
                <mbsc-segmented value="week">Week</mbsc-segmented>
                <mbsc-segmented value="month">Month</mbsc-segmented>
              </mbsc-segmented-group>
            </div>
            <div class="my-calendar-nav-class-2">
              <div class="border-class"><mbsc-calendar-prev class="md-work-week-prev"></mbsc-calendar-prev></div>
              <div class="border-class"><mbsc-calendar-today class="md-work-week-today"></mbsc-calendar-today></div>
              <div class="border-class"><mbsc-calendar-next class="md-work-week-next"></mbsc-calendar-next></div>
            </div>
          </ng-template>

        </mbsc-eventcalendar>


        <ng-template #myScheduleEventContentTemplate let-data>
          <div class="my-event-title">{{data.title}}</div>
        </ng-template>

        <ng-template #myResourceTemplate let-resource>
          <div class="md-work-week-cont">
            <div class="md-work-week-name" [innerHTML]="resource.name"></div>
            <div class="md-work-week-title" [innerHTML]="resource.title"></div>
            <img class="md-work-week-avatar" [src]="resource.img" />
            <div class="md-work-week-title" [innerHTML]="resource.scheduledHours + ' / ' + resource.maxHours + ' hours'"></div>
          </div>
        </ng-template>


        <mbsc-popup [options]="popupOptions" [buttons]="popupButtons" [headerText]="popupHeader" #objPopup class="employee-shifts-popup">
          <div class="margin16">
            <label class="col-4 required-field  mbsc-windows mbsc-ltr mbsc-label mbsc-label-stacked mbsc-label-box-stacked">Shift</label>
            <br />
            <select class="col-4 ddlShiftsClass" #mySelectShifts required [formControl]="shiftsFormControl" name="ddlShifts" id="ddlShifts" (change)="onSelectionChangeShift(mySelectShifts.value)">
              <option *ngFor="let shiftItem of arrAvailableShifts" [value]="shiftItem.shiftId">{{shiftItem.name}}</option>
            </select>
          </div>
          <div>
            <mbsc-input #startMbscInput class="inputDateClass floatLeftClass noPaddingLeftRight noPaddingTop margin16" label="Shift start" data-dropdown="true" [disabled]="!isCustomShift"></mbsc-input>
            <mbsc-input #endMbscInput class="inputDateClass floatLeftClass noPaddingLeftRight noPaddingTop margin16" label="Shift end" data-dropdown="true" [disabled]="!isCustomShift"></mbsc-input>
            <mbsc-datepicker [(ngModel)]="shiftDate" [startInput]="startMbscInput" [endInput]="endMbscInput" [options]="rangeOptions"></mbsc-datepicker>
          </div>
          <div class="margin16 floatLeftClass" style="width: 95%;">
            <mat-accordion>
              <mat-expansion-panel class="noPaddingLeftRight" [expanded]="recurrenceEventPanelExpanded">
                <mat-expansion-panel-header class="expansionPanelHeader">
                  <mat-panel-title>
                    Recurrence
                  </mat-panel-title>
                  <mat-panel-description>
                  </mat-panel-description>
                </mat-expansion-panel-header>
                <mat-slide-toggle [(ngModel)]="recurrenceEnabled">Enabled?</mat-slide-toggle>

                <div class="col-12">
                  <div class="row paddingTop">
                    <label class="col-4 noPaddingLeftRight mbsc-windows mbsc-ltr mbsc-label mbsc-label-stacked mbsc-label-box-stacked">Repeat every</label>
                    <input [(ngModel)]="repeatInterval" type="number" class="col-3 form-control form-control-sm" appStaticEdit [editable]="recurrenceEnabled" />
                    <div class="col-1"></div>
                    <mat-select class="col-4" [(ngModel)]="selectedRecurrenceFrequency" [disabled]="!recurrenceEnabled" (selectionChange)="onSelectionChangeFrequency($event.value)">
                      <mat-option *ngFor="let freq of arrRecurrenceFrequency" [value]="freq">{{freq.text}}</mat-option>
                    </mat-select>
                  </div>

                  <div *ngIf="showRepeatOnOptions" class="row paddingTop">
                    <label class="col-2 required-field noPaddingLeftRight mbsc-windows mbsc-ltr mbsc-label mbsc-label-stacked mbsc-label-box-stacked">Repeat on</label>
                    <div class="col-10 noPaddingLeftRight">
                      <mat-chip-list selectable multiple [disabled]="!recurrenceEnabled" [(ngModel)]="selected_arrOptions_RepeatOn_Week">
                        <mat-chip #chipItem="matChip" *ngFor="let option of arrOptions_RepeatOn_Week" [value]="option" (click)="toggleSelection_RepeatOn(chipItem)">
                          {{option}}
                        </mat-chip>
                      </mat-chip-list>

                    </div>
                  </div>

                  <div class="row paddingTop">
                    <label class="col-4 required-field noPaddingLeftRight mbsc-windows mbsc-ltr mbsc-label mbsc-label-stacked mbsc-label-box-stacked">Ends</label>
                    <div class="col-8">
                      <mat-radio-group aria-label="Select an option" [(ngModel)]="selectedRecurrenceEnd" [disabled]="!recurrenceEnabled">
                        <div class="row">
                          <mat-radio-button #radiobuttonNever="matRadioButton" class="col-4 noPaddingLeftRight flexClass" value="NEVER">Never</mat-radio-button>
                        </div>
                        <div class="row">
                          <mat-radio-button #radiobuttonOn="matRadioButton" class="col-4 noPaddingLeftRight flexClass" value="ON">On</mat-radio-button>
                          <mat-form-field class="col-8 noPaddingLeftRight" appearance="outline">
                            <mat-label>Choose a date</mat-label>
                            <input matInput [(ngModel)]="recurrenceEndDate" [matDatepicker]="picker" [disabled]="!recurrenceEnabled || !radiobuttonOn.checked">
                            <mat-datepicker-toggle matSuffix [for]="picker" [disabled]="!recurrenceEnabled || !radiobuttonOn.checked"></mat-datepicker-toggle>
                            <mat-datepicker #picker></mat-datepicker>
                          </mat-form-field>
                        </div>
                        <div class="row">
                          <mat-radio-button #radiobuttonAfter="matRadioButton" class="col-4 noPaddingLeftRight flexClass" value="AFTER">After</mat-radio-button>
                          <input [(ngModel)]="numOccurrences" type="number" class="col-4 form-control form-control-sm" appStaticEdit [editable]="recurrenceEnabled && radiobuttonAfter.checked " />
                          <label class="col-4">ocurrence/s</label>
                        </div>
                      </mat-radio-group>
                    </div>
                  </div>
                </div>
              </mat-expansion-panel>
            </mat-accordion>
          </div>
          <div class="floatLeftClass widthClass">
            <mbsc-textarea label="Notes" placeholder="Notes" [(ngModel)]="shiftNotes"></mbsc-textarea>
          </div>
          <div class="margin16" *ngIf="editing">
            <mbsc-button class="mbsc-button-block" color="danger" variant="outline" (click)="onDeleteClick()">Delete shift</mbsc-button>
          </div>
        </mbsc-popup>

      </div>
    </div>
  </div>

</div>

