import { VirtualDocument } from "../../common/resources/virtual-document";
import { Product, ProductStandardHistory } from "../../order/resources/product";
import { Customer, CustomerContact } from "../../customer/resources/customer";
import { User } from "../../common/resources/user";
import { Address } from "../../common/resources/address";
import { RMADispositionLot, RMATicket } from "../../rma/resources/rma-ticket";
import { CustomerPurchaseOrder } from "./customer-po";
import { WorkOrder } from "../../planning/resources/work-order";
import { Station } from "./station";
import { Company } from "../../common/resources/company";
import { TaskList } from "../../common/resources/estimatingtask";


export interface QuoteLineItem {
  lineItemNumber?: string;
  quoteLineItemId: string;
  quoteId: string;
  quantity: number
  leadTimeDays: number
  unitPrice: number
  extPrice: number
  productId: string
  product: Product
  stationId: string
  station: Station
}

export interface Order {
  orderSegmentId: string;
  quoteNotes: string;
  privateNotes: string;
  publicNotes: string;
  estimateNotes: string;
  statusNote: string;
  createdDate?: Date;
  receivedDate?: Date;
  requiredDate?: Date;
  estimatedEstimateCompletionDate?: Date;
  issuedDate?: Date;
  legacyId: string;
  customerId: string;
  status: number;
  updatedDate?: Date;
  modifiedBy?: string;
  customerContactId?: string;
  orderNumber: string;
  customerRfqnumber: string;
  discriminator: string;
  poNumber: string;
  projectDescription: string;
  assignedTo: string;
  assignedUser: User;
  companyId: string;
  company: Company;

  shipToAddressId?: string;
  shipToAddress: Address;

  rmaTicketId?: string;
  rmaTicket: RMATicket;

  rmaDispositionLotId?: string;
  rmaDispositionLot: RMADispositionLot;

  lineItems: QuoteLineItem[];

  quoteNumber: string;
  estimateNumber: string;

  shippingAssignments: {
    [key: string]: {
      quantity: number,
      shipDate: Date
    }[]
  }

  quotePurchaseOrders: CustomerPurchaseOrder[];

  orderPurchaseOrderId?: string;
  orderPurchaseOrder?: CustomerPurchaseOrder;

  workOrders: WorkOrder[];

  customer?: Customer;
  customerContact?: CustomerContact;
  products?: OrderProduct[];
  documents?: OrderDocument[]

  rfqReviewer?: User;
  dateRfqReviewed?: Date;


  salesProcessId?: string;

  estimatingTaskList?: TaskList;

  creatorId?: string;
  creator: User;
  
  salesPersonId?: string;
  salesPerson?: User;

  estimatorId?: string;
  estimator?: User;

  estimatePromiseDateNotes: string;
  
}

export class OrderStatus {
  static IN_PROCESS: number = 0;
  static AWAITING_REVIEW: number = 1;
  static HISTORY: number = 2;
  static REJECTED: number = 3;
  static APPROVED: number = 4;
  static TENT_APPROVED: number = 5;
  static AWAIT_ESTIMATE: number = 6;
  static AWAIT_PRICING: number = 7;
  static AWAIT_PO: number = 8;
  static ON_FLOOR: number = 9;
  static SHIPPING: number = 10;
  static FULFILLED: number = 11;
  static EXPEDITED: number = 12;
  static QUEUED: number = 13;
  static ON_HOLD: number = 14;
  static UNASSIGNED: number = 15;
  static QC_HOLD: number = 16;
  static EXPIRED: number = 17;
  static PRE_PLAN: number = 18;
  static CLOSED: number = 19;
  static AWAIT_ISSUANCE: number = 20;
  static PREPLANNING: number = 21;
  static AWAIT_PROGRAMMING: number = 22;
  static PREREQUISITE_MISSING = 23;
  static PRIOR_STEP_PREREQUISITE_MISSING = 24;
  static PLANNED = 25;
  static ON_ORDER = 99;
  static PO_AWARDED = 100;

  static getStatusColorClass(status: number): string {
    switch (status) {
      case OrderStatus.IN_PROCESS: return 'badge-warning';
      case OrderStatus.AWAITING_REVIEW: return 'badge-info';
      case OrderStatus.HISTORY: return 'badge-secondary';
      case OrderStatus.REJECTED: return 'badge-danger';
      case OrderStatus.APPROVED: return 'badge-success';
      case OrderStatus.TENT_APPROVED: return 'badge-success';
      case OrderStatus.AWAIT_PRICING: return 'badge-info';
      case OrderStatus.AWAIT_ESTIMATE: return 'badge-secondary';
      case OrderStatus.AWAIT_PO: return 'badge-info';
      case OrderStatus.ON_FLOOR: return 'badge-success';
      case OrderStatus.SHIPPING: return 'badge-info';
      case OrderStatus.FULFILLED: return 'badge-success';
      case OrderStatus.EXPEDITED: return 'badge-danger';
      case OrderStatus.QUEUED: return 'badge-secondary';
      case OrderStatus.ON_HOLD: return 'badge-secondary';
      case OrderStatus.UNASSIGNED: return 'badge-danger';
      case OrderStatus.QC_HOLD: return 'badge-secondary';
      case OrderStatus.EXPIRED: return 'badge-danger';
      case OrderStatus.CLOSED: return 'badge-secondary';
      case OrderStatus.AWAIT_ISSUANCE: return 'badge-warning';
      case OrderStatus.PREPLANNING: return 'badge-warning';
      case OrderStatus.PLANNED: return 'badge-success';
      case OrderStatus.PO_AWARDED: return 'badge-success';
      default: return null;
    }
  }

  static getStatusColorClassChip(status: number): string {
    switch (status) {
      case OrderStatus.IN_PROCESS: return 'bg-warning text-dark';
      case OrderStatus.AWAITING_REVIEW: return 'bg-info text-white';
      case OrderStatus.HISTORY: return 'bg-secondary text-dark';
      case OrderStatus.REJECTED: return 'bg-danger text-white';
      case OrderStatus.APPROVED: return 'bg-success text-white';
      case OrderStatus.TENT_APPROVED: return 'bg-success text-white';
      case OrderStatus.AWAIT_PRICING: return 'bg-info text-white';
      case OrderStatus.AWAIT_ESTIMATE: return 'bg-secondary text-dark';
      case OrderStatus.AWAIT_PO: return 'bg-info text-white';
      case OrderStatus.ON_FLOOR: return 'bg-success text-white';
      case OrderStatus.SHIPPING: return 'bg-info text-white';
      case OrderStatus.FULFILLED: return 'bg-success text-white';
      case OrderStatus.EXPEDITED: return 'bg-danger text-white';
      case OrderStatus.QUEUED: return 'bg-secondary text-dark';
      case OrderStatus.ON_HOLD: return 'bg-secondary text-dark';
      case OrderStatus.UNASSIGNED: return 'bg-danger text-white';
      case OrderStatus.QC_HOLD: return 'bg-secondary text-dark';
      case OrderStatus.EXPIRED: return 'bg-danger text-dark';
      case OrderStatus.CLOSED: return 'bg-secondary text-dark';
      case OrderStatus.AWAIT_ISSUANCE: return 'bg-warning text-dark';
      case OrderStatus.PREPLANNING: return 'bg-warning text-dark';
      case OrderStatus.PO_AWARDED: return 'bg-success text-white';
      case OrderStatus.ON_ORDER: return 'bg-info text-white';
      default: return null;
    }
  }

  static getStatusColorCode(status: number): string {
    switch (status) {
      case OrderStatus.IN_PROCESS: return '#28B463';
      case OrderStatus.AWAITING_REVIEW: return '#2980B9';
      case OrderStatus.HISTORY: return '#616A6B';
      case OrderStatus.REJECTED: return '#E74C3C';
      case OrderStatus.APPROVED: return '#28B463';
      case OrderStatus.TENT_APPROVED: return '#28B463';
      case OrderStatus.AWAIT_PRICING: return '#2980B9';
      case OrderStatus.AWAIT_ESTIMATE: return '#616A6B';
      case OrderStatus.AWAIT_PO: return 'badge-info';
      case OrderStatus.ON_FLOOR: return '#28B463';
      case OrderStatus.SHIPPING: return '#28B463';
      case OrderStatus.FULFILLED: return '#28B463';
      case OrderStatus.EXPEDITED: return '#E74C3C';
      case OrderStatus.QUEUED: return '#616A6B';
      case OrderStatus.ON_HOLD: return '#616A6B';
      case OrderStatus.UNASSIGNED: return '#E74C3C';
      case OrderStatus.QC_HOLD: return '#616A6B';
      case OrderStatus.EXPIRED: return '#E74C3C';
      case OrderStatus.CLOSED: return '#616A6B';
      case OrderStatus.AWAIT_ISSUANCE: return '#28B463';
      case OrderStatus.PREPLANNING: return '#28B463';
      case OrderStatus.PO_AWARDED: return '#28B463';
      default: return null;
    }
  }

  static getStatusText(status: number): string {
    switch (status) {
      case OrderStatus.IN_PROCESS: return 'In Process';
      case OrderStatus.AWAITING_REVIEW: return 'Awaiting Review';
      case OrderStatus.HISTORY: return 'History';
      case OrderStatus.REJECTED: return 'Rejected';
      case OrderStatus.APPROVED: return 'Approved';
      case OrderStatus.TENT_APPROVED: return 'Tentative Approval';
      case OrderStatus.AWAIT_PRICING: return 'Awaiting Pricing';
      case OrderStatus.AWAIT_ESTIMATE: return 'Awaiting Estimate';
      case OrderStatus.AWAIT_PO: return 'Ready for POs';
      case OrderStatus.ON_FLOOR: return 'On Floor';
      case OrderStatus.SHIPPING: return 'Shipping';
      case OrderStatus.FULFILLED: return 'Fulfilled';
      case OrderStatus.EXPEDITED: return 'Expedited';
      case OrderStatus.QUEUED: return 'Queued';
      case OrderStatus.ON_HOLD: return 'On Hold';
      case OrderStatus.UNASSIGNED: return 'Unassigned';
      case OrderStatus.QC_HOLD: return 'QC Hold';
      case OrderStatus.EXPIRED: return 'Expired';
      case OrderStatus.CLOSED: return 'Closed';
      case OrderStatus.AWAIT_ISSUANCE: return 'Not Issued';
      case OrderStatus.PREPLANNING: return 'Preplanning';
      case OrderStatus.PLANNED: return 'Planned';
      case OrderStatus.ON_ORDER: return 'On Order';
      case OrderStatus.PO_AWARDED: return 'PO Awarded';
      default: return null;
    }
  }

  static isEditable(status: number): boolean {
    if (status == null) return true; //New one...

    switch (status) {
      case OrderStatus.UNASSIGNED:
      case OrderStatus.IN_PROCESS:
      case OrderStatus.TENT_APPROVED:
      case OrderStatus.AWAITING_REVIEW:
      case OrderStatus.AWAIT_PRICING:
      case OrderStatus.REJECTED:
        return true;
      default: return false;
    }
  }
}

export interface OrderDocument {
  orderSegmentOrderSegmentId: string;
  documentDocumentId: string;

  document?: VirtualDocument
}

export enum OrderSegmentProductType {
  NEW = 0,
  CLONED = 1,
  CLONED_NEWREV = 2
}

export enum OrderSegmentProductReviewStatus {
  NotReviewed = 0,
  Incomplete = 1,
  Complete = 2,
  Corrected = 3
}

export interface OrderProduct {
  orderSegmentOrderSegmentId: string;
  productProductId: string;

  product?: Product;
  sort?: number;
  type?: OrderSegmentProductType;
  history?: ProductStandardHistory;

  reviewStatus: OrderSegmentProductReviewStatus;
  reviewStatusNote: string;
}
