import { Component, OnInit, Input } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { NavigationService } from '../../../common/services/navigation.service';
import { ShippingService } from '../../services/shipping.service';
import { ShippingTicket, ShippingTicketStatus } from '../../resources/shipping-ticket';
import { UtilityService } from '../../../common/services/utility.service';

@Component({
  selector: 'shipping-split-view',
  templateUrl: './shipping-split-view.component.html',
  styleUrls: ['./shipping-split-view.component.less']
})
export class ShippingSplitViewComponent implements OnInit {
  @Input() ticketId: string;
  @Input() workOrderId: string;
  @Input() sidenav: MatSidenav;

  public records: ShippingTicket[];
  public saving: boolean = false;

  constructor(private shippingService: ShippingService, navSvc: NavigationService) {
    navSvc.pushBreadcrumb("Split Ticket View");
  }

  public openTicket(ticket: ShippingTicket): void {
    window.open(`/shipping/ticket/${ticket.shippingTicketId}`, '_blank');
  }

  public get totalQuantityForWorkOrder(): number {
    if (!this.records) return 0;

    return this.records.reduce((p, c, i) => p += c.quantityRequired, 0);
  }

  public getTicketNumber(ticket: ShippingTicket) {
    return ShippingService.getTicketNumber(ticket);
  }

  public getStatusColorClass(status: ShippingTicketStatus): string {
    return ShippingService.getStatusColorClass(status)
  }

  public getStatusText(status: ShippingTicketStatus) {
    return ShippingService.getStatusText(status)
  }

  public getRequiredColor(date: string): string {
    return UtilityService.getDateRequiredColor(date);
  }

  public weeksLeft(date: string, abs: boolean): number {
    if (date == null) return null;

    return UtilityService.getWeeksRemaining(new Date(date), abs);
  }

  private getData(): void {
    this.records = null;

    this.shippingService.getTicketsForFulfillment(this.workOrderId).subscribe(results => {
      this.records = results;
    });
  }

  public done(): void {
    this.sidenav.close();
  }

  ngOnInit() {
    this.getData();
  }
}
