import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Station } from '../../../order/resources/station';
import { WorkflowStep } from '../../../order/resources/workflow';
import { StationService } from '../../../order/services/station.service';
import { WorkflowStepProgress } from '../../../planning/resources/workflow-step-progress';
import { WorkOrderService } from '../../../planning/services/work-order.service';

@Component({
  selector: 'workflow-progress',
  templateUrl: './workflow-progress.component.html',
  styleUrls: ['./workflow-progress.component.less']
})
export class WorkflowProgressComponent implements OnInit {

  public id: string;
  public workflowProgress: WorkflowStepProgress[] = [];
  private stationList: Station[] = null;

  constructor(
    private workOrderService: WorkOrderService,
    private stationService: StationService,
    private route: ActivatedRoute,
  ) {
    this.id = this.route.snapshot.paramMap.get("id");
  }

  public getStation(item: WorkflowStep): Station {
    if (this.stationList == null || item == null || item.stationId == null)
      return null;

    return this.stationList.find(r => r.stationId == item.stationId);
  }

  public getStatus(item: WorkflowStepProgress): string {
    if (!item.machineAssignmentId) return 'Unassigned';
    else if (item.machineAssignment.status == 11) return 'Complete';
    else return 'Assigned';
  }

  async ngOnInit() {
    if (this.stationService.loaded) {
      this.stationList = this.stationService.stationList;
    }
    else {
      this.stationService.stationsLoaded.subscribe(
        _ => this.stationList = this.stationService.stationList
      );
    }
    this.workflowProgress = await this.workOrderService.getWorkOrderProgress(this.id).toPromise();
  }

}
