<div class="container-fluid">
  <a class="position-fixed fixed-bottom btn btn-badge" title="Create New Record" routerLink="/supplier/new">+</a>

  <div class="row border">
    <search-bar class="w-100" [searchString]="searchString" (search)="doSearch($event)"></search-bar>

    <div *ngIf="!results"><loading></loading></div>

    <search-result *ngIf="results" [results]="searchResults" class="w-100" (newPage)="getPage($event)" (selected)="onSelect($event)">
      <thead header-template>
        <tr class="text-uppercase text-muted">
          <th (click)="sortBy('name')">
            Vendor Name
            <i [ngClass]="getSortIconFor('name')"></i>
          </th>
          <th class="text-center" (click)="sortBy('score')">
            Overall Score
            <i [ngClass]="getSortIconFor('score')"></i>
          </th>
          <th (click)="sortBy('certified')">
            Certified Thru
            <i [ngClass]="getSortIconFor('certified')"></i>
          </th>
          <th>
            Supplied Materials
          </th>
          <th>
            Supplied Processes
          </th>
        </tr>
      </thead>
      <ng-template let-row>
        <td>{{row.name}}</td>
        <td class="text-center">
          <span [title]="row.overallScore" class="score badge badge-pill badge-light" [ngStyle]="getScoreStyle(row.overallScore)">&nbsp;</span>
        </td>
        <td>
          <span [ngClass]="getStatusColorClass(row.certificationDate)">{{row.certificationDate | date}}</span>
          <span *ngIf="row.certificationDate==null" class="text-muted">---</span>
        </td>
        <td>
          <span class="badge badge-pill badge-secondary mr-1" *ngFor="let m of row.vendorMaterialGroups">
            {{m.materialGroup.groupName}}
          </span>
          <span *ngIf="row.vendorMaterialGroups?.length==0" class="font-italic text-muted">-- None --</span>
        </td>
        <td>
          <span class="badge badge-pill badge-secondary mr-1" *ngFor="let m of row.vendorServiceOfferings">
            {{m.station.name}}
          </span>
          <span *ngIf="row.vendorMaterialGroups?.length==0" class="font-italic text-muted">-- None --</span>
        </td>
      </ng-template>
    </search-result>
  </div>

</div>
