import { Component, Input, EventEmitter, Output, SimpleChanges, OnInit, OnChanges } from '@angular/core';
import { Product } from '../../resources/product';
import { FloorService } from '../../../floor/services/floor.service';
import { MachineAssignment } from '../../../planning/resources/machine-assignment';
import { StationService } from '../../services/station.service';
import { Station } from '../../resources/station';
import { OrderStatus } from '../../resources/order';

@Component({
  selector: 'product-waterfall',
  templateUrl: './product-waterfall.component.html',
  styleUrls: ['./product-waterfall.component.less']
})
export class ProductWaterfallComponent implements OnInit, OnChanges {
  @Input() product: Product;
  @Input() orderId: string;
  @Output() onClick: EventEmitter<any> = new EventEmitter<any>();

  private chartRawData: MachineAssignment[];
  public showChart: boolean = false;
  public chartData: any[];
  public chartLabels: string[];
  public chartOptions: any;
  public chartPlugins: any[];
  public additionalData: any[] = [];

  constructor(private floorSvc: FloorService, private stationSvc: StationService) { }

  public bubbleClick(e: any) {
    if (this.onClick)
      this.onClick.emit(e);
  }

  public refresh(): void {
    this.setupChart();
  }

  public get rawData(): any[] {
    return this.chartRawData;
  }

  private showEvents(chart: any): void {
    var self = this;
    const xAxisWidth = chart.scales['x-axis-0'].right - chart.scales['x-axis-0'].left;

    var drawLineAt = function (date: Date, color: string, label: string) {
      var lineLeftOffset = chart.scales['x-axis-0'].left + (((date.valueOf() - self.minDate.valueOf()) / chart.boxes.find(b => b.id == 'x-axis-0').end) * xAxisWidth);

      chart.ctx.beginPath();
      chart.ctx.strokeStyle = color;
      chart.ctx.moveTo(lineLeftOffset, chart.scales['y-axis-0'].top + (label ? 25 : 0));
      chart.ctx.lineTo(lineLeftOffset, chart.scales['y-axis-0'].bottom);
      chart.ctx.stroke();

      if (label) {
        chart.ctx.font = "normal 14px Arial Narrow";
        chart.ctx.fillStyle = color;
        chart.ctx.textAlign = "center";
        chart.ctx.fillText(label, lineLeftOffset, 25);
      }
    };

    if (this.product && this.product.dateRequired)
      drawLineAt(new Date(this.product.dateRequired), '#dc3545', 'Required');

    drawLineAt(new Date(), '#6c757d', 'Now');
  }

  private shadePast(chart: any): void {
    const xAxisWidth = chart.scales['x-axis-0'].right - chart.scales['x-axis-0'].left;
    const date: Date = new Date();

    var shadedRightBounds = ((date.valueOf() - this.minDate.valueOf()) / chart.boxes.find(b => b.id == 'x-axis-0').end) * xAxisWidth;

    chart.ctx.beginPath();
    chart.ctx.fillStyle = '#6c757d04';
    chart.ctx.fillRect(chart.scales['x-axis-0'].left, chart.scales['y-axis-0'].top, shadedRightBounds, chart.scales['y-axis-0'].bottom - 5);
  }

  private setupChart(): void {
    var self = this;

    if (this.orderId == null) return;

    this.chartOptions = {
      responsive: true,
      legend: {
        display: false
      },
      hover: {
        onHover: function (e, el) {
          var section = el[0];
          e.currentTarget.style.cursor = (section && section._model.backgroundColor != 'transparent') ? 'pointer' : 'default';
        }
      },
      tooltips: {
        mode: 'nearest',
        intersect: true,
        callbacks: {
          label: function (tooltipItem, data) {
            return data.datasets[tooltipItem.datasetIndex].label + ': ' + (Number(tooltipItem.xLabel) / (1000 * 60 * 60)).toFixed(2) + ' hrs';
          }
        }

      },
      aspectRatio: 4,
      scales: {
        xAxes: [{
          stacked: true,
          ticks: {
            suggestedMin: 0,
            scaleStepWidth: 150,
            callback: function (v, i, vs) {
              if (Math.max(...vs) / (60 * 60 * 1000) < 48)
                return new Date(v + self.minDate.valueOf()).toLocaleString();

              return new Date(v + self.minDate.valueOf()).toDateString();
            }
          }
        }],
        yAxes: [{ stacked: true }]
      }
    };

    this.chartPlugins = [{
      afterDatasetDraw: function (chart, _options) {
        self.showEvents(chart);
      },
      beforeDatasetDraw: function (chart, _options) {
        self.shadePast(chart);
      },
      beforeDraw: function (chart, _options) {
        var active = chart.tooltip._active || [];

        if (active.length > 0) {
          if (active[0]._model.start == 'transparent' || active[0]._model.backgroundColor == 'rgba(0, 0, 0, 0)') {
            // Opacity of 0 causes the tooltip draw method to do nothing
            chart.tooltip._model.opacity = 0;
          }
        }
      }
    }];

    if (this.product) {
      this.floorSvc.getProductAssignments(this.product.productId, this.orderId).subscribe(result => {

        if (result == null) {
          this.showChart = false;
          return;
        }
        else
          this.showChart = true;

        this.chartRawData = result;

        this.generateLabels();
        this.parseData();
      });
    }
  }

  private getStation(stationId: string): Station {
    if (!this.stationSvc.loaded) return null;

    return this.stationSvc.stationList.find(s => s.stationId == stationId);
  }

  private generateLabels(): void {
    this.chartLabels = this.chartRawData.map(point => {
      var s: Station = this.getStation(point.operation.stationId);
      if (s == null) return '';

      return s.name;
    });
  }

  public get minDate(): Date {
    if (!this.chartRawData) return new Date();

    return new Date(Math.min.apply(null, this.chartRawData.map(p => new Date(p.scheduledStart).valueOf())));
  }

  public get maxDate(): Date {
    if (!this.chartRawData) return new Date();

    return new Date(Math.max.apply(null, this.chartRawData.map(p => new Date(p.scheduledEnd).valueOf())));
  }

  private mergeData(): any[] {
    var toBeAdded: any[] = JSON.parse(JSON.stringify(this.additionalData));
    if (toBeAdded && toBeAdded.length > 0) {
      var toRemove: number[] = [];
      for (var i in toBeAdded) {
        var overlap = this.chartRawData.findIndex(a => a.assignmentId == toBeAdded[i].assignmentId);
        if (overlap >= 0) {
          this.chartRawData[overlap] = toBeAdded[i];
          toRemove.push(Number(i));
        }
      }

      toRemove = toRemove.sort().reverse();
      for (var i in toRemove) {
        toBeAdded.splice(Number(i), 1);
      }

    }

    return this.chartRawData.concat(toBeAdded);
  }

  private parseData(): void {
    this.chartData = [];
    var data = [];

    var allData = this.mergeData();

    if (this.stationSvc.loaded) {
      for (var i in allData) {
        var assignment = allData[i];

        data.push({
          label: '',
          backgroundColor: 'transparent',
          borderColor: 'transparent',
          borderWidth: 0,
          borderSkipped: false,
          data: this.getTransparencyValues(assignment, parseInt(i))
        });

        data.push({
          label: `${assignment.machine ? assignment.machine.name : '(No Machine)'} / ${(assignment.user ? assignment.user.fullName : '(No Operator)')} (${OrderStatus.getStatusText(assignment.status)})`,
          backgroundColor: assignment.backgroundColor || this.getColor(assignment),
          borderColor: assignment.borderColor || 'transparent',
          borderWidth: assignment.borderColor ? 1 : 0,
          borderSkipped: false,
          data: this.getPointData(assignment, parseInt(i)),
          assignmentId: assignment.assignmentId
        });
      }
    }

    this.chartData = data;
  }

  private getColor(assignment: any): string {
    var barColor = OrderStatus.getStatusColorCode(assignment.status);

    //TODO: Past? Overdue?

    if (assignment.machineId == null)
      barColor = barColor + '99';

    return barColor;
  }

  private getTransparencyValues(value: any, index: number): number[] {
    var data: number[] = new Array(index).fill(null);
    data[index] = new Date(value.scheduledStart).valueOf() - this.minDate.valueOf();

    return data;
  }

  private getPointData(value: any, index: number): number[] {
    var data: number[] = new Array(index).fill(null);

    data[index] = (value.operation.runTime || 0) * (value.operation.runIsPerPart ? 1 : 60) * (60 * 1000);

    return data;
  }

  ngOnChanges(_: SimpleChanges): void {
    this.setupChart();
  }

  ngOnInit(): void {
    this.setupChart();
  }
}
