<div>
  <div class="row">

    <mat-sidenav-container>
      <mat-sidenav disableClose (closedStart)="closeSideNav()" #sidenav mode="over" position="end">
        <machine-assignment-detail [record]="selectedAssignment" *ngIf="showEditor=='assignment'" [sidenav]="sidenav"></machine-assignment-detail>
      </mat-sidenav>
      <mat-sidenav-content>

        <div class="row">
          <div class="col-12 text-right bg-secondary">
            <div class="btn-group m-2">
              <a class="btn btn-light btn-sm dropdown-toggle" href="#" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                Overview
              </a>
              <div class="dropdown-menu" aria-labelledby="dropdownMenuLink">
                <a class="dropdown-item active text-white" (click)="(0)">Overview</a>
                <div class="dropdown-divider"></div>
                <p class="text-center text-muted" *ngIf="!departments">
                  <i class="fas fa-circle-notch fa-spin fa-fw text-primary"></i>
                  Loading...
                </p>
                <p class="text-center text-muted font-italic" *ngIf="departments && departments.length==0">
                  <i class="fas fa-exclamation-triangle fa-fw text-danger"></i>
                  <small>No Buildings Found!</small>
                </p>
                <a *ngFor="let department of departments" href="#" class="dropdown-item" (click)="showBuilding(department)">{{department.name}}</a>
              </div>
            </div>
          </div>
        </div>

        <div class="container-fluid mt-4">
          <div class="row">
            <div class="col-5">
              <widget-area name="Dashboard" id="floor-dashboard"></widget-area>
            </div>
            <div class="col-7">
              <h5 class="w-100 text-center p-2 bg-secondary">Alerts</h5>
              <div class="p-1 small">
                <alert-list></alert-list>
              </div>
            </div>
          </div>
          <div class="row mt-4">
            <div class="col-12">
              <h5 class="w-100 text-center p-2 bg-secondary">This Week</h5>
              <div class="p-1">
                <week-status-list (selected)="openAssignment($event)"></week-status-list>
              </div>
            </div>
          </div>
        </div>

      </mat-sidenav-content>
    </mat-sidenav-container>
  </div>
</div>
