<div>
  <loading *ngIf="!vendors"></loading>

  <div class="p-0 container-fluid" *ngIf="vendors">
    <mat-sidenav-container class="p-0 m-0">
      <mat-sidenav disableClose (closedStart)="closeSideNav()" #insetnav mode="over" position="end">
        <quote-detail *ngIf="bidDetailId!=null" [customer]="order?.customer" [sidenav]="insetnav" [id]="bidDetailId"></quote-detail>
      </mat-sidenav>
      <mat-sidenav-content>
        <!-- TODO: need to give "global" quantity of what's being ordered, give operator info on how much needs ordered, etc.' -->
        <div *ngFor="let vendor of vendors" class="m-4 p-0 border">
          <div class="p-2 border">
            <h4 class="display-inline text-primary">{{vendor.name}}</h4>
            <span title="Overall Score" class="badge badge-pill badge-light border p-2 ml-4">{{vendor.overallScore}}</span>
            <span title="On Time Score" class="badge badge-pill badge-light border p-2 ml-2">{{vendor.onTimeScore}}</span>
            <span title="Quality Score" class="badge badge-pill badge-light border p-2 ml-2">{{vendor.qualityScore}}</span>
            <span title="Subjective Score" class="badge badge-pill badge-light border p-2 ml-2">{{vendor.subjectiveScore}}</span>
            <button class="float-right btn btn-primary" (click)="sendToVendor(vendor)" *ngIf="canSend(vendor.vendorId)">Mark as Sent</button>
            <button class="float-right btn btn-success" (click)="goToQuote(vendor)" *ngIf="isSent(vendor.vendorId)">Open Quote</button>
            <i class="float-right fas fa-2x fa-fw fa-circle-notch fa-spin text-info" *ngIf="isSending(vendor.vendorId)"></i>
          </div>
          <div class="container-fluid p-0 m-0 row">
            <div class="col-8 border p-2">
              <div class="container-fluid row">
                <div class="col-6" *ngIf="vendor.primaryAddress">
                  <span class="font-weight-bold">Primary Address</span>
                  <div class="small">
                    <span class="d-block">{{vendor.primaryAddress?.streetAddress}}</span>
                    <span class="d-block">{{vendor.primaryAddress?.city}}, {{vendor.primaryAddress?.state}} {{vendor.primaryAddress?.postalCode}}</span>
                  </div>
                </div>
                <div class="col-6">
                </div>
              </div>
            </div>
            <div class="col-4 border p-0">
              <h5 class="text-center p-2 bg-secondary">Quote Contacts</h5>
              <table class="table table-sm small table-striped">
                <tbody>
                  <tr *ngFor="let contact of vendor.vendorContacts | callback: isQuoteContact">
                    <td>{{contact.name}}</td>
                    <td>
                      <a target="_blank" [href]="'mailto:'+contact.email">{{contact.email}}</a>
                      <span *ngIf="contact.email==null||contact.email.length==0" class="d-block font-italic text-muted text-center">-- No Email Address --</span>
                    </td>
                  </tr>
                  <tr *ngIf="(vendor.vendorContacts | callback: isQuoteContact).length==0">
                    <td colspan="100%" class="font-italic text-muted text-center">
                      -- No Quoting Contacts Entered --
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <div class="position-absolute buttons">
          <button class="btn btn-success mr-2" (click)="exit()">
            <i class="fas fa-check fa-lg fa-fw"></i>
            Done
          </button>
        </div>
      </mat-sidenav-content>
    </mat-sidenav-container>
  </div>
</div>
