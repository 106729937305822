<h2 mat-dialog-title>{{data.title}}</h2>
<mat-dialog-content class="mat-typography" [innerHTML]="data.detail">
</mat-dialog-content>
<mat-dialog-actions align="end">
  <div class="text-muted small font-italic d-inline-flex mr-1" *ngIf="readTimeLeft > 0">
    Wait {{ readTimeLeft }} more seconds...
  </div>
  <button class="btn btn-default btn-sm mr-3 border" [disabled]="readTimeLeft > 0" [mat-dialog-close]="true">Okay</button>
  <button class="btn btn-primary btn-sm" [mat-dialog-close]="false">Cancel</button>
</mat-dialog-actions>
