import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { NavigationService } from '../../../common/services/navigation.service';
import { SearchList } from '../../../common/components/search-list/search-list';
import { WorkOrderService } from '../../services/work-order.service';
import { UtilityService } from '../../../common/services/utility.service';
import { OrderStatus } from '../../../order/resources/order';
import { UserService } from '../../../common/services/user.service';
import { ProgrammingTicket } from '../../resources/programming-ticket';
import { StationService } from '../../../order/services/station.service';
import { Station } from '../../../order/resources/station';

@Component({
  selector: 'programming-list',
  templateUrl: './programming-list.component.html',
  styleUrls: ['./programming-list.component.less']
})
export class ProgrammingListComponent extends SearchList<ProgrammingTicket> {
  private stationList: Station[] = null;

  constructor( private stationService: StationService, navService: NavigationService, private service: WorkOrderService, private router: Router, private userSvc: UserService) {
    super('programming');
    navService.setPageTitle("Programming");

    if (this.stationService.loaded) {
      this.stationList = this.stationService.stationList;
    }
    else {
      this.stationService.stationsLoaded.subscribe(
        _ => this.stationList = this.stationService.stationList
      );
    }
  }

  search(): void {
    this.results = null;
    this.service.programmingSearch(this.searchString, this.pageNumber, this.sortTerm, this.sortDirection).subscribe(results => {
      this.searchResults = results;
      this.results = results.results
    });
  }

  onSelect(record: ProgrammingTicket): void {
    this.router.navigate(['/programming/', (<ProgrammingTicket>record).programmingTicketId]);
  }

  weeksLeft(date: string, abs: boolean): number {
    if (date == null) return null;

    return UtilityService.getWeeksRemaining(new Date(date), abs);
  }

  getRequiredColor(date: string): string {
    return UtilityService.getDateRequiredColor(date);
  }

  getStatusColorClass(status: number): string {
    return OrderStatus.getStatusColorClass(status);
  }

  getStatusText(status: number): string {
    return OrderStatus.getStatusText(status);
  }

  public hasPermission(moduleName: string): boolean {
    return this.userSvc.canAccess(moduleName);
  }

  public getStationName(stationId: string): string {
    return this.stationList.find(s => s.stationId == stationId).name;
  }


}
