<h2 mat-dialog-title>Are You Finished?</h2>
<mat-dialog-content class="mat-typography">
  <p>Is this estimate ready for review, or is there still more to be done?</p>
  <p class="m-3">
    <mat-radio-group aria-label="Select an option">
      <mat-radio-button value="1" #ready >It's Ready for Review</mat-radio-button>
      <mat-radio-button value="2" #needswork class="ml-3">Still Needs More Work</mat-radio-button>
    </mat-radio-group>
  </p>
  <div *ngIf="ready.checked && data.incomplete?.length > 0">
    <b class="d-block text-danger">The estimate cannot be sent for review as the following parts still have some sections not marked as done:</b>
    <ul>
      <li *ngFor="let item of data.incomplete">
        <b>{{ item.productName }}</b>: {{ item.missing }}
      </li>
    </ul>
  </div>
  <div *ngIf="ready.checked && data.unresolvedReviews?.length > 0">
    <b class="d-block text-danger">The estimate cannot be sent for review as the following line items still have unresolved review issues:</b>
    <ul>
      <li *ngFor="let item of data.unresolvedReviews">
        <b>{{ item.product.partNumber }} Rev. {{ item.product.revision }}</b>
      </li>
    </ul>
  </div>
  <p [hidden]="!needswork.checked">
    <textarea class="form-control form-control-sm" #note placeholder="Please tell us what still needs to be done..."></textarea>
  </p>
</mat-dialog-content>
<mat-dialog-actions align="end" *ngIf="needswork.checked || ready.checked">
  <button class="btn btn-default btn-sm mr-3" [mat-dialog-close]="{ready: ready.checked, note: note.value}"
  [disabled]="ready.checked && data.incomplete?.length > 0"
  >Okay</button>
  <button class="btn btn-primary btn-sm" [mat-dialog-close]="null">Cancel</button>
</mat-dialog-actions>
