<div class="container-fluid">
  <a class="position-fixed fixed-bottom btn btn-badge" title="Create New Record" routerLink="/order/new">+</a>

  <div class="row border">
    <search-bar class="w-100" [showManagerBar]="false" [(searchString)]="searchString" (search)="doSearch($event)"></search-bar>

    <div *ngIf="!results"><loading></loading></div>

    <search-result *ngIf="results" [results]="searchResults" class="w-100" (newPage)="getPage($event)" (selected)="onSelect($event)">
      <thead header-template>
          <tr class="text-uppercase text-muted">
            <th (click)="sortBy('number')">
              Work Order
              <i [ngClass]="getSortIconFor('number')"></i>
            </th>
            <th (click)="sortBy('customer')">
              Customer
              <i [ngClass]="getSortIconFor('customer')"></i>
            </th>
            <th (click)="sortBy('assigned')">
              Assigned
              <i [ngClass]="getSortIconFor('assigned')"></i>
            </th>
            <th (click)="sortBy('required')">
              Required
              <i [ngClass]="getSortIconFor('required')"></i>
            </th>
            <th (click)="sortBy('status')">
              Status
              <i [ngClass]="getSortIconFor('status')"></i>
            </th>
          </tr>
      </thead>
      <ng-template let-row>
          <td>{{row.workOrder?.workOrderNumber || '--'}}</td>
          <td>{{row.workOrder?.order?.customer?.businessName || '--'}}</td>
          <td>{{row.assignedUser.fullName}}</td>
          <td class="text-center">
            <span *ngIf="row.dueDate!=null" class="badge required" [ngClass]="getRequiredColor(row.dueDate)">
              {{row.dueDate | date}}
              <span class="d-block small">{{weeksLeft(row.dueDate, true)||'--'}} Week(s) {{(weeksLeft(row.dueDate, false)||0) >= 0 ? 'From Today':'Overdue'}}</span>
            </span>
          </td>
          <td class="text-center">
            <span class="badge status text-uppercase" [ngClass]="getStatusColorClass(row.status)">
              {{getStatusText(row.status)}}
            </span>
          </td>
      </ng-template>
    </search-result>
  </div>

</div>
