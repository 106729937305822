import { Component } from '@angular/core';
import { SearchList } from '../../../common/components/search-list/search-list';
import { BulkBid, BulkBidStatus } from '../../resources/materialBid';
import { NavigationService } from '../../../common/services/navigation.service';
import { PurchasingService } from '../../services/purchasing.service';
import { MaterialBidService } from '../../services/material-bid.service';
import { Router } from '@angular/router';

@Component({
  selector: 'bulk-quote-list',
  templateUrl: './bulk-quote-list.component.html',
  styleUrls: ['./bulk-quote-list.component.less']
})
export class BulkQuoteListComponent extends SearchList<BulkBid> {

  constructor(navService: NavigationService, private service: PurchasingService, private materialService: MaterialBidService, private router: Router) {
    super('BulkQuoteListComponent');
    navService.setPageTitle("Bulk Quotes");
  }

  public search(): void {
    this.results = null;
    this.service.searchBulkBids(this.searchString, this.pageNumber, this.sortTerm, this.sortDirection).subscribe(results => {
      this.searchResults = results;
      this.results = results.results;
    });
  }

  public onSelect(record: BulkBid): void {
    this.router.navigate(['/purchasing/bulkquote', record.bulkBidId]);
  }

  public getStatusColorClass(status: number): string {
    return BulkBidStatus.getStatusColorClass(status);
  }

  public getStatusText(status: number): string {
    return BulkBidStatus.getStatusText(status);
  }
}
