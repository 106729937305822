import { Directive, Input, HostBinding } from '@angular/core';

@Directive({
  selector: '[appStaticEdit]'
})
export class StaticEditDirective {

  @Input() editable: boolean = false;

  @HostBinding('readonly') get readonly() { return this.editable ? null : "readonly"; }
  @HostBinding('class.form-control-plaintext') get appliedClass() { return !this.editable; }

}
