import { AfterViewInit, Component, EventEmitter, OnInit, Optional, Output, ViewChild, forwardRef } from '@angular/core';
import { ControlContainer, ControlValueAccessor, NG_VALUE_ACCESSOR, NgForm, NgModel } from '@angular/forms';
import { OutsideProcessDescription, OutsideProcessDescriptionStep } from '../../resources/outsideProcessDescription';
import { Product } from '../../../order/resources/product';
import { StationService } from '../../../order/services/station.service';
import { BehaviorSubject, Observable, combineLatest } from 'rxjs';
import { OutsideProcessSpecification, Station } from '../../../order/resources/station';
import { debounceTime, distinctUntilChanged, map, mergeMap, multicast, shareReplay, startWith, tap } from 'rxjs/operators';
import { UtilityService } from '../../../common/services/utility.service';
import { OrderService } from '../../../order/services/order.service';
import { CDK_DRAG_CONFIG, CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';

const DragConfig = {
  dragStartThreshold: 0,
  pointerDirectionChangeThreshold: 5,
  zIndex: 100000
};

@Component({
  selector: 'po-outside-process-editor',
  templateUrl: './po-outside-process-editor.component.html',
  styleUrls: ['./po-outside-process-editor.component.less'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => PoOutsideProcessEditorComponent),
      multi: true
    },
    { provide: CDK_DRAG_CONFIG, useValue: DragConfig }
  ],
  viewProviders: [
    {
      provide: ControlContainer,
      deps: [[Optional, NgForm]],
      useFactory: (ngForm: NgForm) => ngForm,
    },
  ],
})
export class PoOutsideProcessEditorComponent implements OnInit, AfterViewInit, ControlValueAccessor {

  onChange: (val: OutsideProcessDescription) => void = (val) => {};
  registerOnChange(fn: (v: OutsideProcessDescription) => void): void {
    this.onChange = fn;
  }
  onTouched: () => void = () => {};
  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  public value: OutsideProcessDescription;
  writeValue(val: OutsideProcessDescription): void {
    this.value = val;
  }

  public setSelectedProduct(event: Product) {
    if (!this.value) return;
    this.value.product = event;
    this.value.productId = event.productId;
  }

  public getStationName(station: Station) {
    return station?.name;
  }
  public generateStation(name: string): Partial<Station> {
    return {
      stationId: UtilityService.emptyGuid,
      name,
      extendedFields: {},
      isOutsourceStep: true
    }
  }

  public onStationChange(step: OutsideProcessDescriptionStep, station: Station) {
    if (station?.stationId !== step.stationId) {      
      step.outsideProcessSpecifications = [];
      step.outsideProcessSpecificationNames = [];
    }
    step.station = station;
    step.stationId = station?.stationId;
  }

  public async onCreateSpec(step: OutsideProcessDescriptionStep, specName: string) {
    step.outsideProcessSpecifications = [...step.outsideProcessSpecifications, UtilityService.emptyGuid];
    step.outsideProcessSpecificationNames = [...step.outsideProcessSpecificationNames, specName];
  }

  private getSpecName(step: OutsideProcessDescriptionStep, specId: string, index: number) {
    if (specId === UtilityService.emptyGuid) return step.outsideProcessSpecificationNames[index];
    const list = (this.stationService.stationList.find(s => s.stationId == step.stationId)?.outsideProcessSpecifications ?? [])
    return list.find(s => s.outsideProcessSpecificationId === specId)?.name ?? '';
  }

  public onSpecsUpdate(step: OutsideProcessDescriptionStep, specIds: string[]) {
    step.outsideProcessSpecificationNames = specIds.map((id, i) => this.getSpecName(step, id, i));
  }

  public onPartNumberChange(val: string) {
    if (!this.value) return;
    if (!this.value.product || (this.value.product.productId !== UtilityService.emptyGuid && this.value.product.partNumber !== val)) {
      const p = Product.newEmptyProduct();
      if (this.value.product) p.revision = this.value.product.revision;
      this.value.product = p;
      this.value.productId = UtilityService.emptyGuid;
    }
    this.value.product.partNumber = val;
  }
  public onRevisionChange(val: string) {
    if (!this.value) return;
    if (!this.value.product || (this.value.product.productId !== UtilityService.emptyGuid && this.value.product.revision !== val)) {
      const p = Product.newEmptyProduct();
      if (this.value.product) p.partNumber = this.value.product.partNumber;
      this.value.product = p;
      this.value.productId = UtilityService.emptyGuid;
    }
    this.value.product.revision = val;
  }


  constructor(private stationService: StationService, private orderService: OrderService) { }

  @ViewChild('partNumberSearchModel', { static: true }) partNumberSearchModel: NgModel;
  @ViewChild('revisionSearchModel', { static: true }) revisionSearchModel: NgModel;
  public filteredPartNumbers$: Observable<Product[]>;
  public loadingPartNumbers = false;
  public filteredRevisions$: Observable<Product[]>;

  ngOnInit(): void {
    if (this.value?.steps) {
      this.value.steps = this.value.steps.sort((a, b) => a.order - b.order);
    }
  }

  public stations$: Observable<Station[]>
  ngAfterViewInit(): void {
    const searchedPartNumbers$ = this.partNumberSearchModel.valueChanges
      .pipe(
        debounceTime(350),
        distinctUntilChanged(),
        startWith(''),
        tap(() => this.loadingPartNumbers = true),
        mergeMap(search => this.orderService.searchProducts(search)),
        tap(() => this.loadingPartNumbers = false)
      )
    this.filteredPartNumbers$ = combineLatest([
      searchedPartNumbers$,
      this.partNumberSearchModel.valueChanges.pipe(startWith('')),
    ]).pipe(
      map(([results, filter]) => {
        if (!filter || typeof filter !== 'string') return results;
        else return results.filter(i => {
          const field = i.partNumber.toLowerCase();
          const keywords = filter.toLowerCase().split(' ');
          return keywords.some(kw => field.includes(kw));
        })
      })
    );

    this.filteredRevisions$ = combineLatest([
      searchedPartNumbers$,
      this.partNumberSearchModel.valueChanges.pipe(startWith('')),
      this.revisionSearchModel.valueChanges.pipe(startWith('')),
      ]).pipe(
      map(([results, filter, revFilter]) => results.filter(r => r.partNumber === filter).filter(i => {
        const keywords = revFilter.toLowerCase().split(' ');
        return keywords.some(kw => i.revision.includes(kw));
      }))
    );

    this.stations$ = this.stationService.stations.pipe(map(sl => sl.filter(s => !s.isDeleted)));
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.value.steps, event.previousIndex, event.currentIndex);
    this.value.steps = this.value.steps.map((s, i) => ({ ...s, order: i }));
    this.value.steps = [...this.value.steps];
  }

  deleteStep(index: number) {
    this.value.steps = this.value.steps.filter((_, i) => i !== index);
  }

  addOp() {
    this.value.steps = [...this.value.steps, {
      outsideProcessDescriptionId: this.value.outsideProcessDescriptionId,
      outsideProcessDescriptionStepId: UtilityService.emptyGuid,
      outsideProcessSpecifications: [],
      outsideProcessSpecificationNames: [],
      station: null,
      stationId: null,
      order: this.value.steps.length,
      description: ''
    }]
  }

  public get disableEditProduct() {
    return this.value?.outsideProcessDescriptionId !== UtilityService.emptyGuid && !!this.value?.product;
  }

  @Output('clone') clone = new EventEmitter<OutsideProcessDescription>();
  public doClone() {
    const newItem = {
      ...this.value,
      outsideProcessDescriptionId: UtilityService.emptyGuid,
      steps: this.value.steps.map(s => ({
        ...s,
        outsideProcessDescriptionStepId: UtilityService.emptyGuid
      }))
    };
    // we have to completely remove this property
    // setting it to a number (which would be wrong anyway) triggers manual identity setting error on the DB
    // but sending it to the server explicitly set as 'null' makes it fail to parse the JSON
    delete newItem.numericalId;
    this.clone.emit(newItem);
  }

}
