import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { User } from '../../../common/resources/user';
import { UserService } from '../../../common/services/user.service';
import { QualityTicket } from '../../resources/quality-ticket';
import { QualityService } from '../../service/quality.service';

@Component({
  selector: 'quality-assign',
  templateUrl: './quality-assign.component.html',
  styleUrls: ['./quality-assign.component.less']
})
export class QualityAssignComponent implements OnInit {

  @Input() record: QualityTicket;
  public assigningEmployee: User = null;
  @Output() startLoading = new EventEmitter<void>();
  @Output() finishLoading = new EventEmitter<void>();

  constructor(
    private qualitySvc: QualityService,
    private userSvc: UserService,
  ) { }

  ngOnInit() {
  }

  public userIsManager(): boolean {
    return (
      this.userSvc.canAccess("QAManager") ||
      this.userSvc.canAccess("Developer")
    );
  }
  public userIsAssigned(): boolean {
    return this.userSvc.userData.userId === this.record.assignedUserId;
  }

  public async saveAssignment() {
    if (!this.record) return;
    this.startLoading.emit();
    await this.qualitySvc.save(this.record).toPromise();
    await this.qualitySvc.assign(this.record, this.assigningEmployee).toPromise();
    this.finishLoading.emit();
  }

}
