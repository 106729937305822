import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { MessageService } from '../services/message.service';
import { catchError } from 'rxjs/operators';
import { ErrorHandlerService } from '../services/errorHandler.service'
import { httpService } from './http.service';
import { SearchResult } from '../resources/search-result';
import { Address } from '../resources/address';

export interface AddressSearchResult {
    type: 'building' | 'customer' | 'vendor' | 'none';
    address: Address
}

@Injectable({
  providedIn: 'root',
})
export class AddressService extends httpService {
  private apiBase: string = 'api/address';
  private apiUrl: string;

  constructor(errorHandler: ErrorHandlerService, messages: MessageService, private http: HttpClient, @Inject('BASE_URL') private baseUrl: string) {
    super(errorHandler, messages);
    this.serviceName = "Address";

    this.apiUrl = this.baseUrl + this.apiBase;
  }

  public search(searchString?: string, direction = "asc", pageIndex = 0, pageSize = 50, ourBuildings = true, customers= true, vendors = true)
  : Observable<SearchResult<AddressSearchResult>> {
    return this.http.get<SearchResult<AddressSearchResult>>(this.apiUrl + '/search', { params: { 
        searchText: searchString || "", 
        pageIndex: pageIndex.toString(),
        pageSize: pageSize.toString(),
        direction,
        ourBuildings: ourBuildings.toString(),
        customers: customers.toString(),
        vendors: vendors.toString()
    }}).pipe(
      catchError(this.handleError<any>("Get Addresses Search Results", null))
    );
  }
}
