import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSidenav } from '@angular/material/sidenav';
import { ActivatedRoute, Router } from '@angular/router';
import { max } from 'rxjs/operators';
import { VirtualDocument } from '../../../common/resources/virtual-document';
import { NavigationService } from '../../../common/services/navigation.service';
import { UtilityService } from '../../../common/services/utility.service';
import { InventoryItemLocation } from '../../../inventory/resources/inventory-item-location';
import { InventoryLocation } from '../../../inventory/resources/inventory-location';
import { InventoryService } from '../../../inventory/services/inventory.service';
import { Order, OrderStatus } from '../../../order/resources/order';
import { RMADispositionLot, RMATicket } from '../../../rma/resources/rma-ticket';
import { RMAService } from '../../../rma/service/rma.service';
import { QualityTicket } from '../../resources/quality-ticket';
import { QualityService } from '../../service/quality.service';

@Component({
  selector: 'quality-rma-detail',
  templateUrl: './quality-rma-detail.component.html',
  styleUrls: ['./quality-rma-detail.component.less']
})
export class QualityRmaDetailComponent implements OnInit {

  private ticketId: string;
  public editing = false;
  public loading = false;
  public saving = false;
  public qualityTicket: QualityTicket;
  public detail: RMATicket;
  public locationParents: {[key: string]: InventoryLocation[]} = {}
  public lotOrders: {[key: string]: any} = {}
  public showEditor: string = null;
  public documentLotId: string = null;
  @ViewChild('sidenav', { static: true }) sidenav: MatSidenav;

  constructor(
    route: ActivatedRoute,
    public router: Router,
    public qualitySvc: QualityService,
    public rmaSvc: RMAService,
    public navService: NavigationService,
    public inventoryService: InventoryService,
    public utilitySvc: UtilityService,
    public dialog: MatDialog
  ) {
    this.ticketId = route.snapshot.paramMap.get('id');
  }

  public get location(): InventoryItemLocation {
    if (!this.detail || !this.detail.inventoryItemLocations) return null;
    else return this.detail.inventoryItemLocations[0];
  }

  public get totalLotAmount(): number {
    return this.detail.dispositionLots.reduce((acc, cur) => acc + cur.quantity, 0);
  }

  public get dispositionedAmount(): number {
    return this.detail.dispositionLots.filter(d => d.dispositionType !== null && d.repairType !== null).reduce((acc, cur) => acc + cur.quantity, 0);
  }

  public get lotsAreValid(): boolean {
    return this.detail.dispositionLots.every(d => !!(d.quantity > 0 && d.dispositionType !== null && d.repairType !== null));
  }

  async getDetail() {
    this.loading = true;
    this.qualityTicket = await this.qualitySvc.getDetail(this.ticketId).toPromise();
    this.detail = await this.rmaSvc.getDetail(this.qualityTicket.rmaTicketID).toPromise();
    // set nav service page title to "Quality Ticket Detail" and breadcrumb to quality ticket number
    this.navService.setPageTitle('Quality Ticket Detail');
    this.navService.pushBreadcrumb(this.qualityTicket.qualityTicketNumber);
    this.loading = false;
    this.detail.inventoryItemLocations.forEach(l => {
      this.inventoryService.getLocationParents(l.inventoryLocationId).subscribe(parents => {
        this.locationParents[l.inventoryLocationId] = parents;
      })
    })
    if (this.detail.status > 3) {
      this.detail.dispositionLots && this.detail.dispositionLots.forEach(async (l) => {
        this.lotOrders[l.rmaDispositionLotId] = 'loading';
        this.lotOrders[l.rmaDispositionLotId] = await this.rmaSvc.getLotOrderSegment(l).toPromise();
      })
    }
  }

  public addNewLot() {
    this.detail.dispositionLots.push({
      rmaTicketId: this.detail.rmaTicketId,
      rmaDispositionLotId: UtilityService.newGuid(),
      quantity: Math.max(this.detail.receivedQuantity - this.totalLotAmount, 0),
      dispositionType: null,
      repairType: null,
      notes: "",
      documents: []
    });
  }

  public deleteLot(index: number ) {
    this.detail.dispositionLots.splice(index, 1);
  }

  public openDocumentsWindow(lot: RMADispositionLot) {
    this.documentLotId = lot.rmaDispositionLotId;
    this.showEditor = 'document';
    this.sidenav.open()
  }

  public async addDocuments(documents: VirtualDocument[]) {
    const lot = this.detail.dispositionLots.find(l => l.rmaDispositionLotId === this.documentLotId);

    lot.documents = [...lot.documents, ...documents.map(doc => ({
      rmaDispositionLotId: lot.rmaDispositionLotId,
      documentDocumentId: doc.documentId,
      document: doc
    }))];
  }

  public async deleteLotDocument(lot: RMADispositionLot, document: VirtualDocument) {
    const r = await this.utilitySvc.showConfirmationPromise("Are you Sure?", "<p>Are you sure you want to remove this document?</p><p class='text-muted font-weight-bold'>This cannot be undone.</p>");
    if (!r) return;

    this.saving = true;
    await this.rmaSvc.removeLotDocument(lot, document).toPromise();
    lot.documents = lot.documents.filter(d => d.documentDocumentId !== document.documentId);
    this.saving = false;
  }

  public async save(refresh = true) {
    this.saving = true;
    // save the lots to a variable, remove the doc objects then empty the array so it doesn't confuse the backend
    const lots: RMADispositionLot[] = this.detail.dispositionLots.map(l => ({ ...l, documents: l.documents.map(d => ({ 
      rmaDispositionLotId: l.rmaDispositionLotId,
      documentDocumentId: d.documentDocumentId
    })) }));
    this.detail.dispositionLots = [];
    await this.rmaSvc.save(this.detail).toPromise();
    const newDetail = await this.rmaSvc.updateDispositions(this.detail, lots).toPromise();
    this.detail = newDetail;
    this.saving = false;
    this.editing = false;
    if (refresh) this.getDetail();
  }

  public getStatusColorClass(status: number): string {
    return OrderStatus.getStatusColorClass(status);
  }

  public getStatusText(status: number): string {
    return OrderStatus.getStatusText(status);
  }

  public async disposition() {
    const r = await this.utilitySvc.showConfirmationPromise('Is this final?', 'The ticket will be locked from further editing and orders will be created accordingly. This cannot be undone');
    this.saving = true;
    if (!r) return;
    await this.save(false);
    await this.qualitySvc.disposition(this.qualityTicket).toPromise();
    this.getDetail();
    this.saving = false;
  }

  ngOnInit() {
    this.getDetail();
  }

}
