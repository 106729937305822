
export class ResourceEmployee {
  id: string;
  name: string;
  color: string;
  title: string;
  img: string;
  scheduledHours: string;
  maxHours: string;
}
 
