<loading *ngIf="!record"></loading>
<loading *ngIf="saving" content="Saving..."></loading>

<div *ngIf="record">

  <div>
    <a class="position-fixed fixed-bottom btn btn-badge bg-danger has-icon mr-6" title="Close" (click)="close()"><i class="fas fa-times"></i></a>
    <a class="position-fixed fixed-bottom btn btn-badge bg-success has-icon" title="Save" (click)="save()"><i class="fas fa-save"></i></a>
  </div>

  <div class="container-fluid">
    <h5 class="w-100 text-center mt-4 p-2 bg-secondary">Assignment Details</h5>
    <div class="row form-group">
      <div class="col-6">
        <label>Work Order Number</label>
        <div class="small">
          {{record.workOrder?.workOrderNumber}}
        </div>
      </div>
      <div class="col-6">
        <label>Operation</label>
        <div class="small">
          {{getStation(record.operation?.stationId)?.name}}
        </div>
      </div>
    </div>
    <div class="row form-group">
      <div class="col-4">
        <label>Department</label>
        <div class="small">{{record.department?.name}}</div>
      </div>
      <div class="col-4">
        <label>Building</label>
        <div class="small">{{assignedBuilding?.name}}</div>
      </div>
      <div class="col-4">
        <label>Station / Machine</label>
        <div class="small">{{record.machine?.name || '-- Unnamed --'}}</div>
      </div>
    </div>
    <h5 class="w-100 text-center p-2 bg-secondary">Prerequisites</h5>
    <div class="row form-group">
      <div class="col-12">
        <div *ngIf="prerequisites; else loadingPrerequisites">
          <div *ngIf="prerequisites.length>0; else noPrerequisites">
            <div *ngFor="let item of prerequisites">
              <div class="d-flex align-items-center w-100 pl-4" [ngClass]="{'bg-danger text-white':isHere(item.inventoryItem)}">
                <item-type-chip [item]="item.inventoryItem" [iconOnly]="true" class="mr-2"></item-type-chip>
                <div class="flex-grow-1 overflow-hidden">
                  <div>{{item.inventoryItem?.name}}</div>
                  <div class="small"><span class="font-weight-bold">{{item.quantity}}X</span> per operation</div>
                </div>
              </div>
            </div>
          </div>
          <ng-template #noPrerequisites>
            <div class="center text-muted font-italic fa-lg">-- No Prerequisites --</div>
          </ng-template>
        </div>
        <ng-template #loadingPrerequisites>
          <div class="center text-muted font-italic fa-lg">Loading Prerequisites...</div>
        </ng-template>
      </div>
    </div>
    <h5 class="w-100 text-center p-2 bg-secondary">Scheduling</h5>
    <div class="row form-group">
      <div class="col">
        <label>Operation Run Time</label>
        <div class="p-2">
          <i class="far fa-fw fa-lg fa-clock text-muted"></i>
          {{((record.operation?.runTime * (this.record.operation?.runIsPerPart ? 1 : 60)) / 60.0) | number: '0.2-2'}} hrs
          <span class="text-muted">[{{(record.operation?.runTime * (this.record.operation?.runIsPerPart ? 1 : 60)) | number: '0.0-0'}} min]</span>
        </div>
      </div>
      <div class="col">
        <label>Requested Operator</label>
        <div class="p-2" [ngClass]="{'border border-danger position-relative invalid': !record.userId && touched}">
          <input type="hidden" id="record_machineId" [ngModel]="record.machineId" />
          <filtered-user-list emptyResultsMessage="No Certified Operators Found!" [filterExpression]="machineProficencyFilter" [editable]="true" roleFlter="FloorOpsUser" (selectedChange)="refreshCharts()" [(selected)]="record.userId">
            <ng-template let-row>
              <span class="small text-muted">({{getHomeDepartment(row)}})</span>
              <rating [editable]="false" [value]="getOperatorRating(row)"></rating>
            </ng-template>
          </filtered-user-list>
          <div class="validation text-danger small font-italic">Please Select an Operator</div>
        </div>
      </div>
      <div class="col">
        <label>Operation Scheduled Start</label>
        <div class="p-2 text-center" [ngClass]="{'border border-danger position-relative invalid': record.scheduledStart < now && touched}">
          <div class="container-fluid">
            <div class="row">
              <div class="col">
                <date-picker [min]="now" [required]="true" [placeholder]="'Start Date'" [editable]="true" [(date)]="date"></date-picker>
              </div>
              <div class="col">
                <div class="p-1">
                  <!-- TODO: Find a good time picker library -->
                  <input placeholder="Start Time" class="form-control form-control-sm" changetominutes="true" type="time" [(ngModel)]="time" />
                </div>
              </div>
            </div>
          </div>
          <span class="small text-muted font-italic d-block p-2">Thru</span>
          <span class="">
            <i class="far ta-clock fa-fw text-muted"></i>
            {{getEndTime() | date: 'shortDate'}},
            {{getEndTime() | date: 'shortTime'}}
          </span>
          <div class="validation text-danger small font-italic">Please Update the Start Date</div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col">

        <mat-stepper>
          <ng-template matStepperIcon="edit">
            <i class="fas fa-check fa-fw"></i>
          </ng-template>
          <ng-template matStepperIcon="done">
            <i class="fas fa-check fa-fw"></i>
          </ng-template>
          <mat-step label="Machine Schedule">
            <machine-today-schedule #machineSchedule [date]="record.scheduledStart" [machineId]="record.machineId"></machine-today-schedule>
          </mat-step>
          <mat-step label="Operator Schedule">
            <operator-today-schedule #operatorSchedule *ngIf="record.userId; else noOperator" [userId]="record.userId" [date]="record.scheduledStart"></operator-today-schedule>
            <ng-template #noOperator>
              <div class="text-center text-muted font-italic pt-4 mt-4">
                -- Select an Operator to View Schedule --
              </div>
            </ng-template>
          </mat-step>
          <mat-step label="Product Waterfall">
            <product-waterfall #waterfallChart [orderId]="record?.workOrder?.order?.orderSegmentId" [product]="record?.workOrder?.product"></product-waterfall>
          </mat-step>
        </mat-stepper>

      </div>
    </div>
  </div>

</div>
