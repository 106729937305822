import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { MessageService } from '../../common/services/message.service';
import { catchError, tap, concatMap } from 'rxjs/operators';
import { MessageType } from '../../common/resources/message';
import { httpService } from '../../common/services/http.service';
import { Order, OrderDocument, OrderProduct, OrderSegmentProductReviewStatus, OrderStatus, QuoteLineItem } from '../../order/resources/order';
import { Product, ProductDocument, ProductPurchasedItem, ProductQuantity, ProductRepairPlan, ProductHistoryEntry, SimilarPartScore, ProductStandard } from '../resources/product';
import { UtilityService } from '../../common/services/utility.service';
import { UserService } from '../../common/services/user.service';
import { Workflow, WorkflowStep, WorkflowStepInventoryItem, WorkflowStepInventoryItemType } from '../../order/resources/workflow';
import { CounterService } from '../../common/services/counter.service';
import { ErrorHandlerService } from '../../common/services/errorHandler.service';
import { Grouped, SearchResult } from '../../common/resources/search-result';
import { VirtualDocument } from '../../common/resources/virtual-document';
import { MaterialBid } from '../../purchasing/resources/materialBid';
import { User } from '../../common/resources/user';
import { PurchasedItem, PurchasedItemPartNumber } from '../resources/purchased-item';
import { Material } from '../resources/material';
import { Customer } from '../../customer/resources/customer';
import { OutsideProcessSpecification, Station } from '../resources/station';
import { Address } from '../../common/resources/address';
import { Company } from '../../common/resources/company';
import { CustomerPurchaseOrder, CustomerPurchaseOrderType } from '../resources/customer-po';
import { WorkOrder } from '../../planning/resources/work-order';
import { SortedProductHierarchy } from '../../planning/components/product-hierarchy-sort/product-hierarchy-sort.component';
import { PricingBreakdown } from '../resources/pricing-breakdown';
import { LeadTimes } from '../resources/lead-times';
import { MaterialSelectComponent } from '../components/material-select/material-select.component';
import { SalesNote } from '../resources/sales-note';
import { TaskList } from '../../common/resources/estimatingtask';

@Injectable({
  providedIn: 'root',
})
export class OrderService extends httpService {
  private apiBase: string = 'api/ordersegment';
  private apiUrl: string;

  constructor(private counterService: CounterService, private usrService: UserService, errorHandler: ErrorHandlerService, private messages: MessageService, private http: HttpClient, @Inject('BASE_URL') private baseUrl: string) {
    super(errorHandler, messages);
    this.serviceName = "Order";

    this.apiUrl = this.baseUrl + this.apiBase;
  }

  public search(type: string, overrideView?: boolean, searchString?: string, page?: number, sortBy?: string, sortDirection?: string, pageSize?: number, fieldSearches?: {
    field: string,
    string: string
  }[], filters: { [key: string]: any[] } = null, groupBy: string = null): Observable<SearchResult<Order> | SearchResult<Grouped<Order>>> {
    const params = new URLSearchParams({ forAllUsers: (!!overrideView).toString(), searchText: searchString, pageIndex: (page || 0).toString(), pageSize: (pageSize ?? 10).toString(), orderBy: sortBy || "orderNumber", direction: sortDirection || "desc" });
    if (groupBy) params.append('groupBy', groupBy);
    if (fieldSearches) {
      for (const fs of fieldSearches) {
        params.append(`fieldSearches[${fs.field}]`, fs.string)
      }
    }
    if (filters) {
      for (const category in filters) {
        if (!filters.hasOwnProperty(category)) continue;
        params.append(`filters[${category}]`, filters[category].join(','));
      }
    }
    return this.http.get<SearchResult<Order>>(this.apiUrl + '/search/' + type + '?' + params.toString()).pipe(
      catchError(this.handleError<any>("Get Orders Search Results", null))
    );
  }

  public getDetail(id: string): Observable<Order> {
    return this.http.get<Order>(this.apiUrl + '/' + id).pipe(
      catchError(this.handleError<any>("Get Order Detail", null))
    );
  }

  public searchProducts(searchFor: string): Observable<Product[]> {
    return this.http.get<Product[]>(this.baseUrl + 'api/product/getproducts/', { params: { searchText: searchFor } }).pipe(
      catchError(this.handleError<any>("Get Products Search Results", null))
    );
  }

  public getOrders(orderIds: string[]): Observable<Order[]> {
    return this.http.post<Order[]>(this.apiUrl + '/getOrders', orderIds).pipe(
      tap(_ => this.messages.add("Order Service: Fetched Orders", MessageType.SUCCESS, true)),
      catchError(this.handleError<any>("Fetch Orders", orderIds))
    );
  }

  public getProduct(id: string): Observable<Product> {
    return this.http.get<Product>(this.baseUrl + 'api/product/' + id).pipe(
      catchError(this.handleError<any>("Get Product Detail", null))
    );
  }

  public getChildProducts(productId: string): Observable<Product[]> {
    return this.http.get<Product[]>(this.baseUrl + 'api/product/getchildren/' + productId).pipe(
      catchError(this.handleError<any>("Get Child Products", null))
    );
  }

  public getAllChildProducts(productId: string): Observable<Product[]> {
    return this.http.get<Product[]>(this.baseUrl + 'api/product/getAllChildren/' + productId).pipe(
      catchError(this.handleError<any>("Get All Child Products", null))
    );
  }

  public getProductTree(orderId: string): Observable<Product[]> {
    return this.http.get<Product[]>(this.apiUrl + '/getproducttree/' + orderId).pipe(
      catchError(this.handleError<any>("Get Product Tree", null))
    )
  }

  public getAllChildProductsAndRelated(productId: string): Observable<Product[]> {
    return this.http.get<Product[]>(this.baseUrl + 'api/product/getAllChildrenAndRelated/' + productId).pipe(
      catchError(this.handleError<any>("Get All Child Products And Related", null))
    );
  }

  public addDocuments(order: Order, documents: VirtualDocument[]): Observable<OrderDocument[]> {
    return this.http.post<OrderDocument[]>(this.apiUrl + '/adddocuments?orderSegmentId=' + order.orderSegmentId, documents.map(d => d.documentId)).pipe(
      tap(_ => this.messages.add("Order Service: Documents Updated", MessageType.SUCCESS, true)),
      catchError(this.handleError<any>("Add Documents to Order", null))
    );
  }

  public removeDocument(order: Order, document: VirtualDocument): Observable<any> {
    return this.http.get<Product[]>(this.apiUrl + '/removedocument?orderSegmentId=' + order.orderSegmentId + '&documentId=' + document.documentId).pipe(
      tap(_ => this.messages.add("Order Service: Document Removed Successfully", MessageType.SUCCESS, true)),
      catchError(this.handleError<any>("Remove Document from Order", null))
    );
  }

  public removeDocumentFromPart(product: Product, document: VirtualDocument): Observable<any> {
    return this.http.get<Product[]>(this.baseUrl + 'api/product/removedocument?productId=' + product.productId + '&documentId=' + document.documentId).pipe(
      tap(_ => this.messages.add("Order Service: Document Removed from Part Successfully", MessageType.SUCCESS, true)),
      catchError(this.handleError<any>("Remove Document from Part", null))
    );
  }

  public addDocumentsToPart(product: Product, documents: VirtualDocument[]): Observable<ProductDocument[]> {
    return this.http.post<ProductDocument[]>(this.baseUrl + 'api/product/adddocuments?productId=' + product.productId, documents.map(d => d.documentId)).pipe(
      tap(_ => this.messages.add("Order Service: Documents Updated", MessageType.SUCCESS, true)),
      catchError(this.handleError<any>("Add Documents to Part", null))
    );
  }

  public addPartToOrder(product: Product, order: Order): Observable<OrderProduct> {
    return this.http.post<OrderProduct>(this.apiUrl + '/addpart?orderSegmentId=' + order.orderSegmentId, product).pipe(
      tap(_ => this.messages.add("Order Service: Part Added", MessageType.SUCCESS, true)),
      catchError(this.handleError<any>("Add Part to Order", null))
    );
  }

  public removePartFromOrder(order: Order, product: Product): Observable<Product> {
    return this.http.get<Product>(this.apiUrl + '/removepart' + '?orderSegmentId=' + order.orderSegmentId + '&productId=' + product.productId).pipe(
      tap(_ => this.messages.add("Order Service: Order Part Removed", MessageType.SUCCESS, true)),
      catchError(this.handleError<any>("Remove Part from Order", null))
    );
  }

  public addSubComponent(baseProductId: string, product: Product): Observable<Product> {
    return this.http.post<OrderProduct>(this.baseUrl + 'api/product/addsubcomponent?productId=' + baseProductId, product).pipe(
      tap(_ => this.messages.add("Order Service: Subcomponent Added", MessageType.SUCCESS, true)),
      catchError(this.handleError<any>("Add Part as Subcomponent", null))
    );
  }

  public removeSubComponent(parentProduct: Product, childProduct: Product): Observable<Product> {
    return this.http.get<Product>(this.baseUrl + 'api/product/removesubcomponent' + '?parentProductId=' + parentProduct.productId + '&childProductId=' + childProduct.productId).pipe(
      tap(_ => this.messages.add("Order Service: Subcomponent Removed", MessageType.SUCCESS, true)),
      catchError(this.handleError<any>("Remove Part Subcomponent", null))
    );
  }

  public removeSubComponentId(parentProductId: string, childProductId: string): Observable<Product> {
    return this.http.get<Product>(this.baseUrl + 'api/product/removesubcomponent' + '?parentProductId=' + parentProductId + '&childProductId=' + childProductId).pipe(
      tap(_ => this.messages.add("Order Service: Subcomponent Removed", MessageType.SUCCESS, true)),
      catchError(this.handleError<any>("Remove Part Subcomponent", null))
    );
  }

  public orderWorkflowSteps(stepIds: string[]): Observable<any> {
    return this.http.post<OrderProduct>(this.apiUrl + '/orderWorkflowSteps', stepIds).pipe(
      catchError(this.handleError<any>("Set Workflow Step Order", null))
    );
  }

  public saveWorkflowStep(product: Product, step: WorkflowStep): Observable<WorkflowStep> {
    step.workflowId = product.workflowWorkflowId;
    step.paint = null;

    if (step.workflowStepId == UtilityService.emptyGuid) {
      //New Item
      return this.http.post<WorkflowStep>(this.baseUrl + 'api/workflowstep/new', step).pipe(
        tap(_ => this.messages.add("Order Service: Workflow Step Saved Successfully", MessageType.SUCCESS, true)),
        catchError(this.handleError<WorkflowStep>("Save New Workflow Step", step))
      );
    }
    else {
      //Existing Item
      return this.http.post<WorkflowStep>(this.baseUrl + 'api/workflowstep/', step).pipe(
        tap(_ => this.messages.add("Order Service: Workflow Step Saved Successfully", MessageType.SUCCESS, true)),
        catchError(this.handleError<WorkflowStep>("Update Workflow Step", step))
      );
    }
  }

  public saveRepairWorkflowStep(plan: ProductRepairPlan, step: WorkflowStep): Observable<WorkflowStep> {
    step.workflowId = plan.workflowId;
    step.paint = null;

    if (step.workflowStepId == UtilityService.emptyGuid) {
      //New Item
      return this.http.post<WorkflowStep>(this.baseUrl + 'api/workflowstep/new', step).pipe(
        tap(_ => this.messages.add("Order Service: Repair Workflow Step Saved Successfully", MessageType.SUCCESS, true)),
        catchError(this.handleError<WorkflowStep>("Save New Workflow Step", step))
      );
    }
    else {
      //Existing Item
      return this.http.post<WorkflowStep>(this.baseUrl + 'api/workflowstep/', step).pipe(
        tap(_ => this.messages.add("Order Service: Repair Workflow Step Saved Successfully", MessageType.SUCCESS, true)),
        catchError(this.handleError<WorkflowStep>("Update Workflow Step", step))
      );
    }
  }

  public getCostForOrder(orderId: string): Observable<number> {
    return this.http.get<any>(`${this.apiUrl}/getCostForOrder/${orderId}`).pipe(
      catchError(this.handleError<any>("Get Order Cost", null))
    );
  }

  public getCostForAssembly(productId: string, quantity: number): Observable<number> {
    return this.http.get<any>(`${this.apiUrl}/getCostForAssembly/${productId}?qty=${quantity}`).pipe(
      catchError(this.handleError<any>("Get Prodcut Cost", null))
    );
  }

  public getProductCostBreakdown(product: Product, qty: number): Observable<any> {
    return this.http.get<any>(`${this.apiUrl}/getpricingbreakdown/${product.productId}?qty=${qty}`).pipe(
      catchError(this.handleError<any>("Get Product Cost Breakdown", null))
    );
  }

  public getSingleProductCostBreakdown(product: Product): Observable<{ description: string, price: number }[]> {
    if (!!product.productRepairPlan) return this.http.get<any>(`${this.apiUrl}/getrepairbreakdownsingle/${product.productId}`).pipe(
      catchError(this.handleError<any>("Get Single Product Repair Cost Breakdown", null))
    )
    else return this.http.get<any>(`${this.apiUrl}/getpricingbreakdownsingle/${product.productId}`).pipe(
      catchError(this.handleError<any>("Get Single Product Cost Breakdown", null))
    );
  }

  public getPricingBreakdownSingleTree(orderId: string): Observable<PricingBreakdown[]> {
    return this.http.get<any>(`${this.apiUrl}/getpricingbreakdownsingletree/${orderId}`).pipe(
      catchError(this.handleError<any>("Get Pricing Breakdown Single Tree", null))
    );
  }

  public searchPurchasedItems(searchFor: string, pageIndex: number = 0, pageSize: number = 20): Observable<SearchResult<PurchasedItem>> {
    return this.http.get<PurchasedItem[]>(this.baseUrl + 'api/PurchasedItem/search', {
      params: {
        searchText: searchFor,
        pageIndex: pageIndex.toString(),
        pageSize: pageSize.toString(),
        orderBy: 'name'
      }
    }).pipe(
      catchError(this.handleError<any>("Get Purchased Items Search Results", null))
    );
  }

  public savePurchasedItem(item: PurchasedItem): Observable<PurchasedItem> {
    return this.http.post<PurchasedItem>(this.baseUrl + 'api/purchaseditem/', item).pipe(
      tap(_ => this.messages.add("Order Service: Purchased Item Saved Successfully", MessageType.SUCCESS, true)),
      catchError(this.handleError<PurchasedItem>("Update Purchased Item", item))
    );
  }

  public removePurchasedItem(purchasedItemId: string): Observable<any> {
    return this.http.delete<PurchasedItem>(this.baseUrl + `api/purchaseditem/${purchasedItemId}`).pipe(
      tap(_ => {
        this.messages.add("Order Service: Purchased Item Removed Successfully", MessageType.SUCCESS, true);
      }),
      catchError(this.handleError<PurchasedItem>("Remove Purchased Item", null))
    );
  }

  public saveProductPurchasedItem(_item: ProductPurchasedItem): Observable<ProductPurchasedItem> {
    const item = Object.assign({}, _item);
    item.purchasedItem = null;
    if (item.productPurchasedItemId == UtilityService.emptyGuid) {
      //New Item
      return this.http.post<ProductPurchasedItem>(this.baseUrl + 'api/productpurchaseditem/new', item).pipe(
        tap(_ => this.messages.add("Order Service: Product Purchased Item Saved Successfully", MessageType.SUCCESS, true)),
        catchError(this.handleError<ProductPurchasedItem>("Save Product Purchased Item", item))
      );
    }
    else {
      //Existing Item
      return this.http.post<ProductPurchasedItem>(this.baseUrl + 'api/productpurchaseditem/', item).pipe(
        tap(_ => this.messages.add("Order Service: Purchased Item Saved Successfully", MessageType.SUCCESS, true)),
        catchError(this.handleError<ProductPurchasedItem>("Save Product Purchased Item", item))
      );
    }
  }

  public removeProductPurchasedItem(item: ProductPurchasedItem): Observable<any> {
    return this.http.delete<PurchasedItem>(this.baseUrl + `api/productpurchaseditem/${item.productPurchasedItemId}`).pipe(
      tap(_ => {
        this.messages.add("Order Service: Purchased Item Removed Successfully", MessageType.SUCCESS, true);
      }),
      catchError(this.handleError<PurchasedItem>("Remove Purchased Item", undefined))
    );
  }

  public createPurchasedItemPartNumber(item: PurchasedItemPartNumber): Observable<PurchasedItemPartNumber> {
    item.vendor = null;
    return this.http.post<PurchasedItemPartNumber>(this.baseUrl + 'api/PurchasedItemPartNumber/new', item).pipe(
      catchError(this.handleError<any>("Create Purchased Item Part Number", null))
    );
  }

  public deletePurchasedItemPartNumber(item: PurchasedItemPartNumber): Observable<PurchasedItemPartNumber> {
    return this.http.delete<PurchasedItemPartNumber>(this.baseUrl + 'api/PurchasedItemPartNumber/' + item.purchasedItemPartNumberId).pipe(
      catchError(this.handleError<any>("Delete Purchased Item Part Number", null))
    );
  }


  public removeWorkflowStep(stepId: string): Observable<any> {
    return this.http.delete<WorkflowStep>(this.baseUrl + 'api/workflowstep/' + stepId).pipe(
      tap(_ => this.messages.add("Order Service: Workflow Step Removed Successfully", MessageType.SUCCESS, true)),
      catchError(this.handleError<WorkflowStep>("Remove Workflow Step", undefined))
    );
  }

  private getPrefix(discriminator: string): string {
    switch (discriminator) {
      case "Order":
        return "AON";
      case "Estimate":
        return "AEN";
      default:
        return "AQN";
    }
  }

  public getByOrderNumber(orderNumber: string): Observable<Order> {
    return this.http.get<Order[]>(this.apiUrl + '/getByOrderNumber/' + orderNumber).pipe(
      catchError(this.handleError<any>("Get Order By Order Number", null))
    );
  }

  public createPart(part: Product): Observable<Product> {
    if (part.productId == UtilityService.emptyGuid) {
      //New Item
      return this.http.post<Product>(this.baseUrl + 'api/product/new', part).pipe(
        tap(_ => this.messages.add("Order Service: Product Saved Successfully", MessageType.SUCCESS, true)),
        catchError(this.handleError<Product>("Save New Product", part))
      );
    } else {
      return;
    }
  }

  public savePart(part: Product): Observable<Product> {
    //NOTE: We should never see a part with an empty ID
    return this.http.post<Product>(this.baseUrl + 'api/product/', part).pipe(
      tap(_ => this.messages.add("Order Service: Product Saved Successfully", MessageType.SUCCESS, true)),
      catchError(this.handleError<Product>("Update Order", part))
    );
  }

  public createEmptyWorkflow(): Observable<Workflow> {
    return this.http.post<Workflow>(this.baseUrl + 'api/workflow/' + 'new', {
      workflowSteps: []
    }).pipe(
      tap(_ => this.messages.add("Order Service: New Workflow Created", MessageType.SUCCESS, true)),
      catchError(this.handleError<Workflow>("Create Empty Workflow"))
    );
  }

  public findQuote(orderId: string, productId: string, materialId: string, stationId: string, purchasedItemId: string): Observable<MaterialBid> {
    return this.http.get<MaterialBid>(this.baseUrl + `api/materialbid/findQuote?orderId=${orderId}&productId=${productId}${materialId == null ? '' : `&materialId=${materialId}`}${stationId == null ? '' : `&stationId=${stationId}`}${purchasedItemId == null ? '' : `&purchasedItemId=${purchasedItemId}`}`).pipe(
      tap(_ => this.messages.add("Order Service: Found Quote", MessageType.SUCCESS, true)),
      catchError(this.handleError<MaterialBid>("Set Pricing For Product", null))
    );
  }

  public setProductQuotedPriceQty(part: Product): Observable<void> {
    return this.http.get<void>(this.apiUrl + `/setProductQuotedPriceQty/${part.productId}?quotedPrice=${part.quotedUnitPrice}&quotedQty=${part.orderQuantity}`).pipe(
      catchError(this.handleError<void>("Set Pricing For Product", null))
    );
  }

  public setProductPricing(part: Product, orderQuantity: number, quantitiesMap: ProductQuantity[]): Observable<any> {
    return this.http.post<any>(this.apiUrl + `/setProductPricing/${part.productId}`, {
      quantitiesMap, orderQuantity
    }).pipe(
      catchError(this.handleError<void>("Set Pricing For Product", null))
    );
  }

  public assign(orderId: string, toUser: User): Observable<void> {
    return this.http.get<void>(this.apiUrl + `/assign/${orderId}?userId=${toUser.userId}`).pipe(
      tap(_ => this.messages.add("Order Service: Assign Order", MessageType.SUCCESS, true)),
      catchError(this.handleError<void>("Assign Order", null))
    );
  }

  public save(item: Order): Observable<Order> {
    //Clean up - we don't want to insert these again, so clear out the objects and leave the ID's
    item.shipToAddress = null;
    item.assignedUser = null;
    item.customer = null;
    item.company = null;
    item.customerContact = null;
    item.updatedDate = new Date();
    item.modifiedBy = this.usrService.userData.userId;

    if (item.orderSegmentId == UtilityService.emptyGuid) {
      //New Item
      item.createdDate = item.updatedDate;

      //Needs a number first...
      return this.counterService.next(this.getPrefix(item.discriminator)).pipe(
        concatMap(nextPrefix => {
          item.orderNumber = nextPrefix;

          //NOW return the new order
          return this.http.post<Order>(this.apiUrl + '/new', item).pipe(
            tap(_ => this.messages.add("Order Service: Order Saved Successfully", MessageType.SUCCESS, true)),
            catchError(this.handleError<Order>("Save New Order", item))
          );
        })
      );
    }
    else {
      //Existing Item - we can just do a simple update here
      return this.http.post<Order>(this.apiUrl, item).pipe(
        tap(_ => {
          if (item.status == (OrderStatus.AWAITING_REVIEW) && item.discriminator == "Estimate") {
            this.http.get<any>(`${this.baseUrl}/notification/SendEstimateReadyForReview/${item.orderSegmentId}`).subscribe(_ => { });
          }
          this.messages.add("Order Service: Order Saved Successfully", MessageType.SUCCESS, true);
        }),
        catchError(this.handleError<Order>("Update Order", item))
      );
    }
  }

  public quoteToEstimate(item: Order): Observable<Order> {
    return this.http.post<Order>(this.apiUrl + '/toEstimate/' + item.orderSegmentId, {
      responseType: "json"
    }).pipe(
      tap(_ => this.messages.add("Order Service: Order Estimate Created", MessageType.SUCCESS, true)),
      catchError(this.handleError<Order>("Create Estimate from Quote", item))
    );
  }

  public quoteToEstimateId(orderSegmentId: string): Observable<Order> {
    return this.http.post<Order>(this.apiUrl + '/toEstimate/' + orderSegmentId, {
      responseType: "json"
    }).pipe(
      tap(_ => this.messages.add("Order Service: Order Estimate Created", MessageType.SUCCESS, true)),
      catchError(this.handleError<Order>("Create Estimate from Quote"))
    );
  }

  public approveOrder(item: Order): Observable<Order> {
    return this.http.get<Order>(this.apiUrl + '/approve/' + item.orderSegmentId).pipe(
      tap(_ => this.messages.add("Order Service: Order Approved", MessageType.SUCCESS, true)),
      catchError(this.handleError<Order>("Approve Order", item))
    );
  }

  public approveEstimateClientside(estimate: Order, lineItems: QuoteLineItem[]): Observable<Order> {
    return this.http.post<Order>(this.apiUrl + '/approveEstimateClientside/' + estimate.orderSegmentId,lineItems).pipe(
      tap(_ => this.messages.add("Order Service: Estimate Approved", MessageType.SUCCESS, true)),
      catchError(this.handleError<Order>("Approve Order", estimate))
    );
  }

  public rejectOrder(item: Order, note: string): Observable<Order> {
    return this.http.post<Order>(this.apiUrl + '/reject/' + item.orderSegmentId, note).pipe(
      tap(_ => this.messages.add("Order Service: Order Rejected", MessageType.SUCCESS, true)),
      catchError(this.handleError<Order>("Reject Order", item))
    );
  }

  public closeOrder(item: Order, note: string): Observable<Order> {
    return this.http.post<Order>(this.apiUrl + '/close/' + item.orderSegmentId, note).pipe(
      tap(_ => this.messages.add("Order Service: Order Closed", MessageType.SUCCESS, true)),
      catchError(this.handleError<Order>("Close Order", item))
    );
  }

  public getOrderLeadTimes(order: Order): Observable<LeadTimes> {
    return this.http.get<LeadTimes>(this.apiUrl + `/getleadtime?orderId=${order.orderSegmentId}`).pipe(
      catchError(this.handleError<LeadTimes>("Get Order Lead Times", null))
    );
  }

  public getProductLeadTime(product: Product, qty: number = null): Observable<number> {
    let url = this.baseUrl + 'api/product/getleadtime?productId=' + product.productId;
    if (qty) {
      url += '&quantity=' + qty;
    }
    return this.http.get<number>(url).pipe(
      catchError(this.handleError<any>("Get Product Lead Time", null))
    );
  }

  public verifyLeadtime(customer: Customer, partNumber: string): Observable<{ product: Product, leadTime: number }> {
    return this.http.get<number>(this.baseUrl + 'api/product/leadTimeVerification?customerId=' + customer.customerId + '&partNumber=' + encodeURIComponent(partNumber)).pipe(
      catchError(this.handleError<any>("Verify Product Lead Time", null))
    );
  }

  public setLeadTimeBuffer(product: Product, buffer: number): Observable<number> {
    return this.http.post<void>(this.baseUrl + 'api/product/setLeadTimeBuffer/' + product.productId, buffer).pipe(
      catchError(this.handleError<any>("Set Product Lead Time Buffer", null))
    );
  }

  public getQuotePreview(item: Order, overrides?: {
    estimateNotes: string,
    quantityMaps: { [key: string]: ProductQuantity }
    leadTimeBuffers: { [key: string]: number }
  }): Observable<VirtualDocument> {
    let url = this.apiUrl + '/reportPreview/' + item.orderSegmentId;
    return this.http.post<Document>(url, overrides).pipe(
      catchError(this.handleError<any>("Get Formal Quote Preview", null))
    );
  }

  public getQuote(item: Order): Observable<VirtualDocument> {
    let url = this.apiUrl + '/report/' + item.orderSegmentId;
    return this.http.get<Document>(url).pipe(
      catchError(this.handleError<any>("Get Formal Quote Printout", null))
    );
  }

  public getQuoteFromLineItems(item: Order): Observable<VirtualDocument> {
    let url = this.apiUrl + '/getQuoteReportFromLineItems/' + item.orderSegmentId;
    return this.http.get<Document>(url).pipe(
      catchError(this.handleError<any>("Get Formal Quote Printout", null))
    );
  }

  public getSimilarProducts(product: Product, pageIndex?: number): Observable<SearchResult<SimilarPartScore>> {
    return this.http.post<SimilarPartScore[]>(this.baseUrl + 'api/product/getSimilar', product, { params: { pageIndex: (pageIndex || 0).toString() } }).pipe(
      catchError(this.handleError<any>("Get Similar Prodcuts", null))
    );
  }

  public saveProductPurchasedItems(product: Product, items: ProductPurchasedItem[]): Observable<any> {
    items.forEach(item => {
      item.productId = product.productId;
      item.purchasedItem = null;
    });

    return this.http.post<ProductPurchasedItem>(this.baseUrl + 'api/productpurchaseditem/saveMultiple', items).pipe(
      tap(_ => this.messages.add("Order Service: Product Purchased Item [Multiple] Saved Successfully", MessageType.SUCCESS, true)),
      catchError(this.handleError<ProductPurchasedItem[]>("Save Product Purchased Item [Multiple]", items))
    );
  }

  public saveWorkflowSteps(product: Product, steps: WorkflowStep[]): Observable<any> {
    steps.forEach(step => {
      step.workflowId = product.workflowWorkflowId;
      step.workflowStepInventoryItems = [];
      step.outgoingShippingTicket = step.incomingShippingTicket = step.paint = null;
      step.outgoingShippingTicketId = step.incomingShippingTicketId = null;
    });

    return this.http.post<WorkflowStep>(this.baseUrl + 'api/workflowstep/saveMultiple', steps).pipe(
      tap(_ => this.messages.add("Order Service: Workflow Step [Multiple] Saved Successfully", MessageType.SUCCESS, true)),
      catchError(this.handleError<WorkflowStep[]>("Save Workflow Step [Multiple]", steps))
    );
  }

  public deleteWorkflowStepItem(item: WorkflowStepInventoryItem): Observable<any> {
    return this.http.delete<WorkflowStepInventoryItem>(this.baseUrl + `api/workflowstepinventoryitem/${item.workflowStepInventoryItemId}`).pipe(
      tap(_ => {
        this.messages.add("Order Service: Workflow Step Item Removed Successfully", MessageType.SUCCESS, true);
      }),
      catchError(this.handleError<WorkflowStepInventoryItem>("Remove Workflow Step Item", undefined))
    );
  }

  public addWorkflowStepItem(step: WorkflowStep, data: {
    type: WorkflowStepInventoryItemType;
    product: Product;
    purchasedItem: PurchasedItem;
    material: Material;
    quantity: number;
  }) {
    return this.http.post<WorkflowStepInventoryItem>(this.baseUrl + 'api/workflowstep/addItem/' + step.workflowStepId, data).pipe(
      catchError(this.handleError<any>("Add Workflow Step Inventory Item", null))
    );
  }

  public getWorkflowStep(workflowStepId: string): Observable<WorkflowStep> {
    return this.http.get<WorkflowStep>(this.baseUrl + 'api/workflowstep/' + workflowStepId).pipe(
      catchError(this.handleError<any>("Get Workflow Step", null))
    );
  }

  public reorderWorkflow(steps: WorkflowStep[]): Observable<WorkflowStep[]> {
    return this.http.post<WorkflowStep[]>(this.baseUrl + 'api/workflowstep/reorderWorkflow/', steps).pipe(
      catchError(this.handleError<any>("Reorder Workflow", null))
    );
  }

  public getQuoteReport(opts: {
    managerView: boolean;
    showBlocked: boolean;
    receivedFrom?: Date;
    receivedTo?: Date;
    requiredFrom?: Date;
    requiredTo?: Date;
    selectedParts: Product[];
    sortBy: 'customer' | 'required';
  }, user: User): Observable<VirtualDocument> {

    const params = new URLSearchParams({});

    if (!opts.managerView) {
      params.append('salesPersonId', user.userId);
    }

    params.append('showBlocked', opts.showBlocked ? 'true' : 'false');

    if (opts.receivedFrom) params.append('receivedFrom', opts.receivedFrom.toISOString());
    if (opts.receivedTo) params.append('receivedTo', opts.receivedTo.toISOString());
    if (opts.requiredFrom) params.append('requiredFrom', opts.requiredFrom.toISOString());
    if (opts.requiredTo) params.append('requiredTo', opts.requiredTo.toISOString());

    if (opts.selectedParts) {
      for (let part of opts.selectedParts) {
        params.append('selectedParts', part.productId);
      }
    }

    params.append('sortBy', opts.sortBy);

    return this.http.get<Document>(this.apiUrl + '/quoteReportByCustomer?' + params.toString()).pipe(
      catchError(this.handleError<any>("Get Quote Report", null))
    );
  }

  public getQuotePartHistory(opts: {
    issuedFrom?: Date,
    issuedTo?: Date,
    selectedParts: Product[],
  }): Observable<VirtualDocument> {

    const params = new URLSearchParams({});
    if (opts.issuedFrom) params.append('issuedFrom', opts.issuedFrom.toISOString());
    if (opts.issuedTo) params.append('issuedTo', opts.issuedTo.toISOString());

    if (opts.selectedParts) {
      for (let part of opts.selectedParts) {
        params.append('partNumbers', part.partNumber);
      }
    }

    return this.http.get<Document>(this.apiUrl + '/quotePartsReport?' + params.toString()).pipe(
      catchError(this.handleError<any>("Get Quote Part History", null))
    );
  }

  public markAsIssued(orderId: string): Observable<Order> {
    return this.http.post<any>(`${this.apiUrl}/issueQuote/${orderId}`, null).pipe(
      catchError(this.handleError<any>("Mark Quote as Issued", null))
    );
  }

  public returnQuoteToEstimating(orderId: string, note: string): Observable<Order> {
    return this.http.post<any>(`${this.apiUrl}/returnToEstimating/${orderId}`, JSON.stringify(note), { headers: { 'Content-Type': 'application/json' } }).pipe(
      catchError(this.handleError<any>("Return Quote to Estimating", null))
    );
  }

  public resetQuoteLineItems(orderId: string): Observable<QuoteLineItem[]> {
    return this.http.get<any>(`${this.apiUrl}/resetQuoteLineItems/${orderId}`).pipe(
      catchError(this.handleError<any>("Reset Quote Line Items", null))
    );
  }

  public acceptPurchaseOrder(order: Order, purchaseOrderData: {
    purchaseOrderNumber: string,
    addressIsCustom: boolean,
    shipToAddress: Address,
    lineItems: {
      lineItemNumber: string,
      product?: Product,
      station?: Station,
      quantity: number,
      unitPrice: number,
      extPrice: number,
      dueDate: Date
    }[],
    documentId: string,
    poType: CustomerPurchaseOrderType,
    releaseForId?: string
  }): Observable<any> {
    return this.http.post<any>(`${this.apiUrl}/acceptPurchaseOrder/${order.orderSegmentId}`, purchaseOrderData).pipe(
      tap(_ => {
        this.messages.add("Order Service: Purchase Order Accepted Successfully", MessageType.SUCCESS, true);
      }),
      catchError(this.handleError<WorkflowStepInventoryItem>("Accept Customer Purchase Order", undefined))
    );
  }

  public createBatchRFQ(customer: Customer, companyId: string, products: {
    product: Product | null;
    partNumber: string;
    rev: string;
    description: string;
    loading: boolean;
  }[]): Observable<Order> {
    return this.http.post<Order>(`${this.apiUrl}/createBatchRFQ`, { customerId: customer.customerId, companyId, products }).pipe(
      tap(_ => {
        this.messages.add("Order Service: Batch RFQ Created Successfully", MessageType.SUCCESS, true);
      }),
      catchError(this.handleError<Order>("Create Batch RFQ", undefined))
    );
  }

  public searchCompanies(searchFor: string): Observable<SearchResult<Company>> {
    return this.http.get<Company[]>(this.baseUrl + 'api/Company/search', {
      params: {
        searchText: searchFor,
        pageIndex: '0',
        pageSize: '500',
        orderBy: 'name',
        direction: 'desc'
      }
    }).pipe(
      catchError(this.handleError<any>("Get Companies Search Results", null))
    );
  }

  public getPOsForCustomer(customerId: string): Observable<CustomerPurchaseOrder[]> {
    return this.http.get<CustomerPurchaseOrder[]>(this.baseUrl + `api/OrderSegment/getPOsForCustomer/${customerId}`).pipe(
      catchError(this.handleError<any>("Get POs For Customer", null))
    );
  }

  public getWOsForPO(customerPurchaseOrderId: string): Observable<WorkOrder[]> {
    return this.http.get<WorkOrder[]>(this.baseUrl + `api/OrderSegment/getWOsForPO/${customerPurchaseOrderId}`).pipe(
      catchError(this.handleError<any>("Get WOs For Purchase Order", null))
    );
  }

  public getTopParent(product: Product) {
    return this.http.get<Product>(this.baseUrl + 'api/Product/getTopParent/' + product.productId).pipe(
      catchError(this.handleError<any>("Get Product Parent", null))
    );
  }

  public getProductHistory(product: Product) {
    return this.http.get<ProductHistoryEntry[]>(this.baseUrl + 'api/Product/getHistory?partNumber=' + encodeURIComponent(product.partNumber)).pipe(
      catchError(this.handleError<any>("Get Product History", null))
    );
  }

  public saveProductHistory(product: Product, historyData: {
    note: string,
    department: string,
    timestamp: Date,
  }) {
    return this.http.post<ProductHistoryEntry[]>(this.baseUrl + 'api/Product/addHistory/' + product.productId, historyData).pipe(
      catchError(this.handleError<any>("Add Product History Entry", null))
    );
  }

  public reorderHierarchy(data: SortedProductHierarchy[]) {
    return this.http.post<void>(this.baseUrl + 'api/Product/reorderChildren/', data).pipe(
      catchError(this.handleError<any>("Reorder Product Hierarchy", null))
    );
  }

  public makeProductCanonical(product: Product) {
    return this.http.post<Product['productStandard']>(this.baseUrl + 'api/Product/makeCanonical/', product).pipe(
      tap(_ => {
        this.messages.add("Order Service: Canonical Product Set Successfully", MessageType.SUCCESS, true);
      }),
      catchError(this.handleError<any>("Set Canonical Product", null))
    );
  }

  public getAllProductsForOrder(orderId: string): Observable<Product[]> {
    return this.http.get<Product[]>(this.baseUrl + `api/OrderSegment/getAllPartNumbers/${orderId}`).pipe(
      catchError(this.handleError<any>("Get All Child Products", null))
    );
  }

  public fullSave(inputData: {
    orderSegment: Order,
    newItemIds: string[],
    newOPSpecs: OutsideProcessSpecification[],
    quotesMap: { [key: string]: MaterialBid[] },
    newMaterialsMap: { [key: string]: (MaterialSelectComponent['parameterIds']) },
    movedProductIds: string[]
  }) {
    return this.http.post<string>(this.baseUrl + 'api/OrderSegment/fullSave/', inputData).pipe(
      tap(_ => {
        this.messages.add("Order Service: Save Order", MessageType.SUCCESS, true);
      }),
      catchError(this.handleError<any>("Save Order", null))
    );
  }

  public getProductWithTreeClone(id: string): Observable<Product> {
    return this.http.get<Product>(this.baseUrl + 'api/product/getProductWithTreeClone/' + id).pipe(
      catchError(this.handleError<any>("Get Product Detail", null))
    );
  }

  public matchProductAndClone(partNumber: string, revision: string): Observable<Product | null> {
    return this.http.get<Product>(this.baseUrl + 'api/product/matchProductAndClone/', {
      params: {
        partNumber, revision
      }
    }).pipe(
      catchError(this.handleError<any>("Get Product Detail", null))
    );
  }

  public markProductAsCorrected(orderId: string, productId: string): Observable<void> {
    return this.http.post<void>(this.baseUrl + 'api/orderSegment/markProductAsCorrected/' + orderId + '/' + productId, null).pipe(
      tap(_ => {
        this.messages.add("Order Service: Mark Product as Corrected", MessageType.SUCCESS, true);
      }),
      catchError(this.handleError<any>("Mark Product as Corrected", null))
    );
  }
  
  public getSalesNotes(processId: string) {
    return this.http.get<SalesNote>(this.baseUrl + 'api/orderSegment/getSalesNotes/' + processId).pipe(
      catchError(this.handleError<any>("Get Sales Notes", null))
    );
  }

  public saveSalesNote(note: SalesNote) {
    note.author = null;
    if (note.salesNoteId === UtilityService.emptyGuid) {
      return this.http.post<SalesNote>(this.baseUrl + 'api/SalesNote/new', note).pipe(
        tap(_ => this.messages.add("Order Service: Sales Note Created Successfully", MessageType.SUCCESS, true)),
        catchError(this.handleError<SalesNote>("Save New Sales Note", null))
      );
    } else {
      return this.http.post<SalesNote>(this.baseUrl + 'api/salesnote/', note).pipe(
        tap(_ => this.messages.add("Order Service: Sales Note Saved Successfully", MessageType.SUCCESS, true)),
        catchError(this.handleError<SalesNote>("Update Sales Note", null))
      );
    }
  }

  public deleteSalesNote(note: SalesNote) {
    return this.http.delete<void>(this.baseUrl + 'api/SalesNote/' + note.salesNoteId).pipe(
      catchError(this.handleError<SalesNote>("Delete Sales Note", note))
    );
  }

  public saveEstimatingTaskList(order: Order, taskList: TaskList) {
    // We're sending it in as a string so that the server doesn't bother deserializing it
    return this.http.post<void>(this.baseUrl + 'api/orderSegment/saveEstimatingTaskStatus/' + order.orderSegmentId, JSON.stringify(JSON.stringify(taskList)), { headers: { 'Content-Type': 'application/json' } }).pipe(
      catchError(this.handleError<void>("Update Estimating Progress"))
    );
  }

  public cloneRFQ(id: string) {
    return this.http.post<string>(this.baseUrl + 'api/orderSegment/cloneRFQ/' + id, null).pipe(
        tap(_ => this.messages.add("Order Service: RFQ Cloned Successfully", MessageType.SUCCESS, true)),
        catchError(this.handleError<SalesNote>("Clone RFQ", null))
      );

  }

  public setProductRFQReviewStatus(product: OrderProduct, status: OrderSegmentProductReviewStatus, note: string | null) {
    return this.http.post<string>(this.baseUrl + `api/orderSegment/setProductRFQReviewStatus/${product.orderSegmentOrderSegmentId}/${product.productProductId}`, {
      status,
      note
    }).pipe(
        tap(_ => this.messages.add("Order Service: RFQ Cloned Successfully", MessageType.SUCCESS, true)),
        catchError(this.handleError<SalesNote>("Clone RFQ", null))
      );
  }

  public searchOrderProducts(searchText: string, page?: number, orderBy?: string, direction?: string, pageSize?: number) {
    return this.http.get<SearchResult<ProductStandard>>(this.baseUrl + 'api/Product/searchOrderProducts/', { params: {
      searchText,
    }}).pipe(
      catchError(this.handleError<any>("Get Products Search Results", null))
    );
  }

}