import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { SuppliedProduct } from '../supplier-detail/supplier-detail.component';
import { StationService } from '../../../order/services/station.service';
import { NavigationService } from '../../../common/services/navigation.service';
import { Vendor } from '../../../supplier/resources/vendor';
import { VendorService } from '../../../supplier/services/vendor.service';
import { Material } from '../../../order/resources/material';
import { Order } from '../../../order/resources/order';

@Component({
  selector: 'vendor-select',
  templateUrl: './vendor-select.component.html',
  styleUrls: ['./vendor-select.component.less']
})
export class VendorSelectComponent implements OnInit {
  @Input() sidenav: MatSidenav;
  @Input() typeId: string;
  @Input() order: Order;
  @Input() orderId: string;
  @Input() requisitionId: string;
  @Input() product: SuppliedProduct<any>;
  public vendors: Vendor[] = [];
  public searching: boolean = true;
  public selected: Vendor[] = [];
  public showEditor: string = null;
  @ViewChild('insetnav') insetnav: MatSidenav;

  constructor(private vendorSvc: VendorService, private stationSvc: StationService, private navService: NavigationService) { }

  public exit(): void {
    this.sidenav.close();
  }

  public toggleSelection(vendor: Vendor): void {
    var index = this.selected.indexOf(vendor);
    if (index >= 0) {
      this.selected.splice(index, 1);
    } else {
      this.selected.push(vendor);
    }
  }

  public closeSideNav(): void {
    this.showEditor = null;
    this.navService.popBreadCrumb();

    this.exit(); //The child screen is only closed by the "done" button - which should return us to the very top
  }

  public goContact(): void {
    this.showEditor = "contact";
    this.navService.pushBreadcrumb("Contact Supplier");
    this.insetnav.toggle();
  }

  public getProductInfo(): string {
    if (this.product.item.materialId) {
      //Material
      return Material.generatedName(this.product.item);
    }
    else if (this.product.item.stationId) {
      //station
      return this.stationSvc.stationList.find(s => s.stationId == this.product.item.stationId).name;
    }
    else {
      //purchased item
      return this.product.item.description;
    }
  }

  ngOnInit(): void {
    this.vendorSvc.getCapableVendors(this.typeId).subscribe(result => {
      this.vendors = result;
      this.searching = false;
    });
  }
}
