import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { merge, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { LeadTimes } from '../../resources/lead-times';
import { Order } from '../../resources/order';
import { PricingBreakdown } from '../../resources/pricing-breakdown';
import { Product, ProductPurchasedItem } from '../../resources/product';
import { OrderService } from '../../services/order.service';
import { StationService } from '../../services/station.service';
import { EstimateApprovalLeadTimeBreakdownComponent } from '../estimate-approval/estimate-approval-lead-time-breakdown/estimate-approval-lead-time-breakdown.component';
import { QuantityTableComponent } from '../quantity-table/quantity-table.component';
import { WorkflowStep } from '../../resources/workflow';
import { Material } from '../../resources/material';

@Component({
  selector: 'estimate-pricing',
  templateUrl: './estimate-pricing.component.html',
  styleUrls: ['./estimate-pricing.component.less']
})
export class EstimatePricingComponent implements OnDestroy {

  @Input() record: Order;
  @Input() editing: boolean;

  @ViewChild('quantityTable') quantityTable: QuantityTableComponent;
  @ViewChild('leadTimes') leadTimes: EstimateApprovalLeadTimeBreakdownComponent;

  @Input() orderProducts: Product[];
  public pricingBreakdowns: PricingBreakdown[];
  public leadTimeData: LeadTimes;

  constructor(
    private orderService: OrderService,
    public stationService: StationService
  ) { }

  @ViewChildren('quantityTableComponent') components: QueryList<QuantityTableComponent>;


  public save(): void {
    if (this.components) {
      this.components.toArray().forEach(p => p.savePricing());
    }
  }


  externalChange = new EventEmitter();

  public onExternalChange() {
    this.externalChange.emit();
  }
  
  public missingQuotes: {
    item: Material | ProductPurchasedItem | WorkflowStep
  }[] = []
  public onMissingQuote(item: typeof this['missingQuotes'][0]['item']) {
    if (
      ((item as Material).materialId
      &&
      this.missingQuotes.some(mq => (mq.item as Material).materialId === (item as Material).materialId))
      ||
      ((item as ProductPurchasedItem).productPurchasedItemId
      &&
      this.missingQuotes.some(mq => (mq.item as ProductPurchasedItem).productPurchasedItemId === (item as ProductPurchasedItem).productPurchasedItemId))
      ||
      ((item as WorkflowStep).workflowStepId
      &&
      this.missingQuotes.some(mq => (mq.item as WorkflowStep).workflowStepId === (item as WorkflowStep).workflowStepId))
      ) {
        return;
      }
    this.missingQuotes.unshift({ item });
  }
  public getMissingQuoteClassText(item: typeof this['missingQuotes'][0]['item']) {
    if (item.hasOwnProperty('materialId')) {
      return 'Material';
    } else if (item.hasOwnProperty('workflowStepId')) {
      return 'Outsource step';
    } else if (item.hasOwnProperty('purchasedItemId')) {
      return 'Purchased item';
    }
  }
  public getMissingQuoteItemName(item: typeof this['missingQuotes'][0]['item']) {
    if (item.hasOwnProperty('materialId')) {
      return  (item as Material).materialName;
    } else if (item.hasOwnProperty('workflowStepId')) {
      return this.stationService.stationList.find(s => s.stationId === (item as WorkflowStep).stationId)?.name ?? 'Unknown Station';
    } else if (item.hasOwnProperty('purchasedItemId')) {
      return (item as ProductPurchasedItem).purchasedItem?.description;
    }
  }

  quotePreviewWindow: Window = null;
  quoteLoading = false;
  changeSubscription: Subscription;
  loading = true;

  public get overrides() {
    return {
      estimateNotes: this.record.estimateNotes,
      quantityMaps: this.record.products.reduce((acc, p) => {
        acc[p.productProductId] = p.product.quantitiesMap || [];
        return acc;
      }, {}),
      leadTimeBuffers: this.record.products.reduce((acc, p) => {
        acc[p.productProductId] = p.product.leadTimeBuffer || 0;
        return acc;
      }, {}),
      companyId: this.record.companyId
    }
  }

  async ngOnInit() {
    this.loading = true;

    // Fetch all the data at once
    Promise.all([
      this.getAllPricingBreakdowns(),
      this.getAllLeadTimeData(),
    ]).then((results) => {
      this.pricingBreakdowns = results[0];
      this.leadTimeData = results[1];
      this.loading = false;
    })
  }

  async getQuote(): Promise<string> {
    console.log(this.overrides);
    this.quoteLoading = true;
    const doc = await this.orderService.getQuotePreview(this.record, this.overrides).toPromise();
    this.quoteLoading = false;
    return doc.documentId;
  }

  async openQuotePreview() {
    if (!this.quotePreviewWindow || this.quotePreviewWindow.closed) {
      this.quotePreviewWindow = window.open('about:blank', '_blank', 'toolbar=0,location=0,menubar=0,view=fitH');

      this.changeSubscription = merge(
        this.quantityTable.change,
        this.leadTimes.bufferChange,
        this.externalChange
      ).pipe(
        debounceTime(500)
      ).subscribe(async () => {
        const id = await this.getQuote();
        this.quotePreviewWindow.location.href = `/document/${id}?inline=true`;
      });
    }
    const id = await this.getQuote();
    this.quotePreviewWindow.location.href = `/document/${id}?inline=true`;
  }

  @Input() products: Product[];

  async getAllPricingBreakdowns(): Promise<PricingBreakdown[]> {
    return this.orderService.getPricingBreakdownSingleTree(this.record.orderSegmentId).toPromise();
  }

  async getAllLeadTimeData(): Promise<LeadTimes> {
    return this.orderService.getOrderLeadTimes(this.record).toPromise();
  }

  getBreakdownForProduct(product: Product) {
    return this.pricingBreakdowns.find(b => b.productId === product.productId);
  }

  getProductTree(product: Product) {
    return this.orderProducts.find(p => p.productId === product.productId).childAssemblies;
  }

  ngOnDestroy(): void {
    console.log(this.quotePreviewWindow);
    if (this.quotePreviewWindow) {
      this.quotePreviewWindow.close();
    }
  }

}
