<h2 matDialogTitle>Generate RFQ Report</h2>
<mat-dialog-content>
    <div class="form-group">
        <label>RFQs to Report</label>
        <div>
            <span class="pr-2">Just Mine</span>
            <mat-slide-toggle [(ngModel)]="managerView"></mat-slide-toggle>
            <span class="pl-2">Manager View</span>
        </div>
    </div>
    <!-- <div class="form-group">
        <div>
            <mat-checkbox [(ngModel)]="showBlocked">Show Blocked Quotes (awaiting estimate, etc.)</mat-checkbox>
        </div>
    </div> -->
    <div class="form-group">
        <label>Date Range: Received</label>
        <div class="d-flex">
            <date-picker [placeholder]="'From'" [editable]="true" [(date)]="receivedFrom"></date-picker>
            <date-picker [placeholder]="'To'" [editable]="true" [(date)]="receivedTo" [min]="receivedFrom"></date-picker>
        </div>
    </div>
    <div class="form-group">
        <label>Date Range: Required</label>
        <div class="d-flex">
            <date-picker [placeholder]="'From'" [editable]="true" [(date)]="requiredFrom"></date-picker>
            <date-picker [placeholder]="'To'" [editable]="true" [(date)]="requiredTo" [min]="requiredFrom"></date-picker>
        </div>
    </div>
    <div class="form-group">
        <label>Sort By</label>
        <mat-select [(ngModel)]="sortBy">
            <mat-option value="customer">
                Customer
            </mat-option>
            <mat-option value="required">
                Required Date
            </mat-option>
        </mat-select>
    </div>
    <div class="form-group">
        <label>Part Numbers</label>
        <div>
            <mat-form-field class="w-100" appearance="outline">
                <mat-chip-list #chipList aria-label="Parts selection">
                    <mat-chip color="primary"
                        *ngFor="let part of selectedParts"
                    >
                        {{part.partNumber}}
                        <button mat-icon-button matChipRemove>
                            <mat-icon>cancel</mat-icon>
                        </button>
                    </mat-chip>
                    <input
                        placeholder="All Parts"
                        [formControl]="searchControl"
                        [matAutocomplete]="auto"
                        [matChipInputFor]="chipList"
                    >
                </mat-chip-list>
                <i matSuffix [class.invisible]="!loading" class="text-primary fas fa-spin fa-2x fa-circle-notch"></i>
                <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event.option.value)">
                    <mat-option *ngFor="let part of filteredParts | async" [value]="part">
                        <span [innerHTML]="part.partNumber | highlight: searchControl.value"></span>
                    </mat-option>
                </mat-autocomplete>
            </mat-form-field>
        </div>
    </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-button [matDialogClose]="{
        managerView: managerView,
        showBlocked: showBlocked,
        receivedFrom: receivedFrom,
        receivedTo: receivedTo,
        requiredFrom: requiredFrom,
        requiredTo: requiredTo,
        selectedParts: selectedParts,
        sortBy: sortBy
    }">Generate Report</button>
    <button mat-button [matDialogClose]="null">Cancel</button>
</mat-dialog-actions>