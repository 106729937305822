import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { Order } from '../../../order/resources/order';
import { NavigationService } from '../../../common/services/navigation.service';
import { SearchList } from '../../../common/components/search-list/search-list';
import { PurchasingService } from '../../../purchasing/services/purchasing.service';
import { UtilityService } from '../../../common/services/utility.service';
import { MaterialBidService } from '../../services/material-bid.service';
import { SupplierData } from '../../resources/supplierData';

@Component({
  selector: 'supplier-list',
  templateUrl: './supplier-list.component.html',
  styleUrls: ['./supplier-list.component.less']
})
export class SupplierListComponent extends SearchList<SupplierData> {

  public summary: any[] = null;

  constructor(navService: NavigationService, private service: PurchasingService, private materialService: MaterialBidService, private router: Router) {
    super('SupplierListComponent');
    navService.setPageTitle("Purchasing");
  }

  public search(): void {
    this.results = this.summary = null;
    this.service.searchOrdersAndReqs(this.searchString, this.pageNumber, this.sortTerm, this.sortDirection).subscribe(results => {
      this.searchResults = results;
      this.results = results.results;
    });
  }

  public onSelect(record: SupplierData): void {
    this.router.navigate(['/purchasing/quote', record.type.toLowerCase(), record.identifier]);
  }

  public weeksLeft(date: string, abs: boolean): number {
    if (date == null) return null;

    return UtilityService.getWeeksRemaining(new Date(date), abs);
  }

  public getRequiredColor(date: string): string {
    return UtilityService.getDateRequiredColor(date);
  }

}
