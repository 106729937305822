import { Component, Input, Output, EventEmitter, OnInit, ViewChild } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { NgModel } from '@angular/forms';
import { NavigationService } from '../../../common/services/navigation.service';
import { UtilityService } from '../../../common/services/utility.service';
import { VirtualDocument } from '../../../common/resources/virtual-document';
import { VendorCertificate, Vendor } from '../../../supplier/resources/vendor';
import { VendorService } from '../../../supplier/services/vendor.service';

@Component({
  selector: 'vendor-cert-detail',
  templateUrl: './vendor-cert-detail.component.html',
  styleUrls: ['./vendor-cert-detail.component.less']
})
export class VendorCertDetailComponent implements OnInit {
  @Input() cert: VendorCertificate;
  @Input() vendor: Vendor;
  @Input() sidenav: MatSidenav;
  @ViewChild('insetnav') insetnav: MatSidenav;
  @ViewChild('certName') certName: NgModel;
  @ViewChild('issueDate') issueDate: NgModel;
  @Output() certChange: EventEmitter<VendorCertificate> = new EventEmitter<VendorCertificate>();
  public showEditor: string = null;
  public editing: boolean = false;
  public saving: boolean = false;

  constructor(private vendorSvc: VendorService, private navService: NavigationService, private utilitySvc: UtilityService) { }

  cancelEditing(): void {
    if (this.editing) {
      this.toggleEditing();
      return;
    }

    if (this.sidenav)
      this.sidenav.close();
  }

  toggleEditing(): void {
    if (this.cert.vendorCertificateId == "new" || this.cert.vendorCertificateId == UtilityService.emptyGuid) {
      if (this.sidenav)
        this.sidenav.close();
    }

    this.getDetail();
    this.editing = !this.editing;
  }

  openDocumentWindow(): void {
    if (this.certName.invalid || this.issueDate.invalid) {
      this.issueDate.control.markAsTouched();
      this.certName.control.markAsTouched();

      this.utilitySvc.showAlert("Certificate Name and Issue Date are Required", "<p>Please make sure a certificate name and issue date are entered before continuing.</p>");
      return;
    }

    this.showEditor = "document";
    this.navService.pushBreadcrumb("Add Documents");
    this.insetnav.toggle();
  }

  getColor(date: Date): string {
    return VendorService.getStatusColorClass(new Date(date)).replace("text-", "bg-");
  }

  getCertExpirationText(date: Date): string {
    var weeksLeft = UtilityService.getWeeksRemaining(new Date(date), false);

    if (weeksLeft < 0) return " -- EXPIRED -- ";

    if (weeksLeft > 4) {
      weeksLeft = Math.round(weeksLeft / 4);

      return `Expires In ${weeksLeft} Months`;
    }

    return `Expires In ${weeksLeft} Weeks`;
  }

  sidebarClosing(): void {
    this.showEditor = null;
    this.navService.popBreadCrumb();
  }

  async saveChanges() {
    if (this.certName.invalid || this.issueDate.invalid) {
      this.issueDate.control.markAsTouched();
      this.certName.control.markAsTouched();

      this.utilitySvc.showAlert("Certificate Name and Issue Date are Required", "<p>Please make sure a certificate name and issue date are entered before continuing.</p>");
      return;
    }
    this.saving = true;


    if (this.vendor.vendorId === UtilityService.emptyGuid) {
      this.vendor = await this.vendorSvc.save(this.vendor).toPromise();
    }

    await this.vendorSvc.saveCertificate(this.vendor, this.cert).toPromise();
    this.certChange.emit(this.cert);
  }

  deleteDocument(document: VirtualDocument): void {
    this.utilitySvc.showConfirmation("Are you Sure?", "<p>Are you sure you want to remove this document?</p><p class='text-muted font-weight-bold'>This cannot be undone.</p>", (r => {
      if (r) {
        this.saving = true;
        this.vendorSvc.removeDocumentFromCertificate(this.cert, document).subscribe(r => {
          this.saving = false;
          this.getDetail();
        });
      }
    }));
  }

  async addDocuments(documents: VirtualDocument[]) {
    
    this.saving = true;
    this.cert = await this.vendorSvc.saveCertificate(this.vendor, this.cert).toPromise();
    this.cert.vendorCertificateDocuments = await this.vendorSvc.addDocumentsToCertificate(this.cert, documents).toPromise();
    
    this.saving = false;
    this.getDetail();

  }

  getDetail(): void {
    var id = this.cert.vendorCertificateId
    this.cert = null;
    if (id == "new" || id == UtilityService.emptyGuid) {
      this.editing = true;
      this.cert = <VendorCertificate>{
        vendorCertificateId: UtilityService.emptyGuid,
        vendorCertificateDocuments: []
      };
    }
    else {
      this.vendorSvc.getCert(id).subscribe(detail => {
        this.cert = detail;
      });
    }
  }

  ngOnInit(): void {
    this.getDetail();
  }

}
