<loading *ngIf="!locations"></loading>
<loading *ngIf="saving" content="Saving..."></loading>

<div class="container-fluid">
  <a class="position-fixed fixed-bottom btn btn-badge bg-danger has-icon mr-6" title="Cancel" (click)="cancel()"><i class="fas fa-times"></i></a>
  <a class="position-fixed fixed-bottom btn btn-badge bg-success has-icon" title="Continue" (click)="save()"><i class="fas fa-save"></i></a>

  <div class="row mt-4">
    <div class="col">
      <h3 class="text-primary">Requisition Items</h3>
      <div class="form-group mt-4 form-inline">
        <table class="table table-striped border small">
          <thead>
            <tr>
              <th>Item Location</th>
              <th>Quantity</th>
              <th>Available</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let location of locations">
              <td>{{location.inventoryLocation?.name}}</td>
              <td><input class="form-control form-control-sm" type="number" step="1" min="0" [value]="selections[location.inventoryItemLocationId]" [max]="location.quantity" (change)="setQuantity(location.inventoryItemLocationId, $event)" /></td>
              <td>{{location.quantity}}</td>
            </tr>
            <tr *ngIf="locations?.length==0">
              <td colspan="100%">
                <p class="text-muted font-italic text-center">-- No Locations --</p>
                <p class="small text-muted">This item has no inventory location(s) assigned.</p>
              </td>
            </tr>
          </tbody>
        </table>
        <p class="font-weight-bold p-2">Requisitioned: {{totalSelected}}</p>
        <p class="font-weight-bold p-2">Required: {{requiredQuantity}}</p>
      </div>
    </div>
  </div>
</div>
