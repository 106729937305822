import { Directive, Input, HostListener } from "@angular/core";
import { NgControl } from "@angular/forms";
import { MatAutocomplete } from "@angular/material/autocomplete";

@Directive({
    selector: '[requireSelection]',
  })
  export class RequireSelectionDirective {
    @Input('requireSelection') matAutoComplete!: MatAutocomplete;
    @Input('requireSelectionValue') requireSelectionValue: any;
    constructor(private ngControl: NgControl) {}
  
    @HostListener('blur')
    onBlur() {
      const value = this.requireSelectionValue ?? this.ngControl.control?.value;
      const matchingOptions = this.matAutoComplete.options.find(
        (option) => JSON.stringify(option.value) == JSON.stringify(value)
      );
      if (!matchingOptions) {
        this.matAutoComplete._emitSelectEvent(null);
      }
    }
  }