import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { MaterialBidService } from '../../services/material-bid.service';
import { MaterialBid } from '../../resources/materialBid';
import { UserService } from '../../../common/services/user.service';

@Component({
  selector: 'existing-quote-summary',
  templateUrl: './existing-quote-summary.component.html',
  styleUrls: ['./existing-quote-summary.component.less']
})
export class ExistingQuoteSummaryComponent implements OnInit, OnChanges {

  @Input() orderId: string;
  @Input() filterId: string;
  @Input() partNumber: string | null = null;
  @Input() selectionId: string;
  @Input() editable: boolean = false;
  @Output() selectionIdChange: EventEmitter<string> = new EventEmitter<string>();
  @Output() selectionChanged: EventEmitter<MaterialBid> = new EventEmitter<MaterialBid>();
  @Output() edit = new EventEmitter<MaterialBid>();
  @Output() delete = new EventEmitter<MaterialBid>();
  @Output() copy = new EventEmitter<MaterialBid>();

  public data: MaterialBid[];
  public expired: MaterialBid[];
  public showExpired = false;

  constructor(private quoteService: MaterialBidService, private userService: UserService) { }

  public setSelection(bid: MaterialBid): void {
    this.selectionId = bid.materialBidId;
    this.selectionIdChange.emit(this.selectionId);
    this.selectionChanged.emit(bid);
  }

  public isPurchasingManager(): boolean {
    return this.userService.canAccess("PurchasingManager");
  }

  private getDetail(): void {
    if (this.orderId != null && this.filterId != null) {
      this.data = null;
      this.quoteService.searchItem(this.filterId, this.partNumber).subscribe(result => {
        this.expired = result.results.filter(r => this.quoteIsExpired(r));
        this.data = result.results.filter(r => !this.quoteIsExpired(r));
        if (this.data.length === 0) {
          this.showExpiredQuotes()
        }
      });
    }
  }

  public showExpiredQuotes() {
    this.showExpired = true;
    this.data = [...this.data, ...this.expired];
  }

  public quoteIsExpired(q: MaterialBid) {
    // 30 days
    return q.answered && ((new Date().getTime()) - new Date(q.answered).getTime()) > (1000 * 60 * 60 * 24 * 15)
  }

  ngOnInit(): void {
    this.getDetail();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      (changes.orderId && !changes.orderId.isFirstChange() && changes.orderId.currentValue !== changes.orderId.previousValue)
      ||
      (changes.filterId && !changes.filterId.isFirstChange() && changes.filterId.currentValue !== changes.filterId.previousValue)
    ) {
      this.getDetail();
    }
  }

}
