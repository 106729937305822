import { Component, Input, OnInit, TemplateRef, ViewChild, ViewChildren, QueryList, Output, EventEmitter } from '@angular/core';
import { Product, ProductQuantity } from '../../resources/product';
import { MessageType } from '../../../common/resources/message';
import { MessageService } from '../../../common/services/message.service';
import { UtilityService } from '../../../common/services/utility.service';
import { MatDialog } from '@angular/material/dialog';
import { OrderService } from '../../services/order.service';
import { Order } from '../../resources/order';
import { WorkflowStep } from '../../resources/workflow';
import { Station } from '../../resources/station';
import { StationService } from '../../services/station.service';
import { PricingBreakdown } from '../../resources/pricing-breakdown';
import { LeadTimes } from '../../resources/lead-times';

@Component({
  selector: 'quantity-table',
  templateUrl: './quantity-table.component.html',
  styleUrls: ['./quantity-table.component.less']
})
export class QuantityTableComponent implements OnInit {

  constructor(private messageSvc: MessageService, private utilitySvc: UtilityService, private orderSvc: OrderService, private dialog: MatDialog, private stationService: StationService) { }

  @Input() record?: Order;
  @Input() product: Product;
  @Input() editable = false;
  @Input() pricingEditable = true;
  @Input() quantityEditable = true;
  @Input() parentQty: number = 1;
  @Input() parentMarkup: number;
  @Input() breakdown: PricingBreakdown;
  @Input() leadTime: { [productId: string]: { [qty: string]: number }};
  public quantityMap: ProductQuantity[] = [];

  public leadTimeMap: {
    [key: number]: number
  } = {}

  public costBreakdown: PricingBreakdown = null;
  public childCostBreakdown: PricingBreakdown[] = null;

  @Output() change = new EventEmitter<void>()

  public dirty = false;

  public setDirty() {
    this.dirty = true;
    this.change.emit();
  }

  public preventNegative(e: KeyboardEvent) {
    console.log(e)
    if (e.key === "-" || e.keyCode === 17) {
        return false;
    }
  }

  private getCost(product: Product, breakdown: PricingBreakdown, previousQty: number) {
    if (!breakdown) return 0;

    // This is the quantity of this assembly for each individual top-level one
    const lowerQuantity = product.parentAssemblyId ? ((product.quantityAsChild || 1) * previousQty) : previousQty;

    // This is the quantity for the entire order
    const higherQuantity = lowerQuantity * this.parentQty

    const standaloneSteps = product && ((product.productRepairPlan ? product.productRepairPlan.workflow.workflowSteps : (product.workflow && product.workflow.workflowSteps)) || []).filter(s => s.isStandalone);

    const repeatingStandalone = standaloneSteps.filter(s => s.runIsPerPart);
    const oneTimeStandalone = standaloneSteps.filter(s => !s.runIsPerPart);

    const repeatingCosts = [
      breakdown.prices['Labor - Repeating'],
      breakdown.prices['Outsourcing - Repeating'],
      breakdown.prices['Purchased Items - Repeating'],
    ]
      // Change nulls to 0
      .map(c => c ? c : 0)
      // Multiply values by top-level quantity
      .map(c => c * higherQuantity)
      // Add together
      .reduce((acc, x) => acc + x, 0);

    const singleCosts = [
      breakdown.prices['Labor - One Time'],
      breakdown.prices['Outsourcing - One Time'],
      breakdown.prices['Purchased Items - One Time'],
    ]
      // Change nulls to 0
      .map(c => c ? c : 0)
      // Add together
      .reduce((acc, x) => acc + x, 0);

    const matCost = product.productRepairPlan ? 0 : Product.getMaterialCost(product, lowerQuantity);

    // Get batched inspection costs
    const batchedInspectionStepsMap = Object.entries(breakdown.prices["Batched Inspections"] as unknown as { number: number }) as unknown as [number, number][];
    let batchedInspectionCost = 0;
    batchedInspectionStepsMap.forEach(([batchSize, costPerBatch]) => {
      var batchCount = Math.ceil(higherQuantity / batchSize);
      batchedInspectionCost += parseFloat((batchCount * costPerBatch).toFixed(2));
    });

    // Get repeating standalone step costs, times quantities
    const repeatingStandaloneCost = higherQuantity * repeatingStandalone.reduce((acc, s) => {
      return acc + ((s.runTime || 0) / 60) * s.runPrice
    }, 0);

    // Get one time standalone step costs
    const oneTimeStandaloneCost = oneTimeStandalone.reduce((acc, s) => {
      return acc + (s.runTime || 0) * s.runPrice
    }, 0);

    // Recursively get the costs of all children
    const childCosts = product.childAssemblies.map((child) => {
      const childBreakdown = breakdown.childBreakdowns.find(b => b.productId === child.productId);
      return [child.partNumber, this.getCost(child, childBreakdown, lowerQuantity)]
    });
    const childCost = childCosts.reduce((acc, x) => acc + x[1], 0);

    const costSum = repeatingCosts + singleCosts + matCost + batchedInspectionCost + childCost;
    const standaloneSum = repeatingStandaloneCost + oneTimeStandaloneCost;

    return costSum - standaloneSum;
  }

  public getCostForQuantity(qty: number) {
    return this.getCost(this.product, this.costBreakdown, qty);
  }

  public getPriceForQuantity(qty: ProductQuantity) {
    if (qty.priceOverride) {
      return qty.priceOverride
    }
    else {
      return parseFloat((this.getCostForQuantity(qty.value) * (1 + qty.markup / 100)).toFixed(2))
    }
  }

  public getUnitPriceForQuantity(qty: ProductQuantity): number {
    return parseFloat((this.getPriceForQuantity(qty) / qty.value).toFixed(2));
  }

  public getExtPriceForQuantity(qty: ProductQuantity): number {
    return this.getUnitPriceForQuantity(qty) * qty.value;
  }

  public addingQty = null;
  public addQty() {
    if (isNaN(this.addingQty) || this.addingQty === null || <any>this.addingQty === "") {
      this.addingQty = null
      this.messageSvc.add("Quantity Input Invalid!", MessageType.INFO, true)
      return
    }
    const newVal = this.addingQty
    if (this.quantityMap.find(x => x.value === newVal)) {
      this.messageSvc.add(`Already have quantity '${newVal}'`, MessageType.INFO, true)
    } else {
      this.quantityMap.push({
        value: newVal,
        markup: this.globalMarkup !== null ? this.globalMarkup : (this.quantityMap.length > 0 ? this.quantityMap[this.quantityMap.length - 1].markup : 0),
        showOnQuote: true,
      })
      this.quantityMap = this.quantityMap.sort((a, b) => a.value - b.value)
    }
    this.setDirty();
    this.addingQty = null;
    this.orderSvc.getProductLeadTime(this.product, newVal).subscribe(lt => {
      this.leadTimeMap[newVal] = lt;
    })
  }

  public deleteQty(val: number) {
    this.utilitySvc.showConfirmation("Delete Quantity?", 
    `<p>Are you sure you want to delete the quantity <b>${val}</b>?</p>`, (r => {
      if (r) {
        this.quantityMap = this.quantityMap.filter(x => x.value !== val);
        if (val === this.product.orderQuantity) this.product.orderQuantity = null;
        this.setDirty();
      }
    }));
  }

  public get allShownOnQuote() {
    return this.quantityMap.every(x => x.showOnQuote);
  }

  public toggleShowAllOnQuote(e: MouseEvent) {
    e.preventDefault();
    if (this.allShownOnQuote) this.quantityMap.forEach(q => q.showOnQuote = false)
    else this.quantityMap.forEach(q => q.showOnQuote = true)
  }

  @ViewChild('overridePriceDialog', { static: true }) overridePriceDialog: TemplateRef<any>;
  
  public editPriceOverride(qty: ProductQuantity) {
    this.dialog.open(this.overridePriceDialog, {
      disableClose: true,
      data: {
        qty,
        basePrice: parseFloat((this.getCostForQuantity(qty.value) * (1 + qty.markup / 100)).toFixed(2)),
        newPrice: qty.priceOverride || null,
        note: qty.overrideNote || null,
      }
    });
  }

  public setOverride(val: number, price: number, note: string) {
    console.log(val, price, note)
    const i = this.quantityMap.findIndex(x => x.value === val)
    this.quantityMap[i].priceOverride = price;
    this.quantityMap[i].overrideNote = note;
    // set markup based on override
    if (price !== null) {
      const basePrice = this.getCostForQuantity(this.quantityMap[i].value);
      if (basePrice === 0) {
        this.quantityMap[i].markup = 0;
      } else {
        const diff = price - basePrice;
        const percentageDiff = (diff / basePrice) * 100;
        this.quantityMap[i].markup = Math.round(percentageDiff);
      }
      this.setDirty();
    } else {
      this.quantityMap[i].markup = 18;
    }
  }

  public showOverrideNote(note: string) {
    this.utilitySvc.showAlert('Override Note', note)
  }

  globalMarkup = 18;

  get globalMarkupActive(): boolean {
    return this.quantityMap.every(q => q.markup === this.quantityMap[0].markup)
  } 

  public singleMarkupChange() {
    if (this.quantityMap.length === 0) {
      this.globalMarkup = 0
      return
    }
    if (this.globalMarkupActive) {
      this.globalMarkup = this.quantityMap[0].markup
    } else {
      this.globalMarkup = null
    }
  }

  public globalMarkupChange(val: number) {
    this.quantityMap.forEach(q => q.markup = val)
    if (this.product.partNumber === 'ABCD') console.log(this.quantityMap)
  }

  public get childMarkup(): number {
    if (this.product.parentAssemblyId) return this.parentMarkup
    const selectedQtyInfo = this.quantityMap.find(x => x.value === this.product.orderQuantity)
    if (selectedQtyInfo) return selectedQtyInfo.markup
    if (this.globalMarkup) return this.globalMarkup
    else return null
  }

  @ViewChildren('quantityTable') components: QueryList<QuantityTableComponent>;
  public savePricing() {
    console.log(this.product.productId, this.product)
    if (this.dirty) {
      // parent part - save qty map
      if (!this.product.parentAssemblyId) this.orderSvc.setProductPricing(this.product, this.product.orderQuantity, this.quantityMap).subscribe();
      // child part - save part with its changed quantity
      else this.orderSvc.savePart(this.product).subscribe();
    }
    

    if (this.components) {
      this.components.toArray().forEach(p => p.savePricing());
    }
  }

  public hasChildren(): boolean {
    if (this.product == null || this.product.childAssemblies == null) return false;

    return this.product.childAssemblies.length > 0;
  }

  public get allQuantitiesSelected(): boolean {
    if (this.product.parentAssemblyId) return !!this.product.quantityAsChild
    return !!this.product.orderQuantity && (this.components && this.components.toArray().every(c => c.allQuantitiesSelected))
  }

  get totalAssemblyPrice(): number {
    if (!this.allQuantitiesSelected) return null
    if (!this.components || this.components.toArray().some(c => c.totalAssemblyPrice === null)) return null
    if (this.product.parentAssemblyId) {
      if (!this.parentMarkup || !this.product.quantityAsChild) return null
      else return this.getCostForQuantity(this.product.quantityAsChild) * (1 + this.parentMarkup / 100)
    } 
    const orderQtyData = this.quantityMap.find(q => q.value === this.product.orderQuantity)
    if (!orderQtyData) return null
    return (orderQtyData.priceOverride || (this.getCostForQuantity(this.product.orderQuantity) * (1 + orderQtyData.markup / 100)))
      + this.components.toArray().reduce((acc, c) => acc + c.totalAssemblyPrice, 0)
  }
  public showChildren = false;

  private fixBrokenQuantity() {
    const availableQuantities = this.quantityMap.map(x => x.value);
    if (!availableQuantities.includes(this.product.orderQuantity)) this.product.orderQuantity = null;
  }

  public doUpdate() {
    this.orderSvc.getProduct(this.product.productId).subscribe(p => {
      Object.assign(this.product, p);
      this.initialize(true);
    })
  }

  public async initialize(subcomponents = false) {
    console.log(this.product.partNumber, 'initialized')
    this.costBreakdown = this.breakdown;
    this.childCostBreakdown = this.breakdown.childBreakdowns;
    try {
      this.quantityMap = this.product.quantitiesMap
      this.singleMarkupChange()
    } catch (e) {
      this.quantityMap = []
    }
    if (this.quantityMap === null) this.quantityMap = []
    if (this.quantityMap.length === 0) this.product.orderQuantity = null
    this.fixBrokenQuantity()
    this.quantityMap.forEach(q => {
      if (!q.hasOwnProperty('showOnQuote')) q.showOnQuote = true;
    })
    this.quantityMap.forEach(q => {
      this.leadTimeMap[q.value] = this.leadTime[this.product.productId][q.value];
    })
    if (subcomponents && this.components) {
      this.components.toArray().forEach(p => p.initialize(true));
    }
  }

  public getStation(item: WorkflowStep): Station {
    if (this.stationList == null || item == null || item.stationId == null)
      return null;

    return this.stationList.find(r => r.stationId == item.stationId);
  }

  public get standaloneSteps() {
    const steps = this.product && (this.product.productRepairPlan ? this.product.productRepairPlan.workflow.workflowSteps : this.product.workflow.workflowSteps);
    return steps.filter(
      s => s.isStandalone
    );
  }

  getBreakdownForProduct(product: Product) {
    const childBreakdown = this.breakdown.childBreakdowns.find(b => b.productId == product.productId)
    return childBreakdown;
  }

  getNextParentQty(child: Product) {
    const effectiveQuantity = this.product.parentAssemblyId ? this.product.quantityAsChild : this.product.orderQuantity
    return this.parentQty * (effectiveQuantity || 0);
  }

  private stationList: Station[] = null;

  ngOnInit() {
    if (this.stationService.loaded) {
      this.stationList = this.stationService.stationList;
    }
    else {
      this.stationService.stationsLoaded.subscribe(
        _ => this.stationList = this.stationService.stationList
      );
    }

    this.initialize(false);
  }

}
