import { Component, ViewChild, OnInit, ElementRef, OnDestroy, AfterViewInit } from '@angular/core';
import { FieldType } from '@ngx-formly/material';
import { Observable, Subject, combineLatest, of } from 'rxjs';
import { startWith, switchMap, map, tap, takeUntil, withLatestFrom } from 'rxjs/operators';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { FormControl } from '@angular/forms';
import { FieldTypeConfig } from '@ngx-formly/core';
import { MatChipInputEvent } from '@angular/material/chips';
import { MatAutocomplete, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';

@Component({
  template: `
    <mat-chip-list #chipList>
      <mat-chip
        *ngFor="let item of formControl.value;let i = index"
        [selectable]="selectable"
        [removable]="removable"
        (removed)="remove(i)"
      >
        {{ displayWith(item) | async }}
        <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
      </mat-chip>
      <input
        matInput
        [formControl]="itemControl"
        [matAutocomplete]="auto"
        #autoTrigger="matAutocompleteTrigger"
        [matAutocompleteNoDisplay]="autoTrigger"
        (blur)="onBlur()"
      />
    </mat-chip-list>
    <mat-autocomplete
      #auto="matAutocomplete"
      (optionSelected)="selected($event)"
    >
      <mat-option *ngFor="let item of (filter | async)" [value]="item">
        {{ optionDisplayWith(item) | async }}
      </mat-option>
    </mat-autocomplete>
    <mat-progress-bar class="progress-bar" mode="indeterminate" *ngIf="loading"></mat-progress-bar>
  `,
  styles: [`
    .progress-bar {
      position: absolute;
      bottom: 0;
      left: -8px;
      width: calc(100% + 16px) !important;
      border-radius: 9px;
    }
  `]
})
export class FormlyChipListTypeComponent extends FieldType<FieldTypeConfig> implements AfterViewInit {

  itemControl = new FormControl();
  selectable = true;
  removable = true;
  addOnBlur = true;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  loading = false;

  @ViewChild('auto') matAutocomplete: MatAutocomplete;

  filter: Observable<any>;


  refilterLocal = new Subject<void>();

  ngAfterViewInit() {
    this.filter = this.itemControl.valueChanges.pipe(
      startWith(''),
      tap(() => this.loading = true),
      switchMap((term) => this.props.filter(term)),
      map((items: any[]) => 
        items.filter(
          i => !this.formControl.value.includes(this.compareWith(i))
        )
      ),
      tap(() => this.loading = false),
    );
  }


  get empty() { return this.formControl.value.length === 0; }

  public add(event: MatChipInputEvent): void {
    // select only
    // // Add item only when MatAutocomplete is not open
    // // To make sure this does not conflict with OptionSelected Event
    // if (!this.matAutocomplete.isOpen) {
    //   const input = event.input;
    //   const value = event.value;

    //   // Add item
    //   if ((value || '').trim()) {
    //     this.formControl.setValue([
    //       ...this.formControl.value,
    //       value.trim(),
    //     ]);
    //   }

    //   // Reset the input value
    //   if (input) {
    //     input.value = '';
    //   }

    //   this.itemControl.setValue(null);
    // }
  }

  public selected(event: MatAutocompleteSelectedEvent): void {
    this.formControl.setValue([
      ...this.formControl.value,
      this.compareWith(event.option.value),
    ]);


    this.itemControl.setValue('');
  }

  public remove(i: number): void {
    const value = this.formControl.value;

    this.formControl.setValue([
      ...value.slice(0, i),
      ...value.slice(i + 1, value.length)
    ]);
    this.formControl.markAsTouched()
  }

  private _filter(value: any): any[] {
    if (!this.to.filter) return [];
    if (!value) return this.to.filter.slice();

    const filterValue = value.toLowerCase();

    return this.to.filter.filter(
      item => item.toLowerCase().indexOf(filterValue) === 0
    );
  }

  onBlur() {
    this.formControl.markAsTouched();
    this.field.focus = false;
  }

  public displayWith(item: any) {
    if (!this.props.displayWith) return of(item);
    if (!item) return of(item);
    else return this.props.displayWith(item);
  }

  public compareWith(item: any) {
    if (!this.props.compareWith) return item;
    if (!item) return item;
    else return this.props.compareWith(item);
  }

  public optionDisplayWith(item: any) {
    if (!this.props.compareWith) return item;
    if (!item) return item;
    else return this.displayWith(this.props.compareWith(item));
  }
}
