<ul class="list-unstyled">
  <li (click)="bubbleSelection(product)" title="Click to Select">
    <span *ngIf="hasChildren" title="Click to Expand" (click)="toggleExpansion($event)" class="badge badge-pill small-badge badge-secondary mr-2">
      <i class="fas fa-lg fa-fw" [ngClass]="{'fa-angle-double-right':(!isExpanded), 'fa-angle-double-down':(isExpanded)}"></i>
    </span>
    <span class="name relative">
      <i *ngIf="childrenLoaded; else loadingChildren" [ngClass]="getIcon()" class="fas fa-fw"></i>
      <i *ngIf="childrenLoaded && product.productRepairPlan" class="repair-wrench fa-flip-horizontal fa-fw fa-sm fa-wrench fas"></i>
      <ng-template #loadingChildren>
        <i class="fas fa-fw fa-lg fa-circle-notch fa-spin text-info"></i>
      </ng-template>
      {{product?.partNumber}} / Rev {{product?.revision}}
      <span *ngIf="product.productRepairPlan" class="ml-1 badge badge-danger">REPAIR</span>
      <span *ngIf="firstQuantity!=null" class="ml-1 small-badge badge badge-secondary text-dark">{{firstQuantity}}</span>
      <span *ngIf="editing" class="delete-button d-inline-block ml-1 text-white" (click)="deleteProduct(product, $event)">
        <i class="fas fa-times-circle"></i>
      </span>
    </span>
    <span *ngIf="isExpanded && hasChildren" class="d-block ml-4 mt-2">
      <product-tree (selected)="bubbleSelection($event)" (delete)="deleteChildProduct(product, child)" (deleteChild)="deleteChildProduct($event.parent, $event.child)" [product]="child" [editing]="editing" *ngFor="let child of product.childAssemblies"></product-tree>
    </span>
  </li>
</ul>
