import { Component, OnInit } from '@angular/core';
import { OrderDetailService } from '../order-detail.service';
import { Observable, combineLatest } from 'rxjs';
import { Product, ProductDocument, ProductPurchasedItem } from '../../../resources/product';
import { map, shareReplay, startWith } from 'rxjs/operators';
import { Material } from '../../../resources/material';
import { WorkflowStep } from '../../../resources/workflow';
import { PurchasedItem } from '../../../resources/purchased-item';
import { StationService } from '../../../services/station.service';
import { VirtualDocument } from '../../../../common/resources/virtual-document';

type SimpleProduct = Pick<Product, 'partNumber' | 'revision' | 'productId'> &
{ material?: boolean, workflowStep?: WorkflowStep, purchasedItem?: ProductPurchasedItem }

@Component({
  selector: 'order-detail-microticket-screen',
  templateUrl: './order-detail-microticket-screen.component.html',
  styleUrls: ['./order-detail-microticket-screen.component.less']
})
export class OrderDetailMicroticketScreenComponent implements OnInit {

  constructor(
    public service: OrderDetailService,
    public stationService: StationService
  ) { }

  public get record() { return this.service.order }

  public partNumbers: Observable<SimpleProduct[]>
  public documents: Observable<VirtualDocument[]>

  private recursiveName(product: Product): SimpleProduct[] {
    const base = { partNumber: product.partNumber, revision: product.revision, productId: product.productId};
    return [
      base,
      { ...base, material: true } ,
      ...product.workflow.workflowSteps.map(ws => ({ ...base, workflowStep: ws})),
      ...product.purchasedItems.map(pi => ({ ...base, purchasedItem: pi})),
      ...product.childAssemblies.flatMap(x => this.recursiveName(x))
    ].filter(x => x !== null);
  }

  private getDocuments(prod: Product): VirtualDocument[] {
    return [...prod.documents.map(d => d.document), ...prod.childAssemblies.flatMap(c => this.getDocuments(c))];
  }

  ngOnInit(): void {
    this.partNumbers = combineLatest([this.service.productsModified, this.service.productEdited.pipe(startWith(null))]).pipe(
      shareReplay(1),
      map(([x, _]) => x.flatMap(y => this.recursiveName(y.product))),
    );
    this.documents = combineLatest([this.service.productsModified, this.service.orderEdited.pipe(startWith(this.record)), this.service.productEdited.pipe(startWith(null))]).pipe(
      shareReplay(1),
      map(([x, z, _]) => [...z.documents.map(d => d.document), ...x.flatMap(y => this.getDocuments(y.product))]),
    );
  }

  public partNumberDisplayWith(item: SimpleProduct) {
    const base = `${item.partNumber} Rev. ${item.revision}`;
    if (item.material) {
      return `${base} - Material`;
    } else if (item.workflowStep) {
      const station = this.stationService?.stationList?.find(s => s.stationId === item.workflowStep.stationId);
      return `${base} - ${station?.name ?? 'Workflow Step'}`;
    } else if (item.purchasedItem) {
      return `${base} - Purchased Item - ${item.purchasedItem.purchasedItem?.description}`
    } else {
      return base;
    }
  }
  public partNumberCompareWith(item: SimpleProduct) {
    if (item.material) {
      return this.service.generateSubItemNavigationId(item.productId, "material");
    } else if (item.workflowStep) {
      return this.service.generateSubItemNavigationId(item.productId, "workflow", item.workflowStep.workflowStepId);
    } else if (item.purchasedItem) {
      return this.service.generateSubItemNavigationId(item.productId, "hardware", item.purchasedItem.productPurchasedItemId);
    } else {
      return item.productId;
    }
  }

  public jumpToSubitem(item: string) {
    this.service.navigateToSubItem(item);
  }
}
