import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Message, MessageType } from '../resources/message'

@Injectable({
  providedIn: 'root',
})
export class MessageService {
  private clearInterval: number = 2500;
  private messageObservable: BehaviorSubject<Message[]> = new BehaviorSubject([]);
  private msgArry: Message[] = [];
  messages: Observable<Message[]> = this.messageObservable.asObservable();

  constructor() { }

  add(message: string, type?: number, selfDestruct?: boolean): Message {
    var newMsg: Message = new Message();
    newMsg.messageText = message;
    newMsg.messageType = type || MessageType.GENERAL;

    this.msgArry.push(newMsg);
    this.messageObservable.next(this.msgArry);

    if (selfDestruct || false) {
      setTimeout(_ => this.remove(newMsg.messageId), this.clearInterval);
    }

    return newMsg;
  }

  remove(messageId: string): void {
    this.msgArry = this.msgArry.filter(msg => msg.messageId !== messageId);
    this.messageObservable.next(this.msgArry);
  }

  clear(): void {
    this.msgArry = [];
    this.messageObservable.next(this.msgArry);
  }

}
