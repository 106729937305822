import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { MessageService } from '../../common/services/message.service';
import { catchError, tap } from 'rxjs/operators';
import { MessageType } from '../../common/resources/message';
import { httpService } from '../../common/services/http.service';
import { UtilityService } from '../../common/services/utility.service';
import { ErrorHandlerService } from '../../common/services/errorHandler.service';
import { SearchResult } from '../../common/resources/search-result';
import { WorkOrder } from '../resources/work-order';
import { MachineAssignment } from '../resources/machine-assignment';
import { Order } from '../../order/resources/order';
import { Product, ProductMaterialDimension } from '../../order/resources/product';
import { WorkflowStepProgress } from '../resources/workflow-step-progress';
import { WorkflowStep } from '../../order/resources/workflow';
import { User } from '../../common/resources/user';
import { ProgrammingTicket } from '../resources/programming-ticket';
import { VirtualDocument } from '../../common/resources/virtual-document';
import { Paint } from '../../order/resources/paint';
import { PlanningTicket } from '../resources/planning-ticket';


export interface WorkOrderShippingAssignment {
  workflowStepId: string;
  sendingBuildingId?: string;
  sendingDateTime?: Date;
  receivingBuildingId?: string;
  receivingDateTime?: Date;
}

export interface PreplanningMaterialSpec {
  materialSpecificationId: string;
  isRequired: boolean;
}
export type PreplanningData = {
    product: Product;
    paintList: Paint[];
    specs: PreplanningMaterialSpec[];
    createProgrammingCNC: boolean;
    assignedProgrammerCNC?: string;
    createProgrammingNC: boolean;
    assignedProgrammerNC?: string; 
    createProgrammingCMM: boolean;
    assignedProgrammerCMM?: string; 
    assignedPlanner: string;
};

@Injectable({
  providedIn: 'root',
})
export class WorkOrderService extends httpService {
  private apiBase: string = 'api/workorder';
  private apiUrl: string;

  constructor(errorHandler: ErrorHandlerService, private messages: MessageService, private http: HttpClient, @Inject('BASE_URL') private baseUrl: string) {
    super(errorHandler, messages);
    this.serviceName = "WorkOrder";

    this.apiUrl = this.baseUrl + this.apiBase;
  }

  public search(overrideView?: boolean, searchString?: string, page?: number, sortBy?: string, sortDirection?: string): Observable<SearchResult<WorkOrder>> {
    return this.http.get<SearchResult<WorkOrder>>(this.apiUrl + '/filter', { params: { searchText: searchString, forAllUsers: ((overrideView || false) ? "true" : "false"), pageIndex: (page || 0).toString(), orderBy: sortBy || "name", direction: sortDirection || "asc" } }).pipe(
      catchError(this.handleError<any>("Get Work Orders Search Results", null))
    );
  }

  public programmingSearch(searchString?: string, page?: number, sortBy?: string, sortDirection?: string): Observable<SearchResult<ProgrammingTicket>> {
    return this.http.get<SearchResult<ProgrammingTicket>>(this.baseUrl + 'api/programmingticket/filter', { params: { searchText: searchString, pageIndex: (page || 0).toString(), orderBy: sortBy || "name", direction: sortDirection || "asc" } }).pipe(
      catchError(this.handleError<any>("Get Programming Ticket Search Results", null))
    );
  }

  public getDetail(id: string): Observable<WorkOrder> {
    return this.http.get<WorkOrder>(this.apiUrl + '/' + id).pipe(
      catchError(this.handleError<any>("Get Work Order Detail", null))
    );
  }

  public getAssignmentsForWorkOrder(wo: WorkOrder): Observable<MachineAssignment[]> {
    return this.http.get<MachineAssignment[]>(this.apiUrl + '/getassignmentsforworkorder/' + wo.workOrderId).pipe(
      catchError(this.handleError<any>("Get Work Order Assignments", null))
    );
  }

  public assign(workOrderId: string, toUser: User): Observable<void> {
    return this.http.get<void>(this.apiUrl + `/assign/${workOrderId}?userId=${toUser.userId}`).pipe(
      tap(_ => this.messages.add("Planning Ticket Service: Assign Planning Ticket", MessageType.SUCCESS, true)),
      catchError(this.handleError<void>("Assign Planning Ticket", null))
    );
  }

  public getWorkflowStepDetail(workflowStepId: string): Observable<WorkflowStep> {
    return this.http.get<WorkflowStep>(this.baseUrl + 'api/workflowstep/' + workflowStepId).pipe(
      catchError(this.handleError<any>("Get Workflow Step Detail", null))
    );
  }

  public getShippingAssignmentsForWorkOrder(wo: WorkOrder): Observable<WorkOrderShippingAssignment[]> {
    return this.http.get<WorkOrderShippingAssignment[]>(this.apiUrl + '/getShippingAssignments/' + wo.workOrderId).pipe(
      catchError(this.handleError<any>("Get Work Order Shipping Assignments", null))
    );
  }

  public getWorkOrderProgress(workOrderId: string): Observable<WorkflowStepProgress[]> {
    return this.http.get<WorkflowStepProgress[]>(this.apiUrl + '/getWorkOrderProgress/' + workOrderId).pipe(
      catchError(this.handleError<any>("Get Work Order Progress", null))
    );
  }

  public newWorkOrderFromProduct(order: Order, product: Product) {
    return this.http.post<WorkOrder>(this.apiUrl + '/createFromProduct/' + order.orderSegmentId + '/' + product.productId, null).pipe(
      tap(_ => this.messages.add("Work Order Service: Order Created Successfully", MessageType.SUCCESS, true)),
      catchError(this.handleError<WorkOrder>("Create New Work Order From Product", null))
    )
  }

  public newWorkOrderFromProductId(orderId: string, productId: string) {
    return this.http.post<WorkOrder>(this.apiUrl + '/createFromProduct/' + orderId + '/' + productId, null).pipe(
      tap(_ => this.messages.add("Work Order Service: Order Created Successfully", MessageType.SUCCESS, true)),
      catchError(this.handleError<WorkOrder>("Create New Work Order From Product", null))
    )
  }

  public save(item: WorkOrder): Observable<WorkOrder> {

    if (item.workOrderId == UtilityService.emptyGuid) {
      //New Item
      return this.http.post<WorkOrder>(this.apiUrl + '/new', item).pipe(
        tap(_ => this.messages.add("Work Order Service: Order Saved Successfully", MessageType.SUCCESS, true)),
        catchError(this.handleError<WorkOrder>("Save New Work Order", item))
      );
    }
    else {
      //Existing Item - we can just do a simple update here
      return this.http.post<WorkOrder>(this.apiUrl, item).pipe(
        tap(_ => this.messages.add("Work Order Service: Order Saved Successfully", MessageType.SUCCESS, true)),
        catchError(this.handleError<WorkOrder>("Update Work Order", item))
      );
    }
  }

  public finishPreplanning(wo: WorkOrder, preplanningData: PreplanningData) {
    return this.http.post<WorkOrder>(this.apiUrl + '/finishPreplanning/' + wo.workOrderId, preplanningData).pipe(
      tap(_ => this.messages.add("Work Order Service: Preplanning Finished Successfully", MessageType.SUCCESS, true)),
      catchError(this.handleError<WorkOrder>("Finish Preplanning", null))
    )
  }

  public returnToPreplanning(workOrder: WorkOrder): Observable<WorkOrder> {
    return this.http.post<WorkOrder>(this.apiUrl + '/returnToPreplanning/' + workOrder.workOrderId, null).pipe(
      tap(_ => this.messages.add("Work Order Service: Returned to Preplanning", MessageType.SUCCESS, true)),
      catchError(this.handleError<WorkOrder>("Return to Preplanning", null))
    )
  }

  public getTraveler(item: WorkOrder): Observable<VirtualDocument> {
    let url = this.baseUrl + 'api/orderSegment/getTraveler/' + item.workOrderId;
    return this.http.get<Document>(url).pipe(
      catchError(this.handleError<any>("Get Traveler", null))
    );
  }

  public newSubassemblyPlanning(planningTicket: PlanningTicket, product: Product): Observable<{ workOrder: WorkOrder, product: Product }> {
    return this.http.post<any>(this.apiUrl + '/createSubassembly/' + planningTicket.planningTicketId, product).pipe(
      tap(_ => this.messages.add("Work Order Service: Created Subassembly", MessageType.SUCCESS, true)),
      catchError(this.handleError<WorkOrder>("Create Subassembly", null))
    )
  }

  public getOpenOrders() {
    return this.http.get<Document>(this.apiUrl + '/openOrders/').pipe(
      catchError(this.handleError<any>("Get Open Order Report", null))
    );
  }

}
