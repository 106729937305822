import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { OutsideProcessSpecification, Station } from '../../../order/resources/station';
import { UtilityService } from '../../../common/services/utility.service';
import { StationService } from '../../../order/services/station.service';

@Component({
  selector: 'op-specification-editor',
  templateUrl: './op-specification-editor.component.html',
  styleUrls: ['./op-specification-editor.component.less']
})
export class OpSpecificationEditorComponent implements OnInit, OnChanges {

  @Input() station: Station;

  public specs: OutsideProcessSpecification[] = [];
  public refreshSpecs() {
    const st = this.stationSvc.stationList.find(s => s.stationId === this.station.stationId);
    this.specs = st?.outsideProcessSpecifications?.filter(s => !s.isDeleted).reverse() ?? [];
  }

  public editingId: string | null = null;
  public isEditing(spec: OutsideProcessSpecification) {
    return spec.outsideProcessSpecificationId === this.editingId;
  }

  public oldVersion: OutsideProcessSpecification | null = null;

  public cancelEditing() {
    if (this.oldVersion && this.oldVersion.outsideProcessSpecificationId === this.editingId) {
      const idx = this.station.outsideProcessSpecifications.findIndex(s => s.outsideProcessSpecificationId === this.editingId);
      if (idx !== -1) {
        this.station.outsideProcessSpecifications.splice(idx, 1, this.oldVersion);
        this.station.outsideProcessSpecifications = [...this.station.outsideProcessSpecifications];
      }
    }
    this.oldVersion = null;
    this.editingId = null;
    this.addingNew = null;
  }

  public edit(spec: OutsideProcessSpecification) {
    this.cancelEditing();
    this.oldVersion = JSON.parse(JSON.stringify(spec));
    this.editingId = spec.outsideProcessSpecificationId;
  }

  @Input() saving: boolean;
  @Output() savingChange = new EventEmitter<boolean>();

  public async deleteSpec(spec: OutsideProcessSpecification) {
    const r = await this.utilitySvc.showConfirmationPromise('Are you sure?', 'This cannot be undone.');
    if (!r) return;
    this.savingChange.emit(true);
    await this.stationSvc.deleteSpec(spec).toPromise();
    this.savingChange.emit(false);
    this.refreshSpecs();
  }

  public async saveEdit(spec: OutsideProcessSpecification) {
    this.savingChange.emit(true);
    await this.stationSvc.editSpec(spec).toPromise();
    this.savingChange.emit(false);
    this.refreshSpecs();
    this.oldVersion = null;
    this.editingId = null;
  }

  public addingNew: string | null = null;
  public startNew() {
    this.cancelEditing();
    this.addingNew = '';
  }

  public async saveNew(name: string) {
    this.savingChange.emit(true);
    await this.stationSvc.createSpec(this.station.stationId, name).toPromise();
    this.savingChange.emit(false);
    this.refreshSpecs();
    this.addingNew = null;
  }

  constructor(private utilitySvc: UtilityService, private stationSvc: StationService) { }

  ngOnInit(): void {
    this.refreshSpecs();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.station) {
      this.cancelEditing();
      this.refreshSpecs();
    }
  }

}
