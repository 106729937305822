import { Component, Input, Output, EventEmitter } from '@angular/core';
import { Order } from '../../resources/order';
import { UtilityService } from '../../../common/services/utility.service';
import { Customer, CustomerContact } from '../../../customer/resources/customer';
import { CustomerService } from '../../../customer/services/customer.service';
import { VirtualDocument } from '../../../common/resources/virtual-document';
import { Address } from '../../../common/resources/address';

@Component({
  selector: 'order-info-detail',
  templateUrl: './order-info-detail.component.html',
  styleUrls: ['./order-info-detail.component.less']
})
export class OrderInfoDetailComponent {
  @Input() @Output() record: Order;
  @Input() editing: boolean = false;
  @Output() openDocuments: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() showCustomer: EventEmitter<string> = new EventEmitter<string>();
  @Output() showCustomerContact: EventEmitter<string> = new EventEmitter<string>();
  @Output() onDeleteDocument: EventEmitter<VirtualDocument> = new EventEmitter<VirtualDocument>();
  @Output() showRMA: EventEmitter<void> = new EventEmitter<void>();

  public customerLoading = false;

  constructor(private customerService: CustomerService) { }

  public weeksLeft(date: string, abs: boolean): number {
    if (date == null) return null;

    return UtilityService.getWeeksRemaining(new Date(date), abs);
  }

  public getRequiredColor(date: string): string {
    return UtilityService.getDateRequiredColor(date);
  }

  public async assignCustomer(c: Customer) {

    this.customerLoading = true;
    try {
      const customer = await this.customerService.getDetail(c.customerId).toPromise();
      this.record.customer = customer;
      this.record.customerId = (customer != null) ? customer.customerId : null;

      //Reset these...
      this.record.customerContact = null;
      this.record.customerContactId = null;

      this.record.shipToAddress = null;
      this.record.shipToAddressId = null;
    } catch (e) {

    } finally {
      this.customerLoading = false;
    }
    
  }

  public setContact(c: CustomerContact): void {
    this.record.customerContact = c;
    this.record.customerContactId = (c != null) ? c.customerContactId : null;
  }

  public openDocumentWindow(): void {
    this.openDocuments.emit(true);
  }

  public showAddCustomer(nameHint): void {
    this.showCustomer.emit(nameHint);
  }

  public showAddContact(nameHint): void {
    this.showCustomerContact.emit(nameHint);
  }

  public deleteDocument(document: VirtualDocument) {
    this.onDeleteDocument.emit(document);
  }

  public onShipToAddressChange(addressId: string) {
    if (addressId) {
      this.record.shipToAddress = this.record.customer.customerAddresses.find(x => x.addressId === addressId).address;
    }
  }

}
