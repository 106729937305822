<div class="position-relative">
  <span *ngIf="editable" class="position-absolute dropdown-indicator" (click)="toggleDropdown($event)"><i class="fas" [ngClass]="{'text-muted fa-chevron-down':!searching, 'fa-lg fa-circle-notch fa-spin text-info': searching}"></i></span>
  <input required #autoCompleteTrigger="matAutocompleteTrigger" tabindex="-1" #customerInputText matInput placeholder="Customer" [readonly]="!editable" [ngClass]="{'form-control-plaintext':!editable}" class="form-control form-control-sm" type="text" [matAutocomplete]="customerList" [formControl]="searchControl" />
  <mat-autocomplete autoActiveFirstOption #customerList="matAutocomplete" (opened)="opened.next()" (optionSelected)="setValue($event.option.value)">
    <mat-option *ngFor="let c of filteredCustomers | async" [value]="c">
      <span [innerHTML]="c.businessName | highlight: searchControl.value"></span>
      <span class="float-right small text-muted">Cust# C-{{c.customerNum | number:'8.0' | noComma}}</span>
    </mat-option>
    <mat-option *ngIf="(filteredCustomers | async)?.length==0 && !searching">
      <button *ngIf="canAdd() && !addDisabled" class="float-right btn btn-primary btn-sm mt-4" (click)="onAddCustomer()">
        <i class="fas fa-fw fa-lg fa-plus"></i> Add New Customer
      </button>
      <p class="text-muted small text-center mt-3">
        <em>-- No Matching Customers Found --</em>
      </p>
    </mat-option>
  </mat-autocomplete>
</div>