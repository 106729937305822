import { NestedTreeControl } from '@angular/cdk/tree';
import { Component, ElementRef, forwardRef, Input, OnInit, Self, ViewChild } from '@angular/core';
import { ControlValueAccessor, FormControl, NgControl, NgModel, NG_VALIDATORS, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatTreeNestedDataSource } from '@angular/material/tree';
import { debounceTime, tap } from 'rxjs/operators';
import { InventoryLocation } from '../../resources/inventory-location';
import { InventoryService } from '../../services/inventory.service';


type AsyncChildrenLocation = InventoryLocation & { childrenLoaded?: boolean; loading?: boolean };


@Component({
  selector: 'inventory-tree-select',
  templateUrl: './inventory-tree-select.component.html',
  styleUrls: ['./inventory-tree-select.component.less'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InventoryTreeSelectComponent),
      multi: true
    }
  ]
})
export class InventoryTreeSelectComponent implements OnInit, ControlValueAccessor {

  @Input() label: string = "Location";

  range(n: number): any[] {
    return Array(n);
  }

  treeControl = new NestedTreeControl<AsyncChildrenLocation>(node => node.children);
  dataSource = new MatTreeNestedDataSource<AsyncChildrenLocation>();

  locationFilterControl = new FormControl<string>('');

  searchingLocations = false;

  constructor(private service: InventoryService) {
    this.dataSource.data = [];
    this.searchingLocations = true;
    this.doFilter('').then(() => {
      if (this.value) this.locationBreadcrumbs = this.getLocationBreadcrumbsRecursive(this.value.inventoryLocationId, this.dataSource.data);
    });
  }

  async toggleNode(node: AsyncChildrenLocation) {
    if (this.treeControl.isExpanded(node)) {
      this.treeControl.collapse(node);
      return;
    }
    else if (!node.childrenLoaded) {
      node.loading = true;
      const children = await this.service.getLocationChildren(node).toPromise();
      node.loading = false;
      node.children = children;
      node.childrenLoaded = true;
    }
    let _data = this.dataSource.data;
    this.dataSource.data = null;
    this.dataSource.data = _data;
    this.treeControl.expand(node);
  }
  
  hasChild = (_: number, node: AsyncChildrenLocation) => (!!node.children && node.children.length > 0) || !node.childrenLoaded;

  getLevel(data: AsyncChildrenLocation[], node: AsyncChildrenLocation) {
    let path = data.find(branch => {
      return this.treeControl
        .getDescendants(branch)
        .some(n => n.inventoryLocationId === node.inventoryLocationId);
    });
    if (!path || !path.children) return 0;
    return path ? this.getLevel(path.children, node) + 1 : 0 ; 
  }

  getLocationBreadcrumbsRecursive(id: string, data: AsyncChildrenLocation[] | null): AsyncChildrenLocation[] | null {
    if (data) {
      for (let item of data) {
        if (item.inventoryLocationId === id) {
          return [item];
        }
        const a = item.children ? this.getLocationBreadcrumbsRecursive(id, item.children) : null;
        if (a !== null) {
          a.unshift(item);
          return a;
        }
      }
    }
    return null;
  }

  public locationBreadcrumbs: AsyncChildrenLocation[] = [];

  @ViewChild('locationTreeCard') locationTreeCard: ElementRef<HTMLDivElement>;

  public editLocation() {
    if (!this.value) return;
    this.writeValue(null);
    setTimeout(() => this.locationTreeCard.nativeElement.scrollIntoView());
  }

  public inventoryLocation: AsyncChildrenLocation | null = null;
  public get value() { return this.inventoryLocation; }

  onChange = (location: AsyncChildrenLocation | null) => {};
  onTouched = () => {};

  writeValue(location: AsyncChildrenLocation | null) {
    this.inventoryLocation = location;
    if (location) this.locationBreadcrumbs = this.getLocationBreadcrumbsRecursive(location.inventoryLocationId, this.dataSource.data);
    this.onChange(this.inventoryLocation);
  }

  registerOnChange(fn: (location: AsyncChildrenLocation) => void): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  public disabled = false;

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  expandAll(data: AsyncChildrenLocation[]) {
    data.forEach(
      x => { 
        this.treeControl.expand(x);
        x.childrenLoaded = true;
        if (x.children) this.expandAll(x.children);
      }
    );
  }

  // Filtering code
  public locationFilter = '';

  async doFilter(filter: string) {
    const trimmedFilter = filter.toLowerCase().trim();
    const locs = await this.service.locationTreeFilter(trimmedFilter).toPromise();
    this.dataSource.data = locs;
    this.treeControl.collapseAll();
    if (trimmedFilter) this.expandAll(locs);
    this.searchingLocations = false;
  }

  @ViewChild('locationFilterModel') locationFilterModel: NgModel;
  ngOnInit() {
    this.locationFilterControl.valueChanges.pipe(
      tap(() => this.searchingLocations = true),
      debounceTime(500),
    )
      .subscribe(newValue => this.doFilter(newValue));
  }

}
