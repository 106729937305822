<mat-sidenav #subSidenav mode="over" position="end" (closed)="subSidenavMode = null">
  <webcam-snapshot *ngIf="subSidenavMode=='photo'" [sidenav]="subSidenav" [documentTags]="['SHIPPING CHECKIN']" [saveDocuments]="false" [photoFilenamePrefix]="'TKT' + ticket.subticketNumber" (photosTaken)="addPhotos($event)"></webcam-snapshot>
</mat-sidenav>

<button mat-fab color="error" class="extended-fab-button position-fixed bg-danger text-white" style="bottom: 25px; right: 20px; z-index: 1030" (click)="abort()" *ngIf="!subSidenavMode">
  <mat-icon>close</mat-icon>
  <span class="extended-fab-button__text">Abort</span>
</button>

<loading *ngIf="saving" content="Saving..."></loading>

<mat-stepper orientation="vertical" [linear]="true" class="checkin-stepper" (selectionChange)="onStepChange($event)">
  <mat-step #step label="Review ticket details" [stepControl]="checklistForm">
    <mat-card class="border rounded mat-elevation-z0 w-50">
      <mat-card-title>Ticket #{{getTicketNumber(ticket)}}</mat-card-title>
      <mat-card-subtitle>Checklist</mat-card-subtitle>
      <div class="my-1 checklist" [formGroup]="checklistForm">
        <mat-divider></mat-divider>
        <div class="mx-2 mb-1 mt-2">
          <mat-checkbox formControlName="item">
            <div mat-line class="small font-weight-bold">
              Item
            </div>
            <div mat-line>
              <item-type-chip [iconOnly]="true" [item]="ticket.inventoryItem" class="mr-1"></item-type-chip>
              {{ ticket.inventoryItem.name }}
            </div>
          </mat-checkbox>
        </div>
        <mat-divider></mat-divider>
        <div class="mx-2 mb-1 mt-2">
          <mat-checkbox formControlName="origin">
            <div mat-line class="small font-weight-bold">
              Receiving from
            </div>
            <div mat-line>
              {{receivingFrom}}
            </div>
          </mat-checkbox>
        </div>
        <mat-divider></mat-divider>
        <ng-container *ngIf="ticket.rmaTicketId">
          <div class="mx-2 mb-1 mt-2">
            <mat-checkbox formControlName="rmaNumber">
              <div mat-line class="small font-weight-bold">
                RMA Number
              </div>
              <div mat-line>
                {{ticket.rmaTicket.rmaNumber}}
              </div>
            </mat-checkbox>
          </div>
          <mat-divider></mat-divider>
        </ng-container>
        <div class="mx-2 mb-1 mt-2">
          <mat-checkbox formControlName="poNumber">
            <div mat-line class="small font-weight-bold">
              Purchase Order
            </div>
            <div mat-line>
              <div *ngIf="ticket.purchaseOrder">
                {{ticket.purchaseOrder.purchaseOrderNumber}}
              </div>
              <div *ngIf="!ticket.purchaseOrder" class="text-muted">
                No Associated Purchase Order
              </div>
            </div>
          </mat-checkbox>
        </div>
        <mat-divider></mat-divider>
        <div class="mx-2 mb-1 mt-2">
          <mat-checkbox formControlName="woNumber">
            <div mat-line class="small font-weight-bold">
              Work Order
            </div>
            <div mat-line>
              <div *ngIf="ticket.workOrder">
                {{ticket.workOrder.workOrderNumber}}
              </div>
              <div *ngIf="!ticket.workOrder && ticket.legacyWorkOrderOrDeptCode">
                {{ticket.legacyWorkOrderOrDeptCode}}
              </div>
              <div *ngIf="!ticket.workOrder && !ticket.legacyWorkOrderOrDeptCode" class="text-muted">
                No Associated Work Order
              </div>
            </div>
          </mat-checkbox>
        </div>
        <mat-divider></mat-divider>
      </div>
      <mat-card-actions>
        <button mat-button matStepperNext [disabled]="!checklistForm.valid">Next</button>
      </mat-card-actions>
    </mat-card>
  </mat-step>
  <mat-step label="Enter amount received & other data" [stepControl]="receiptForm.control">
    <mat-card class="border rounded mat-elevation-z0 w-50">
      <mat-card-content>
        <div class="w-100" ngForm #receiptForm="ngForm">
          <div class="row">
            <div class="col-6">
                <date-picker appearance="outline" [required]="true" placeholder="Date Received"
                  [editable]="true"
                  [date]="receivedDate.value" [max]="now" (dateChange)="receivedDate.setValue($event)">
                </date-picker>
            </div>
          </div>
          <div class="row">
            <div class="col-6">
              <mat-form-field appearance="outline" class="w-100" floatLabel="always">
                <mat-label>Amount Received</mat-label>
                <input
                class="input-right-align"
                matInput type="number" placeholder="Amount Received"
                [(ngModel)]="amountReceived"
                (ngModelChange)="onReceivedChange()"
                name="amountReceived"
                required
                [minValidator]="1"
                />
                <span matSuffix class="text-muted">/{{ticket.quantityRequired}}</span>
                <mat-error *ngIf="amountReceived < 1">
                  Amount received cannot be lower than 1.
                </mat-error>
                <mat-error *ngIf="!amountReceived && amountReceived !== 0">
                  Field is required.
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col-6">
              <div class="backorder-date" [class.backorder-date-show]="amountReceived > 0 && amountReceived < ticket.quantityRequired">
                <mat-label class="small">Backorder Date for Remaining Items (optional)</mat-label>
                <date-picker #datePicker [min]="receivedDate.value" [required]="false" placeholder="Backorder Date (optional)"
                  [editable]="true"
                  [date]="backorderDate.value" (dateChange)="backorderDate.setValue($event)">
                </date-picker>
              </div>
            </div>
          </div>
        </div>
      </mat-card-content>
      <mat-card-actions>
        <button mat-button matStepperPrevious>Back</button>
        <button mat-button matStepperNext [disabled]="!receiptForm.valid">Next</button>
      </mat-card-actions>
    </mat-card>
  </mat-step>
  <mat-step label="Fill in lot number info" [stepControl]="lotNumbersForm.control" #lotNumberStep>
    <mat-card class="border rounded mat-elevation-z0 w-50">
      <mat-card-title>Inventory lot numbers</mat-card-title>
      <mat-card-subtitle class="text-muted small">For RMA shipments, please keep all items in one lot. Quality will split if necessary.</mat-card-subtitle>
      <mat-card-content>
        <div ngForm #lotNumbersForm="ngForm" class="w-100">
          <div class="row" *ngFor="let ln of lotNumbers; index as i">
            <div class="col-7">
              <mat-form-field appearance="outline" class="w-100">
                <mat-label>Lot Number (optional)</mat-label>
                <input matInput type="text" placeholder="Lot Number (optional)" [(ngModel)]="ln.lotNumber" [name]="'lotNumber' + i" autocomplete="off" />
                <mat-error *ngIf="amountReceived > ticket.quantityRequired">
                  Amount received cannot be higher than the ticket amount.
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col-3">
              <mat-form-field appearance="outline" class="w-100" floatLabel="always">
                <mat-label>Quantity</mat-label>
                <input
                class="input-right-align"
                matInput type="number" placeholder="Quantity"
                [(ngModel)]="ln.qty" [name]="'lotNumberQty' + i"
                required
                [minValidator]="1"
                min="0"
                [maxValidator]="amountReceived - (totalLotNumberQty - ln.qty)"
                />
                <span matSuffix class="text-muted">/{{amountReceived - (totalLotNumberQty - ln.qty)}}</span>
                <mat-error *ngIf="ln.qty < 1">
                  Must be at least 1.
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col-2 pt-2 d-flex justify-content-center">
              <button mat-icon-button (click)="deleteLotNumber(i)" *ngIf="!ticket.rmaTicketId" [disabled]="lotNumbers.length < 2">
                <mat-icon>delete</mat-icon>
              </button>
            </div>
          </div>
          <div class="row mt-1" *ngIf="!ticket.rmaTicketId">
            <div class="col">
              <button mat-button class="bg-success text-white w-100" (click)="addLotNumber()">
                <mat-icon matPrefix>add</mat-icon>
                New Lot
              </button>
            </div>
          </div>
        </div>
      </mat-card-content>
      <mat-card-actions>
        <button mat-button matStepperPrevious>Back</button>
        <button mat-button matStepperNext [disabled]="!lotNumbersForm.valid">Next</button>
      </mat-card-actions>
    </mat-card>
  </mat-step>
  <mat-step label="Place in inventory" [stepControl]="locationForm.control">
    <mat-card class="border rounded mat-elevation-z0" style="width: 66%">
      <mat-card-title>Place lots in inventory</mat-card-title>
      <mat-divider></mat-divider>
      <form #locationForm="ngForm" style="margin: 0 -15px;">
        <mat-tab-group>
          <mat-tab *ngFor="let ln of lotNumbers; index as i">
            <ng-template mat-tab-label>
              <span class="text-dark">{{ !!ln.lotNumber.trim() ? 'Lot Number #' + ln.lotNumber : 'No Lot Number' }}</span>
              <span class="badge bg-dark text-white mx-2">
                {{ln.qty}}
              </span>
              <mat-icon class="text-dark mx-1" [class.invisible]="!ln.location">
                done
              </mat-icon>
            </ng-template>
            <div class="p-3">
              <inventory-tree-select #its [(ngModel)]="ln.location" (ngModelChange)="setBreadcrumbs(ln, its)" [name]="'location' + i" notNullValidator></inventory-tree-select>
            </div>
          </mat-tab>
        </mat-tab-group>
      </form>
      <mat-divider></mat-divider>
      <mat-card-actions>
        <button mat-button matStepperPrevious>Back</button>
        <button mat-button matStepperNext [disabled]="!locationForm.valid">Next</button>
      </mat-card-actions>
    </mat-card>
  </mat-step>
  <mat-step label="Add Photos / Scans / Documents" [stepControl]="photosForm.control">
    <mat-card class="border rounded mat-elevation-z0" style="width: 66%">
      <mat-card-title>Inventory Photos / Scans</mat-card-title>
      <mat-card-content>
        <div class="w-100" ngForm #photosForm="ngForm">
          <div class="row">
            <div class="col-6">
              <a class="mat-focus-indicator bg-success text-white w-100 mat-button mat-button-base" title="Take Photo" (click)="openPhotoSubSidenav()">Take Photos / Scans<i class="fas fa-camera ml-4"></i></a>
            </div>
          </div>
          <div class="row" *ngIf="uploadedPhotos.length > 0">
            <div class="col-12">
              <carousel [freeScroll]="false" [cellWidth]="100">
                <div class="carousel-cell photo-carousel-slide" *ngFor="let photo of uploadedPhotos">
                  <a class="position-fixed fixed-bottom btn btn-badge bg-danger has-icon btn-delete-photo btn-delete-photo-small" title="DeletePhoto" (click)="deletePhoto(photo)" ><i class="fas fa-times"></i></a>
                  <img [src]="photo.imageAsDataUrl" *ngIf="photo.imageAsDataUrl"/>
                </div>
              </carousel>
            </div>
          </div>
        </div>
      </mat-card-content>  
      <mat-card-title>Documents</mat-card-title>
      <mat-card-content>
        <div class="w-100">
          <div class="row">
            <div class="col-6">
              <a class="mat-focus-indicator bg-success text-white w-100 mat-button mat-button-base" title="Take Photo" (click)="fileInput.click()">Upload Document</a>
            </div>
            <input type="file" class="d-none" #fileInput (change)="onBrowseDone(fileInput.files)" />
          </div>
          <file-display-grid [editable]="true" [files]="documents"></file-display-grid>
        </div>
      </mat-card-content>  
      <mat-divider></mat-divider>
      <mat-card-actions>
        <button mat-button matStepperPrevious>Back</button>
        <button mat-button matStepperNext [disabled]="!photosForm.valid">Next</button>
      </mat-card-actions>
    </mat-card>
  </mat-step>
  <mat-step [label]="ticket.rmaTicketId ? 'Double-check and finalize' : 'Double-check, print label and finalize'" #finalizeStep>
    <mat-card class="border rounded mat-elevation-z0 w-50">
      <mat-card-title>Checking in ticket <b>{{getTicketNumber(ticket)}}</b></mat-card-title>
      <mat-card-content>
        <p>
          Receiving <b>{{ amountReceived }}</b> <span *ngIf="ticket.rmaTicketId"> returned</span> units of <item-type-chip [iconOnly]="true" [item]="ticket.inventoryItem" class="mr-1"></item-type-chip><b>{{ ticket.inventoryItem.name }}</b>
          from <b>{{receivingFrom}}</b>
        </p>
        <mat-divider></mat-divider>
        <div class="font-weight-bold text-muted mt-4 mb-2">Inventory information</div>
        <div class="row mb-2">
          <ng-container *ngIf="!ticket.rmaTicketId">
            <div class="col-6">
              <label class="font-weight-bold small">Purchase Order</label>
              <div>
                <div *ngIf="ticket.purchaseOrder">
                  {{ticket.purchaseOrder.purchaseOrderNumber}}
                </div>
                <div *ngIf="!ticket.purchaseOrder" class="text-muted">
                  --
                </div>
              </div>
            </div>
            <div class="col-6">
              <label class="font-weight-bold small">Work Order</label>
              <div>
                <div *ngIf="ticket.workOrder">
                  {{ticket.workOrder.workOrderNumber}}
                </div>
                <div *ngIf="!ticket.workOrder && ticket.legacyWorkOrderOrDeptCode">
                  {{ticket.legacyWorkOrderOrDeptCode}}
                </div>
                <div *ngIf="!ticket.workOrder && !ticket.legacyWorkOrderOrDeptCode" class="text-muted">
                  --
                </div>
              </div>
            </div>
          </ng-container>
          <ng-container *ngIf="ticket.rmaTicketId">
            <div class="col-6">
              <label class="font-weight-bold small">RMA Number</label>
              <div>
                <div>
                  {{ticket.rmaTicket.rmaNumber}}
                </div>
              </div>
            </div>
          </ng-container>
        </div>
        <mat-divider></mat-divider>
        <div class="font-weight-bold text-muted mt-4 mb-2">Lots</div>
        <ul class="pl-3">
          <li *ngFor="let lot of lotNumbers">
            <ng-container>
              <span *ngIf="lot.lotNumber">Lot <b>{{ lot.lotNumber }}</b></span><b *ngIf="!lot.lotNumber">No Lot Number</b>: <b>{{ lot.qty }}</b> items going to
              <b *ngFor="let breadcrumb of lot.breadcrumbs; last as last">
                {{ breadcrumb }}{{ last ? '' : ', ' }}
              </b>
            </ng-container>
            <span class="ml-1 badge">
              
            </span>
          </li>
        </ul>
      </mat-card-content>
      <mat-divider></mat-divider>
      <mat-card-actions class="mb-0">
        <!-- TODO -->
        <button mat-stroked-button color="secondary" (click)="printLabel()" [disabled]="printed">
          <mat-icon matPrefix>print</mat-icon>
          Print Inventory Label
        </button>
        <button mat-stroked-button color="secondary" (click)="printPO()">
          <mat-icon matPrefix>print</mat-icon>
          Print Purchase Order
        </button>
      </mat-card-actions>
      <mat-divider></mat-divider>
      <mat-card-actions>
        <button mat-flat-button color="primary" [disabled]="!printed" (click)="finalizeAndCheckIn()">
          <mat-icon matPrefix>task_alt</mat-icon>
          Finalize check-in and close ticket
        </button>
      </mat-card-actions>
    </mat-card>
  </mat-step>
</mat-stepper>