<div class="d-flex flex-column">
  <div class="d-flex mb-2" *ngIf="editing">
    <input #formulaInput [class.border-danger]="error" [class.border-success]="!error && !!cleanFormula"  class="form-control flex-grow-1" [(ngModel)]="formula" placeholder="Input a volume formula">
    <i class="fas fa-check ml-2 text-success align-self-center" [class.invisible]="error || !cleanFormula"></i>
  </div>
  <div *ngIf="!editing" class="text-muted font-weight-bold">
    {{ formula }}
  </div>
  <div *ngIf="editing">
    <label>Available Dimensions</label>
    <div class="text-muted" *ngIf="availableSymbols.length === 0">—</div>
    <mat-chip-list class="mb-1">
      <mat-chip color="blue"
        *ngFor="let symbol of availableSymbols"
        (mousedown)="onInsertSymbol($event, symbol)"
        >
        {{symbol}}
      </mat-chip>
    </mat-chip-list>
    <label>Math Operators</label>
    <mat-chip-list class="mb-1">
      <mat-chip color="blue"
        *ngFor="let operator of operators"
        (mousedown)="onInsertSymbol($event, operator.formula)"
        >
        {{operator.display}}
      </mat-chip>
    </mat-chip-list>
  </div>
  <div class="alert alert-danger mt-2 mb-0" *ngIf="error">
    <h6>Error in formula</h6>
    {{ error }}
  </div>
</div>