<loading *ngIf="!suggestions"></loading>
<loading *ngIf="saving" content="Saving..."></loading>

<div class="container-fluid" *ngIf="suggestions">
  <a class="position-fixed fixed-bottom btn btn-badge bg-danger has-icon mr-6" title="Cancel" (click)="cancel()"><i class="fas fa-times"></i></a>
  <a class="position-fixed fixed-bottom btn btn-badge bg-success has-icon" title="Continue" (click)="save()"><i class="fas fa-save"></i></a>

  <div class="row mt-4">
    <div class="col">
      <h3 class="text-primary">Combine Shipments</h3>
      <div class="form-group mt-4">
        <table class="table table-striped border small">
          <thead>
            <tr>
              <th>&nbsp;</th>
              <th>Ticket Number</th>
              <th>Qty</th>
              <th>Required</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let ticket of suggestions">
              <td class="text-center">
                <mat-checkbox (change)="selectionChanged($event)" [value]="ticket.shippingTicketId" [checked]="ticket.shippingBatchId && ticket.shippingBatchId==batchId" *ngIf="ticket.shippingTicketId!=ticketId"></mat-checkbox>
              </td>
              <td>
                <button (click)="openTicket(ticket)" class="btn btn-link btn-sm" *ngIf="ticket.shippingTicketId != ticketId; else thisTicket">
                  <i class="fas fa-fw fa-external-link-alt text-muted pr-2"></i>
                  {{getTicketNumber(ticket)}}
                </button>
                <ng-template #thisTicket>
                  <span title="Current Shipment" class="text-muted pl-4 ml-2">
                    {{getTicketNumber(ticket)}}
                  </span>
                </ng-template>
              </td>
              <td class="font-weight-bold">{{ticket.quantityRequired}}</td>
              <td>
                <span *ngIf="ticket.workOrder?.order?.requiredDate" class="badge required" [ngClass]="getRequiredColor(ticket.workOrder.order.requiredDate)">
                  {{ticket.workOrder.order.requiredDate | date}}
                  <span class="d-block small">{{weeksLeft(ticket.workOrder.order.requiredDate, true)||'--'}} Week(s) {{(weeksLeft(ticket.workOrder.order.requiredDate, false)||0) >= 0 ? 'From Today':'Overdue'}}</span>
                </span>
              </td>
              <td>
                <span class="badge status text-uppercase p-2" [ngClass]="getStatusColorClass(ticket.status)">
                  {{getStatusText(ticket.status)}}
                </span>
              </td>
            </tr>
            <tr *ngIf="suggestions?.length == 0">
              <td colspan="100%">
                <p class="font-italic text-muted text-center">-- No Combination Suggestions Found --</p>
              </td>
            </tr>
            <tr>
              <td colspan="100%" class="text-center small">
                <i class="fas fa-fw fa-lg fa-info-circle text-info"></i>
                Suggestions are made for shipments to the same company that have not already departed and currently have no combined tickets themselves.
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>
