import { Injectable } from "@angular/core";
import { Task, TaskList, TaskStatus } from "../../common/resources/estimatingtask";
import { Order } from "../resources/order";
import { Product } from "../resources/product";

@Injectable()
export class EstimateProgressService {

  public order: Order;

  public productsOverride: Product[]
  public get products() {
    return this.productsOverride ?? this.order.products.map(p => p.product);
  }

  public static countsAsDone(status: TaskStatus) {
    if (status === TaskStatus.DONE) return true;
    if (status === TaskStatus.NOT_APPLICABLE) return true;
    return false;
  }

  public static countsAsInProgress(status: TaskStatus) {
    if (status === TaskStatus.IN_PROGRESS) return true;
    if (status === TaskStatus.NEEDS_QUOTE) return true;
    return false;
  }

  public getSubtask(id: string, parentTask?: Task): Task | null {
    const list = parentTask ? parentTask.subtasks : (this.order.estimatingTaskList?.tasks ?? []);
    return list.find(t => t.microTaskId === id) ?? null;
  }

  private createMainTaskForProduct(product: Product): Task {
    return {
      microTaskId: product.productId,
      status: TaskStatus.NOT_STARTED,
      subtasks: []
    };
  }

  public static readonly basicEstimatingTasks = ['material', 'workflow', 'hardware'];

  private syncSubtasksForProductTask(product: Product, mainTask: Task) {
    // Cleanup + subassemblies
    const allowedIds: string[] = [...EstimateProgressService.basicEstimatingTasks, ...product.childAssemblies.map(c => c.productId)];
    mainTask.subtasks = mainTask.subtasks.filter(s => allowedIds.includes(s.microTaskId));
    for (const subassembly of product.childAssemblies) {
      let subtask = this.getSubtask(subassembly.productId, mainTask);
      if (!subtask) {
        subtask = this.createMainTaskForProduct(subassembly);
        mainTask.subtasks.push(subtask);
      }
      this.syncSubtasksForProductTask(subassembly, subtask);
    }
    // Basic tasks
    EstimateProgressService.basicEstimatingTasks.forEach(id => {
      const existing = this.getSubtask(id, mainTask);
      if (!existing) {
        const created: Task = {
          microTaskId: id,
          status: TaskStatus.NOT_STARTED,
          subtasks: []
        }
        mainTask.subtasks.push(created);
      }
    });
  }

  public syncOrCreateTopLevelProductTask(product: Product) {
    if (!this.shouldShowIndicators()) return;
    let task = this.getSubtask(product.productId);
    if (!task) {
      task = this.createMainTaskForProduct(product);
      this.order.estimatingTaskList.tasks.push(task);
    }
    this.syncSubtasksForProductTask(product, task);
  }

  public deleteTopLevelProductTask(product: Product) {
    if (!this.shouldShowIndicators()) return;
    this.order.estimatingTaskList.tasks = this.order.estimatingTaskList.tasks.filter(t => t.microTaskId !== product.productId);
  }

  public syncEstimatingTaskList() {
    if (!this.shouldShowIndicators()) return;
    if (!this.order.estimatingTaskList) this.order.estimatingTaskList = { tasks: [] };
    const idsToSync = this.products.map(p => p.productId);
    this.order.estimatingTaskList.tasks = this.order.estimatingTaskList.tasks.filter(t => idsToSync.includes(t.microTaskId));
    for (const product of this.products) {
      this.syncOrCreateTopLevelProductTask(product);      
    }
  }

  private bubbleProductTaskStatus(topLevelTask: Task) {
    if (topLevelTask.subtasks.length === 0) return;
    let foundNotDone = false;
    let foundInProgress = false;
    for (const subtask of topLevelTask.subtasks) {
      this.bubbleProductTaskStatus(subtask);
      if (!foundNotDone && !EstimateProgressService.countsAsDone(subtask.status)) foundNotDone = true;
      if (!foundInProgress && subtask.status !== TaskStatus.NOT_STARTED) foundInProgress = true;
    }
    if (!foundNotDone) topLevelTask.status = TaskStatus.DONE;
    else if (foundInProgress) topLevelTask.status = TaskStatus.IN_PROGRESS;
    else topLevelTask.status = TaskStatus.NOT_STARTED;
  }

  private updateSimpleEstimatingTaskStatus(productTask: Task, simpleTaskId: 'material' | 'workflow' | 'hardware', status: TaskStatus, topLevelTask: Task) {
    const subtask = productTask.subtasks.find(s => s.microTaskId === simpleTaskId);
    subtask.status = status;
    this.bubbleProductTaskStatus(topLevelTask);
  }

  public getProductTask(productId: string, taskList: Task[] = null): Task | null {
    if (!taskList) taskList = this.order.estimatingTaskList?.tasks;
    for (const task of taskList) {
      if (task.microTaskId === productId) return task;
      const inner = this.getProductTask(productId, task.subtasks);
      if (inner) return inner;
    }
    return null;
  }

  public getSimpleTaskStatus(productId: string, type: 'material' | 'workflow' | 'hardware', topLevelId: string) {
    const topLevelTask = this.getProductTask(topLevelId);
    const productTask = topLevelTask.microTaskId === productId ? topLevelTask : this.getProductTask(productId, topLevelTask?.subtasks);
    if (!productTask) return null;
    const subtask = productTask.subtasks.find(s => s.microTaskId === type);
    return subtask?.status;
  }

  public setSimpleTaskStatus(productId: string, type: 'material' | 'workflow' | 'hardware', topLevelId: string, status: TaskStatus) {
    const topLevelTask = this.getProductTask(topLevelId);
    const task = topLevelTask.microTaskId === productId ? topLevelTask : this.getProductTask(productId, topLevelTask?.subtasks);
    this.updateSimpleEstimatingTaskStatus(task, type, status, topLevelTask);
  }

  private allChildrenFlatRecursive(product: Product): Product[] {
    return product.childAssemblies.flatMap(p => this.allProductsFlatRecursive(p))
  }

  private allProductsFlatRecursive(product: Product): Product[] {
    return [product, ...this.allChildrenFlatRecursive(product)]
  }

  public allProductsFlat(): Product[] {
    return this.products.flatMap(p => this.allProductsFlatRecursive(p));
  }

  public getAllIncompleteSimpleTasksFlat(mainTask: Task, productList: Product[]): { productName: string, missing: string, productId: string, missingAry: string[] }[] {
    const simples = mainTask.subtasks.filter(t => EstimateProgressService.basicEstimatingTasks.includes(t.microTaskId))
      .filter(t => !EstimateProgressService.countsAsDone(t.status))
      .map(x => x.microTaskId)
    ;
    const others = mainTask.subtasks.filter(t => !EstimateProgressService.basicEstimatingTasks.includes(t.microTaskId))
      .filter(t => !EstimateProgressService.countsAsDone(t.status))
    ;

    const myProduct = productList.find(p => p.productId === mainTask.microTaskId);

    // In case a product gets deleted or whatever, we no longer care, just return empty
    if (!myProduct) {
      return [];
    }

    let missing = simples.map(s => s.charAt(0).toUpperCase() + s.slice(1)).join(', ')
    const output = {
      productName: `${myProduct.partNumber} Rev. ${myProduct.revision}`,
      productId: myProduct.productId,
      missing,
      missingAry: simples
    }
    const listOutput = simples.length > 0 ? [output] : [];

    return [...listOutput, ...others.flatMap(t => this.getAllIncompleteSimpleTasksFlat(t, productList))]
  }

  public getAllIncompleteSimpleTasksByProduct(): { productName: string, missing: string, productId: string, missingAry: string[] }[] {
    const productsFlat = this.allProductsFlat();

    return (this.order.estimatingTaskList?.tasks ?? []).flatMap(t => this.getAllIncompleteSimpleTasksFlat(t, productsFlat))

  }

  public static getAllSimpleStatusesFlat(tasks: Task[]): TaskStatus[] {
    return [
      ...tasks
      .filter(t => EstimateProgressService.basicEstimatingTasks.includes(t.microTaskId))
      .map(t => t.status),
      ...tasks
      .filter(t => !EstimateProgressService.basicEstimatingTasks.includes(t.microTaskId))
      .flatMap(t => EstimateProgressService.getAllSimpleStatusesFlat(t.subtasks))
    ]
  }
  
  public static getTaskListProgress(taskList: TaskList): {
    [key in TaskStatus]: number;
  } & { total: number } {
    if (!taskList || taskList.tasks.length === 0) return {
      [TaskStatus.NOT_STARTED]: 1,
      [TaskStatus.IN_PROGRESS]: 0,
      [TaskStatus.NEEDS_QUOTE]: 0,
      [TaskStatus.DONE]: 0,
      [TaskStatus.NOT_APPLICABLE]: 0,
      total: 1
    }
    const statuses = EstimateProgressService.getAllSimpleStatusesFlat(taskList.tasks);

    return statuses.reduce((acc, x) => {
      acc[x] += 1;
      return acc;
    }, {
      [TaskStatus.NOT_STARTED]: 0,
      [TaskStatus.IN_PROGRESS]: 0,
      [TaskStatus.NEEDS_QUOTE]: 0,
      [TaskStatus.DONE]: 0,
      [TaskStatus.NOT_APPLICABLE]: 0,
      total: statuses.length
    })
  }

  public moveTask(taskId: string, fromId: string, toId: string) {
    const fromTaskList = this.getProductTask(fromId).subtasks;
    const idx = fromTaskList.findIndex(t => t.microTaskId === taskId);
    if (idx === -1) return;
    const grabbed = fromTaskList.splice(idx, 1);
    const toTask = this.getProductTask(toId);
    toTask.subtasks = [...toTask.subtasks, ...grabbed];
  }

  public deleteTask(taskId: string, parentId: string) {
    const parent = this.getProductTask(parentId);
    parent.subtasks = parent.subtasks.filter(t => t.microTaskId !== taskId);
  }

  public static getStatusColorClass(status: TaskStatus) {
    switch (status) {
      case (TaskStatus.NOT_STARTED): return 'progress--not_started';
      case (TaskStatus.IN_PROGRESS): return 'progress--in_progress';
      case (TaskStatus.DONE): return 'progress--done';
      case (TaskStatus.NOT_APPLICABLE): return 'progress--not_applicable';
      case (TaskStatus.NEEDS_QUOTE): return 'progress--needs_quote';
    }
  }

  public static getStatusIcon(status: number) {
    switch (status) {
      case (TaskStatus.NOT_STARTED): return 'alert-circle';
      case (TaskStatus.IN_PROGRESS): return 'dots-horizontal';
      case (TaskStatus.DONE): return 'check';
      case (TaskStatus.NOT_APPLICABLE): return 'border-none-variant';
      case (TaskStatus.NEEDS_QUOTE): return 'clipboard-clock';
    }
  }

  public static getStatusText(status: number) {
    switch (status) {
      case (TaskStatus.NOT_STARTED): return 'Not Started';
      case (TaskStatus.IN_PROGRESS): return 'In Progress';
      case (TaskStatus.DONE): return 'Done';
      case (TaskStatus.NOT_APPLICABLE): return 'Not Applicable';
      case (TaskStatus.NEEDS_QUOTE): return 'Needs Quote';
    }
  }

  public shouldShowIndicators() {
    return (this.order.discriminator === 'Estimate' || this.order.discriminator === 'RMAEstimate') ||
      (this.order.discriminator === 'RFQ' && this.order.status === 1);
  }
}