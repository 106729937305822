import { Injectable, Inject, Output, EventEmitter, Directive } from '@angular/core';
import { Observable, forkJoin, Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { catchError, map, tap } from 'rxjs/operators';
import { ErrorHandlerService } from '../../common/services/errorHandler.service'
import { SearchResult } from '../../common/resources/search-result';
import { httpService } from '../../common/services/http.service';
import { UtilityService } from '../../common/services/utility.service';
import { MessageType } from '../../common/resources/message';
import { MaterialGroup, Material, MaterialType, MaterialSpecification, MaterialHardness, MaterialMaterialSpecification, MaterialAlloy, DimensionType, DimensionUnit, MaterialTypeDimension, MaterialDimension } from '../../order/resources/material';
import { MessageService } from '../../common/services/message.service';

interface AdvancedMaterialSearchTerms {
  specifications?: string[],
  types?: string[],
  groups?: string[],
  alloys?: string[],
  hardnesses?: string[]
}

@Directive()
@Injectable({
  providedIn: 'root',
})
export class MaterialService extends httpService {
  private apiBase: string = 'api/material';
  private apiDimensionBase: string = 'api/dimension';
  private apiUrl: string;
  private apiDimensionUrl: string;

  @Output() updated: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() typeDimensionsUpdated = new Subject<MaterialType>();

  constructor(errorHandler: ErrorHandlerService, private messages: MessageService, private http: HttpClient, @Inject('BASE_URL') private baseUrl: string) {
    super(errorHandler, messages);
    this.serviceName = "Material";

    this.apiUrl = this.baseUrl + this.apiBase;
    this.apiDimensionUrl = this.baseUrl + this.apiDimensionBase;
  }

  public search(searchString?: string, pageIndex: number = 0, pageSize: number = 200): Observable<SearchResult<Material>> {
    return this.http.get<SearchResult<Material>>(this.apiUrl + '/search', { params: { searchText: searchString || '', pageIndex: pageIndex.toString(), orderBy: "name", direction: "asc", pageSize: pageSize.toString() } }).pipe(
      catchError(this.handleError<any>("Get Materials Search Results", null))
    );
  }

  public advancedSearch(terms: AdvancedMaterialSearchTerms) {
    const queryString = '?' + Object.entries(terms).filter(([name, values]: [string, string[]]) => values && values.length > 0)
      .map(([name, values]: [string, string[]]) => {
        return `${name}=${values.join(',')}`
      }).join('&')
    return this.http.get<SearchResult<Material>>(this.apiUrl + '/advancedSearch' + queryString).pipe(
      catchError(this.handleError<any>("Get Materials Search Results", null))
    );
  }

  public getMaterial(id: string): Observable<Material> {
    return this.http.get<Material>(this.apiUrl + '/' + id).pipe(
      tap(_ => {
        this.messages.add("Material Service: Material Specifications Saved Successfully", MessageType.SUCCESS, true);
        this.updated.emit(true);
      }),
      catchError(this.handleError<Material>("Get Material", null))
    );
  }

  public getMaterialGroups(searchString?: string): Observable<SearchResult<MaterialGroup>> {
    return this.http.get<SearchResult<MaterialGroup>>(this.apiUrl + 'group/search', { params: { searchText: searchString || '', pageIndex: '0', pageSize: '500', orderBy: "name", direction: "asc" } }).pipe(
      catchError(this.handleError<any>("Get Material Groups Search Results", null))
    );
  }

  public saveMaterialGroup(spec: MaterialGroup): Observable<MaterialGroup> {
    if (spec.materialGroupId == UtilityService.emptyGuid) {
      return this.http.post<MaterialGroup>(this.apiUrl + 'Group/new', spec).pipe(
        tap(_ => {
          this.messages.add("Material Service: Material Group Saved Successfully", MessageType.SUCCESS, true);
          this.updated.emit(true);
        }),
        catchError(this.handleError<MaterialGroup>("Save New Material Group", spec))
      );
    }
    else {
      return this.http.post<MaterialGroup>(this.apiUrl + "Group", spec).pipe(
        tap(_ => {
          this.messages.add("Material Service: Material Group Saved Successfully", MessageType.SUCCESS, true);
          this.updated.emit(true);
        }),
        catchError(this.handleError<MaterialGroup>("Update Material Group", spec))
      );
    }
  }

  public removeMaterialGroup(materialGroupId: string): Observable<any> {
    return this.http.delete<MaterialGroup>(this.apiUrl + `Group/${materialGroupId}`).pipe(
      tap(_ => {
        this.messages.add("Material Service: Material Group Removed Successfully", MessageType.SUCCESS, true);
        this.updated.emit(true);
      }),
      catchError(this.handleError<MaterialGroup>("Remove Material Group", null))
    );
  }

  public getMaterialHardnesses(searchString?: string): Observable<SearchResult<MaterialHardness>> {
    return this.http.get<SearchResult<MaterialHardness>>(this.apiUrl + 'hardness/search', { params: { searchText: searchString || '', pageIndex: '0', pageSize: '500', orderBy: "name", direction: "asc" } }).pipe(
      catchError(this.handleError<any>("Get Material Hardnesses Search Results", null))
    );
  }

  public saveMaterialHardness(spec: MaterialHardness): Observable<MaterialHardness> {
    if (spec.materialHardnessId == UtilityService.emptyGuid) {
      return this.http.post<MaterialHardness>(this.apiUrl + 'Hardness/new', spec).pipe(
        tap(_ => {
          this.messages.add("Material Service: Material Hardness Saved Successfully", MessageType.SUCCESS, true);
          this.updated.emit(true);
        }),
        catchError(this.handleError<MaterialHardness>("Save New Material Hardness", spec))
      );
    }
    else {
      return this.http.post<MaterialHardness>(this.apiUrl + "Hardness", spec).pipe(
        tap(_ => {
          this.messages.add("Material Service: Material Hardness Saved Successfully", MessageType.SUCCESS, true);
          this.updated.emit(true);
        }),
        catchError(this.handleError<MaterialHardness>("Update Material Hardness", spec))
      );
    }
  }

  public removeMaterialHardness(materialHardnessId: string): Observable<any> {
    return this.http.delete<MaterialGroup>(this.apiUrl + `Hardness/${materialHardnessId}`).pipe(
      tap(_ => {
        this.messages.add("Material Service: Material Hardness Removed Successfully", MessageType.SUCCESS, true);
        this.updated.emit(true);
      }),
      catchError(this.handleError<MaterialGroup>("Remove Material Hardness", null))
    );
  }

  public getMaterialAlloys(searchString?: string): Observable<SearchResult<MaterialAlloy>> {
    return this.http.get<SearchResult<MaterialAlloy>>(this.apiUrl + 'Alloy/search', { params: { searchText: searchString || '', pageIndex: '0', pageSize: '500', orderBy: "name", direction: "asc" } }).pipe(
      catchError(this.handleError<any>("Get Material Alloys Search Results", null))
    );
  }

  public saveMaterialAlloy(spec: MaterialAlloy): Observable<MaterialAlloy> {
    if (spec.materialAlloyId == UtilityService.emptyGuid) {
      return this.http.post<MaterialAlloy>(this.apiUrl + 'Alloy/new', spec).pipe(
        tap(_ => {
          this.messages.add("Material Service: Material Alloy Saved Successfully", MessageType.SUCCESS, true);
          this.updated.emit(true);
        }),
        catchError(this.handleError<MaterialAlloy>("Save New Material Alloy", spec))
      );
    }
    else {
      return this.http.post<MaterialAlloy>(this.apiUrl + "Alloy", spec).pipe(
        tap(_ => {
          this.messages.add("Material Service: Material Alloy Saved Successfully", MessageType.SUCCESS, true);
          this.updated.emit(true);
        }),
        catchError(this.handleError<MaterialAlloy>("Update Material Alloy", spec))
      );
    }
  }

  public removeMaterialAlloy(materialAlloyId: string): Observable<any> {
    return this.http.delete<MaterialAlloy>(this.apiUrl + `Alloy/${materialAlloyId}`).pipe(
      tap(_ => {
        this.messages.add("Material Service: Material Alloy Removed Successfully", MessageType.SUCCESS, true);
        this.updated.emit(true);
      }),
      catchError(this.handleError<MaterialAlloy>("Remove Material Alloy", null))
    );
  }

  public getMaterialSpecifications(searchString?: string): Observable<SearchResult<MaterialSpecification>> {
    return this.http.get<SearchResult<MaterialSpecification>>(this.apiUrl + 'specification/search', { params: { searchText: searchString || '', pageIndex: '0', pageSize: '500', orderBy: "name", direction: "asc" } }).pipe(
      catchError(this.handleError<any>("Get Material Specifications Search Results", null))
    );
  }

  public saveMaterialSpecification(spec: MaterialSpecification): Observable<MaterialSpecification> {
    if (spec.materialSpecificationId == UtilityService.emptyGuid) {
      return this.http.post<MaterialSpecification>(this.apiUrl + 'Specification/new', spec).pipe(
        tap(_ => {
          this.messages.add("Material Service: Material Specification Saved Successfully", MessageType.SUCCESS, true);
          this.updated.emit(true);
        }),
        catchError(this.handleError<MaterialSpecification>("Save New Material Specification", spec))
      );
    }
    else {
      return this.http.post<MaterialSpecification>(this.apiUrl + "Specification", spec).pipe(
        tap(_ => {
          this.messages.add("Material Service: Material Specification Saved Successfully", MessageType.SUCCESS, true);
          this.updated.emit(true);
        }),
        catchError(this.handleError<MaterialSpecification>("Update Material Specification", spec))
      );
    }
  }

  public saveMaterialMaterialSpecifications(saveList: MaterialMaterialSpecification[], material: Material): Observable<Material> {
    // Remove actual objects on the new ones that will be saved
    saveList.forEach((spec) => {
      spec.material = null;
      spec.materialId = material.materialId;
      spec.materialSpecification = null;
    });

    return this.http.post<Material>(this.apiUrl + 'MaterialSpecification/saveBulk/' + material.materialId, saveList).pipe(
      tap(_ => {
        this.messages.add("Material Service: Material Specifications Saved Successfully", MessageType.SUCCESS, true);
        this.updated.emit(true);
      }),
      catchError(this.handleError<Material>("Get Material", null))
    )
  }

  public removeMaterialSpecification(materialSpecificationId: string): Observable<any> {
    return this.http.delete<MaterialSpecification>(this.apiUrl + `Specification/${materialSpecificationId}`).pipe(
      tap(_ => {
        this.messages.add("Material Service: Material Specification Removed Successfully", MessageType.SUCCESS, true);
        this.updated.emit(true);
      }),
      catchError(this.handleError<MaterialSpecification>("Remove Material Specification", null))
    );
  }

  public getMaterialTypes(searchString?: string): Observable<SearchResult<MaterialType>> {
    return this.http.get<SearchResult<MaterialType>>(this.apiUrl + 'type/search', { params: { searchText: searchString || '', pageIndex: '0', pageSize: '500', orderBy: "name", direction: "asc" } }).pipe(
      catchError(this.handleError<any>("Get Material Forms Search Results", null))
    );
  }

  public saveMaterialType(t: MaterialType): Observable<MaterialType> {
    return this.http.post<MaterialType>(this.apiUrl + "type", t).pipe(
      tap(_ => {
        this.messages.add("Material Service: Material Form Saved Successfully", MessageType.SUCCESS, true);
        this.updated.emit(true);
      }),
      catchError(this.handleError<MaterialType>("Update Material Form", null))
    );
  }

  public removeMaterialType(materialTypeId: string): Observable<any> {
    return this.http.delete<MaterialType>(this.apiUrl + `type/${materialTypeId}`).pipe(
      tap(_ => {
        this.messages.add("Material Service: Material Form Removed Successfully", MessageType.SUCCESS, true);
        this.updated.emit(true);
      }),
      catchError(this.handleError<MaterialType>("Remove Material Form", null))
    );
  }

  public getDimensionTypes(searchString?: string): Observable<SearchResult<DimensionType>> {
    return this.http.get<SearchResult<DimensionType>>(this.apiDimensionUrl + 'type/search', { params: { searchText: searchString || '', pageIndex: '0', orderBy: "name", direction: "asc" } }).pipe(
      catchError(this.handleError<any>("Get Dimension Type Search Results", null))
    );
  }

  public saveDimensionType(t: DimensionType): Observable<DimensionType> {
    return this.http.post<DimensionType>(this.apiDimensionUrl + 'type/' + (t.dimensionTypeId === UtilityService.emptyGuid ? 'new' : t.dimensionTypeId), t).pipe(
      catchError(this.handleError<any>("Save Dimension Type", null))
    );
  }

  public getDimensionUnits(searchString?: string): Observable<SearchResult<DimensionUnit>> {
    return this.http.get<SearchResult<DimensionUnit>>(this.apiDimensionUrl + 'unit/search', { params: { searchText: searchString || '', pageIndex: '0', orderBy: "name", direction: "asc" } }).pipe(
      catchError(this.handleError<any>("Get Dimension Unit Search Results", null))
    );
  }

  public saveDimensionUnit(t: DimensionUnit): Observable<DimensionUnit> {
    return this.http.post<DimensionUnit>(this.apiDimensionUrl + 'unit/' + (t.dimensionUnitId === UtilityService.emptyGuid ? 'new' : t.dimensionUnitId), t).pipe(
      catchError(this.handleError<any>("Save Dimension Unit", null))
    );
  }

  public bulkSaveMaterialTypeDimensions(saveList: MaterialTypeDimension[], materialType: MaterialType): Observable<MaterialType> {
    // Remove actual objects on the new ones that will be saved
    saveList.forEach((mtd) => {
      mtd.materialType = null;
      mtd.materialTypeId = materialType.materialTypeId;
      mtd.dimensionType = null;
      mtd.dimensionUnit = null;
    });

    return this.http.post<MaterialType>(this.apiUrl + '/bulkSaveDimensions/' + materialType.materialTypeId, saveList).pipe(
      tap(resultType => {
        this.messages.add("Material Service: Material Form Dimensions Saved Successfully", MessageType.SUCCESS, true);
        this.typeDimensionsUpdated.next(resultType);
      }),
      catchError(this.handleError<MaterialType>("Get Material Form", null))
    )
  }

  public bulkSaveMaterialDimensions(saveList: MaterialDimension[], material: Material): Observable<Material> {
    // Remove actual objects on the new ones that will be saved
    saveList.forEach((md) => {
      md.material = null;
      md.materialId = material.materialId;
      md.materialTypeDimension = null;
    });

    return this.http.post<Material>(this.apiUrl + '/bulkSaveDimensionValues/' + material.materialId, saveList).pipe(
      tap(_ => {
        this.messages.add("Material Service: Material Dimensions Saved Successfully", MessageType.SUCCESS, true);
        this.updated.emit(true);
      }),
      catchError(this.handleError<Material>("Get Material", null))
    )
  }

  public getExistingCastingNumbers(): Observable<string[]> {
    return this.http.get<string[]>(this.apiUrl + '/getCastingNumbers').pipe(
      catchError(this.handleError<any>("Get Casting Numbers", null))
    );
  }

  public findOrCreateMaterial(materialParams: {
    materialTypeId: string;
    materialGroupId: string;
    materialHardnessId: string | null;
    materialAlloyId: string | null;
    materialSpecificationIds: string[];
    materialDimensions: MaterialDimension[];
    density: number | null;
    castingNumber: string;
  }): Observable<Material | null> {
    return this.http.post<{ created: boolean; material: Material }>(this.apiUrl + '/findOrCreateMaterial', materialParams).pipe(
        tap(({ created }) => {
          const message = created ? 'New Material Created' : 'Existing Material Found';
          this.messages.add(`Material Service: Material Find/Create Successful - ${message}`, MessageType.SUCCESS, true);
          this.updated.emit(true);
        }),
        map(({ material }) => material),
        catchError(this.handleError("Material Find/Create", null)),
      );
  }

  public save(material: Material): Observable<Material> {
    //Clean up - we don't want to insert these again, so clear out the objects and leave the ID's
    material.materialGroup = null;
    material.materialType = null;
    material.materialHardness = null;
    material.materialAlloy = null;
    material.materialMaterialSpecifications = null;
    material.materialDimensions = null;

    if (material.materialId == UtilityService.emptyGuid) {
      return this.http.post<Material>(this.apiUrl + '/new', material).pipe(
        tap(_ => {
          this.messages.add("Material Service: Material Saved Successfully", MessageType.SUCCESS, true);
          this.updated.emit(true);
        }),
        catchError(this.handleError<Material>("Save New Material", material))
      );
    }
    else {
      return this.http.post<Material>(this.apiUrl, material).pipe(
        tap(_ => {
          this.messages.add("Material Service: Material Saved Successfully", MessageType.SUCCESS, true);
          this.updated.emit(true);
        }),
        catchError(this.handleError<Material>("Update Material", material))
      );
    }
  }

  public remove(materialId: string): Observable<any> {
    return this.http.delete<Material>(this.apiUrl + `/${materialId}`).pipe(
      tap(_ => {
        this.messages.add("Material Service: Material Removed Successfully", MessageType.SUCCESS, true);
        this.updated.emit(true);
      }),
      catchError(this.handleError<Material>("Remove Material", null))
    );
  }
}
