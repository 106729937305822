import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MatSidenav } from '@angular/material/sidenav';
import { OrderService } from '../../../order/services/order.service';
import { NavigationService } from '../../../common/services/navigation.service';
import { StationService } from '../../../order/services/station.service';
import { UtilityService } from '../../../common/services/utility.service';
import { Material } from '../../../order/resources/material';
import { Product } from '../../../order/resources/product';
import { Workflow, WorkflowStep } from '../../../order/resources/workflow';
import { Order } from '../../../order/resources/order';
import { MaterialBid } from '../../../purchasing/resources/materialBid';
import { PurchasingService } from '../../../purchasing/services/purchasing.service';
import { MaterialBidService } from '../../services/material-bid.service';
import { Observable, Subject } from 'rxjs';
import { VirtualDocument } from '../../../common/resources/virtual-document';
import { DocumentService } from '../../../common/services/document.service';
import { PurchasedItem } from '../../../order/resources/purchased-item';
import { Requisition } from '../../resources/requisition';
import { Station } from '../../../order/resources/station';
import { Vendor } from '../../../supplier/resources/vendor';
import { PurchaseOrder, PurchaseOrderStatus } from '../../resources/purchaseOrder';
import { MatCheckboxChange } from '@angular/material/checkbox';

@Component({
  selector: 'supplier-detail',
  templateUrl: './supplier-detail.component.html',
  styleUrls: ['./supplier-detail.component.less']
})
export class SupplierDetailComponent implements OnInit {
  @Input() public type: string;
  @Input() public id: string;
  public record: Order | Requisition;
  public order: Order;
  public req: Requisition;
  public saving: boolean = false;
  public materials: SuppliedProduct<Material>[] = [];
  public outsourced: SuppliedProduct<WorkflowStep>[] = [];
  public purchased: SuppliedProduct<PurchasedItem>[] = [];
  public completedQuotes: MaterialBid[] = null;
  public approvedQuotes: {
    vendor: Vendor,
    materialBids: MaterialBid[],
    isOpen?: boolean,
    blacklist?: string[],
  }[] = null;
  public existingPOs: PurchaseOrder[] = null;
  public displayPerPart: boolean = false;
  public showEditor: string = null;
  public detailId: string;
  public selectedProduct: SuppliedProduct<any> = null;
  public selectedQuoteId: string = null;
  public materialsLoading: boolean = false;
  public outsourcedLoading: boolean = false;
  public purchasedItemsLoading: boolean = false;
  @Input() parentSidenav: MatSidenav = null;
  @ViewChild('sidenav') sidenav: MatSidenav;
  private documents: VirtualDocument[] = [];
  public selection: any = null;

  private isOrder(record: Order | Requisition): record is Order {
    return this.type === "order";
  }

  private isReq(record: Order | Requisition): record is Requisition {
    return this.type === "requisition";
  }

  constructor(private documentService: DocumentService, private utilitySvc: UtilityService, private quoteService: MaterialBidService, private route: ActivatedRoute, private orderSvc: OrderService, private navService: NavigationService, private stationSvc: StationService, private supplierSvc: PurchasingService, private router: Router) {
    if (!this.type) this.type = this.route.snapshot.paramMap.get('type');
    if (!this.id) this.id = this.route.snapshot.paramMap.get('id');
  }

  public cancelEditing(): void {
    if (this.parentSidenav) this.parentSidenav.close()
    else this.router.navigate(['/purchasing']);
  }

  public setSelection(obj: SuppliedProduct<unknown & { materialId?: string, stationId?: string, purchasedItemId?: string }>): void {
    this.selection = obj;

    this.completedQuotes = null;
    if (this.selection != null) {
      this.quoteService.search(
        this.isOrder(this.record) ? this.record.orderSegmentId : this.record.requisitionId,
        obj.item.materialId || obj.item.stationId || obj.item.purchasedItemId
      ).subscribe(result => {
        this.completedQuotes = result.results;
      });
    }
    else {
      this.supplierSvc.getQuotesForOrder(
        this.isOrder(this.record) ? this.record.orderSegmentId : this.record.requisitionId
      ).subscribe(results => {
        this.completedQuotes = results;
      });
    }
  }

  public addDocument(quote: MaterialBid, event: any): void {

    var files: any[] = [];
    this.documents = [];

    for (let index = 0; index < event.length; index++) {
      const element = event[index];
      files.push({ document: element });
    }

    quote.answered = new Date();

    this.saving = true;
    this.quoteService.save(quote).subscribe(_ => {
      this.doUploads(quote, files);
    });
  }


  private doUploads(quote: MaterialBid, files: any[]): void {

    if (files.length > 0) {
      var file = files.pop();

      this.upload(file).subscribe(d => {
        this.documents.push(d);
        this.doUploads(quote, files);
      });
    }
    else {
      this.quoteService.addDocuments(quote, this.documents).subscribe(_ => {
        this.saving = false;
        this.showQuote(quote);
      });
    }
  }

  private upload(file: any): Observable<VirtualDocument> {
    var subject = new Subject<VirtualDocument>();
    this.documentService.upload(file.document)
      .subscribe(r => {
        subject.next(r);
      });

    return subject;
  }

  private async getDetail() {
    this.record = this.completedQuotes = null;
    
    var detail: Order | Requisition;
    if (this.type === "order") {
      detail = await this.orderSvc.getDetail(this.id).toPromise();
      this.order = detail;
    } else if (this.type === "requisition") {
      detail = await this.supplierSvc.getRequisitionDetail(this.id).toPromise();
      this.req = detail;
    }
    this.record = detail;
    this.gatherData();

    this.supplierSvc.getApprovedQuotesByVendor(this.isOrder(this.record) ? this.record.orderSegmentId : this.record.requisitionId).subscribe(r => {
      this.approvedQuotes = r;
      this.approvedQuotes = this.approvedQuotes.map(v => ({ ...v, blacklist: []}));
    });
    this.supplierSvc.getPurchaseOrdersForOrder(this.isOrder(this.record) ? this.record.orderSegmentId : this.record.requisitionId).subscribe(r => {
      this.existingPOs = r;
    });
  
    this.setSelection(null);

    this.setPageTitle();
    
  }

  public closeSideNav(): void {
    this.showEditor = this.detailId = null;
    this.navService.popBreadCrumb();

    this.getDetail();
  }

  private setPageTitle(): void {
    if (!this.parentSidenav) this.navService.setPageTitle("Purchasing Detail");
    this.navService.pushBreadcrumb(this.type === "order" ? this.order.orderNumber : this.req.requisitionNumber);
  }

  private gatherData(): void {
    this.materials = [];
    this.outsourced = [];
    this.purchased = [];
    
    if (this.isOrder(this.record)) {
      this.gatherMaterials(this.record.products.map(p => p.product), true);
      this.gatherOursourced(this.record.products.map(p => p.product), true);
      this.gatherPurchasedItems(this.record.products.map(p => p.product), true);
    } else if (this.isReq(this.record)) {
      this.record.requisitionLineItems.filter(i => i.materialId).forEach(i => {
        this.materials.push(<SuppliedProduct<Material>> {
          item: i.material,
          quantity: i.amountRequired
        });
      });
      this.record.requisitionLineItems.filter(i => i.purchasedItemId).forEach(i => {
        this.purchased.push(<SuppliedProduct<PurchasedItem>> {
          item: i.purchasedItem,
          quantity: i.amountRequired
        });
      });
      this.record.requisitionLineItems.filter(i => i.stationId).forEach(i => {
        this.outsourced.push(<SuppliedProduct<WorkflowStep>> {
          item: <WorkflowStep>{
            stationId: i.stationId
          },
          quantity: i.amountRequired
        });
      })
    }
  }

  private async gatherMaterials(products: Product[], top?: boolean) {
    if (!products || products.length == 0) return;
    if (top) this.materialsLoading = true;

    for (var i in products) {
      const product: Product = await this.orderSvc.getProduct(products[i].productId).toPromise();

      if (product.productRepairPlan && product.materialId != null) {
        //if (product.materialLotCost == null || product.materialLotCost == 0)
        {
          this.materials.push(<SuppliedProduct<Material>>{
            productId: product.productId,
            item: product.material,
            partNumber: product.partNumber,
            revision: product.revision
          });
        }
      }

      await this.gatherMaterials(product.childAssemblies);
    }

    if (top) {
      if (!this.displayPerPart) {
        this.materials = this.materials.filter((val, i, arr) => { return arr.findIndex(m => m.item.materialId == val.item.materialId) === i });
      }

      this.materialsLoading = false;
    }
  }

  private async gatherOursourced(products: Product[], top?: boolean) {
    if (!products || products.length == 0) return;
    if (top) this.outsourcedLoading = true;

    for (var i in products) {
      const product: Product = await this.orderSvc.getProduct(products[i].productId).toPromise();

      if (!product.workflow) continue;

      const targetSteps = product.productRepairPlan ? product.productRepairPlan.workflow.workflowSteps : product.workflow.workflowSteps;

      for (var j in targetSteps) {
        var step = targetSteps[j];

        if (step.outsourceMarkup != null /*&& (step.runPrice == null || step.runPrice == 0)*/) {
          this.outsourced.push(<SuppliedProduct<WorkflowStep>>{
            productId: product.productId,
            item: step,
            partNumber: product.partNumber,
            revision: product.revision
          });
        }
      }

      await this.gatherOursourced(product.childAssemblies);
    }

    if (top) {
      if (!this.displayPerPart) {
        this.outsourced = this.outsourced.filter((val, i, arr) => { return arr.findIndex(o => o.item.stationId == val.item.stationId) === i });
      }

      this.outsourcedLoading = false;
    }
  }

  private async gatherPurchasedItems(products: Product[], top?: boolean) {
    if (!products || products.length == 0) return;
    if (top) this.purchasedItemsLoading = true;

    for (var i in products) {
      const product: Product = await this.orderSvc.getProduct(products[i].productId).toPromise();

      const items = product.productRepairPlan ? product.productRepairPlan.purchasedItems : product.purchasedItems;

      for (var j in items) {
        var purchasedItem = items[j];

        //if (purchasedItem.costPer == null || purchasedItem.costPer == 0)
        {
          this.purchased.push(<SuppliedProduct<PurchasedItem>>{
            productId: product.productId,
            item: purchasedItem.purchasedItem,
            partNumber: product.partNumber,
            revision: product.revision,
            quantity: purchasedItem.quantity
          });
        }
      }

      await this.gatherPurchasedItems(product.childAssemblies);
    }

    if (top) {
      if (!this.displayPerPart) {
        this.purchased = this.purchased.filter((val, i, arr) => { return arr.findIndex(p => (p.item.description || '').toLowerCase().trim() == (val.item.description || '').toLowerCase().trim()) === i });
      }

      this.purchasedItemsLoading = false;
    }
  }

  public getCost(bid: MaterialBid) {
    if (bid.perItemBid) return bid.perItemBid * bid.qty;
    else if (bid.totalBid) return bid.totalBid;
    else return null;
  }

  public getTotalCost(bids: MaterialBid[]) {
    return bids.reduce((acc, bid) => {
      const cost = this.getCost(bid) || 0;
      return acc + cost;
    }, 0);
  }

  public getStationName(step: WorkflowStep): string {
    if (this.stationSvc.stationList == null) return null;
    return this.stationSvc.stationList.find(s => s.stationId == step.stationId).name;
  }

  public weeksLeft(date: string, abs: boolean): number {
    if (date == null) return null;

    return UtilityService.getWeeksRemaining(new Date(date), abs);
  }

  public getMaterialName(material: Material): string {
    return Material.generatedName(material);
  }

  public getRequiredColor(date: string): string {
    return UtilityService.getDateRequiredColor(date);
  }

  public showDetail(product: SuppliedProduct<any>, typeId: string): void {
    this.selectedProduct = product;
    this.selectedProduct.usePart = this.displayPerPart;
    this.detailId = typeId;
    this.showEditor = "detail";
    this.navService.pushBreadcrumb("Supplier Selection");
    this.sidenav.toggle();
  }

  togglePartDisplay(): void {
    this.displayPerPart = !this.displayPerPart;
    this.gatherData();
  }

  public showQuote(quote: MaterialBid): void {
    this.selectedQuoteId = quote.materialBidId;
    this.showEditor = 'quote';
    this.navService.pushBreadcrumb("Quote Detail");
    this.sidenav.toggle();
  }

  public openPO(po: PurchaseOrder) {
    this.router.navigate(['purchasing/order', po.purchaseOrderId])
  }

  public get requiredDate(): Date {
    if (this.order) return this.order.requiredDate;
    else if (this.req) return this.req.dueDate;
    else return null;
  }

  public getProductInfo(bid: MaterialBid): string {
    if (bid.materialId) {
      //Material
      return Material.generatedName(bid.material);
    }
    else if (bid.stationId) {
      //station
      return bid.station && bid.station.name;
    }
    else {
      //purchased item
      return bid.purchasedItem && bid.purchasedItem.description;
    }
  }

  public toggleBidBlacklist(group: SupplierDetailComponent['approvedQuotes'][0], bidId: string, event: MatCheckboxChange) {
    if (!event.checked) {
      group.blacklist = [...group.blacklist, bidId];
    } else {
      group.blacklist = group.blacklist.filter(id => id !== bidId);
    }
  }

  public async createPO(group: SupplierDetailComponent['approvedQuotes'][0]) {

    const bidIds = group.materialBids.filter(b => !group.blacklist.includes(b.materialBidId)).map(b => b.materialBidId);

    const result = await this.utilitySvc.showConfirmationPromise('Create PO?',
    `Are you sure you want to create a PO for vendor <b>${group.vendor.name}</b> with <b>${bidIds.length}</b> line items?`)
    if (!result) return;
    this.saving = true;
    const po = await this.supplierSvc.createVendorPOFromBids(group.vendor, bidIds).toPromise();
    this.saving = false;
    if (po) {
      this.utilitySvc.showConfirmation("PO Created Successfully", "<p>Click 'Okay' to go to the new PO, or click 'Cancel' to continue quoting.", res => {
        if (res) {
          this.router.navigate(['/purchasing/order', po.purchaseOrderId]);
        } else {
          this.getDetail();
        }
      });
    }
  }

  getStatusColorClass(status: number): string {
    return PurchaseOrderStatus.getStatusColorClass(status);
  }

  getStatusText(status: number): string {
    return PurchaseOrderStatus.getStatusText(status);
  }


  ngOnInit(): void {
    this.getDetail();
  }
}

export interface SuppliedProduct<T> {
  productId: string;
  partNumber: string;
  revision: string;
  usePart: boolean;
  quantity?: number;
  item: T;
}
