import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { NgForm, NgModel } from '@angular/forms';
import { MatSidenav } from '@angular/material/sidenav';
import { Router } from '@angular/router';
import { debounceTime, tap } from 'rxjs/operators';
import { Address } from '../../../common/resources/address';
import { NavigationService } from '../../../common/services/navigation.service';
import { UtilityService } from '../../../common/services/utility.service';
import { Building } from '../../../floor/resources/building';
import { FloorService } from '../../../floor/services/floor.service';
import { InventoryLocation } from '../../../inventory/resources/inventory-location';

@Component({
  selector: 'building-editor',
  templateUrl: './building-editor.component.html',
  styleUrls: ['./building-editor.component.less']
})
export class BuildingEditorComponent implements OnInit {
  public loading = false;
  public saving = false;

  @ViewChild('itemForm') itemForm: NgForm;
  @Input() sidenav: MatSidenav;

  constructor(private router: Router, private navService: NavigationService, private service: FloorService, private util: UtilityService) { }

  public async close() {
    if ((this.itemForm && this.itemForm.dirty) || (this.selectedItem && this.selectedItem.buildingId) === UtilityService.emptyGuid) {
      const confirm = await this.util.showConfirmationPromise('Exit?', 'Are you sure you want to exit the editor? You will lose any unsaved changes.');
      if (!confirm) return;
    }
    if (this.sidenav)
      this.sidenav.close();
    else
      this.router.navigate(['/admin']);
  }

  public selectedItem: Building | null;
  public items: Building[] = [];


  public searching = false;
  canScroll = true;
  currentPageIndex = 0;

  public async onSearch() {
    this.searching = true;
    const { results, resultCount } = await this.service.searchBuildings(this.filter, 0, 30).toPromise();
    this.searching = false;
    this.items = results;
    this.currentPageIndex = 0;

    this.canScroll = this.items.length < resultCount;
  }

  public async onListScroll(e: Event) {

    const panel = e.target as HTMLUListElement;
    const maxScrollPosition = panel.scrollHeight - panel.clientHeight;
    const scrollThreshold = maxScrollPosition - 80;
    if (panel.scrollTop > scrollThreshold && !this.searching && this.canScroll) {
      this.currentPageIndex += 1;
      this.searching = true;
      const { results, resultCount } = await this.service.searchBuildings(this.filter, this.currentPageIndex, 30).toPromise();
      this.searching = false;

      this.items = this.items.concat(results);

      this.canScroll = this.items.length < resultCount;
    }
  }

  public resetForm() {
    if (this.itemForm && this.itemForm.form) {
      this.itemForm.form.markAsPristine();
      this.itemForm.form.markAsUntouched();
    }
  }

  public async setSelectedItem(item: Building) {
    if ((this.itemForm && this.itemForm.dirty) || (this.selectedItem && this.selectedItem.buildingId) === UtilityService.emptyGuid) {
      const confirm = await this.util.showConfirmationPromise('Exit?', 'Are you sure you want to exit the current item? You will lose any unsaved changes.');
      if (!confirm) return;
    }
    this.loading = true;
    this.selectedItem = item;
    this.resetForm();
    this.navService.popBreadCrumb();
    this.navService.pushBreadcrumb(item.name);
    this.loading = false;
  };

  public async saveSelected() {
    if (!this.selectedItem) return;
    this.saving = true;
    const isNew = this.selectedItem.buildingId === UtilityService.emptyGuid;
    const item = await this.service.saveBuilding(this.selectedItem).toPromise();
    if (isNew) {
      this.filter = '';
    }
      this.onSearch();
    this.saving = false;
    this.selectedItem.buildingId = item.buildingId;
  } 

  public async deleteSelected() {
    if (!this.selectedItem) return;
    const deletedId = this.selectedItem.buildingId;

    const confirm = await this.util.showConfirmationPromise(
      `Delete Building "${this.selectedItem.name}"?`,
      "Are you sure you want to remove this building?</p><p class='text-danger'>Removing a building will impact ALL departments, planning, and shipping that use it department. This is probably not a good idea!</p>",
      3);

    this.saving = true;
    if (!confirm) return;

    await this.service.removeBuilding(deletedId).toPromise();
    this.saving = false;
    this.items = this.items.filter(x => x.buildingId !== deletedId);
    this.selectedItem = null;
  } 

  public createNewItem() {
    this.selectedItem = {
      buildingId: UtilityService.emptyGuid,
      name: '',
      addressId: UtilityService.emptyGuid,
      address: <Address>{
        addressId: UtilityService.emptyGuid,
        streetAddress: '',
        streetAddress2: '',
        city: '',
        postalCode: '',
        nickname: '',
        phoneNumber: '',
        faxNumber: ''
      },
      rootInventoryLocationId: null,
    };
    this.resetForm();
  }

  public get isCreating() {
    return this.selectedItem && this.selectedItem.buildingId === UtilityService.emptyGuid;
  }

  public get canSave() {
    return this.itemForm.valid && this.itemForm.dirty;
  }

  public filter = '';

  ngOnInit() {
    this.navService.clearBreadCrumbs();
    this.navService.pushBreadcrumb("Building Editor");
    this.onSearch();
  }

  @ViewChild('filterModel', { static: true }) filterModel: NgModel;
  ngAfterViewInit() {
    if (!this.filterModel || !this.filterModel.valueChanges) return;
    this.filterModel.valueChanges.pipe(
      tap(() => this.searching = true),
      debounceTime(250)
    ).subscribe(() => this.onSearch());
  }

  public onLocationChange(location: InventoryLocation) {
    if (!location) this.selectedItem.rootInventoryLocationId = null;
    else this.selectedItem.rootInventoryLocationId = location.inventoryLocationId;
  }

}
