import { Injectable, OnDestroy } from "@angular/core";
import { Order, OrderProduct, OrderSegmentProductReviewStatus, OrderStatus, QuoteLineItem } from "../../resources/order";
import { MaterialBid } from "../../../purchasing/resources/materialBid";
import { Product } from "../../resources/product";
import { MaterialSelectComponent } from "../material-select/material-select.component";
import { Material, MaterialDimension } from "../../resources/material";
import { MaterialBidService } from "../../../purchasing/services/material-bid.service";
import { BehaviorSubject, Observable, ReplaySubject, Subject, Subscription, combineLatest, concat, merge, of, timer } from "rxjs";
import { OutsideProcessSpecification, Station } from "../../resources/station";
import { StationService } from "../../services/station.service";
import { WorkflowStep } from "../../resources/workflow";
import { UtilityService } from "../../../common/services/utility.service";
import { Router } from "@angular/router";
import { AsyncPipe, Location } from "@angular/common";
import { ProductHierarchySortComponent } from "../../../planning/components/product-hierarchy-sort/product-hierarchy-sort.component";
import { debounceTime, filter, map, mergeMap, pairwise, pluck, sampleTime, scan, share, shareReplay, skip, skipUntil, startWith, switchAll, switchMap, take, tap, throttleTime, withLatestFrom } from "rxjs/operators";
import { VirtualDocument } from "../../../common/resources/virtual-document";
import { DocumentService } from "../../../common/services/document.service";
import { get, set } from 'idb-keyval';
import { UserService } from "../../../common/services/user.service";
import { OrderService } from "../../services/order.service";
import { Task, TaskStatus } from "../../../common/resources/estimatingtask";
import { EstimateProgressService } from "../../services/estimate-progress.service";
import { SelectionModel } from "@angular/cdk/collections";
import { MicroTicket } from "../../../microtickets/resources/microticket";

interface OrderDetailValidationError {
  error: string,
  screen: 'productList' | 'form' | 'material' | 'workflowStep' | 'purchasedItem'
  formField?: string
  productId?: string
  itemId?: string
}

function doAltText(text: string, alt: string) {
  return `<abbr title="${alt}">${text}</abbr>`
}

export interface PricingBreakdownSubitem {
  name: string,
  id?: string,
  calculation?: string,
  value?: number,
  displayValue?: string
}

export interface PricingBreakdownItem {
  name: string,
  price: number,
  subitems: PricingBreakdownSubitem[]
}

export interface PricingBreakdown {
  material: PricingBreakdownItem,
  labor: PricingBreakdownItem[],
  process: PricingBreakdownItem[],
  purchasedItems: PricingBreakdownItem[],
}

type QuoteMap = { [itemId: string]: MaterialBid[] }

type QuoteStartMessage = {
  type: 'start',
  data: QuoteMap
}
type QuoteAddedMessage = {
  type: 'add',
  data: {
    itemId: string,
    quote: MaterialBid
  }
}
type QuoteBatchAddedMessage = {
  type: 'batch',
  data: QuoteMap
}

export async function fetchLatest<T>(obs: Observable<T>): Promise<T> {
  const result = await obs.pipe(take(1)).toPromise();
  return result;
}

@Injectable()
export class OrderDetailService {

  private _order: Order;

  public get order() { return this._order }
  public set order(val: Order) {
    this.progressService.order = val;
    this._order = val;
  }

  public productHierarchyExpansionModel = new SelectionModel<string>(true);


  public _selectedProductId: string;
  public productChange = new Subject<Product>();
  public suppressProductUpdate = false;
  public get selectedProductId() { return this._selectedProductId }
  public set selectedProductId(val: string) {
    this.suppressProductUpdate = true;
    if (!val) this.productHierarchyExpansionModel.clear();
    if (val !== null && this.mainTabBodyElement) {
      this.lastMainTabScroll = this.mainTabBodyElement?.scrollTop ?? 0;
    }
    this._selectedProductId = val;
    if (val === null) this.productTab = 0;
    if (val === null && this.mainTabBodyElement) {
      const observer = new ResizeObserver(() => {
        this.mainTabBodyElement.scrollTop = this.lastMainTabScroll ?? 0
        observer.disconnect();
      });
      observer.observe(this.mainTabBodyElement);
    }
    this.productChange.next(this.selectedProduct);
    setTimeout(() => this.suppressProductUpdate = false);
  }
  public get selectedProduct(): Product {
    if (this._selectedProductId === null) return null;
    return this.allProductsFlat().find(p => p.productId === this._selectedProductId);
  }
  public get selectedTopLevelProduct() {
    if (!this.selectedProduct) return null;
    else return this.order.products.find(tlp => tlp.productProductId === this.selectedProduct.topParentAssemblyId)?.product;
  }
  public editing = false;

  public loading$ = new BehaviorSubject(false);
  public set loading(value: boolean) {
    this.loading$.next(value);
  }

  public hierarchySort: ProductHierarchySortComponent;

  public oldData: Order;

  public setOldData() {
    this.oldData = JSON.parse(JSON.stringify(this.order));
  }
  
  public async startEditing() {
    this.setOldData();
    // if (this.order.discriminator === 'Estimate' || this.order.discriminator === 'RMAEstimate') {
    //   const r = await this.utilSvc.showConfirmationPromise("Edit estimate?", "It will have to be reapproved.");
    //   if (!r) {
    //     this.oldData = null;
    //     return;
    //   };
    //   this.order.status = 0;
    // }
    this.editing = true;
  }

  public dirty = false;
  public async stopEditing() {
    if (this.selectedProductId) {
      this.selectedProductId = null;
      return;
    }
    if (this.dirty) {
      const r = await this.utilSvc.showConfirmationPromise('Are you sure?', 'If you cancel editing, all your changes will be lost. This cannot be undone.');
      if (!r) return;
    }
    if (this.order.orderSegmentId === UtilityService.emptyGuid) {
      this.location.back();
    } else {
      if (this.dirty) this.order = this.oldData;
      this.dirty = false;
      this.selectedProductId = null;
      this.resetMetadata();
      this.onProductsChange(this.order.products.map(p => p.product));
      if (this.hierarchySort) this.hierarchySort.getDetail();
      this.oldData = null;
      this.editing = false;
    }
  }

  public onTopLevelProductChange(product: Product) {
    let current = this.order.products?.map(p => p.product) ?? [];
    let index = current.findIndex(p => p.productId === product.productId);
    if (index === -1) return;
    current.splice(index, 1, product);
    this.progressService.syncOrCreateTopLevelProductTask(product);
    this.onProductsChange(current);
  }

  public onProductsChange(newProducts: Product[]) {
    const oldProducts: OrderProduct[] = JSON.parse(JSON.stringify(this.order.products));
    const toDelete = oldProducts.filter(op => newProducts.findIndex(np => np.productId === op.productProductId) === -1);
    const toUpdate = oldProducts.filter(op => newProducts.findIndex(np => np.productId === op.productProductId) !== -1);
    const toAdd = newProducts.filter(np => oldProducts.findIndex(op => op.productProductId === np.productId) === -1);
    let output: OrderProduct[] = JSON.parse(JSON.stringify(this.order.products));
    for (const item of toDelete) {
      output = output.filter(o => o.productProductId !== item.productProductId)
    }
    for (const item of toUpdate) {
      const index = output.findIndex(o => o.productProductId === item.productProductId);
      const newItem = newProducts.find(p => p.productId === item.productProductId);
      output[index].product = newItem;
    }
    for (const item of toAdd) {
      output = [...output, <OrderProduct>{
        orderSegmentOrderSegmentId: this.order.orderSegmentId,
        productProductId: item.productId,
        product: item
      }]
    }
    this.order.products = output;
    this.order.products.forEach((p, i) => p.sort = i);
    this.productsModified.next(this.order.products);
  }

  private allChildrenFlatRecursive(product: Product): Product[] {
    return product.childAssemblies.flatMap(p => this.allProductsFlatRecursive(p))
  }

  private allProductsFlatRecursive(product: Product): Product[] {
    return [product, ...this.allChildrenFlatRecursive(product)]
  }

  public allProductsFlat(): Product[] {
    return this.order.products.flatMap(p => this.allProductsFlatRecursive(p.product));
  }

  // Any IDs for items that need to be saved later go here
  public newIds: string[] = [];
  public addNewId(id: string) {
    this.newIds.push(id);
  }

  public addClonedProductIds(product: Product, documents = true) {
    const allProducts = this.allProductsFlatRecursive(product);
    const ids = [];
    for (const product of allProducts) {
      ids.push(product.productId);
      ids.push(product.workflow.workflowId);
      product.workflow.workflowSteps.forEach(s => {
        ids.push(s.workflowStepId);
        s.workflowStepInventoryItems?.forEach(wsii => ids.push(wsii.workflowStepInventoryItemId));
      });
      product.purchasedItems.forEach(p => ids.push(p.productPurchasedItemId));
      // if (documents) product.documents.forEach(p => ids.push(p.documentDocumentId));
    }
    this.newIds.push(...ids);
  }

  public mapMaterialSearchStatus(map: { [oldId: string]: string }) {
    for (const oldId in map) {
      if (Object.prototype.hasOwnProperty.call(map, oldId)) {
        const newId = map[oldId];
        const oldInputMaterial = this.productsWithInputMaterial[oldId];
        if (oldInputMaterial) this.productsWithInputMaterial[newId] = JSON.parse(JSON.stringify(oldInputMaterial));
        const oldInputParameters = this.productMaterialParameters[oldId];
        if (oldInputParameters) this.productMaterialParameters[newId] = JSON.parse(JSON.stringify(oldInputParameters));
      }
    }
  }
  // Storing products that have a newly built material and will require extra logic in the backend.
  public productsWithInputMaterial: { [key: string]: (MaterialSelectComponent['parameterIds']) } = {}
  public productMaterialParameters: { [key: string]: { params: (MaterialSelectComponent['materialParameters']) , dimensions: MaterialDimension[], state: MaterialSelectComponent['state'], hasFinishedSpecs: boolean, density: number } } = {}
  
  public getStation(item: WorkflowStep): Station {
    if (this.stationService.stationList == null || item == null || item.stationId == null)
      return null;

    return this.stationService.stationList.find(r => r.stationId == item.stationId);
  }

  public quotesByItemObservableMap: { [itemId: string]: Observable<MaterialBid[]> } = {};
  public getItemQuotesObservable(itemId: string, isNewMaterial = false): Observable<MaterialBid[]> {
    if (this.quotesByItemObservableMap[itemId]) return this.quotesByItemObservableMap[itemId];
    const newObservable = this.allQuotes.pipe(
        map(all => {
          return all[itemId]
        }),
        filter(i => !!i),
        shareReplay(1)
      );
    this.quotesByItemObservableMap[itemId] = newObservable;

    fetchLatest(this.allQuotes).then(all => {
      // If we haven't seen this item before, go fetch it from the API
      if (!all[itemId]) {
        this.quoteService.searchItem(itemId).subscribe(results => {
          this.quotesFetched.next({
            [itemId]: results.results
          })
        });
      }
    });

    return newObservable;
  }

  public quoteAdded = new Subject<{
    itemId: string,
    quote: MaterialBid,
  }>();

  public addQuote(itemId: string, quote: MaterialBid, isNewMaterial = false) {
    // if (isNewMaterial) {
    //   if (!this.searchedMaterialQuotesMap[productId]) this.searchedMaterialQuotesMap[productId] = [];
    //   this.searchedMaterialQuotesMap[productId] = [...this.searchedMaterialQuotesMap[productId], quote];
    // } else {
    //   if (!this.quotesMap[productId]) this.quotesMap[productId] = {};
    //   if (!this.quotesMap[productId][itemId]) this.quotesMap[productId][itemId] = [];
    //   this.quotesMap[productId][itemId] = [...this.quotesMap[productId][itemId], quote];
    // }
    this.addNewId(quote.materialBidId);
    this.quoteAdded.next({ itemId, quote });
  }

  // Have to save new outside process specs so that they will display properly before saving
  public newOPSpecs: OutsideProcessSpecification[] = [];
  
  public getFirstQuantity(product: Product): number {
    if (product.parentAssemblyId && product.quantityAsChild) return product.quantityAsChild;
    if (product.quantitiesMap == null) return 1;

    var quantities = product.quantitiesMap;

    if (quantities.length == 0) return 1;

    return quantities[0].value;
  }

  public async getProductLeadTime(product: Product, parentQty: number = 1): Promise<number> {
    let qty = this.getFirstQuantity(product) * parentQty;
    let totalLeadTimeDays = 0;
    if (product.material && product.selectedMaterialQuote) {
      const matQuotes = await fetchLatest(this.getItemQuotesObservable(product.materialId));
      const selectedMaterialQuote = matQuotes.find(q => q.materialBidId === product.selectedMaterialQuote);
      totalLeadTimeDays += selectedMaterialQuote?.leadTimeDays ?? 0;
    }
    let laborTimeHours = 0;
    for (const step of product.workflow?.workflowSteps ?? []) {
      const station = this.getStation(step);
      if (station.isOutsourceStep && step.selectedQuote) {
        const stepQuotes = await fetchLatest(this.getItemQuotesObservable(step.stationId));
        const selectedStepQuote = stepQuotes.find(q => q.materialBidId === step.selectedQuote);
        totalLeadTimeDays += selectedStepQuote?.leadTimeDays ?? 0;
      } else if (!station.isOutsourceStep) {
        if (step.runIsPerPart == true)
        {
            laborTimeHours += (((step.runTime ?? 0) / 60) + (step.hasSetup ? (step.perPieceSetupTime ? (step.perPieceSetupTime / 60) : 0) : 0)) * (qty);
        }
        else
        // else time is already in hours and doesn't need to be multiplied
        {
            laborTimeHours += (step.runTime ?? 0);
        }
        var setupTime = (step.hasSetup ? (step.setupTime ? step.setupTime : 0) : 0);
        laborTimeHours += setupTime;
      }
    }
    const laborTimeDays = Math.ceil(laborTimeHours / 8);
    totalLeadTimeDays += laborTimeDays;
    for (const item of product.purchasedItems ?? []) {
      if (item.selectedQuote) {
        const stepQuotes = await fetchLatest(this.getItemQuotesObservable(item.purchasedItem.purchasedItemId));
        const selectedItemQuote = stepQuotes.find(q => q.materialBidId === item.selectedQuote);
        totalLeadTimeDays += selectedItemQuote?.leadTimeDays ?? 0;
      }
    }
    // children
    const childLeadTimes = await Promise.all((product.childAssemblies ?? []).map(c => this.getProductLeadTime(c, qty)));
    if (childLeadTimes.length > 0) {
      const highestChildLeadTime = Math.max(...childLeadTimes);
      totalLeadTimeDays += highestChildLeadTime ?? 0;
    }
    totalLeadTimeDays += product.leadTimeBuffer ?? 0;
    return totalLeadTimeDays;
  }

  public getProductMaterial(product: Product) {
    if (product.material) return product.material;
    else if (this.productsWithInputMaterial[product.productId]) {
      const { params, dimensions, density, state, hasFinishedSpecs } = this.productMaterialParameters[product.productId];
      if (state !== 'DONE' || !hasFinishedSpecs) return null;
      else return <Material>{
        materialType: params.type,
        materialDimensions: dimensions,
        density
      }
    } else return null;
  }

  private getProductPrice(product: Product, qty: number, parentQty: number = null): number {
    // do this to make sure we have all quotes. should be instant if we do since we store them clientside
    // Should always exist after the previous method is run
    if (parentQty) qty = qty * parentQty;
    let totalPrice = 0;
    let material = this.getProductMaterial(product);
    if (material && product.selectedMaterialQuote) {
      const materialCost = Product.getMaterialCost(product, qty, material);
      totalPrice += materialCost;
    }
    const stepCostList = product.workflow.workflowSteps.filter(s => !s.isStandalone).sort((a,b) => a.stepOrder - b.stepOrder).map(s => WorkflowStep.calculateCostForQty(s, qty));
    const stepCost = stepCostList.reduce((acc, x) => acc + x, 0);
    totalPrice += stepCost;
    let purchasedItemPrice = 0;
    for (const item of product.purchasedItems ?? []) {
      let itemPrice = item.costPer ?? 0;
      itemPrice = itemPrice * item.quantity;
      if (!item.isNonRecurring) itemPrice = itemPrice * qty;
      purchasedItemPrice += itemPrice * (1 + ((item.markupPercent ?? 0.0) / 100.0));
    }
    totalPrice += purchasedItemPrice;
    // children
    const childCosts = (product.childAssemblies ?? []).map(c => this.getProductPrice(c, c.quantityAsChild, qty));
    totalPrice += childCosts.reduce((acc, x) => acc + x, 0);
    return totalPrice;
  }

  public async getProductUnitPrice(product: Product, parentQty: number = null) {
    let qty = this.getFirstQuantity(product);
    const total = this.getProductPrice(product, qty, parentQty);
    return parseFloat((total / qty).toFixed(2));
  }

  public productEdited = new Subject<Product>();
  public orderEdited = new Subject<Order>();
  public productsModified = new BehaviorSubject<OrderProduct[]>([]);
  public notifyEdited(product: Product = null) {
    if (this.editing && !this.suppressProductUpdate) {
      this.productEdited.next(product ?? this.selectedProduct);
    }
  }
  
  // Some navigation bindings so we can easily jump to certain parts of the app using the service
  public mainTab = 0;
  public productTab = 0;
  public selectedWorkflowStepId: string;
  public selectedPurchasedItemId: string;

  public async validate(product: Product): Promise<OrderDetailValidationError> {
    // await this.getAllQuotesForProduct(product);
    // Quantities
    if (!product.parentAssemblyId && product.quantitiesMap.length === 0) {
      const screen = this.order.discriminator === 'RFQ' ? 'productList' : 'form';
      const i = this.order.products.findIndex(op => op.productProductId === product.productId);
      const formField = this.order.discriminator === 'RFQ' ? `orderProducts[${i}].quantitiesMap` : null;
      return { error: `Part ${product.partNumber} Rev. ${product.revision} needs at least 1 quantity`, screen, formField, productId: product.productId }
    }
    if (this.order.discriminator !== 'RFQ' && product.parentAssemblyId && (!product.quantityAsChild || product.quantityAsChild < 0)) {
      return { error: 'Subassembly needs a valid quantity', screen: 'form', productId: product.productId }
    }
    // Workflow steps
    // for (const step of product.workflow.workflowSteps) {
    //   if (this.getStation(step).isOutsourceStep) {
    //     // ...
    //   } else {

    //   }
    // }
    // Purchased items
    for (const item of product.purchasedItems) {
      if (!item.purchasedItem) return { error: 'Please select a purchased item before saving', screen: 'purchasedItem', productId: product.productId, itemId: item.productPurchasedItemId }
    }
  }

  public async validateAll(): Promise<OrderDetailValidationError> {
    if (!this.order.customer) return { error: 'Please select a customer before saving.', screen: 'form' }
    const all = this.allProductsFlat();
    for (const product of all) {
      const error = await this.validate(product);
      if (error) return error;
    }
  }

  public async uploadDocument(file: File): Promise<VirtualDocument> {
    this.loading = true;
    const doc = this.documentService.upload(file as any).toPromise();
    this.addNewId((await doc).documentId);
    this.loading = false;
    return doc;
  }

  public async batchUploadDocuments(files: File[]): Promise<VirtualDocument[]> {
    this.loading = true;
    const docs = await Promise.all(files.map(f => this.documentService.upload(f as any).toPromise()));
    docs.forEach(d =>  this.addNewId(d.documentId))
    this.loading = false;
    return docs;
  }

  resetMetadata() {
    this.newIds = [];
    this.productsWithInputMaterial = {};
    this.productMaterialParameters = {};
    this.newOPSpecs = [];
    this.movedProductIds = new Set();
  }

  public get products() {
    return this.order.products?.map(p => p.product);
  }

  public initStartingQuotes() {
    this.quoteService.getAllQuotesForOrder(this.order).subscribe(x => this.startingQuotes.next(x));
  }

  public startingQuotes = new ReplaySubject<{ [key: string]: MaterialBid[]}>(1);
  private observableMap: { [key: string]: Observable<Product> } = {};
  // public allProducts: Observable<{ [key: string]: Product }>
  public productsReset = new ReplaySubject<Product[]>(1);




  public allQuotes: Observable<{ [productId: string]: MaterialBid[] }>;

  public allQuotesStatic: { [productId: string]: MaterialBid[] };
  
  public quotesFetched = new Subject<QuoteMap>();
  constructor(
    private documentService: DocumentService,
    private quoteService: MaterialBidService,
    public stationService: StationService,
    private utilSvc: UtilityService,
    private location: Location,
    private asyncPipe: AsyncPipe,
    private orderService: OrderService,
    private userSvc: UserService,
    private progressService: EstimateProgressService
    ) {
    this.allQuotes = concat(
      this.startingQuotes.pipe(take(1)), 
      merge(
        this.startingQuotes.pipe(map(data => ({
          type: 'start',
          data
        }))),
        this.quoteAdded.pipe(map(data => ({
          type: 'add',
          data
        }))),
        this.quotesFetched.pipe(map(data => ({
          type: 'batch',
          data
        })))
      )
    ).pipe(
      scan<any, QuoteMap>((acc: QuoteMap, val: QuoteMap | QuoteStartMessage | QuoteAddedMessage | QuoteBatchAddedMessage) => {
        if (!val.type) return val as QuoteMap;
        if (val.type === 'start') acc = val.data;
        else if (val.type === 'batch') acc = { ...acc, ...val.data };
        else if (val.type === 'add') {
          const { itemId, quote } = val.data;
          if (!acc[itemId]) acc[itemId] = [];
          // For some reason, in some instances every new quote triggers the subject twice
          // I don't have time to figure out why on earth that happens, so I'm adding a bandaid fix here
          if (acc[itemId].findIndex(x => x.materialBidId === val.data.quote.materialBidId) === -1) acc[itemId].push(quote);
        }
        return acc;
      }),
      shareReplay(1)
    );

    merge(this.orderEdited, this.productsModified, this.productEdited).subscribe(() => this.dirty = true);

    this.productEdited.subscribe(() => console.log('PRODUCTEDITED'));
  }

  private mapSubassemblies(product: Product): string[] {
    return [product.productId, ...product.childAssemblies.flatMap(ca => this.mapSubassemblies(ca))];
  }

  private findSubassembly


  public productObservables: { [key: string]: Observable<Product> } = {}
  public productSubjects: { [key: string]: BehaviorSubject<Product> } = {}

  private createProductObservableAndSubject(productId: string) {
    const obs = merge(
      this.productsReset.pipe(
        map(l => l.flatMap(p => this.allProductsFlatRecursive(p))),
        map(l => l.find(p => p.productId === productId)),
        filter(l => !!l)
      ),
      this.productEdited
    ).pipe(
      scan((state, p) => {
        if (!p) {
          state.emit = false;
        } else if (p?.productId === productId) {
          state.subassemblyMap = this.mapSubassemblies(p);
          state.product = p;
          state.emit = true;
        } else if (state.subassemblyMap.includes(p?.productId)) {
          const position = state.subassemblyMap.findIndex(id => id === p.productId);
          if (position !== -1) state.subassemblyMap.splice(position, 1, ...this.mapSubassemblies(p));
          // We have to replace the product in the subassemblies with the one we just got
          state.emit = true;
        } else {
          state.emit = false;
        }
        return state;
      }, {
        product: null,
        subassemblyMap: [],
        emit: false
      }),
      filter(state => state.emit),
      switchMap(state => {
        const subassemblies = (state.product as Product).childAssemblies.map(x => this.getProductObservable(x.productId).pipe(take(1)));
        if (subassemblies.length === 0) return combineLatest([of(state.product as Product), of([])]);
        else return combineLatest([of(state.product as Product), combineLatest(subassemblies)]);
      }),
      map(([product, subassemblies]) => {
        product.childAssemblies = subassemblies;
        return product;
      }),
      shareReplay(1)
    );
    const flat = this.allProductsFlat();
    const base = flat.find(p => p.productId === productId);
    const sbj = new BehaviorSubject<Product>(base);
    obs.subscribe(sbj);
    this.productObservables[productId] = obs;
    this.productSubjects[productId] = sbj;
  }

  public getProductObservable(productId: string) {
    if (!this.productObservables[productId]) {
      this.createProductObservableAndSubject(productId);
    }
    return this.productObservables[productId];
  }
  public getProductSubject(productId: string) {
    if (!this.productObservables[productId]) {
      this.createProductObservableAndSubject(productId);
    }
    return this.productSubjects[productId];
  }

  public async getProductsLatestValues(): Promise<OrderProduct[]> {
    const products = this.order.products.map((op) => {

      const sbj = this.getProductSubject(op.productProductId);
      return {
        ...op,
        product: sbj.getValue()
      }
    });
    return products;
  }

  public leadTimeObservables: { [key: string]: Observable<number> } = {}
  public getProductLeadTimeObservable(productId: string): Observable<number> {
    if (!this.leadTimeObservables[productId]) this.leadTimeObservables[productId] = this.getProductObservable(productId).pipe(
      switchMap(pr => this.getProductLeadTime(pr)),
      shareReplay(1)
    );
    return this.leadTimeObservables[productId];
  }

  public productPriceObservables: { [key: string]: { [key: number]: Observable<number> } } = {}
  public getProductPriceObservable(product: Product, qty: number): Observable<number> {
    let qtyMap = this.productPriceObservables[product.productId];
    if (!qtyMap) this.productPriceObservables[product.productId] = {};
    qtyMap = this.productPriceObservables[product.productId];
    if (!qtyMap[product.productId]) qtyMap[qty] = this.getProductObservable(product.productId).pipe(
      map(p => {
        if (!p) return 0;
        return this.getProductPrice(p, qty);
      }),
      shareReplay(1)
    );
    return qtyMap[qty];
  }

  public productUnitPriceObservables: { [key: string]: { [key: number]: Observable<number> } } = {}
  public getProductUnitPriceObservable(product: Product, qty: number = null): Observable<number> {
    let qtyMap = this.productUnitPriceObservables[product.productId];
    if (!qtyMap) this.productUnitPriceObservables[product.productId] = {};
    qtyMap = this.productUnitPriceObservables[product.productId];
    if (!qtyMap[product.productId]) qtyMap[qty ?? 0] = this.getProductObservable(product.productId).pipe(
      switchMap(p => {
        if (!qty) qty = this.getFirstQuantity(p);
        const markup = p.quantitiesMap.find(q => q.value === qty)?.markup ?? 0;
        return this.getProductPriceObservable(p, qty).pipe(
          take(1),
          map(o => {
          const markedUp = o * (1 + markup / 100);
          return parseFloat((markedUp / qty).toFixed(2));
        }));
      }),
      shareReplay(1)
    );
    return qtyMap[qty ?? 0];
  }

  public generateProgrammingPrice(product: Product) {
    return product.workflow.workflowSteps.filter(s => s.hasProgramming).map(s => WorkflowStep.getProgrammingCost(s)).reduce((acc, x) => acc + x, 0);
  }

  public async generateLineItems(): Promise<QuoteLineItem[]> {
    if (!this.stationService.loaded) {
      await this.stationService.stationsLoaded.toPromise();
    }
    const output = await Promise.all(this.order.products.map(osp => osp.product).map(async (product, i) => {
      // All quantities
      const leadTimeDays = await this.getProductLeadTime(product);
      let output: QuoteLineItem[] = [];
      const quantityItems = await Promise.all(product.quantitiesMap.map(async (qty) => {
        let cost = await this.getProductPrice(product, qty.value);
        const price = cost * ( 1 + qty.markup / 100);
        const unitPrice = parseFloat((price / qty.value).toFixed(2));
        let extPrice = unitPrice * qty.value;
        return <QuoteLineItem>{
          lineItemNumber: (i + 1).toString(),
          quoteLineItemId: UtilityService.emptyGuid,
          quoteId: UtilityService.emptyGuid,
          productId: product.productId,
          product: product,
          stationId: null,
          station: null,
          quantity: qty.value,
          unitPrice,
          extPrice,
          leadTimeDays,
        };
      }));
      output = output.concat(quantityItems);
      const standaloneStepItems = product.workflow.workflowSteps.filter((s) => s.isStandalone).map(step => {
        const qty = this.getFirstQuantity(product);
        let stepPrice = WorkflowStep.calculateCostForQty(step, qty);
        return <QuoteLineItem>{
          quoteLineItemId: UtilityService.emptyGuid,
          quoteId: UtilityService.emptyGuid,
          productId: product.productId,
          product: product,
          stationId: step.stationId,
          station: this.getStation(step),
          quantity: step.runIsPerPart ? qty : 1,
          unitPrice: step.runIsPerPart ? (stepPrice / qty) : stepPrice,
          extPrice: stepPrice,
          leadTimeDays,
        };
      });
      output = output.concat(standaloneStepItems);
      const programmingCost = this.generateProgrammingPrice(product);
      if (programmingCost > 0) {
        const progStation = this.stationService.stationList.find((s) => s.name === 'Programming');
        output.push({
          quoteLineItemId: UtilityService.emptyGuid,
          quoteId: UtilityService.emptyGuid,
          productId: product.productId,
          product: product,
          stationId: progStation.stationId,
          station: progStation,
          quantity: 1,
          unitPrice: programmingCost,
          extPrice: programmingCost,
          leadTimeDays,
        })
      }
      return output;
    }));
    return output.flat();
  }

  // Code for live updating estimating quote view
  public quotePreviewWindow: Window | null;
  public quotePreviewLoading = false;
  private quotePreviewSubscription: Subscription;
  private quotePreviewWindowInterval: any = null;

  public async updateQuotePreview() {
    this.quotePreviewLoading = true;
    const lineItems = await this.generateLineItems();
    const res = await fetch(`/api/orderSegment/getQuoteReportClientside/${this.order.orderSegmentId}`, {
      method: 'POST',
      body: JSON.stringify(lineItems),
      headers: {
        'Content-Type': 'application/json'
      },
    });
    const buffer = await res.arrayBuffer();
    const file = new Blob([buffer], { type: 'application/pdf' });
    const fileURL = URL.createObjectURL(file);
    const w = this.quotePreviewWindow;
    this.quotePreviewLoading = false;
    w.open(fileURL, '_self');
    setTimeout(() => w.document.title = `Quote Preview - ${this.order.orderSegmentId}`, 50);
  }

  private onPreviewWindowUnload() {
    if (this.quotePreviewWindow.closed) {
      this.quotePreviewWindow = null;
      this.quotePreviewLoading = false;
      clearInterval(this.quotePreviewWindowInterval);
      this.quotePreviewWindowInterval = null;
      if (this.quotePreviewSubscription) this.quotePreviewSubscription.unsubscribe();
    }
  }

  public closeQuotePreviewWindow() {
    if (this.quotePreviewWindow) {
      this.quotePreviewWindow.close();
    }
  }

  public startQuotePreview() {
    if (this.quotePreviewWindow) {
      this.quotePreviewWindow.focus();
      return;
    };
    this.quotePreviewWindow = window.open('about:blank', '_blank', 'menubar=no,status=no,toolbar=no');
    // only reliable way to check if window was closed seems to be constant interval
    this.quotePreviewWindowInterval = setInterval(() => {
      if (this.quotePreviewWindow.closed) this.onPreviewWindowUnload()
    }, 500)
    // this.quotePreviewWindow.addEventListener('beforeunload', this.onPreviewWindowUnload.bind(this));
    this.updateQuotePreview();
    this.quotePreviewSubscription = combineLatest([this.productsModified, this.productEdited.pipe(startWith(() => null))])
    .pipe(
      debounceTime(1000)
    )
    .subscribe(() => {
      this.updateQuotePreview();
    });
    window.addEventListener('beforeunload', this.closeQuotePreviewWindow.bind(this));
    return this.quotePreviewWindow;
  }

  public userIsManager(): boolean {
    return this.userSvc.canAccess('QuoteManager');
  }

  public canApproveRFQ() {
    return this.order && this.order.discriminator === 'RFQ' && this.order.status === OrderStatus.AWAITING_REVIEW && this.userSvc.canAccess("RFQReviewer");
  }

  public getIncompleteAssemblyInfo(product: Product) {
    const top = this.order.products.find(p => p.productProductId === product.topParentAssemblyId);
    if (!top) return null;
    if (top.reviewStatus !== 1 && top.reviewStatus !== 3) return null;
    else return { note: top.reviewStatusNote, status: top.reviewStatus };
  }

  public async markAsCorrected(product: Product) {
    this.loading = true;
    const top = this.order?.products?.find(p => p.productProductId === product.topParentAssemblyId);
    try {
      await this.orderService.markProductAsCorrected(this.order.orderSegmentId, top.productProductId).toPromise();
      top.reviewStatus = OrderSegmentProductReviewStatus.Corrected;
    } catch (_) { } finally {
      this.loading = false;
    }
  }
  private getMaterialBreakdown(product: Product, quantity: number): PricingBreakdownItem {
    // Material
    const material = this.getProductMaterial(product);
    if (!material) return null;
    const subitems: PricingBreakdownSubitem[] = [];
    let blanksPerMaterialLot: number;
    if (product.partsPerMaterialOverride) {
      blanksPerMaterialLot = product.partsPerMaterial;
      subitems.push({
        name: 'Blanks Per Material Lot <b>(Overriden)</b>',
        value: blanksPerMaterialLot,
      });
    }
    else {
      // Lot volume
      const materialLotVolume = Product.getVolume(product, material);
      // We're trusting that the user-input formula returns a cubed unit here
      const volumeUnit = `<b>${material?.materialType?.materialTypeDimensions?.[0]?.dimensionUnit?.abbreviation + '³'}</b>`;

      let preppedFormula = '';
      if (material.materialType.volumeFormula) {
        preppedFormula = material.materialType.volumeFormula
          .replace(/\^2/g, '²')
          .replace(/\*/g, '×')
          .replace(/\//g, '÷')
          .replace(/([^\s]+?)([×÷\+])/g, '$1 $2')
          .replace(/([×÷\+])([^\s]+?)/g, '$1 $2')
          .replace(/([×÷²\+])/g, '<b>$1</b>')
        ;
      }

      let materialLotVolumeFormula = preppedFormula;
      for (const mtd of material?.materialType?.materialTypeDimensions) {
        const matDimension = (material.materialDimensions || []).find(
          md => md.materialTypeDimensionId === mtd.materialTypeDimensionId
        );
        const value = matDimension?.value ?? 0;
        materialLotVolumeFormula = materialLotVolumeFormula.replace(new RegExp(mtd.dimensionType.label, 'i'), 
          doAltText(value.toFixed(3), `Raw Material ${mtd.dimensionType.label}`)
        )
      }
      subitems.push({
        name: 'Material Lot Volume',
        calculation: materialLotVolumeFormula,
        value: materialLotVolume,
        displayValue: `${materialLotVolume.toFixed(3)}${volumeUnit}`
      });
      // Blank volume
      const blankVolume = Product.getBlankVolume(product, material);
      let blankVolumeFormula = preppedFormula;
      for (const mtd of material?.materialType?.materialTypeDimensions) {
        const blankDimension = (product.blankDimensions || []).find(
          md => md.materialTypeDimensionId === mtd.materialTypeDimensionId
        );
        blankVolumeFormula = blankVolumeFormula.replace(
          new RegExp(mtd.dimensionType.label, 'i'),
          doAltText(blankDimension?.value.toFixed(3) ?? '0.000', `Blank ${mtd.dimensionType.label}`)
          )
      }
      subitems.push({
        name: 'Blank Volume',
        calculation: blankVolumeFormula,
        value: blankVolume,
        displayValue: `${blankVolume.toFixed(3)}${volumeUnit}`
      });
      // Blanks per lot
      blanksPerMaterialLot = Math.floor(materialLotVolume / blankVolume);
      const isInfinity = !isFinite(blanksPerMaterialLot)
      if (isInfinity) blanksPerMaterialLot = 0;
      subitems.push({
        name: 'Blanks Per Material Lot',
        calculation: `<b>⌋</b>${doAltText(`${materialLotVolume.toFixed(3)}${volumeUnit}`, 'Material Lot Volume')} <b>÷</b> ${doAltText(`${blankVolume.toFixed(3)}${volumeUnit}`, 'Blank Volume')}<b>⌊</b> <b>=</b> <b>⌋</b>${(materialLotVolume / blankVolume).toFixed(3)}<b>⌊</b>`,
        value: blanksPerMaterialLot,
        displayValue: isInfinity ? 'Division by zero (treated as 0)' : blanksPerMaterialLot.toString()
      });
    }
    subitems.push({
      name: 'Material cost calculation type',
      displayValue: product.materialCostIsLotBased ? 'Lot-based (full price of all lots used)' : 'Amortized (proportional to usage)'
    });
    const lotCost = product.materialLotCost ?? 0;
    const lotCostDisplay = `$${lotCost.toFixed(2)}`;
    subitems.push({
      name: 'Material Lot Cost',
      value: lotCost,
      displayValue: lotCostDisplay
    });
    let preMarkupMaterialCost: number;
    if (product.materialCostIsLotBased) {
      const lotsNeeded = Math.ceil(quantity / blanksPerMaterialLot);
      subitems.push({
        name: `Lots needed for quantity of <b>${quantity}</b>`,
        calculation: `<b>⌈</b>${doAltText(quantity.toString(), 'Total Part Quantity')} <b>÷</b> ${doAltText(blanksPerMaterialLot.toString(), 'Blanks Per Material Lot')}<b>⌉</b> <b>=</b> <b>⌈</b>${(quantity / blanksPerMaterialLot).toFixed(3)}<b>⌉</b>`,
        value: lotsNeeded,
      });
      preMarkupMaterialCost = lotsNeeded * lotCost;
      subitems.push({
        id: 'PRE_MARKUP',
        name: `Base Material Cost`,
        calculation: `${doAltText(lotsNeeded.toString(), 'Lots Needed')} <b>×</b> ${doAltText(lotCostDisplay, 'Material Lot Cost')}`,
        value: preMarkupMaterialCost,
        displayValue: `$${preMarkupMaterialCost.toFixed(2)}`
      });
    } else {
      let matPricePerPart = lotCost / blanksPerMaterialLot;
      const isInfinity = !isFinite(matPricePerPart)
      if (isInfinity) matPricePerPart = 0;
      subitems.push({
        // name: product.partsPerMaterialOverride ? 'Blanks Per Material Lot <b>(Overriden)</b>' : 'Blanks Per Material Lot',
        name: 'Material Price Per Part',
        calculation: `${doAltText(lotCostDisplay, 'Material Lot Cost')} <b>÷</b> ${doAltText(blanksPerMaterialLot.toString(), 'Blanks Per Material Lot')}`,
        value: matPricePerPart,
        displayValue: isInfinity ? 'Division by zero (treated as $0)' : `$${matPricePerPart.toFixed(2)}`
      });
      preMarkupMaterialCost = matPricePerPart * quantity;
      subitems.push({
        id: 'PRE_MARKUP',
        name: `Base Material Cost`,
        calculation: `${doAltText('$' + matPricePerPart.toFixed(2), 'Material Price Per Part')} <b>×</b> ${doAltText(quantity.toString(), 'Total Quantity')}`,
        value: preMarkupMaterialCost,
        displayValue: `$${preMarkupMaterialCost.toFixed(2)}`
      });
    }
    // Final markup stuff
    subitems.push({
      id: 'MARKUP_PCT',
      name: 'Material Markup Rate',
      displayValue: `${product.materialMarkup ?? 0}%`
    });
    const additionalMarkup = preMarkupMaterialCost * (((product.materialMarkup || 0.0) / 100.0));
    subitems.push({
      id: 'MARKUP_AMT',
      name: 'Markup',
      calculation: `${doAltText('$' + preMarkupMaterialCost.toFixed(2), 'Base Material Cost')} <b>×</b> ${doAltText(`${product.materialMarkup ?? 0}%`, 'Material Markup Rate')}`,
      value: additionalMarkup,
      displayValue: `$${additionalMarkup.toFixed(2)}`
    });
    const totalMaterialPrice = preMarkupMaterialCost * (1 + ((product.materialMarkup || 0.0) / 100.0));
    subitems.push({
      name: `Total Material Price`,
      calculation: `${doAltText('$' + preMarkupMaterialCost.toFixed(2), 'Base Material Cost')} <b>+</b> ${doAltText(`$${additionalMarkup.toFixed(2)}`, 'Markup')}`,
      value: totalMaterialPrice,
      displayValue: `$${totalMaterialPrice.toFixed(2)}`
    });
    return {
      name: material.materialName,
      price: totalMaterialPrice,
      subitems
    }
  }

  public getPurchasedItemsBreakdown(product: Product, quantity: number) {
    if (!product.purchasedItems?.length)
      return null;

    const breakdown: PricingBreakdownItem[] = [];

    for (const item of product.purchasedItems) {
      const name = item.purchasedItem?.description ?? 'No Description'
      let calculation = '';
      const subitems: PricingBreakdownSubitem[] = [];

      // Define step names
      const base_price_id = 'BASE_PRICE';
      const item_quantity_mult_id = 'ITEM_QUANTITY_MULT';
      const recurring_id = 'RECURRING';
      const parent_quantity_mult_id = 'PARENT_QUANTITY_MULT';
      const markup_pct_id = 'MARKUP_PCT';
      const markup_calc_id = 'CALC_MARKUP';
      const apply_markup_id = 'APPLY_MARKUP';

      // Shorter step getter
      const getStep = (id: string) => subitems.find(i => i.id === id);

      // 1. Base price
      const costPer = item.costPer ?? 0; // actual calc

      subitems.push({
        id: base_price_id,
        name: 'Base quoted price',
        value: costPer,
        displayValue: `$${costPer.toFixed(2)}`,
      });

      // 2. Multiply by item quantity
      const itemQuantity = item.quantity ?? 0; // actual calc
      const perParentTotal = getStep(base_price_id).value * itemQuantity;

      calculation = doAltText(getStep(base_price_id).displayValue, 'Base quoted price');
      calculation += ' <b>&times;</b> ';
      calculation += doAltText(`${itemQuantity}`, 'Item quantity');

      subitems.push({
        id: item_quantity_mult_id,
        name: 'Multiply by item quantity',
        value: perParentTotal,
        displayValue: `$${perParentTotal.toFixed(2)}`,
        calculation,
      });

      // 3. Recurring check
      const recurring = item.isNonRecurring ? 0 : 1;

      subitems.push({
        id: recurring_id,
        name: 'Item is recurring?',
        value: recurring,
        displayValue: recurring === 1 ? 'Yes' : 'No',
      });

      // 4. Multiply by quantity
      const quantityMultiplier = getStep(recurring_id).value === 1 ? quantity : 1;
      const preMarkupTotal = getStep(item_quantity_mult_id).value * quantityMultiplier; // actual calc

      calculation = doAltText(getStep(base_price_id).displayValue, 'Base quoted price');
      calculation += ' <b>&times;</b> ';
      calculation += doAltText(`${quantityMultiplier}`, getStep(recurring_id).value === 1 ? 'Recurring, total quantity' : 'Non-recurring single quantity');

      subitems.push({
        id: parent_quantity_mult_id,
        name: 'Cost for all parts together',
        value: preMarkupTotal,
        displayValue: `$${preMarkupTotal.toFixed(2)}`,
        calculation,
      });

      // 5. Calculate markup
      const markupPercent = item.markupPercent ?? 0.0;
      const markupDollars = getStep(parent_quantity_mult_id).value * (markupPercent / 100.0); // actual calc

      calculation = doAltText(getStep(parent_quantity_mult_id).displayValue, 'Pre-markup total');
      calculation += ' <b>&times;</b> ';
      calculation += doAltText(`${markupPercent}%`, `Total markup`);

      subitems.push({
        id: markup_pct_id,
        name: 'Markup Rate',
        value: markupPercent,
        displayValue: `${markupPercent}%`,
        calculation,
      });

      subitems.push({
        id: markup_calc_id,
        name: 'Markup',
        value: markupDollars,
        displayValue: `$${markupDollars.toFixed(2)}`,
        calculation,
      });

      // 6. Add markup
      const totalPrice = getStep(parent_quantity_mult_id).value + getStep(markup_calc_id).value; // actual calc

      calculation = doAltText(getStep(parent_quantity_mult_id).displayValue, 'Pre-markup total');
      calculation += ' <b>+</b> ';
      calculation += doAltText(getStep(markup_calc_id).displayValue, 'Total markup');

      subitems.push({
        id: apply_markup_id,
        name: 'Apply markup',
        value: totalPrice,
        displayValue: `$${totalPrice.toFixed(2)}`,
        calculation,
      });

      // Finalize price
      const price = getStep(apply_markup_id).value;

      breakdown.push({ name, price, subitems });
    }

    return breakdown;
  }

  public getOutsideProcessBreakdown(product: Product, quantity: number) {
    const outsourcingSteps = product.workflow.workflowSteps.filter(s => !s.isStandalone && s.outsourceMarkup).sort((a,b) => a.stepOrder - b.stepOrder);

    if (!outsourcingSteps.length)
      return null;

    const breakdown: PricingBreakdownItem[] = [];

    for (const step of outsourcingSteps) {
      const name = this.getStation(step).name;
      let priceSoFar = 0;
      let calculation = '';
      const subitems: PricingBreakdownSubitem[] = [];

      // Define step ids
      const run_price_id = 'RUN_PRICE';
      const run_per_part_id = 'RUN_PER_PART';
      const quantity_mult_id = 'QUANTITY_MULT';
      const markup_pct_id = 'MARKUP_PCT';
      const markup_calc_id = 'CALC_MARKUP';
      const apply_markup_id = 'APPLY_MARKUP';

      // Shorter step getter
      const getStep = (id: string) => subitems.find(i => i.id === id);

      // 1. Base run price
      priceSoFar = step.runPrice || 0; // actual calc
      subitems.push({
        id: run_price_id,
        name: 'Base run price',
        value: priceSoFar,
        displayValue: `$${priceSoFar.toFixed(2)}`,
      });

      // 2. Check if recurring
      subitems.push({
        id: run_per_part_id,
        name: 'Price is per part?',
        displayValue: step.runIsPerPart ? 'Yes' : 'No'
      });

      // 3. Quantity multiply
      const quantityMultiplier = step.runIsPerPart ? quantity : 1;
      priceSoFar *= quantityMultiplier; // actual calc

      calculation = doAltText(getStep(run_price_id).displayValue, 'Base quoted price');
      calculation += ' <b>&times;</b> ';
      calculation += doAltText(`${quantityMultiplier}`, `${step.runIsPerPart ? 'Per-part' : 'Non-per-part'} quantity`);

      subitems.push({
        id: quantity_mult_id,
        name: 'Multiply by quantity',
        value: priceSoFar,
        displayValue: `$${priceSoFar.toFixed(2)}`,
        calculation,
      });

      // 4. Calculate markup
      const markupPercent = step.outsourceMarkup ?? 0.0;
      const markupDollars = priceSoFar * (markupPercent / 100.0); // actual calc

      calculation = doAltText(getStep(quantity_mult_id).displayValue, 'Base cost');
      calculation += ' <b>&times;</b> ';
      calculation += doAltText(`${markupPercent}%`, `Total markup`);

      subitems.push({
        id: markup_pct_id,
        name: 'Markup Rate',
        value: markupPercent,
        displayValue: `${markupPercent}%`,
        calculation,
      });

      subitems.push({
        id: markup_calc_id,
        name: 'Markup',
        value: markupDollars,
        displayValue: `$${markupDollars.toFixed(2)}`,
        calculation,
      });

      // 5. Add markup
      priceSoFar += getStep(markup_calc_id).value; // actual calc

      calculation = doAltText(getStep(quantity_mult_id).displayValue, 'Base cost');
      calculation += ' <b>+</b> ';
      calculation += doAltText(getStep(markup_calc_id).displayValue, 'Total markup');

      subitems.push({
        id: apply_markup_id,
        name: 'Apply markup',
        value: priceSoFar,
        displayValue: `$${priceSoFar.toFixed(2)}`,
        calculation,
      });

      // Finalize price
      const price = priceSoFar;

      breakdown.push({name, price, subitems});
    }

    return breakdown;
  }

  public getLaborBreakdown(product: Product, quantity: number) {
    const laborSteps = product.workflow.workflowSteps.filter(s => !s.isStandalone && !s.outsourceMarkup).sort((a,b) => a.stepOrder - b.stepOrder);

    if (!laborSteps.length)
      return null;

    const breakdown: PricingBreakdownItem[] = [];

    for (const step of laborSteps) {
      if (step.paint)
        breakdown.push(this.getLaborPaintStepBreakdown(step, quantity));
      else
        breakdown.push(this.getLaborStepBreakdown(step, quantity));
    }

    return breakdown;
  }

  public getLaborPaintStepBreakdown(step: WorkflowStep, quantity: number): PricingBreakdownItem {
    const name = this.getStation(step).name;
    let calculation = '';
    const subitems: PricingBreakdownSubitem[] = [];

    // Define step ids
    const paint_area_id = 'PAINTED_AREA';
    const labor_cost_id = 'LABOR_COST';
    const paint_cost_id = 'PAINT_COST';
    const combined_cost_id = 'COMBINED_COST';
    const check_per_part_id = 'PER_PART';
    const quantity_mult_id = 'QUANTITY_MUL';
    const check_minimum_id = 'MINIMUM';

    // Shorter step getter
    const getStep = (id: string) => subitems.find(i => i.id === id);

    // 1. Calculate painted area
    const unitPaintedArea = step.paintedArea * step.paintCoats; // actual calc

    calculation = doAltText(`${step.paintedArea} in&sup2;`, 'Painted area');
    calculation += ' <b>&times;</b> ';
    calculation += doAltText(`${step.paintCoats}`, 'Paint coats');

    subitems.push({
      id: paint_area_id,
      name: 'Calculate painted area per unit',
      value: unitPaintedArea,
      displayValue: `${unitPaintedArea} in&sup2;`,
    });

    // 2. Calculate labor cost
    const runPrice = step.runPrice ?? 0;
    const unitLaborCost = runPrice * getStep(paint_area_id).value; // actual calc

    calculation = doAltText(`$${runPrice.toFixed(2)}`, 'Labor cost per in&sup2;');
    calculation += ' <b>&times;</b> ';
    calculation += doAltText(getStep(paint_area_id).displayValue, 'Unit painted area');

    subitems.push({
      id: labor_cost_id,
      name: 'Calculate labor cost per unit',
      value: unitLaborCost,
      displayValue: `$${unitLaborCost.toFixed(2)}`,
      calculation,
    });

    // 3. Calculate paint cost
    const paintCost = step.paintCost ?? 0;
    const unitPaintCost = paintCost * getStep(paint_area_id).value; // actual calc

    calculation = doAltText(`$${paintCost.toFixed(2)}`, 'Paint cost per in&sup2;');
    calculation += ' <b>&times;</b> ';
    calculation += doAltText(getStep(paint_area_id).displayValue, 'Unit painted area');

    subitems.push({
      id: paint_cost_id,
      name: 'Calculate paint cost per unit',
      value: unitPaintCost,
      displayValue: `$${unitPaintCost.toFixed(2)}`,
      calculation,
    });

    // 4. Calculate paint cost
    const unitTotalCost = getStep(labor_cost_id).value * getStep(paint_cost_id).value; // actual calc

    calculation = doAltText(getStep(labor_cost_id).displayValue, 'Labor cost per unit');
    calculation += ' <b>&times;</b> ';
    calculation += doAltText(getStep(paint_cost_id).displayValue, 'Paint cost per unit');

    subitems.push({
      id: combined_cost_id,
      name: 'Combine costs',
      value: unitTotalCost,
      displayValue: `$${unitTotalCost.toFixed(2)}`,
      calculation,
    });

    // 5. Check per-part
    subitems.push({
      id: check_per_part_id,
      name: 'Check if cost is per-part',
      displayValue: step.runIsPerPart ? 'Yes' : 'No',
    });

    // 6. Multiply by adjusted quantity
    const adjustedQuantity = step.runIsPerPart ? quantity : 1; // actual calc
    const prelimTotalCost = getStep(combined_cost_id).value * adjustedQuantity; // actual calc

    calculation = doAltText(getStep(combined_cost_id).displayValue, 'Combined cost');
    calculation += ' <b>&times;</b> ';
    calculation += doAltText(`${adjustedQuantity}`, step.runIsPerPart ? 'Per-part quantity' : 'Non-per-part quantity');

    subitems.push({
      id: quantity_mult_id,
      name: 'Calculate preliminary total cost',
      value: prelimTotalCost,
      displayValue: `$${prelimTotalCost.toFixed(2)}`,
      calculation,
    });

    // 7. Check minimum price
    const paintMinPrice = step.paintMinPrice ?? 0;
    const actualTotalCost = Math.max(prelimTotalCost, paintMinPrice); // actual calc

    calculation = 'Maximum of either ';
    calculation += doAltText(getStep(quantity_mult_id).displayValue, 'Preliminary total cost');
    calculation += ' or ';
    calculation += doAltText(`$${paintMinPrice.toFixed(2)}`, 'Minimum paint price');

    subitems.push({
      id: check_minimum_id,
      name: 'Replace with minimum price if necessary',
      value: actualTotalCost,
      displayValue: `${actualTotalCost.toFixed(2)}`,
      calculation,
    });

    // Finalize price
    const price = getStep(check_minimum_id).value;

    // Structure into breakdown item
    return { name, price, subitems };
  }

  public getLaborStepBreakdown(step: WorkflowStep, quantity: number): PricingBreakdownItem {
    const name = this.getStation(step).name;
    let calculation = '';
    const subitems: PricingBreakdownSubitem[] = [];

    // Define step ids
    const per_part_id = 'PER_PART';
    const per_run_cost_id = 'PER_RUN_COST';
    const per_run_qty_mult_id = 'PER_RUN_QTY';
    const has_setup_id = 'HAS_SETUP';
    const per_part_setup_id = 'PER_PART_SETUP';
    const setup_qty_mult_id = 'SETUP_QTY';
    const single_setup_id = 'SINGLE_SETUP'
    const setup_total_id = 'SETUP_TOTAL';
    const has_inspection_id = 'HAS_INSPECTION';
    const has_batch_inspection_id = 'HAS_BATCHED_INSPECTION';
    const unbatched_cost_id = 'UNBATCHED_COST';
    const unbatched_qty_mult_id = 'UNBATCHED_QTY';
    const batched_count_id = 'BATCHED_COUNT';
    const batch_cost_id = 'BATCH_COST';
    const batches_cost_id = 'BATCHES_COST';
    const has_first_inspection_id = 'HAS_FIRST';
    const first_inspection_cost_id = 'FIRST_INSPECT_COST';
    const total_inspection_cost_id = 'TOTAL_INSPECTION';
    const grand_total_id = 'GRAND_TOTAL';

    // Shorter step getter
    const getStep = (id: string) => subitems.find(i => i.id === id);

    // 1. Is run per-part?
    const perPartCoefficient = step.runIsPerPart ? 1 : 0; // actual calc

    subitems.push({
      id: per_part_id,
      name: 'Is the cost for each part?',
      value: perPartCoefficient,
      displayValue: perPartCoefficient === 1 ? 'Yes' : 'No',
    });

    // 2. Calculate cost per run
    const runTime = getStep(per_part_id).value === 1 ? (step.runTime ?? 0) / 60 : (step.runTime ?? 0);
    const runPrice = step.runPrice ?? 0;
    const costPerRun = runTime * runPrice; // actual calc

    calculation = doAltText(`${runTime.toFixed(3)} hours`, 'Run time');
    calculation += ' <b>&times;</b> ';
    calculation += doAltText(`$${runPrice.toFixed(2)}/hr`, 'Run price');

    subitems.push({
      id: per_run_cost_id,
      name: 'Calculate cost per run',
      value: costPerRun,
      displayValue: `$${costPerRun.toFixed(2)}`,
      calculation,
    });

    // 3. Multiply cost per run by adjusted quantity
    const costPerRunQty = getStep(per_part_id).value === 1 ? quantity : 1
    const fullRunCost = getStep(per_run_cost_id).value * costPerRunQty; // actual calc

    calculation = doAltText(`${getStep(per_run_cost_id).displayValue}`, 'Run price');
    calculation += ' <b>&times;</b> ';
    calculation += doAltText(`${costPerRunQty}`, getStep(per_part_id).value === 1 ? 'Per-part quantity' : 'Not per-part, so single run');

    subitems.push({
      id: per_run_qty_mult_id,
      name: '<b>Get total run cost</b>',
      value: fullRunCost,
      displayValue: `$${fullRunCost.toFixed(2)}`,
      calculation,
    });

    // 4. Part has setup
    const setupCoefficient = step.hasSetup ? 1 : 0; // actual calc

    subitems.push({
      id: has_setup_id,
      name: 'Part has setup',
      value: setupCoefficient,
      displayValue: setupCoefficient === 1 ? 'Yes' : 'No',
    });

    // 5. Calculate per-part setup cost
    const perPieceSetupTime = (step.perPieceSetupTime ?? 0) / 60;
    const perPartSetupCost = getStep(has_setup_id).value * getStep(per_part_id).value * perPieceSetupTime * runPrice; // actual calc

    calculation = doAltText(`${getStep(has_setup_id).value}`, '1 if run has setup');
    calculation += ' <b>&times;</b> ';
    calculation += doAltText(`${getStep(per_part_id).value}`, '1 if run is per-part');
    calculation += ' <b>&times;</b> ';
    calculation += doAltText(`${perPieceSetupTime.toFixed(3)} hours`, 'Per-piece setup time');
    calculation += ' <b>&times;</b> ';
    calculation += doAltText(`$${runPrice.toFixed(2)}/hr`, 'Run price');

    subitems.push({
      id: per_part_setup_id,
      name: 'Calculate the per-part setup costs',
      value: perPartSetupCost,
      displayValue: `$${perPartSetupCost.toFixed(2)}`,
      calculation,
    });

    // 6. Multiply per-part setup by quantity
    const allPartsSetupCost = getStep(per_part_setup_id).value * quantity; // actual calc

    calculation = doAltText(`${getStep(per_part_setup_id).displayValue}`, 'Per part setup cost');
    calculation += ' <b>&times;</b> ';
    calculation += doAltText(`${quantity}`, 'Quantity');

    subitems.push({
      id: setup_qty_mult_id,
      name: 'Get per-part setup cost for all parts',
      value: allPartsSetupCost,
      displayValue: `$${allPartsSetupCost.toFixed(2)}`,
      calculation,
    });

    // 7. Get single setup cost
    const setupTime = step.setupTime ?? 0;
    const singleSetupCost = getStep(has_setup_id).value * setupTime * runPrice; // actual calc

    calculation = doAltText(`${getStep(has_setup_id).value}`, '1 if part has setup');
    calculation += ' <b>&times;</b> ';
    calculation += doAltText(`${setupTime.toFixed(3)} hours`, 'Setup time');
    calculation += ' <b>&times;</b> ';
    calculation += doAltText(`$${runPrice.toFixed(2)}/hr`, 'Setup time');

    subitems.push({
      id: single_setup_id,
      name: 'Get per-part setup cost for all parts',
      value: singleSetupCost,
      displayValue: `$${singleSetupCost.toFixed(2)}`,
      calculation,
    });

    // 8. Get total setup cost
    const totalSetup = getStep(setup_qty_mult_id).value + getStep(single_setup_id).value; // actual calc

    calculation = doAltText(`${getStep(setup_qty_mult_id).displayValue}`, 'Per-part setup total');
    calculation += ' <b>+</b> ';
    calculation += doAltText(`${getStep(single_setup_id).displayValue}`, 'Single setup total');

    subitems.push({
      id: setup_total_id,
      name: '<b>Get total setup costs</b>',
      value: totalSetup,
      displayValue: `$${totalSetup.toFixed(2)}`,
      calculation,
    });

    // 9. Check for inspection
    const hasInspection = step.hasInspection ? 1 : 0; // actual calc

    subitems.push({
      id: has_inspection_id,
      name: 'Step has inspection?',
      value: hasInspection,
      displayValue: hasInspection === 1 ? 'Yes' : 'No',
    });

    // 10. Is inspection batched?
    const isBatched = step.inspectionIsBatched ? 1 : 0; // actual calc

    subitems.push({
      id: has_batch_inspection_id,
      name: 'Step\'s inspection is batched?',
      value: isBatched,
      displayValue: isBatched === 1 ? 'Yes' : 'No',
    });

    // 11. Get unbatched inspection cost
    const inspectionTime = (step.inspectionTime ?? 0) / 60;
    const inspectionRate = step.inspectionRate ?? 0;
    const reverseBatchInspectionCoefficient = getStep(has_batch_inspection_id).value === 1 ? 0 : 1;
    const unbatchedCostPerPart = getStep(has_inspection_id).value * reverseBatchInspectionCoefficient * inspectionTime * inspectionRate; // actual calc

    calculation = doAltText(`${getStep(has_inspection_id).value}`, '1 if part has inspection');
    calculation += ' <b>&times;</b> ';
    calculation += doAltText(`${reverseBatchInspectionCoefficient}`, '1 if inspection is not batched');
    calculation += ' <b>&times;</b> ';
    calculation += doAltText(`${inspectionTime.toFixed(3)} hours`, 'Inspection time');
    calculation += ' <b>&times;</b> ';
    calculation += doAltText(`$${inspectionRate.toFixed(2)}/hr`, 'Inspection rate');

    subitems.push({
      id: unbatched_cost_id,
      name: 'Get per-part cost for unbatched inspection',
      value: unbatchedCostPerPart,
      displayValue: `$${unbatchedCostPerPart.toFixed(2)}`,
      calculation,
    });

    // 12. Get unbatched inspection cost
    const totalUnbatchedInspectionCost = getStep(unbatched_cost_id).value * quantity; // actual calc

    calculation = doAltText(`${getStep(unbatched_cost_id).displayValue}`, 'Unbatched inspection per-part cost');
    calculation += ' <b>&times;</b> ';
    calculation += doAltText(`${quantity}`, 'Quantity');

    subitems.push({
      id: unbatched_qty_mult_id,
      name: 'Get total cost for all unbatched inspections',
      value: totalUnbatchedInspectionCost,
      displayValue: `$${totalUnbatchedInspectionCost.toFixed(2)}`,
      calculation,
    });

    // 13. Get inspection batch count
    const inspectionBatchSize = step.inspectionBatchSize || 1;
    const inspectionBatchCount = Math.ceil(quantity / inspectionBatchSize); // actual calc

    calculation = doAltText(`${quantity}`, 'Quantity');
    calculation += ' <b>&divide;</b> ';
    calculation += doAltText(`${inspectionBatchSize}`, 'Inspection batch size');
    calculation += ' (rounded up)';

    subitems.push({
      id: batched_count_id,
      name: 'Get number of batches for batched inspection',
      value: inspectionBatchCount,
      displayValue: `${inspectionBatchCount}`,
      calculation,
    });

    // 14. Get inspection cost per batch
    const inspectionCostPerBatch = inspectionTime * inspectionRate; // actual calc

    calculation = doAltText(`${inspectionTime.toFixed(3)} hours`, 'Inspection time');
    calculation += ' <b>&times;</b> ';
    calculation += doAltText(`$${inspectionRate.toFixed(2)}/hr`, 'Inspection cost');

    subitems.push({
      id: batch_cost_id,
      name: 'Get cost per inspection batch',
      value: inspectionCostPerBatch,
      displayValue: `$${inspectionCostPerBatch.toFixed(2)}`,
      calculation,
    });

    // 15. Get batched inspection cost overall
    const inspectionBatchesCost = getStep(has_inspection_id).value * getStep(has_batch_inspection_id).value * getStep(batched_count_id).value * getStep(batch_cost_id).value; // actual calc

    calculation = doAltText(`${getStep(has_inspection_id).value}`, '1 if step has inspection');
    calculation += ' <b>&times;</b> ';
    calculation += doAltText(`${getStep(has_batch_inspection_id).value}`, '1 if step has batched inspection');
    calculation += ' <b>&times;</b> ';
    calculation += doAltText(`${getStep(batched_count_id).value} batches`, 'Batch count');
    calculation += ' <b>&times;</b> ';
    calculation += doAltText(`${getStep(batch_cost_id).displayValue} per batch`, 'Per-batch cost');

    subitems.push({
      id: batches_cost_id,
      name: 'Get cost for inspecting all batches',
      value: inspectionBatchesCost,
      displayValue: `$${inspectionBatchesCost.toFixed(2)}`,
      calculation,
    });

    // 16. Check first part inspection
    const firstPartInspectionCoefficient = step.hasFirstPartInspection ? 1 : 0; // actual calc

    subitems.push({
      id: has_first_inspection_id,
      name: 'Part has first part inspection',
      value: firstPartInspectionCoefficient,
      displayValue: firstPartInspectionCoefficient === 1 ? 'Yes' : 'No',
    });

    // 17. Get first part inspection cost
    const firstPartInspectionTime = (step.firstPartInspectionTime ?? 0) / 60;
    const firstPartInspectionRate = step.firstPartInspectionRate ?? 0;
    const firstPartInspectionCost = getStep(has_first_inspection_id).value * firstPartInspectionTime * firstPartInspectionRate; // actual calc

    calculation = doAltText(`${getStep(has_first_inspection_id).value}`, '1 if step has first part inspection');
    calculation += ' <b>&times;</b> ';
    calculation += doAltText(`${firstPartInspectionTime.toFixed(3)} hours`, 'First part inspection hours');
    calculation += ' <b>&times;</b> ';
    calculation += doAltText(`$${firstPartInspectionRate.toFixed(2)}/hr`, 'First part inspection rate');

    subitems.push({
      id: first_inspection_cost_id,
      name: 'Get total cost for first part inspection',
      value: firstPartInspectionCost,
      displayValue: `$${firstPartInspectionCost.toFixed(2)}`,
      calculation,
    });

    // 18. Get total inspection cost
    const totalInspectionCost = getStep(unbatched_qty_mult_id).value + getStep(batches_cost_id).value + getStep(first_inspection_cost_id).value; // actual calc

    calculation = doAltText(`${getStep(unbatched_qty_mult_id).displayValue}`, 'Unbatched inspection total');
    calculation += ' <b>+</b> ';
    calculation += doAltText(`${getStep(batches_cost_id).displayValue}`, 'Batched inspection total');
    calculation += ' <b>+</b> ';
    calculation += doAltText(`${getStep(first_inspection_cost_id).displayValue}`, 'First part inspection total');

    subitems.push({
      id: total_inspection_cost_id,
      name: '<b>Get total inspection cost</b>',
      value: totalInspectionCost,
      displayValue: `$${totalInspectionCost.toFixed(2)}`,
      calculation,
    });

    // 19. Get grand combined total
    const grandCombinedTotal = getStep(per_run_qty_mult_id).value + getStep(setup_total_id).value + getStep(total_inspection_cost_id).value; // actual calc

    calculation = doAltText(`${getStep(per_run_qty_mult_id).displayValue}`, 'Run total');
    calculation += ' <b>+</b> ';
    calculation += doAltText(`${getStep(setup_total_id).displayValue}`, 'Setup total');
    calculation += ' <b>+</b> ';
    calculation += doAltText(`${getStep(total_inspection_cost_id).displayValue}`, 'Inspection total');

    subitems.push({
      id: grand_total_id,
      name: 'Combine run, setup, and inspection costs',
      value: grandCombinedTotal,
      displayValue: `$${grandCombinedTotal.toFixed(2)}`,
      calculation,
    });

    // Finalize price
    const price = getStep(grand_total_id).value;

    // Structure into breakdown item
    return { name, price, subitems };
  }

  public getTotalQuantity(product: Product, quantity: number) {
    let currentParent = product;
    let totalQuantity = quantity;
    let allProducts = this.allProductsFlat();

    while (currentParent.parentAssemblyId !== null) {
      totalQuantity *= currentParent.quantityAsChild ?? 0;
      currentParent = allProducts.find(p => p.productId === currentParent.parentAssemblyId);
    }

    return totalQuantity;
  }

  public getPriceBreakdown(product: Product, quantity: number) {

    const totalQuantity = this.getTotalQuantity(product, quantity);

    return <PricingBreakdown>{
      material: this.getMaterialBreakdown(product, totalQuantity),
      purchasedItems: this.getPurchasedItemsBreakdown(product, totalQuantity),
      process: this.getOutsideProcessBreakdown(product, totalQuantity),
      labor: this.getLaborBreakdown(product, totalQuantity),
    }
  }

  public movedProductIds = new Set<string>();
  public addMovedProduct(productId: string) {
    this.movedProductIds.add(productId);
  }
  public removeMovedProduct(productId: string) {
    this.movedProductIds.delete(productId);
  }

  public generateSubItemNavigationId(productId: string, type: 'product' | 'material' | 'workflow' | 'hardware', subItemId?: string) {
    if (type === 'product') {
      return `${productId}`;
    } else if (type === 'material') {
      return `${productId}--material`;
    } else if (type === 'workflow') {
      if (!subItemId) return `${productId}--workflow`;
      return `${productId}--workflow--${subItemId}`;
    } else if (type === 'hardware') {
      if (!subItemId) return `${productId}--hardware`;
      return `${productId}--hardware--${subItemId}`;
    }
  }

  public focusedMicroticketId: string = null;
  public focusedPurchasedItemId: string = null;
  public navigateToSubItem(navigationString: string) {
    const [left, right, subItemId] = navigationString.split('--');
    if (left === 'microticket') {
      const microticketId = right;
      if (!microticketId) console.warn('Attempted to navigate to microticket with invalid ID')
      else {
        // Microtickets tab
        this.focusedMicroticketId = microticketId;
        this.mainTab = 5;
        this.selectedProductId = null;
      }
      return;
    }
    const productId = left;
    if (!productId) {
      console.warn(`Invalid navigation string ${navigationString}`);
      return;
    }
    this.mainTab = 0;
    this.selectedProductId = productId;
    if (!right) this.productTab = 0;
    else if (right === 'material') this.productTab = 2;
    else if (right === 'workflow') {
      this.selectedWorkflowStepId = subItemId ?? null;
      this.productTab = 3;
    } else if (right === 'hardware') {
      this.selectedPurchasedItemId = subItemId ?? null;
      this.productTab = 4;
    }
  }
  
  public get nameForMicrotickets(): string {
    if (!this.order) return '';
    else if (this.order.discriminator === 'RFQ' || this.order.discriminator === 'Quote') return this.order.orderNumber;
    else return this.order.quoteNumber ?? this.order.orderNumber;
  }

  public get microticketFilterKey(): string {
    if (this.order.discriminator === 'RFQ' && this.order.status === 1) return 'Estimating';
    if (this.order.discriminator === 'RFQ' || this.order.discriminator === 'Quote') return 'Sales';
    if (this.order.discriminator === 'Estimate' || this.order.discriminator === 'RMAEstimate') return 'Estimating';
    else return 'Other';
  }

  public sharedMicroticketSubject = new ReplaySubject<MicroTicket[]>(1);
  public mainTabBodyElement: HTMLDivElement;
  private lastMainTabScroll: number;

  public partListPageIndex = 0;

}