import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap, catchError } from 'rxjs/operators';
import { MessageService } from '../../common/services/message.service';
import { httpService } from '../../common/services/http.service';
import { ErrorHandlerService } from '../../common/services/errorHandler.service';
import { MachineAssignment } from '../resources/machine-assignment';
import { UtilityService } from '../../common/services/utility.service';
import { MessageType } from '../../common/resources/message';
import { WorkOrder } from '../resources/work-order';
import { WorkOrderShippingAssignment } from './work-order.service';
import { ProgrammingTicket, ProgrammingTicketDocument } from '../resources/programming-ticket';
import { SearchResult } from '../../common/resources/search-result';
import { PlanningTicket } from '../resources/planning-ticket';
import { User } from '../../common/resources/user';
import { VirtualDocument } from '../../common/resources/virtual-document';
import { Product } from '../../order/resources/product';

@Injectable({
  providedIn: 'root',
})
export class PlanningService extends httpService {
  private apiBase: string = 'api/machineassignment';
  private apiUrl: string;

  constructor(errorHandler: ErrorHandlerService, private messages: MessageService, private http: HttpClient, @Inject('BASE_URL') private baseUrl: string) {
    super(errorHandler, messages);
    this.serviceName = "Planning";

    this.apiUrl = this.baseUrl + this.apiBase;
  }

  public getDetail(id: string): Observable<PlanningTicket> {
    return this.http.get<PlanningTicket>(this.baseUrl + 'api/planningTicket/' + id).pipe(
      catchError(this.handleError<any>("Get Planning Ticket", null))
    );
  }

  public search(overrideView?: boolean, searchString?: string, page?: number, sortBy?: string, sortDirection?: string): Observable<SearchResult<PlanningTicket>> {
    return this.http.get<SearchResult<PlanningTicket>>(this.baseUrl + 'api/planningTicket/search', { params: { searchText: searchString, forAllUsers: ((overrideView || false) ? "true" : "false"), pageIndex: (page || 0).toString(), orderBy: sortBy || "name", direction: sortDirection || "asc" } }).pipe(
      catchError(this.handleError<any>("Get Planning Tickets Search Results", null))
    );
  }

  public assign(ticketId: string, toUser: User): Observable<void> {
    return this.http.get<void>(this.baseUrl + `api/planningTicket/assign/${ticketId}?userId=${toUser.userId}`).pipe(
      tap(_ => this.messages.add("Work Order Service: Assign Work Order", MessageType.SUCCESS, true)),
      catchError(this.handleError<void>("Assign Work Order", null))
    );
  }

  public saveProgrammingTicket(ticket: ProgrammingTicket): Observable<ProgrammingTicket> {
    return this.http.post<ProgrammingTicket>(`${this.baseUrl}api/ProgrammingTicket`, ticket).pipe(
      tap(_ => this.messages.add("Planning Service: Programming Ticket Saved Successfully", MessageType.SUCCESS, true)),
      catchError(this.handleError<ProgrammingTicket>("Update Programming Ticket", ticket))
    );
  }

  public getProgrammingTicket(programmingTicketId: string): Observable<ProgrammingTicket> {
    return this.http.get<ProgrammingTicket>(this.baseUrl + 'api/programmingticket/' + programmingTicketId).pipe(
      catchError(this.handleError<any>("Get Programming Ticket Detail", null))
    );
  }

  public addProgrammingDocuments(ticket: ProgrammingTicket, documents: VirtualDocument[]): Observable<ProgrammingTicketDocument[]> {
    return this.http.post<ProgrammingTicketDocument[]>(`${this.baseUrl}api/ProgrammingTicket/adddocuments?ticketId=` + ticket.programmingTicketId, documents.map(d => d.documentId)).pipe(
      tap(_ => this.messages.add("Programming Service: Documents Updated", MessageType.SUCCESS, true)),
      catchError(this.handleError<any>("Add Documents to Programming Ticket", null))
    );
  }

  public removeProgrammingDocument(ticket: ProgrammingTicket, document: VirtualDocument): Observable<any> {
    return this.http.get<any>(`${this.baseUrl}api/ProgrammingTicket/removedocument?ticketId=` + ticket.programmingTicketId + '&documentId=' + document.documentId).pipe(
      tap(_ => this.messages.add("Programming Service: Document Removed Successfully", MessageType.SUCCESS, true)),
      catchError(this.handleError<any>("Remove Document from Programming Ticket", null))
    );
  }

  public completeProgrammingTicket(ticket: ProgrammingTicket) {
    return this.http.post<void>(`${this.baseUrl}api/ProgrammingTicket/complete/${ticket.programmingTicketId}`, null).pipe(
      tap(_ => this.messages.add("Planning Service: Programming Ticket Fulfilled Successfully", MessageType.SUCCESS, true)),
      catchError(this.handleError<ProgrammingTicket>("Update Programming Ticket", ticket))
    );
  }

  public createProgrammingTicket(wo: WorkOrder, data: {
    assignedProgrammer?: string; 
    programmingDueDate?: Date; 
    highlightedProgrammingSteps?: string[];
  }) {
    return this.http.post<ProgrammingTicket>(`${this.baseUrl}api/ProgrammingTicket/createTicket/${wo.workOrderId}`, data).pipe(
      tap(_ => this.messages.add("Planning Service: Programming Ticket Created Successfully", MessageType.SUCCESS, true)),
      catchError(this.handleError<ProgrammingTicket>("Create Programming Ticket", null))
    );
  }

  public saveMachineAssignment(assignment: MachineAssignment): Observable<MachineAssignment> {
    if (assignment.assignmentId == UtilityService.emptyGuid) {
      //New Item
      return this.http.post<MachineAssignment>(this.apiUrl + '/new', assignment).pipe(
        tap(_ => this.messages.add("Planning Service: Machine Assignment Saved Successfully", MessageType.SUCCESS, true)),
        catchError(this.handleError<MachineAssignment>("Save New Machine Assignment", assignment))
      );
    }
    else {
      //Existing Item - we can just do a simple update here
      return this.http.post<MachineAssignment>(this.apiUrl, assignment).pipe(
        tap(_ => this.messages.add("Planning Service: Order Saved Successfully", MessageType.SUCCESS, true)),
        catchError(this.handleError<MachineAssignment>("Update Machine Assignment", assignment))
      );
    }
  }

  public saveWorkOrder(wo: WorkOrder): Observable<WorkOrder> {
    return this.http.post<WorkOrder>(`${this.baseUrl}api/workorder`, wo).pipe(
      tap(_ => this.messages.add("Planning Service: Work Order Saved Successfully", MessageType.SUCCESS, true)),
      catchError(this.handleError<WorkOrder>("Update Order", wo))
    );
  }

  public saveWorkOrderDetails(_wo: WorkOrder, assignments: MachineAssignment[], shippingAssignments: WorkOrderShippingAssignment[], product: Product): Observable<WorkOrder> {
    // Clone and clean up WO
    const wo: WorkOrder = JSON.parse(JSON.stringify(_wo));
    console.log(product);
    wo.product = null;
    //Clean up - we don't want to re-create these...
    assignments.forEach(a => {
      a.department = null;
      a.machine = null;
      a.operation = null;
      a.workOrder = null;
    });

    return this.http.post<WorkOrder>(`${this.baseUrl}api/workorder/updateWorkOrderDetails`, { workOrder: wo, machineAssignments: assignments, shippingAssignments, product }).pipe(
      tap(_ => this.messages.add("Planning Service: Work Order Details Saved Successfully", MessageType.SUCCESS, true)),
      catchError(this.handleError<WorkOrder>("Update Work Order", wo))
    );
  }

  public setWorkflowStepPlanningStatus(stepId: string, status: number): Observable<any> {
    return this.http.post<void>(this.baseUrl + `api/workflowstep/setPlanningStatus/${stepId}/${status}`, null).pipe(
      catchError(this.handleError<void>("Set Workflow Step Planning Status", undefined))
    );
  }
  
  public finishPlanning(wo: WorkOrder): Observable<WorkOrder> {
    return this.http.post<WorkOrder>(`${this.baseUrl}api/workorder/finishPlanning/${wo.workOrderId}`, null).pipe(
      tap(_ => this.messages.add("Planning Service: Planning Finished Successfully", MessageType.SUCCESS, true)),
      catchError(this.handleError<WorkOrder>("Update Work Order", wo))
    );
  }
}
