import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SalesNote } from '../../../resources/sales-note';
import { UserService } from '../../../../common/services/user.service';

@Component({
  selector: 'sales-note-card',
  templateUrl: './sales-note-card.component.html',
  styleUrls: ['./sales-note-card.component.less']
})
export class SalesNoteCardComponent implements OnInit {

  @Input() note: SalesNote;

  constructor(private userSvc: UserService) { }

  ngOnInit(): void {
  }

  public isOwn() {
    return this.userSvc.userData?.userId === this.note?.authorId;
  }

  @Output() edit = new EventEmitter<void>();
  @Output() delete = new EventEmitter<void>();

}
