import { User } from "../../common/resources/user";

export enum SalesNoteType {
    Note = 0,
    Event = 1
}

export interface SalesNote {
    salesNoteId: string,
    salesProcessId: string,
    authorId?: string,
    author?: User,
    date: Date,
    content: string,
    tags: string[]
    type: SalesNoteType
}
