import { Injectable, Inject, Output, EventEmitter, Directive } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { MessageService } from '../../common/services/message.service';
import { catchError, filter, map, shareReplay, tap } from 'rxjs/operators';
import { ErrorHandlerService } from '../../common/services/errorHandler.service'
import { SearchResult } from '../../common/resources/search-result';
import { httpService } from '../../common/services/http.service';
import { OutsideProcessSpecification, Station } from '../resources/station';
import { UtilityService } from '../../common/services/utility.service';
import { MessageType } from '../../common/resources/message';

@Directive()
@Injectable({
  providedIn: 'root',
})
export class StationService extends httpService {
  private apiBase: string = 'api/station';
  private apiUrl: string;
  public stationList: Station[] = null;
  public loaded: boolean = false;
  @Output() stationsLoaded: EventEmitter<boolean> = new EventEmitter<boolean>();
  public stations: Observable<Station[]>;

  constructor(errorHandler: ErrorHandlerService, private messages: MessageService, private http: HttpClient, @Inject('BASE_URL') private baseUrl: string) {
    super(errorHandler, messages);
    this.serviceName = "Station";

    this.apiUrl = this.baseUrl + this.apiBase;
    this.getStationList();
  }

  public getStationList(): void {
    this.stationsLoaded.emit(this.loaded = false);
    this.stations = this.search('').pipe(
      filter(r => !!r),
      map(r => r.results), shareReplay(1));
    this.stations.subscribe(r => {
      this.stationList = r;
      this.stationsLoaded.emit(this.loaded = true);
    });
  }

  public search(searchString?: string): Observable<SearchResult<Station>> {
    return this.http.get<SearchResult<Station>>(this.apiUrl + '/search', { params: { searchText: searchString, pageIndex: '0', pageSize: '1000', orderBy: "name", direction: "asc" } }).pipe(
      catchError(this.handleError<any>("Get Stations Search Results", null))
    );
  }

  public save(station: Station): Observable<Station> {
    if (station.stationId == UtilityService.emptyGuid) {
      return this.http.post<Station>(this.apiUrl + '/new', station).pipe(
        tap(_ => {
          this.messages.add("Station Service: Station Saved Successfully", MessageType.SUCCESS, true);
          this.getStationList();
        }),
        catchError(this.handleError<Station>("Save New Station", station))
      );
    }
    else {
      return this.http.post<Station>(this.apiUrl, station).pipe(
        tap(_ => {
          this.messages.add("Station Service: Station Saved Successfully", MessageType.SUCCESS, true);
          this.getStationList();
        }),
        catchError(this.handleError<Station>("Update Station", station))
      );
    }
  }

  public remove(stationId: string): Observable<any> {
    return this.http.delete<Station>(this.apiUrl + `/${stationId}`).pipe(
      tap(_ => {
        this.messages.add("Station Service: Station Removed Successfully", MessageType.SUCCESS, true);
        this.getStationList();
      }),
      catchError(this.handleError<Station>("Remove Station", null))
    );
  }

  public createSpec(stationId: string, name: string) {
    return this.http.post<OutsideProcessSpecification>(this.baseUrl + 'api/OutsideProcessSpecification/new', <OutsideProcessSpecification>{
      outsideProcessSpecificationId: UtilityService.emptyGuid,
      name,
      stationId
    }).pipe(
      tap(newSpec => {
        this.stationList = this.stationList.map((s) => {
          if (s.stationId === stationId) s.outsideProcessSpecifications = [...s.outsideProcessSpecifications, newSpec]
          return s;
        })
      }),
      catchError(this.handleError<OutsideProcessSpecification>("Add Outside Process Spec", null))
    );
  }

  public editSpec(spec: OutsideProcessSpecification) {
    return this.http.post<OutsideProcessSpecification>(this.baseUrl + 'api/OutsideProcessSpecification', spec)
      .pipe(
        tap(editedSpec => {
          this.stationList = this.stationList.map((s) => {
            if (s.stationId === editedSpec.stationId) {
              const idx = s.outsideProcessSpecifications.findIndex(s => s.outsideProcessSpecificationId === editedSpec.outsideProcessSpecificationId);
              if (idx !== -1) {
                s.outsideProcessSpecifications.splice(idx, 1, editedSpec);
                s.outsideProcessSpecifications = [...s.outsideProcessSpecifications];
              } 
            }
            return s;
          })
        }),
        catchError(this.handleError<OutsideProcessSpecification>("Edit Outside Process Spec", null))
      )
  }

  public deleteSpec(spec: OutsideProcessSpecification) {
    return this.http.delete<OutsideProcessSpecification>(this.baseUrl + 'api/OutsideProcessSpecification/' + spec.outsideProcessSpecificationId)
      .pipe(
        tap(() => {
          this.stationList = this.stationList.map((s) => {
            if (s.stationId === spec.stationId) {
              s.outsideProcessSpecifications = s.outsideProcessSpecifications.map(p => {
                if (p.outsideProcessSpecificationId === spec.outsideProcessSpecificationId) p.isDeleted = true;
                return p;
              })
            }
            return s;
          })
        }),
        catchError(this.handleError<OutsideProcessSpecification>("Delete Outside Process Spec", null))
      )
  }

}
