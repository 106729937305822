import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { Observable } from 'rxjs';
import { NavigationService } from '../../../common/services/navigation.service';
import { ShippingService } from '../../../shipping/services/shipping.service';
import { Building } from '../../resources/building';
import { ToolCribTicket } from '../../resources/tool-crib-ticket';
import { FloorService } from '../../services/floor.service';

const BUILDING_ID_KEY = 'tool-crib-building';

@Component({
  selector: 'tool-crib-ticket-list',
  templateUrl: './tool-crib-ticket-list.component.html',
  styleUrls: ['./tool-crib-ticket-list.component.less']
})
export class ToolCribTicketListComponent implements OnInit {

  $buildings: Observable<Building[]>
  currentBuilding: Building;

  @ViewChild('sidenav', { static: true }) sidenav: MatSidenav;
  public todayTickets: ToolCribTicket[];
  public selectedTicket: ToolCribTicket;

  constructor(
    navService: NavigationService,
    private shippingService: ShippingService,
    private floorService: FloorService
  ) {
    navService.setPageTitle('Floor');
    navService.pushBreadcrumb("Tool Crib");
    this.$buildings = shippingService.$buildings;
  }

  public selectBuilding(b: Building) {
    if (!this.currentBuilding || this.currentBuilding.buildingId != b.buildingId) {
      this.todayTickets = null;
      this.floorService.getToolCribTodayTickets(b.buildingId).subscribe(tickets => { this.todayTickets = tickets }); 
    }
    this.currentBuilding = b;
    localStorage.setItem(BUILDING_ID_KEY, b.buildingId);
  }

  public selectTicket(ticket: ToolCribTicket) {
    this.selectedTicket = ticket;
    this.sidenav.open();
  }

  public isOverdue(ticket: ToolCribTicket) {
    return new Date(ticket.machineAssignment.scheduledStart) <= new Date();
  }

  public onClose() {
    this.selectedTicket = null;
    this.sidenav.close();
  }

  public onDone() {
    this.currentBuilding = null;
    this.sidenav.close();
    this.todayTickets = null;
    this.floorService.getToolCribTodayTickets(this.currentBuilding.buildingId).subscribe(tickets => { this.todayTickets = tickets }); 
  }

  ngOnInit() {
    const lastBuildingUsed = localStorage.getItem(BUILDING_ID_KEY);
    if (lastBuildingUsed) {
      this.floorService.getBuilding(lastBuildingUsed).subscribe(b => {
        if (b) this.selectBuilding(b);
      })
    }
  }

  public isToday(dateParam: Date) {
    const date = new Date(dateParam);
    const now = new Date();
    return date.getFullYear() === now.getFullYear() &&
    date.getMonth() === now.getMonth() &&
    date.getDate() === now.getDate();
  }

}
