<div>
  <a *ngIf="!showEditor" class="position-fixed fixed-bottom btn btn-badge bg-danger has-icon" title="Close" (click)="close()"><i class="fas fa-times"></i></a>

  <loading *ngIf="saving" content="Saving..."></loading>

  <div class="container-fluid pb-4">
    <div class="row">
      <mat-sidenav-container>
        <mat-sidenav disableClose (closedStart)="closeSideNav()" #insetnav mode="over" position="end">
          <material-type-editor *ngIf="showEditor=='type'" [sidenav]="insetnav"></material-type-editor>
          <material-spec-editor *ngIf="showEditor=='spec'" [sidenav]="insetnav"></material-spec-editor>
          <material-group-editor *ngIf="showEditor=='group'" [sidenav]="insetnav"></material-group-editor>
          <material-hardness-editor *ngIf="showEditor=='hardness'" [sidenav]="insetnav"></material-hardness-editor>
          <material-alloy-editor *ngIf="showEditor=='alloy'" [sidenav]="insetnav"></material-alloy-editor>
        </mat-sidenav>
        <mat-sidenav-content>
          <div class="p-0 m-3 row">
            <div class="col-4">
              <material-list (selected)="select($event)"></material-list>
              <div class="mt-2">
                <div class="float-left">
                  <div class="btn-group">
                    <button type="button" class="btn border dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                      Materials
                    </button>
                    <div class="dropdown-menu">
                      <span class="dropdown-item active" href="#">Materials</span>
                      <div class="dropdown-divider"></div>
                      <a class="dropdown-item cursor-pointer" (click)="addMaterialType()">Material Forms</a>
                      <a class="dropdown-item cursor-pointer" (click)="addMaterialGroup()">Material Groups</a>
                      <a class="dropdown-item cursor-pointer" (click)="addMaterialHardness()">Hardnesses</a>
                      <a class="dropdown-item cursor-pointer" (click)="addMaterialAlloy()">Material Alloys</a>
                      <a class="dropdown-item cursor-pointer" (click)="addMaterialSpec()">Material Specifications</a>
                    </div>
                  </div>
                </div>
                <div class="text-right">
                  <button class="btn btn-success" (click)="addMaterial()">
                    <i class="fas fa-fw la-lg fa-plus"></i>
                    Add Material
                  </button>
                </div>
              </div>
            </div>
            <div class="col-8">
              <div *ngIf="!selectedMaterial">
                <p class="text-muted text-center font-italic p-5">-- Select a Material to View its Details --</p>
              </div>
              <div *ngIf="selectedMaterial">
                <mat-tab-group>
                  <mat-tab label="Material Details">
                    <div class="row">
                      <div class="col">
                        <div class="form-group">
                          <label>Material Description</label>
                          <div class="lead">{{generatedName}}</div>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col">
                        <div class="form-group">
                          <label>Material Form</label>
                          <material-type-select (addValue)="addMaterialType()" (valueChange)="setType($event)"
                            [value]="selectedMaterial.materialType" [editable]="true"></material-type-select>
                        </div>
                        <div class="form-group">
                          <label>Condition/Hardness/Temper</label>
                          <material-hardness-select (addValue)="addMaterialHardness()" (valueChange)="setHardness($event)"
                            [value]="selectedMaterial.materialHardness" [editable]="true"></material-hardness-select>
                        </div>
                        <div class="form-group">
                          <label>
                            <span>Casting/Extrusion/Forging Number</span>
                          </label>
                          <div class="input-group input-group-sm">
                            <input [(ngModel)]="selectedMaterial.castingNumber" placeholder="Casting/Extrusion/Forging Number" type="text"
                              class="form-control form-control-sm" appStaticEdit [editable]="true" />
                          </div>
                        </div>
                        <div class="form-group">
                          <label>
                            <span>Inventory Units</span>
                          </label>
                          <mat-select [(ngModel)]="selectedMaterial.units">
                            SQIN,
                            CUIN,
                            L_FT,
                            LB,
                            EA
                            <mat-option [value]="0">in<sup>2</sup></mat-option>
                            <mat-option [value]="1">in<sup>3</sup></mat-option>
                            <mat-option [value]="2">l-ft</mat-option>
                            <mat-option [value]="3">lb</mat-option>
                            <mat-option [value]="4">ea</mat-option>
                          </mat-select>
                        </div>
                        <div class="form-group">
                          <label>
                            <span>Default Lead Time</span>
                          </label>
                          <div class="input-group input-group-sm">
                            <input min="0.0" step="1.0" [(ngModel)]="selectedMaterial.defaultLeadTimeDays" placeholder="Lead Time"
                              type="number" class="form-control form-control-sm" appStaticEdit [editable]="true" />
                            <div class="input-group-append">
                              <span class="input-group-text">
                                Days
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col">
                        <div class="form-group">
                          <label>Material Group</label>
                          <material-group-select (addGroup)="addMaterialGroup()" (materialGroupChange)="setGroup($event)"
                            [materialGroup]="selectedMaterial.materialGroup" [editable]="true"></material-group-select>
                        </div>
                        <div class="form-group">
                          <label>Material Alloy</label>
                          <material-alloy-select (addAlloy)="addMaterialAlloy()" (materialAlloyChange)="setAlloy($event)"
                            [materialAlloy]="selectedMaterial.materialAlloy" [editable]="true"></material-alloy-select>
                        </div>
                        <div class="form-group">
                          <label>Material Specification</label>
                          <material-specification-select (newSpec)="newSpec($event)" (materialSpecificationChange)="setSpecs($event)"
                            [selectedMaterialMaterialSpecifications]="selectedMaterial.materialMaterialSpecifications"
                            [editable]="true"></material-specification-select>
                        </div>
                        <div class="form-group">
                          <label>Price History</label>
                          <price-history-chart [materialId]="selectedMaterial.materialId"
                            *ngIf="selectedMaterial.materialId !== '00000000-0000-0000-0000-000000000000'"></price-history-chart>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-6">
                        <div class="form-group"><label>Dimensions</label></div>
                        <div class="row">
                          <div class="alert alert-info" *ngIf="selectedMaterial.materialDimensions.length === 0">This material's form does
                            not have any specified dimensions.</div>
                          <div class="col-12 mb-2 mx-0 row" *ngFor="let dimension of selectedMaterial.materialDimensions">
                            <div class="col-2 font-weight-bold text-center">{{ dimension.materialTypeDimension.dimensionType.label }}</div>
                            <div class="col flex-grow-1 input-group input-group-sm">
                              <input min="0.0" step="0.01" [(ngModel)]="dimension.value"
                                [placeholder]="dimension.materialTypeDimension.dimensionType.label" type="number"
                                class="form-control form-control-sm" appStaticEdit [editable]="true" />
                              <div class="input-group-append">
                                <span class="input-group-text">
                                  {{ dimension.materialTypeDimension.dimensionUnit.abbreviation }}
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-6" *ngIf="selectedMaterial.materialType">
                        <div class="form-group">
                          <label>Volume formula</label>
                          <div class="text-muted font-italic">{{selectedMaterial.materialType.volumeFormula}}</div>
                        </div>
                        <div class="form-group">
                          <label>
                            Density
                          </label>
                          <div class="input-group input-group-sm">
                            <input min="0.0" step="0.01" [(ngModel)]="selectedMaterial.density" placeholder="Density" type="number"
                              class="form-control form-control-sm" appStaticEdit [editable]="true" />
                            <div class="input-group-append">
                              <span class="input-group-text">
                                kg / in<sup>3</sup>
                              </span>
                            </div>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-6">
                            <div class="form-group">
                              <label>Material Volume</label>
                              <div class="text-muted" *ngIf="selectedVolume !== null">{{ selectedVolume | number: '1.3-3'
                                }}<b>in<sup>3</sup></b></div>
                              <div class="text-muted small font-italic" *ngIf="selectedVolume === null">No volume formula.</div>
                            </div>
                          </div>
                          <div class="col-6">
                            <div class="form-group">
                              <label>Raw Weight</label>
                              <div class="text-muted" *ngIf="selectedVolume !== null && selectedMaterial.density !== null">{{ selectedVolume
                                * selectedMaterial.density | number: '1.3-3' }}<b>kg</b></div>
                              <div class="text-muted small font-italic"
                                *ngIf="selectedVolume === null || selectedMaterial.density === null">No volume formula or density.</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="float-right mt-4 pt-4">
                      <button class="btn btn-danger mr-3" *ngIf="canRemove()" (click)="remove()">
                        <i class="fas fa-fw fa-lg fa-minus-circle"></i>
                        Delete
                      </button>
                      <button class="btn btn-success" (click)="save()">
                        <i class="fas fa-fw fa-lg fa-save"></i>
                        Save
                      </button>
                    </div>
                </mat-tab>
                <mat-tab label="Pricing History">
                  <purchasing-line-item-history [itemId]="this.selectedMaterial.materialId"></purchasing-line-item-history>
                </mat-tab>
                </mat-tab-group>
              </div>
            </div>
          </div>

        </mat-sidenav-content>
      </mat-sidenav-container>

    </div>
  </div>
</div>
