<div>
  <loading *ngIf="!record && !quoteWasDeleted"></loading>
  <loading *ngIf="saving">Saving...</loading>
  <div class="position-fixed d-flex" style="bottom: 25px; right: 20px; z-index: 1030">
    <button mat-fab color="error" class="extended-fab-button bg-danger text-white" *ngIf="quoteWasDeleted"
        (click)="sidenav.close()">
      <mat-icon>logout</mat-icon>
      <span class="extended-fab-button__text">Return</span>
    </button>
  </div>
  

  <mat-sidenav-container class="p-0 m-0">
    <mat-sidenav disableClose (closedStart)="sidebarClosing()" #insetnav mode="over" position="end">
      <upload-file *ngIf="showEditor=='document'" (documentsLoaded)="addDocuments($event)" [sidenav]="insetnav"></upload-file>
      <vendor-detail *ngIf="showEditor=='addVendor'" [vendorId]="'new'" [sidenav]="insetnav"></vendor-detail>
    </mat-sidenav>
    <mat-sidenav-content>
      <div *ngIf="record && !showEditor && !quoteWasDeleted">
        <div class="container-fluid row">
          <div class="col-12">
            <div class="m-4 p-0 border">
              <div class="p-2 border">
                <div *ngIf="data; else showName">
                  <filtered-vendor-list [typeId]="getTypeId()" [typeName]="getTypeName()" [customer]="customer" (loading)="this.loading=$event" (OnAddVendorClick)="openAddVendor()" [editable]="!forceVendor" [(vendor)]="record.vendor"></filtered-vendor-list>
                </div>
                <ng-template #showName>
                  <h4 class="display-inline text-primary">{{record.vendor?.name}}</h4>
                </ng-template>
                <span title="Overall Score" class="badge badge-pill badge-light border p-2 ml-4">{{record.vendor?.overallScore}}</span>
                <span title="On Time Score" class="badge badge-pill badge-light border p-2 ml-2">{{record.vendor?.onTimeScore}}</span>
                <span title="Quality Score" class="badge badge-pill badge-light border p-2 ml-2">{{record.vendor?.qualityScore}}</span>
                <span title="Subjective Score" class="badge badge-pill badge-light border p-2 ml-2">{{record.vendor?.subjectiveScore}}</span>
              </div>
              <div class="container-fluid p-0 m-0 row" *ngIf="record.vendor">
                <div class="col-8 border p-2">
                  <div class="container-fluid row">
                    <div class="col-6" *ngIf="record.vendor?.primaryAddress">
                      <span class="font-weight-bold">Primary Address</span>
                      <div class="small">
                        <span class="d-block">{{record.vendor?.primaryAddress?.streetAddress}}</span>
                        <span class="d-block">{{record.vendor?.primaryAddress?.city}}, {{record.vendor?.primaryAddress?.state}} {{record.vendor?.primaryAddress?.postalCode}}</span>
                      </div>
                    </div>
                    <div class="col-6">
                      
                    </div>
                  </div>
                </div>
                <div class="col-4 border p-0">
                  <h5 class="text-center p-2 bg-secondary">Contacts</h5>
                  <table class="table table-sm small table-striped">
                    <tbody>
                      <tr *ngFor="let contact of record.vendor?.vendorContacts">
                        <td>{{contact.contact.name}}</td>
                        <td>
                          <a target="_blank" [href]="'mailto:'+contact.contact.email">{{contact.contact.email}}</a>
                          <span *ngIf="contact.contact.email==null||contact.contact.email.length==0" class="d-block font-italic text-muted text-center">-- No Email Address --</span>
                        </td>
                      </tr>
                      <tr *ngIf="(record.vendor?.vendorContacts)?.length==0">
                        <td colspan="100%" class="font-italic text-muted text-center">
                          -- No Quoting Contacts Entered --
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div class="container-fluid p-0 m-0" *ngIf="record.vendor; else chooseVendor">
                <div class="row form-group" *ngIf="record?.accepted!=null || record?.rejected!=null">
                  <div class="col-12 p-4 rounded text-white" [ngClass]="{'bg-success':record.accepted!=null, 'bg-danger':record.rejected!=null}">
                    {{record.accepted ? "Accepted" : "Rejected"}} by {{modifiedBy?.fullName}} on {{(record.accepted || record.rejected) | date}}
                  </div>
                </div>
                <div class="row form-group p-3 justify-content-center">
                  <div class="col-5">
                    <div class="p-2 rounded mat-elevation-z2">
                      <h6 class="text-center text-muted">Historical Pricing</h6>
                      <price-history-chart [materialId]="record.materialId" [stationId]="record.stationId" [purchasedItemId]="record.purchasedItemId"></price-history-chart>
                    </div>
                  </div>
                  <div class="col-7">
                    <div class="row">
                      <div class="col-6 form-group">
                        <label>Quote Type</label>
                        <div>
                          Formal <mat-slide-toggle [(ngModel)]="record.isVerbal"></mat-slide-toggle> Verbal
                        </div>
                      </div>
                      <div class="col-6">
                        <vendor-contact-search *ngIf="record.isVerbal" [vendor]="record.vendor" [addAllowed]="true" [(selectedItem)]="record.contact" (selectedItemChange)="record.contactId = $event.contactId" (onAddItem)="newVendorContact($event)"></vendor-contact-search>
                      </div>
                    </div>
                    <div class="row form-group">
                      <div class="col-4">
                        <date-picker appearance="outline" placeholder="Date Requested" [editable]="true" [(date)]="record.asked"></date-picker>
                      </div>
                      <div class="col-4">
                        <date-picker appearance="outline" placeholder="Required By" [editable]="true" [(date)]="record.requiredBy"></date-picker>
                      </div>
                      <div class="col-4">
                        <date-picker appearance="outline" placeholder="Date Received" [editable]="true" [(date)]="record.answered"></date-picker>
                    </div>
                  </div>
                  <div class="row form-group">
                    <div class="col-3">
                      <mat-form-field appearance="outline" class="w-100">
                        <mat-label>Lead Time (days)</mat-label>
                        <input type="number" matInput min="0" required step="1" placeholder="Lead Time (days)" [(ngModel)]="record.leadTimeDays" #leadTime="ngModel" />
                      </mat-form-field>
                    </div>
                    <div class="col-3">
                      <label>Bid Type</label>
                      <div class="input-group input-group-sm">
                        <mat-radio-group [(ngModel)]="bidType" class="row align-items-center">
                          <mat-radio-button class="col-6" value="total">Total</mat-radio-button>
                          <mat-radio-button class="col-6" value="perItem">Per Item</mat-radio-button>
                        </mat-radio-group>
                      </div>
                    </div>
                    <div class="col-3" *ngIf="bidType == 'total'">
                      <mat-form-field appearance="outline" class="w-100">
                        <mat-label>Total Bid</mat-label>
                        <span matPrefix style="position: relative; bottom: 8px;" class="text-muted">$</span>
                        <input matInput style="text-align: right;" type="number" min="0" step=".01" placeholder="Total Bid" [(ngModel)]="record.totalBid" />
                      </mat-form-field>
                    </div>
                    <div class="col-3" *ngIf="bidType == 'perItem'">
                      <mat-form-field appearance="outline" class="w-100">
                        <mat-label>Per Item Bid</mat-label>
                        <span matPrefix style="position: relative; bottom: 8px;" class="text-muted">$</span>
                        <input matInput style="text-align: right;" type="number" min="0" step=".01" placeholder="Per Item Bid" [(ngModel)]="record.perItemBid" />
                      </mat-form-field>
                    </div>
                    <div class="col-3">
                      <mat-form-field appearance="outline" class="w-100">
                        <mat-label>Quoted Quantity</mat-label>
                        <input matInput type="number" min="0" step="1" placeholder="Quoted Quantity" [(ngModel)]="record.qty" />
                      </mat-form-field>
                    </div>
                  </div>
                  </div>
                </div>
                <div class="row form-group p-3">
                  <div class="col p-1">
                    <mat-form-field appearance="outline" class="w-100">
                      <mat-label>Notes</mat-label>
                      <textarea matInput rows="6" [(ngModel)]="record.note" placeholder="Notes"></textarea>
                    </mat-form-field>
                  </div>
                  <div class="col p-0 bg-light" *ngIf="!record.isVerbal">
                    <h5 class="w-100 p-2 bg-secondary">Documents</h5>
                    <div class="p-2" *ngIf="!record.isVerbal">
                      <file-display-grid (delete)="deleteDocument($event)" [editable]="true" [files]="record?.materialBidDocuments"></file-display-grid>
                      <p *ngIf="record?.materialBidDocuments?.length==0" class="text-muted text-center"><em>-- No Documents --</em></p>
                      <a class="btn btn-badge bg-success has-icon float-right" title="Add Document" (click)="openDocumentWindow()"><i class="fas fa-plus mt-2"></i></a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <ng-template #chooseVendor>
          <div class="text-center p-4 m-4">
            <h5 class="font-italic text-muted">
              <i class="fas fa-fw fa-lg fa-info-circle text-info"></i>
              Please Choose a vendor
            </h5>
          </div>
        </ng-template>

        <div class="text-right buttons mb-4 pb-4">
          <div class="d-inline-block" *ngIf="!(record?.accepted || record?.rejected)">
            <div class="d-inline-block" *ngIf="record?.answered && isManager()">
              <button class="btn btn-success mr-2" (click)="approve()">
                <i class="fas fa-check fa-lg fa-fw"></i>
                Accept
              </button>
              <button class="btn btn-danger mr-4" (click)="reject()">
                <i class="fas fa-times-circle fa-lg fa-fw"></i>
                Reject
              </button>
            </div>
            <button class="btn btn-success mr-2" (click)="save()">
              <i class="fas fa-save fa-lg fa-fw"></i>
              Save
            </button>
          </div>
          <button class="btn btn-danger mr-2" (click)="exit()">
            <i class="fas fa-times fa-lg fa-fw"></i>
            Close
          </button>
        </div>
      </div>
      <div *ngIf="quoteWasDeleted" class="w-100 h-100 d-flex flex-column align-items-center justify-content-center">
        <div class="text-danger" style="font-size: 128px">
          <mat-icon inline>
            cancel
          </mat-icon>
        </div>
        <p class="mt-1 text-danger text-center">
          The quote could not be found. It may have been deleted. Please requote with the "Quick Quote" button on the previous screen.
        </p>
      </div>
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>


<ng-template #newVendorContactDialogTemplate let-contact>
  <h2 mat-dialog-title>New Vendor Contact</h2>
  <mat-dialog-content>
    <div class="row">
      <div class="col-6">
        <mat-form-field appearance="outline" class="w-100 mat-input-no-message">
          <mat-label>Contact Name</mat-label>
          <input matInput required placeholder="Contact Name" type="text" max="100"
           [(ngModel)]="contact.name" />
        </mat-form-field>
      </div>
      <div class="col-6">
        <mat-form-field appearance="outline" class="w-100 mat-input-no-message">
          <mat-label>Title</mat-label>
          <input matInput placeholder="Title" type="text" max="100"
            [(ngModel)]="contact.title" />
        </mat-form-field>
      </div>
    </div>
    <div class="row">
      <div class="col-8">
        <phone-number appearance="outline" [editable]="true" [(number)]="contact.phoneNumber">
        </phone-number>
      </div>
      <div class="col-4">
        <mat-form-field appearance="outline" class="w-100 mat-input-no-message">
          <mat-label>Ext.</mat-label>
          <input matInput placeholder="Extension" type="text" max="100"
        [(ngModel)]="contact.phoneExtension" />
        </mat-form-field>
      </div>
    </div>
    <div class="row">
      <div class="col-6">
        <phone-number placeholder="Fax Number" appearance="outline" [editable]="true" [(number)]="contact.faxNumber">
        </phone-number>
      </div>
      <div class="col-6">
        <phone-number placeholder="Cell Number" appearance="outline" [editable]="true" [(number)]="contact.cellNumber">
        </phone-number>
      </div>
    </div>
    <div class="row">
      <div class="col-6">
        <mat-form-field appearance="outline" class="w-100 mat-input-no-message">
          <mat-label>Work Email</mat-label>
          <input matInput placeholder="Work Email" type="email" max="100"
            [(ngModel)]="contact.email" />
        </mat-form-field>
      </div>
      <div class="col-6">
        <mat-form-field appearance="outline" class="w-100 mat-input-no-message">
          <mat-label>Personal Email</mat-label>
          <input matInput placeholder="Personal Email" type="email" max="100"
        [(ngModel)]="contact.personalEmail" />
        </mat-form-field>
      </div>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button mat-button class="btn btn-default btn-sm mr-3" [matDialogClose]="null">Cancel</button>
    <button mat-button [disabled]="!contact.name" [matDialogClose]="contact">Add Contact</button>
  </mat-dialog-actions>
</ng-template>