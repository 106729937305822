<div class="row m-0 h-100-t d-flex flex-column" style="overflow: hidden">
  <div class="d-flex border-bottom py-2" *ngIf="progressService.shouldShowIndicators()">
    <div class="ml-auto">
      <estimating-progress-status label="Workflow Status" [status]="getWorkflowTaskStatus()" (statusChange)="setWorkflowTaskStatus($event)" [disabled]="!editing"></estimating-progress-status>
    </div>
  </div>
  <div class="row m-0 p-0 flex-grow-1" style="height: 0">
    <div class="col-4 h-100-t p-0 border-right">
      <product-workflow-step-form [step]="selectedStep" (deleteStep)="deleteStep()" class="h-100-t" #stepFormComponent></product-workflow-step-form>
    </div>
    <div class="col-4 h-100-t p-0" [class.col-4]="editing" [class.col-8]="!editing">
      <product-workflow-workflow-list class="h-100-t" (stationDraggedIn)="addStep($event.index, $event.station)" [(selectedStepId)]="selectedStepId"></product-workflow-workflow-list>
    </div>
    <div class="col-4 p-0 pt-3 border-left" *ngIf="editing">
      <product-workflow-station-list class="h-100-t" (stationDblClick)="addStep(product.workflow.workflowSteps.length, $event)"></product-workflow-station-list>
    </div>
  </div>
</div>