import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { FloorBuilding, Department } from '../../resources/building';
import { NavigationService } from '../../../common/services/navigation.service';
import { MatSidenav } from '@angular/material/sidenav';
import { MachineAssignment } from '../../../planning/resources/machine-assignment';
import { Station } from '../../../order/resources/station';
import { StationService } from '../../../order/services/station.service';
import { FloorService } from '../../services/floor.service';
import { UtilityService } from '../../../common/services/utility.service';
import { MachineTodayScheduleComponent } from '../../charts/machine-today-schedule/machine-today-schedule.component';
import { OperatorTodayScheduleComponent } from '../../charts/operator-today-schedule/operator-today-schedule.component';
import { User } from '../../../common/resources/user';
import { FloorOpsMetaData } from '../../../employee/resources/floor-ops-meta-data';
import { OrderStatus } from '../../../order/resources/order';
import { ProductWaterfallComponent } from '../../../order/charts/product-waterfall/product-waterfall.component';
import { WorkflowStepInventoryItem } from '../../../order/resources/workflow';
import { WorkOrderService } from '../../../planning/services/work-order.service';

import $ from "jquery";
import { InventoryItem } from '../../../inventory/resources/inventory-item';

@Component({
  selector: 'machine-assignment-detail',
  templateUrl: './machine-assignment-detail.component.html',
  styleUrls: ['./machine-assignment-detail.component.less']
})
export class MachineAssignmentDetailComponent implements OnInit {
  @Input() record: MachineAssignment;
  @Input() buildingId: string;
  @Input() sidenav: MatSidenav;
  @ViewChild('machineSchedule') machineSchedule: MachineTodayScheduleComponent;
  @ViewChild('operatorSchedule') operatorSchedule: OperatorTodayScheduleComponent;
  @ViewChild('waterfallChart') waterfallChart: ProductWaterfallComponent;
  public saving: boolean = false;
  public stations: Station[];
  public assignedBuilding: FloorBuilding;
  public touched: boolean = false;
  public prerequisites: WorkflowStepInventoryItem[];
  private departments: Department[];
  private buildings: FloorBuilding[];

  constructor(private utilitySvc: UtilityService, private navService: NavigationService, private stationSvc: StationService, private floorSvc: FloorService, private woService: WorkOrderService) {
    this.navService.pushBreadcrumb("Machine Assignment");
  }

  public get now(): Date {
    return new Date();
  }

  public getHomeDepartment(user: User): string {
    if (!user.permissionMetaData['floorOps'] || !this.departments || !this.buildings) return null;
    const meta: FloorOpsMetaData = (<FloorOpsMetaData>user.permissionMetaData['floorOps']);

    const flrBuilding: FloorBuilding = this.buildings.find(d => d.floorBuildingId == meta.primaryDepartmentId);
    const dept: Department = this.departments.find(b => b.departmentId == (flrBuilding == null ? UtilityService.newGuid : flrBuilding.departmentId));

    if (dept != null && flrBuilding != null) {
      return `${dept.name} / ${flrBuilding.name}`;
    }

    console.log("UserId " + user.userId + " has an invalid department!!");
    return null;
  }

  public getOperatorRating(user: User): number {
    if (!user.permissionMetaData['floorOps']) return -1;
    const proficiency = (<FloorOpsMetaData>user.permissionMetaData['floorOps']).machineProficiencies.find(m => m.machineId == this.record.machineId);

    return (!proficiency) ? -1 : proficiency.level;
  }

  public close(): void {

    this.utilitySvc.showConfirmation("Close Without Saving?", "<p>Are you sure you want to close this assignment without saving first?</p><p class='text-muted'>Hit cancel to save first, or hit 'Okay' if you're sure.</p>", r => {
      if (r) {
        if (this.sidenav)
          this.sidenav.close();
      }
    });

  }

  public machineProficencyFilter(user: User, _index: number): boolean {
    if (!user.permissionMetaData['floorOps']) return false;
    const floorOpsData = <FloorOpsMetaData>user.permissionMetaData['floorOps'];

    //NOTE: 'this' is not in context when called....
    return floorOpsData.machineProficiencies.findIndex(m => m.machineId == $('#record_machineId').val()) >= 0;
  }

  public save(): void {
    this.touched = true;

    //if (this.record.userId == null) {
    //  this.utilitySvc.showAlert("Operator Required", "<p>You must set an Operator before saving.</p>");
    //  return;
    //}
    if (this.record.scheduledStart < this.now) {
      this.utilitySvc.showAlert("Invalid Scheduling Date/Time", "<p>You cannot schedule an assignment in the past!</p><p class='text-muted'>Please update the Scheduled Start time before saving.</p>");
      return;
    }

    this.saving = true;
    this.record.scheduledEnd = this.getEndTime();
    this.record.status = OrderStatus.ON_FLOOR;

    this.floorSvc.saveAssignment(this.record).subscribe(_ => {

      this.saving = false;

      if (this.sidenav)
        this.sidenav.close();

    });

  }

  public updateSchedule(e: any): void {
    this.record.scheduledStart = new Date(e);

    this.refreshCharts();
  }

  public refreshCharts(): void {
    var insertedData: any = <any>this.record;

    insertedData.backgroundColor = "#cccc0099";
    insertedData.borderColor = "#00000099";

    //Update the operator chart if an operator is selected.
    //NOTE: We use a timeout here because this event is raised at the same time the value is updated, and otherwise causes a race condition.
    setTimeout(_ => {
      if (this.record.userId != null) {
        this.operatorSchedule.additionalData = [insertedData];
        this.operatorSchedule.refresh();
      }
    }, 10);

    this.waterfallChart.additionalData = [insertedData];
    this.waterfallChart.refresh();

    this.machineSchedule.additionalData = [insertedData];
    this.machineSchedule.refresh();
  }

  public getStation(stationId: string): Station {
    if (!this.stationSvc.stationList) return null;

    return this.stationSvc.stationList.find(s => s.stationId == stationId);
  }

  public getEndTime(): Date {
    if (!this.record || !this.record.operation) return new Date();

    var end = new Date(this.record.scheduledStart);
    end.setMinutes(end.getMinutes() + this.record.operation.runTime * (this.record.operation.runIsPerPart ? 1 : 60));
    return end;
  }

  public setStartTime(e: any): void {
    var parsed = e.target.value.split(":");

    this.record.scheduledStart.setHours(parsed[0], parsed[1], 0, 0);
    this.record.scheduledEnd = this.getEndTime();
  }

  public get date(): Date {
    return this.record.scheduledStart;
  }

  public set date(val: Date) {
    this.record.scheduledStart.setFullYear(val.getFullYear(), val.getMonth(), val.getDate());
    this.record.scheduledEnd = this.getEndTime();

    this.refreshCharts();
  }

  public get time(): string {
    return `${this.record.scheduledStart.getHours().toString().padStart(2, '0')}:${this.record.scheduledStart.getMinutes().toString().padStart(2, '0')}`;
  }

  public set time(val: string) {
    const parsed = val.split(":");

    this.record.scheduledStart.setHours(parseInt(parsed[0]), parseInt(parsed[1]), 0, 0);
    this.record.scheduledEnd = this.getEndTime();

    this.refreshCharts();
  }

  public isHere(item: InventoryItem): boolean {
    if (!item.locations || item.locations.length == 0) return true;

    return true; //TODO: This needs updating to determine if the item is at the machine
  }

  ngOnInit(): void {
    this.record.scheduledStart = new Date(this.record.scheduledStart); //Just in case it's NOT a date...

    this.floorSvc.search().subscribe(b => {
      this.departments = b.results;
    });
    this.woService.getWorkflowStepDetail(this.record.workflowStepId).subscribe(r => {
      this.prerequisites = r.workflowStepInventoryItems.filter(i => i.type == 0);
    });
    this.floorSvc.getAllFloorBuildings().subscribe(d => {
      this.buildings = d.results;
    });
    this.stationSvc.stationsLoaded.subscribe(r => {
      if (r) {
        this.stations = this.stationSvc.stationList;
      }
    });
    this.floorSvc.getFloorBuilding(this.record.machine.floorBuildingId).subscribe(result => {
      this.assignedBuilding = result;

      this.refreshCharts();
    });
  }
}
