<div>
  <a *ngIf="user && !editing && canEdit" class="position-fixed fixed-bottom btn btn-badge has-icon" title="Edit" (click)="toggleEditing()"><i class="fas fa-edit"></i></a>
  <a *ngIf="user && editing || id" class="position-fixed fixed-bottom btn btn-badge bg-danger has-icon mr-6" title="Cancel Edit" (click)="cancelEditing()"><i class="fas fa-times"></i></a>
  <a *ngIf="user && editing" class="position-fixed fixed-bottom btn btn-badge bg-success has-icon" title="Save" (click)="saveChanges(true)"><i class="fas fa-save"></i></a>

  <loading *ngIf="!user"></loading>
  <loading *ngIf="saving" content="Saving..."></loading>
  <loading *ngIf="uploadFileName" [content]="uploadFileName"></loading>

  <div class="container-fluid" *ngIf="user">
    <div class="row">
      <div class="col-4 photo p-3">
        <div *ngIf="editing">
          <button class="btn btn-primary float-right" (click)="fileInput.click()"><i class="fas fa-images"></i> Change Image</button>
          <input hidden type="file" #fileInput (change)="getFile($event.target.files)">
        </div>
        <img *ngIf="user.userProfilePhotoPath; else noPhoto" class="rounded-circle shadow border border-dark" [style.background-image]="'url('+user.userProfilePhotoPath+')'" />
        <span *ngIf="user.userProfilePhotoPath" class="img-wait">
          <i class="fas fa-spin fa-circle-notch text-primary fa-4x"></i>
          <span class="d-block">Loading...</span>
        </span>
        <ng-template #noPhoto>
          <div class="position-relative">
            <i class="no-user-icon fas fa-user shadow text-muted border rounded-circle"></i>
            <span class="no-photo">-- No Photo --</span>
          </div>
        </ng-template>
      </div>
      <div class="col-8 pl-4">
        <div class="form-group" *ngIf="id">
          <label>Last Login</label>
          <div class="pl-2 text-muted">{{(user.lastActive | date:'EEEE MMM d, y h:mm a') || '--'}}</div>
        </div>
        <div class="container-fluid">
          <div class="row">
            <div class="col">
              <div class="form-group">
                <label>Full Name</label>
                <div class="pl-2">
                  <div *ngIf="editing" class="bg-info text-white text-center p-1"><i class="text-white fas fa-fw fa-lg fa-exclamation-triangle"></i> Name is Synced from Active Directory and Must be Modified Elsewhere</div>
                  {{user.fullName}}
                </div>
              </div>
              <div class="form-group">
                <label>Title</label>
                <input placeholder="Title" type="text" max="100" class="form-control" appStaticEdit [editable]="editing" [(ngModel)]="user.title" />
              </div>
              <div class="form-group">
                <label>Contact Email</label>
                <input placeholder="Contact Email" type="email" class="form-control form-control-sm" appStaticEdit [editable]="editing" [(ngModel)]="user.contactEmail" />
              </div>
            </div>
            <div class="col">
              <div class="form-group">
                <label>Phone (Ext)</label>
                <div class="container-fluid row p-0">
                  <div class="col-9">
                    <phone-number [editable]="editing" [(number)]="user.phone"></phone-number>
                  </div>
                  <div class="col-3 p-0">
                    <input placeholder="Ext" type="text" max="10" class="form-control form-control-sm" appStaticEdit [editable]="editing" [(ngModel)]="user.extension" />
                  </div>
                </div>
              </div>
              <div class="form-group">
                <label>SMS Phone Number</label>
                <div *ngIf="!mySmsNumber">
                  Loading My SMS Number...
                </div>
                <div *ngIf="mySmsNumber!= 'Not Set' else notSet">
                  <phone-number placeholder="Phone Number for SMS" [editable]="editing" [(number)]="mySmsNumber"></phone-number>
                </div> 
                <ng-template #notSet>
                  <br />
                  <span class="font-italic text-secondary small">Not Set</span>
                </ng-template>
              </div>
              <div class="form-group">
                <label>Fax</label>
                <phone-number placeholder="Fax Number" [editable]="editing" [(number)]="user.fax"></phone-number>
              </div>
              <div class="form-group">
                <label>Supervisor</label>
                <filtered-user-list [editable]="editing" roleFlter="*Manager" [(selected)]="user.supervisorId"></filtered-user-list>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <div class="form-group permissions" *ngIf="canEdit">
                <label>Module Permissions</label>
                <div *ngIf="editing" class="bg-info text-white text-center p-1"><i class="text-white fas fa-fw fa-lg fa-exclamation-triangle"></i> Module Permissions are Synced from Active Directory and Must be Modified Elsewhere</div>
                <div class="pl-2 row">
                  <div class="col-4" *ngFor="let group of availableGroups">
                    <span>{{group.niceName}}</span><mat-slide-toggle [checked]="isMemberOf(group.name)" [disabled]="true"></mat-slide-toggle>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="pb-3 modules">
          <notification-preferred-channel [editable]="editing" [(user)]="user" [eventsObservable]="eventsSubjectEmitter.asObservable()"></notification-preferred-channel>
          <floor-user-setup [editable]="editing" [(user)]="user" *ngIf="id && isMemberOf('FloorOpsUser') && canAccess('FloorOpsManager')"></floor-user-setup>
          <!-- Any other module-specific configurations can go here... -->
        </div>
  </div>

</div>
