import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Paint } from '../../../order/resources/paint';
import { Station } from '../../../order/resources/station';
import { WorkflowStep } from '../../../order/resources/workflow';
import { StationService } from '../../../order/services/station.service';
import { UserService } from '../../services/user.service';

@Component({
  selector: 'workflow-step-edit',
  templateUrl: './workflow-step-edit.component.html',
  styleUrls: ['./workflow-step-edit.component.less']
})
export class WorkflowStepEditComponent implements OnInit {

  @Input() step: WorkflowStep;
  @Input() isRepair: boolean = false;
  @Input() canEditWorkflowStep: boolean = true;
  @Input() quantity: number = 1;

  @Output() save = new EventEmitter<WorkflowStep>();
  
  @Input() workflowDirty: boolean = false;
  @Output() workflowDirtyChange = new EventEmitter<boolean>();
  constructor(private stationSvc: StationService, private usrService: UserService) { }

  ngOnInit() {
  }

  public getStation(stationId: string): Station {
    if (!this.stationSvc.loaded) return null;
    return this.stationSvc.stationList.find(s => s.stationId == stationId);
  }

  public setWorkflowDirty(): void {
    this.workflowDirty = true;
    this.workflowDirtyChange.emit(true);
    // this.chartData = this.getWorkflowPricingData();
  }

  public isPurchasingUser(): boolean {
    return this.usrService.canAccess("PurchasingUser");
  }

  public assignPaint(paint: Paint): void {
    this.setWorkflowDirty();
    if (paint) {
      this.step.paintId = paint.paintId;
      this.step.paint = paint;
    }
  }

  public addPaint(_name: string): void {
    // this.showEditor = "paintEditor";
    // this.navService.pushBreadcrumb("Add Paints");
    // this.insetnav.toggle();
  }

  public onToggleProgramming(e: boolean) {
    this.setWorkflowDirty();
    // set defaults
    if (e === true) {
      if (!this.step.programmingTime)
        this.step.programmingTime = 1;
      if (!this.step.programmingRate)
        this.step.programmingRate = 125;
    }
  }

  public onToggleInspection(e: boolean) {
    this.setWorkflowDirty();
    // set defaults
    if (e === true) {
      if (!this.step.inspectionTime)
        this.step.inspectionTime = 60;
      if (!this.step.inspectionRate)
        this.step.inspectionRate = 125;
    }
  }

  public getInspectionCost() {
    if (!this.step || !this.step.inspectionTime || !this.step.inspectionRate) return 0;
    return ((this.step.inspectionTime / 60) * this.step.inspectionRate) * this.quantity;
  }

  public onSave() {
    this.save.emit(this.step);
  }

}
