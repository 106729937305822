import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import wait from '../../../../../util/wait';

@Component({
  selector: 'confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.less']
})
export class ConfirmDialogComponent {

  constructor(@Inject(MAT_DIALOG_DATA) public data: ConfirmDialogContent) {
    if (data.timer) {
      this.startTimer(data.timer);
    }
  }


  public readTimeLeft = 0;

  private async startTimer(time: number) {
    this.readTimeLeft = time;
    while (this.readTimeLeft > 0) {
      await wait(1000);
      this.readTimeLeft -= 1;
    }
  }


}

export interface ConfirmDialogContent {
  title: string;
  detail: string;
  timer?: number;
}
