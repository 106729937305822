export class Paint {
  paintId: string;
  name: string;
  coverage: number;
  manufacturer: string;
  series: string;
  sheen: number;
  colorCode: string;
  sampleColor: string;
  purchaseVolume: number;

  public static PaintVolumes: any = {
    1: "Pint",
    2: "Quart",
    8: "Gallon",
    40: "5-Gallon"
  };

  public static PaintSheen: string[] = [
    "Flat",
    "Eggshell",
    "Stain",
    "Semi-Gloss",
    "Gloss",
    "Other",
  ];
}
