import { Component, EventEmitter, Output, Input, ViewChild, ElementRef } from '@angular/core';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { UserService } from '../../../common/services/user.service';
import { Subscription } from 'rxjs';
import { VendorService } from '../../../supplier/services/vendor.service';
import { Vendor } from '../../../supplier/resources/vendor';

@Component({
  selector: 'vendor-chooser',
  templateUrl: './vendor-chooser.component.html',
  styleUrls: ['./vendor-chooser.component.less']
})
export class VendorChooserComponent {
  @Input() vendor: Vendor;
  @Output() vendorChange: EventEmitter<Vendor> = new EventEmitter<Vendor>();
  @Input() editable: boolean;
  @ViewChild('vendorInputText', { static: true }) inputTxt: ElementRef;
  @ViewChild('autoCompleteTrigger', { static: true }) autoCompleteTrigger: MatAutocompleteTrigger;
  @Output() addVendor: EventEmitter<string> = new EventEmitter<string>();
  private request: Subscription = null;
  public searching: boolean = false;

  vendors: Vendor[] = [];

  constructor(private vendorService: VendorService, private usrService: UserService) { }

  setValue(vendor: Vendor): void {
    this.vendor = vendor;
    if (vendor == null)
      return;

    this.vendorService.getDetail(this.vendor.vendorId).subscribe(v => {
      this.vendor = v;
      this.vendorChange.emit(this.vendor);
    });
  }

  toggleDropdown(): void {
    event.stopImmediatePropagation();
    if (this.autoCompleteTrigger.panelOpen) {
      this.autoCompleteTrigger.closePanel();
    }
    else {
      this.searchVendors(null);
      this.autoCompleteTrigger.openPanel();
    }
  }

  canAdd(): boolean {
    return this.usrService.canAccess("SupplierUser");
  }

  onAddVendor(): void {
    event.stopImmediatePropagation();
    this.addVendor.emit(this.inputTxt.nativeElement.value);
    this.autoCompleteTrigger.closePanel();
  }

  public testSelection(_: any): void {
    if (!this.vendor) {
      this.inputTxt.nativeElement.value = "";
      return;
    }
    this.inputTxt.nativeElement.value = this.vendor.name;
  }

  searchVendors(_: any) {
    this.searching = true;
    if (this.request != null) {
      this.request.unsubscribe();
    }

    this.request = this.vendorService.search(this.inputTxt.nativeElement.value)
      .subscribe(result => {
        this.searching = false;
        this.vendors = result.results
      });
  }
}
