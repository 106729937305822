import { Component, ViewChild } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { NavigationService } from '../../../common/services/navigation.service';
import { UntypedFormControl, Validators } from '@angular/forms';
import { RuleBuilder } from '../../resources/rule-builder';
import { ActivatedRoute, Router } from '@angular/router';
import { RuleBuilderService } from '../../services/rule-builder.service';
import { UtilityService } from './../../../common/services/utility.service';
import {
  RuleBuilder_Constants_RuleAction, RuleBuilder_Constants_RuleActionWhenUnit,
  RuleBuilder_Constants_RuleActionToTake, RuleBuilder_Constants_RuleActionToTakeWhenUnit
} from '../../rule-builder-constants';
import { notification_preferred_channel_Constants } from '../../../notification/notification_preferred_channel_Constants';


@Component({
  selector: 'rule-builder-detail',
  templateUrl: './rule-builder-detail.component.html',
  styleUrls: ['./rule-builder-detail.component.less']
})
export class RuleBuilderDetailComponent {

  public ruleBuilder: RuleBuilder;

  public editing: boolean;
  public actionType: string = 'Add';

  public ruleNameForm = new UntypedFormControl('', [Validators.required, Validators.maxLength(100)]);
  public actionToTakeWhenUnitFormControl = new UntypedFormControl('');
 
  public lRuleActions: string[];
  public lRuleActionsWhenUnit: string[];
  //public selectedRuleAction: string[];
  public lRuleActionsToTake: string[];
  public lRuleActionsToTakeWhenFrequency: string[];
  public lRuleActionsToTakeWhenUnit: string[];

  public ruleBuilder_actionToTakeWhenFrequency: string = "Per";
  public showActionToTakeNumericParameters: boolean;
  public labelActionToTakeWhen: string;


  constructor(private navService: NavigationService, http: HttpClient, private route: ActivatedRoute, private router: Router, private utilitySvc: UtilityService,
              private ruleBuilderService: RuleBuilderService) {
    navService.setPageTitle("Rule Builder Detail");
  }

  ngOnInit() {

    let ruleId: string = this.route.snapshot.params['id'];

    this.getData(ruleId);
  }

  public async getData(ruleId: string) {

    this.lRuleActions = this.getAllRuleActions();
    this.lRuleActionsWhenUnit = this.getAllRuleActionsWhenUnit();

    this.lRuleActionsToTake = this.getAllRuleActionsToTake();
    this.lRuleActionsToTakeWhenFrequency = this.getAllRuleActionToTakeWhenFrequency();
    this.lRuleActionsToTakeWhenUnit = this.getAllRuleActionsToTakeWhenUnit();
     
    //console.log("getData() ruleId= " + ruleId);

    if (ruleId && !isNaN(Number(ruleId))) {
      this.actionType = 'Edit';

      await this.getRuleBuilder(ruleId);
    };

    if (this.actionType == "Add") {

      this.ruleBuilder = <RuleBuilder>{
        name: null,
        description: null,
        enabled: true,
        ruleAction: null,
        ruleActionWhenValue: null,
        ruleActionWhenUnit: null,
        actionToTake: null,
        actionToTakeWhenValue: null,
        actionToTakeWhenUnit: null
      };

      this.enableForm();
    };

    this.setPageTitle(ruleId);
  }

  private async getRuleBuilder(ruleId: string) {

    var promise = new Promise<void>(resolve => {
      this.ruleBuilderService.getRule(ruleId).subscribe(data => {
        this.ruleBuilder = data;
        //console.log("getRuleBuilder() - this.ruleBuilder= " + JSON.stringify(this.ruleBuilder));
        this.onSelectionChangeRuleActionToTake(this.ruleBuilder.actionToTake);

        resolve();
      });      
    }); 
    return promise;
  }


  public getAllRuleActions(){
    var lRuleActions = [];
     
    lRuleActions.push(RuleBuilder_Constants_RuleAction.ruleAction_1);
    lRuleActions.push(RuleBuilder_Constants_RuleAction.ruleAction_2);
    lRuleActions.push(RuleBuilder_Constants_RuleAction.ruleAction_3);
    lRuleActions.push(RuleBuilder_Constants_RuleAction.ruleAction_4);
    lRuleActions.push(RuleBuilder_Constants_RuleAction.ruleAction_5);
    lRuleActions.push(RuleBuilder_Constants_RuleAction.ruleAction_6);
    lRuleActions.push(RuleBuilder_Constants_RuleAction.ruleAction_7);

    return lRuleActions.sort();
  }

  public getAllRuleActionsWhenUnit() {
    var lRuleActionsWhenUnit = [];
     
    lRuleActionsWhenUnit.push(RuleBuilder_Constants_RuleActionWhenUnit.ruleActionWhenUnit_Minutes);
    lRuleActionsWhenUnit.push(RuleBuilder_Constants_RuleActionWhenUnit.ruleActionWhenUnit_Hours);
    lRuleActionsWhenUnit.push(RuleBuilder_Constants_RuleActionWhenUnit.ruleActionWhenUnit_Days);

    return lRuleActionsWhenUnit;
  }

  public getAllRuleActionsToTake() {
    var lRuleActionsToTake = [];

    lRuleActionsToTake.push(RuleBuilder_Constants_RuleActionToTake.ruleActionToTake_SendANotification);
    lRuleActionsToTake.push(RuleBuilder_Constants_RuleActionToTake.ruleActionToTake_ScheduleToSendANotification);
    lRuleActionsToTake.push(RuleBuilder_Constants_RuleActionToTake.ruleActionToTake_ExpireQuotes);

    return lRuleActionsToTake.sort();
  }

  public getAllRuleActionToTakeWhenFrequency() {
    var lRuleActionsToTakeWhenFrequency = [];

    lRuleActionsToTakeWhenFrequency.push("Per");

    return lRuleActionsToTakeWhenFrequency;
  }

  public getAllRuleActionsToTakeWhenUnit() {
    var lRuleActionsToTakeWhenUnit = [];

    lRuleActionsToTakeWhenUnit.push(RuleBuilder_Constants_RuleActionToTakeWhenUnit.ruleActionToTakeWhenUnit_Minute);
    lRuleActionsToTakeWhenUnit.push(RuleBuilder_Constants_RuleActionToTakeWhenUnit.ruleActionToTakeWhenUnit_Hour);
    lRuleActionsToTakeWhenUnit.push(RuleBuilder_Constants_RuleActionToTakeWhenUnit.ruleActionToTakeWhenUnit_Day);
    lRuleActionsToTakeWhenUnit.push(RuleBuilder_Constants_RuleActionToTakeWhenUnit.ruleActionToTakeWhenUnit_Week);

    return lRuleActionsToTakeWhenUnit;
  }

  public onSelectionChangeRuleAction(selectedRuleAction) {
  }

  public onSelectionChangeRuleActionWhenUnit() {
  }
 
  public onSelectionChangeRuleActionToTake(selectedRuleActionToTake) {

    //console.log("onSelectionChangeRuleActionToTake() - selectedRuleActionToTake.toUpperCase()= " + selectedRuleActionToTake.toUpperCase());
     
    switch (selectedRuleActionToTake.toUpperCase()) {
      case RuleBuilder_Constants_RuleActionToTake.ruleActionToTake_SendANotification.toUpperCase():
        this.labelActionToTakeWhen = "How many ?";
        this.ruleBuilder_actionToTakeWhenFrequency = "Per";
        this.showActionToTakeNumericParameters = true;

        //remove "minute" option
        this.lRuleActionsToTakeWhenUnit.forEach((item, index) => {
          if (item.toUpperCase() == RuleBuilder_Constants_RuleActionToTakeWhenUnit.ruleActionToTakeWhenUnit_Minute.toUpperCase()) {
            var lRuleActionsToTakeWhenUnitRemoved = this.lRuleActionsToTakeWhenUnit.splice(index, 1);
          }
        });
        this.actionToTakeWhenUnitFormControl.setValue(this.lRuleActionsToTakeWhenUnit);
        break;

      case RuleBuilder_Constants_RuleActionToTake.ruleActionToTake_ExpireQuotes.toUpperCase():
          this.labelActionToTakeWhen = "";
          this.ruleBuilder_actionToTakeWhenFrequency = "";
          this.showActionToTakeNumericParameters = false;
        break;

      case RuleBuilder_Constants_RuleActionToTake.ruleActionToTake_ScheduleToSendANotification.toUpperCase():
          this.labelActionToTakeWhen = "When ?";
          this.ruleBuilder_actionToTakeWhenFrequency = "";
          this.showActionToTakeNumericParameters = true;

        //add "minute" option
        var itemMinute = this.lRuleActionsToTakeWhenUnit.find(x => x.toUpperCase() == RuleBuilder_Constants_RuleActionToTakeWhenUnit.ruleActionToTakeWhenUnit_Minute.toUpperCase())
        if (!itemMinute)
          this.lRuleActionsToTakeWhenUnit.unshift(RuleBuilder_Constants_RuleActionToTakeWhenUnit.ruleActionToTakeWhenUnit_Minute);

        //this.lRuleActionsToTakeWhenUnit = this.lRuleActionsToTakeWhenUnit.sort((a, b) => a.localeCompare(b));
        this.actionToTakeWhenUnitFormControl.setValue(this.lRuleActionsToTakeWhenUnit );
        break;

      default:
          console.log("Error: Not recognized selectedRuleActionToTake= " + selectedRuleActionToTake);
        break;
    } 
  }  

  public toggleEditing(): void {

    this.editing = !this.editing;
  }

  enableForm() {
    this.editing = true;

  }

  disableForm() {
    this.editing = false;
  }

  private setPageTitle(ruleId: string): void {
    
    this.navService.setPageTitle("Rule Builder Detail");

    var ruleId_number = Number(ruleId);

    var breadcrumb = null;
    if (ruleId != null && !isNaN(ruleId_number)) {
      breadcrumb = this.ruleBuilder.name;
    }
    else {
      breadcrumb = "New Rule";
    }

    this.navService.pushBreadcrumb(breadcrumb);
  }


  formIsValid() {
    let isValid = true;

    if (this.ruleNameForm.invalid) {
      isValid = false;
      this.utilitySvc.showAlert("A Name is Required", "<p>Please add a rule name before proceeding.</p>");
    }

    if (this.ruleBuilder.actionToTakeWhenValue != null){
      var actionToTakeWhenValue_int = parseInt(this.ruleBuilder.actionToTakeWhenValue);

      var maxNumNotifications = 10;
      switch (this.ruleBuilder.actionToTakeWhenUnit) {
        case RuleBuilder_Constants_RuleActionToTakeWhenUnit.ruleActionToTakeWhenUnit_Hour:
          maxNumNotifications = 1;          
          break;

        case RuleBuilder_Constants_RuleActionToTakeWhenUnit.ruleActionToTakeWhenUnit_Day:
          maxNumNotifications = 24;
          break;

        case RuleBuilder_Constants_RuleActionToTakeWhenUnit.ruleActionToTakeWhenUnit_Week:
          maxNumNotifications = 7 * 24;
          break;

        default:
          console.log("Error: ruleBuilder.actionToTakeWhenUnit not recognized= " + this.ruleBuilder.actionToTakeWhenUnit);
          break;
      }

      if (actionToTakeWhenValue_int > maxNumNotifications) {
        isValid = false;
        this.utilitySvc.showAlert("'How Many?' value should be smaller", "<p>The number <b>" + this.ruleBuilder.actionToTakeWhenValue + "</b> of notifications to send is too high, please enter a value smaller than " + maxNumNotifications + " per " + this.ruleBuilder.actionToTakeWhenUnit + " .</p>");
      }
    }      
    return isValid;
  }

  public saveChanges() {
     
    if (this.formIsValid()) {
      //console.log("saveChanges() - this.ruleBuilder= " + JSON.stringify(this.ruleBuilder));

      this.disableForm();
      if (this.actionType === 'Add') {
        this.ruleBuilderService.addRuleBuilder(this.ruleBuilder).subscribe(data => {
          //console.log("saveChanges() - Successfully Added.");
          this.disableForm();
          this.ruleBuilder = data;

          this.actionType = 'Edit';

          this.setPageTitle(this.ruleBuilder.ruleId);
          this.router.navigate(['/rule-builder-detail', data.ruleId]);
        },
        err => {
          console.log("Error while Adding. err= " + err);
        });
      }
      else if (this.actionType === 'Edit') {
        this.ruleBuilderService.updateRuleBuilder(this.ruleBuilder).subscribe(data => {

          this.setPageTitle(this.ruleBuilder.ruleId);
          this.router.navigate([this.router.url]);
        }, err => {
          console.log("Error while Updating. err= " + err);
        });
      }

    }
  }

  public deleteRecord() {
    console.log("deleteRecord() - this.ruleBuilder.ruleId= " + this.ruleBuilder.ruleId);

    this.utilitySvc.showConfirmation("Confirm Delete Rule Builder",
      `<p>Are you sure you want to delete the rule '<em>${this.ruleBuilder.name}</em>'?</p><p class='font-weight-bold text-center'>This cannot be undone.</p>`
      , (result => {
        if (result === true) {
          this.ruleBuilderService.deleteRuleBuilder(this.ruleBuilder.ruleId).subscribe(
            (data) => {
              console.log("Successfully Deleted.");
              this.router.navigate(['/rules-builder']);
            },
            (err) => {
              console.log("Error while Deleting.");
            }
          );
        }
      })
    );
      
  }








}
