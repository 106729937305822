import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Department, FloorBuilding, FloorMachine } from '../../resources/building';
import { FloorService } from '../../services/floor.service';
import { BlockData } from '../floor-layout-block/floor-layout-block.component';
import { NavigationService } from '../../../common/services/navigation.service';
import { UserService } from '../../../common/services/user.service';
import { NgModel } from '@angular/forms';
import { UtilityService } from '../../../common/services/utility.service';
import { MatSidenav } from '@angular/material/sidenav';
import { MachineAssignment } from '../../../planning/resources/machine-assignment';
import { UnassignedJobsListComponent } from '../unassigned-jobs-list/unassigned-jobs-list.component';
import { FloorAlert } from '../../resources/floor-alert';

@Component({
  selector: 'department-detail',
  templateUrl: './department-detail.component.html',
  styleUrls: ['./department-detail.component.less']
})
export class DepartmentDetailComponent implements OnInit {
  private id: string;
  public department: Department;
  public departments: Department[] = null;
  public editing: boolean = false;
  public showEditor: string = null;
  public selectedBuilding: FloorBuilding = null;
  public selectedAssignment: MachineAssignment = null;
  public selectedMachineId: string = null;
  public selectedInspectionTicketId: string = null;
  public saving: boolean = false;
  @ViewChild('name') name: NgModel;
  @ViewChild('sidenav') sidenav: MatSidenav;
  @ViewChild('unassignedJobsList') unassignedJobsList: UnassignedJobsListComponent;

  constructor(private navService: NavigationService, private route: ActivatedRoute, private router: Router, private floorSvc: FloorService, private userSvc: UserService, private utilitySvc: UtilityService) {
    this.id = this.route.snapshot.paramMap.get('id');
    this.navService.setPageTitle("Floor");
  }

  public showBuilding(b: Department): void {
    event.preventDefault();

    this.router.navigate(['/floor/department', b.departmentId]);

    this.id = b.departmentId;
    this.editing = false;
    this.getData();
  }

  private getData(): void {
    this.department = null;

    this.floorSvc.get(this.id).subscribe(res => {
      this.department = res;
      this.navService.clearBreadCrumbs();
      this.navService.pushBreadcrumb(`'${this.department.name}' Department`);
    });
  }

  public goOverview(): void {
    event.preventDefault();

    this.router.navigate(['/floor']);
  }

  public setDepartmentUIData(department: FloorBuilding, event: BlockData) {
    ;//empty
  }

  public toggleEdit(): void {
    this.editing = !this.editing;
  }

  public selectDepartment(fb: FloorBuilding): void {
    if (this.editing) {
      return;
    }

    this.selectedBuilding = fb;
    this.showEditor = 'departmentDetail';
    this.sidenav.open();
  }

  public closeSideNav(): void {
    if (this.showEditor == 'assignment') {
      this.unassignedJobsList.refresh();
    }

    this.showEditor = null;
    this.navService.popBreadCrumb();
  }

  public saveChanges(): void {
    if (this.name.invalid) {
      this.name.control.markAsTouched();
      this.utilitySvc.showAlert("A Valid Building Name is Required", "<p>Please add a building name before saving.</p>");
      return;
    }

    this.saving = true;
    this.floorSvc.save(this.department).subscribe(result => {

      this.floorSvc.saveFloorBuildingUI(this.department.floorBuildings).subscribe(_ => {
        this.saving = this.editing = false;
        this.id = this.department.departmentId;        
        this.getData();
      });
      this.department = result;
    });

  }

  public cancelEdit(): void {
    this.editing = false;
    this.getData();
  }

  public canEdit(): boolean {
    return this.userSvc.canAccess("FloorManager");
  }

  public showAssignmentDetails(assignment: MachineAssignment): void {
    this.selectedAssignment = assignment;
    this.showEditor = 'assignment';
    this.sidenav.open();
  }

  public getAlertDetail(record: FloorAlert): void {
    // failed inspection
    if (record.type === 1 && record.inspectionTicketId) {
      this.selectedInspectionTicketId = record.inspectionTicketId;
      this.showEditor = 'failed-inspection';
    }
    else if (record.floorMachineId) {
      //Open the machine
      this.selectedMachineId = record.floorMachineId;
      this.showEditor = 'machine';
    }
    else {
      //Open the department
      this.selectedBuilding = record.floorBuilding;
      this.showEditor = 'departmentDetail';
    }

      this.sidenav.open();
  }

  ngOnInit(): void {
    this.getData();
    this.floorSvc.search().subscribe(result => {
      this.departments = result.results;
    });
  }
}
