import { Contact } from "./contact";

export interface Address {

    addressId: string;

    nickname: string;
    streetAddress: string;
    streetAddress2: string;
    city: string;
    state: string;
    postalCode: string;
    phoneNumber: string;
    faxNumber: string;

    contactId?: string;
    contact?: Contact;
}

export function printAddress(address: Address) {
    let out = `${address.streetAddress}
${address.streetAddress2}
${address.city}, ${address.state} ${address.postalCode}`;
    if (address.phoneNumber) out += `\nTel. ${address.phoneNumber}`
    if (address.faxNumber) out += `\nFax: ${address.faxNumber}`;
    return out;
}