import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { NavigationService } from '../../../common/services/navigation.service';
import { SearchList } from '../../../common/components/search-list/search-list';
import { WorkOrder } from '../../resources/work-order';
import { WorkOrderService } from '../../services/work-order.service';
import { UtilityService } from '../../../common/services/utility.service';
import { OrderStatus } from '../../../order/resources/order';
import { UserService } from '../../../common/services/user.service';
import { PlanningTicket } from '../../resources/planning-ticket';
import { PlanningService } from '../../services/planning.service';

@Component({
  selector: 'planning-list',
  templateUrl: './planning-list.component.html',
  styleUrls: ['./planning-list.component.less']
})
export class PlanningListComponent extends SearchList<PlanningTicket> {

  constructor(navService: NavigationService, private service: PlanningService, private router: Router, private userSvc: UserService) {
    super('PlanningListComponent');
    navService.setPageTitle("Planning");
  }
  
  search(): void {
    this.results = null;
    this.service.search(this.managerView, this.searchString, this.pageNumber, this.sortTerm, this.sortDirection).subscribe(results => {
      this.searchResults = results;
      this.results = results.results
    });
  }

  onSelect(record: PlanningTicket): void {
    this.router.navigate(['/planning', record.planningTicketId]);
  }

  weeksLeft(date: string, abs: boolean): number {
    if (date == null) return null;

    return UtilityService.getWeeksRemaining(new Date(date), abs);
  }

  getRequiredColor(date: string): string {
    return UtilityService.getDateRequiredColor(date);
  }

  getStatusColorClass(status: number): string {
    return OrderStatus.getStatusColorClass(status);
  }

  getStatusText(status: number): string {
    return OrderStatus.getStatusText(status);
  }

  public hasPermission(moduleName: string): boolean {
    return this.userSvc.canAccess(moduleName);
  }

}
