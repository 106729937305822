<loading *ngIf="!record" content="Saving..."></loading>
<loading *ngIf="loading" content="Saving..."></loading>

<div class="position-fixed d-flex" style="bottom: 25px; right: 20px; z-index: 1030">
  <button mat-fab color="error" class="extended-fab-button bg-success text-white" *ngIf="done && !loading"
      (click)="finish()">
    <mat-icon>check</mat-icon>
    <span class="extended-fab-button__text">Complete</span>
  </button>
</div>


<div class="row p-3 w-100" *ngIf="record">
  <div class="col-5">
    <div class="row border rounded m-1 py-2">
      <div class="col-12">
        <mat-card-title>Inspection Details</mat-card-title>
      </div>
      <div class="col-6">
        <mat-form-field class="w-100" appearance="outline">
          <mat-label>Customer</mat-label>
          <input label="Customer" disabled matInput
            [value]="record.machineAssignment.workOrder.order.customer.businessName" type="text">
        </mat-form-field>
      </div>
      <div class="col-6">
        <mat-form-field class="w-100" appearance="outline">
          <mat-label>Work Order Number</mat-label>
          <input disabled matInput [value]="record.machineAssignment.workOrder.workOrderNumber" type="text">
        </mat-form-field>
      </div>
      <div class="col-6">
        <mat-form-field class="w-100" appearance="outline">
          <mat-label>Part Number</mat-label>
          <input class="font-weight-bold" disabled matInput
            [value]="record.machineAssignment.workOrder.product.partNumber + 'Rev. ' + record.machineAssignment.workOrder.product.revision"
            type="text">
        </mat-form-field>
      </div>
      <div class="col-6">
        <mat-form-field class="w-100" appearance="outline">
          <mat-label>Operation</mat-label>
          <input disabled matInput [value]="getStation(record.machineAssignment.operation.stationId)?.name" type="text">
        </mat-form-field>
      </div>
      <div class="col-6">
        <mat-form-field class="w-100 chip-holder" appearance="outline">
          <mat-label>Inspection Type</mat-label>
          <input disabled matInput [value]="' '" type="text">
          <mat-chip class="text-white" [ngClass]="{
                  'bg-first-part': record.inspectionType == 0 && !record.requiresReport,
                  'bg-fair': record.inspectionType == 0 && record.requiresReport,
                  'bg-lot-inspection': record.inspectionType == 1
                  }">
            <i ngPrefix class="mr-1 fas" [ngClass]="{
                     'fa-box': record.inspectionType == 0 && !record.requiresReport,
                     'fa-box-check': record.inspectionType == 0 && record.requiresReport,
                     'fa-boxes': record.inspectionType == 1
                     }">
            </i>
            {{ getInspectionTypeText(record) }}
          </mat-chip>
        </mat-form-field>
      </div>
      <div class="col-6">
        <mat-form-field class="w-100 chip-holder" appearance="outline">
          <mat-label>Inspection Method</mat-label>
          <input disabled matInput [value]="' '" type="text">
          <mat-chip class="text-white" [ngClass]="{
                  'bg-cmm': record.isCMM,
                  'bg-non-cmm': !record.isCMM
                  }">
            <i ngPrefix class="mr-1 fas" [ngClass]="{
                     'fa-terminal': record.isCMM,
                     'fa-user-check': !record.isCMM
                     }">
            </i>
            {{ getInspectionMethodText(record) }}
          </mat-chip>
        </mat-form-field>
      </div>
    </div>
  </div>
  <div class="col-7">
    <div class="border m-1 px-3 py-2 rounded">
      <mat-card-title>Part Drawings/Documents</mat-card-title>
      <p class="text-center text-muted font-italic"
        *ngIf="record.machineAssignment.workOrder.product?.documents?.length==0">-- No Documents --</p>
      <file-display-grid [files]="record.machineAssignment.workOrder.product.documents" [editable]="false">
      </file-display-grid>
    </div>
  </div>
</div>
<div class="row w-100 p-3" *ngIf="record">
  <div class="col-4">
    <div>
      <div class="font-weight-bold overline mb-1">
        <span class="text-success">{{ passedAmount }}</span> items passed
      </div>
      <div class="font-weight-bold overline mb-1">
        <span class="text-danger">{{ failedAmount }}</span> items failed
      </div>
    </div>
    <mat-selection-list #selectionList class="border rounded pt-0" style="overflow-y: scroll; height: 45vh;"
        (selectionChange)="selectionList.deselectAll(); $event.source.selectedOptions.select($event.option);">
    <mat-list-option *ngFor="let status of record.inspectedItems; index as i" class="border-bottom" [class.selected-item]="i === selectedItemIndex" (click)="selectPart(i)" style="height: auto !important; padding: 10px 0;">
      <div class="d-flex align-items-center">
        <div class="font-weight-bold text-muted mr-2" style="order: -1;">
          #{{ i + 1 }}
        </div>
        <div>
          <div mat-line class="font-weight-bold overline" [class.text-success]="status.approved" [class.text-danger]="!status.approved">
            {{ status.approved ? 'Approved' : 'Rejected' }}
          </div>
          <div mat-line class="font-weight-bold overline" [class.text-danger]="!status.disposition" [class.text-muted]="status.disposition !== undefined" *ngIf="!status.approved">
            <span *ngIf="status.disposition === undefined || status.disposition === null">Action Required</span>
            <span *ngIf="status.disposition === 0">Minor Repair ({{ status.reworkWorkflowSteps?.length + ' Step' + (status.reworkWorkflowSteps?.length === 1 ? '' : 's') }})</span>
            <span *ngIf="status.disposition === 2">Rework ({{ status.reworkWorkflowSteps?.length + ' Step' + (status.reworkWorkflowSteps?.length === 1 ? '' : 's') }})</span>
            <span *ngIf="status.disposition === 4">Use As Is</span>
            <span *ngIf="status.disposition === 1">Scrap/Remake</span>
            
          </div>
          <div *ngIf="(status.disposition === 0 || status.disposition === 2) && getLinkedIndex(i) === null">
            <button mat-stroked-button class="overline button-small mr-1" [matTooltip]="'Copy Rework Steps'" [matTooltipPosition]="'after'" (click)="copySteps($event, i)">
              <mat-icon matPrefix class="mr-1" [class.text-muted]="copiedStepsIndex !== i" [class.text-primary]="copiedStepsIndex === i">content_copy</mat-icon>
              Copy
            </button>
            <button mat-stroked-button class="overline button-small" [matTooltip]="'Paste Rework Steps'" [matTooltipPosition]="'after'" [disabled]="copiedStepsIndex === null" (click)="pasteSteps($event, i)">
              <mat-icon matPrefix class="mr-1" [class.text-muted]="copiedStepsIndex !== null">content_paste</mat-icon>
              Paste
            </button>
          </div>
          <div>
            <mat-icon class="ml-1" *ngIf="getLinkedIndex(i) !== null" [matTooltip]="'Linked to workflow of step #' + (getLinkedIndex(i) + 1) + '. Click to remove'" [matTooltipPosition]="'after'">
              link
            </mat-icon>
          </div>
        </div>
        <div mat-line class="small text-muted ml-auto" style="font-size: .7em !important" *ngIf="!status.approved">
          <div *ngIf="status.note">Has Note</div>
        </div>
      </div>
    </mat-list-option>
  </mat-selection-list>
  </div>
  <div class="col-8" *ngIf="!selectedItem.approved">
    <div class="border rounded p-3" style="height: 100%">
      <div class="row" *ngIf="!selectedItem.approved" style="height: 100%">
        <div class="col-4" style="height: 45vh; overflow-y: scroll;">
          <div class="font-weight-bold mt-2 mb-1" style="font-size: 0.65em">Disposition</div>
          <mat-radio-group [ngModel]="selectedItem.disposition" (change)="onRadioGroupChange(selectedItem, $event)">
            <mat-radio-button class="mr-2" [value]="0">Minor Repair</mat-radio-button>
            <mat-radio-button [value]="2">Rework</mat-radio-button>
            <mat-radio-button [value]="4">Use As Is</mat-radio-button>
            <mat-radio-button [value]="1">Scrap/Remake</mat-radio-button>
          </mat-radio-group>
          <div class="font-weight-bold text-muted mb-2">Item #{{ selectedItemIndex + 1}}</div>
          <div class="font-weight-bold mb-1" style="font-size: 0.65em">Note</div>
          <div class="mb-3" *ngIf="selectedItem.note">{{selectedItem.note}}</div>
          <div class="small mt-1 text-muted font-italic" *ngIf="selectedItem.disposition === 0 || selectedItem.disposition === 2">
            Please add the workflow steps necessary for repair/rework on the right.
          </div>
          <div class="font-weight-bold mt-2 mb-1" style="font-size: 0.65em" *ngIf="record.isCMM">Dimensions</div>
          <div *ngFor="let measurement of selectedItem.measuredDimensions; index as i" class="border rounded px-3 py-2 mb-2 position-relative" style="font-size: 13px;">
            <div class="small font-weight-bold text-muted" style="position: absolute;
            top: -8px;
            left: 10px;
            background: white;
            padding: 0 4px;">#{{i + 1}}: {{measurement.name}}</div>
            <div>
              <b>S/B:</b> {{record.expectedCMMMeasurements[i].value}}{{measurement.unit}}
            </div>
            <div>
              <b>Is:</b> {{measurement.value}}{{measurement.unit}}
            </div>
          </div>
        </div>
        <div class="col-8 border-left bg-light text-muted font-italic d-flex justify-content-center align-items-center" style="margin: -1rem 0;" *ngIf="selectedItem.disposition === 0">
          Part will be scrapped. No further action is required.
        </div>
        <div class="col-8 border-left" style="margin: -1rem 0; padding: 0 !important; height: 45vh; overflow-y: scroll;" *ngIf="(status.disposition === 0 || status.disposition === 2)">
          <mat-list *ngIf="workflowEditorState === 'list'">
            <mat-list-item style="opacity: 0.75; pointer-events: none;" class="border-bottom" *ngFor="let item of workflowBefore">
              <div class="mr-2 d-flex" style="order: -1;">
                <i [title]="!item?.runIsPerPart?'Per Job':''" [ngClass]="{'fa-cogs':!item?.runIsPerPart}" class="fas fa-fw fa-lg text-muted"></i>
                <i [title]="getStation(item)?.isOutsourceStep?'Outsourced':''" [ngClass]="{'fa-sign-out-alt':getStation(item)?.isOutsourceStep}" class="fas fa-fw fa-lg text-muted"></i>
                <i [title]="getStation(item)?.isPainting?'Painting':''" [ngClass]="{'fa-fill-drip':getStation(item)?.isPainting}" class="fas fa-fw fa-lg text-muted"></i>
                <i [title]="item.outsourceIsFinal?'Tentative':''" [ngClass]="{'fa-question':(getStation(item)?.isOutsourceStep && !item.outsourceIsFinal)}" class="fas fa-fw fa-lg text-muted"></i>
              </div>
              <div mat-line>{{getStation(item.stationId)?.name}}</div>
            </mat-list-item>
            <!-- New steps -->
            <mat-list-item class="border-bottom" *ngFor="let item of selectedItem.reworkWorkflowSteps; index as i">
              <div class="mr-2 d-flex" style="order: -1;">
                <i [title]="!item?.runIsPerPart?'Per Job':''" [ngClass]="{'fa-cogs':!item?.runIsPerPart}" class="fas fa-fw fa-lg text-muted"></i>
                <i [title]="getStation(item)?.isOutsourceStep?'Outsourced':''" [ngClass]="{'fa-sign-out-alt':getStation(item)?.isOutsourceStep}" class="fas fa-fw fa-lg text-muted"></i>
                <i [title]="getStation(item)?.isPainting?'Painting':''" [ngClass]="{'fa-fill-drip':getStation(item)?.isPainting}" class="fas fa-fw fa-lg text-muted"></i>
                <i [title]="item.outsourceIsFinal?'Tentative':''" [ngClass]="{'fa-question':(getStation(item)?.isOutsourceStep && !item.outsourceIsFinal)}" class="fas fa-fw fa-lg text-muted"></i>
              </div>
              <div mat-line class="font-weight-bold" [class.text-info]="item.stepType == 1" [class.text-success]="item.stepType == 2">{{getStation(item.stationId)?.name}}</div>
              <div class="ml-auto d-flex">
                <button mat-flat-button class="bg-primary mr-1" (click)="editWorkflowStep(i)">
                  <mat-icon>edit</mat-icon>
                </button>
                <button mat-flat-button class="bg-danger text-white" (click)="deleteWorkflowStep(i)">
                  <mat-icon>close</mat-icon>
                </button>
              </div>
            </mat-list-item>
            <!--  -->
            <mat-list-item class="cursor-pointer text-center">
              <button mat-flat-button class="bg-primary text-white add-button d-block w-100" (click)="workflowEditorState = 'add'">
                <mat-icon matPrefix>add</mat-icon>
                Add Workflow Step
              </button>
            </mat-list-item>
            <mat-list-item style="opacity: 0.75; pointer-events: none;" class="border-bottom" *ngFor="let item of workflowAfter">
              <div class="mr-2 d-flex" style="order: -1;">
                <i [title]="!item?.runIsPerPart?'Per Job':''" [ngClass]="{'fa-cogs':!item?.runIsPerPart}" class="fas fa-fw fa-lg text-muted"></i>
                <i [title]="getStation(item)?.isOutsourceStep?'Outsourced':''" [ngClass]="{'fa-sign-out-alt':getStation(item)?.isOutsourceStep}" class="fas fa-fw fa-lg text-muted"></i>
                <i [title]="getStation(item)?.isPainting?'Painting':''" [ngClass]="{'fa-fill-drip':getStation(item)?.isPainting}" class="fas fa-fw fa-lg text-muted"></i>
                <i [title]="item.outsourceIsFinal?'Tentative':''" [ngClass]="{'fa-question':(getStation(item)?.isOutsourceStep && !item.outsourceIsFinal)}" class="fas fa-fw fa-lg text-muted"></i>
              </div>
              <div mat-line>{{getStation(item.stationId)?.name}}</div>
            </mat-list-item>
          </mat-list>
          <station-list *ngIf="workflowEditorState === 'add'" (selected)="addStep($event)"></station-list>
          <div *ngIf="workflowEditorState === 'edit'" class="bg-secondary py-1 d-flex justify-content-center">
            <div class="font-weight-bold mr-1">
              Assigned Department
            </div>
            <department-dropdown [editable]="true" [selectedDepartment]="getAssignment(editingStep)"
                (selectedDepartmentChange)="saveAssignment(editingStep, $event)"></department-dropdown>
          </div>
          <workflow-step-edit *ngIf="workflowEditorState === 'edit'" [step]="editingStep" [isRepair]="true" (save)="onStepSave($event)" ([workflowDirty])="workflowDirty">
          </workflow-step-edit>
        </div>
      </div>
    </div>
  </div>
  <div class="col-8" *ngIf="selectedItem.approved">
    <div class="border rounded bg-light text-muted font-italic d-flex justify-content-center align-items-center" style="height: 100%">
      Part passed inspection. No further action is required.
    </div>
  </div>
</div>