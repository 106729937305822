import { Component, Input } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { Router } from '@angular/router';
import { RMATicket } from '../../../rma/resources/rma-ticket';
import { RMAService } from '../../../rma/service/rma.service';
import { Order, OrderStatus } from '../../resources/order';

@Component({
  selector: 'order-rma-detail',
  templateUrl: './order-rma-detail.component.html',
  styleUrls: ['./order-rma-detail.component.less']
})
export class OrderRmaDetailComponent {

  @Input() detail: Order;
  @Input() sidenav: MatSidenav;

  public lotOrders: {[key: string]: any} = {}


  constructor(
    public router: Router,
    public rmaSvc: RMAService
  ) { }


  public get ticket() {
    return this.detail && this.detail.rmaTicket;
  }

  public get lot() {
    return this.detail && this.detail.rmaDispositionLot;
  }

  public get otherLots() {
    return this.detail && this.detail.rmaTicket && this.detail.rmaTicket.dispositionLots.filter(l => l.rmaDispositionLotId !== this.lot.rmaDispositionLotId);
  }

  public getStatusColorClass(status: number): string {
    return OrderStatus.getStatusColorClass(status);
  }

  public getStatusText(status: number): string {
    return OrderStatus.getStatusText(status);
  }

  public goToOrder(orderId: string) {
    this.router.navigate(['/order', orderId])
  }

  ngOnInit() {
    this.otherLots && this.otherLots.forEach(async (l) => {
      this.lotOrders[l.rmaDispositionLotId] = 'loading';
      this.lotOrders[l.rmaDispositionLotId] = await this.rmaSvc.getLotOrderSegment(l).toPromise();
    })
  }


  close() {
    this.sidenav.close();
  }

}
