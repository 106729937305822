<mat-selection-list [multiple]="false"
    *ngIf="tickets">
    <ng-container *ngFor="let grouping of tickets; last as last">
        <div #scrollAnchor class="height: 0px;"></div>
        <div mat-subheader class="list-header" matRipple [matRippleColor]="getRippleColor(grouping)"
            (click)="scrollAnchor.scrollIntoView({ behavior: 'smooth' })" [ngClass]="{
              'bg-light': grouping.day && !isToday(grouping.day),
              'alert-info': isToday(grouping.day),
              'text-danger': grouping.overdue,
              'alert-danger': grouping.overdue,
              'alert-warning': !grouping.day && !grouping.overdue
            }">
            <ng-container *ngIf="grouping.day">
                <mat-icon class="mr-1" *ngIf="isToday(grouping.day)">today</mat-icon>
                <mat-icon class="mr-1" *ngIf="!isToday(grouping.day)">date_range</mat-icon>
                {{ grouping.day | date: 'EEEE, MMM d' }}
                <span class="badge badge-info ml-1" *ngIf="isToday(grouping.day)">
                    Today
                </span>
            </ng-container>
            <ng-container *ngIf="grouping.overdue">
                <mat-icon class="mr-1">warning</mat-icon>
                {{ direction === 'incoming' ? 'Overdue' : 'Late' }}
            </ng-container>
            <ng-container *ngIf="!grouping.day && !grouping.overdue">
                <mat-icon class="mr-1">event_busy</mat-icon>
                No {{ direction === 'incoming' ? 'Arrival' : 'Departure' }} Date Assigned
            </ng-container>
        </div>
        <mat-divider></mat-divider>
        <ng-container *ngFor="let ticket of grouping.tickets">
            <mat-list-option matRipple matRippleColor="rgba(0,0,0,.04)" #option class="ticket-line"
                [class.ticket-line-selected]="option.selected"
                (click)="scrollAnchor.scrollIntoView({ behavior: 'smooth' })">
                <div class="row">
                    <div class="col-5 col-xl-3">
                        <div mat-line>
                            <b>{{ getTicketNumber(ticket) }}</b>
                            <span class="badge badge-danger ml-1" *ngIf="grouping.overdue"
                                [matTooltip]="'Due on ' + (ticket.arrivalDate | date: 'short')"
                                matTooltipPosition="after">
                                {{getDaysOverdue(ticket)}} Day{{getDaysOverdue(ticket) > 1 ? 's' : ''}} Overdue
                            </span>
                        </div>
                        <div mat-line class="text-muted" *ngIf="direction === 'incoming'" style="white-space: pre-wrap;">
                            <span *ngIf="ticket.originBuilding">
                                Internal shipment from <b>{{ticket.originBuilding.name}}</b>
                            </span>
                            <span *ngIf="ticket.purchaseOrder && ticket.purchaseOrder.vendor">
                                Vendor shipment from <b>{{ticket.purchaseOrder.vendor.name}}</b>
                            </span>
                            <span *ngIf="ticket.rmaTicket && ticket.rmaTicket.customer">
                                Customer RMA shipment from <b>{{ticket.rmaTicket.customer.businessName}}</b>
                            </span>
                        </div>
                        <div mat-line class="text-muted" *ngIf="direction === 'outgoing'" style="white-space: pre-wrap;">
                            <span *ngIf="ticket.destinationBuilding">
                                Internal shipment to <b>{{ticket.destinationBuilding.name}}</b>
                            </span>
                            <span *ngIf="ticket.purchaseOrder && ticket.purchaseOrder.vendor && ticket.inventoryItem.productId">
                                Shipping part to vendor <b>{{ticket.purchaseOrder.vendor.name}}</b> for processing
                            </span>
                            <span *ngIf="ticket.workOrder && ticket.inventoryItem.productId && (!ticket.purchaseOrder) && ticket.workOrder.order.customer">
                                Shipping final product to customer <b>{{ticket.workOrder.order.customer.businessName}}</b>
                            </span>
                        </div>
                        <div mat-line>
                            <item-type-chip [iconOnly]="true" [item]="ticket.inventoryItem"></item-type-chip>
                            {{ ticket.inventoryItem.name }}
                        </div>
                    </div>
                    <div class="col-4 col-xl-2">
                        <ng-container *ngIf="ticket.employee">
                            <div mat-line class="font-weight-bold text-uppercase unassigned-indicator d-flex align-items-center">
                                <mat-icon class="mr-1 text-muted">assignment_ind</mat-icon>
                                Assigned
                            </div>
                            <div mat-line class="text-muted small d-flex align-items-center" *ngIf="ticket.employee">
                                {{ ticket.employee.fullName }}
                            </div>
                        </ng-container>
                        <div mat-line
                            class="font-weight-bold text-danger text-uppercase unassigned-indicator d-flex align-items-center"
                            *ngIf="!ticket.employee">
                            <mat-icon class="mr-1">person_off</mat-icon>
                            Unassigned
                        </div>
                    </div>
                    <div class="col-3 col-xl-1">
                        <div mat-line class="small" *ngIf="ticket.shippingCarrier">
                            Shipping via <b>{{ ticket.shippingCarrier.name }}</b>
                        </div>
                        <div mat-line *ngIf="ticket.shippingCarrier && ticket.trackingNumber">
                            <button class="small btn btn-sm btn-link d-block" title="Open Tracking"
                                (click)="openTracking(ticket)">
                                <img [attr.src]="getShippingCarrierIcon(ticket)" />
                                {{ticket.trackingNumber}}
                                <i class="fas fa-fw fa-lg fa-external-link-alt text-muted pl-2"></i>
                            </button>
                        </div>
                    </div>
                    <div class="text-muted action-click-hint">
                        Click for actions...
                    </div>
                    <div class="col col-xl-6 action-bar-outer px-0" [class.action-bar-selected]="option.selected">
                        <div class="d-flex align-items-center action-bar">
                            <button mat-icon-button class="action-bar-close mr-auto" (click)="$event.stopPropagation(); selectionList.deselectAll()">
                                <mat-icon>close</mat-icon>
                            </button>
                            <button 
                            mat-raised-button 
                            matRippleColor="rgba(0,0,0,.04)" 
                            class="text-white bg-primary"
                            (click)="$event.stopPropagation(); finalize.emit(ticket)">
                                <mat-icon matPrefix [ngClass]="{
                                    'fa-rotate-90': direction === 'incoming',
                                    'fa-rotate-270': direction === 'outgoing'
                                }">
                                {{ direction === 'incoming' ? 'login' : 'logout' }}
                                </mat-icon>
                                {{ direction === 'incoming' ? 'Check In' : 'Check Out' }}
                            </button>
                            <button 
                            mat-raised-button 
                            matRippleColor="rgba(0,0,0,.04)"
                            *ngIf="!ticket.employee && userIsManager()"
                            class="text-white bg-danger"
                            (click)="$event.stopPropagation(); assignTicket(ticket)">
                                <mat-icon matPrefix>person_add_alt_1</mat-icon>
                                Assign
                            </button>
                            <button 
                            mat-raised-button 
                            matRippleColor="rgba(0,0,0,.04)" 
                            class="text-white bg-success"
                            *ngIf="grouping.overdue || !grouping.day"
                            (click)="$event.stopPropagation(); rescheduleTicket(ticket)">
                                <mat-icon matPrefix>edit_calendar</mat-icon>
                                Reschedule
                            </button>
                            <button 
                            mat-raised-button 
                            matRippleColor="rgba(0,0,0,.04)" 
                            class="text-white bg-info"
                            (click)="$event.stopPropagation(); openTicketInfo.emit(ticket)">
                                <mat-icon matPrefix>assignment</mat-icon>
                                Ticket Details
                            </button>
                        </div>
                    </div>
                </div>
            </mat-list-option>
            <mat-divider></mat-divider>
        </ng-container>
    </ng-container>
</mat-selection-list>

<ng-template #assignUserDialogTemplate let-data>
    <h1 mat-dialog-title>Assigning user to ticket <b>{{getTicketNumber(data.ticket)}}</b></h1>
    <div mat-dialog-content>
        <div class="form-group">
        <label>Select a user</label>
        <employee-select [editable]="true" [(employee)]="data.employee"></employee-select>
        </div>
    </div>
    <div mat-dialog-actions>
        <button mat-button [mat-dialog-close]="false">Cancel</button>
        <button mat-button [mat-dialog-close]="data.employee">Save</button>
    </div>
</ng-template>


<ng-template #rescheduleDialogTemplate let-data>
    <h1 mat-dialog-title>Rescheduling {{ direction === 'incoming' ? 'expected arrival' : 'departure' }} date for <b>{{getTicketNumber(data.ticket)}}</b></h1>
    <div mat-dialog-content>
        <div class="form-group">
        <label>New Date</label>
        <date-picker [editable]="true" [(date)]="data.date"></date-picker>
        </div>
    </div>
    <div mat-dialog-actions>
        <button mat-button [mat-dialog-close]="false">Cancel</button>
        <button mat-button [mat-dialog-close]="data.date">Save</button>
    </div>
</ng-template>