import { Component, OnInit, Input, TemplateRef, ViewChild } from '@angular/core';
import { Order, OrderStatus } from '../../../resources/order';
import { ControlContainer, NgForm } from '@angular/forms';
import { OrderDetailService } from '../order-detail.service';
import { MatDialog } from '@angular/material/dialog';
import { Customer, CustomerContact } from '../../../../customer/resources/customer';
import { UtilityService } from '../../../../common/services/utility.service';

@Component({
  selector: 'order-detail-form',
  templateUrl: './order-detail-form.component.html',
  styleUrls: ['./order-detail-form.component.less'],
  viewProviders: [ { provide: ControlContainer, useExisting: NgForm } ]
})
export class OrderDetailFormComponent implements OnInit {

  constructor(
    public service: OrderDetailService,
    private dialog: MatDialog
  ) { }

  public get record() { return this.service.order }
  public get editing() { return this.service.editing }

  ngOnInit(): void {
  }

  public getStatusColorClass(status: number): string {
    return OrderStatus.getStatusColorClassChip(status);
  }

  public getStatusText(status: number): string {
    return OrderStatus.getStatusText(status);
  }

  @ViewChild('statusNoteDialogTemplate') statusNoteDialogTemplate: TemplateRef<any>;
  public viewStatusNote() {
    this.dialog.open(this.statusNoteDialogTemplate);
  }

  public getCustomerContactName = (c: CustomerContact | null) => c?.contact?.name ?? '';

  public generateCustomerContact(name: string): CustomerContact {
    return {
      customerContactId: null,
      contactId: null,
      customerId: null,
      contact: {
        contactId: null,
        name,
        title: '',
        cellNumber: '',
        email: '',
        personalEmail: '',
        faxNumber: '',
        note: '',
        phoneExtension: '',
        phoneNumber: ''
      },
    }
  }

  public customerContactAdded(contact: CustomerContact) {
    const customerContactId = UtilityService.newGuid();
    const contactId = UtilityService.newGuid();
    contact.contactId = contactId;
    contact.contact.contactId = contactId;
    contact.customerContactId = customerContactId;
    contact.customerId = this.record.customerId;
    this.service.addNewId(customerContactId);
    this.service.addNewId(contactId);
    this.record.customerContact = contact;
    this.record.customerContactId = customerContactId;
  }

  public get isSales(): boolean {
    return this.record.discriminator === 'RFQ' || this.record.discriminator === 'Quote';
  }

  public get isEstimating(): boolean {
    return this.record.discriminator === 'Estimate' || this.record.discriminator === 'RMAEstimate';
  }

  public onCustomerSelected(customer: Customer) {
    this.record.customerId = customer?.customerId;
    this.record.customerContact = null;
    this.record.customerContactId = null;
    if (customer.salesPerson && this.isSales) {
      this.record.assignedTo = customer.salesPerson.userId;
      this.record.assignedUser = customer.salesPerson;
    } else if (customer.salesPerson && this.isEstimating) {
      this.record.salesPerson = customer.salesPerson;
      this.record.salesPersonId = customer.salesPerson.userId;
    }
  }

}
