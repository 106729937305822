<div class="form-group position-relative search mt-2 mb-2 ml-3 mr-3">
  <div *ngIf="showManagerBar" class="float-right small text-muted mb-2 mt-0 d-flex">
    <span class="pr-2">Just Mine</span>
    <mat-slide-toggle [checked]="managerView" (change)="toggleMyView()"></mat-slide-toggle>
    <span class="pl-2">Manager View</span>
  </div>
  <div class="input-group">
    <input type="text" (keyup.enter)="doSearch()" [(ngModel)]="searchString" class="form-control form-control-sm" aria-label="Search Customers" aria-describedby="basic-addon2">
    <div class="input-group-append">
      <button class="btn btn-search btn-sm btn-default border pl-4 pr-4" type="button" (click)="doSearch()">Search</button>
    </div>
    <a href="javascript:void(0);" (click)="resetSearch()" title="Reset Search" class="reset badge position-absolute badge-pill badge-secondary text-white float-right"><i class="fas fa-fw fa-times"></i></a>
  </div>
</div>
