import { Component, Input, Output, EventEmitter, ViewChild, OnInit } from '@angular/core';
import { IResizeEvent } from 'angular2-draggable/lib/models/resize-event';
import { IPosition } from 'angular2-draggable';
import { NgModel } from '@angular/forms';
import { UtilityService } from '../../../common/services/utility.service';
import { MachineAssignment } from '../../../planning/resources/machine-assignment';
import { FloorService } from '../../services/floor.service';

@Component({
  selector: 'floor-layout-block',
  templateUrl: './floor-layout-block.component.html',
  styleUrls: ['./floor-layout-block.component.less']
})
export class FloorLayoutBlockComponent implements OnInit {
  @Input() block: any;
  @Input() editable: boolean = true;
  @Output() onBlockChanged: EventEmitter<BlockData> = new EventEmitter<BlockData>();
  @Output() click: EventEmitter<any> = new EventEmitter<any>();
  @ViewChild('name', { static: true }) name: NgModel;
  public statusColor: string = '#ccc';
  public statusText: string = '';
  public canDrop: boolean = false;
  public isDropping: boolean = false;

  constructor(private utilitySvc: UtilityService, private floorSvc: FloorService) { }

  public checkName(_: any) {
    if (this.name.invalid) {
      this.name.control.markAsTouched();
      this.utilitySvc.showAlert("A Valid Machine Name is Required", "<p>Please add a machine name before saving.</p>");
    }
  }

  bubbleClick(): void {
    this.click.emit(this.block);
  }

  public onResize(e: IResizeEvent): void {
    this.block.width = e.size.width;
    this.block.height = e.size.height;

    this.notify();
  }

  public onMove(e: IPosition) {
    this.block.posX = e.x;
    this.block.posY = e.y;

    this.notify();
  }

  public notify(): void {
    this.onBlockChanged.emit(<BlockData>{
      name: this.block.name,
      width: this.block.width,
      height: this.block.height,
      posX: this.block.posX,
      posY: this.block.posY,
    });
  }

  public onDrop(_event: any) {
    // index is of the element on which the item is dropped
    if (this.canDrop) {
      var data: MachineAssignment = JSON.parse(sessionStorage.getItem('machine-assignment-list-drag-data')) as MachineAssignment;

      if (data != null && this.block.machineId != data.machineId) {
        //        todo
        sessionStorage.removeItem('machine-assignment-list-drag-data');
        data.machineId = this.block.machineId;

        this.floorSvc.saveAssignment(data).subscribe(_ => {
          this.click.emit(this.block);
          this.removeDropIndicator();
        });
      }
    }
    this.removeDropIndicator();
  }

  public removeDropIndicator(): void {
    this.canDrop = false;
    this.isDropping = false;
  }

  public allowDrop($event: any) {
    $event.preventDefault();
    this.isDropping = true;
    const data: MachineAssignment = JSON.parse(sessionStorage.getItem('machine-assignment-list-drag-data')) as MachineAssignment;

    if (data != null) {

      this.canDrop = data.operation.stationId == this.block.stationId;
    }
  }

  ngOnInit(): void {
    if (this.block.status != null) {
      switch (this.block.status) {
        case 0:
          this.statusColor = "#595";
          break;
        case 1:
          this.statusColor = "#ed2";
          this.statusText = "M";
          break;
        case 2:
          this.statusColor = "#e22";
          this.statusText = "-";
          break;
        case 3:
          this.statusColor = "#fb0";
          this.statusText = "!";
          break;
      }
    }
    else if (this.block.alertCount != null) {
      if (this.block.alertCount > 0) {
        this.statusColor = "#fb0";
        this.statusText = this.block.alertCount;
      }
      else {
        this.statusColor = "#595";
      }
    }
  }
}

export interface BlockData {
  name: string;
  width: number;
  height: number;
  posX: number;
  posY: number;
}
