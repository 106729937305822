import { Component, Input, Output, EventEmitter, OnInit, OnChanges, SimpleChanges } from '@angular/core';
import { FloorMachine } from '../../resources/building';
import { StationService } from '../../../order/services/station.service';
import { Station } from '../../../order/resources/station';

@Component({
  selector: 'machine-data-form',
  templateUrl: './machine-data-form.component.html',
  styleUrls: ['./machine-data-form.component.less']
})
export class MachineDataFormComponent implements OnInit, OnChanges {
  @Input() machine: FloorMachine;
  @Output() machineChange: EventEmitter<FloorMachine> = new EventEmitter<FloorMachine>();
  public machineStations: Station[] = null;
  public machineStatuses: any[];

  constructor(private stationSvc: StationService) {
    this.machineStatuses = FloorMachine.statuses;
  }

  public changeType(newStation: any): void {
    this.machine.stationId = newStation.value.stationId;
    this.machine.operationType = newStation.value;
  }

  public compareStationIds(option: Station, value: Station): boolean {
    return option.stationId == value.stationId;
  }

  private setStations(): void {
    if (this.stationSvc.loaded) {
      this.machineStations = this.stationSvc.stationList.filter(s => s.isOutsourceStep == false);

      if (this.machine.stationId == null) {
        this.machine.stationId = this.stationSvc.stationList.find(i => i.name.toLowerCase() == 'other').stationId;
      }
      this.machine.operationType = this.stationSvc.stationList.find(i => i.stationId == this.machine.stationId);
    }
  }

  public getStatusText(status: number): string {
    return FloorMachine.getStatusText(status);
  }

  ngOnChanges(_: SimpleChanges): void {
    this.setStations();
  }

  ngOnInit(): void {
    this.stationSvc.stationsLoaded.asObservable().subscribe(_ => this.setStations());
    if (this.stationSvc.loaded) {
      this.setStations();
    }
  }
}
