import { Component, OnInit } from "@angular/core";
import { OrderStatusInfoService, DashboardCount } from "./service"
import { ChartDataSet } from '../../../../common/components/chart/chart.component';


// estimateManager
@Component({
  selector: 'cccccc',
  template: `
    <div class="text-center">
      <div *ngIf="data === null" class="waitContainer">
        <mat-spinner [color]="'warn'"></mat-spinner>
      </div>
      <chart *ngIf="data !== null" [options]="{ responsive: true }" width="100%" chartType="polarArea" [dataSet]="dataSet"></chart>
    </div>
  `,
  styleUrls: ['./styles.less']
})
export class WaitingOrdersWidgetComponent implements OnInit {

  public data: DashboardCount[] = null;

  constructor(private service: OrderStatusInfoService) { }

  ngOnInit(): void {
    this.service.getWaitingOrdersData().subscribe(results => this.data = results);
  }

  public get dataSet(): ChartDataSet[] {
    var data = [];

    if (this.data == null) return data;

    data.push(<ChartDataSet>{
      label: "Waiting Orders",
      data: this.data.map(d => d.count),
      dataPointLabels: this.data.map(d => d.type)
    });

    return data;
  }

}

@Component({
  selector: 'bbbbb',
  template: `
    <div class="text-center">
      <div *ngIf="data === null" class="waitContainer">
        <mat-spinner [color]="'warn'"></mat-spinner>
      </div>
      <chart *ngIf="data !== null" [options]="{ responsive: true }" width="100%" chartType="polarArea" [dataSet]="dataSet"></chart>
    </div>
  `,
  styleUrls: ['./styles.less']
})
export class OverdueOrdersWidgetComponent implements OnInit {

  public data: DashboardCount[] = null;

  constructor(private service: OrderStatusInfoService) { }

  ngOnInit(): void {
    this.service.getOverdueOrdersData().subscribe(results => this.data = results);
  }

  public get dataSet(): ChartDataSet[] {
    var data = [];

    if (this.data == null) return data;

    data.push(<ChartDataSet>{
      label: "Overdue Orders",
      data: this.data.map(d => d.count),
      dataPointLabels: this.data.map(d => d.type)
    });

    return data;
  }
}

@Component({
  selector: 'aaaaa',
  template: `
    <div class="text-center">
      <div *ngIf="data === null" class="waitContainer">
        <mat-spinner [color]="'warn'"></mat-spinner>
      </div>
      <chart *ngIf="data !== null" [options]="{ responsive: true }" width="100%" chartType="polarArea" [dataSet]="dataSet"></chart>
    </div>
  `,
  styleUrls: ['./styles.less']
})
export class AtRiskOrdersWidgetComponent implements OnInit {

  public data: DashboardCount[] = null;

  constructor(private service: OrderStatusInfoService) { }

  ngOnInit(): void {
    this.service.getAtRiskOrdersData().subscribe(results => this.data = results);
  }

  public get dataSet(): ChartDataSet[] {
    var data = [];

    if (this.data == null) return data;

    data.push(<ChartDataSet>{
      label: "At Risk Orders",
      data: this.data.map(d => d.count),
      dataPointLabels: this.data.map(d => d.type)
    });

    return data;
  }
}
