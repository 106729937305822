<div class="row mb-2">
  <div class="col-12">
    <h5>Formal Quote</h5>
    <div>
      <button class="btn btn-lg btn-primary" (click)="openQuotePreview()" [disabled]="quoteLoading">
        <i class="fas fa-fw fa-lg fa-file-download" *ngIf="!quoteLoading"></i>
        <i class="fas fa-fw fa-lg fa-spin fa-circle-notch" *ngIf="quoteLoading"></i>
        <span *ngIf="quoteLoading">Loading Formal Quote</span>
        <span *ngIf="!quoteLoading && (!quotePreviewWindow || quotePreviewWindow.closed)">Preview Formal Quote</span>
        <span *ngIf="!quoteLoading && quotePreviewWindow && !quotePreviewWindow.closed">Reload Formal Quote</span>
      </button>
      <span class="text-muted small ml-2" *ngIf="quotePreviewWindow && editing">
        <i class="fas fa-info-circle mr-1"></i>
        The preview window will refresh automatically based on changes to lead time and pricing.
      </span>
    </div>
  </div>
</div>
<div class="row">
  <div class="col-12">
    <mat-form-field class="w-100" appearance="fill">
      <mat-label>Estimate Notes</mat-label>
      <textarea matInput placeholder="Write any exceptions, pricing changes, material subtitutions, etc. here" rows="2" [(ngModel)]="record.estimateNotes" (ngModelChange)="this.onExternalChange()"></textarea>
    </mat-form-field>
  </div>
</div>
<div class="row" *ngIf="stationService.stationsLoaded">
  <div class="col-12" *ngIf="missingQuotes.length > 0">
    <mat-card class="bg-danger text-white p-2 mb-2 small d-flex align-items-center cursor-default" *ngFor="let missingQuote of missingQuotes">
      <mat-icon class="mr-2">warning</mat-icon>
      <div>
        {{ getMissingQuoteClassText(missingQuote.item) }} <b>{{ getMissingQuoteItemName(missingQuote.item) }}</b> is missing quote info and is not contributing to estimated price or lead time.
      </div>
    </mat-card>
  </div>
  <div class="col-6">
    <h5>Quantities & Pricing</h5>
  </div>
  <div class="col-6">
    <h5>Lead Times</h5>
  </div>
</div>
<div *ngIf="loading" class="w-100 d-flex flex-column p-4">
  <div class="row">
    <div class="col-6">
      <mat-spinner [color]="'warn'" class=" mb-3" [diameter]="70"></mat-spinner>
      <div>Loading...</div>
    </div>
    <div class="col-6">
      <mat-spinner [color]="'warn'" class=" mb-3" [diameter]="70"></mat-spinner>
      <div>Loading...</div>
    </div>
  </div>
</div>
<div *ngIf="!loading && orderProducts" class="small text-muted">
  <div *ngFor="let product of orderProducts" [id]="'pricing-select-' + product.productId">
    <div class="row">
      <div class="col-6">
        <quantity-table #quantityTable #quantityTableComponent
            [editable]="editing"
            [quantityEditable]="false"
            [pricingEditable]="true"
            [product]="product"
            [record]="record"
            [breakdown]="getBreakdownForProduct(product)"
            [leadTime]="leadTimeData.item1"
        ></quantity-table>
      </div>
      <div class="col-6">
        <estimate-approval-lead-time-breakdown #leadTimes
          [product]="product"
          [productId]="product.productId"
          [children]="getProductTree(product)"
          [childLeadTimes]="leadTimeData.item2"
          [editing]="editing"
          (foundMissingQuote)="onMissingQuote($event)"
        ></estimate-approval-lead-time-breakdown>
      </div>
    </div>
    <hr>
  </div>
</div>