import { User } from "../../common/resources/user";
import { InventoryItem } from "../../inventory/resources/inventory-item";
import { Material } from "../../order/resources/material";
import { PurchasedItem } from "../../order/resources/purchased-item";
import { Station } from "../../order/resources/station";
import { ShippingTicket } from "../../shipping/resources/shipping-ticket";
import { MaterialBid } from "./materialBid";
import { PurchaseOrder, PurchaseOrderLineItem } from "./purchaseOrder";

export interface Requisition {
    requisitionId: string;
    requisitionNumber: string;
    assignedTo?: string;
    assignedUser?: User;
    createdById: string;
    createdBy: User;
    department: string;
    note: string;
    statusNote: string;
    datePlaced: Date;
    dueDate?: Date;

    status: RequisitionStatus;

    requisitionLineItems: RequisitionLineItem[];

    materialBids: MaterialBid[];
}

export interface RequisitionLineItem {
    readonly name: string;
    requisitionLineItemId: string;
    purchasedItemId: string;
    purchasedItem: PurchasedItem;
    materialId: string;
    material: Material;
    stationId: string;
    station: Station;
    amountRequired: number;
    amountReceived: number;
    dueDate?: Date;
    requisitionId: string;
}

export class RequisitionStatus {
  static AWAITING_INPUT: number = 0;
  static AWAITING_REVIEW: number = 1;
  static REJECTED: number = 2;
  static APPROVED: number = 3;
  static FULFILLED: number = 4;

  static getStatusColorClass(status: number): string {
    switch (status) {
      case RequisitionStatus.AWAITING_INPUT: return 'badge-secondary';
      case RequisitionStatus.AWAITING_REVIEW: return 'badge-info';
      case RequisitionStatus.REJECTED: return 'badge-danger';
      case RequisitionStatus.APPROVED: return 'badge-success';
      case RequisitionStatus.FULFILLED: return 'badge-secondary';
      default: return null;
    }
  }

  static getStatusText(status: number): string {
    switch (status) {
      case RequisitionStatus.AWAITING_INPUT: return 'Work In Progress';
      case RequisitionStatus.AWAITING_REVIEW: return 'Awaiting Review';
      case RequisitionStatus.REJECTED: return 'Rejected';
      case RequisitionStatus.APPROVED: return 'Approved/In Process';
      case RequisitionStatus.FULFILLED: return 'Fulfilled';
      default: return null;
    }
  }
}

export interface RequisitionOrderInfo {
  po: PurchaseOrder,
  items: PurchaseOrderLineItem[],
  shippingTickets: ShippingTicket[],
}[]