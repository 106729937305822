<div class="rounded mat-elevation-z4 breakdown h-100-t" style="overflow-y: scroll;">
  <mat-list class="pt-0">
    <!-- Material -->
    <mat-list-item *ngIf="product.material">
      <div class="list-item-inner item--material padding-noexpand" *ngLet="$materialQuote | async as quoteData">
        <mat-icon svgIcon="cube" class="mr-1"></mat-icon>
        <div style="width: 65%" class="text-ellipsis">
          <span class="font-weight-medium">Material</span> ({{ product.material.materialName }})
        </div>
        <div class="ml-auto" *ngIf="!quoteData">
          <i class="fa fa-spin fa-circle-notch"></i>
        </div>
        <mat-icon class="ml-auto" svgIcon="close-thick" *ngIf="quoteData?.error"></mat-icon>
        <div class="ml-auto font-weight-medium" *ngIf="quoteData && quoteData.leadTimeDays !== undefined">
          {{ quoteData.leadTimeDays }} days
        </div>
      </div>
    </mat-list-item>
    <!-- Purchased -->
    <mat-list-item *ngIf="product.purchasedItems.length > 0">
      <div class="list-item-inner item--purchased" *ngLet="$purchasedItemsTotalLeadTime | async as val">
        <button mat-icon-button class="mr-1" (click)="selectionModel.toggle('PURCHASED')">
          <mat-icon>
            {{ selectionModel.isSelected('PURCHASED') ? 'expand_more' : 'chevron_right' }}
          </mat-icon>
        </button>
        <mat-icon svgIcon="package-variant" class="mr-1"></mat-icon>
        <div style="width: 65%" class="text-ellipsis">
          <span class="font-weight-medium">Purchased Items</span>
        </div>
        <ng-container *ngIf="val !== null; else loading">
          <div class="ml-auto font-weight-medium" *ngIf="!selectionModel.isSelected('PURCHASED')">
            {{ val }} days
          </div>
          <mat-icon class="ml-auto" svgIcon="dots-horizontal" *ngIf="selectionModel.isSelected('PURCHASED')"></mat-icon>
        </ng-container>
      </div>
    </mat-list-item>
    <ng-container *ngIf="selectionModel.isSelected('PURCHASED')">
      <mat-list-item *ngFor="let productPurchasedItem of product.purchasedItems">
        <div class="list-item-inner item--purchased padding-subitem" *ngLet="quoteLeadTimeObservableFiltered(productPurchasedItem.selectedQuote) | async as quoteData">
          <div style="width: 65%" class="text-ellipsis">
            <span>{{ productPurchasedItem.purchasedItem.description }}</span>
          </div>
          <div class="ml-auto" *ngIf="!quoteData">
            <i class="fa fa-spin fa-circle-notch"></i>
          </div>
          <mat-icon class="ml-auto" svgIcon="close-thick" *ngIf="quoteData?.error"></mat-icon>
          <div class="ml-auto font-weight-medium" *ngIf="quoteData && quoteData.leadTimeDays !== undefined">
            {{ quoteData.leadTimeDays }} days
          </div>
        </div>
      </mat-list-item>
    </ng-container>
    <!-- Outsourced -->
    <mat-list-item *ngIf="outsourceSteps.length > 0">
      <div class="list-item-inner item--process" *ngLet="$outsourcedTotalLeadTime | async as val">
        <button mat-icon-button class="mr-1" (click)="selectionModel.toggle('OUTSOURCED')">
          <mat-icon>
            {{ selectionModel.isSelected('OUTSOURCED') ? 'expand_more' : 'chevron_right' }}
          </mat-icon>
        </button>
        <mat-icon svgIcon="logout-variant" class="mr-1"></mat-icon>
        <div style="width: 65%" class="text-ellipsis">
          <span class="font-weight-medium">Outside Processing</span>
        </div>
        <ng-container *ngIf="val !== null; else loading">
          <div class="ml-auto font-weight-medium" *ngIf="!selectionModel.isSelected('OUTSOURCED') && val">
            {{ val }} days
          </div>
          <mat-icon class="ml-auto" svgIcon="dots-horizontal" *ngIf="selectionModel.isSelected('OUTSOURCED')"></mat-icon>
        </ng-container>
      </div>
    </mat-list-item>
    <ng-container *ngIf="selectionModel.isSelected('OUTSOURCED')">
      <mat-list-item *ngFor="let step of outsourceSteps">
        <div class="list-item-inner item--process padding-subitem" *ngLet="quoteLeadTimeObservableFiltered(step.selectedQuote) | async as quoteData">
          <div style="width: 65%" class="text-ellipsis">
            <span>{{ service.getStation(step).name }}</span>
          </div>
          <div class="ml-auto" *ngIf="!quoteData">
            <i class="fa fa-spin fa-circle-notch"></i>
          </div>
          <mat-icon class="ml-auto" svgIcon="close-thick" *ngIf="quoteData?.error"></mat-icon>
          <div class="ml-auto font-weight-medium" *ngIf="quoteData && quoteData.leadTimeDays !== undefined">
            {{ quoteData.leadTimeDays }} days
          </div>
        </div>
      </mat-list-item>
    </ng-container>
    <!-- Labor -->
    <mat-list-item *ngIf="laborSteps.length > 0">
      <div class="list-item-inner item--labor">
        <button mat-icon-button class="mr-1" (click)="selectionModel.toggle('LABOR')">
          <mat-icon>
            {{ selectionModel.isSelected('LABOR') ? 'expand_more' : 'chevron_right' }}
          </mat-icon>
        </button>
        <mat-icon svgIcon="account-hard-hat" class="mr-1"></mat-icon>
        <div style="width: 65%" class="text-ellipsis">
          <span class="font-weight-medium">Labor</span>
        </div>
        <div class="ml-auto font-weight-medium" *ngIf="!selectionModel.isSelected('LABOR')">{{ $laborLeadTime | async }} days</div>
        <mat-icon class="ml-auto" svgIcon="dots-horizontal" *ngIf="selectionModel.isSelected('LABOR')"></mat-icon>
      </div>
    </mat-list-item>
    <ng-container *ngIf="selectionModel.isSelected('LABOR')">
      <mat-list-item *ngFor="let step of laborSteps">
        <div class="list-item-inner item--labor padding-subitem">
          <div style="width: 65%" class="text-ellipsis">
            <span>{{ service.getStation(step).name }}</span>
          </div>
          <div class="ml-auto font-weight-medium">{{ getLaborStepLeadTime(step) | number:'1.0-2' }} hours</div>
        </div>
      </mat-list-item>
    </ng-container>
    <!-- Children -->
    <mat-list-item *ngIf="product.childAssemblies.length > 0">
      <div class="list-item-inner" *ngLet="$subassembliesLeadTime | async as val">
        <button mat-icon-button class="mr-1" (click)="selectionModel.toggle('CHILDREN')">
          <mat-icon>
            {{ selectionModel.isSelected('CHILDREN') ? 'expand_more' : 'chevron_right' }}
          </mat-icon>
        </button>
        <mat-icon svgIcon="list-box" class="mr-1"></mat-icon>
        <div style="width: 65%" class="text-ellipsis">
          <span class="font-weight-medium">Subassemblies</span> (longest)
        </div>
        <div class="ml-auto font-weight-medium" *ngIf="val !== null; else loading">
          {{ val }} days
        </div>
      </div>
    </mat-list-item>
    <ng-container *ngIf="selectionModel.isSelected('CHILDREN')">
      <mat-list-item *ngFor="let child of product.childAssemblies">
        <div class="list-item-inner padding-subitem">
          <div style="width: 65%" class="text-ellipsis">
            <span>{{ child.partNumber }} Rev. {{ child.revision }}</span>
          </div>
          <div class="ml-auto font-weight-medium">{{ service.getProductLeadTimeObservable(child.productId) | async }} days</div>
        </div>
      </mat-list-item>
    </ng-container>
    <!-- Subtotal -->
    <mat-list-item style="
      position: sticky;
      bottom: 112px;
      background: white;
      /* border-top: 1px solid rgba(0,0,0,.12); */
    ">
      <div class="list-item-inner padding-noexpand" *ngLet="$subtotalLeadTime | async as val">
        <mat-icon svgIcon="playlist-plus" class="mr-1"></mat-icon>
        <div style="width: 65%" class="text-ellipsis">
          <span class="font-weight-medium">Subtotal</span>
        </div>
        <div class="ml-auto font-weight-medium" *ngIf="val !== null; else loading">
          {{ val }} days
        </div>
      </div>
    </mat-list-item>
    <mat-list-item style="
      position: sticky;
      bottom: 56px;
      background: white;
    ">
      <div class="list-item-inner padding-noexpand">
        <mat-icon svgIcon="arrow-left-right-bold" class="mr-1"></mat-icon>
        <div style="width: 65%" class="text-ellipsis">
          <span class="font-weight-medium">Buffer</span>
        </div>
        <mat-form-field appearance="outline" class="ml-auto dense-field mat-input-no-message buffer-field">
          <input placeholder="0" matInput [readonly]="!editing" type="number" step="1" class="text-right" [(ngModel)]="product.leadTimeBuffer" (ngModelChange)="this.service.notifyEdited(this.product)">
          <span class="text-muted" matSuffix>days</span>
        </mat-form-field>
      </div>
    </mat-list-item>
    <mat-list-item style="
      position: sticky;
      bottom: 0px;
      background: white;
      /* border-top: 1px solid rgba(0,0,0,.12); */
    ">
      <div class="list-item-inner padding-noexpand" *ngLet="$totalLeadTime | async as val">
        <mat-icon svgIcon="calendar-clock" class="mr-1"></mat-icon>
        <div style="width: 65%" class="text-ellipsis">
          <span class="font-weight-medium">Total</span>
        </div>
        <div class="ml-auto font-weight-medium" *ngIf="val !== null; else loading">
          {{ val }} days
        </div>
      </div>
    </mat-list-item>
  </mat-list>
  <!-- <div>$purchasedItemsTotalLeadTime {{ $purchasedItemsTotalLeadTime | async | json }}</div>
  <div>$outsourcedTotalLeadTime {{ $outsourcedTotalLeadTime | async | json }}</div>
  <div>$subassembliesLeadTime {{ $subassembliesLeadTime | async | json }}</div>
  <div>$materialQuote {{ $materialQuote | async | json }}</div>
  <div>$laborLeadTime {{ $laborLeadTime | async | json }}</div>
  <div>$subtotalLeadTime {{ $subtotalLeadTime | async | json }}</div>
  <div>$totalLeadTime {{ $totalLeadTime | async | json }}</div> -->
</div>

<ng-template #loading>
  <div class="ml-auto">
    <i class="fa fa-spin fa-circle-notch"></i>
  </div>
</ng-template>