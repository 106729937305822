<div class="sortable-container">
  <div  style="
    position: sticky;
    top: 0px;
  ">
    <h5 class="bg-secondary text-center p-2">
      Available Stations
      <button class="btn btn-sm btn-success float-right addBtn" title="Add a Station" *ngIf="addButton && editing && canAdd" (click)="addNew.emit()"><i class="fas fa-plus"></i></button>
    </h5>
  </div>
  <div class="w-100 bg-white pb-2 px-3" style="
    position: sticky;
    top: 48px;
  ">
    <mat-form-field appearance="outline" class="w-100 dense-field">
      <input placeholder="Search Stations" matInput [formControl]="searchCtrl">
    </mat-form-field>
  </div>
  <ul class="sortable-list" *ngLet="filteredStations | async as list">
    <li *ngIf="!list">
      <p class="text-center">
        <i class="text-primary fas fa-spin fa-2x fa-circle-notch"></i>
      </p>
    </li>
    <ng-container *ngIf="list">
      <li draggable="true" *ngFor="let item of list; let i = index;" [ngClass]="{'active': item == selectedItem}"
        (dragstart)="onDragStart($event, item, i)" (dblclick)="send(item)" (click)="select(item)">
      <div>
        <i [title]="!(item.perPart)?'Per Job':''" [ngClass]="{'fa-cogs':!(item.perPart)}" class="fas fa-fw fa-lg text-muted"></i>
        <i [title]="item.isOutsourceStep?'Outsourced':''" [ngClass]="{'fa-sign-out-alt':item.isOutsourceStep}" class="fas fa-fw fa-lg text-muted"></i>
        <i [title]="item.isPainting?'Painting':''" [ngClass]="{'fa-fill-drip':item.isPainting}" class="fas fa-fw fa-lg text-muted"></i>
        {{item.name}}
      </div>
    </li>
    </ng-container>
  </ul>
</div>
