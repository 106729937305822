<div class="position-relative h-100-t overflow-y-scroll" *ngLet="(results$ | async) as results">
  <div class="mb-4 d-flex align-items-center mat-input-no-message border-bottom px-3 py-4 bg-white search-area">
    <mat-form-field class="flex-grow-1 dense-field" appearance="outline">
      <mat-icon class="text-muted" matPrefix>search</mat-icon>
      <input ngModel #filterModel="ngModel" matInput type="text" placeholder="Search notes...">
    </mat-form-field>
    <button [matMenuTriggerFor]="filterMenu" mat-flat-button class="mx-2 mat-square-button h-100">
      <mat-icon *ngLet="filters | async as selectedFilters"
        [matBadge]="selectedFilters?.length === 0 ? null : selectedFilters?.length" matBadgePosition="below after"
        matBadgeSize="small" svgIcon="filter"></mat-icon>
    </button>
    <mat-menu #filterMenu (click)="$event.stopPropagation()" class="p-0">
      <div class="w-100 p-3" (click)="$event.stopPropagation()" *ngLet="existingTags$ | async as availableFilters">
        <mat-chip-list *ngLet="filters | async as selectedFilters">
          <mat-chip *ngFor="let filter of availableFilters"
            [class.filter-disabled]="!filterIsEnabled(filter, selectedFilters)"
            (click)="$event.stopPropagation(); toggleFilter(filter)">{{ filter }}</mat-chip>
        </mat-chip-list>
      </div>
    </mat-menu>
    <button *ngIf="(sortOrder | async) as order" mat-flat-button class="ml-2 mat-square-button h-100"
      (click)="setSortOrder(order === 'asc' ? 'desc' : 'asc')">
      <mat-icon [svgIcon]="'sort-calendar-'+ order +'ending'"></mat-icon>
    </button>
    <button mat-fab class="extended-fab-button extended-fab-small button-text" color="primary"
      style="position: absolute; bottom: -1.25em; right: 1em; z-index: 3" (click)="createNote()">
      <mat-icon svgIcon="note-plus"></mat-icon>
      New Note
    </button>
    <mat-progress-bar class="w-100 progress-bar" mode="indeterminate" style="position: absolute; bottom: 0; left: 0; width: 100%;" *ngIf="!results"></mat-progress-bar>
  </div>
  <div class="adding-overlay cdk-overlay-dark-backdrop" *ngIf="editingNote" (click)="canClickOutside && editingNote = null">

  </div>
  <div class="adding-container mat-elevation-z6"
    *ngIf="editingNote"
  >
    <sales-note-creator [note]="editingNote" [existingTags$]="existingTags$" (close)="editingNote = null" (save)="saveNote(editingNote)"></sales-note-creator>
  </div>
  <div class="px-3" *ngIf="results">
    <div *ngIf="results.length === 0" class="w-100 h-100 d-flex align-items-center justify-content-center font-italic text-muted">
      No notes were found for this sales process.
    </div>
    <div *ngFor="let note of results" class="mb-3">
      <sales-note-card [note]="note" (edit)="editNote(note)" (delete)="deleteNote(note)"></sales-note-card>
    </div>
  </div>
</div>
