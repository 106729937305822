import { Component, Inject } from '@angular/core';
import { Message, MessageType } from '../../../common/resources/message'
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UtilityService } from '../../../common/services/utility.service';
import { MessageService } from '../../../common/services/message.service';

@Component({
  selector: 'app-messages',
  templateUrl: './messages.component.html',
  styleUrls: ['./messages.component.less']
})
export class MessagesComponent {
  messages: Message[] = [];

  constructor(private messageService: MessageService, private dialog: MatDialog) {
    this.messageService.messages.subscribe(msgs => this.messages = msgs);
  }

  getClass(msg: Message, prefix?: string): string {

    prefix = prefix || "";

    switch (msg.messageType) {
      case MessageType.ERROR:
        return prefix + "-danger";
      case MessageType.INFO:
        return prefix + "-info";
      case MessageType.SUCCESS:
        return prefix + "-success";
      default:
        return prefix + "-secondary";
    }
  }

  acknowledge(msg: Message): void {
    console.log(`removing message ${msg.messageId}`);
    this.messageService.remove(msg.messageId);
  }

  getDetails(msg: Message): string {
    return msg.messagePayload.error;
  }

  showErrorDetail(msg: Message): void {
    this.dialog.open(ErrorDetailDialog, {
      data: msg
    });
  }

}

@Component({
  selector: 'error-detail-dialog',
  templateUrl: 'error-detail-dialog.html',
})
export class ErrorDetailDialog {
  constructor(@Inject(MAT_DIALOG_DATA) public data: Message, private utilitySvc: UtilityService) { }

  copyToClipboard(text: string): void {
    UtilityService.copyText(text);
    this.utilitySvc.showAlert("Message Copied", "<p>The message body has been copied to the clipboard.</p><p class='text-muted'>You can now use the 'Paste' function in any program to retrieve this text.</p>");
  }
}
