import { Injectable, Inject } from '@angular/core';
import { Observable, forkJoin } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { MessageService } from '../../common/services/message.service';
import { catchError, tap } from 'rxjs/operators';
import { ErrorHandlerService } from '../../common/services/errorHandler.service'
import { httpService } from '../../common/services/http.service';
import { StaffUtilization } from '../resources/staff-utilization';
import { MbscCalendarEvent, MbscEventcalendarOptions } from '@mobiscroll/angular';
import { Shift } from '../resources/shift';
import { ResourceEmployee } from '../resources/resource-employee';
import { User } from '../../common/resources/user';
import { auxString } from '../../../../util/auxString';
import { EmployeeShiftAssignmentService } from './employee-shift-assignment.service';
import { FloorAlertService } from '../../floor/services/floor-alert.service';
import { MachineAssignment } from '../../planning/resources/machine-assignment';


@Injectable({
  providedIn: 'root',
})
export class StaffUtilizationService extends httpService {
  private apiBase: string = 'api/StaffUtilization';
  private apiUrl: string;

  private arrAvailableShifts: Shift[];
  private valueIndex_Shift: number = 0;

  public arrStaffUtilization: MbscCalendarEvent[];


  constructor(private employeeShiftAssignmentService: EmployeeShiftAssignmentService, private floorAlertService: FloorAlertService, errorHandler: ErrorHandlerService, private messages: MessageService, private http: HttpClient, @Inject('BASE_URL') private baseUrl: string) {
    super(errorHandler, messages);
    this.serviceName = "StaffUtilization";

    this.apiUrl = this.baseUrl + this.apiBase;

    employeeShiftAssignmentService.getAvailableShifts().subscribe(data => {
      this.arrAvailableShifts = data;
    });

  }

  public map_ArrMachineAssignment_To_ArrTasksMbscCalendarEvent(arrMachineAssignment: MachineAssignment[]) {
    var arrTaksMbscCalendarEvent: MbscCalendarEvent[] = [];

    arrMachineAssignment.forEach(x => {

      var assignmentTitle = "";

      if ((x.department != undefined) && (x.department.name != undefined))
        assignmentTitle = assignmentTitle + " " + x.department.name ;

      assignmentTitle = assignmentTitle + " / ";

      if ( (x.machine != undefined) && (x.machine.name != undefined))
        assignmentTitle = assignmentTitle + " " + x.machine.name;

       
      var taskMbscCalendarEvent: MbscCalendarEvent = {
        start: x.scheduledStart,
        end: x.scheduledEnd,
        title: assignmentTitle,
        resource: x.userId!= null ? x.userId.toLocaleUpperCase() : null,
        color: "#FC0",
        x: [this.arrAvailableShifts[0]],   // Note: nevermind whic shift, just set one here. It won't be used.
        notes: "[some note goes here]"
      }
     
      arrTaksMbscCalendarEvent.push(taskMbscCalendarEvent );
    });

    return arrTaksMbscCalendarEvent;
  }


  public async getStaffUtilization() {

    var promise = new Promise<void>((resolve) => {

      let pageSize: number = 900500;  // TODO !!!! 

      forkJoin(this.employeeShiftAssignmentService.getEmployeeShiftAssignments(), this.floorAlertService.getScheduledTasks(0, pageSize)).subscribe(([resultData1, resultData2]) => {

        //arrAssignedMbscCalendarEvent
        let arrAssignedMbscCalendarEvent: MbscCalendarEvent[] = this.employeeShiftAssignmentService.map_ArrEmployeeShiftAssignments_To_ArrMbscCalendarEvent(resultData1);
        //console.log("getStaffUtilization() - arrAssignedMbscCalendarEvent.length= " + arrAssignedMbscCalendarEvent.length);
        arrAssignedMbscCalendarEvent.forEach(z => {
          z.color = "#0cf";
          z.title = "Assigned Shift";
        });

        //arrTasksMbscCalendarEvent
        var arrTasksMbscCalendarEvent: MbscCalendarEvent[];
        arrTasksMbscCalendarEvent = this.map_ArrMachineAssignment_To_ArrTasksMbscCalendarEvent(resultData2.results);
        //console.log("getStaffUtilization() - arrTasksMbscCalendarEvent.length= " + arrTasksMbscCalendarEvent.length);
        //console.log("getStaffUtilization() - resultData2.length= " + resultData2.results.length);
        //console.log("getStaffUtilization() - resultData2= " + JSON.stringify(resultData2));

        this.arrStaffUtilization = arrAssignedMbscCalendarEvent.concat(arrTasksMbscCalendarEvent);
        console.log("getStaffUtilization() - this.arrStaffUtilization.length= " + this.arrStaffUtilization.length);

        resolve();
      });

    });
    return promise;
  }



}
