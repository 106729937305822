<loading *ngIf="!ticket || loading"></loading>
<loading *ngIf="saving" content="Saving..."></loading>

<mat-sidenav-container *ngIf="ticket">
  <mat-sidenav disableClose (closedStart)="closeSideNav()" #sidenav mode="over" position="end">
        <product-detail [customer]="ticket.purchaseOrder.customer" [orderId]="selectedWorkOrder.orderSegmentId" [canEdit]="true"
          [(product)]="editingProduct" [rapidEdit]="true" (productUpdate)="onProductUpdate($event)" *ngIf="showEditor=='part'" [sidenav]="sidenav" [department]="'Planning'"></product-detail>
    </mat-sidenav>
    <mat-sidenav-content>
<div *ngIf="ticket">
  <div class="row">
                

    <div class="col-12">
      <div class="row" *ngIf="userIsManager() && ticket">
        <div class="col-12 text-center rounded p-4" [ngClass]="{'bg-info text-light': !ticket?.assignedUserId}">
          <div *ngIf="!ticket.assignedUserId">
            <h3>Needs Assignment</h3>
            <p>This ticket needs to be assigned to an employee before it can be edited.</p>
          </div>
          <div class="row align-items-center justify-content-center">
            <div class="col-2 text-right" *ngIf="ticket.assignedUserId">
              <span>Assigned To:</span>
            </div>
            <div class="col-6">
              <employee-select [editable]="true" (employeeChange)="assigningEmployee = $event" [employee]="ticket?.assignedUser">
              </employee-select>
            </div>
            <div class="col-2">
              <button [disabled]="!assigningEmployee" class="btn btn-success" (click)="saveAssignment()">
                <span *ngIf="ticket.assignedUserId">Re-</span>Assign
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row w-100 mt-4" style="height: 80vh; padding: 0 30px;">
          <div class="col-8" style="height: 100%;">
            <planning-wo-detail
              [allProductsFlat]="allChildrenFlat"
              (editWorkflow)="editWorkflow($event)"
              (savePlanning)="savePlanning($event)"
              (donePlanning)="donePlanning()"
              (revertPreplanning)="returnToPreplanning()" (preplanningFinished)="finishPreplanning($event)" (reassign)="reassignWO($event)" #woDetail *ngIf="selectedWorkOrder" [record]="selectedWorkOrder"
            ></planning-wo-detail>
          </div>
          <div class="col-4" style="height: 100%">
            <div class="bg-white border rounded" style="height: 100%;">
              <product-hierarchy-sort #productHierarchySort [single]="true" [planningTicket]="ticket" [selectedItemId]="selectedWorkOrderId" (selectedItemIdChange)="onSelectItem($event)" [(workOrders)]="ticket.workOrders" [products]="[rootWorkOrder.product]" (sorted)="reorderHierarchy($event)">
              </product-hierarchy-sort>
            </div>
          </div>
        </div>
  </div>
</div>

</mat-sidenav-content>
</mat-sidenav-container>
