<div class="h-100-t" ngForm #form="ngForm" style="
    display: flex;
    flex-direction: column;">
  <div class="w-100 d-flex align-items-center mt-3 p-3 border-bottom" style="padding-bottom: 19px !important;">
    <div style="font-size: 1.15rem" class="font-weight-medium">Step Detail</div>
    <div style="font-size: 0.9em; margin-top: 4px;" class="text-muted font-weight-medium mx-2" *ngIf="step">
      {{ getStation(step)?.name }}
    </div>
    <button mat-stroked-button color="warn" class="ml-auto" *ngIf="step && editing"
      (click)="deleteStep.emit()">Delete</button>
  </div>
  <div class="p-4 text-center small text-muted" *ngIf="!step">Select a step to edit.</div>
  <mat-tab-group *ngIf="step" class="flex-grow-1">
    <mat-tab label="Step Data">
      <div>
        <div class="border rounded p-2 mb-2 mx-3 mt-2 d-flex align-items-center justify-content-center">
          <mat-icon svgIcon="cog" [class.text-muted]="step.runIsPerPart" class="mr-1"></mat-icon>
          <div class="small font-weight-medium" [class.text-muted]="step.runIsPerPart">
            Per Job
          </div>
          <mat-slide-toggle [class.mat-slide-toggle-readonly]="!editing" [(ngModel)]="step.runIsPerPart"
            name="runIsPerPart" class="mx-2 mat-slide-toggle-impartial"></mat-slide-toggle>
          <div class="small font-weight-medium" [class.text-muted]="!step.runIsPerPart">Per Part</div>
        </div>
      </div>
      <div>
        <div class="border rounded p-2 mb-2 mx-3 mt-2 d-flex align-items-center justify-content-center">
          <mat-checkbox [class.mat-checkbox-readonly]="!editing" [(ngModel)]="step.isStandalone" name="isStandalone"
            class="mr-2 mat-checkbox-no-margin"></mat-checkbox>
          <div class="small font-weight-medium">
            Standalone Step
          </div>
        </div>
      </div>
      <div *ngIf="!(getStation(step)?.isOutsourceStep)">
        <div class="row m-0 mt-2">
          <div class="col-12">
            <div class="border rounded p-2 mb-2">
              <div class="text-center position-relative">
                <div class="font-weight-medium">Setup</div>
                <mat-checkbox class="mat-checkbox-no-margin" style="position: absolute; right: 0; top: -2px"
                  [(ngModel)]="step.hasSetup" name="hasSetup"
                  [class.mat-checkbox-readonly]="!canEditWorkflowStep"></mat-checkbox>
              </div>
              <div class="w-100 mt-2" *ngIf="step.hasSetup">
                <mat-form-field class="w-100" appearance="outline">
                  <mat-label>Initial Setup Time</mat-label>
                  <input type="number" matInput [(ngModel)]="step.setupTime" name="setupTime"
                    [readonly]="!canEditWorkflowStep" />
                  <span matSuffix>
                    hr
                  </span>
                </mat-form-field>
                <mat-form-field class="w-100" appearance="outline" *ngIf="step.runIsPerPart">
                  <mat-label>Per-Piece Setup Time</mat-label>
                  <input type="number" matInput [(ngModel)]="step.perPieceSetupTime" name="perPieceSetupTime"
                    [readonly]="!canEditWorkflowStep" />
                  <span matSuffix>
                    min
                  </span>
                </mat-form-field>
              </div>
            </div>
          </div>
          <div class="col-12">
            <mat-form-field class="w-100" appearance="outline">
              <mat-label>Run Time</mat-label>
              <input type="number" matInput [(ngModel)]="step.runTime" name="runTime"
                [readonly]="!canEditWorkflowStep" />
              <span matSuffix>
                {{step.runIsPerPart ? 'min':'hr'}}
              </span>
            </mat-form-field>
          </div>
          <div class="col-12 mb-2">
            <mat-form-field class="w-100" appearance="outline">
              <mat-label>Run Rate</mat-label>
              <span matPrefix>
                $
              </span>
              <input type="number" matInput [(ngModel)]="step.runPrice" name="runRate"
                [readonly]="!canEditWorkflowStep" />
            </mat-form-field>
          </div>
          <div class="col-12">
            <div class="border rounded p-2 mb-2">
              <div class="text-center position-relative">
                <div class="font-weight-medium">Programming</div>
                <mat-checkbox class="mat-checkbox-no-margin" style="position: absolute; right: 0; top: -2px"
                  [(ngModel)]="step.hasProgramming" name="hasProgramming"
                  [class.mat-checkbox-readonly]="!canEditWorkflowStep"></mat-checkbox>
              </div>
              <ng-container *ngIf="step.hasProgramming">
                <div class="px-2">
                  <mat-form-field class="w-100 dense-field mt-1 mat-input-no-message" appearance="outline">
                    <mat-label>Run Time</mat-label>
                    <input type="number" matInput [(ngModel)]="step.programmingTime" name="programmingTime"
                      [readonly]="!canEditWorkflowStep" />
                    <span matSuffix>
                      hr
                    </span>
                  </mat-form-field>
                </div>
                <div class="px-2 mb-1">
                  <mat-form-field class="w-100 dense-field mt-1 mat-input-no-message" appearance="outline">
                    <mat-label>Run Rate</mat-label>
                    <span matPrefix>
                      $
                    </span>
                    <input type="number" matInput [(ngModel)]="step.programmingRate" name="programmingRate"
                      [readonly]="!canEditWorkflowStep" />
                  </mat-form-field>
                </div>
                <div class="px-2 mt-1 text-right" *ngIf="step.hasProgramming">
                  <span class="font-weight-bold small">Total:{{ (step.programmingRate || 0) * (step.programmingTime ||
                    0) | currency }}</span>
                </div>
              </ng-container>
            </div>
          </div>
          <div class="col-12">
            <div class="border rounded mb-2 pt-1 d-flex flex-column">
              <div class="text-center position-relative px-2 mb-1">
                <div class="font-weight-medium">Inspection</div>
              </div>
              <div class="px-2">
                <mat-checkbox class="small" [(ngModel)]="step.inspectionIsCMM" name="inspectionIsCMM"
                  [class.mat-checkbox-readonly]="!canEditWorkflowStep">
                  Inspection Uses CMM
                </mat-checkbox>
              </div>
              <div class="border-bottom mb-1"></div>
              <div class="text-center position-relative px-2 mb-1">
                <div class="font-weight-medium">First Part Inspection</div>
                <mat-checkbox class="mat-checkbox-no-margin mr-2" style="position: absolute; right: 0; top: -2px"
                  [(ngModel)]="step.hasFirstPartInspection" name="hasFirstPartInspection"
                  [class.mat-checkbox-readonly]="!canEditWorkflowStep"></mat-checkbox>
              </div>
              <ng-container *ngIf="step.hasFirstPartInspection">
                <div class="px-2">
                  <mat-form-field class="w-100 dense-field mt-1 mat-input-no-message" appearance="outline">
                    <mat-label>Run Time</mat-label>
                    <input type="number" matInput [(ngModel)]="step.firstPartInspectionTime"
                      name="firstPartInspectionTime" [readonly]="!canEditWorkflowStep" />
                    <span matSuffix>
                      min
                    </span>
                  </mat-form-field>
                </div>
                <div class="px-2 mb-1">
                  <mat-form-field class="w-100 dense-field mt-1 mat-input-no-message" appearance="outline">
                    <mat-label>Run Rate</mat-label>
                    <span matPrefix>
                      $
                    </span>
                    <input type="number" matInput [(ngModel)]="step.firstPartInspectionRate"
                      name="firstPartInspectionRate" [readonly]="!canEditWorkflowStep" />
                  </mat-form-field>
                </div>
                <div class="px-2 mt-1 text-right" *ngIf="step.hasFirstPartInspection || step.hasInspection">
                  <span class="font-weight-bold small">Total: {{ (this.firstPartInspectionCost) | currency }}</span>
                </div>
              </ng-container>
              <div class="border-bottom mb-1"></div>
              <div class="text-center position-relative px-2 mb-1">
                <div class="font-weight-medium">Full Inspection</div>
                <mat-checkbox class="mat-checkbox-no-margin mr-2" style="position: absolute; right: 0; top: -2px"
                  [(ngModel)]="step.hasInspection" name="hasInspection"
                  [class.mat-checkbox-readonly]="!canEditWorkflowStep"></mat-checkbox>
              </div>
              <ng-container *ngIf="step.hasInspection">
                <div class="px-2 row m-0">
                  <div class="col-4 p-0">
                    <mat-slide-toggle [(ngModel)]="step.inspectionIsBatched" name="inspectionIsBatched"
                      [class.mat-checkbox-readonly]="!canEditWorkflowStep">
                      Batch
                    </mat-slide-toggle>
                  </div>
                  <div class="col-8 p-0">
                    <mat-form-field class="w-100 dense-field mt-1 mat-input-no-message" appearance="outline"
                      *ngIf="step.inspectionIsBatched">
                      <span matPrefix>
                        every
                      </span>
                      <input type="number" class="text-right pr-2" matInput [(ngModel)]="step.inspectionBatchSize"
                        name="inspectionBatchSize" [readonly]="!canEditWorkflowStep" />
                      <span matSuffix>
                        parts
                      </span>
                    </mat-form-field>
                  </div>
                </div>
                <div class="px-2">
                  <mat-form-field class="w-100 dense-field mt-1 mat-input-no-message" appearance="outline">
                    <mat-label>Run Time</mat-label>
                    <input type="number" matInput [(ngModel)]="step.inspectionTime" name="inspectionTime"
                      [readonly]="!canEditWorkflowStep" />
                    <span matSuffix>
                      min
                    </span>
                  </mat-form-field>
                </div>
                <div class="px-2 mb-1">
                  <mat-form-field class="w-100 dense-field mt-1 mat-input-no-message" appearance="outline">
                    <mat-label>Run Rate</mat-label>
                    <span matPrefix>
                      $
                    </span>
                    <input type="number" matInput [(ngModel)]="step.inspectionRate" name="inspectionRate"
                      [readonly]="!canEditWorkflowStep" />
                  </mat-form-field>
                </div>
                <div class="px-2 mt-1 text-right" *ngIf="step.hasFirstPartInspection || step.hasInspection">
                  <span class="font-weight-bold small">Total: {{ (this.fullInspectionCost) | currency }}</span>
                </div>
              </ng-container>
              <div class="py-1 px-2 mt-1 border-top text-right"
                *ngIf="step.hasFirstPartInspection || step.hasInspection">
                <span class="font-weight-bold small">Grand Total: {{ (this.firstPartInspectionCost +
                  this.fullInspectionCost) | currency }}</span>
              </div>
            </div>
          </div>
          <div class="col-12">
            <mat-form-field class="w-100" appearance="outline">
              <mat-label>Station/Process Notes</mat-label>
              <textarea matInput rows="6" [readonly]="!canEditWorkflowStep" [(ngModel)]="step.description"
                name="description"></textarea>
            </mat-form-field>
          </div>
        </div>
      </div>
      <div class="p-3 d-flex flex-column" *ngIf="getStation(step)?.isOutsourceStep">
        <div class="mb-2">
          <specifications-input [editable]="canEditWorkflowStep" [stationId]="step.stationId"
            [(ngModel)]="step.outsideProcessSpecifications" name="outsideProcessSpecifications"
            (createSpec)="onCreateSpec($event)"></specifications-input>
        </div>
        <div class="mb-2">
          <mat-form-field class="w-100" appearance="outline">
            <mat-label>Outsource Markup</mat-label>
            <input type="number" matInput [(ngModel)]="step.outsourceMarkup" name="outsourceMarkup"
              [readonly]="!editing" />
            <span matSuffix>
              %
            </span>
          </mat-form-field>
        </div>
        <div>
          <product-quoting [order]="record" [stationId]="getStation(step)?.stationId" [(ngModel)]="step.selectedQuote"
            name="selectedQuote" (quoteSelected)="setOutsourcedStepPrice($event)"
            [editable]="editing"></product-quoting>
        </div>

      </div>
    </mat-tab>
    <mat-tab label="Microtickets">
      <div class="pt-4 microticket-area">
        <microticket-subitem-viewer [relatedTicketId]="record.salesProcessId"
          [relatedTicketName]="service.nameForMicrotickets" [relatedTicketType]="'SalesProcess'"
          [filterKey]="service.microticketFilterKey"
          [subItem]="service.generateSubItemNavigationId(product.productId, 'workflow', step.workflowStepId)"
          (loadingChange)="service.loading = $event"
          [microTickets]="service.sharedMicroticketSubject">
        </microticket-subitem-viewer>
      </div>
    </mat-tab>
  </mat-tab-group>
</div>