import { Component, Input, Output, OnInit, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { MaterialService } from '../../../order/services/material.service';
import { MaterialGroup } from '../../../order/resources/material';

@Component({
  selector: 'material-group-select',
  templateUrl: './material-group-select.component.html',
  styleUrls: ['./material-group-select.component.less']
})
export class MaterialGroupSelectComponent implements OnInit {
  public materialGroups: MaterialGroup[];
  @Input() materialGroup: MaterialGroup;
  @Output() materialGroupChange: EventEmitter<MaterialGroup> = new EventEmitter<MaterialGroup>();
  @Input() editable: boolean;
  @ViewChild('materialInputText', { static: true }) inputTxt: ElementRef;
  @ViewChild('autoCompleteTrigger', { static: true }) autoCompleteTrigger: MatAutocompleteTrigger;
  @Output() addGroup: EventEmitter<string> = new EventEmitter<string>();

  constructor(private materialService: MaterialService) { }

  public getMaterialGroupList(_event: KeyboardEvent) {
    this.materialService.getMaterialGroups(this.inputTxt.nativeElement.value)
      .subscribe(result => {
        this.materialGroups = result.results;
      });
  }

  public toggleDropdown(): void {
    event.stopImmediatePropagation();
    if (this.autoCompleteTrigger.panelOpen) {
      this.autoCompleteTrigger.closePanel();
    }
    else {
      this.getMaterialGroupList(null);
      this.autoCompleteTrigger.openPanel();
    }
  }

  public onAddGroup(): void {
    event.stopImmediatePropagation();
    this.addGroup.emit(this.inputTxt.nativeElement.value);
    this.autoCompleteTrigger.closePanel();
  }

  public setGroup(m: MaterialGroup) {
    this.materialGroup = m;
    if (m == null)
      return;

    this.materialGroupChange.emit(m);
  }

  public testSelection(_: any): void {
    if (!this.materialGroup) {
      this.inputTxt.nativeElement.value = "";
      return;
    }
    this.inputTxt.nativeElement.value = this.materialGroup.groupName;
  }

  ngOnInit(): void {
  }
}
