import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { NavigationService } from '../../../common/services/navigation.service';
import { UtilityService } from '../../../common/services/utility.service';
import { SearchList } from '../../../common/components/search-list/search-list';
import { UserService } from '../../../common/services/user.service';
import { QualityTicket, QualityTicketType } from '../../resources/quality-ticket';
import { QualityService } from '../../service/quality.service';
import { ShippingService } from '../../../shipping/services/shipping.service';

@Component({
  selector: 'quality-ticket-list',
  templateUrl: './quality-ticket-list.component.html',
  styleUrls: ['./quality-ticket-list.component.less']
})
export class QualityTicketListComponent extends SearchList<QualityTicket> {

  constructor(navService: NavigationService, private service: QualityService, private router: Router, private userSvc: UserService) {
    super('QualityTicketListComponent');
    navService.setPageTitle("Quality");
    navService.pushBreadcrumb("Contract Review");
  }

  public filterTypes: QualityTicketType[] = [QualityTicketType.ContractReview, QualityTicketType.ReceivedShippingTicket, QualityTicketType.SupplierCertExpiration, QualityTicketType.RMAEvaluation, QualityTicketType.RMAApproval];

  search(): void {
    this.sortTerm = 'required';
    this.results = null;
    this.service.search(this.searchString, this.managerView, this.pageNumber, this.sortTerm, this.sortDirection, this.filterTypes).subscribe(results => {
      this.searchResults = results;
      this.results = results.results
    });
  }

  onSelect(record: QualityTicket): void {
    // This would crash anyway
    if (record.ticketType == QualityTicketType.ContractReview && record.orderSegment == null)
      return;

    this.router.navigate(['/quality/', this.getTypeUrl(record), record.qualityTicketId]);
  }

  weeksLeft(date: string, abs: boolean): number {
    if (date == null) return null;

    return UtilityService.getWeeksRemaining(new Date(date), abs);
  }

  getRequiredColor(date: string): string {
    return UtilityService.getDateRequiredColor(date);
  }

  getTypeColorClass(ticket: QualityTicket): string {
    if (ticket.ticketType === QualityTicketType.ContractReview) {
      return 'bg-info';
    } else if (ticket.ticketType === QualityTicketType.ReceivedShippingTicket) {
      return 'bg-success';
    } else if (ticket.ticketType === QualityTicketType.SupplierCertExpiration) {
      return 'bg-danger';
    } else if (ticket.ticketType === QualityTicketType.RMAEvaluation) {
      return 'bg-warning';
    } else if (ticket.ticketType === QualityTicketType.RMAApproval) {
      return 'bg-info';
    }
  }

  getTypeIcon(ticket: QualityTicket): string {
    if (ticket.ticketType === QualityTicketType.ContractReview) {
      return 'receipt_long';
    } else if (ticket.ticketType === QualityTicketType.ReceivedShippingTicket) {
      return 'local_shipping';
    } else if (ticket.ticketType === QualityTicketType.SupplierCertExpiration) {
      return 'approval';
    } else if (ticket.ticketType === QualityTicketType.RMAEvaluation) {
      return 'assignment_returned';
    } else if (ticket.ticketType === QualityTicketType.RMAApproval) {
      return 'assignment_returned';
    }
  }

  getTypeText(ticket: QualityTicket): string {
    if (ticket.ticketType === QualityTicketType.ContractReview) {
      return 'Contract Review';
    } else if (ticket.ticketType === QualityTicketType.ReceivedShippingTicket) {
      return 'Received Shipment';
    } else if (ticket.ticketType === QualityTicketType.SupplierCertExpiration) {
      return 'Supplier Cert Expiring';
    } else if (ticket.ticketType === QualityTicketType.RMAEvaluation) {
      return 'RMA Disposition';
    } else if (ticket.ticketType === QualityTicketType.RMAApproval) {
      return 'RMA Request';
    }
  }


  getTypeUrl(ticket: QualityTicket) {
    if (ticket.ticketType === QualityTicketType.ContractReview) {
      return 'contract';
    } else if (ticket.ticketType === QualityTicketType.ReceivedShippingTicket) {
      return 'received';
    } else if (ticket.ticketType === QualityTicketType.SupplierCertExpiration) {
      return 'cert-expire';
    } else if (ticket.ticketType === QualityTicketType.RMAEvaluation) {
      return 'rma';
    } else if (ticket.ticketType === QualityTicketType.RMAApproval) {
      return 'rma-approve';
    }
  }

  public getSubticketNumber(ticket: QualityTicket): string
  {
    if (ticket.ticketType === QualityTicketType.ContractReview) return (ticket.orderSegment == null ? '<removed>' : ticket.orderSegment.orderPurchaseOrder ? ticket.orderSegment.orderPurchaseOrder.purchaseOrderNumber : ticket.orderSegment.orderNumber);
    else if (ticket.ticketType === QualityTicketType.ReceivedShippingTicket) return ShippingService.getTicketNumber(ticket.shippingTicketPlaceLocation.shippingTicket);
    else if (ticket.ticketType === QualityTicketType.SupplierCertExpiration) return `${ticket.vendor.name} - ${ticket.vendorCertificate?.certificateName}`;
    else if (ticket.ticketType === QualityTicketType.RMAEvaluation) return ticket.rmaTicket.requestNumber ?? ticket.rmaTicket.rmaNumber;
    else if (ticket.ticketType === QualityTicketType.RMAApproval) return ticket.rmaTicket.rmaNumber ?? ticket.rmaTicket.requestNumber;
    else return "";
  }

  public hasPermission(moduleName: string): boolean {
    return this.userSvc.canAccess(moduleName);
  }



}
