<div class='main-nav' [ngClass]='{ "expanded": isExpanded }'>
  <nav class='navbar navbar-expand-lg navbar-dark bg-dark position-relative'>
    <div id="navbarNav" class='navbar-collapse collapse p-0'>
      <ul class='nav navbar-nav'>
        <li class="nav-item text-center">
          <a class="nav-link toggle text-uppercase text-center small" (click)='toggle()'>
            <p class="position-relative">
              <i class='fas fa-fw fa-2x'
                [ngClass]="{'fa-long-arrow-alt-left': isExpanded, 'fa-long-arrow-alt-right':(!isExpanded)}"></i>
              <span class="small">Minimize</span>
            </p>
          </a>
        </li>
        <li class="nav-item" routerLinkActive='link-active' [routerLinkActiveOptions]='{ exact: true }'>
          <a [routerLink]='["/dashboard"]' class="nav-link">
            <i class="fas fa-lg fa-fw fa-tachometer-alt"></i>
            <span>Dashboard</span>
          </a>
          <ul class='list-unstyled ml-4 pl-3'>
            <li class="nav-item">
              <a [routerLink]='["/microtickets"]' class="nav-link">Microtickets</a>
            </li>
          </ul>
        </li>
        <li class="nav-item" routerLinkActive='link-active' *ngIf="canAccess('CustomerUser')">
          <a [routerLink]='["/customer"]' class="nav-link">
            <i class='far fa-lg fa-fw fa-dot-circle'></i>
            <span>Customers</span>
          </a>
        </li>
        <li class="nav-item" routerLinkActive='link-active' #rla="routerLinkActive" *ngIf="canAccess('SalesUser') || canAccess('QuoteUser')">
          <a [routerLink]='["/rfqs"]' class="nav-link">
            <i class="fas fa-fw fa-lg fa-money-check-dollar"></i>
            <span>Sales</span>
          </a>
          <ul class='list-unstyled ml-4 pl-3'>
            <li class="nav-item">
              <a [routerLink]='["/rfqs"]' class="nav-link">RFQs</a>
            </li>
            <li class="nav-item">
              <a [routerLink]='["/quoting"]' class="nav-link">Quotes</a>
            </li>
            <li class="nav-item">
              <a [routerLink]='["/sales/lead-times"]' class="nav-link">Lead Time Verification</a>
            </li>
            <li class="nav-item">
              <a [routerLink]='["/sales/rma"]' class="nav-link">RMA Requests</a>
            </li>
            <li class="nav-item">
              <a [routerLink]='["/sales/travelers"]' class="nav-link">Travelers</a>
            </li>
            <li class="nav-item">
              <a [routerLink]='["/report-embed/1fb37ac1-ac9e-4521-8c35-f39148f397ba"]' class="nav-link">Quote Analysis</a>
            </li>
          </ul>
        </li>
        <li class="nav-item" routerLinkActive='link-active' *ngIf="canAccess('EstimateUser')">
          <a [routerLink]='["/estimating"]' class="nav-link">
            <i class='fas fa-lg fa-fw fa-ruler'></i>
            <span>Estimating</span>
          </a>
          <ul class='list-unstyled ml-4 pl-3'>
            <li class="nav-item">
              <a [routerLink]='["/estimating/scorecard"]' class="nav-link">Estimates</a>
            </li>
            <li class="nav-item">
              <a [routerLink]='["/rma-estimating"]' class="nav-link">RMA Estimates</a>
            </li>
            <li class="nav-item">
              <a [routerLink]='["/rfqApproval"]' class="nav-link" *ngIf="canAccess('RFQReviewer')">RFQ Approval</a>
            </li>
            <li class="nav-item">
              <a [routerLink]='["/floor/upcoming"]' class="nav-link">Workflow Graphs</a>
            </li>
          </ul>
        </li>
        <li class="nav-item" routerLinkActive='link-active' *ngIf="canAccess('PurchasingManager') || canAccess('ShippingManager')">
          <a [routerLink]='["/inventory"]' class="nav-link">
            <i class='fas fa-lg fa-fw fa-warehouse'></i>
            <span>Inventory</span>
          </a>
          <ul class='list-unstyled ml-4 pl-3'>
            <li class="nav-item">
              <a [routerLink]='["/inventory/locations"]' class="nav-link">Locations</a>
            </li>
          </ul>
        </li>
        <li class="nav-item" routerLinkActive='link-active' *ngIf="canAccess('SalesUser')">
          <a [routerLink]='["/order"]' class="nav-link">
            <i class='fas fa-lg fa-fw fa-download'></i>
            <span>Orders</span>
          </a>
          <ul class='list-unstyled ml-4 pl-3'>
            <li class="nav-item">
              <a [routerLink]='["/openorders"]' class="nav-link">Open Order Report</a>
            </li>
          </ul>
        </li>
        <li class="nav-item" routerLinkActive='link-active' #rla="routerLinkActive" *ngIf="canAccess('SupplierUser')">
          <a [routerLink]='["/supplier"]' class="nav-link">
            <span class="fa-stack">
              <i class="fab fa-themeco fa-stack-2x"></i>
              <i class="fas fa-circle fa-stack-1x fa-inverse"></i>
            </span>
            <span>Suppliers</span>
          </a>
        </li>
        <li class="nav-item" routerLinkActive='link-active' #rla="routerLinkActive" *ngIf="canAccess('PurchasingUser')">
          <a [routerLink]='["/purchasing"]' class="nav-link">
            <i class="fas fa-fw fa-lg fa-tags"></i>
            <span>Purchasing</span>
          </a>
          <ul class='list-unstyled ml-4 pl-3'>
            <li class="nav-item">
              <a [routerLink]='["/purchasing/prerequisites"]' class="nav-link">Prerequisites</a>
              <a [routerLink]='["/purchasing/orders"]' class="nav-link">Purchase Orders</a>
              <a [routerLink]='["/purchasing/orders/unassigned"]' class="nav-link">Unattached POs</a>
              <a [routerLink]='["/purchasing/bulkquotes"]' class="nav-link">Bulk Quotes</a>
              <a [routerLink]='["/purchasing/global"]' class="nav-link">Global Requisitions</a>
              <a [routerLink]='["/openorders"]' class="nav-link">Open Order Report</a>
              <a [routerLink]='["/report-embed/c32ad8e2-786e-4d09-b9ac-6256402a4494"]' class="nav-link">Outsource Report</a>
            </li>
          </ul>
        </li>
        <li class="nav-item" routerLinkActive='link-active' [routerLinkActiveOptions]='{ exact: true }' *ngIf="canAccess('User')">
          <a [routerLink]='["/requisition"]' class="nav-link">
            <i class="fa-lg fa-solid fa-hand-holding"></i>
            <span>Requisition</span>
          </a>
        </li>
        <li class="nav-item" routerLinkActive='link-active' #rla="routerLinkActive" *ngIf="canAccess('FloorUser')">
          <a [routerLink]='["/floor"]' class="nav-link">
            <i class="fas fa-fw fa-lg fa-tasks"></i>
            <span>Floor</span>
          </a>
          <ul class='list-unstyled ml-4 pl-3'>
            <li class="nav-item">
              <a [routerLink]='["/floor/tool-crib"]' class="nav-link">Tool Crib</a>
            </li>
            <li class="nav-item">
              <a [routerLink]='["/floor/operator"]' class="nav-link">Operator View</a>
            </li>
            <li class="nav-item">
              <a [routerLink]='["/quality/inspection"]' class="nav-link">Inspection</a>
            </li>
            <li class="nav-item">
              <a [routerLink]='["/floor/travelers"]' class="nav-link">Travelers</a>
            </li>
            <li class="nav-item">
              <a [routerLink]='["/openorders"]' class="nav-link">Open Order Report</a>
            </li>
          </ul>
        </li>
        <li class="nav-item" routerLinkActive='link-active' #rla="routerLinkActive" *ngIf="canAccess('User')">
          <a [routerLink]='["/employee"]' class="nav-link">
            <i class="fas fa-fw fa-lg fa-user-friends"></i>
            <span>Employees</span>
          </a>
          <ul class='list-unstyled ml-4 pl-3'>
            <li class="nav-item">
              <a [routerLink]='["/employee-shift-assignment"]' class="nav-link">Shift Assignments</a>
            </li>
          </ul>
          <ul class='list-unstyled ml-4 pl-3'>
            <li class="nav-item">
              <a [routerLink]='["/staff-utilization"]' class="nav-link">Staff Utilization</a>
            </li>
          </ul>
        </li>
        <li class="nav-item" routerLinkActive='link-active' #rla="routerLinkActive" *ngIf="canAccess('PlanningUser')">
          <a [routerLink]='["/planning"]' class="nav-link">
            <i class="far fa-fw fa-lg fa-compass"></i>
            <span>Planning</span>
          </a>
          <ul class='list-unstyled ml-4 pl-3'>
            <li class="nav-item">
              <a [routerLink]='["/programming"]' class="nav-link">Programming</a>
            </li>
          </ul>
        </li>
        <li class="nav-item" routerLinkActive='link-active' #rla="routerLinkActive" *ngIf="canAccess('QAUser')">
          <a [routerLink]='["/quality"]' class="nav-link">
            <i class="fas fa-fw fa-lg fa-check-double"></i>
            <span>Quality</span>
          </a>
          <ul class='list-unstyled ml-4 pl-3'>
            <li class="nav-item">
              <a [routerLink]='["/quality"]' class="nav-link">Queue</a>
            </li>
          </ul>
          <ul class='list-unstyled ml-4 pl-3'>
            <li class="nav-item">
              <a [routerLink]='["/quality/inspection"]' class="nav-link">Inspection</a>
            </li>
          </ul>
        </li>
        <li class="nav-item" routerLinkActive='link-active' #rla="routerLinkActive" *ngIf="canAccess('ShippingUser')">
          <a [routerLink]='["/shipping"]' class="nav-link">
            <i class="fas fa-fw fa-lg fa-truck"></i>
            <span>Shipping</span>
          </a>
          <ul class='list-unstyled ml-4 pl-3'>
            <li class="nav-item" *ngIf="canAccess('ShippingUser')">
              <a [routerLink]='["/shipping/queue"]' class="nav-link">Queue</a>
            </li>
          </ul>
        </li>
        <!-- TODO: Add permissions -->
        <li class="nav-item" routerLinkActive='link-active' #rla="routerLinkActive" *ngIf="canAccess('AccountingUser')">
          <a [routerLink]='["/accounting/billing"]' class="nav-link">
            <i class="fas fa-fw fa-lg fa-file-invoice-dollar"></i>
            <span>Accounting</span>
          </a>
          <ul class='list-unstyled ml-4 pl-3'>
            <li class="nav-item" *ngIf="canAccess('AccountingUser')">
              <a [routerLink]='["/accounting/billing"]' class="nav-link">Billing</a>
            </li>
          </ul>
        </li>



        <li class="nav-item mb-4" routerLinkActive='link-active' #rla="routerLinkActive" *ngIf="canAccess('AdminUser')">
          <a [routerLink]='["/admin"]' class="nav-link">
            <i class="fas fa-fw fa-lg fa-tools"></i>
            <span>Admin</span>
          </a>
          <ul class='list-unstyled ml-4 pl-3'>
            <li class="nav-item">
              <a [routerLink]='["/admin/departments"]' class="nav-link">Department Editor</a>
            </li>
            <li class="nav-item">
              <a [routerLink]='["/admin/materials"]' class="nav-link">Material Editor</a>
            </li>
            <li class="nav-item">
              <a [routerLink]='["/admin/paints"]' class="nav-link">Paint Editor</a>
            </li>
            <li class="nav-item">
              <a [routerLink]='["/admin/stations"]' class="nav-link">Station Editor</a>
            </li>
 			      <li class="nav-item">
              <a [routerLink]='["/admin/purchased"]' class="nav-link">Purchased Item/Hardware Editor</a>
            </li>
            <li class="nav-item">
              <a [routerLink]='["/admin/qcclause"]' class="nav-link">QC Clause Editor</a>
            </li>
            <li class="nav-item">
              <a [routerLink]='["/admin/constants"]' class="nav-link">Global Constants Editor</a>
            </li>
            <li class="nav-item">
              <a [routerLink]='["/rules-builder"]' class="nav-link">Rule Builder</a>
            </li> 
          </ul>
        </li>
      </ul>
      <a href="/auth/signout" class="signout text-center small text-muted text-uppercase position-absolute"
        title="Sign Out of COTS 2">
        <i class="fas fa-lg fa-fw fa-sign-out-alt"></i>
        <span class="small">Sign Out</span>
      </a>
    </div>
  </nav>
</div>
