import { Component } from '@angular/core';
import { FloorAlert } from '../../resources/floor-alert';
import { SearchList } from '../../../common/components/search-list/search-list';
import { FloorAlertService } from '../../services/floor-alert.service';
import { Router } from '@angular/router';

@Component({
  selector: 'alert-list',
  templateUrl: './alert-list.component.html',
  styleUrls: ['./alert-list.component.less']
})
export class AlertListComponent extends SearchList<FloorAlert> {
  constructor(private service: FloorAlertService, private router: Router) {
    super('alert');
  }

  protected search(): void {
    this.results = null;
    this.service.search("", this.pageNumber, this.sortTerm, this.sortDirection).subscribe(results => {
      this.searchResults = results;
      this.results = results.results
    });
  }

  public onSelect(record: FloorAlert): void {
    this.router.navigate(['/floor/department', record.departmentId]);
  }
}
