<div>
  <a *ngIf="cert && !editing && showEditor==null" class="position-fixed fixed-bottom btn btn-badge has-icon" title="Edit" (click)="toggleEditing()"><i class="fas fa-edit"></i></a>
  <a *ngIf="showEditor==null" class="position-fixed fixed-bottom btn btn-badge bg-danger has-icon mr-6" title="Cancel" (click)="cancelEditing()"><i class="fas fa-times"></i></a>
  <a *ngIf="cert && editing && showEditor==null" class="position-fixed fixed-bottom btn btn-badge bg-success has-icon" title="Save" (click)="saveChanges()"><i class="fas fa-save"></i></a>

  <loading *ngIf="!cert"></loading>
  <loading *ngIf="saving" content="Saving..."></loading>

  <div *ngIf="cert" class="p-2 container-fluid">
    <mat-sidenav-container class="p-0 m-0">
      <mat-sidenav disableClose (closedStart)="sidebarClosing()" #insetnav mode="over" position="end">
        <upload-file *ngIf="showEditor=='document'" (documentsLoaded)="addDocuments($event)" [sidenav]="insetnav"></upload-file>
      </mat-sidenav>
      <mat-sidenav-content>
        <div class="p-2 vendorCert">
          <div>
            <div class="col-12">
              <div class="form-group">
                <label>Certificate Name</label>
                <certificate-list #certName [required]="true" [editable]="editing" [(name)]="cert.certificateName"></certificate-list>
              </div>
            </div>
          </div>
          <div class="row p-2">
            <div class="col-6">
              <div class="form-group">
                <label>Issue Date</label>
                <date-picker #issueDate [required]="true" placeholder="Issue Date" [editable]="editing" [(date)]="cert.issueDate"></date-picker>
              </div>
            </div>
            <div class="col-6">
              <div class="form-group">
                <label>
                  Expiration Date
                  <span *ngIf="cert.expirationDate!=null" class="badge required p-1 pl-2 pr-2" [ngClass]="getColor(cert.expirationDate)">
                    <span class="fa-lg text-white">{{getCertExpirationText(cert.expirationDate)}}</span>
                  </span>
                </label>
                <date-picker placeholder="Expiration Date" [editable]="editing" [(date)]="cert.expirationDate"></date-picker>
              </div>
            </div>
          </div>
          <div>
            <div class="col-12 p-0 bg-light">
              <h5 class="w-100 p-2 bg-secondary">Documents</h5>
              <div class="p-2">
                <file-display-grid (delete)="deleteDocument($event)" [editable]="editing" [files]="cert?.vendorCertificateDocuments"></file-display-grid>
                <a *ngIf="editing" class="btn btn-badge bg-success has-icon float-right" title="Add Document" (click)="openDocumentWindow()"><i class="fas fa-plus mt-2"></i></a>
                <p *ngIf="cert?.vendorCertificateDocuments?.length==0" class="text-muted text-center"><em>-- No Documents --</em></p>
              </div>
            </div>
          </div>
        </div>
      </mat-sidenav-content>
    </mat-sidenav-container>
  </div>
</div>
