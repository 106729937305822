import { Component, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NavigationService } from '../../common/services/navigation.service';
import { OrderService } from '../../order/services/order.service';
import { StationService } from '../../order/services/station.service';
import { AccountingService } from '../services/accounting.service';
import { AccountReceivable, AccountReceivableStatus } from '../resources/account-receivable';
import { UtilityService } from '../../common/services/utility.service';
import { ShippingService } from '../../shipping/services/shipping.service';
import { ShippingTicket, ShippingTicketStatus } from '../../shipping/resources/shipping-ticket';
import { DatePickerComponent } from '../../common/components/date-picker/date-picker.component';
import { OrderStatus } from '../../order/resources/order';
import { printAddress } from '../../common/resources/address';
import { MatFormField } from '@angular/material/form-field';

@Component({
  selector: 'account-receivable-detail',
  templateUrl: './account-receivable-detail.component.html',
  styleUrls: ['./account-receivable-detail.component.less']
})
export class AccountReceivableDetailComponent implements OnInit {

  public id: string;
  public record: AccountReceivable;
  public lineItemName: string;
  public editing = false;
  public saving = false;
  constructor(
    private route: ActivatedRoute,
    private navService: NavigationService,
    private orderService: OrderService,
    private service: AccountingService,
    private stationService: StationService,
    private utilityService: UtilityService
  ) {
    this.id = this.route.snapshot.paramMap.get("id");
    navService.setPageTitle("Accounting");
    navService.pushBreadcrumb('Billing');
  }

  async ngOnInit() {
    const item = await this.service.getDetail(this.id).toPromise();
    const product = await this.orderService.getProduct(item.customerPurchaseOrderLineItem.productId).toPromise();
    if (item.customerPurchaseOrderLineItem.stationId) {
      if (!this.stationService.loaded) await this.stationService.stationsLoaded.toPromise();
      const station = this.stationService.stationList.find(s => s.stationId === item.customerPurchaseOrderLineItem.stationId);
      this.lineItemName = `${station.name} for ${product.partNumber} Rev. ${product.revision}`
    } else {
      this.lineItemName = `${product.partNumber} Rev. ${product.revision}`
    }
    
    this.record = item;
    this.navService.pushBreadcrumb(this.ticketNumber);
  }

  public preEdit: AccountReceivable = null;
  public edit() {
    this.preEdit = JSON.parse(JSON.stringify(this.record));
    this.editing = true;
  }

  public async stopEditing() {
    const r = await this.utilityService.showConfirmationPromise('Really cancel editing?', 'All changes will be lost.');
    if (!r) return;
    this.record = JSON.parse(JSON.stringify(this.preEdit));
    this.preEdit = null;
    this.editing = false;
  }

  public async save() {
    this.saving = true;
    const record = await this.service.save(this.record).toPromise();
    this.saving = false;
    this.record = record;
    this.preEdit = null;
    this.editing = false;
  }

  public get ticketNumber() {
    let out = `${this.record.customerPurchaseOrderLineItem.customerPurchaseOrder.purchaseOrderNumber}-${this.record.customerPurchaseOrderLineItem.lineItemNumber}`;
    if (this.record.shippingTicket && this.record.shippingTicket.ticketNumber) {
      out += '-' + this.record.shippingTicket.ticketNumber.toString().padStart(3, '0')
    }
    return out;
  }

  public get shippingTicketNumber() {
    return ShippingService.getTicketNumber(this.record.shippingTicket);
  }

  public openPODocument() {
    window.open('/document/' + this.record.customerPurchaseOrderLineItem.customerPurchaseOrder.documentId + '?inline=true', '_blank')
  }

  public getWOStatusColorClassChip(status: number) {
    return OrderStatus.getStatusColorClassChip(status);
  }

  public getWOStatusText(status: number) {
    return OrderStatus.getStatusText(status);
  }

  public getShippingStatusColorClassChip(status: number) {
    return ShippingService.getStatusColorClassChip(status);
  }

  public getShippingStatusText(status: number) {
    return ShippingService.getStatusText(status);
  }

  public getInvoiceStatusColorClass(status: number) {
    return AccountingService.getStatusColorClass(status);
  }

  public getInvoiceStatusText(status: number) {
    return AccountingService.getStatusText(status);
  }

  public get saveDisabled() {
    return ((this.record.status === 2 || this.record.status === 3) && !this.record.invoiceDate) || 
    ((this.record.status === 3) && !this.record.paymentDate) ||
    ((this.record.status >= 2 && this.record.status != 4) && !this.record.invoiceNumber.trim())
  }

  @ViewChildren('datepicker') datepickers: QueryList<DatePickerComponent>;
  @ViewChild('invoiceNumberField') invoiceNumberField: MatFormField;
  public onStatusChange() {
    setTimeout(() => {
      this.datepickers.forEach(d => d.markAsTouched());
    });
    this.invoiceNumberField._control.ngControl.control.markAsTouched();
  }

  public openTracking(): void {
    if (this.record && this.record.shippingTicket.shippingCarrier && this.record.shippingTicket.trackingNumber) {
      window.open(this.record.shippingTicket.shippingCarrier.trackingProvider + this.record.shippingTicket.trackingNumber, "_blank");
    }
  }

  public get shippingCarrierIcon(): string {
    if (!this.record.shippingTicket || !this.record.shippingTicket.shippingCarrier) return null;

    return `${(new URL(this.record.shippingTicket.shippingCarrier.trackingProvider)).origin}/favicon.ico`;
  }

  public printAddress = printAddress;

}
