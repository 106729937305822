<div class="accordian-panel" [ngClass]="{'open': opened}">
  <h5 (click)="opened=!opened">
    <span>{{product.partNumber}} / Rev {{product.revision}}</span>
    <span class="small text-muted float-center">Required: <span class="font-weight-bold">{{(product.dateRequired | date:'MM/dd/yyyy') || '(Not Specified)'}}</span> / Expected: <span class="font-weight-bold">{{expectedDate | date:'MM/dd/yyyy'}}</span></span>
    <span class="float-right" [innerHtml]="trackStatus"></span>
  </h5>
  <div>
    <product-waterfall #waterfallChart [orderId]="orderId" [product]="product"></product-waterfall>
  </div>
</div>
<div class="children" *ngIf="product.childAssemblies && product.childAssemblies.length > 0">
  <product-build-status *ngFor="let p of product.childAssemblies" [orderId]="orderId" [product]="p"></product-build-status>
</div>
