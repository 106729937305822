<loading *ngIf="saving" content="Saving..."></loading>

<mat-form-field class="w-100" appearance="outline">
  <mat-label>Capable Vendor Name</mat-label>
  <input required #autoCompleteTrigger="matAutocompleteTrigger" #vendorInputText matInput placeholder="Capable Vendor Name" [readonly]="!editable" (change)="setValue($event.value)" [ngModel]="vendor?.name" (keyup)="searchVendors($event)" type="text" max="100" [matAutocomplete]="vendorList" [readonly]="!editable" />
  <mat-autocomplete (opened)="searchVendors($event)" autoActiveFirstOption #vendorList="matAutocomplete" (optionSelected)="setValue($event.option.value)">
    <mat-option *ngFor="let v of vendors" [value]="v">
      {{v.name}}
    </mat-option>
    <mat-option *ngIf="vendors?.length === 0" disabled style="height: auto">
      <p class="text-muted small text-center mt-3">
        <em *ngIf="canAddVendor()" style="text-wrap: wrap">Can't find your vendor? It might not be marked as approved or capable.</em>
        <em *ngIf="!canAddVendor()" style="text-wrap: wrap">Can't find your vendor? It might not be marked as approved or capable. Ask a manager.</em>
      <span class="d-inline-block ml-1" *ngIf="canAddVendor()">
        <button class="btn btn-primary btn-sm" (click)="findVendor()"><i class="fas fa-plus fa-fw fa-lg"></i>Search All Vendors</button>
      </span>
    </p>
    </mat-option>
  </mat-autocomplete>
</mat-form-field>

<ng-template #findVendorDialogTemplate let-data>
  <h2 mat-dialog-title>All Vendors</h2>
  <div mat-dialog-content>
    <div><vendor-search [hint]="data.hint" [(selectedItem)]="data.vendor" (onAddItem)="onAddItem()"></vendor-search></div>
  </div>
  <mat-dialog-actions align="end">
    <button mat-flat-button [mat-dialog-close]="null">Cancel</button>
    <button mat-raised-button color="primary" [mat-dialog-close]="data.vendor" [disabled]="!data.vendor">Select Vendor</button>
  </mat-dialog-actions>
</ng-template>