<div class="position-relative">
  <span *ngIf="editable" class="position-absolute dropdown-indicator" (click)="toggleDropdown()"><i class="text-muted fas fa-chevron-down"></i></span>
  
  <mat-chip-list matAutocompleteOrigin
                 #chipList
                 #origin="matAutocompleteOrigin"
                 aria-label="Specification Selection"
                 class="form-control">
    <mat-chip *ngFor="let spec of selectedMaterialMaterialSpecifications"
              [removable]="editable"
              (removed)="removeSpec(spec)">
      {{spec.materialSpecification?.name}}
      <mat-icon matChipRemove *ngIf="editable">cancel</mat-icon>
    </mat-chip>
    <input matInput
           appStaticEdit
           type="text"
           max="100"
           tabindex="-1"
           placeholder="Select Specification"
           #materialInputText
           ngModel
           #inputModel="ngModel"
           #autoCompleteTrigger="matAutocompleteTrigger"
           (focusout)="testSelection($event)"
           (change)="addSpec($event.value)"
           (keyup)="getMaterialSpecList($event)"
           (matChipInputTokenEnd)="testSelection($event)"
           [editable]="editable"
           [matAutocomplete]="materialList"
           [matAutocompleteConnectedTo]="origin"
           [matChipInputFor]="chipList"
           [matChipInputSeparatorKeyCodes]="separatorKeyCodes" />
  </mat-chip-list>
  <mat-autocomplete #materialList="matAutocomplete"
                    autoActiveFirstOption
                    (opened)="getMaterialSpecList($event)"
                    (optionSelected)="addSpec($event.option.value)">
    <mat-option *ngFor="let spec of allMaterialSpecifications" [value]="spec">
      <span>{{spec.name}}</span>
    </mat-option>
    <mat-option *ngIf="allMaterialSpecifications?.length==0">
      <button class="float-right btn btn-primary btn-sm mt-4" (click)="onAdd(inputModel.value)">
        <i class="fas fa-fw fa-lg fa-plus"></i> Add New Specification
      </button>
      <p class="text-muted small text-center mt-3">
        <em>-- No Matching Material Specifications Found --</em>
      </p>
    </mat-option>
  </mat-autocomplete>

</div>
