import { Component, Input, OnInit } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { NavigationService } from '../../../common/services/navigation.service';
import { UtilityService } from '../../../common/services/utility.service';
import { MaterialService } from '../../../order/services/material.service';
import { MaterialAlloy } from '../../../order/resources/material';
import { Router } from '@angular/router';

@Component({
  selector: 'material-alloy-editor',
  templateUrl: './material-alloy-editor.component.html',
  styleUrls: ['./material-alloy-editor.component.less']
})
export class MaterialAlloyEditorComponent implements OnInit {
  public selected: MaterialAlloy = null;
  public saving: boolean = false;
  @Input() sidenav: MatSidenav;

  constructor(private navService: NavigationService, private materialSvc: MaterialService, private utilitySvc: UtilityService, private router: Router) {
    this.navService.clearBreadCrumbs();
    this.navService.pushBreadcrumb("Material Alloys");
  }

  public close(): void {
    if (this.sidenav)
      this.sidenav.close();
    else
      this.router.navigate(['/admin']);
  }

  public select(material: MaterialAlloy): void {
    this.selected = material;
  }

  public add(): void {
    this.selected = <MaterialAlloy>{
      materialAlloyId: UtilityService.emptyGuid,
      name: ""
    };
  }

  public save(): void {
    this.saving = true;
    this.materialSvc.saveMaterialAlloy(this.selected).subscribe(r => {
      this.selected = r;
      this.saving = false;
    });
  }

  public canRemove(): boolean {
    return this.selected != null && this.selected.materialAlloyId != UtilityService.emptyGuid;
  }

  public remove(): void {
    this.utilitySvc.showConfirmation(`Remove Material Alloy '${this.selected.name} '?`, "<p>Are you sure you want to remove this material alloy?</p><p class='text-muted'>This is a global change that can affect multiple materials, quotes and workorders!.</p>", r => {
      if (r) {
        this.saving = true;
        this.materialSvc.removeMaterialAlloy(this.selected.materialAlloyId).subscribe(_ => { this.saving = false; this.selected = null; });
      }
    });
  }

  ngOnInit(): void {
  }
}
