<ng-container *ngIf="appearance === 'default'">
  <div class="datepicker">
    <input *ngIf="!required" (change)="notifyChange()" class="form-control form-control-sm" [max]="max" [min]="min"  matInput [matDatepicker]="datepicker" [placeholder]="placeholder" appStaticEdit [editable]="editable" [readonly]="readonly" [(ngModel)]="date" />
    <input *ngIf="required" [max]="max" [min]="min" #dateInput="ngModel" required minlength="6" (change)="notifyChange()" class="form-control form-control-sm" matInput [matDatepicker]="datepicker" [placeholder]="placeholder" appStaticEdit [editable]="editable" [(ngModel)]="date"/>
    <mat-datepicker-toggle *ngIf="editable" matSuffix [for]="datepicker"></mat-datepicker-toggle>
    <mat-datepicker (closed)="notifyChange()" #datepicker></mat-datepicker>
  </div>
</ng-container>
<ng-container *ngIf="appearance === 'outline'">
  <mat-form-field class="w-100 non-default mat-input-no-message" [class.dense-field]="dense" appearance="outline" [class.mat-form-field-invalid]="error" [floatLabel]="readonly ? 'always' : 'undefined'">
    <mat-label *ngIf="placeholder">{{placeholder}}</mat-label>
    <input matInput [required]="required" [max]="max" [min]="min" #dateInput="ngModel" minlength="6" (change)="notifyChange()" matInput [matDatepicker]="datepicker" [placeholder]="readonly ? '--' : placeholder" [disabled]="!editable" [readonly]="readonly" [(ngModel)]="date"
      (focus)="dense && datepicker.open()"
      (keydown.control.;)="setToToday()"
    />
    <mat-datepicker-toggle *ngIf="!dense" [class.invisible]="!editable || readonly" matSuffix [for]="datepicker" tabindex="-1"></mat-datepicker-toggle>
    <mat-datepicker (closed)="notifyChange()" #datepicker [disabled]="!editable || readonly"></mat-datepicker>
  </mat-form-field>
</ng-container>