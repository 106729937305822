<mat-card class="h-100 border mat-elevation-z0 overflow-hidden">
  <mat-card-title class="text-center bg-primary text-white" 
  style="margin: -16px -16px -8px -16px; font-size: 0.9em;
  padding: 5px;">
      Lead Time Breakdown - {{ product.partNumber }} Rev. {{ product.revision }}
  </mat-card-title>
  <div *ngIf="!breakdown" class="d-flex flex-column align-items-center py-2">
    <div>
      <mat-spinner [color]="'warn'"></mat-spinner>
    </div>
    <div class="text-muted font-italic mt-4">
      Loading...
    </div>
  </div>
  <mat-list *ngIf="breakdown" class="breakdown-list">
    <mat-list-item *ngIf="product.material && !isRepair" matRipple matRippleColor="rgba(0,0,0,.04)" [class.expanded]="expanded === 'material'" (click)="setSelected('material')" class="material main-item w-100">
      <div class="d-flex align-items-center w-100">
        <i class="fas fa-cube mr-1"></i>
        <b>Material</b>
        <b class="ml-auto">{{breakdown.material.leadTime}} days</b>
        <button mat-icon-button class="ml-1">
          <mat-icon *ngIf="expanded !== 'material'">
            chevron_right
          </mat-icon>
          <mat-icon *ngIf="expanded === 'material'">
            expand_more
          </mat-icon>
        </button>
      </div>
    </mat-list-item>
    <mat-list dense *ngIf="expanded === 'material'" class="sublist">
      <mat-list-item class="material w-100">
        <div class="d-flex align-items-center w-100">
          <i class="fas fa-cube mr-1" style="width: 20px;"></i>
          <div class="subitem" [matTooltip]="product.material.materialName" matTooltipPosition="above">{{ product.material.materialName }}</div>
          <b class="ml-auto">{{ breakdown.material.leadTime }} days</b>
        </div>
      </mat-list-item>
    </mat-list>
    <mat-list-item *ngIf="breakdown.purchased.items.length > 0" matRipple matRippleColor="rgba(0,0,0,.04)" [class.expanded]="expanded === 'purchased'" (click)="setSelected('purchased')" class="purchased main-item w-100">
      <div class="d-flex align-items-center w-100">
        <i class="fas fa-box-open mr-1"></i>
        <b>Purchased Items</b>
        <b class="ml-auto">{{breakdown.purchased.total}} days</b>
        <button mat-icon-button class="ml-1">
          <mat-icon *ngIf="expanded !== 'purchased'">
            chevron_right
          </mat-icon>
          <mat-icon *ngIf="expanded === 'purchased'">
            expand_more
          </mat-icon>
        </button>
      </div>
    </mat-list-item>
    <mat-list dense *ngIf="expanded === 'purchased'" class="sublist">
      <mat-list-item class="purchased w-100" *ngFor="let item of breakdown.purchased.items">
        <div class="d-flex align-items-center w-100">
          <i class="fas fa-box-open mr-1" style="width: 20px;"></i>
          <div class="subitem" [matTooltip]="item.item.description" matTooltipPosition="above">{{ item.item.description }}</div>
          <b class="ml-auto">{{ item.leadTime }} days</b>
        </div>
      </mat-list-item>
    </mat-list>
    <mat-list-item *ngIf="breakdown.outsourced.items.length > 0" matRipple matRippleColor="rgba(0,0,0,.04)" [class.expanded]="expanded === 'outsourced'" (click)="setSelected('outsourced')" class="outsourced main-item w-100">
      <div class="d-flex align-items-center w-100">
        <i class="fas fa-sign-out-alt mr-1"></i>
        <b>Outside Processing</b>
        <b class="ml-auto">{{breakdown.outsourced.total}} days</b>
        <button mat-icon-button class="ml-1">
          <mat-icon *ngIf="expanded !== 'outsourced'">
            chevron_right
          </mat-icon>
          <mat-icon *ngIf="expanded === 'outsourced'">
            expand_more
          </mat-icon>
        </button>
      </div>
    </mat-list-item>
    <mat-list dense *ngIf="expanded === 'outsourced'" class="sublist">
      <mat-list-item class="outsourced w-100" *ngFor="let item of breakdown.outsourced.items">
        <div class="d-flex align-items-center w-100">
          <i class="fas fa-sign-out-alt mr-1" style="width: 20px;"></i>
          <div class="subitem" [matTooltip]="item.item.name" matTooltipPosition="above">{{ item.item.name }}</div>
          <b class="ml-auto">{{ item.leadTime }} days</b>
        </div>
      </mat-list-item>
    </mat-list>
    <mat-list-item *ngIf="breakdown.labor.items.length > 0" matRipple matRippleColor="rgba(0,0,0,.04)" [class.expanded]="expanded === 'labor'" (click)="setSelected('labor')" class="labor main-item w-100">
      <div class="d-flex align-items-center w-100">
        <i class="fas fa-screwdriver-wrench mr-1"></i>
        <b>Labor</b>
        <b class="ml-auto">{{breakdown.labor.total}} days</b>
        <button mat-icon-button class="ml-1">
          <mat-icon *ngIf="expanded !== 'labor'">
            chevron_right
          </mat-icon>
          <mat-icon *ngIf="expanded === 'labor'">
            expand_more
          </mat-icon>
        </button>
      </div>
    </mat-list-item>
    <mat-list dense *ngIf="expanded === 'labor'" class="sublist">
      <mat-list-item class="labor w-100" *ngFor="let item of breakdown.labor.items">
        <div class="d-flex align-items-center w-100">
          <i class="fas fa-screwdriver-wrench mr-1" style="width: 20px;"></i>
          <div class="subitem" [matTooltip]="item.item.name" matTooltipPosition="above">{{ item.item.name }}</div>
          <b class="ml-auto">{{ item.hours.toFixed(2) }} hours</b>
        </div>
      </mat-list-item>
    </mat-list>
    <mat-list-item *ngIf="breakdown.children.items.length > 0" matRipple matRippleColor="rgba(0,0,0,.04)" [class.expanded]="expanded === 'children'" (click)="setSelected('children')" class="other main-item w-100">
      <div class="d-flex align-items-center w-100">
        <i class="fas fa-cubes mr-1"></i>
        <b>Sub-Assemblies <small>(longest)</small></b>
        <b class="ml-auto">{{breakdown.children.highest}} days</b>
        <button mat-icon-button class="ml-1">
          <mat-icon *ngIf="expanded !== 'children'">
            chevron_right
          </mat-icon>
          <mat-icon *ngIf="expanded === 'children'">
            expand_more
          </mat-icon>
        </button>
      </div>
    </mat-list-item>
    <mat-list dense *ngIf="expanded === 'children'" class="sublist">
      <mat-list-item class="other w-100" *ngFor="let item of breakdown.children.items">
        <div class="d-flex align-items-center w-100">
          <i class="fas fa-cubes mr-1" style="width: 20px;"></i>
          <div class="subitem" [matTooltip]="item.item.partNumber + 'Rev. ' + item.item.revision" matTooltipPosition="above">{{ item.item.partNumber + ' Rev. ' + item.item.revision }}</div>
          <b class="ml-auto">{{ item.leadTime }} days</b>
        </div>
      </mat-list-item>
    </mat-list>
    <mat-list-item class="other w-100">
      <div class="d-flex align-items-center w-100">
        <i class="fas fa-circle-plus mr-1"></i>
        <b>Sub-total</b>
        <b class="ml-auto">{{breakdown.total}} days</b>
        <button mat-icon-button class="ml-1 invisible cursor-default">
          <mat-icon>
            edit
          </mat-icon>
        </button>
      </div>
    </mat-list-item>
    <mat-list-item class="other w-100">
      <div class="d-flex align-items-center w-100">
        <i class="fas fa-left-right mr-1"></i>
        <b>Buffer</b>
        <b class="ml-auto">{{ product.leadTimeBuffer }} days</b>
        <button mat-icon-button class="ml-1" [class.invisible]="!editing" (click)="editBuffer()">
          <mat-icon>
            edit
          </mat-icon>
        </button>
      </div>
    </mat-list-item>
    <mat-list-item class="other w-100">
      <div class="d-flex align-items-center w-100">
        <i class="fas fa-calculator mr-1"></i>
        <b>Total</b>
        <b class="ml-auto">{{breakdown.total + product.leadTimeBuffer}} days</b>
        <button mat-icon-button class="ml-1 invisible cursor-default">
          <mat-icon>
            edit
          </mat-icon>
        </button>
      </div>
    </mat-list-item>
  </mat-list>
</mat-card>

<ng-template #bufferUpdateDialogTemplate let-data>
  <h2 matDialogTitle>Modify Buffer for {{ data.product.partNumber + 'Rev. ' + data.product.revision }}</h2>
  <mat-dialog-content>
    <div class="form-group">
      <label>Buffer</label>
      <input type="number" [(ngModel)]="data.buffer" required step="1">
    </div>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button mat-button matDialogClose color="secondary" [matDialogClose]="null">Cancel</button>
    <button mat-button matDialogClose color="secondary" [matDialogClose]="data.buffer">Save</button>
  </mat-dialog-actions>
</ng-template>