import { AfterViewInit, Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { Product, ProductMaterialDimension } from '../../../../resources/product';
import { MaterialSelectComponent } from '../../../material-select/material-select.component';
import { UtilityService } from '../../../../../common/services/utility.service';
import { Order } from '../../../../resources/order';
import { MaterialBid } from '../../../../../purchasing/resources/materialBid';
import { Material, MaterialDimension, MaterialType, MaterialTypeDimension } from '../../../../resources/material';
import { OrderDetailService } from '../../order-detail.service';
import { NgForm } from '@angular/forms';
import { Subscription } from 'rxjs';
import { TaskStatus } from '../../../../../common/resources/estimatingtask';
import { EstimateProgressService } from '../../../../services/estimate-progress.service';

@Component({
  selector: 'product-material',
  templateUrl: './product-material.component.html',
  styleUrls: ['./product-material.component.less']
})
export class ProductMaterialComponent implements OnInit, AfterViewInit {

  constructor(
    public service: OrderDetailService,
    public progressService: EstimateProgressService
  ) { }

  public get record() { return this.service.order }
  public get product() { return this.service.selectedProduct }
  public get editing() { return this.service.editing }


  public newMaterialType: 'new' | 'search' = 'new';

  public get isRepair(): boolean {
    return !!this.product.productRepairPlan;
  }

  public initializeMaterial() {
    if (this.product?.material) {
      if (!this.product?.blankDimensions || !this.product?.finishedDimensions) {
        this.initializeWorkingDimensions(this.product?.material.materialType, this.product?.material.materialDimensions);
      }
    }
    else if (!this.product?.material && this.materialSelect && this.service.productMaterialParameters[this.product?.productId]) {
      const { params, dimensions, state, hasFinishedSpecs } = JSON.parse(JSON.stringify(this.service.productMaterialParameters[this.product?.productId]));
      this.materialSelect.materialParameters = params;
      this.materialSelect.dimensions = dimensions;
      this.materialSelect._state = state;
      this.materialSelect.dirty = true;
      this.setupFakeMaterial();
    } else if (this.materialSelect) {
      this.materialSelect.resetMaterialParameters();
    }
  }

  ngOnInit(): void {
  }

  private updateProgress() {
    if (!this.progressService.shouldShowIndicators()) return;
    const status = this.progressService.getSimpleTaskStatus(this.product.productId, "material", this.service.selectedTopLevelProduct.productId);
    if (status === TaskStatus.NOT_STARTED) this.progressService.setSimpleTaskStatus(this.product.productId, "material", this.service.selectedTopLevelProduct.productId, TaskStatus.IN_PROGRESS);
    this.service.notifyEdited();
  }

  @ViewChild('form') form: NgForm;
  private formSubscription: Subscription;
  private setupSubscription() {
    if (this.formSubscription) this.formSubscription.unsubscribe();
    this.initializeMaterial();
    setTimeout(() => {
      this.formSubscription = this.form?.valueChanges.subscribe(() => {
        setTimeout(() => this.service.notifyEdited());
      });
    });
  }
  
  ngAfterViewInit() {
    this.setupSubscription();
    this.service.productChange.subscribe(() => this.setupSubscription());
  }

  ngOnDestroy(): void {
    this.formSubscription && this.formSubscription.unsubscribe();
  }

  @ViewChild('materialSelect') materialSelect: MaterialSelectComponent;
  public get showParameters() {
    return !!this.product.material || (this.materialSelect && this.materialSelect.materialFullyInput) 
  }

  public onMaterialSearchSelect(mat: Material) {
    this.product.materialId = mat.materialId;
    this.initializeWorkingDimensions(mat.materialType, mat.materialDimensions);
    this.updateProgress();
  }

  public onNewMaterialTypeChange(value: 'new' | 'search') {
    if (value === 'new') {
      this.product.material = null;
      this.product.materialId = null;
      this.service.notifyEdited();
    } else if (value === 'search') {
      this.materialSelect && this.materialSelect.resetMaterialParameters();
      this.service.notifyEdited();
    }
  }

  public initializeWorkingDimensions(type: MaterialType, dimensions: MaterialDimension[]) {
    const defaultDimensions = type.materialTypeDimensions.map<ProductMaterialDimension>(mtd => {
      const dim = dimensions.find(md => md.materialTypeDimensionId === mtd.materialTypeDimensionId)
      if (!dim) return { materialTypeDimensionId: mtd.materialTypeDimensionId, value: 0 };
      else return { materialTypeDimensionId: mtd.materialTypeDimensionId, value: dim.value };
    })
    this.product.blankDimensions = JSON.parse(JSON.stringify(defaultDimensions));
    this.product.finishedDimensions = JSON.parse(JSON.stringify(defaultDimensions));
    this.service.notifyEdited();
  }

  public onMaterialFullyInput(params: MaterialSelectComponent['materialParameters'] & { dimensions: MaterialDimension[] }) {
    this.updateProgress();
    this.setupFakeMaterial();
    this.initializeWorkingDimensions(params.type, params.dimensions);
    this.updateServiceMaterial();
  }

  public get materialQuoteId() {
    // provide product guid when material is being edited
    if (this.newMaterialType === 'new' && this.materialSelect?.dirty) return this.product.productId;
    else return this.product.materialId;
  }

  public setMaterialPrice(quote: MaterialBid) {
    this.product.materialLotCost = quote.totalBid ? (quote.totalBid / quote.qty) : quote.perItemBid;
    this.service.notifyEdited();
  }

  public togglePartsPerMaterialOverride(e) {
    e.preventDefault();
    this.product.partsPerMaterialOverride = !this.product.partsPerMaterialOverride;
    this.service.notifyEdited();
  }

  public get firstQuantity(): number {
    if (this.product.quantityAsChild) return this.product.quantityAsChild;
    if (this.product.orderQuantity) return this.product.orderQuantity;
    if (this.product.quantitiesMap == null) return null;

    var quantities = this.product.quantitiesMap;

    if (quantities.length == 0) return null;

    return quantities[0].value;
  }

  public get rawWeight(): number {
    if (this.newMaterialType === 'new' || !this.editing) {
      if (!this.materialSelect || !this.materialSelect.calculatedVolume || !this.materialSelect.materialDensity) return null;
      return this.materialSelect && this.materialSelect.calculatedVolume * this.materialSelect.materialDensity
    } else {
      return Material.getVolume(this.product.material) * this.product.material.density;
    }
  }

  public get rawWeightDisplay(): string {
    return this.rawWeight ? this.rawWeight.toFixed(2) + 'lb' : null;
  }

  private fakeMaterial: Material;
  public setupFakeMaterial() {
    this.fakeMaterial = <Material>{
      materialType: this.materialSelect.materialParameters.type,
      materialDimensions: JSON.parse(JSON.stringify(this.materialSelect.dimensions)),
      density: this.materialSelect.materialDensity
    }
  }

  public get material() {
    if (this.product.material) return this.product.material;
    else if (this.newMaterialType === 'new') {
      if (this.materialSelect?.materialFullyInput) {
        return this.fakeMaterial;
      } else return null;
    }
  }

  public get blankWeight(): number {
    return Product.getBlankWeight(this.product, this.material);
  }

  public get blankWeightDisplay(): string {
    return this.blankWeight ? this.blankWeight.toFixed(2) + 'lb' : null;
  }

  public get finishedWeight(): number {
    return Product.getFinishedWeight(this.product, this.material);
  }

  public get finishedWeightDisplay(): string {
    return this.finishedWeight ? this.finishedWeight.toFixed(2) + 'lb' : null;
  }

  public get materialRemoved(): number {
    return Product.getMaterialRemoved(this.product, this.material);
  }

  public get piecesFromLot(): number {
    return Product.getPiecesFromLot(this.product, this.material);
  }

  public get piecesFromLotAfterOverride(): number {
    return this.product.partsPerMaterialOverride ? this.product.partsPerMaterial : this.piecesFromLot;
  }

  public get materialMarkupFactor(): number {
    if (!this.product.materialMarkup) return 1;
    return (1 + ((this.product.materialMarkup || 0.0) / 100.0));
  }

  public get materialCost(): number {
    return Product.getMaterialCost(this.product, this.firstQuantity, this.material);
  }

  public getBlankDimension(mtdId: string) {
    const dimensions = this.product.blankDimensions || [];
    const out = dimensions.find(d => d.materialTypeDimensionId === mtdId);
    if (!out) return 0;
    else return out.value;
  }

  public setBlankDimension(mtdId: string, value: number) {
    const dimensions = this.product.blankDimensions || [];
    const dim = dimensions.find(d => d.materialTypeDimensionId === mtdId);
    if (!dim) {
      dimensions.push({ materialTypeDimensionId: mtdId, value })
    }
    else {
      dim.value = value;
    }
  }

  public getFinishedDimension(mtdId: string) {
    const dimensions = this.product.finishedDimensions || [];
    let out = dimensions.find(d => d.materialTypeDimensionId === mtdId);
    if (!out) return 0;
    else return out.value;
  }

  public setFinishedDimension(mtdId: string, value: number) {
    const dimensions = this.product.finishedDimensions || [];
    const dim = dimensions.find(d => d.materialTypeDimensionId === mtdId);
    if (!dim) {
      dimensions.push({ materialTypeDimensionId: mtdId, value })
    }
    else {
      dim.value = value;
    }
  }

  public get complexity() {
    if (this.product.complexity) return this.product.complexity
    return Math.round((this.materialRemoved || 0) / 10);
  }

  public set complexity(val) {
    Product.overrideComplexity(this.product, val)
  }

  public resetComplexity() {
    Product.resetComplexity(this.product)
  }

  public updateServiceMaterial() {
    if (!this.materialSelect || !this.materialSelect.dirty || !this.materialSelect.materialFullyInput) {
      this.service.productsWithInputMaterial[this.product.productId] = undefined;
      this.service.productMaterialParameters[this.product.productId] = undefined;
    } else {
      this.product.material = null;
      this.service.notifyEdited();
      this.service.productsWithInputMaterial[this.product.productId] = JSON.parse(JSON.stringify({ ...this.materialSelect.parameterIds }));
      this.service.productMaterialParameters[this.product.productId] = JSON.parse(JSON.stringify({ 
        params: this.materialSelect.materialParameters,
        dimensions: this.materialSelect.dimensions,
        state: this.materialSelect.state,
        hasFinishedSpecs: this.materialSelect.hasFinishedSpecs,
        density: this.materialSelect.materialDensity
      }));
    }
  }

  public getMaterialTaskStatus() {
    return this.progressService.getSimpleTaskStatus(this.product.productId, 'material', this.service.selectedTopLevelProduct.productId)
  }

  public setMaterialTaskStatus(status: TaskStatus) {
    return this.progressService.setSimpleTaskStatus(this.product.productId, 'material', this.service.selectedTopLevelProduct.productId, status)
  }

}
