import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { MessageService } from '../../common/services/message.service';
import { catchError, tap } from 'rxjs/operators';
import { ErrorHandlerService } from '../../common/services/errorHandler.service'
import { SearchResult } from '../../common/resources/search-result';
import { httpService } from '../../common/services/http.service';
import { QualityTicket, QualityTicketDocument, QualityTicketType } from '../resources/quality-ticket';
import { VirtualDocument } from '../../common/resources/virtual-document';
import { MessageType } from '../../common/resources/message';
import { ContractReviewCategory } from '../resources/contract-review-questions';
import { User } from '../../common/resources/user';
import { InspectionItem, InspectionTicket } from '../resources/inspection-ticket';
import { Department } from '../../floor/resources/building';

@Injectable({
  providedIn: 'root',
})
export class QualityService extends httpService {
  private apiBase: string = 'api/qualityticket';
  private apiUrl: string;

  constructor(errorHandler: ErrorHandlerService, private messages: MessageService, private http: HttpClient, @Inject('BASE_URL') private baseUrl: string) {
    super(errorHandler, messages);
    this.serviceName = "Quality";

    this.apiUrl = this.baseUrl + this.apiBase;
  }

  public getDetail(id: string): Observable<QualityTicket> {
    return this.http.get<QualityTicket>(this.apiUrl + '/' + id).pipe(
      catchError(this.handleError<any>("Get Quality Ticket Detail", null))
    );
  }

  public search(searchString?: string, overrideView?: boolean, page?: number, sortBy?: string, sortDirection?: string, types?: QualityTicketType[]): Observable<SearchResult<QualityTicket>> {
    let params = new HttpParams({
      fromObject: { searchText: searchString, forAllUsers: ((overrideView || false) ? "true" : "false"), pageIndex: (page || 0).toString(), orderBy: sortBy || "name", direction: sortDirection || "asc" }
    });
    types.forEach(t => params = params.append('types', t.toString()));
    return this.http.get<SearchResult<QualityTicket>>(this.apiUrl + '/search', { params }).pipe(
      catchError(this.handleError<any>("Get Quality Tickets Search Results", null))
    );
  }

  public save(item: QualityTicket): Observable<QualityTicket> {
    item.assignedUser = null;
    item.orderSegment = null;
    item.qualityTicketDocuments = null;
    item.shippingTicketPlaceLocation = null;

    return this.http.post<QualityTicket>(this.apiUrl, item).pipe(
      catchError(this.handleError<any>("Get Quality Ticket Detail", null))
    );
  }

  public approveTicket(item: QualityTicket): Observable<void> {
    return this.http.post<any>(this.apiUrl + '/approve/' + item.qualityTicketId, null).pipe(
      catchError(this.handleError<any>("Approve Quality Ticket", null))
    );
  }

  public approveReceivingTicket(item: QualityTicket, data: {
    heatLot: string,
    expirationDate: Date | null
  }): Observable<void> {
    return this.http.post<any>(this.apiUrl + '/approveReceiving/' + item.qualityTicketId, data).pipe(
      catchError(this.handleError<any>("Approve Quality Ticket", null))
    );
  }
  public rejectTicket(item: QualityTicket, rejectionNote: string): Observable<void> {
    return this.http.post<any>(this.apiUrl + '/reject/' + item.qualityTicketId, rejectionNote).pipe(
      catchError(this.handleError<any>("Reject Quality Ticket", null))
    );
  }


  public addDocuments(ticket: QualityTicket, documents: VirtualDocument[]): Observable<QualityTicketDocument[]> {
    return this.http.post<QualityTicketDocument[]>(this.apiUrl + '/adddocuments?qualityTicketId=' + ticket.qualityTicketId, documents.map(d => d.documentId)).pipe(
      tap(_ => this.messages.add("Quality Service: Documents Updated", MessageType.SUCCESS, true)),
      catchError(this.handleError<any>("Add Documents to Quality Ticket", null))
    );
  }

  public removeDocument(ticket: QualityTicket, document: VirtualDocument): Observable<any> {
    return this.http.get<any>(this.apiUrl + '/removedocument?qualityTicketId=' + ticket.qualityTicketId + '&documentId=' + document.documentId).pipe(
      tap(_ => this.messages.add("Quality Service: Document Removed Successfully", MessageType.SUCCESS, true)),
      catchError(this.handleError<any>("Remove Document from Quality Ticket", null))
    );
  }


  public getContractReviewChecklist(): Observable<SearchResult<ContractReviewCategory>> {
    return this.http.get<SearchResult<QualityTicket>>(this.baseUrl + 'api/ContractReviewCategory/search', { params: { searchText: "", pageIndex: "0", orderBy: "name", direction: "asc", pageSize: "1000" } }).pipe(
      catchError(this.handleError<any>("Get Contract Review Checklist", null))
    );
  }

  public assign(ticket: QualityTicket, toUser: User): Observable<void> {
    return this.http.get<void>(this.apiUrl + `/assign/${ticket.qualityTicketId}?userId=${toUser.userId}`).pipe(
      tap(_ => this.messages.add("Quality Service: Assign Ticket", MessageType.SUCCESS, true)),
      catchError(this.handleError<void>("Assign Ticket", null))
    );
  }

  public renewCertificate(ticket: QualityTicket, newExpirationDate: Date): Observable<void> {
    return this.http.post<void>(this.apiUrl + `/renewCertificate/${ticket.qualityTicketId}`, newExpirationDate).pipe(
      tap(_ => this.messages.add("Quality Service: Renew Certificate", MessageType.SUCCESS, true)),
      catchError(this.handleError<void>("Renew Certificate", null))
    );
  }

  public approveRMARequest(ticket: QualityTicket): Observable<void> {
    return this.http.post<void>(this.apiUrl + `/approveRMARequest/${ticket.qualityTicketId}`, null).pipe(
      tap(_ => this.messages.add("Quality Service: RMA Request Approved", MessageType.SUCCESS, true)),
      catchError(this.handleError<void>("Approve RMA Request", null))
    );
  }

  public rejectRMARequest(ticket: QualityTicket, rejectionNote: string): Observable<void> {
    console.log(rejectionNote);
    return this.http.post<void>(this.apiUrl + `/rejectRMARequest/${ticket.qualityTicketId}`, rejectionNote).pipe(
      tap(_ => this.messages.add("Quality Service: RMA Request Rejected", MessageType.SUCCESS, true)),
      catchError(this.handleError<void>("Reject RMA Request", null))
    );
  }

  public disposition(ticket: QualityTicket): Observable<void> {
    return this.http.post<void>(this.apiUrl + `/disposition/${ticket.qualityTicketId}`, null).pipe(
      tap(_ => this.messages.add("Quality Service: RMA Disposition", MessageType.SUCCESS, true)),
      catchError(this.handleError<void>("Disposition", null))
    );
  }

  // public getInspectionTodayTickets(buildingId: string, filter: string) {
  public getInspectionTodayTickets(filter: string) {

    let params = new HttpParams({
      // fromObject: { buildingId, filter }
      fromObject: { filter }
    });

    return this.http.get<InspectionTicket[]>(this.baseUrl + 'api/QualityTicket/inspectionTodayTickets', { params }).pipe(
      catchError(this.handleError<any>("Get Tool Crib Tickets", null))
    );
  }

  public getInspectionTicket(ticketId: string) {
    return this.http.get<InspectionTicket>(this.baseUrl + 'api/InspectionTicket/' + ticketId).pipe(
      catchError(this.handleError<any>("Get Inspection Ticket Detail", null))
    )
  }

  public submitInspection(ticket: InspectionTicket) {
    return this.http.post<InspectionTicket>(this.baseUrl + 'api/QualityTicket/submitInspection', ticket).pipe(
      catchError(this.handleError<any>("Submit Inspection Ticket", null))
    )
  }

  public getSelfInspectionTable(item: InspectionTicket, verifyEvery: number): Observable<VirtualDocument> {
    let url = this.baseUrl + 'api/QualityTicket/generateSelfInspectionTable/' + item.inspectionTicketId + '/' + verifyEvery;
    return this.http.post<Document>(url, item.bubbleMeasurements).pipe(
      catchError(this.handleError<any>("Print Self Inspection Table", null))
    );
  }

  public finishFailedInspectionReview(inspectionTicketId: string, items: InspectionItem[], departmentAssignmentMap: { [key: string]: Department }) {
    return this.http.post<void>(this.baseUrl + 'api/QualityTicket/submitFailedInspectionReview/' + inspectionTicketId, {
      items,
      departmentAssignmentMap
    }).pipe(
      tap(_ => {
        this.messages.add("Quality Service: Finished Failed Inspection Review", MessageType.SUCCESS, true);
      }),
      catchError(this.handleError<any>("Finish Failed Inspection Review", null))
    );
  }

}
