import { Material } from "./material";
import { Workflow, WorkflowStepType } from "./workflow";
import { VirtualDocument } from "../../common/resources/virtual-document";
import evalInScope from '../../../../util/evalInScope'
import { PurchasedItem } from "./purchased-item";
import { User } from "../../common/resources/user";
import { UtilityService } from "../../common/services/utility.service";

export interface ProductMaterialDimension
{
    materialTypeDimensionId: string
    value: number
}


export class ProductRepairPlan {
    productId: string
    productRepairPlanId: string
    workflowId: string

    workflow: Workflow;
    purchasedItems: ProductPurchasedItem[];
}

export enum ProductStandardHistory {
  NEW = 0,
  RFQ = 1,
  ESTIMATED = 2,
  QUOTED = 3,
  PURCHASED = 4,
  GOLD = 100
}

export interface ProductStandard {
  productStandardId: string;
  productId: string;
  partNumber: string;
  revision: string;
  isFinal: boolean;
  history: ProductStandardHistory;
};

export class Product {
  productId: string;
  partNumber: string;
  revision: string;
  description: string;
  note: string;
  privateNote: string;
  materialId?: string;
  materialCostIsLotBased: boolean;
  materialLotCost?: number;
  useMetric: boolean;
  partsPerMaterialOverride: boolean;
  partsPerMaterial: number;
  partsPerBlankOverride: boolean;
  partsPerBlank: number;
  materialMarkup?: number;
  finishedWeight?: number;
  numberTestPeices?: number;
  leadTimeWeeks?: number;
  dateRequired?: Date;
  quantitiesMap: ProductQuantity[];
  customerQuoteLineNo: string;
  legacyId: string;
  workflowWorkflowId?: string;
  parentAssemblyId?: string;
  quantityAsChild?: number;
  pricePerUnit?: number;
  orderQuantity?: number;
  quotedUnitPrice?: number;
  selectedMaterialQuote?: string;
  leadTimeBuffer: number;
  selectedSimilarPart?: string;
  blankDimensions: ProductMaterialDimension[];
  finishedDimensions: ProductMaterialDimension[];

  topParentAssemblyId: string;

  readonly useableQuantity: number;

  parentAssembly: Product;
  childAssemblies: Product[];
  documents: ProductDocument[];

  material: Material;
  workflow: Workflow;
  purchasedItems: ProductPurchasedItem[];

  productRepairPlan?: ProductRepairPlan;

  complexity: number | null = null;
  productStandard?: ProductStandard;
  numberProductStandard?: ProductStandard;

  // static methods for calculations
  // using static methods so we don't have to bother with having serialization/deserialization for the DB

  static getVolume(product: Product, material?: Material): number {

    material = material ?? product.material;

    if (
      !product || !material ||
      !material.materialType ||
      !material.materialType.materialTypeDimensions ||
      !material.materialType.volumeFormula
    ) return 0;

    const cleanFormula = material.materialType.volumeFormula ? material.materialType.volumeFormula.replace(/\^/g, '**') : null
    if (!cleanFormula) return 0;



    const scope = (material.materialType.materialTypeDimensions.map(mtd => {
      const label = mtd.dimensionType.label
      const dimension = (material.materialDimensions || []).find(
        md => md.materialTypeDimensionId === mtd.materialTypeDimensionId
      )
      if (!dimension) return [label, 0]
      const { siEquivalent } = dimension.materialTypeDimension.dimensionUnit
      const value = dimension.value// * (siEquivalent !== null ? siEquivalent : 1)
      return [label, value]
    }))
      .reduce((obj, [key, val]) => {
        obj[key] = val
        return obj
      }, {})

    return evalInScope(cleanFormula, scope)// * (product.useMetric ? 1 : 1550)

  }

  static getBlankVolume(product: Product, material?: Material): number {

    material = material ?? product.material;

    if (
      !product || !material ||
      !material.materialType ||
      !material.materialType.materialTypeDimensions ||
      !material.materialType.volumeFormula
    ) return 0;

    const cleanFormula = material.materialType.volumeFormula ? material.materialType.volumeFormula.replace(/\^/g, '**') : null
    if (!cleanFormula) return 0;



    const scope = (material.materialType.materialTypeDimensions.map(mtd => {
      const label = mtd.dimensionType.label
      const blankDimension = (product.blankDimensions || []).find(bd => bd.materialTypeDimensionId === mtd.materialTypeDimensionId)
      const value = blankDimension ? blankDimension.value : 0;
      return [label, value]
    }))
      .reduce((obj, [key, val]) => {
        obj[key] = val
        return obj
      }, {})

    return evalInScope(cleanFormula, scope)// * (product.useMetric ? 1 : 1550)

  }

  static getFinishedVolume(product: Product, material?: Material): number {

    material = material ?? product.material;

    if (
      !product || !material ||
      !material.materialType ||
      !material.materialType.materialTypeDimensions ||
      !material.materialType.volumeFormula
    ) return 0;

    const cleanFormula = material.materialType.volumeFormula ? material.materialType.volumeFormula.replace(/\^/g, '**') : null
    if (!cleanFormula) return 0;



    const scope = (material.materialType.materialTypeDimensions.map(mtd => {
      const label = mtd.dimensionType.label
      const finishedDimension = (product.finishedDimensions || []).find(bd => bd.materialTypeDimensionId === mtd.materialTypeDimensionId)
      const value = finishedDimension ? finishedDimension.value : 0;
      return [label, value]
    }))
      .reduce((obj, [key, val]) => {
        obj[key] = val
        return obj
      }, {})

    return evalInScope(cleanFormula, scope)// * (product.useMetric ? 1 : 1550)

  }

  static getRawWeight(product: Product, material?: Material): number {

    material = material ?? product.material;

    if (
      !product || !material ||
      !material.materialType ||
      !material.materialType.materialTypeDimensions ||
      !material.materialType.volumeFormula
    ) return 0;

    else return Product.getVolume(product, material) * material.density

  }

  static getBlankWeight(product: Product, material?: Material): number {
    material = material ?? product.material;
    return Product.getBlankVolume(product, material) * material.density;
  }

  static getFinishedWeight(product: Product, material?: Material): number {
    material = material ?? product.material;
    return Product.getFinishedVolume(product, material) * material.density;
  }

  static getMaterialRemoved(product: Product, material?: Material): number | null {
    var rawWeight = Product.getBlankWeight(product, material);
    var finishedWeight = Product.getFinishedWeight(product, material);
    if (rawWeight == null || finishedWeight == null) return null;

    return (1 - (finishedWeight) / rawWeight) * 100; //As a percentage
  }

  static getPiecesFromLot(product: Product, material?: Material): number {
    material = material ?? product.material;
    if (product.partsPerMaterialOverride) return product.partsPerMaterial;
    
    if (Product.getBlankVolume(product, material) === 0) return 0;
    return Math.floor(Product.getVolume(product, material) / Product.getBlankVolume(product, material))
  }

  static getMaterialCost(product: Product, qty: number, material?: Material) {
    material = material ?? product.material;
    var lotCost = product.materialLotCost || 0;
    var ppm = Product.getPiecesFromLot(product, material);
    let cost: number;
    if (product.materialCostIsLotBased) {
      var lotsNeeded = Math.ceil(qty / ppm);
      cost = lotCost * lotsNeeded;
    } else {
      cost = (lotCost / ppm) * qty;
    }
    if (isNaN(cost) || !isFinite(cost)) {
      return 0;
    }
    return cost * (1 + ((product.materialMarkup || 0.0) / 100.0));
  }

  static getComplexity(product: Product, material?: Material): number {
    material = material ?? product.material;
    if (product.complexity) return product.complexity
    return Math.round((Product.getMaterialRemoved(product, material) || 0) / 10);
  }
  static overrideComplexity(product: Product, val: number) {
    product.complexity = val;
  }
  static resetComplexity(product: Product) {
    product.complexity = null;
  }

  static newEmptyProduct(): Product {
    return {
      productId: UtilityService.emptyGuid,
      partNumber: '',
      revision: '-',
      quantitiesMap: [],
      childAssemblies: [],
      parentAssemblyId: null,
      quantityAsChild: 1,
      complexity: null,
      customerQuoteLineNo: '',
      description: '',
      documents: [],
      leadTimeBuffer: 0,
      legacyId: '',
      material: null,
      materialCostIsLotBased: false,
      note: '',
      privateNote: '',
      parentAssembly: null,
      partsPerBlank: 0,
      partsPerBlankOverride: false,
      partsPerMaterial: 0,
      partsPerMaterialOverride: false,
      purchasedItems: [],
      useMetric: false,
      topParentAssemblyId: null,
      workflow: {
        workflowId: UtilityService.emptyGuid,
        name: '',
        workflowSteps: []
      },
      blankDimensions: [],
      finishedDimensions: [],
      useableQuantity: 0
    }
  }

  static generateNewIdsRecursive(products: Product[], parentId: string | null, topParentId: string | null, oldIdsMap: { [oldId: string]: string } = {}): Product[] {
    return products.map(p => {
      const newId = UtilityService.newGuid();
      topParentId = topParentId ?? newId;
      oldIdsMap[p.productId] = newId;
      return {
        ...p,
        parentAssemblyId: parentId,
        topParentAssemblyId: topParentId,
        productId: newId,
        childAssemblies: this.generateNewIdsRecursive(p.childAssemblies, newId, topParentId, oldIdsMap),
        documents: p.documents.map(d => ({
          ...d,
          productProductId: newId
        })),
        workflowWorkflowId: newId,
        workflow: {
          ...p.workflow,
          workflowId: newId,
          workflowSteps: p.workflow.workflowSteps.map(ws => ({
            ...ws,
            workflowStepId: UtilityService.newGuid(),
            workflowId: newId,
          }))
        },
        purchasedItems: p.purchasedItems.map(i => ({
          ...i,
          productPurchasedItemId: UtilityService.newGuid(),
          productId: newId,
        }))
      };
    })
  }
  
}

export interface ProductDocument {
  productProductId: string;
  documentDocumentId: string;

  document?: VirtualDocument
}

export interface ProductPurchasedItem {
  productPurchasedItemId: string;
  purchasedItemId: string;
  productId?: string;
  productRepairPlanId?: string;
  costPer: number;
  isAmortized: boolean;
  isNonRecurring: boolean;
  markupPercent: number;
  quantity: number;
  selectedQuote?: string;
  isFromCompare?: boolean;

  itemRepairType: WorkflowStepType;

  purchasedItem?: PurchasedItem | null;
}


export interface ProductQuantity {
  value: number;
  markup: number;
  showOnQuote: boolean;
  priceOverride?: number;
  overrideNote?: string;
}

export interface SimilarPartScore {
  productId: string;
  partNumber: string;
  revision: string;
  customerNum: number;
  businessName: string;
  score: number;
}


export interface ProductHistoryEntry {
  productHistoryEntryId: string;
  partNumber: string;
  userId: string;
  user: User;
  timestamp: Date;
  note: string;
  department: string;
}

export function getQuoteHistoryText(h: ProductStandardHistory) {
  switch (h) {
    case ProductStandardHistory.NEW:
      return 'New Part';
    case ProductStandardHistory.RFQ:
      return 'Prev. In RFQ';
    case ProductStandardHistory.ESTIMATED:
      return 'Prev. Estimated';
    case ProductStandardHistory.QUOTED:
      return 'Prev. Quoted';
    case ProductStandardHistory.PURCHASED:
      return 'Prev. Purchased';
    case ProductStandardHistory.GOLD:
      return 'Gold Part';
  }
}

export function getQuoteHistoryChipClass(h: ProductStandardHistory) {
  switch (h) {
    case ProductStandardHistory.NEW:
      return 'history-chip-new';
    case ProductStandardHistory.RFQ:
      return 'history-chip-new';
    case ProductStandardHistory.ESTIMATED:
      return 'history-chip-estimated';
    case ProductStandardHistory.QUOTED:
      return 'history-chip-quoted';
    case ProductStandardHistory.PURCHASED:
      return 'history-chip-purchased';
    case ProductStandardHistory.GOLD:
      return 'history-chip-gold';
  }
}