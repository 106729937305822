import { ProductMaterialDimension } from "../../order/resources/product";
import { Workflow, WorkflowStep } from "../../order/resources/workflow";
import { MachineAssignment } from "../../planning/resources/machine-assignment";
import { RMARepairType } from "../../rma/resources/rma-ticket";

export enum InspectionType
{
    FIRST_PART = 0,
    FINAL_INSPECTION = 1
}

export interface BubbleMeasurement {
    bubbleNumber: string
    measurementTool: string
    bubbleSnippetDocumentId?: string
}

export interface InspectionItem {
    approved: boolean,
    note?: string,
    disposition?: RMARepairType;
    reworkWorkflowSteps?: WorkflowStep[];
}

export interface InspectionTicket {
    inspectionTicketId: string;
    status: number;
    inspectionType: InspectionType;
    requiresReport: boolean;
    isCMM: boolean;
    isDeleted: boolean;
    partAvailableTime?: Date;
    startedTime?: Date;
    finishedTime?: Date;
    machineAssignmentId: string;
    machineAssignment: MachineAssignment;
    inspectionNotes: string;
    bubbleMeasurements: BubbleMeasurement[];
    inspectedItems: InspectionItem[];
    repairWorkflowId?: string;
    repairWorkflow?: Workflow;
}