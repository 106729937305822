import { Component, Input } from '@angular/core';
import { Order, OrderStatus } from '../../resources/order';
import { UserService } from '../../../common/services/user.service';
import { FilterParam, SearchData } from '../../../common/components/search-list-new/search-list-new.component';
import { User } from '../../../common/resources/user';
import { OrderService } from '../../services/order.service';
import { Router } from '@angular/router';
import { EstimateProgressService } from '../../services/estimate-progress.service';
import { ProductStandardHistory, getQuoteHistoryChipClass, getQuoteHistoryText } from '../../resources/product';
import { TaskStatus } from '../../../common/resources/estimatingtask';

@Component({
  selector: 'estimate-scorecard',
  templateUrl: './estimate-scorecard.component.html',
  styleUrls: ['./estimate-scorecard.component.less']
})
export class EstimateScorecardComponent {
  @Input() displayedColumns: string[] = ['quoteNumber', 'createdDate', 'received', 'required', 'estimatedEstimateCompletionDate', 'estimateApprovalDate','partHistory', 'hasIncomplete', 'rfqApprovalDate', 'estimateLate', 'daysToQuote', 'estimateProgress', 'assigned', 'customer', 'status'];
  @Input() orderNumberTitle = 'Estimate';
  @Input() showAdd = false;
  @Input() showManagerToggle = true;
  @Input() alwaysManager = false;
  @Input() path = '/estimating/';
  @Input() discriminator = 'Estimate';
  @Input() localStorageId = 'sales-estimate-scorecard';

  public readonly availableStatuses = [OrderStatus.IN_PROCESS, OrderStatus.AWAITING_REVIEW, OrderStatus.REJECTED, OrderStatus.TENT_APPROVED, OrderStatus.APPROVED, OrderStatus.CLOSED, OrderStatus.HISTORY] as const;

  public readonly filterParams: FilterParam[] = [{
    categoryName: 'status',
    categoryTitle: 'Status',
    options: this.availableStatuses.map(s => ({
      class: OrderStatus.getStatusColorClassChip(s),
      title: OrderStatus.getStatusText(s),
      value: s
    }))
  }];

  public readonly defaultStatuses = [OrderStatus.IN_PROCESS, OrderStatus.AWAITING_REVIEW, OrderStatus.REJECTED] as const;

  public readonly defaultFilters = { status: this.defaultStatuses }

  public loading = true;

  constructor(private userService: UserService, private orderService: OrderService, private router: Router) {
  }

  public isManager(user: User): boolean {
    return this.userService.canAccess('EstimateManager', user);
  }

  public search({ searchText, page, sort, forAllUsers, fieldSearches, filters, groupBy }: SearchData) {
    return this.orderService.search(this.discriminator, forAllUsers ?? false, searchText, page.pageIndex, sort.active, sort.direction, page.pageSize, fieldSearches, filters, groupBy);
  }

  public onSelect(record: Order): void {
    this.router.navigate([this.path, record.orderSegmentId]);
  }

  public getStatusColorClass(status: number): string {
    return OrderStatus.getStatusColorClassChip(status);
  }

  public getStatusText(status: number): string {
    if (status === OrderStatus.TENT_APPROVED) return "Tent. Approved";
    if (status === OrderStatus.AWAITING_REVIEW) return "Aw. Review";
    if (status === OrderStatus.AWAIT_ESTIMATE) return "Aw. Estimate";
    return OrderStatus.getStatusText(status);
  }

  public getProgress(o: Order) {
    return EstimateProgressService.getTaskListProgress(o.estimatingTaskList);
  }

  public getDaysLate(since: string) {
    return Math.max(0, Math.floor((Date.now() - +new Date(since))/(24*60*60*1000)));
  }

  public getDaysBetween(since: string, until: string) {
    return Math.floor((+new Date(until) - +new Date(since))/(24*60*60*1000));
  }

  public getQuoteHistoryText(h: ProductStandardHistory) {
    return getQuoteHistoryText(h);
  }

  public getQuoteHistoryChipClass(h: ProductStandardHistory) {
    return getQuoteHistoryChipClass(h);
  }

  public truncateQuoteNumber(str: string) {
    return str?.replace(/\w{3}\-0+/, '');
  }

  public getHighlightClass(due: string) {
    if (!due) {
      return '';
    }

    const target = new Date(due);
    const now = new Date();
    const daysBetween = (+target - +now) / (24*60*60*1000);

    if (daysBetween < 1) {
      return 'bg-danger';
    } else if (daysBetween < 3) {
      return 'bg-warning';
    }

    return '';
  }

  public possibleProgressStatuses = Object.values(TaskStatus).filter(x => typeof x === 'number');
  public getProgressColorClass(status: TaskStatus) {
    return EstimateProgressService.getStatusColorClass(status);
  }
  public getProgressStatusText(status: number) {
    return EstimateProgressService.getStatusText(status);
  }
}