<mat-sidenav-container style="
padding-top: 15px;
margin-right: 0 !important;
margin-left: 0 !important;"
>
  <mat-sidenav disableClose #sidenav mode="over" position="end">
    <tool-crib-fulfill *ngIf="selectedTicket" [ticket]="selectedTicket" [building]="currentBuilding" (done)="onDone()" (closed)="onClose()"></tool-crib-fulfill>
  </mat-sidenav>
  <mat-sidenav-content class="border rounded">
      <mat-tab-group color="primary" backgroundColor="primary" class="h-100 queue-tab-group">
        <mat-tab [class.invisible]="!currentBuilding">
          <ng-template mat-tab-label>
            <mat-icon>today</mat-icon>
            <span class="tab-title">Today</span>
            <span class="badge bg-white text-primary mx-1" *ngIf="todayTickets?.length > 0">
              {{todayTickets.length}}
            </span>
            <i class="ml-1 fas fa-circle-notch fa-spin" [class.invisible]="todayTickets"></i>
          </ng-template>
          <div class="bg-white">
            <mat-selection-list
              *ngIf="todayTickets">
              <ng-container *ngFor="let ticket of todayTickets">
                <mat-list-option matRipple matRippleColor="rgba(0,0,0,.04)" #option class="ticket-line" [class.ticket-line-selected]="option.selected" [class.alert-danger]="isOverdue(ticket)">
                  <div class="row align-items-center">
                    <div class="col-1">
                      <div mat-line class="font-weight-bold mat-line small text-muted text-uppercase" style="letter-spacing: 1px" *ngIf="!isOverdue(ticket)">Needed by</div>
                      <div class="badge badge-danger p-1" *ngIf="isOverdue(ticket)">OVERDUE</div>
                      <div mat-line style="font-size: 2em; font-weight: bold; line-height: 1em;" [class.text-danger]="isOverdue(ticket)">{{ ticket.machineAssignment.scheduledStart | date: 'HH:mm' }}</div>
                      <div class="mat-line font-weight-bold small text-uppercase" *ngIf="!isToday(ticket.machineAssignment.scheduledStart)">
                        {{ ticket.machineAssignment.scheduledStart | date: 'yyyy/MM/dd' }}
                      </div>
                    </div>
                    <div class="col-5">
                      <item-type-chip [item]="ticket.inventoryItem" [iconOnly]="true" class="mr-2"></item-type-chip>
                      <span class="font-weight-bold">{{ ticket.inventoryItem.name }}</span>
                      <span class="ml-1">x{{ ticket.quantityRequired }}</span>
                    </div>
                    <div class="col-2">
                      <div class="mat-line font-weight-bold small text-muted text-uppercase">
                        needed at
                      </div>
                      <div class="mat-line font-weight-bold">
                        {{ ticket.machineAssignment.machine.name }}
                      </div>
                    </div>
                    <div class="col-2">
                      <div class="mat-line font-weight-bold small text-muted text-uppercase">
                        Work order #
                      </div>
                      <div class="mat-line font-weight-bold">
                        {{ ticket.machineAssignment.workOrder.workOrderNumber }}
                      </div>
                    </div>
                    <div class="col-2 action-bar-outer px-0">
                      <div class="d-flex align-items-center action-bar">
                          <button 
                          mat-raised-button 
                          matRippleColor="rgba(0,0,0,.04)" 
                          class="text-white bg-primary"
                          (click)="this.selectTicket(ticket)">
                              <mat-icon matPrefix>assignment</mat-icon>
                              Fulfill
                          </button>
                      </div>
                  </div>
                  </div>
                </mat-list-option>
                <mat-divider [class.border-danger]="isOverdue(ticket)"></mat-divider>
            </ng-container>
            </mat-selection-list>
          </div>
        </mat-tab>
        <mat-tab disabled>
          {{ currentBuilding }}
          <ng-template mat-tab-label>
            <div class="btn-group m-2">
              <button class="btn btn-outline-light building-label" disabled>Building</button>
              <a class="btn btn-light btn-sm dropdown-toggle" href="#" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                {{currentBuilding?.name || "--"}}
              </a>
              <div class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuLink">
                <p class="text-center text-muted" *ngIf="!($buildings | async)">
                  <i class="fas fa-circle-notch fa-spin fa-fw text-primary"></i>
                  Loading...
                </p>
                <p class="text-center text-muted font-italic" *ngIf="($buildings | async)?.length==0">
                  <i class="fas fa-exclamation-triangle fa-fw text-danger"></i>
                  <small>No Departments Found!</small>
                </p>
                <a *ngFor="let b of ($buildings | async)" [ngClass]="{'active': (currentBuilding?.buildingId==b.buildingId)}" class="dropdown-item" (click)="selectBuilding(b)">{{b.name}}</a>
              </div>
            </div>
          </ng-template>
        </mat-tab>
      </mat-tab-group>
  </mat-sidenav-content>
</mat-sidenav-container>