<loading *ngIf="!detail || !poDetail"></loading>
<loading *ngIf="saving" content="Saving..."></loading>

<mat-card *ngIf="poDetail && detail" class="p-0 rounded">
  <mat-card-content>
    <table class="table table-striped table-sm mb-0">
      <thead>
        <tr>
          <th>
            &nbsp;
          </th>
          <th>
            Line Item Name
          </th>
          <th>
            Line Item Qty
          </th>
          <th>Assigned</th>
        </tr>
      </thead>
      <tbody>
        <ng-container *ngFor="let materialBid of detail">
          <tr class="cursor-pointer" (click)="materialBid.expanded = !materialBid.expanded">
            <td>
              <i class="text-muted fas fa-fw"
                [ngClass]="{'fa-plus': !materialBid.expanded, 'fa-minus': materialBid.expanded}"></i>
            </td>
            <td>
              {{getProductInfo(materialBid.bid)}}
            </td>
            <td>
              {{materialBid.availableQty}}
            </td>
            <td>
              {{getNumberAssigned(materialBid.bid.materialBidId)}}/{{materialBid.availableQty}}
            </td>
          </tr>
          <tr *ngIf="materialBid.expanded">
            <td colspan="4" class="px-0">
              <div>
                <div class="d-flex align-items-center px-3 py-1 border-bottom w-full" style="font-size: small"
                  *ngFor="let workOrder of materialBid.assignableWOs" [class.wo-override]="workOrder.override">
                  <a target="_blank" [href]="'/planning/' + workOrder.wo.workOrderId">{{workOrder.wo.workOrderNumber}}</a>
                  <div class="col flex-grow-1 form-inline">
                    <mat-checkbox class="mr-2" #useMe
                      [checked]="assignmentMap[materialBid.bid.materialBidId] && assignmentMap[materialBid.bid.materialBidId][workOrder.wo.workOrderId] !== undefined"
                      (change)="toggleWO($event.checked, materialBid.bid.materialBidId, workOrder.wo.workOrderId)">
                    </mat-checkbox>
                    <input appStaticEdit
                      [value]="getWOAssignment(materialBid.bid.materialBidId, workOrder.wo.workOrderId)"
                      [editable]="true" class="w-25 pl-2 form-control form-control-sm text-right" (change)="setWOAssignment(materialBid.bid.materialBidId, workOrder.wo.workOrderId,
                    $event.target.value)" #selectedQty type="number" step="1" min="0" [max]="getMaxAssignable(workOrder.wo, materialBid.bid, materialBid.availableQty, workOrder.unfulfilled)" value="0" [disabled]="!useMe.checked" />
                    &nbsp;of {{workOrder.unfulfilled}}
                    <span class="badge badge-sm bg-primary ml-1" *ngIf="workOrder.override">OVERRIDE</span>
                  </div>
                </div>
                <button class="btn btn-sm btn-primary my-2 mx-3" (click)="assignOtherWO(materialBid.bid)">Assign Other
                  WO</button>
              </div>
            </td>
          </tr>
        </ng-container>
      </tbody>
    </table>
    <div class="d-flex align-center justify-content-end border-top p-3">
      <button class="btn btn-success" [disabled]="getTotalAssigned() <= 0" (click)="submit()">Confirm</button>
    </div>
  </mat-card-content>
</mat-card>


<ng-template let-data #otherWOSearchDialog>
  <h2 class="mat-card-title text-left">
    Assign Other WO
  </h2>
  <h3 class="mat-card-subtitle text-left">
    {{getProductInfo(data.bid)}}
  </h3>
  <div class="py-3">
    <div class="px-6 py-2">
      <mat-form-field dense class="w-100" appearance="outline">
        <mat-icon class="mr-2" matPrefix>search</mat-icon>
        <input type="text" matInput ngModel (ngModelChange)="searchWOs($event, data.bid)"
          placeholder="Search for a work order...">
      </mat-form-field>
    </div>
    <div class="border rounded" *ngIf="!!otherWOResults">
      <table class="wo-table table small mb-0">
        <thead>
          <tr>
            <th>WO #</th>
            <th>Vendor</th>
            <th>Part #</th>
            <th class="text-right">
              <span [class.invisible]="!otherWOResultsSearching">
                <i class="fa fa-sm fa-spin fa-circle-notch text-primary"></i>
              </span>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let wo of otherWOResults">
            <td>{{wo.workOrderNumber}}</td>
            <td>
              {{wo.order.customer.businessName}}
            </td>
            <td>
              {{wo.product.partNumber}} Rev.
              {{wo.product.revision}}
            </td>
            <td align="end">
              <button class="btn btn-sm btn-primary" [matDialogClose]="wo">Select</button>
            </td>
          </tr>
          <tr *ngIf="otherWOResults.length === 0">
            <td colspan="4" class="text-center bg-light text-muted font-italic">
              -- No data --
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</ng-template>


<ng-template let-data #purchasedItemOverrideTemplate>
  <div>
    <h3>{{getProductInfo(data.bid)}}</h3>
    <p>How many of this purchased item would you like to add to the work order?</p>
    <mat-form-field appearance="outline" class="text-right">
      <input matInput type="number" min="1" step="1" ngModel #qtyInput="ngModel">
    </mat-form-field>
  </div>
  <mat-card-actions align="end">
    <button mat-raised-button [matDialogClose]="null">Cancel</button>
    <button mat-raised-button color="primary" [disabled]="!qtyInput.value || !isInteger(qtyInput.value)" [matDialogClose]="{ qty: qtyInput.value }">Accept</button>
  </mat-card-actions>
</ng-template>

<ng-template let-data #materialOverrideTemplate>
  <div>
    <h3>{{getProductInfo(data.bid)}}</h3>
    <p>How would you like to assign this material to the work order?</p>
    <mat-radio-group ngModel #materialOptionModel="ngModel" class="d-flex flex-column w-100">
      <mat-radio-button value="child">
        In a new part as a child of the work order part
      </mat-radio-button>
      <mat-radio-button value="sibling">
        In a new part within a new assembly including both the new and existing parts
      </mat-radio-button>
      <mat-radio-button value="replace">
        <span *ngIf="data.wo.product.material">Replace the part's existing material</span>
        <span *ngIf="!data.wo.product.material">Set as the part's material</span>
      </mat-radio-button>
    </mat-radio-group>
    <div>
      <div class="form-group" [class.invisible]="materialOptionModel.value !== 'sibling'">
        <label>Parent Assembly</label>
        <input type="text"
          ngModel
          #parentPartNumber="ngModel"
          placeholder="Part Number"
          class="form-control form-control-sm form-inline mb-1">
          <input type="text"
          ngModel
          #parentPartRev="ngModel"
          placeholder="Rev"
          class="form-control form-control-sm form-inline">
      </div>
      <div class="form-group" [class.invisible]="materialOptionModel.value !== 'child' && materialOptionModel.value !== 'sibling'">
        <label>New Child Part</label>
        <input type="text"
          ngModel
          #childPartNumber="ngModel"
          placeholder="Part Number"
          class="form-control form-control-sm form-inline mb-1">
          <input type="text"
          ngModel
          #childPartRev="ngModel"
          placeholder="Rev"
          class="form-control form-control-sm form-inline">
      </div>
    </div>
  </div>
  <mat-card-actions align="end">
    <button mat-raised-button [matDialogClose]="null">Cancel</button>
    <button mat-raised-button color="primary" [disabled]="
    !materialOptionModel.value ||
    (materialOptionModel.value === 'child' && (!childPartNumber.value || !childPartRev.value)) ||
    (materialOptionModel.value === 'sibling' && ((!childPartNumber.value || !childPartRev.value) || (!parentPartNumber.value || !parentPartRev.value)))
    "
    [matDialogClose]="{
      option: materialOptionModel.value,
      childPartNumber: childPartNumber.value,
      childPartRev: childPartRev.value,
      parentPartNumber: parentPartNumber.value,
      parentPartRev: parentPartRev.value
    }">Accept</button>
  </mat-card-actions>
</ng-template>