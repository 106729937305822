<div class="pt-3">
  <a *ngIf="!forSearch" class="position-fixed fixed-bottom btn btn-badge bg-danger has-icon" title="Close"
    (click)="close()"><i class="fas fa-times"></i></a>

  <loading *ngIf="saving" content="Saving..."></loading>

  <div class="container-fluid pb-4">

    <div class="p-0 m-0 row">
      <div class="col-4" *ngIf="!forSearch">
        <material-type-list (selected)="select($event)"></material-type-list>
        <div class="mt-2">
          <div class="text-right">
            <button class="btn btn-success" (click)="add()">
              <i class="fas fa-fw la-lg fa-plus"></i>
              Add Material Form
            </button>
          </div>
        </div>
      </div>
      <div [class]="forSearch ? 'col-12' : 'col-8'">
        <div *ngIf="!selected">
          <p class="text-muted text-center font-italic p-5">-- Select a Material Form to View its Details --</p>
        </div>
        <div *ngIf="selected">
          <div class="container-fluid">
            <div class="row">
              <div class="col">
                <div class="form-group">
                  <label>Type Name</label>
                  <input [(ngModel)]="selected.name" class="form-control" placeholder="Type Name" />
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <div class="form-group">
                  <label>Display Template</label>
                  <div class="align-items-center d-flex px-3 row">
                    <input [(ngModel)]="selected.displayTemplate" (ngModelChange)="validateDisplayTemplate()"
                      class="form-control col-8 flex-grow-1" placeholder="e.g. {width} x {height} x {thickness}" />
                    <div class="flex-grow-0 col-4 text-muted font-weight-bold font-italic">Preview:
                      {{ displayTemplateExample }}</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <div class="form-group">
                  <label>Volume Formula</label>
                  <volume-formula #volumeFormula [materialType]="this.selected" [editing]="true"></volume-formula>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <div class="form-group">
                  <div class="d-flex">
                    <label>Dimensions</label>
                    <div *ngIf="dimensionDataLoading">
                      <i class="fas fa-circle-notch fa-spin mx-2 text-primary"></i>
                      <span>Loading...</span>
                    </div>
                  </div>
                  <div class="alert alert-danger" *ngIf="failedValidation">
                    Please ensure all dimensions have a type and unit selected.
                  </div>
                  <div [class.dimensions-loading]="dimensionDataLoading">
                    <material-dimension-type-unit-select #dimensionSelector
                      *ngFor="let materialTypeDimension of selected.materialTypeDimensions; index as i"
                      [materialTypeDimension]="materialTypeDimension" [allTypes]="dimensionTypes"
                      [allUnits]="dimensionUnits" (deleted)="dimensionDeleted(i)"
                      (createType)="openNewDimensionType($event, i)" (createUnit)="openNewDimensionUnit($event, i)"
                      (updated)="onDimensionUpdated()"></material-dimension-type-unit-select>
                    <div class="btn btn-block btn-success" (click)="addDimensionTypeUnit()">
                      <i class="fas fa-plus mr-1"></i>
                      Add Dimension
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="float-right mt-4 pt-4" *ngIf="!forSearch">
            <button class="btn btn-danger mr-3" *ngIf="canRemove()" (click)="remove()">
              <i class="fas fa-fw fa-lg fa-minus-circle"></i>
              Delete
            </button>
            <button class="btn btn-success" [disabled]="!selected.name || !volumeFormulaIsValid" (click)="save()">
              <i class="fas fa-fw fa-lg fa-save"></i>
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


<ng-template #newDimensionTypeDialog let-data>
  <h2 matDialogTitle>New Dimension Type</h2>
  <mat-dialog-content>
    <div>
      <div class="form-group">
        <label>Label</label>
        <input class="form-control form-control-sm" placeholder="Label" [(ngModel)]="newDimensionType.label"
          #dimensionTypeLabel="ngModel" type="text" required>
      </div>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button mat-button [disabled]="dimensionTypeLabel.invalid" color="success"
      (click)="saveNewDimensionType(data.indexToSet)">Save</button>
    <button mat-button matDialogClose color="danger">Cancel</button>
  </mat-dialog-actions>
</ng-template>

<ng-template #newDimensionUnitDialog let-data>
  <h2 matDialogTitle>New Dimension Type</h2>
  <mat-dialog-content>
    <div class="row">
      <div class="col-12">
        <div class="form-group">
          <label>Label</label>
          <input class="form-control form-control-sm" placeholder="Label" [(ngModel)]="newDimensionUnit.label"
            #dimensionUnitLabel="ngModel" type="text" required>
        </div>
      </div>
      <div class="col-6">
        <div class="form-group">
          <label>Abbreviation</label>
          <input class="form-control form-control-sm" placeholder="Abbreviation"
            [(ngModel)]="newDimensionUnit.abbreviation" #dimensionUnitAbbreviation="ngModel" type="text" required>
        </div>
      </div>
      <div class="col-6">
        <div class="form-group">
          <label>Metric Equivalent <small>(usually meters)</small></label>
          <input class="form-control form-control-sm" placeholder="SI unit equivalent"
            [(ngModel)]="newDimensionUnit.siEquivalent" #dimensionUnitEquivalent="ngModel" type="number" min="0"
            step="0.001" required>
        </div>
      </div>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button mat-button
      [disabled]="dimensionUnitLabel.invalid || dimensionUnitAbbreviation.invalid || dimensionUnitEquivalent.invalid"
      color="success" (click)="saveNewDimensionUnit(data.indexToSet)">Save</button>
    <button mat-button matDialogClose color="danger">Cancel</button>
  </mat-dialog-actions>
</ng-template>
