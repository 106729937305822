import { Component, OnInit, ViewChild, ChangeDetectorRef, ElementRef, ViewChildren, QueryList, Pipe, PipeTransform, Input, TemplateRef, OnChanges, AfterViewInit, SimpleChanges, Output, EventEmitter } from '@angular/core';
import { MatAutocomplete, MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { MatChipInput, MatChipList } from '@angular/material/chips';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { first } from 'rxjs/operators';
import { MaterailTypeEditorComponent } from '../../../admin/components/material-type-editor/material-type-editor.component';
import { UtilityService } from '../../../common/services/utility.service';
import { Material, MaterialAlloy, MaterialDimension, MaterialGroup, MaterialHardness, MaterialSpecification, MaterialType } from '../../../order/resources/material';
import { MaterialService } from '../../../order/services/material.service';

function escapeRegExp(string) {
  if (!string) return string;
  return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
}

@Pipe({
    name: 'highlight'
})
export class HighlightPipe implements PipeTransform {
    transform(value: string, search: string): string {
        if (!search) { return value; }
        var re = new RegExp(escapeRegExp(search), 'gi');
        return value.replace(re, "<b>$&</b>");
    }
}


type SearchStates = "GROUP" | "ALLOY" | "TYPE" | "HARDNESS" | "SPECS" | "DONE" | "CASTNUMBER";

@Component({
  selector: "material-select",
  templateUrl: "./material-select.component.html",
  styleUrls: ["./material-select.component.less"],
})
export class MaterialSelectComponent implements OnInit, AfterViewInit, OnChanges {
  constructor(
    private element: ElementRef,
    private cdRef: ChangeDetectorRef,
    private matService: MaterialService,
    private dialog: MatDialog,
) {}

  @Input() material: Material;
  @Input() disabled = false;

  /** Lots of ViewChildren!! **/

  /* MatAutocompletes for all fields */
  @ViewChild("groupAutocomplete", { static: true }) groupAutocomplete: MatAutocomplete;
  @ViewChild("alloyAutocomplete", { static: true }) alloyAutocomplete: MatAutocomplete;
  @ViewChild("typeAutocomplete", { static: true }) typeAutocomplete: MatAutocomplete;
  @ViewChild("hardnessAutocomplete", { static: true }) hardnessAutocomplete: MatAutocomplete;
  @ViewChild("specificationAutocomplete") specificationAutocomplete: MatAutocomplete;

  /**
   * And their corresponding MatAutocompleteTriggers;
   * we need these to open the autocompletes programatically
   */
  @ViewChild("groupTrigger") groupTrigger: MatAutocompleteTrigger;
  @ViewChild("alloyTrigger") alloyTrigger: MatAutocompleteTrigger;
  @ViewChild("typeTrigger") typeTrigger: MatAutocompleteTrigger;
  @ViewChild("hardnessTrigger") hardnessTrigger: MatAutocompleteTrigger;
  @ViewChild("specsTrigger") specsTrigger: MatAutocompleteTrigger;
  @ViewChild("castNumberTrigger") castNumberTrigger: MatAutocompleteTrigger;

  /* All the autocomplete input fields */
  @ViewChildren("mainInputs") mainInputs: QueryList<ElementRef<HTMLInputElement>>;

  /* The form field's chip list */
  @ViewChild("chipList", { static: true }) chipList: MatChipList;

  /* All the chips for displaying fields (save for the programatically generated ones for specs) */
  @ViewChildren("mainChips") mainChips: QueryList<ElementRef<HTMLDivElement>>;

  /* Chips for selected specifications */
  @ViewChildren("specChips") specChips: QueryList<ElementRef<HTMLDivElement>>;

  /* The dialog used for creating new items */
  @ViewChild('newTypeDialog', { static: true }) newTypeDialog: TemplateRef<any>;

  /* Type editor */
  @ViewChild('matTypeEditor') matTypeEditor: MaterailTypeEditorComponent;

  /* Dialog for creating new items other than types */
  @ViewChild('newItemDialog', { static: true }) newItemDialog: TemplateRef<any>;

  /** End of ViewChildren **/

  /* All the parameter lists, grabbed from MaterialService */
  public materialGroups: MaterialGroup[] = [];
  public materialAlloys: MaterialAlloy[] = [];
  public materialTypes: MaterialType[] = [];
  public materialHardnesses: MaterialHardness[] = [];
  public materialSpecifications: MaterialSpecification[] = [];
  public castingNumbers: string[] = [];

  /* Flags for UI flow */
  private preventNextFocus = false;
  private preventNextExit = false;
  private midTransition = false;

  /**
   * Model for whatever user input text is in the form field right now,
   * used for autocomplete
   */
  public searchInput = "";

  /* Whether search data is being loaded */
  public loadingData = false;

  /**
   * Material parameters (type, group, specs, etc)
   *
   */
  public materialParameters: {
    type: MaterialType | undefined;
    castnumber: string | undefined,
    group: MaterialGroup | undefined;
    hardness: MaterialHardness | undefined | null;
    alloy: MaterialAlloy | undefined | null;
    specs: MaterialSpecification[];
  } = {
    group: undefined,
    alloy: undefined,
    type: undefined,
    castnumber: undefined,
    hardness: undefined,
    specs: [],
  };

  /**
   * Sets all fields to empty.
   *
   */
  public resetMaterialParameters() {
    this.materialParameters = {
      group: undefined,
      alloy: undefined,
      type: undefined,
      castnumber: undefined,
      hardness: undefined,
      specs: [],
    };
    this._state = "GROUP";
  }

  /**
   * Material dimension data
   */
  public dimensions: MaterialDimension[] = [];
  /*  Material density */
  public materialDensity: number = 0;

  /* The last type that was checked by the setupDimensions function */
  public lastType: MaterialType;
  public hasFinishedSpecs = false;

  /* Flags whether the material has been modified, IE we should search for/create it when the product is saved */
  @Output() change = new EventEmitter<void>();
  public dirty = false;
  public setDirty() {
    this.dirty = true;
    this.change.emit();
  }

  /* Variables for state logic */
  public _state: SearchStates = "GROUP";
  public get state() {
    return this._state;
  }
  public set state(val) {
    this.setState(val);
  }
  // The order states should be advanced through
  public statesOrder: SearchStates[] = [
    "GROUP",
    "TYPE",
    "CASTNUMBER",
    "ALLOY",
    "HARDNESS",
    "SPECS",
    "DONE",
  ];

  /* When creating a new item, the parameter that is being created, otherwise null */
  public creatingNew: SearchStates = null;
  /* When we're creating anything save a `MaterialType`,
   * we save to the API directly so we need a loading state */
  public savingNew = false;

  /**
   * Gets search data (all types, groups, etc.)
   * Called on component init and when a new item is created
   *
   * @private
   */
  private async loadSearchData() {
    this.loadingData = true;

    const [types, groups, hardnesses, alloys, specs, castingNumbers] = await Promise.all([
      this.matService.getMaterialTypes('').toPromise(),
      this.matService.getMaterialGroups('').toPromise(),
      this.matService.getMaterialHardnesses('').toPromise(),
      this.matService.getMaterialAlloys('').toPromise(),
      this.matService.getMaterialSpecifications('').toPromise(),
      this.matService.getExistingCastingNumbers().toPromise()
    ]);

    this.materialTypes = types.results;
    this.materialGroups = groups.results;
    this.materialHardnesses = hardnesses.results;
    this.materialAlloys = alloys.results;
    this.materialSpecifications = specs.results;
    this.castingNumbers = castingNumbers;
    this.loadingData = false;

  }

  private getCurrentTrigger(): MatAutocompleteTrigger {
    const triggerMap: { [key: string]: MatAutocompleteTrigger } = {
      TYPE: this.typeTrigger,
      CASTNUMBER: this.castNumberTrigger,
      GROUP: this.groupTrigger,
      HARDNESS: this.hardnessTrigger,
      ALLOY: this.alloyTrigger,
      SPECS: this.specsTrigger,
    };
    return triggerMap[this.state];
  }

  private openCurrentPanel() {
    if (this.state === 'DONE') return;
    try {
      this.getCurrentTrigger().openPanel();
    } catch(e) {
      throw new Error(`Could not open panel for ${this.state}`);
    }
  }

  /**
   * Handles state changes, refocusing the input and opening the next autocomplete
   *
   * @param state State to set
   * @param [newText=""] If we're switching to editing an already-set field, use this to set the input text to its name
   */
  public setState(state: SearchStates, newText: string = ""): void {
    if (state !== this._state) this.searchInput = newText;
    this._state = state;
    if (state === "DONE") return;

    this.preventNextFocus = true;

    if (this.mainInputs) {
      this.mainInputs.changes
      .pipe(
        first()
      )
      .subscribe((q: QueryList<ElementRef<HTMLInputElement>>) => {
        this.cdRef.detectChanges();
        setTimeout((_) => {
          if (this.mainInputs.first) {
            this.mainInputs.first.nativeElement.value = this.searchInput;
            this.mainInputs.first.nativeElement.focus();
          }
          this.preventNextFocus = false;
          this.openCurrentPanel();
        });
      });
    }
  }

  /**
   * Setter for the material parameters so we can do side-effects as necessary
   * Currently mostly for setting up dimensions when the Type changes
   *
   * @param param The name of the material parameter
   * @param value The value to set it to
   */
  public setParameter(param: string, value: any): void {
    const origValue = this.materialParameters[param];
    if (JSON.stringify(origValue) !== JSON.stringify(value)) this.setDirty();
    this.materialParameters[param] = value;
    if (param === 'type') this.setupDimensions();
  }


  /**
   * Handle a chip being focused, usually switching to its state
   *
   * @param state The state to switch to
   * @param [newText=""] `newText` param to pass to `setState`
   */
  public handleFocus(state: SearchStates, newText: string = ""): void {
    if (this.disabled) return;
    if (this.preventNextFocus) {
      console.log('focus prevented');
      this.preventNextFocus = false;
      return;
    } else {
      this.preventNextExit = true;
      this.setState(state, newText);
      if (state === 'SPECS') this.specChips.forEach(
        c => c.nativeElement.blur()
      );
      this.midTransition = false;
    }
  }

  @Output() fullyInput = new EventEmitter<typeof this['materialParameters'] & { dimensions: MaterialDimension[] }>();

  /**
   * Advance to the next state, skipping over already-set states
   *
   */
  public advanceState() {
    // if we don't do this, trying to switch from editing one already-set parameter to another
    // will instead send the state all the way to DONE
    if (this.midTransition) {
      this.midTransition = false;
      return;
    }
    let curState = this.state;
    do {
      curState = this.statesOrder[
        this.statesOrder.findIndex((x) => curState === x) + 1
      ];
      // Need a special case for specs since they're never undefined.
      if (curState === "SPECS" && this.materialParameters.specs.length === 0) {
        if (!this.hasFinishedSpecs) break;
      };
    } while (this.materialParameters[curState.toLowerCase()] !== undefined);


    this.midTransition = false;
    this.preventNextFocus = false;
    this.state = curState;
    if (this.materialFullyInput) this.fullyInput.emit({ ...this.materialParameters, dimensions: this.dimensions });
  }

  /**
   * Is called when an autocomplete option is selected for any parameter
   * (except for specs, which have their own function below)
   *
   * @param option The option that was selected in the autocomplete
   */
  public optionSelected(
    option: MaterialGroup | MaterialAlloy | MaterialType | MaterialHardness | string
  ) {
    this.preventNextExit = true;
    this.setParameter(this.state.toLowerCase(), option);
    this.advanceState();
  }

  /**
   * Called when a specification is selected from autocomplete
   *
   * @param option Either a specification, or `"SPECS_FINISH"` if the user selected "Finished Editing Specs"
   */
  public specSelected(option: MaterialSpecification | "SPECS_FINISH") {
    // Reset the autocomplete input
    this.searchInput = "";
    this.preventNextExit = true;
    if (option === "SPECS_FINISH") {
      this.midTransition = false;
      this.hasFinishedSpecs = true;
      this.advanceState();
      return;
    }
    this.materialParameters.specs.push(option);
    this.setDirty();
    setTimeout((_) => this.specsTrigger.openPanel());
  }

  /**
   * Filter most of the parameter lists, except for specs which need special case
   *
   * @param group The group to filter
   * @return {*} The filtered group
   */
  public doFilter(group: any[]): any[] {
    if (!this.searchInput) return group;
    if (group.length === 0) return group;
    let property = "name";
    if (group[0] && group[0].hasOwnProperty("groupName")) property = "groupName";
    if (group[0] && typeof group[0] === 'string') property = null;

    const x = group.filter((item) => {
      const val = property ? item[property] : item;
      return val && val.toLowerCase().includes(this.searchInput.toLowerCase())
    });
    return x;
  }

  /**
   * Filters the specs list, including filtering out already-chosen specs
   *
   * @param group The specs list
   * @return {*}
   */
  public doFilterSpecs(group: MaterialSpecification[]): MaterialSpecification[] {
    if (!this.searchInput) return group;
    if (group.length === 0) return group;
    let property = "name";
    if (group[0].hasOwnProperty("groupName")) property = "groupName";

    return group.filter((item) =>
      item[property].toLowerCase().includes(this.searchInput.toLowerCase()) &&
      this.materialParameters.specs.findIndex(s => s.materialSpecificationId === item.materialSpecificationId) === -1
    );
  }

  /**
   * Called when a material spec is removed either via X button or backspace.
   *
   * @param specToDelete The spec to be deleted
   */
  public removeSpec(specToDelete: MaterialSpecification) {
    console.log(specToDelete.materialSpecificationId);
    this.materialParameters.specs = this.materialParameters.specs.filter(s => s.materialSpecificationId !== specToDelete.materialSpecificationId);
    this.setDirty();
    if (this.materialParameters.specs.length === 0) {
      this.state = 'DONE';

      // if we delete the last spec then the chipList will automatically focus the last chip,
      // (the alloy) which would normally open the alloy dropdown.
      // Here we set a flag to prevent the next dropdown open on focus,
      // and we also unfocus the chip as soon as it's focused
      this.mainChips.last.nativeElement.addEventListener('focus', (event) => {
        this.mainChips.last.nativeElement.blur();
        this.mainInputs.last.nativeElement.focus();
      }, { once: true });
    }

  }

  /**
   * Whether the dimension editing panel should be shown.
   * (Only if all fields are set and specs are done being edited)
   *
   * @readonly
   */
  public get canShowDimensions() {
    return this.hasFinishedSpecs &&
    this.materialParameters.type &&
    this.materialParameters.group &&
    this.materialParameters.hardness !== undefined &&
    this.materialParameters.alloy !== undefined;
  }

  /**
   * Determines whether dimensions contain both a "width" and "length" component
   * so that we can show a warning about how they should be input.
   *
   * @readonly
   */
  public get hasWidthAndLength(): boolean {
    if (!this.dimensions) return;
    const dimensionNames = this.dimensions
      .filter(dim => dim && dim.materialTypeDimension && dim.materialTypeDimension.dimensionType)
      .map(dim => dim.materialTypeDimension.dimensionType.label);
    const hasWidth = dimensionNames.some(name => !!(name.match(/width/i)));
    const hasLength = dimensionNames.some(name => !!(name.match(/length/i)));
    return hasWidth && hasLength;
  }

  /**
   * Whether the field is fully empty (all params are undefined)
   *
   * @readonly
   */
  public get materialNotInput(): boolean {
    return this.materialParameters.type === undefined &&
    this.materialParameters.group === undefined &&
    this.materialParameters.hardness === undefined &&
    this.materialParameters.alloy === undefined &&
    this.materialParameters.specs.length === 0
  }
  
  /**
   * Determines whether the material has been fully input
   * and the enclosing product-detail page should allow the user to save.
   *
   * @readonly
   */
  public get materialFullyInput(): boolean {
    return this.hasFinishedSpecs &&
    this.materialParameters.type &&
    this.materialParameters.group &&
    this.materialParameters.hardness !== undefined &&
    this.materialParameters.alloy !== undefined &&
    (typeof this.materialParameters.castnumber === 'string') &&
    this.dimensions &&
    this.dimensions.every(dimension => dimension.value !== null && dimension.value !== undefined);
  }
  

  /**
   * Sets up empty `MaterialDimensions` when a new `MaterialType` is set
   * Will skip if the new type has an identical dimension profile to the old one
   *
   * @private
   */
  private setupDimensions() {
    const { type } = this.materialParameters;
    if (!type || !type.materialTypeDimensions) return;
    if (type === this.lastType) return;
    // if the MTDs for the type are the exact same, skip this
    if (
      this.lastType && this.lastType.materialTypeDimensions &&
      type.materialTypeDimensions.length === this.lastType.materialTypeDimensions.length &&
      type.materialTypeDimensions.map((mtd, i) => {
        const lastMtd = this.lastType.materialTypeDimensions[i];
        return lastMtd &&
          mtd.dimensionTypeId === lastMtd.dimensionTypeId &&
          mtd.dimensionUnitId === lastMtd.dimensionUnitId;
      }).some(x => x === true)
    ) return;
    // reset dimensions
    this.dimensions = type.materialTypeDimensions.map(mtd => ({
      materialId: null,
      material: null,
      materialTypeDimensionId: mtd.materialTypeDimensionId,
      materialTypeDimension: mtd,
      value: 0,
    }));
    this.lastType = type;
  }

  /**
   * Handles the user pressing Backspace on an input field while it is empty
   * Presumably they are trying to go back to the previous field, so we do this
   * This sets the current field as undefined.
   *
   * @param event The keydown `KeyboardEvent`
   */
  public handleBackspace(event: KeyboardEvent) {
    if (event.key !== 'Backspace') return;
    if (this.searchInput.trim().length > 0) return;
    const stateIndex = this.statesOrder.findIndex((x) => this.state === x);
    if (stateIndex === 0) return;
    // check if this is the forwardmost field currently
    const remainingStates = this.statesOrder.slice(stateIndex + 1).filter(s => s !== 'DONE');
    let statesAheadExist =
    (remainingStates.includes('SPECS') && this.materialParameters.specs.length > 0) ||
    remainingStates.filter(x => x !== 'SPECS').some(state => this.materialParameters[state.toLowerCase()] !== undefined);
    if (statesAheadExist) return;
    this.setParameter(this.state.toLowerCase(), undefined);
    this.midTransition = true;
    this.preventNextFocus = false;
    this.preventNextExit = true;
    const chip = this.mainChips.toArray()[stateIndex - 1];
    chip?.nativeElement?.focus();
  }


  /**
   * Handles an autocomplete losing focus.
   * If the field is already set, we assume the user is trying to leave it as-is,
   * so we advance the state, disabling input
   *
   */
  public inputExit(specs = false) {
    if (this.dialog.openDialogs.length > 0) return;
    if (this.preventNextExit) {
      this.preventNextExit = false;
      return;
    }
    if (specs) {
      setTimeout(() => this.specsTrigger.openPanel());
    } else {
      const currentValue = this.materialParameters[this.state.toLowerCase()];
      if (currentValue !== undefined) this.advanceState();
    }
  }

  /**
   * Handles the form-field being clicked on.
   * If the click wasn't directly on a specific chip,
   * we want to focus whatever the currently available input field is.
   *
   * @param event The mousedown `MouseEvent`
   */
  public focusCurrentInput(event: MouseEvent) {
    const target = <HTMLElement>event.target;
    if (!target.classList.contains('mat-chip')) {
      event.preventDefault();
      this.mainInputs.first && this.mainInputs.first.nativeElement.focus();
    }
  }

  /* ----- Visuals ----- */

  /**
   * The calculated offset for the current input's autocomplete,
   * based on the position/length of chips.
   *
   * Used as `margin-left` for the autocomplete.
   *
   * @readonly
   */
  public get panelOffset(): string {
    const root: HTMLElement = this.element.nativeElement;
    const inputFlex = root.getElementsByClassName("mat-form-field-flex")[0];
    const inputFlexPadding = parseInt(
      window.getComputedStyle(inputFlex, null).getPropertyValue("padding-left")
    );

    const chips = root.getElementsByClassName("material-select-input-chip");
    let currentStateIndex = this.statesOrder.findIndex(
      (x) => x === this.state
    );
    // if (this.state === 'SPECS') currentStateIndex += this.materialParameters.specs.length;
    const trimmedChips = Array.from(chips).slice(0, currentStateIndex);

    if (trimmedChips.length === 0) return "0";

    const totalChipWidth = trimmedChips.reduce((acc, chip) => {
      return (
        acc +
        chip.getBoundingClientRect().width +
        parseInt(
          window.getComputedStyle(chip, null).getPropertyValue("margin-left")
        ) +
        parseInt(
          window.getComputedStyle(chip, null).getPropertyValue("margin-right")
        )
      );
    }, 0);

    return inputFlexPadding + totalChipWidth + "px";
  }

  /**
   * Set the panel's margin-left based on panelOffset getter.
   *
   * @param ac The autocomplete to set the margin for.
   */
  public setPanelMargin(ac: MatAutocomplete) {
    const { id } = ac;
    const panels = document.querySelectorAll(`#${id}.mat-autocomplete-panel`);
    Array.from(panels).forEach((panel: HTMLElement) => {
      panel.style.marginLeft = this.panelOffset;
    });
  }

  /**
   * Called as the user types into the current autocomplete input.
   * Resizes the input based on the length of its contents so that it fits snugly between
   * surrounding tags.
   *
   * @param el `HTMLInputElement` to modify
   * @param text An override for the text to use to resize, as Angular does not provide this correctly
   * when we call this when switching to an already-filled input
   */
  public resizeInput(el: HTMLInputElement, text?: string) {
    if (!text) text = el.value;
    el.style.width = text.length + 1 + "ch";
    el.style.flexGrow = "0";
    el.style.flexBasis = "unset";
  }

  /**
   * Calculates volume of the material
   *
   * @readonly
   */
  public get calculatedVolume() {
    if (!this.materialParameters.type || !this.dimensions) return;
    else return Material.getVolume(<Material>{
      materialDimensions: this.dimensions,
      materialType: this.materialParameters.type,
    });
  }

  public get dimensionsDisplay() {
    if (!this.materialParameters.type || !this.dimensions) return;
    const out = Material.dimensionsDisplay(<Material>{
      materialDimensions: this.dimensions,
      materialType: this.materialParameters.type,
    });
    return out;
  }

  public get parameterIds() {
    if (!this.materialFullyInput) return null;
    return {
      materialTypeId: this.materialParameters.type.materialTypeId,
      materialGroupId: this.materialParameters.group.materialGroupId,
      materialAlloyId: this.materialParameters.alloy ? this.materialParameters.alloy.materialAlloyId : null,
      materialHardnessId: this.materialParameters.hardness? this.materialParameters.hardness.materialHardnessId: null,
      materialSpecificationIds: this.materialParameters.specs.map(ms => ms.materialSpecificationId),
      materialDimensions: this.dimensions.map(d => ({
        ...d,
        // backend doesn't like null ID here
        materialId: UtilityService.emptyGuid,
      })),
      density: this.materialDensity,
      castingNumber: this.materialParameters.castnumber
    }
  }


  public newType: MaterialType = null;
  public newItemDialogRef: MatDialogRef<any>;

  /**
   * Create a new item when an event is emitted,
   * either from the `material-type-editor` or the generic new item dialog.
   *
   * @param type
   */
  public async createNew(type: SearchStates) {
    this.creatingNew = type;
    this.getCurrentTrigger().closePanel();

    let newItem: MaterialGroup | MaterialHardness | MaterialAlloy | MaterialSpecification;
    switch (type) {
      case 'TYPE':
        this.newItemDialogRef = this.dialog.open(this.newTypeDialog, {
          disableClose: true,
          minWidth: 1000,
        });
        await this.newItemDialogRef.afterOpened().toPromise();
        this.cdRef.detectChanges();
        this.matTypeEditor.add();
        this.matTypeEditor.selected.name = this.searchInput;
        this.newType = this.matTypeEditor.selected;
        this.cdRef.detectChanges();
        return;
      case 'SPECS':
        newItem = {
          materialSpecificationId: UtilityService.emptyGuid,
          name: ''
        } as MaterialSpecification;
        break;
      case 'GROUP':
        newItem = {
          materialGroupId: UtilityService.emptyGuid,
          groupName: ''
        } as MaterialGroup;
        break;
      case 'ALLOY':
        newItem = {
          materialAlloyId: UtilityService.emptyGuid,
          name: ''
        } as MaterialAlloy;
        break;
      case 'HARDNESS':
        newItem = {
          materialHardnessId: UtilityService.emptyGuid,
          name: ''
        } as MaterialHardness;
        break;
      default:
        break;
    }
    const nameKey = type === 'GROUP' ? 'groupName' : 'name';
    newItem[nameKey] = this.searchInput;
    this.newItemDialogRef = this.dialog.open(this.newItemDialog, {
      data: {
        newItem,
        nameKey,
      },
      disableClose: true,
      minWidth: 400,
    });

  }

  public async onNewItem(newItem: MaterialGroup | MaterialAlloy | MaterialType | MaterialHardness | MaterialSpecification) {
    let newItemWithId: typeof newItem;
    if (this.creatingNew === 'TYPE') {
      // If we're creating a Type, that means we used the MaterialTypeEditorComponent,
      // so it's already been saved to the DB. We just close the dialog
      this.newItemDialogRef.close();
      newItemWithId = newItem as MaterialType;
    } else {
      this.savingNew = true;
      switch (this.creatingNew) {
        case 'SPECS':
          newItemWithId = await this.matService.saveMaterialSpecification(newItem as MaterialSpecification).toPromise();
          break;
        case 'GROUP':
          newItemWithId = await this.matService.saveMaterialGroup(newItem as MaterialGroup).toPromise();
          break;
        case 'ALLOY':
          newItemWithId = await this.matService.saveMaterialAlloy(newItem as MaterialAlloy).toPromise();
          break;
        case 'HARDNESS':
          newItemWithId = await this.matService.saveMaterialHardness(newItem as MaterialHardness).toPromise();
          break;
      }
      this.savingNew = false;
      this.newItemDialogRef.close();
    }
    if (this.creatingNew === 'SPECS') {
      this.materialParameters.specs.push(newItemWithId as MaterialSpecification)
      this.setDirty()
    }
    else if (!!this.creatingNew) this.setParameter(this.creatingNew.toLowerCase(), newItemWithId);
    this.loadSearchData();
    this.setDirty();
    this.midTransition = false;
    this.creatingNew = null;
    this.advanceState();
  }

  public newItemCancel() {
    this.newItemDialogRef.close();
    this.setState(this.creatingNew);
    this.creatingNew = null;
  }

  /**
   * Load an existing material, usually from the @Input material but who knows we might need this elsewhere
   *
   * @param mat The existing material
   */
  public loadExistingMaterial(mat: Material) {
    // Copy values
    this.materialParameters.type = mat.materialType;
    this.materialParameters.group = mat.materialGroup;
    this.materialParameters.hardness = mat.materialHardness;
    this.materialParameters.alloy = mat.materialAlloy;
    this.materialParameters.specs = mat.materialMaterialSpecifications.map(mms => mms.materialSpecification);
    this.materialParameters.castnumber = mat.castingNumber;
    this.dimensions = JSON.parse(JSON.stringify(mat.materialDimensions));
    this.materialDensity = mat.density || 0;
    // setting lastType and running setupDimensions to check for a possible discrepancy between dimensions and type,
    // and reset the dimensions if we find one
    this.lastType = mat.materialType;
    this.setupDimensions();
    if (this.dimensions.length === 0 && this.materialParameters.type.materialTypeDimensions.length !== 0) {
      this.dimensions = this.materialParameters.type.materialTypeDimensions.map(mtd => ({
        materialId: null,
        material: null,
        materialTypeDimensionId: mtd.materialTypeDimensionId,
        materialTypeDimension: mtd,
        value: 0,
      }));
    }
    // Setting state = done since everything is already input
    this.state = 'DONE';
    this.hasFinishedSpecs = true;
    // Making sure dirty is false - we're assuming this is a material straight from the DB, so saving it as-is would be pointless
    this.dirty = false;
  }

  /**
   * Called by the enclosing `ProductDetailComponent` to save the material.
   * Calls the API /findOrCreateMaterial endpoint and returns the resulting material.
   *
   */
  public async onSave(): Promise<Material> {
    if (!this.materialFullyInput) throw new Error('Trying to save with missing parameters!');
    return this.matService.findOrCreateMaterial(this.parameterIds).toPromise();
  }


  ngOnInit() {
    // Skip loading dropdown params when disabled, since we basically just want to display the name
    // and the material already has all that data
    if (!this.disabled) this.loadSearchData();
    if (this.material) this.loadExistingMaterial(this.material);
  }

  ngAfterViewInit() {
    /* 
    This is really weird, but basically what we're doing here is replacing the mat-chip-list's keyDown event 
    with one that first checks if we're in a state earlier than Specs before running the original code.
    We don't want the default focus-on-backspace behavior in the earlier states because it conflicts with `handleBackspace` in some really ugly ways.
    All the `bind` stuff is to ensure that the right "this" is preserved. Not sure how necessary it is, but better safe than sorry...
    */
    const chipList = this.chipList
    const originalKeyDown = chipList._keydown
    const matSelect = this
    const newKeyDown = (event: KeyboardEvent) => {
      if (matSelect.state === 'DONE' || matSelect.state === 'SPECS') {
        originalKeyDown.bind(chipList)(event)
      }
    }
    chipList._keydown = newKeyDown.bind(chipList)
  }

  ngOnChanges(changes: SimpleChanges) {
    // disabled should never change after init, but just in case...
    if (!this.disabled) this.loadSearchData();
    if (changes.material!=null && changes.material.currentValue != null) {
      if (JSON.stringify(changes.material.currentValue) !== JSON.stringify(changes.material.previousValue)) {
        if (this.material) this.loadExistingMaterial(this.material);
        else this.resetMaterialParameters();
      }
    }
  }


}
