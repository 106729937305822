<div class="mat-small-header mb-2 px-3">Prerequisites</div>
<mat-list class="pt-0 border-top prereq-list">
  <mat-list-item *ngFor="let prereq of prerequisites" class="border-bottom prereq-item-outer">
    <div class="w-100">
      <div class="w-100 d-flex align-items-center prereq-item p-2">
        <mat-checkbox class="mat-checkbox-display mat-checkbox-no-margin mr-3" [checked]="isFullyAssigned(prereq)"></mat-checkbox>
        <item-type-chip [item]="prereq" class="mr-2"></item-type-chip>
        <b>{{ prereq.name }} </b>
        <div class="ml-auto">
          <b>{{ getQuantityAssigned(prereq) }}</b> of <b>{{ prereq.qty }}</b> required items assigned
        </div>
        <button class="ml-2" mat-icon-button [disabled]="prereq.assignments.length === 0" (click)="this.expansionModel.toggle(prereq.purchasingSheetItemId)">
          <mat-icon>
            {{expansionModel.isSelected(prereq.purchasingSheetItemId) ? 'expand_more' : 'chevron_right'}}
          </mat-icon>
        </button>
      </div>
      <ng-container *ngIf="expansionModel.isSelected(prereq.purchasingSheetItemId)">
        <div class="assignment-list">
          <div class="py-2 assignment" *ngFor="let assignment of prereq.assignments">
            <mat-icon class="px-1 mr-3">chevron_right</mat-icon>
            <div>
              <b>{{ assignment.qtyAssigned }}</b> items from <b>{{ assignment.type ? assignment.type : 'existing inventory' }}</b> assigned by <b>{{ assignment.assigningUser_Name }}</b> on <b>{{ assignment.dateAssigned | date: 'MM/dd/yyyy' }}</b>
            </div>
          </div>
        </div>
      </ng-container>
    </div>
  </mat-list-item>
  <mat-list-item *ngIf="prerequisites.length === 0" class="prereq-item text-center text-muted">
    This work order has no prerequisites.
  </mat-list-item>
</mat-list>