import { Component, OnInit, Input, OnChanges, SimpleChanges, ViewChild, EventEmitter, Output } from '@angular/core';
import { FloorService } from '../../services/floor.service';
import { StationService } from '../../../order/services/station.service';
import { Station } from '../../../order/resources/station';
import { MachineAssignment } from '../../../planning/resources/machine-assignment';
import { Department, FloorBuilding, FloorMachine } from '../../resources/building';
import { SearchList } from '../../../common/components/search-list/search-list';
import { MatMenuTrigger } from '@angular/material/menu';
import { PlanningService } from '../../../planning/services/planning.service';

@Component({
  selector: 'unassigned-jobs-list',
  templateUrl: './unassigned-jobs-list.component.html',
  styleUrls: ['./unassigned-jobs-list.component.less']
})
export class UnassignedJobsListComponent extends SearchList<MachineAssignment> implements OnInit, OnChanges {
  @Input() department: Department;
  @Output() assignment: EventEmitter<MachineAssignment> = new EventEmitter<MachineAssignment>();
  public stations: Station[];
  @ViewChild('trigger') trigger: MatMenuTrigger;
  public selectedItem: MachineAssignment = null;
  public selectedDepartment: FloorBuilding = null;
  public filteredMachines: FloorMachine[] = null;

  constructor(private floorSvc: FloorService, private stationSvc: StationService, private planningSvc: PlanningService) {
    super('UnassignedJobsListComponent');
  }

  protected search(): void {
    this.results = null;
    this.floorSvc.getUnassignedJobs(this.department, this.pageNumber).subscribe(results => {
      this.searchResults = results;
      this.results = results.results
    });
  }

  public refresh(): void {
    this.search();
  }

  public getStation(stationId: string): Station {
    if (!this.stationSvc.stationList) return null;

    return this.stationSvc.stationList.find(s => s.stationId == stationId);
  }

  public onSelect(record: MachineAssignment): void {
    this.selectedItem = record;
    this.trigger.openMenu();
  }

  public setDepartment(department: FloorBuilding): void {
    this.selectedDepartment = null;
    this.filteredMachines = null;
    this.floorSvc.getFloorBuilding(department.floorBuildingId).subscribe(r => {
      this.selectedDepartment = r;
      this.filteredMachines = r.machines.filter(m => m.stationId == this.selectedItem.operation.stationId);
    });
  }

  public assignTo(machine: FloorMachine): void {
    this.results = null;
    this.selectedItem.machine = machine;
    this.selectedItem.machineId = machine.machineId;
    this.assignment.emit(this.selectedItem);
  }

  ngOnChanges(_: SimpleChanges): void {
    this.search();
  }

  ngOnInit(): void {
    this.stationSvc.stationsLoaded.subscribe(r => {
      if (r) {
        this.stations = this.stationSvc.stationList;
        this.search();
      }
    });
  }
}
