<div [ngClass]="{'m-4':(sidenav != null)}">
  <a *ngIf="!showEditor && customer && !editing" class="position-fixed fixed-bottom btn btn-badge has-icon"
    title="Edit Record" (click)="toggleEditing()"><i class="fas fa-edit"></i></a>
  <a *ngIf="!showEditor && customer && editing"
    class="position-fixed fixed-bottom btn btn-badge bg-danger has-icon mr-6" title="Cancel Edit"
    (click)="toggleEditing()"><i class="fas fa-times"></i></a>
  <a *ngIf="!showEditor && customer && editing" class="position-fixed fixed-bottom btn btn-badge bg-success has-icon"
    title="Save Record" (click)="saveChanges(true)"><i class="fas fa-save"></i></a>
  <a *ngIf="customer && !editing && !insetnav?.opened"
    class="position-fixed fixed-bottom btn btn-badge bg-danger has-icon mr-6" title="Delete/Disable"
    (click)="delete()"><i class="fas fa-minus-circle"></i></a>

  <div class="row">

    <loading *ngIf="!customer"></loading>
    <loading *ngIf="saving" content="Saving..."></loading>

    <mat-sidenav-container *ngIf="customer">
      <mat-sidenav disableClose (closedStart)="closeSideNav()" #insetnav mode="over" position="end">
        <vendor-detail [sidenav]="insetnav" vendorId="new" *ngIf="showEditor == 'addVendor'"></vendor-detail>
      </mat-sidenav>
      <mat-sidenav-content>

        <mat-stepper [selectedIndex]="selectedTab" (selectionChange)="setSelectedTab($event)"
          [linear]="false" #stepper>
          <ng-template matStepperIcon="edit">
            <i class="fas fa-check fa-fw"></i>
          </ng-template>
          <ng-template matStepperIcon="done">
            <i class="fas fa-check fa-fw"></i>
          </ng-template>
          <mat-step label="General Info">
            <div *ngIf="!contactsView" class="row">
              <div class="col-9">
                <div class="row">
                  <div class="col-6">
                    <div class="form-group">
                      <input #name="ngModel" minlength="3" [(ngModel)]="customer.businessName" required
                        placeholder="Customer Name" type="text" max="100" class="form-control form-control-lg" appStaticEdit
                        [editable]="editing" />
                    </div>
                    <div class="row">
                      <div class="col">
                        <div class="form-group">
                          <label>Sales Person</label>
                          <salesperson-select [editable]="editing" (salesPersonChange)="salesPersonChange($event)"
                            [salesPerson]="customer.salesPerson"></salesperson-select>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-6" *ngIf="customer.orderStatus">
                    <h5>In-Process Orders</h5>
                    <ul>
                      <li *ngFor="let item of customer.orderStatus.discriminators">
                        {{item.count}} {{item.discriminator}}{{item.count === 1 ? '' : 's'}}
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="row border rounded mx-0 mb-1">
                  <div class="col-4 border-right px-0">
                    <h5 class="m-0 p-2 bg-secondary text-center">
                      Addresses
                    </h5>
                    <table class="table table-sm table-striped">
                      <tr>
                        <th class="w-100 pl-2">Name</th>
                        <th class="small text-center">Physical</th>
                        <th class="small text-center">Mailing</th>
                        </tr>
                      <tr *ngIf="customer.customerAddresses?.length==0">
                        <td class="text-center text-muted" colspan="100%">
                          <em>-- No Addresses Entered --</em>
                        </td>
                      </tr>
                      <tr [class.bg-primary]="selectedAddress?.customerAddressId ===
                      customerAddress.customerAddressId" [class.text-light]="selectedAddress?.customerAddressId ===
                      customerAddress.customerAddressId" [class.address-selected]="selectedAddress?.customerAddressId ===
                      customerAddress.customerAddressId" *ngFor="let customerAddress of customer.customerAddresses">
                        <td class="pl-2 cursor-pointer w-100" (click)="switchToAddress(customerAddress)">
                          <span *ngIf="!!customerAddress.address.nickname">{{customerAddress.address.nickname}}</span>
                          <span class="font-italic" *ngIf="!customerAddress.address.nickname">--Unnamed
                            Address--</span>
                        </td>
                        <td class="text-center">
                          <mat-radio-button [name]="'phys'+customerAddress.addressId" class="address-radio ml-auto px-2"
                            *ngIf="editing"
                            [class.invisible]="customerAddress.addressId === '00000000-0000-0000-0000-000000000000'"
                            [checked]="customer.physicalAddressId === customerAddress.addressId"
                            (click)="onPhysicalAddressClick($event, customerAddress.addressId)">
                          </mat-radio-button>
                          <div class="fas fa-check ml-auto px-2" *ngIf="!editing"
                            [class.invisible]="customer.physicalAddressId !== customerAddress.addressId">
                          </div>
                        </td>
                        <td class="text-center">
                          <mat-radio-button [name]="'mail'+customerAddress.addressId" class="address-radio ml-auto px-2"
                            *ngIf="editing"
                            [class.invisible]="customerAddress.addressId === '00000000-0000-0000-0000-000000000000'"
                            [checked]="customer.mailingAddressId === customerAddress.addressId"
                            (click)="onMailingAddressClick($event, customerAddress.addressId)">
                          </mat-radio-button>
                          <div class="fas fa-check ml-auto px-2" *ngIf="!editing"
                            [class.invisible]="customer.mailingAddressId !== customerAddress.addressId">
                          </div>
                        </td>
                      </tr>
                    </table>
                    <div class="text-center" *ngIf="editing">
                      <button class="btn btn-primary btn-sm mb-2" [class.invisible]="creatingAddress"
                        (click)="newAddress()"><i class="fas fa-plus"></i> Add Address</button>
                    </div>
                  </div>
                  <div class="col-8 py-2">
                    <div *ngIf="!selectedAddress"
                      class="w-100 h-100 relative top-0 left-0 d-flex align-items-center justify-content-center text-muted">
                      -- Select an address from the left. --
                    </div>
                    <form #addressForm="ngForm" [class.invisible]="!selectedAddress"
                      (submit)="saveAddress(selectedAddress)">
                      <div class="form-group">
                        <label>Address Nickname</label>
                        <input placeholder="Nickname" type="text" max="100" class="form-control form-control-sm"
                          appStaticEdit [editable]="editing" [(ngModel)]="selectedAddress?.address.nickname"
                          name="nickname" required />
                        <div class="small text-muted font-italic">An identifying name for the address. Will not show up in any reports or quotes.</div>
                      </div>
                      <div class="form-group">
                        <label>Street Address</label>
                        <input placeholder="Street Address" type="text" max="100" class="form-control form-control-sm"
                          appStaticEdit [editable]="editing" [(ngModel)]="selectedAddress?.address.streetAddress"
                          name="streetAddress" required />
                      </div>
                      <div class="form-group">
                        <label>Second Line (optional)</label>
                        <input placeholder="Second Line" type="text" max="100" class="form-control form-control-sm"
                          appStaticEdit [editable]="editing" [(ngModel)]="selectedAddress?.address.streetAddress2"
                          name="streetAddress2" />
                      </div>
                      <div class="form-group">
                        <label>City</label>
                        <input placeholder="City" type="text" max="100" class="form-control form-control-sm"
                          appStaticEdit [editable]="editing" [(ngModel)]="selectedAddress?.address.city" name="city"
                          required />
                      </div>
                      <div class="form-group">
                        <label>State</label>
                        <input placeholder="State" type="text" max="100" class="form-control form-control-sm"
                          appStaticEdit [editable]="editing" [(ngModel)]="selectedAddress?.address.state" name="state"
                          required />
                      </div>
                      <div class="form-group">
                        <label>Zip</label>
                        <input placeholder="Zip" type="text" max="100" class="form-control form-control-sm"
                          appStaticEdit [editable]="editing" [(ngModel)]="selectedAddress?.address.postalCode"
                          name="postalCode" required />
                      </div>
                      <div class="row">
                        <div class="col-6 form-group">
                          <label>Phone Number</label>
                          <phone-number placeholder="Phone Number" [editable]="editing" [(number)]="selectedAddress?.address.phoneNumber" name="phone"></phone-number>
                        </div>
                        <div class="col-6 form-group">
                          <label>Fax</label>
                          <phone-number placeholder="Fax" [editable]="editing" [(number)]="selectedAddress?.address.faxNumber" name="fax"></phone-number>
                        </div>
                      </div>
                      <div class="form-group">
                        <label>Contact</label>
                        <mat-select [(ngModel)]="selectedAddress?.address.contact" name="contact" *ngIf="editing"
                          (ngModelChange)="setAddressContactId($event)">
                          <mat-select-trigger>
                            <span *ngIf="!selectedAddress?.address.contact">
                              <i class="text-muted fas fa-user-slash mr-2"></i>
                              <span class="text-muted font-italic">None</span>
                            </span>
                            <span *ngIf="!!selectedAddress?.address.contact">
                              <i class="text-muted fas fa-user mr-2"></i>
                              <span>{{selectedAddress?.address.contact.name}}</span>
                            </span>
                          </mat-select-trigger>
                          <mat-option [value]="null">
                            <div class="text-muted font-italic">None</div>
                          </mat-option>
                          <mat-option *ngFor="let vContact of customer.customerContacts" [value]="vContact.contact">
                            {{ vContact.contact.name }}
                          </mat-option>
                        </mat-select>
                        <input placeholder="None" type="text" max="100" class="form-control form-control-sm"
                          appStaticEdit [editable]="false" [value]="selectedAddress?.address.contact?.name"
                          *ngIf="!editing" />
                      </div>
                      <div class="w-100 d-flex justify-content-end" *ngIf="editing">
                        <button class="btn btn-danger" type="button" (click)="deleteCurrentAddress()">
                          <i class="fas fa-times mr-1"></i>{{creatingAddress ? 'Cancel' : 'Delete'}}</button>
                        <button class="btn btn-success ml-2" type="submit"
                          [disabled]="addressForm.pristine || addressForm.invalid">
                          <i class="fas fa-trash mr-1"></i>Save</button>
                      </div>
                    </form>
                  </div>
                </div>
                <div class="row pb-2">
                  <div class="col-12 pl-0 pr-0 bg-light">
                    <h5 class="w-100 p-2 pl-1 bg-secondary">Notes</h5>
                    <div class="p-1">
                      <textarea rows="6" appStaticEdit [editable]="editing" class="form-control form-control-sm"
                        [(ngModel)]="customer.additionalNotes" placeholder="Any customer notes can go here"></textarea>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-3 border-left border-secondary pl-0">
                <h5 (click)="toggleContactsView()" class="cursor-pointer m-0 p-2 bg-secondary text-center">
                  <i class="fas fa-play fa-rotate-180 float-left"></i> Contacts
                </h5>
                <table class="table table-sm table-striped">
                  <tr *ngIf="!customer.customerContacts || customer.customerContacts.length==0">
                    <td class="text-center text-muted" colspan="100%">
                      <em>-- No Contacts Entered --</em>
                    </td>
                  </tr>
                  <tr (click)="toggleContactsView()" *ngFor="let cContact of customer.customerContacts">
                    <td>{{cContact.contact.name}}</td>
                    <td class="text-muted text-right">{{cContact.contact.phoneNumber}}
                      {{cContact.contact.phoneExtension==null?'':('ext. '+cContact.contact.phoneExtension)}}</td>
                  </tr>
                </table>
                <div class="text-center" *ngIf="editing">
                  <button (click)="toggleContactsView()" class="btn btn-primary btn-sm"><i class="fas fa-plus"></i> Add
                    Contact</button>
                </div>
              </div>
            </div>
            <div *ngIf="contactsView" class="row mr-2">
              <h5 (click)="toggleContactsView()" class="cursor-pointer d-block w-100">
                <i class="fas fa-play text-muted"></i> Contacts
                <a href="javascript:void(0);" class="float-right btn btn-sm btn-secondary text-dark">Back to Business
                  Info</a>
              </h5>
              <table class="table table-sm table-striped">
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Title</th>
                    <th>Phone Number</th>
                    <th style="width: 85px">Extension</th>
                    <th>Cell Number</th>
                    <th>Work Email</th>
                    <th>Personal Email</th>
                    <th>&nbsp;</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngIf="!editing && (!customer.customerContacts || customer.customerContacts.length==0)">
                    <td class="text-center text-muted" colspan="100%">
                      <em>-- No Contacts Entered --</em>
                    </td>
                  </tr>
                  <tr *ngFor="let cContact of customer.customerContacts">
                    <td>
                      <input required placeholder="Contact Name" type="text" max="100"
                        class="form-control form-control-sm" appStaticEdit [editable]="editing"
                        [(ngModel)]="cContact.contact.name" />
                    </td>
                    <td>
                      <input placeholder="Title" type="text" max="100" class="form-control form-control-sm"
                        appStaticEdit [editable]="editing" [(ngModel)]="cContact.contact.title" />
                    </td>
                    <td>
                      <phone-number [editable]="editing" [(number)]="cContact.contact.phoneNumber"></phone-number>
                    </td>
                    <td>
                      <input placeholder="Extension" type="text" max="100" class="form-control form-control-sm"
                        appStaticEdit [editable]="editing" [(ngModel)]="cContact.contact.phoneExtension" />
                    </td>
                    <td>
                      <phone-number [editable]="editing" [(number)]="cContact.contact.cellNumber"></phone-number>
                    </td>
                    <td>
                      <input placeholder="Work Email" type="email" max="100" class="form-control form-control-sm"
                        appStaticEdit [editable]="editing" [(ngModel)]="cContact.contact.email" />
                    </td>
                    <td>
                      <input placeholder="Personal Email" type="email" max="100" class="form-control form-control-sm"
                        appStaticEdit [editable]="editing" [(ngModel)]="cContact.contact.personalEmail" />
                    </td>
                    <td>
                      <div *ngIf="editing">
                        <button title="Delete" class="btn btn-sm btn-danger" (click)="deleteContact(cContact)"><i
                            class="fas fa-fw fa-times"></i></button>
                        &nbsp;
                        <button title="Save" class="btn btn-sm btn-success" (click)="saveContact(cContact)"><i
                            class="fas fa-fw fa-save"></i></button>
                      </div>
                      <div *ngIf="!editing">&nbsp;</div>
                    </td>
                  </tr>
                  <tr *ngIf="editing">
                    <td>
                      <input required minlength="2" placeholder="Contact Name" type="text" max="100"
                        class="form-control form-control-sm" [(ngModel)]="newContact.contact.name" />
                    </td>
                    <td>
                      <input placeholder="Title" type="text" max="100" class="form-control form-control-sm"
                        [(ngModel)]="newContact.contact.title" />
                    </td>
                    <td>
                      <phone-number [editable]="editing" [(number)]="newContact.contact.phoneNumber"></phone-number>
                    </td>
                    <td>
                      <input placeholder="Extension" type="text" max="100" class="form-control form-control-sm"
                        [(ngModel)]="newContact.contact.phoneExtension" />
                    </td>
                    <td>
                      <phone-number [editable]="editing" [(number)]="newContact.contact.cellNumber"></phone-number>
                    </td>
                    <td>
                      <input placeholder="Work Email" type="email" max="100" class="form-control form-control-sm"
                        [(ngModel)]="newContact.contact.email" />
                    </td>
                    <td>
                      <input placeholder="Personal Email" type="email" max="100" class="form-control form-control-sm"
                        [(ngModel)]="newContact.contact.personalEmail" />
                    </td>
                    <td>
                      <button title="Cancel" class="btn btn-sm btn-danger" (click)="clearContact()"><i
                          class="fas fa-fw fa-minus-circle"></i></button>
                      &nbsp;
                      <button title="Add" class="btn btn-sm btn-success" (click)="saveContact(newContact)"><i
                          class="fas fa-fw fa-plus"></i></button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </mat-step>
          <mat-step label="Approved Supplier List">
            <div class="col-12">
              <p *ngIf="!approvedSupplierList" class="text-center">
                <i class="fas fa-2x fa-circle-notch fa-spin text-primary"></i>
              </p>
              <table class="table search-results table-striped table-borderless table-hover thead-light"
                *ngIf="approvedSupplierList">
                <thead>
                  <tr>
                    <th>Supplier Name</th>
                    <th>&nbsp;</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let vendor of approvedSupplierList" (click)="goToVendor(vendor)">
                    <td>{{vendor.name}}</td>
                    <td class="text-right">
                      &nbsp;
                      <button *ngIf="editing" class="btn btn-sm btn-danger" (click)="removeSupplier($event, vendor)"
                        title="Delete">
                        <i class="fas fa-fw fa-lg fa-times"></i>
                      </button>
                    </td>
                  </tr>
                  <tr *ngIf="approvedSupplierList.length==0">
                    <td class="text-center text-muted font-italic" colspan="100%">
                      -- No Suppliers Have been Certified for this Customer --
                      <span class="d-block small">
                        <i class="text-info fas fa-fw fa-lg fa-info-circle"></i>
                        Any Supplier can be used for orders.
                      </span>
                    </td>
                  </tr>
                  <tr *ngIf="editing">
                    <td>
                      <vendor-chooser [(vendor)]="newSupplier" [editable]="true" (addVendor)="createSupplier($event)">
                      </vendor-chooser>
                    </td>
                    <td class="text-right">
                      <button title="Cancel" class="btn btn-sm btn-danger mr-2" (click)="cancelAddSupplier()">
                        <i class="fas fa-minus-circle fa-fw fa-lg"></i>
                      </button>
                      <button title="Save" class="btn btn-sm btn-success" (click)="saveSupplier()">
                        <i class="fas fa-save fa-fw fa-lg"></i>
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
              <p class="text-center" *ngIf="editing && newSupplier==null">
                <button class="btn btn-sm btn-primary" (click)="addSupplier()">
                  <i class="fas fa-fw fa-lg fa-plus"></i>
                  Add an Approved Supplier
                </button>
              </p>
            </div>
          </mat-step>
        </mat-stepper>

      </mat-sidenav-content>
    </mat-sidenav-container>

  </div>
</div>
