<mat-accordion *ngIf="!loading">
  <mat-expansion-panel *ngFor="let entry of entries; index as i">
    <mat-expansion-panel-header>
      <div class="small">
        <div>
          <span class="font-weight-bold text-muted">Entry #{{ entries.length - i }}</span> - {{ entry.timestamp | date: 'yyyy/MM/dd HH:mm' }}
        </div>
        <b>{{ entry.user.fullName }} - {{ entry.department }}</b>
      </div>
    </mat-expansion-panel-header>
    {{ entry.note }}
  </mat-expansion-panel>
</mat-accordion>