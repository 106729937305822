<loading *ngIf="!record || saving"></loading>
<div class="position-fixed d-flex flex-row-reverse" style="bottom: 40px; right: 25px; z-index: 1030" *ngIf="record && !saving">
  <button mat-fab class="bg-success text-white" (click)="edit()" *ngIf="!editing">
    <mat-icon>edit</mat-icon>
  </button>
  <button mat-fab class="bg-danger text-white" (click)="stopEditing()" *ngIf="editing">
    <mat-icon>close</mat-icon>
  </button>
  <button mat-fab class="mr-2" [disabled]="saveDisabled" [ngClass]="{
    'bg-success': !saveDisabled,
    'text-white': !saveDisabled,
    'bg-light': saveDisabled,
    'text-dark': saveDisabled
  }" (click)="save()" *ngIf="editing">
    <mat-icon>save</mat-icon>
  </button>
</div>


<div class="rounded mat-elevation-z4 position-relative d-flex flex-column" style="height: 90vh;" *ngIf="record">
  <div class="d-flex px-4 pt-3 pb-1 align-items-center">
    <h4 class="mb-0">
      Ticket {{ ticketNumber }}
    </h4>
    <div class="position-absolute d-flex" style="top: 10px; right: 10px; z-index: 99;">
      <mat-form-field appearance="outline" class="ml-auto mat-input-no-message" style="min-width: 250px;">
        <mat-label>Invoice Status</mat-label>
        <mat-select [(ngModel)]="record.status" (ngModelChange)="onStatusChange()" [disabled]="!editing">
          <mat-select-trigger>
            <mat-chip style="pointer-events: none;" class="mat-standard-chip button-text" [ngClass]="getInvoiceStatusColorClass(record.status)">{{ getInvoiceStatusText(record.status) }}</mat-chip>
          </mat-select-trigger>
          <mat-option *ngFor="let num of [0,1,2,3,4]" [value]="num">
            <mat-chip style="pointer-events: none;" class="mat-standard-chip button-text" [ngClass]="getInvoiceStatusColorClass(num)">{{ getInvoiceStatusText(num) }}</mat-chip>
          </mat-option>
        </mat-select>
      </mat-form-field>
      <div class="date-field ml-2">
        <date-picker #datepicker [editable]="editing && (record.status == 2 || record.status == 3)" appearance="outline" [placeholder]="'Invoice Date'" [required]="record.status == 2 || record.status == 3" [(date)]="record.invoiceDate"></date-picker>
      </div>
      <div class="date-field ml-2">
        <date-picker #datepicker [editable]="editing && record.status == 3" appearance="outline" [placeholder]="'Payment Date'" [required]="record.status == 3" [(date)]="record.paymentDate"></date-picker>
      </div>
    </div>
  </div>
  <mat-tab-group class="mat-tab-group-colgrow flex-grow-1">
    <mat-tab label="Ticket Information">
      <div class="p-3 h-100-t">
        <div class="row">
          <div class="col-10">
            <div class="row">
              <div class="col-4">
                <mat-form-field appearance="outline" class="w-100 form-field-readonly">
                  <mat-label>Customer</mat-label>
                  <input matInput disabled type="text" [(ngModel)]="record.customerPurchaseOrderLineItem.customerPurchaseOrder.customer.businessName">
                </mat-form-field>
              </div>
              <div class="col-8">
                <mat-form-field #invoiceNumberField appearance="outline" class="w-100" [class.form-field-readonly]="record.status <= 1">
                  <mat-label>Invoice Number</mat-label>
                  <input matInput [required]="record.status >= 2 && record.status != 4" [disabled]="!editing || record.status <= 1" type="text" [(ngModel)]="record.invoiceNumber">
                </mat-form-field>
              </div>
              <div class="col-10">
                <mat-form-field appearance="outline" class="w-100 form-field-readonly">
                  <mat-label>Purchase Order Number</mat-label>
                  <input matInput disabled type="text" [(ngModel)]="record.customerPurchaseOrderLineItem.customerPurchaseOrder.purchaseOrderNumber" class="font-weight-bold">
                </mat-form-field>
              </div>
              <div class="col-2">
                <mat-form-field appearance="outline" class="w-100 form-field-readonly">
                  <mat-label>Line Item Number</mat-label>
                  <input matInput disabled type="text" [(ngModel)]="record.customerPurchaseOrderLineItem.lineItemNumber">
                </mat-form-field>
              </div>
              <div class="col-4">
                <mat-form-field appearance="outline" class="w-100 form-field-readonly">
                  <mat-label>Work Order</mat-label>
                  <!-- TODO -->
                <input matInput disabled type="text" [(ngModel)]="record.customerPurchaseOrderLineItem.workOrder.workOrderNumber">
                </mat-form-field>
              </div>
              <div class="col-4">
                <mat-form-field appearance="outline" class="w-100 form-field-readonly">
                  <mat-label>Work Order Due Date</mat-label>
                  <input matInput disabled type="text" [value]="record.customerPurchaseOrderLineItem.dueDate | date: 'shortDate'">
                </mat-form-field>
              </div>
              <div class="col-4">
                <mat-form-field appearance="outline" class="w-100 form-field-readonly form-field-readonly-nodash mat-form-field-disabled form-field-chip-size">
                  <mat-label>Work Order Status</mat-label>
                  <mat-chip-list disabled #cl>
                    <mat-chip matPrefix style="pointer-events: none;" class="mat-standard-chip button-text" [ngClass]="getWOStatusColorClassChip(record.customerPurchaseOrderLineItem.workOrder.status)">{{ getWOStatusText(record.shippingTicket.status) }}</mat-chip>
                    <input matInput [matChipInputFor]="cl" type="text" disabled>
                  </mat-chip-list>
                </mat-form-field>
              </div>
              <div class="col-3">
                <mat-form-field appearance="outline" class="w-100 form-field-readonly">
                  <mat-label>Line Item</mat-label>
                  <input matInput disabled type="text" [(ngModel)]="lineItemName">
                </mat-form-field>
              </div>
              <div class="col-3">
                <mat-form-field appearance="outline" class="w-100 form-field-readonly">
                  <mat-label>Unit Price</mat-label>
                  <input matInput disabled type="text" [value]="(record.customerPurchaseOrderLineItem.unitPrice) | currency">
                </mat-form-field>
              </div>
              <div class="col-3">
                <mat-form-field appearance="outline" class="w-100 form-field-readonly">
                  <mat-label>Quantity</mat-label>
                  <input matInput disabled type="text" [(ngModel)]="record.quantity">
                </mat-form-field>
              </div>
              <div class="col-3">
                <mat-form-field appearance="outline" class="w-100 form-field-readonly">
                  <mat-label>Total Value</mat-label>
                  <input matInput disabled type="text" [value]="(record.customerPurchaseOrderLineItem.unitPrice * record.quantity) | currency">
                </mat-form-field>
              </div>
            </div>
          </div>
          <div class="col-2">
            <div class="mat-card p-3 rounded cursor-pointer d-flex flex-column justify-content-center align-items-center h-100-t"
              *ngIf="record.customerPurchaseOrderLineItem.customerPurchaseOrder?.documentId" (click)="openPODocument()">
              <i class="fas fa-file fa-fw fa-6x text-info"></i>
              <div class="mt-2 font-weight-bold small">
                View Purchase Order Document
              </div>
            </div>
            <div class="mat-card bg-light p-3 rounded d-flex flex-column justify-content-center align-items-center h-100-t"
              *ngIf="!record.customerPurchaseOrderLineItem.customerPurchaseOrder?.documentId">
              <i class="fas fa-file fa-fw fa-6x text-muted"></i>
              <div class="mt-2 font-weight-bold small text-muted">
                Purchase Order Document Not Available
              </div>
            </div>
          </div>
        </div>
      </div>
    </mat-tab>
    <mat-tab label="Shipping Information" *ngIf="record.shippingTicket">
      <div class="p-3 h-100-t">
        <div class="row">
          <div class="col-4">
            <mat-form-field appearance="outline" class="w-100 form-field-readonly">
              <mat-label>Shipping Ticket Number</mat-label>
              <input matInput disabled type="text" [value]="shippingTicketNumber" class="font-weight-bold">
            </mat-form-field>
          </div>
          <div class="col-3">
            <mat-form-field appearance="outline" class="w-100 form-field-readonly">
              <mat-label>Assigned To</mat-label>
              <input matInput disabled type="text" [value]="record.shippingTicket.employee || ''">
            </mat-form-field>
          </div>
          <div class="col-2">
            <mat-form-field appearance="outline" class="w-100 form-field-readonly">
              <mat-label>{{ (record.shippingTicket.status == 4 || record.shippingTicket.status == 5) ? 'Ship Date' : 'Planned Ship Date' }}</mat-label>
              <input matInput disabled type="text" [value]="record.shippingTicket.employee || ''">
            </mat-form-field>   
          </div>
          <div class="col-3">
            <mat-form-field appearance="outline" class="w-100 form-field-readonly form-field-readonly-nodash mat-form-field-disabled form-field-chip-size">
              <mat-label>Shipping Ticket Status</mat-label>
              <mat-chip-list disabled #cl>
                <mat-chip matPrefix style="pointer-events: none;" class="mat-standard-chip button-text" [ngClass]="getShippingStatusColorClassChip(record.shippingTicket)">{{ getShippingStatusText(record.shippingTicket.status) }}</mat-chip>
                <input matInput [matChipInputFor]="cl" type="text" disabled>
              </mat-chip-list>
            </mat-form-field>
          </div>
          <div class="col-12">
            <mat-form-field appearance="outline" class="w-100 form-field-readonly form-field-readonly-nodash">
              <mat-label>Tracking Number</mat-label>
              <input matInput disabled type="text" style="display: none;">
              <div>
                <button class="btn btn-sm btn-link d-flex align-items-center" title="Open Tracking" (click)="openTracking()" *ngIf="record.shippingTicket.trackingNumber; else noTracking">
                  <img [attr.src]="shippingCarrierIcon" *ngIf="record.shippingTicket.shippingCarrier" />
                  {{record.shippingTicket.trackingNumber}}
                  <mat-icon class="text-decoration-none">
                    open_in_new
                  </mat-icon>
                </button>
                <ng-template #noTracking>
                  <div class="text-muted font-italic small">Tracking Number Not Set</div>
                </ng-template>
              </div>
            </mat-form-field>
          </div>
          <div class="col-12">
            <mat-form-field appearance="outline" class="w-100 form-field-readonly">
              <mat-label>Ship To Address</mat-label>
              <textarea class="noresize" matInput disabled [value]="record.customerPurchaseOrderLineItem.customerPurchaseOrder?.shipToAddress ? printAddress(record.customerPurchaseOrderLineItem.customerPurchaseOrder.shipToAddress) : ''" rows="8"></textarea>
            </mat-form-field>
          </div>
        </div>
      </div>
    </mat-tab>
  </mat-tab-group>
</div>