import { Input } from '@angular/core';
import { TemplateRef } from '@angular/core';
import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm, NgModel } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSidenav } from '@angular/material/sidenav';
import { Router } from '@angular/router';
import { debounceTime, tap } from 'rxjs/operators';
import { NavigationService } from '../../../common/services/navigation.service';
import { UtilityService } from '../../../common/services/utility.service';
import { PurchasedItem, PurchasedItemCategory, PurchasedItemPartNumber } from '../../../order/resources/purchased-item';
import { OrderService } from '../../../order/services/order.service';
import { ArrayDataSource, DataSource } from '@angular/cdk/collections';
import { MatTableDataSource } from '@angular/material/table';
import { BehaviorSubject } from 'rxjs';
import { PurchasingService } from '../../../purchasing/services/purchasing.service';


@Component({
  selector: 'purchased-item-editor',
  templateUrl: './purchased-item-editor.component.html',
  styleUrls: ['./purchased-item-editor.component.less']
})
export class PurchasedItemEditorComponent implements OnInit {

  public loading = false;
  public saving = false;

  @ViewChild('itemForm') itemForm: NgForm;
  @Input() sidenav: MatSidenav;
  
  constructor(public service: OrderService, public purchasingService: PurchasingService, public util: UtilityService, public navService: NavigationService, private router: Router, private dialog: MatDialog) { }

  public async close() {
    if ((this.itemForm && this.itemForm.dirty) || (this.selectedItem && this.selectedItem.purchasedItemId) === UtilityService.emptyGuid) {
      const confirm = await this.util.showConfirmationPromise('Exit?', 'Are you sure you want to exit the editor? You will lose any unsaved changes.');
      if (!confirm) return;
    }
    if (this.sidenav)
      this.sidenav.close();
    else
      this.router.navigate(['/admin']);
  }

  public selectedItem: PurchasedItem | null;
  public items: PurchasedItem[] = [];


  public searching = false;
  canScroll = true;
  currentPageIndex = 0;

  public async onSearch() {
    this.searching = true;
    const { results, resultCount } = await this.purchasingService.searchUniquePurchasedItemsByPartNumber(this.filter, 0, 30).toPromise();
    this.searching = false;
    this.items = results;
    this.currentPageIndex = 0;

    this.canScroll = this.items.length < resultCount;
  }

  public async onListScroll(e: Event) {

    const panel = e.target as HTMLUListElement;
    const maxScrollPosition = panel.scrollHeight - panel.clientHeight;
    const scrollThreshold = maxScrollPosition - 80;
    if (panel.scrollTop > scrollThreshold && !this.searching && this.canScroll) {
      this.currentPageIndex += 1;
      this.searching = true;
      const { results, resultCount } = await this.purchasingService.searchUniquePurchasedItemsByPartNumber(this.filter, this.currentPageIndex, 30).toPromise();
      this.searching = false;

      this.items = this.items.concat(results);

      this.canScroll = this.items.length < resultCount;
    }
  }

  public resetForm() {
    if (this.itemForm && this.itemForm.form) {
      this.itemForm.form.markAsPristine();
      this.itemForm.form.markAsUntouched();
    }
  }

  public async setSelectedItem(item: PurchasedItem) {
    if ((this.itemForm && this.itemForm.dirty) || (this.selectedItem && this.selectedItem.purchasedItemId) === UtilityService.emptyGuid) {
      const confirm = await this.util.showConfirmationPromise('Exit?', 'Are you sure you want to exit the current item? You will lose any unsaved changes.');
      if (!confirm) return;
    }
    this.loading = true;
    this.selectedItem = item;
    this.resetForm();
    this.categoryIsNew = false;
    this.navService.popBreadCrumb();
    this.navService.pushBreadcrumb(item.description);
    this.loading = false;
  };

  public async saveSelected() {
    if (!this.selectedItem) return;
    this.saving = true;
    const isNew = this.selectedItem.purchasedItemId === UtilityService.emptyGuid;
    const clonedItem: PurchasedItem = JSON.parse(JSON.stringify(this.selectedItem));
    if (!this.categoryIsNew) clonedItem.purchasedItemCategory = null;
    const item = await this.service.savePurchasedItem(clonedItem).toPromise();
    if (isNew) {
      this.filter = '';
    }
      this.onSearch();
      this.updateCategories();
    this.categoryIsNew = false;
    this.saving = false;
    this.selectedItem.purchasedItemId = item.purchasedItemId;
  } 

  public async deleteSelected() {
    if (!this.selectedItem) return;
    this.saving = true;
    const deletedId = this.selectedItem.purchasedItemId;

    const confirm = await this.util.showConfirmationPromise('Delete Item?', 'This will delete this purchased item from any quotes, orders and purchase orders that contain it. Are you sure?');

    if (!confirm) return;

    await this.service.removePurchasedItem(deletedId).toPromise();
    this.saving = false;
    this.items = this.items.filter(x => x.purchasedItemId !== deletedId);
    this.selectedItem = null;
  } 

  public createNewItem() {
    this.selectedItem = {
      purchasedItemId: UtilityService.emptyGuid,
      description: '',
      purchasedItemPartNumbers: [],
      purchasedItemCategory: null,
      purchasedItemCategoryId: null
    };
    this.resetForm();
  }

  @ViewChild('newPartNumberDialogTemplate', { static: true }) newPartNumberDialogTemplate: TemplateRef<any>;
  public async createAlternatePartNumber() {
    const dialogRef = this.dialog.open<any, PurchasedItemPartNumber, PurchasedItemPartNumber>(this.newPartNumberDialogTemplate, {
      disableClose: true,
      data: {
        purchasedItemPartNumberId: UtilityService.emptyGuid,
        purchasedItemId: this.selectedItem.purchasedItemId,
        partNumber: '',
        vendorId: null,
      }
    });
    const item = await dialogRef.afterClosed().toPromise();

    if (!item) return;

    this.saving = true;
    const createdItem = await this.service.createPurchasedItemPartNumber(item).toPromise();
    this.selectedItem.purchasedItemPartNumbers = [...this.selectedItem.purchasedItemPartNumbers, createdItem];
    this.saving = false;
  }

  public async deleteAlternatePartNumber(item: PurchasedItemPartNumber) {
    const r = await this.util.showConfirmationPromise('Are you sure?', `
      <p>Are you sure you want to delete this part number?</p>
      <p>Any purchase orders using it will revert to using the original part number/description.</p>
    `);
    if (!r) return;
    this.saving = true;
    await this.service.deletePurchasedItemPartNumber(item).toPromise();
    this.selectedItem.purchasedItemPartNumbers = this.selectedItem.purchasedItemPartNumbers.filter(i => i.purchasedItemPartNumberId !== item.purchasedItemPartNumberId);
    this.saving = false;
  }

  public get isCreating() {
    return this.selectedItem && this.selectedItem.purchasedItemId === UtilityService.emptyGuid;
  }

  public get canSave() {
    return this.selectedItem && this.selectedItem.description;
  }

  public filter = '';

  public categories = new MatTableDataSource<PurchasedItemCategory>([{ purchasedItemCategoryId: UtilityService.emptyGuid, name: 'Other', isTooling: false }]);
  public categoryDisplayFn = (o: PurchasedItemCategory) => o?.name;
  public categoriesData: BehaviorSubject<PurchasedItemCategory[]>;

  public updateCategories() {
    this.purchasingService.getPurchasedItemCategories().subscribe((r) => this.categories.data = r);
  }

  public categoryIsNew = false; 
  @ViewChild('newCategoryDialogTemplate', { static: true }) newCategoryDialogTemplate: TemplateRef<any>;
  public async newCategory(startingText: string) {
    const result = await this.dialog.open(this.newCategoryDialogTemplate, {
      disableClose: true,
      minWidth: '30vw',
      data: {
        name: startingText ?? '',
        isTooling: false
      }
    }).afterClosed().toPromise();
    if (result === null) return;
    this.categoryIsNew = true;
    var guid = UtilityService.emptyGuid;
    this.selectedItem.purchasedItemCategory = {
      purchasedItemCategoryId: guid,
      name: result.name,
      isTooling: result.isTooling
    };
    this.selectedItem.purchasedItemCategoryId = guid;
  }

  ngOnInit() {
    this.categoriesData = this.categories.connect();
    this.categories.filterPredicate = (d, f) => d?.name?.toLowerCase().includes(f.toLowerCase());
    this.navService.clearBreadCrumbs();
    this.navService.pushBreadcrumb("Purchased Item/Hardware Editor");
    this.onSearch();
    this.updateCategories();
  }

  @ViewChild('filterModel', { static: true }) filterModel: NgModel;
  ngAfterViewInit() {
    if (!this.filterModel || !this.filterModel.valueChanges) return;
    this.filterModel.valueChanges.pipe(
      tap(() => this.searching = true),
      debounceTime(250)
    ).subscribe(() => this.onSearch());
  }  

}
