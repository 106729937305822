import { Component, OnInit } from '@angular/core';
import { UserService } from '../../../common/services/user.service';
import { Router } from '@angular/router';

@Component({
  selector: 'signin',
  templateUrl: './signin.component.html',
  styleUrls: ['./signin.component.less']
})
export class SigninComponent implements OnInit {
  constructor(private usrSevice: UserService, private router: Router) { }

  ngOnInit(): void {
    this.usrSevice.user.subscribe(n => {
      if (n != null && n.userId)
        this.router.navigate(['/dashboard']);
    });
  }
}
