import { Injectable } from '@angular/core';
import { UtilityService } from './utility.service';

@Injectable({
  providedIn: 'root',
})
export class ErrorHandlerService {

  constructor(private utilitySvc: UtilityService) { }

  handle(error: any): void {
    if (error.status != null && error.status == 0) {
      this.utilitySvc.showConfirmation("Login Required",
        "<p>Your login is invalid or needs to be renewed.</p><p class='text-muted'>Click '<span class='font-weight-bold'>Okay</span>' to be redirected to login, click 'Cancel' to stay here (you can log in from another window to continue).</p>",
        (r => {
          if (r) {
            window.sessionStorage.setItem('returnTo', window.location.pathname);
            window.location.href = "/auth";
          }
        })
      );
      
    }
  }
}
