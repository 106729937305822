import { Component } from '@angular/core';
import { NavigationService } from '../../../common/services/navigation.service';
import { User } from '../../../common/resources/user';
import { SearchList } from '../../../common/components/search-list/search-list';
import { UserService } from '../../../common/services/user.service';
import { Router } from '@angular/router';
import { PermissionSelection } from '../permission-selection/permission-selection.component';

@Component({
  selector: 'employee-list',
  templateUrl: './employee-list.component.html',
  styleUrls: ['./employee-list.component.less']
})
export class EmployeeListComponent extends SearchList<User> {

  public groupFilters: PermissionSelection[] = null;

  constructor(navService: NavigationService, private userService: UserService, private router: Router) {
    super('employee');
    navService.setPageTitle("Employees");
  }

  protected search(): void {
    this.results = null;
    var filter = "";
    if (this.groupFilters != null) {
      filter = this.groupFilters.filter(g => g.selected).map(i => this.userService.roles.find(r => r.id == i.id).name).join();
    }
    this.userService.search(this.searchString, this.pageNumber, this.sortTerm, this.sortDirection, filter).subscribe(results => {
      this.searchResults = results;
      this.results = results.results
    });
  }

  public setGroupFilter(event: PermissionSelection[]): void {
    this.groupFilters = event;

    this.search();
  }

  public onSelect(record: User): void {
    this.router.navigate(['/employee', record.userId]);
  }
}
