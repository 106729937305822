<mat-card class="p-0" style="cursor: unset;">
  <mat-card-title class="p-2" style="padding-left: 24px !important; padding-bottom: 0 !important;">
    Quoted Line Items
  </mat-card-title>
  <table mat-table [dataSource]="lineItems" #kl="matTable" class="w-100">

    <ng-container matColumnDef="description">
      <th mat-header-cell *matHeaderCellDef> Item </th>
      <td mat-cell *matCellDef="let item" class="font-weight-bold">
        {{
        item.station ? item.station.name + ' for ' + item.product.partNumber + ' Rev. ' + item.product.revision :
        item.product.partNumber
        }}
      </td>
    </ng-container>

    <ng-container matColumnDef="notes">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let item">
        {{item.product ? item.product.description : ''}}
      </td>
    </ng-container>

    <ng-container matColumnDef="leadTime">
      <th mat-header-cell *matHeaderCellDef>Lead Time</th>
      <td mat-cell *matCellDef="let item">
        {{item.leadTimeDays}} working days/{{toWeeks(item.leadTimeDays)}} weeks
      </td>
    </ng-container>

    <ng-container matColumnDef="quantity">
      <th mat-header-cell *matHeaderCellDef>Quantity</th>
      <td mat-cell *matCellDef="let item"> {{item.quantity}} </td>
    </ng-container>

    <ng-container matColumnDef="unitPrice">
      <th mat-header-cell *matHeaderCellDef>Unit Price</th>
      <td mat-cell *matCellDef="let item"> {{item.unitPrice | currency }} </td>
    </ng-container>

    <ng-container matColumnDef="extPrice">
      <th mat-header-cell *matHeaderCellDef>Ext. Price</th>
      <td mat-cell *matCellDef="let item"> {{item.extPrice | currency }} </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>
</mat-card>