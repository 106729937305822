import { Component } from '@angular/core';
import { NavigationService } from '../../../common/services/navigation.service';
import { User } from '../../../common/resources/user';
import { UserService } from '../../../common/services/user.service';
import { Router } from '@angular/router';

@Component({
  selector: 'user-info',
  templateUrl: './user-info.component.html',
  styleUrls: ['./user-info.component.less']
})

export class UserInfoComponent {
  expanded: boolean = true;
  user: User;

  constructor(private navService: NavigationService, private userService: UserService, private router: Router) { }

  public goToDetail(): void {
    this.router.navigate(['/user']);
  }

  ngOnInit() {
    this.navService.isExpanded.subscribe(state => this.expanded = !state);
    this.userService.user.subscribe(u => this.user = u);
  }
}
