import { Component, Input, Output, OnInit, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { MaterialService } from '../../../order/services/material.service';
import { MaterialHardness } from '../../../order/resources/material';

@Component({
  selector: 'material-hardness-select',
  templateUrl: './material-hardness-select.component.html',
  styleUrls: ['./material-hardness-select.component.less']
})
export class MaterialHardnessSelectComponent implements OnInit {
  public values: MaterialHardness[];
  @Input() value: MaterialHardness;
  @Output() valueChange: EventEmitter<MaterialHardness> = new EventEmitter<MaterialHardness>();
  @Input() editable: boolean;
  @ViewChild('materialInputText', { static: true }) inputTxt: ElementRef;
  @ViewChild('autoCompleteTrigger', { static: true }) autoCompleteTrigger: MatAutocompleteTrigger;
  @Output() addValue: EventEmitter<string> = new EventEmitter<string>();

  constructor(private materialService: MaterialService) { }

  public getValueList(_event: KeyboardEvent) {
    this.materialService.getMaterialHardnesses(this.inputTxt.nativeElement.value)
      .subscribe(result => {
        this.values = result.results;
      });
  }

  public toggleDropdown(): void {
    event.stopImmediatePropagation();
    if (this.autoCompleteTrigger.panelOpen) {
      this.autoCompleteTrigger.closePanel();
    }
    else {
      this.getValueList(null);
      this.autoCompleteTrigger.openPanel();
    }
  }

  public onAdd(): void {
    event.stopImmediatePropagation();
    this.addValue.emit(this.inputTxt.nativeElement.value);
    this.autoCompleteTrigger.closePanel();
  }

  public setSelection(m: MaterialHardness) {
    this.value = m;
    if (m == null)
      return;

    this.valueChange.emit(m);
  }

  public testSelection(_: any): void {
    if (!this.value) {
      this.inputTxt.nativeElement.value = "";
      return;
    }
    this.inputTxt.nativeElement.value = this.value.name;
  }

  ngOnInit(): void {
  }
}
