<div class="sortable-container">
  <div>
    <h5 class="bg-secondary text-center p-2">Material Alloys</h5>
  </div>
  <ul class="sortable-list">
    <li *ngIf="!materialList">
      <p class="text-center">
        <i class="text-primary fas fa-spin fa-2x fa-circle-notch"></i>
      </p>
    </li>
    <li *ngFor="let material of materialList" (click)="select(material)">
      <div>
        <span>{{material.name}}</span>
      </div>
    </li>
  </ul>
</div>
