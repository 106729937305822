<div class="mat-small-header mb-2 px-3">Step Planning</div>
<mat-list class="pt-0 border-top">
  <mat-list-item *ngFor="let step of workflow" class="border-bottom">
    <div class="w-100">
      <div class="w-100 d-flex align-items-center p-2" style="font-size: 0.9em;">
        <mat-checkbox class="mat-checkbox-display mat-checkbox-no-margin mr-3" [checked]="isPlanned(step)"></mat-checkbox>
        <b>{{ getName(step) }} </b>
        <div class="ml-auto small" *ngIf="isPlanned(step)">
          Planned by <b>{{ step.plannedUser_Name ?? 'Unknown User' }}</b> on <b>{{ (step.plannedDate | date: 'MM/dd/yyyy') ?? 'Unknown Date' }}</b>
        </div>
      </div>
    </div>
  </mat-list-item>
  <mat-list-item *ngIf="workflow.length === 0" class="prereq-item text-center text-muted">
    This work order has no workflow steps.
  </mat-list-item>
</mat-list>