<div class="container-fluid">
  <div class="row border">
    <search-bar class="w-100" [(searchString)]="searchString" [showManagerBar]="false" (search)="doSearch($event)">
    </search-bar>

    <div *ngIf="!results">
      <loading></loading>
    </div>

    <search-result *ngIf="results" [results]="searchResults" class="w-100" (newPage)="getPage($event)"
      (selected)="onSelect($event)">
      <thead header-template>
        <tr class="text-uppercase text-muted">
          <th (click)="sortBy('purchaseOrderNumber')">
            PO #
            <i [ngClass]="getSortIconFor('purchaseOrderNumber')"></i>
          </th>
          <th (click)="sortBy('process')">
            Process
            <i [ngClass]="getSortIconFor('process')"></i>
          </th>
          <th (click)="sortBy('assigned')">
            Assigned To
            <i [ngClass]="getSortIconFor('assigned')"></i>
          </th>
          <th (click)="sortBy('status')">
            Status
            <i [ngClass]="getSortIconFor('status')"></i>
          </th>
          <th (click)="sortBy('datePlaced')">
            Date Placed
            <i [ngClass]="getSortIconFor('datePlaced')"></i>
          </th>
          <th (click)="sortBy('vendor')">
            Supplier
            <i [ngClass]="getSortIconFor('vendor')"></i>
          </th>
          <th>&nbsp;</th>
        </tr>
      </thead>
      <ng-template let-row>
        <td>
          {{row.purchaseOrderNumber}}
        </td>
        <td>{{getProcessText(row.process)}}</td>
        <td [ngClass]="{'text-muted font-italic small': !row.assignedUser}">
          {{row.assignedUser?.fullName || '-- Not Assigned --'}}</td>
        <td>
          <span class="badge required badge-dark" *ngIf="row.void">
            VOID
          </span>
          <span class="badge required" *ngIf="!row.void" [ngClass]="getStatusColorClass(row.status)">
            {{getStatusText(row.status)}}
          </span>
        </td>
        <td>
          {{row.datePlaced | date}}
        </td>
        <td>
          <span *ngIf="row.vendor; else emptyList">{{row.vendor?.name}}</span>
        </td>
        <td>
          <button class="btn btn-success">Assign WOs</button>
        </td>
      </ng-template>
    </search-result>
  </div>

</div>

<ng-template #emptyList>
  <div class="text-muted small font-italic">-- None --</div>
</ng-template>
