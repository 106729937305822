import { Component, Input, OnInit } from "@angular/core";
import { WorkOrderOverview } from "../../../../planning/resources/work-order";
import { PurchasingSheetItem } from "../../../../purchasing/resources/purchasingSheet";
import { WorkflowStep } from "../../../../order/resources/workflow";
import { Station } from "../../../../order/resources/station";
import { StationService } from "../../../../order/services/station.service";

@Component({
  selector: "oor-gantt",
  templateUrl: "./oor-gantt.component.html",
  styleUrls: ["./oor-gantt.component.less"],
})
export class OorGanttComponent implements OnInit {
  @Input() record: WorkOrderOverview;

  public data: any;
  public options: any;

  public yAxisChart =  [null, 'planning', 'preplanning', 'purchasing', 'contractReview'];
  public getYIndex(name: string) {
    return this.yAxisChart.findIndex(x => x === name);
  }

  public get orderStartDate() {
    return new Date(this.record.salesOrder.receivedDate);
  }

  public get endDate() {
    const dueDateWithBuffer = (new Date(this.record.dueDate));
    dueDateWithBuffer.setDate(dueDateWithBuffer.getDate() + 7)
    const today = new Date();
    if (today > dueDateWithBuffer) return today;
    else return dueDateWithBuffer;
  }

  public get timeSpan() {
    return this.endDate.getTime() - this.orderStartDate.getTime();
  }

  public getBarWidth(start: Date, end: Date) {
    let diff = end.getTime() - start.getTime();
    if (diff < 0) diff = 0;
    return `${((diff / this.timeSpan) * 100)}%`;
  }

  public getBarStartOffset(startDate: Date) {
    const diff = startDate.getTime() - this.orderStartDate.getTime();
    return `${((diff / this.timeSpan) * 100)}%`;
  }

  public getQuantityAssigned(prereq: PurchasingSheetItem) {
    return prereq.assignments?.reduce((acc, a) => acc + a.qtyAssigned, 0) ?? 0;
  }

  public isFullyAssigned(prereq: PurchasingSheetItem) {
    return this.getQuantityAssigned(prereq) >= prereq.qty;
  }

  public get purchasingEndDate() {
    // purchasing start date = last prereq assigned date if done, otherwise today
    const items = this.record?.purchasingSheet?.purchasingSheetItems;
    if (items.every(i => this.isFullyAssigned(i))) {
      return new Date(Math.max(...items.flatMap(i => i.assignments.flatMap(a => (new Date(a.dateAssigned)).getTime()))))
    } else {
      return new Date();
    }
  }

  public get preplanningEndDate() {
    return this.record.datePreplanningFinished ? new Date(this.record.datePreplanningFinished) : new Date();
  }

  public get contractReviewEndDate() {
    return this.record.contractReviewTicket.dateCompleted ? new Date(this.record.contractReviewTicket.dateCompleted) : new Date();
  }

  public get canStartPlanning() {
    return this.record.datePreplanningFinished;
  }

  public get planningEndDate() {
    return this.record.datePlanningFinished ? new Date(this.record.datePlanningFinished) : new Date();
  }

  public get isProductionFinished() {
    const schedulingTickets = this.record.workflowSteps.map(s => s.schedulingTicket);
    if (schedulingTickets.length === 0) return false;
    return schedulingTickets.every(t => t?.executionStatus === 2);
  }

  public get overallProductionEndDate() {
    if (!this.isProductionFinished) return new Date();
    const stepEndTimes = this.record.workflowSteps.flatMap(s => s.machineAssignments).map(ma => new Date(ma.actualEnd).getTime());
    const inspectionEndTimes = this.record.workflowSteps.flatMap(s => s.machineAssignments).flatMap(s => s.inspectionTickets).map(it => new Date(it.finishedTime).getTime());
    return new Date(Math.max(...stepEndTimes, ...inspectionEndTimes))
  }

  public get workflow() {
    return (this.record.workflowSteps ?? []).sort((a, b) => a.stepOrder - b.stepOrder);
  }

  public isStepStarted(step: WorkflowStep) {
    const actualStarts = step.machineAssignments.filter(s => !!s.actualStart).map(s => new Date(s.actualStart).getTime());
    return actualStarts.length > 0;
  }

  public isStepEnded(step: WorkflowStep) {
    const actualEnds = step.machineAssignments.map(s => s.actualEnd ? new Date(s.actualEnd).getTime(): null);
    if (actualEnds.some(a => a === null)) return false;
    else return true;
  }

  public getWorkflowStepEnd(step: WorkflowStep) {
    if (!this.isStepStarted(step)) return null;
    let end: Date;
    const actualEnds = step.machineAssignments.map(s => s.actualEnd ? new Date(s.actualEnd).getTime(): null);
    if (actualEnds.some(a => a === null)) end = new Date();
    else end = new Date(Math.min(...actualEnds));
    return end;
  }

  public getWorkflowStepStart(step: WorkflowStep): Date {
    // const index = this.workflow.findIndex(s => s.workflowStepId === step.workflowStepId);
    // if (index === 0) return this.planningEndDate;
    // else return this.getWorkflowStepEnd(this.workflow[index - 1])
    const actualStarts = step.machineAssignments.filter(s => !!s.actualStart).map(s => new Date(s.actualStart).getTime());
    if (actualStarts.length === 0) return null;
    else return new Date(Math.min(...actualStarts));
  }

  public getWorkflowStepStartOffset(step: WorkflowStep) {
    const start = this.getWorkflowStepStart(step);
    if (!start) return '0%';
    else return this.getBarStartOffset(start);
  }

  public getWorkflowStepBarWidth(step: WorkflowStep) {
    if (!this.isStepStarted(step)) return '0%';
    const start = this.getWorkflowStepStart(step);
    if (!start) return '0%';
    const end = this.getWorkflowStepEnd(step);
    return this.getBarWidth(start, end);
  }

  public workflowStepHasInspection(step: WorkflowStep) {
    const tickets = step.machineAssignments.flatMap(a => a.inspectionTickets);
    return tickets.length > 0;
  }

  public getWorkflowStepInspectionStartOffset(step: WorkflowStep) {
    const tickets = step.machineAssignments.flatMap(a => a.inspectionTickets);
    return new Date(Math.min(...tickets.map(t => new Date(t.partAvailableTime).getTime())));
  }

  public getWorkflowStepInspectionBarWidth(step: WorkflowStep) {
    const tickets = step.machineAssignments.flatMap(a => a.inspectionTickets);
    const allFinished = tickets.every(t => !!t.finishedTime);
    if (!allFinished) return new Date();
    else return new Date(Math.max(...tickets.map(t => new Date(t.finishedTime).getTime()))).getTime();
  }

  public get shippingEndDate() {
    const shippingTickets = this.record.shippingTickets.filter(st => !!st.inventoryItem.productId);
    const allFinished = shippingTickets.every(t => t.departureDate)
    if (!allFinished) return new Date();
    else return new Date(Math.max(...shippingTickets.map(t => new Date(t.departureDate).getTime())));
  }



  // Grid generation stuff
  public gridxIntervalPercent = 5;
  public get gridxIntervalCount() {
    return (100 / this.gridxIntervalPercent);
  }
  public get gridxIntervalArray() {
    return Array(this.gridxIntervalCount).fill(null).map((x, i) => (i));
  }

  public getGridTickDate(i: number) {
    const pct = ((i * this.gridxIntervalPercent) / 100);
    const offset = this.timeSpan * pct;
    const date = new Date(this.orderStartDate.getTime() + offset)
    return date;
  }

  public get gridTicks() {
    let ticks: Date[] = [this.orderStartDate, this.endDate];
    ticks = [...ticks, ...Array(this.gridxIntervalCount).fill(null).map((x, i) => (i + 1)).map(x => this.getGridTickDate(x))]
    return ticks.sort((a, b) => a.getTime() - b.getTime());
  }

  public get rows() {
    const base = 7;
    const steps = this.workflow.reduce((acc, ws) => {
      acc += 1;
      if (this.workflowStepHasInspection(ws)) acc += 1;
      return acc;
    }, 0)
    const amt = base + steps;
    return Array(amt).fill(null).map((_, i) => i);
  }

  public get today() {
    return new Date();
  }

  public get dueDate() {
    return new Date(this.record.dueDate);
  }

  public getStation(item: WorkflowStep): Station {
    if (this.stationList == null || item == null || item.stationId == null)
      return null;

    return this.stationList.find(r => r.stationId == item.stationId);
  }

  public getName(step: WorkflowStep) {
    return this.getStation(step)?.name ?? 'Unknown Station';
  }

  constructor(private stationService: StationService) { }

  public stationList: Station[]
  ngOnInit(): void {
    if (this.stationService.loaded) {
      this.stationList = this.stationService.stationList;
    }
    else {
      this.stationService.stationsLoaded.subscribe(
        _ => this.stationList = this.stationService.stationList
      );
    }
  }
}
