import { AfterViewInit, Component, Input, NgModuleDecorator, OnInit, ViewChild } from '@angular/core';
import { SalesNote, SalesNoteType } from '../../resources/sales-note';
import { User } from '../../../common/resources/user';
import { NgModel } from '@angular/forms';
import { BehaviorSubject, Observable, ReplaySubject, combineLatest, of } from 'rxjs';
import { map, shareReplay, startWith, take } from 'rxjs/operators';
import { MatSidenav } from '@angular/material/sidenav';
import { OrderService } from '../../services/order.service';
import { UtilityService } from '../../../common/services/utility.service';
import { UserService } from '../../../common/services/user.service';

@Component({
  selector: 'sales-notes',
  templateUrl: './sales-notes.component.html',
  styleUrls: ['./sales-notes.component.less']
})
export class SalesNotesComponent implements AfterViewInit {

  constructor(private orderSvc: OrderService, private userSvc: UserService, private utilitySvc: UtilityService) { }

  @Input('processId') processId: string;
  @ViewChild('filterModel') filterModel: NgModel;
  public sortOrder = new BehaviorSubject<"asc" | "desc">("asc");
  public notesSubject = new ReplaySubject<SalesNote[]>(1);
  public existingTags$: Observable<string[]>;
  public filters = new BehaviorSubject<string[]>([]);
  public results$: Observable<SalesNote[]>;

  ngAfterViewInit(): void {

    this.orderSvc.getSalesNotes(this.processId).pipe(take(1)).subscribe(n => this.notesSubject.next(n));

    this.existingTags$ = this.notesSubject.pipe(
      map(results => 
        results ? Array.from(new Set(results.flatMap(r => r.tags))) : []
      )
    );

    this.results$ = combineLatest([
      this.notesSubject,
      this.filterModel.valueChanges.pipe(startWith('')),
      this.sortOrder,
      this.filters,
    ]).pipe(
      map(([data, searchText, sortOrder, filters]) => {
        if (!data) return null;
        // Filters first
        if (filters.length > 0) data = data.filter(d => d.tags.some(t => filters.includes(t)));
        // Then text
        if (typeof searchText === 'string' && !!searchText?.trim()) data = data.filter(d => JSON.stringify(d).toLowerCase().includes(searchText.toLowerCase()))
        // Then sort
        if (sortOrder === 'asc') {
          data.sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime());
        } else {
          data.sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime());
        }
        return data;
      })
    )


  }

  public setSortOrder(to: 'asc' | 'desc') {
    this.sortOrder.next(to);
  }

  public filterIsEnabled(filter: string, selected: string[]) {
    return selected.includes(filter);
  }

  public toggleFilter(filter: string) {
    this.filters.pipe(
      take(1),
      map(fs => {
        if (fs.includes(filter)) return fs.filter(ff => ff !== filter);
        else return [...fs, filter];
      })
    ).subscribe((v) => this.filters.next(v));
  }

  @ViewChild('sidenav') sidenav: MatSidenav;
  public editingNote: SalesNote | null = null;
  public async createNote() {
    const user = await this.userSvc.userReplaySubject.pipe(take(1)).toPromise();
    this.editingNote = {
      salesNoteId: UtilityService.emptyGuid,
      salesProcessId: this.processId,
      authorId: user.userId,
      date: new Date(),
      content: "",
      tags: [],
      author: null,
      type: SalesNoteType.Note
    }
  }

  public get canClickOutside() {
    return this.editingNote && this.editingNote.content === '' && this.editingNote.tags.length === 0;
  }

  public async deleteNote(note: SalesNote) {
    const r = await this.utilitySvc.showConfirmationPromise("Are you sure?", "Deleting this note cannot be undone.");
    if (!r) return;
    combineLatest([
      this.orderSvc.deleteSalesNote(note),
      this.notesSubject.pipe(take(1))
    ]).subscribe(([res, current]) => {
      if (!!res) return;
      else this.notesSubject.next(
        current.filter(n => n.salesNoteId !== note.salesNoteId)
      )
    })
  }

  public editNote(note: SalesNote) {
    this.editingNote = note;
  }

  public async saveNote(note: SalesNote) {
    this.editingNote = null;
    this.notesSubject.next(null);
    await this.orderSvc.saveSalesNote(note).toPromise();
    this.orderSvc.getSalesNotes(this.processId).pipe(take(1)).subscribe(n => this.notesSubject.next(n));
  }

}
