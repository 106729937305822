<div class="position-relative">
  <span *ngIf="editable" class="position-absolute dropdown-indicator" (click)="toggleDropdown()"><i class="text-muted fas fa-chevron-down"></i></span>
  <input #autoCompleteTrigger="matAutocompleteTrigger" tabindex="-1" (focusout)="testSelection($event)" #materialInputText matInput placeholder="Select Group" (change)="setGroup($event.value)" appStaticEdit [editable]="editable" [ngModel]="materialGroup?.groupName" class="form-control" (keyup)="getMaterialGroupList($event)" type="text" max="100" [matAutocomplete]="materialList" />
  <mat-autocomplete (opened)="getMaterialGroupList($event)" autoActiveFirstOption #materialList="matAutocomplete" (optionSelected)="setGroup($event.option.value)">
    <mat-option *ngFor="let group of materialGroups" [value]="group">
      <span>{{group.groupName}}</span>
    </mat-option>
    <mat-option *ngIf="materialGroups?.length==0">
      <button class="float-right btn btn-primary btn-sm mt-4" (click)="onAddGroup()">
        <i class="fas fa-fw fa-lg fa-plus"></i> Add New Group
      </button>
      <p class="text-muted small text-center mt-3">
        <em>-- No Matching Material Groups Found --</em>
      </p>
    </mat-option>
  </mat-autocomplete>
</div>
