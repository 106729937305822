import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SearchList } from '../../../common/components/search-list/search-list';
import { NavigationService } from '../../../common/services/navigation.service';
import { UserService } from '../../../common/services/user.service';
import { UtilityService } from '../../../common/services/utility.service';
import { ShippingService } from '../../../shipping/services/shipping.service';
import { PurchaseOrder, PurchaseOrderStatus } from '../../resources/purchaseOrder';
import { PurchasingService } from '../../services/purchasing.service';

@Component({
  selector: 'app-unassigned-pos-list',
  templateUrl: './unassigned-pos-list.component.html',
  styleUrls: ['./unassigned-pos-list.component.less']
})
export class UnassignedPosListComponent extends SearchList<PurchaseOrder> {

  constructor(navService: NavigationService, private service: PurchasingService, private router: Router, private userSvc: UserService) {
    super('UnassignedPosListComponent');
    navService.setPageTitle("Unattached Purchase Orders");
  }

  search(): void {
    this.results = null;
    this.service.searchUnassignedPurchaseOrders(this.searchString, this.pageNumber, this.sortTerm, this.sortDirection).subscribe(results => {
      this.searchResults = results;
      this.results = results? results.results : [];
    });
  }

  onSelect(record: PurchaseOrder): void {
    this.router.navigate(['/purchasing/orders/unassigned', record.purchaseOrderId]);
  }

  getStatusColorClass(status: number): string {
    return PurchaseOrderStatus.getStatusColorClass(status);
  }

  getStatusText(status: number): string {
    return PurchaseOrderStatus.getStatusText(status);
  }

  public getProcessText(process: number): string {
    return ShippingService.getShippingClassText(process);
  }

  public hasPermission(moduleName: string): boolean {
    return this.userSvc.canAccess(moduleName);
  }

}