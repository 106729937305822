import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { UtilityService } from '../../../common/services/utility.service';
import { NavigationService } from '../../../common/services/navigation.service';
import { MbscEventcalendarView} from '@mobiscroll/angular';
import { HttpClient } from '@angular/common/http';
import { MbscCalendarEvent, MbscDatepickerOptions, MbscEventcalendarOptions, MbscPopup, MbscPopupOptions, formatDate, Notifications, setOptions} from '@mobiscroll/angular';
import { UntypedFormControl, Validators } from '@angular/forms';
import { EmployeeShiftAssignmentService } from '../../services/employee-shift-assignment.service';
import { UserService } from '../../../common/services/user.service';
import { User } from '../../../common/resources/user';
import { SearchList } from '../../../common/components/search-list/search-list';
import { Shift } from '../../resources/shift';
import { ResourceEmployee } from '../../resources/resource-employee';
import { EmployeeShiftAssignment } from '../../resources/employee-shift-assignment';
import { MatSnackBar } from '@angular/material/snack-bar';
import { employee_shift_assignment_Constants, shift_assignment_recurrence_Constants} from './employee-shift-assignment_Constants';
import { RecurrenceFrequency, ShiftAssignmentRecurrence } from '../../resources/shift-assignment-recurrence';
import { MatChip } from '@angular/material/chips';
import { FloorService } from '../../../floor/services/floor.service';
import { FloorBuilding, Department } from '../../../floor/resources/building';


setOptions({
  theme: 'windows',
  themeVariant: 'light'
});


@Component({
  selector: 'employee-shift-assignment',
  templateUrl: './employee-shift-assignment.component.html',
  styleUrls: ['./employee-shift-assignment.component.less']
})
export class EmployeeShiftAssignmentComponent extends SearchList<User> {

  private _millisecondsInOneHour = 1000 * 60 * 60;
  private CUSTOM_SHIFT_ID: number;
  private MAX_NUM_SHIFT_RECURRENCE_OCURRENCES = 9999;

  public saving: boolean = false;
  public editing: boolean;
  public actionType: string = 'Add';
  private valueIndex_Shift: number = 0;
  private valueIndex_ShiftAssginmentRecurrenceId: number = 1;
  private valueIndex_ShiftAssginmentRecurernceEndOption: number = 2;
  
  private isCustomShift: boolean = false;
  private validatePopupForm: boolean = true;

  view = 'week';
  calView: MbscEventcalendarView = {
    timeline: {
      type: 'week',
      eventList: true 
    }
  };

  @ViewChild('objPopup') objMbscPopup!: MbscPopup;
  employeeShiftAssignmentId?: number;
  shiftDate: Date[]
  shiftNotes: string | undefined;
  mbsCalendarEvent_color: string;
  selectedMbscCalendarEvent!: MbscCalendarEvent;
  selectedShift: Shift;
  shiftsFormControl: UntypedFormControl = new UntypedFormControl('', [Validators.required]);
  employeeShiftAssignment: EmployeeShiftAssignment;

  public arrAssignedMbscCalendarEvent: MbscCalendarEvent[];
  public arrAvailableShifts: Shift[];
  public myResourcesEmployee: ResourceEmployee[] | null = null;
  public myInvalids: any | null = null;

  public departments: FloorBuilding[];
  public buildings: Department[];
  public selectedDepartment: string ="0";
  public selectedBuilding: string = "0";

  // ---------------- Recurrence variables: ---------------- 
  recurrenceEnabled: boolean = false;
  recurrenceEventPanelExpanded: boolean = false;

  arrRecurrenceFrequency: RecurrenceFrequency[] = [
    { value: 'daily', text: 'day/s' },
    { value: 'weekly', text: 'week/s' },
    { value: 'monthly', text: 'month/s' },
    { value: 'yearly', text: 'year/s' },
  ];

  shiftAssignmentRecurrenceId?: number;
  selectedRecurrenceFrequency: RecurrenceFrequency = this.arrRecurrenceFrequency[1];
  repeatInterval: number = 1

  showRepeatOnOptions: boolean = true;
  arrOptions_RepeatOn_Week: string[] = ['Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa', 'Su'];
  selected_arrOptions_RepeatOn_Week: string[] = [];

  selectedRecurrenceEnd: string = shift_assignment_recurrence_Constants.RECURRENCE_END_OPTION_NEVER;
  numOccurrences: number = 1;
  recurrenceStartDate: Date = new Date();
  recurrenceEndDate: Date = new Date();
  // ---------------- ---------------- ----------------

  constructor(private employeeShiftAssignmentService: EmployeeShiftAssignmentService, private userService: UserService,
              private utilitySvc: UtilityService, private navService: NavigationService, private router: Router,
              private floorService: FloorService,
              private http: HttpClient, private notify: Notifications, private matSnackBar: MatSnackBar) {
    super('employee-shift');
    this.navService.pushBreadcrumb("Employees Shift Assignments");
    navService.setPageTitle("Employees Shift Assignment");
  }

  ngOnInit(): void {
    this.getData();
    this.getFilters();
  }

  public async getFilters() {

    this.floorService.getAllFloorBuildings().subscribe(d => {
      this.departments = d.results;
      //console.log("getFilters() - this.departments.length= " + this.departments.length);
      //console.log("getFilters() - this.departments= " + JSON.stringify(this.departments));
    });

    this.floorService.search().subscribe(b => {
      this.buildings = b.results;
      //console.log("getFilters() - this.buildings.length= " + this.buildings.length);
      //console.log("getFilters() - this.buildings= " + JSON.stringify(this.buildings));
    });
  }

  public async getData() {

    this.myInvalids = this.employeeShiftAssignmentService.getCalendarBlockedOutDates();

    this.employeeShiftAssignmentService.getAvailableShifts().subscribe(data => {     
      this.arrAvailableShifts = data;

      var customShift: Shift = this.arrAvailableShifts.find(item => item.name.toString().toUpperCase() === employee_shift_assignment_Constants.CUSTOM_SHIFT_NAME_VALUE.toUpperCase() );
      this.CUSTOM_SHIFT_ID = customShift.shiftId;
      //console.log("getData() - getAvailableShifts() - this.arrAvailableShifts.length= " + this.arrAvailableShifts.length);
      //console.log("getData() - getAvailableShifts() - this.arrAvailableShifts= " + JSON.stringify(this.arrAvailableShifts));
    });
     
    this.search();  //get Employees

    this.employeeShiftAssignmentService.getEmployeeShiftAssignments().subscribe(data => {
      this.arrAssignedMbscCalendarEvent = this.employeeShiftAssignmentService.map_ArrEmployeeShiftAssignments_To_ArrMbscCalendarEvent(data);

      //console.log("getData() - getEmployeeShiftAssignments() - this.arrAssignedMbscCalendarEvent.length= " + this.arrAssignedMbscCalendarEvent.length);
      //console.log('getData() - getEmployeeShiftAssignments() - this.arrAssignedMbscCalendarEvent = ' + JSON.stringify(this.arrAssignedMbscCalendarEvent ))
    });
  }
    
  protected async search() {
    this.results = null;
    var filter = "";
    
    this.userService.search(this.searchString, this.pageNumber, this.sortTerm, this.sortDirection, filter).subscribe(results => {
      //console.log("search() - Start - results.results.length= " + results.results.length);

      this.searchResults = results;
      this.results = results.results
      this.myResourcesEmployee = this.employeeShiftAssignmentService.map_ArrUser_To_ArryResourcesEmployee(this.results);

      //console.log("search() - this.myResourcesEmployee.length= " + this.myResourcesEmployee.length);
      //console.log("search() - mapToMyResourcesEmployees() - this.myResourcesEmployee = " + JSON.stringify(this.myResourcesEmployee));
    });
  }


  public onSelect(record: User): void {
    //this.router.navigate(['/employee', record.userId]);
    console.log("onSelect() - Start");
  }

  rangeOptions: MbscDatepickerOptions = {
    controls: ['time'],
    select: 'range',
    display: 'anchored',
    showRangeLabels: false,
    touchUi: false,
    stepMinute: 30
  };

  popupHeader: string;

  popupAddButtons = ['cancel', {
    handler: () => {
      this.saveChanges();
    },
    keyCode: 'enter',
    text: 'Add',
    cssClass: 'mbsc-popup-button-primary'
  }];

  popupEditButtons = ['cancel', {
    handler: () => {
      this.saveChanges();
    },
    keyCode: 'enter',
    text: 'Save',
    cssClass: 'mbsc-popup-button-primary'
  }];

  popupButtons: any = [];

  popupOptions: MbscPopupOptions = {
    display: 'bottom',
    contentPadding: false,
    fullScreen: true,
    closeOnOverlayClick: false,

    onClose: () => {
      if (!this.editing) {
        // refresh the list, if add popup was canceled, to remove the temporary shift
        this.arrAssignedMbscCalendarEvent = [...this.arrAssignedMbscCalendarEvent];
      }
    },
    responsive: {
      medium: {
        display: 'center',
        width: 500,
        fullScreen: false,
        touchUi: false,
        showOverlay: false
      }
    }
  };

  calendarOptions: MbscEventcalendarOptions = {
    view: {
      timeline: {
        type: 'week',
        eventList: true,
        startDay: 1,
        endDay: 5
      }
    },
    dragToCreate: true,
    dragToResize: true,
    dragToMove: true,
    clickToCreate: 'single',
    resources: this.myResourcesEmployee,
    invalid: this.myInvalids,
    exclusiveEndDates: true,

    onEventClick: (event, inst) => {
      this.selectedMbscCalendarEvent = event.event;
      const resource: any = this.myResourcesEmployee.find((obj) => obj.id === this.selectedMbscCalendarEvent.resource);

      var spanName = ' <span style="color: rgb(253, 112, 55)" >' + resource.name + '\'s </span>';
      this.popupHeader = '<div>Edit ' + spanName + ' hours</div><div class="employee-shift-day">' + formatDate('DDDD, DD MMMM YYYY', new Date(this.selectedMbscCalendarEvent.start.toString())) + '</div>';

      this.editing = true;
      this.selectedMbscCalendarEvent.x = event.event.x

      this.shiftAssignmentRecurrenceId = undefined;
      if (this.selectedMbscCalendarEvent.x[this.valueIndex_ShiftAssginmentRecurrenceId] != undefined) {
        this.shiftAssignmentRecurrenceId = Number(this.selectedMbscCalendarEvent.x[this.valueIndex_ShiftAssginmentRecurrenceId].toString());
        //console.log("onEventClick() - this.shiftAssignmentRecurrenceId= " + this.shiftAssignmentRecurrenceId);
      }
        
      //console.log("onEventClick() - this.selectedMbscCalendarEvent= " + JSON.stringify(this.selectedMbscCalendarEvent) );
      this.actionType = "Edit";
      this.loadPopupForm(this.selectedMbscCalendarEvent, resource);
      this.popupButtons = this.popupEditButtons;
      this.objMbscPopup.open();
    },
    onEventCreated: (event, inst) => {
      this.selectedMbscCalendarEvent = event.event;

      setTimeout(() => {
        const resource: any = this.myResourcesEmployee.find((obj) => obj.id === this.selectedMbscCalendarEvent.resource);

        var spanName = ' <span style="color: rgb(253, 112, 55)" >' + resource.name + '\'s </span>';
        this.popupHeader = '<div>New shift for ' + spanName + ' </div><div class="employee-shift-day">' + formatDate('DDDD, DD MMMM YYYY', new Date(event.event.start.toString())) + '</div>';

        this.editing = false;
        this.selectedMbscCalendarEvent.x = {};
        this.selectedShift = new Shift();
        this.shiftAssignmentRecurrenceId = undefined;

        //console.log("onEventCreated() - selectedMbscCalendarEvent= " + JSON.stringify(this.selectedMbscCalendarEvent));
        this.actionType = "Add";
        this.loadPopupForm(this.selectedMbscCalendarEvent, resource);
        this.popupButtons = this.popupAddButtons;
        this.objMbscPopup.open();
      });
    },
    onEventDeleted: (event, inst) => {
      this.selectedMbscCalendarEvent = event.event;

      setTimeout(() => {
        this.deleteShift();
      });
    },
    onSelectedDateChange: (event, inst) => {       
      console.log("onSelectedDateChange() - Start");
    }, 
    onEventUpdated: (event, inst) => {
      this.selectedMbscCalendarEvent = event.event;
      this.validatePopupForm = false;
      console.log("onEventUpdated() - Start this.validatePopupForm= " + this.validatePopupForm + ", this.selectedMbscCalendarEvent= " + JSON.stringify(this.selectedMbscCalendarEvent));

      setTimeout(() => {
        this.actionType = "Edit";
        this.editing = true;
        this.employeeShiftAssignmentId = Number(this.selectedMbscCalendarEvent.id);
        this.selectedShift = event.event.x[this.valueIndex_Shift];
        this.shiftAssignmentRecurrenceId = event.event.x[this.valueIndex_ShiftAssginmentRecurrenceId];
        this.shiftNotes = this.selectedMbscCalendarEvent.notes;

        //console.log("onEventUpdated() - this.selectedShift= " + JSON.stringify(this.selectedShift));

        this.shiftDate = [new Date(this.selectedMbscCalendarEvent.start.toString()), new Date(this.selectedMbscCalendarEvent.end.toString())];

        this.saveChanges();

        this.validatePopupForm = true;
      });

    },
    onEventHoverIn: (event, inst) => {
      //console.log("onEventHoverIn() - Start");
    }  
     
  };
    
  setEmployeeShiftAssignment(employeeShiftAssignmentId: number, shiftId: number, shift: Shift, resourceId: string, title: string, color: string, notes: string, jsonConfiguration: string, startDate?: Date, endDate?: Date, startTime?: string, endTime?: string): EmployeeShiftAssignment {

    var employeeShiftAssignment: EmployeeShiftAssignment = new EmployeeShiftAssignment();
    employeeShiftAssignment.title = title; 
    employeeShiftAssignment.color = color;
    employeeShiftAssignment.notes = notes;
    employeeShiftAssignment.startDate = startDate;
    employeeShiftAssignment.endDate = endDate;
    employeeShiftAssignment.shiftId = shiftId;
    employeeShiftAssignment.resourceId = resourceId;

    if (this.actionType === 'Edit') {
      employeeShiftAssignment.employeeShiftAssignmentId = employeeShiftAssignmentId;
      employeeShiftAssignment.shift = shift;
    }

    return employeeShiftAssignment;
  }

  private setEmployeeShiftAssignmentRecurrence(shiftAssignmentRecurrenceId: number, selectedRecurrenceEndOption: string, mbscCalendarEvent: MbscCalendarEvent, employeeShiftAssignment: EmployeeShiftAssignment): EmployeeShiftAssignment {
    //Recurrence
    if (mbscCalendarEvent.recurring != undefined) {
      employeeShiftAssignment.shiftAssignmentRecurrence = new ShiftAssignmentRecurrence();

      if (shiftAssignmentRecurrenceId != undefined)
        employeeShiftAssignment.shiftAssignmentRecurrence.shiftAssignmentRecurrenceId = shiftAssignmentRecurrenceId;

      employeeShiftAssignment.shiftAssignmentRecurrence.repeat = (mbscCalendarEvent.recurring as MbscCalendarEvent).repeat;
      employeeShiftAssignment.shiftAssignmentRecurrence.repeatInterval = (mbscCalendarEvent.recurring as MbscCalendarEvent).interval;
      employeeShiftAssignment.shiftAssignmentRecurrence.weekDays = (mbscCalendarEvent.recurring as MbscCalendarEvent).weekDays;
      employeeShiftAssignment.shiftAssignmentRecurrence.startDate = (mbscCalendarEvent.recurring as MbscCalendarEvent).from;


      switch (selectedRecurrenceEndOption.toUpperCase()) {
        case shift_assignment_recurrence_Constants.RECURRENCE_END_OPTION_NEVER.toUpperCase():
          employeeShiftAssignment.shiftAssignmentRecurrence.recurrenceEndOption = shift_assignment_recurrence_Constants.RECURRENCE_END_OPTION_NEVER;
          employeeShiftAssignment.shiftAssignmentRecurrence.countMax = this.MAX_NUM_SHIFT_RECURRENCE_OCURRENCES;
          employeeShiftAssignment.shiftAssignmentRecurrence.untilDate = undefined;
          break;

        case shift_assignment_recurrence_Constants.RECURRENCE_END_OPTION_ON.toUpperCase():
          employeeShiftAssignment.shiftAssignmentRecurrence.recurrenceEndOption = shift_assignment_recurrence_Constants.RECURRENCE_END_OPTION_ON;
          employeeShiftAssignment.shiftAssignmentRecurrence.countMax = this.MAX_NUM_SHIFT_RECURRENCE_OCURRENCES;
          employeeShiftAssignment.shiftAssignmentRecurrence.untilDate = (mbscCalendarEvent.recurring as MbscCalendarEvent).until;
          break;

        case shift_assignment_recurrence_Constants.RECURRENCE_END_OPTION_AFTER.toUpperCase():
          employeeShiftAssignment.shiftAssignmentRecurrence.recurrenceEndOption = shift_assignment_recurrence_Constants.RECURRENCE_END_OPTION_AFTER;
          employeeShiftAssignment.shiftAssignmentRecurrence.countMax = (mbscCalendarEvent.recurring as MbscCalendarEvent).count;
          employeeShiftAssignment.shiftAssignmentRecurrence.untilDate = undefined;
          break;

        default:
          console.log("Error: selectedRecurrenceEndOption not recognized. selectedRecurrenceEndOption= " + selectedRecurrenceEndOption);
          break;
      }
    }
    return employeeShiftAssignment;
  }


  loadPopupForm(mbscCalendarEvent: MbscCalendarEvent, resourceEmployee: ResourceEmployee): void {
    //console.log("loadPopupForm() - mbscCalendarEvent= " + JSON.stringify(mbscCalendarEvent) );
     
    this.employeeShiftAssignmentId = Number(mbscCalendarEvent.id);
    console.log("loadPopupForm() - mbscCalendarEvent.id= " + mbscCalendarEvent.id + ", this.shiftAssignmentRecurrenceId= " + this.shiftAssignmentRecurrenceId);
    this.shiftNotes = mbscCalendarEvent.notes;

    this.shiftDate = [new Date(mbscCalendarEvent.start.toString()), new Date(mbscCalendarEvent.end.toString())];

    this.selectedShift = mbscCalendarEvent.x[this.valueIndex_Shift];
    this.mbsCalendarEvent_color = mbscCalendarEvent.color;

    this.resetRecurrencePopUpValues();

    if (this.selectedShift && this.selectedShift.shiftId) {   //Update
      this.shiftsFormControl.setValue(this.selectedShift.shiftId);

      this.isCustomShift = this.isACustomShift(this.selectedShift.shiftId);

      //Recurrence
      if (mbscCalendarEvent.recurring != undefined) {
        this.recurrenceEventPanelExpanded = true;
        this.recurrenceEnabled = true;
        this.selectedRecurrenceEnd = mbscCalendarEvent.x[this.valueIndex_ShiftAssginmentRecurernceEndOption].toString().toUpperCase();

        switch (this.selectedRecurrenceEnd.toUpperCase()) {
          case shift_assignment_recurrence_Constants.RECURRENCE_END_OPTION_NEVER.toUpperCase():

            this.numOccurrences = 1;
            this.recurrenceEndDate = undefined;
            break;

          case shift_assignment_recurrence_Constants.RECURRENCE_END_OPTION_ON.toUpperCase():

            this.numOccurrences = 1;
            this.recurrenceEndDate = (mbscCalendarEvent.recurring as MbscCalendarEvent).until;
            break;

          case shift_assignment_recurrence_Constants.RECURRENCE_END_OPTION_AFTER.toUpperCase():

            this.numOccurrences = (mbscCalendarEvent.recurring as MbscCalendarEvent).count;
            this.recurrenceEndDate = undefined;
            break;

          default:
            console.log("Error: selectedRecurrenceEnd not recognized. this.selectedRecurrenceEndOption= " + this.selectedRecurrenceEnd);
            break;
        }

        this.selectedRecurrenceFrequency = this.arrRecurrenceFrequency.find(x => x.value.toUpperCase() == mbscCalendarEvent.recurring.repeat.toString().toUpperCase());
        this.onSelectionChangeFrequency(this.selectedRecurrenceFrequency);

        this.repeatInterval = (mbscCalendarEvent.recurring as MbscCalendarEvent).interval;

        if ((mbscCalendarEvent.recurring as MbscCalendarEvent).weekDays != undefined && (mbscCalendarEvent.recurring as MbscCalendarEvent).weekDays != '') {
          this.showRepeatOnOptions = true;
          this.selected_arrOptions_RepeatOn_Week = (mbscCalendarEvent.recurring as MbscCalendarEvent).weekDays.split(',');
        }

        this.recurrenceStartDate = (mbscCalendarEvent.recurring as MbscCalendarEvent).from;
      }

      //this.employeeShiftAssignment = this.setEmployeeShiftAssignment(this.employeeShiftAssignmentId, this.selectedShift.shiftId, this.selectedShift, resourceEmployee.id, this.mbsCalendarEvent_title, this.mbsCalendarEvent_color, this.shiftNotes,null, startDate, endDate);
    }
    else {  //Add
      this.shiftsFormControl.reset();
      this.resetRecurrencePopUpValues();
    }
  }
   

  private resetRecurrencePopUpValues(): void {

    this.recurrenceEnabled = false;
    this.recurrenceEventPanelExpanded = false;
    this.selectedRecurrenceFrequency = this.arrRecurrenceFrequency[1];
    this.repeatInterval = 1;
    this.onSelectionChangeFrequency(this.selectedRecurrenceFrequency);
    this.selected_arrOptions_RepeatOn_Week = [];
    this.selectedRecurrenceEnd = shift_assignment_recurrence_Constants.RECURRENCE_END_OPTION_NEVER.toUpperCase();
    this.recurrenceStartDate = new Date();
    this.recurrenceEndDate = new Date();
    this.numOccurrences = 1;
  }


  openSnackBarMessageAlert(message: string, action: string) {
    this.matSnackBar.open(message, action, { duration: 4000 });
  }


  onSelectionChangeFrequency(value) {
    //console.log("onSelectionChangeFrequency() - event= " + JSON.stringify(value));

    this.showRepeatOnOptions = (value.value.toUpperCase() === 'WEEKLY');
  }

  toggleSelection_RepeatOn(chip: MatChip) {
    let arrRemoved: string[];
    let length: number;

    if (this.recurrenceEnabled) {
      //console.log("toggleSelection_RepeatOn() - chip.value= " + chip.value);
      chip.toggleSelected();

      if (chip.selected) { //add to array
        length = this.selected_arrOptions_RepeatOn_Week.push(chip.value);

      }
      else {  //remove from array
        let index: number = this.selected_arrOptions_RepeatOn_Week.findIndex(x => x.toUpperCase() === chip.value.toUpperCase());
        if (index > -1)
          arrRemoved = this.selected_arrOptions_RepeatOn_Week.splice(index, 1);
      }
    }
  }
 

  formIsValid() {
    let isValid = true;

    if (this.validatePopupForm) {
      if (this.shiftsFormControl.invalid) {
        isValid = false;
        //this.utilitySvc.showAlert("A Shift is Required", "<p>Please select a Shift.</p>");
        this.notify.toast({
          message: 'Please select a Shift.'
        });
      }

      if (this.recurrenceEnabled) {
        if (this.selected_arrOptions_RepeatOn_Week.length == 0) {
          isValid = false;
          this.notify.toast({
            message: 'Please select at least one Week Day.'
          });
        }

        if (this.selectedRecurrenceEnd == undefined) {
          isValid = false;
          this.notify.toast({
            message: 'Please select an Recurrence End option.'
          });
        }
      }

    } 
    return isValid;
  }


  saveChanges(): void {
    this.saving = true;
     
    if (this.formIsValid()) {

      var offset = new Date().getTimezoneOffset();
      console.log("saveChanges() offset= " + offset)

      this.selectedMbscCalendarEvent.title = this.selectedShift.name;
      this.selectedMbscCalendarEvent.notes = this.shiftNotes;
      this.selectedMbscCalendarEvent.start = this.shiftDate[0];
      this.selectedMbscCalendarEvent.end = this.shiftDate[1];
      var startDate: Date = new Date(this.selectedMbscCalendarEvent.start.toString());
      var endDate: Date = new Date(this.selectedMbscCalendarEvent.end.toString());
      var startTime = formatDate('HH:mm', new Date(this.shiftDate[0].toString()));
      var endTime = formatDate('HH:mm', new Date(this.shiftDate[1].toString()));
      this.selectedMbscCalendarEvent.x[this.valueIndex_Shift] = this.selectedShift;

      //Recurrence
      if (this.recurrenceEnabled != undefined && this.recurrenceEnabled) {
        if ((this.selectedMbscCalendarEvent.recurring as MbscCalendarEvent) == undefined) 
          (this.selectedMbscCalendarEvent.recurring as MbscCalendarEvent) = {};

        (this.selectedMbscCalendarEvent.recurring as MbscCalendarEvent).repeat = this.selectedRecurrenceFrequency.value;
        (this.selectedMbscCalendarEvent.recurring as MbscCalendarEvent).interval = this.repeatInterval;
        (this.selectedMbscCalendarEvent.recurring as MbscCalendarEvent).weekDays = this.selected_arrOptions_RepeatOn_Week.join(','); 

        switch (this.selectedRecurrenceEnd.toUpperCase()) {
          case shift_assignment_recurrence_Constants.RECURRENCE_END_OPTION_NEVER.toUpperCase():
            this.selectedMbscCalendarEvent.x[this.valueIndex_ShiftAssginmentRecurernceEndOption] = shift_assignment_recurrence_Constants.RECURRENCE_END_OPTION_NEVER.toUpperCase();
            (this.selectedMbscCalendarEvent.recurring as MbscCalendarEvent).count = this.MAX_NUM_SHIFT_RECURRENCE_OCURRENCES;
            (this.selectedMbscCalendarEvent.recurring as MbscCalendarEvent).until = undefined;
            break;

          case shift_assignment_recurrence_Constants.RECURRENCE_END_OPTION_ON.toUpperCase():
            this.selectedMbscCalendarEvent.x[this.valueIndex_ShiftAssginmentRecurernceEndOption] = shift_assignment_recurrence_Constants.RECURRENCE_END_OPTION_ON.toUpperCase();
            (this.selectedMbscCalendarEvent.recurring as MbscCalendarEvent).count = this.MAX_NUM_SHIFT_RECURRENCE_OCURRENCES;
            (this.selectedMbscCalendarEvent.recurring as MbscCalendarEvent).until = this.recurrenceEndDate;
            break;

          case shift_assignment_recurrence_Constants.RECURRENCE_END_OPTION_AFTER.toUpperCase():
            this.selectedMbscCalendarEvent.x[this.valueIndex_ShiftAssginmentRecurernceEndOption] = shift_assignment_recurrence_Constants.RECURRENCE_END_OPTION_AFTER.toUpperCase();
            (this.selectedMbscCalendarEvent.recurring as MbscCalendarEvent).count = this.numOccurrences;
            (this.selectedMbscCalendarEvent.recurring as MbscCalendarEvent).until = undefined;
            break;

          default:
            console.log("Error: selectedRecurrenceEnd not recognized. this.selectedRecurrenceEndOption= " + this.selectedRecurrenceEnd);
            break;
        }
      }
      else //No Recurrence
        this.selectedMbscCalendarEvent.recurring = undefined;

      if (this.selectedShift) {
        this.selectedMbscCalendarEvent.color = this.selectedShift.color;
        this.mbsCalendarEvent_color = this.selectedShift.color;
      }


      if (this.actionType === 'Add') {
        this.employeeShiftAssignment = this.setEmployeeShiftAssignment(undefined, this.selectedShift.shiftId, undefined, this.selectedMbscCalendarEvent.resource.toString(), this.selectedMbscCalendarEvent.title, this.mbsCalendarEvent_color, this.shiftNotes, null, startDate, endDate, startTime, endTime);
        this.employeeShiftAssignment = this.setEmployeeShiftAssignmentRecurrence(undefined, this.selectedRecurrenceEnd, this.selectedMbscCalendarEvent, this.employeeShiftAssignment);
        console.log("SaveChanges() - Add - this.employeeShiftAssignment= " + JSON.stringify(this.employeeShiftAssignment));

        this.employeeShiftAssignmentService.addEmployeeShiftAssignment(this.employeeShiftAssignment).subscribe(data => {
          //console.log("saveChanges() - data= " + JSON.stringify(data));
          this.employeeShiftAssignmentId = data.employeeShiftAssignmentId;
          this.selectedMbscCalendarEvent.id = data.employeeShiftAssignmentId;
          console.log("saveChanges() - this.selectedMbscCalendarEvent.id= " + this.selectedMbscCalendarEvent.id );

          this.arrAssignedMbscCalendarEvent = [...this.arrAssignedMbscCalendarEvent, this.selectedMbscCalendarEvent];

          this.openSnackBarMessageAlert("Successfully Added.", "Close");
          //console.log("saveChanges() - Successfully Added.");
        },
          err => { 
            this.openSnackBarMessageAlert("Error while Adding.", "Close");
            //console.log("Error while Adding. err= " + err);
          });
      }
      else if (this.actionType === 'Edit') {
        this.employeeShiftAssignment = this.setEmployeeShiftAssignment(this.employeeShiftAssignmentId, this.selectedShift.shiftId, this.selectedShift, this.selectedMbscCalendarEvent.resource.toString(), this.selectedMbscCalendarEvent.title, this.mbsCalendarEvent_color, this.shiftNotes, null, startDate, endDate, startTime, endTime);
        this.employeeShiftAssignment = this.setEmployeeShiftAssignmentRecurrence(this.shiftAssignmentRecurrenceId, this.selectedRecurrenceEnd, this.selectedMbscCalendarEvent, this.employeeShiftAssignment);
        console.log("SaveChanges() - Edit - this.employeeShiftAssignment= " + JSON.stringify(this.employeeShiftAssignment));

        this.employeeShiftAssignmentService.updateEmployeeShiftAssignment(this.employeeShiftAssignment).subscribe(data => {
          this.arrAssignedMbscCalendarEvent = [...this.arrAssignedMbscCalendarEvent];

          this.openSnackBarMessageAlert("Successfully Updated.", "Close");
          //console.log("saveChanges() - Successfully Updated.");
        }, err => {

          this.openSnackBarMessageAlert("Error while Updating.", "Close");
          //console.log("Error while Updating. err= " + err);
        });
      }

      this.objMbscPopup.close();

      var periodInHours = ((new Date(this.selectedMbscCalendarEvent.end.toString())).getTime() - (new Date(this.selectedMbscCalendarEvent.start.toString())).getTime()) / this._millisecondsInOneHour ;

      var resourceId_str = this.selectedMbscCalendarEvent.resource.toString();
      this.sumScheduledHours(resourceId_str, periodInHours)

      //this.editing = false;
    }

    this.saving = false;
  }

  deleteShift(): void {
    var resource: ResourceEmployee = this.myResourcesEmployee.find((obj) => obj.id === this.selectedMbscCalendarEvent.resource);
    var shiftAssignmentHtml: string = resource.name + " <br/>" + this.selectedMbscCalendarEvent.title + " <br/>" + formatDate('M/D/YY h:mm A', new Date(this.selectedMbscCalendarEvent.start.toString())) + " - " + formatDate('M/D/YY h:mm A', new Date(this.selectedMbscCalendarEvent.end.toString()));

    this.utilitySvc.showConfirmation("Are you sure?", "<p>Are you sure you want to remove this Shift Assignment?</p><p> " + shiftAssignmentHtml + " </p>", result => {
      if (result) {
        this.saving = true;

        console.log("deleteEmployeeShiftAssignment() - this.selectedMbscCalendarEvent.id= " + this.selectedMbscCalendarEvent.id.toString());

        this.arrAssignedMbscCalendarEvent = this.arrAssignedMbscCalendarEvent.filter(item => item.id !== this.selectedMbscCalendarEvent.id);

        this.employeeShiftAssignmentService.deleteEmployeeShiftAssignment(this.selectedMbscCalendarEvent.id.toString()).subscribe(
          data => {
            this.objMbscPopup.close();
            this.saving = false;

            this.openSnackBarMessageAlert("Successfully Deleted.", "Close");
          },
          (err) => { 
            this.openSnackBarMessageAlert("Error while Deleting.", "Close");
            console.log("err= " + err)
          });
      }
    })
   
  }

  onDeleteClick(): void {
    this.deleteShift();
  }

  defaultShift(ev: any): any {
    //console.log("defaultShift() - Start ");

    var d = ev.start;
    var start = new Date(d.getFullYear(), d.getMonth(), d.getDate(), 9);
    var end = new Date(d.getFullYear(), d.getMonth(), d.getDate(), 18);

    return {
      title: formatDate('HH:mm', start) + ' - ' + formatDate('HH:mm', end),
      start,
      end,
      resource: ev.resource
    };
  }


  onSelectionChangeShift(eventValue) {
    //console.log("onSelectionChangeShift() - this.arrAvailableShifts= " + JSON.stringify(this.arrAvailableShifts) + ", eventValue= " + eventValue);

    setTimeout(() => {
      
      this.selectedShift = this.arrAvailableShifts.find(item => item.shiftId.toString().toUpperCase() === eventValue.toUpperCase());
      //console.log("onSelectionChangeShift() - this.CUSTOM_SHIFT_ID= " + this.CUSTOM_SHIFT_ID.toString() + ", eventValue= " + JSON.stringify(eventValue) + ", this.selectedShift= " + JSON.stringify(this.selectedShift));

      var paramStartTime: any;
      var paramEndTime: any;

      this.isCustomShift = this.isACustomShift(eventValue);

      if (this.isCustomShift) {
        paramStartTime = formatDate('HH:mm', new Date(this.shiftDate[0].toString()));
        paramEndTime = formatDate('HH:mm', new Date(this.shiftDate[1].toString()));
      }
      else {
        paramStartTime = this.selectedShift.startTime;
        paramEndTime = this.selectedShift.endTime;
      }
      console.log("onSelectionChangeShift() - this.isCustomShift= " + this.isCustomShift);
       
      this.shiftDate = this.employeeShiftAssignmentService.buildDateTimeObject(this.selectedMbscCalendarEvent.start, paramStartTime, this.selectedMbscCalendarEvent.end, paramEndTime);
      console.log("onSelectionChangeShift() - this.shiftDate= " + JSON.stringify(this.shiftDate));

      //console.log("onSelectionChangeShift() - this.mbscCalendarEvent= " + JSON.stringify(mbscCalendarEvent));
      //console.log("onSelectionChangeShift() - this.selectedMbscCalendarEvent= " + JSON.stringify(this.selectedMbscCalendarEvent));
    });
  }

  private isACustomShift(shiftId: number): boolean {
    let isCustomShift: boolean;

    if (shiftId != null && shiftId.toString() === this.CUSTOM_SHIFT_ID.toString()) {
      isCustomShift= true;
    }
    else {
      isCustomShift = false;
    }
    return isCustomShift;
  }


  changeView(): void {
    setTimeout(() => {
      switch (this.view) {
        case 'day':
          this.calView = {
            timeline: { type: 'day' }
          };
          break;
        case 'workweek':
          this.calView = {
            timeline: {
              type: 'week',
              eventList: true,
              startDay: 1,
              endDay: 5
            }
          };
          break;
        case 'week':
          this.calView = {
            timeline: {
              type: 'week',
              eventList: true 
            }
          };
          break;
        case 'month':
          this.calView = {
            timeline: {
              type: 'month',
              eventList: true
            }
          };
          break;
      }
    });
  }

  sumScheduledHours(resourceIndex: string, sumValueHours: number) {

    var resource = this.myResourcesEmployee.find((obj) => obj.id === resourceIndex);

    var scheduledHours_int = parseInt(resource.scheduledHours);
    console.log("sumScheduledHours() - resourceIndex= " + resourceIndex + ", sumValueHours= " + sumValueHours.toString() + ", scheduledHours_int= " + scheduledHours_int.toString());
    resource.scheduledHours = (scheduledHours_int + sumValueHours).toString();
  }


  eventUpdateFail(event): void {
    this.notify.toast({
      message: 'Can\'t schedule outside of working hours'
    });
  }



}


