<button class="heading-menu" [matMenuTriggerFor]="locations">Filter Locations <i class="fas fa-fw fa-caret-right"></i></button>
<mat-menu #locations="matMenu" class="p-3">
  <ul class="location-list">
    <li *ngIf="!selectedList || selectedList.length == 0">Viewing All</li>
    <li *ngFor="let location of selectedList">{{location.name}}</li>
  </ul>
  <div class="location-label" *ngFor="let location of locationList">
    <mat-radio-button (change)="select(location)">{{location.name}}</mat-radio-button>
  </div>
  <div>
    <a class="btn btn-outline-secondary btn-sm small float-right" (click)="clearSelection()">Clear Selection</a>
  </div>
</mat-menu>
