import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Title } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root',
})
export class NavigationService {
  private expandedState: BehaviorSubject<boolean> = new BehaviorSubject(true);
  isExpanded = this.expandedState.asObservable();
  private pageTitleSubject: BehaviorSubject<string> = new BehaviorSubject("");
  pageTitle = this.pageTitleSubject.asObservable();
  private pageBreadcrumbSubject: BehaviorSubject<string[]> = new BehaviorSubject([]);
  pageBreadcrumbs = this.pageBreadcrumbSubject.asObservable();
  private allBreadcrumbs: string[] = [];

  private condenseLength: number = 5;
  private condenseIndicator: string = ">>>";

  constructor(private titleService: Title) { }

  public toggleExpanded(state?: boolean): void {
    if (state !== null) state = !this.expandedState.value;
    this.expandedState.next(state);
  }

  public setPageTitle(newTitle: string): void {
    this.pageTitleSubject.next(newTitle);

    this.pageBreadcrumbSubject.value.length = 0;
    this.allBreadcrumbs.length = 0;
    this.pageBreadcrumbSubject.next(this.pageBreadcrumbSubject.value);

    this.setTitle();
  }

  public pushBreadcrumb(newBreadcrumb: string): void {
    this.allBreadcrumbs.push(newBreadcrumb);
    this.pageBreadcrumbSubject.value.push(newBreadcrumb);
    this.pageBreadcrumbSubject.next(this.getDisplayableBreadcrumbs());

    this.setTitle();
  }

  public popBreadCrumb(): string {
    this.allBreadcrumbs.pop();
    var popped = this.pageBreadcrumbSubject.value.pop();
    this.pageBreadcrumbSubject.next(this.getDisplayableBreadcrumbs());

    this.setTitle();

    return popped;
  }

  private getDisplayableBreadcrumbs(): string[] {
    var forDisplay: string[] = [];
    var condenseCount = Math.floor(this.allBreadcrumbs.length / this.condenseLength);

    if (condenseCount == this.allBreadcrumbs.length / this.condenseLength) condenseCount--;

    for (var i = 0; i < condenseCount; i++) {
      forDisplay.push(this.condenseIndicator);
    }

    this.allBreadcrumbs.slice(condenseCount * this.condenseLength).forEach(i => forDisplay.push(i));

    return forDisplay;
  }

  private setTitle() {
    if (this.pageBreadcrumbSubject.value.length > 0) {
      this.titleService.setTitle(`COTS - ${this.pageTitleSubject.value}: ${this.pageBreadcrumbSubject.value[0]}`);
    }
    else {
      this.titleService.setTitle(`COTS - ${this.pageTitleSubject.value}`);
    }
  }

  public clearBreadCrumbs(): void {
    this.allBreadcrumbs.length = 0;
    this.pageBreadcrumbSubject.next([]);
  }
}
