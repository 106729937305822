import { Portal, TemplatePortal } from '@angular/cdk/portal';
import { ElementRef, ViewChildren, ViewContainerRef, QueryList } from '@angular/core';
import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSelectionList } from '@angular/material/list';
import { MatSidenav } from '@angular/material/sidenav';
import { MatStep } from '@angular/material/stepper';
import { MatTabGroup } from '@angular/material/tabs';
import { Router } from '@angular/router';
import { User } from '../../../common/resources/user';
import { NavigationService } from '../../../common/services/navigation.service';
import { UserService } from '../../../common/services/user.service';
import { UtilityService } from '../../../common/services/utility.service';
import { Station } from '../../../order/resources/station';
import { Workflow, WorkflowStep, WorkflowStepType } from '../../../order/resources/workflow';
import { StationService } from '../../../order/services/station.service';
import { MaterialBid } from '../../../purchasing/resources/materialBid';
import { MaterialBidService } from '../../../purchasing/services/material-bid.service';
import { WorkOrder } from '../../resources/work-order';
import { PreplanningData, PreplanningMaterialSpec, WorkOrderService } from '../../services/work-order.service';
import { PlanningDetailComponent } from '../planning-detail/planning-detail.component';
import { Product, ProductMaterialDimension, ProductPurchasedItem } from '../../../order/resources/product';
import { NgForm } from '@angular/forms';
import { PurchasedItem } from '../../../order/resources/purchased-item';
import { Paint } from '../../../order/resources/paint';
import { MaterialSpecification } from '../../../order/resources/material';

@Component({
  selector: 'preplanning-detail',
  templateUrl: './preplanning-detail.component.html',
  styleUrls: ['./preplanning-detail.component.less']
})
export class PreplanningDetailComponent implements OnInit {

  public initializeWorkingDimensions() {
    if (!this.product.material) {
      this.product.blankDimensions = [];
      this.product.finishedDimensions = [];
      return;
    } else {
      const genDefaultDimensions = () => this.product.material.materialType.materialTypeDimensions.map<ProductMaterialDimension>(mtd => {
        const dim = this.product.material.materialDimensions.find(md => md.materialTypeDimensionId === mtd.materialTypeDimensionId)
        if (!dim) return { materialTypeDimensionId: mtd.materialTypeDimensionId, value: 0 };
        else return { materialTypeDimensionId: mtd.materialTypeDimensionId, value: dim.value };
      })
      if (this.product.blankDimensions.length === 0) this.product.blankDimensions = genDefaultDimensions();
      if (this.product.finishedDimensions.length === 0) this.product.finishedDimensions = genDefaultDimensions();
    }
  }

  @Input('workOrder') workOrder: WorkOrder;
  @Input('product') product: Product;
  @Input('parentComponent') parentComponent: PlanningDetailComponent;

  @Output() workOrderChange = new EventEmitter<WorkOrder>();
  @Output() save = new EventEmitter<void>();
  @Output() stopSave = new EventEmitter<void>();
  
  
  public loading = false;
  public showEditor = null;
  public dirty = false;

  @ViewChild('sidenav') sidenav: MatSidenav;
  @ViewChild('form') form: NgForm;

  public openWorkflow(): void {
    this.showEditor = "part";
    this.sidenav.open();
  }

  public async closeSideNav() {
    console.log('closeSidenav');
    this.showEditor = null;

    
    this.getDetail();
  }


  public openPODocument() {
    window.open('/document/' + this.workOrder.order.orderPurchaseOrder.documentId + '?inline=true', '_blank')
  }

  constructor(private woService: WorkOrderService, private userService: UserService, private navService: NavigationService, public stationService: StationService, private materialBidService: MaterialBidService, private utilityService: UtilityService, private dialog: MatDialog, private router: Router, private _viewContainerRef: ViewContainerRef) { }

  public paintItems: Paint[] = [];
  @ViewChild('programList') programList: MatSelectionList;
  public async getDetail() {
    console.log('getDetail');
    this.product.workflow.workflowSteps = this.product.workflow.workflowSteps.sort((a, b) => { return a.stepOrder - b.stepOrder; });
  
    this.initializeWorkingDimensions();

    this.paintItems = this.product.workflow.workflowSteps.filter(s => this.getStation(s.stationId).isPainting).map(p => p.paint);
    
  }

  ngOnInit() {
    this.getDetail();
  }

  

  public getDimensionValue(materialTypeDimensionId: string) {
    const d = this.product.material.materialDimensions.find(md => md.materialTypeDimensionId === materialTypeDimensionId)
    return d ? d.value : 0;
  }

  public setDimensionValue(materialTypeDimensionId: string, value: number) {
    const d = this.product.material.materialDimensions.find(md => md.materialTypeDimensionId === materialTypeDimensionId)
    if (!d) return;
    else d.value = value;
  }

  public getBlankDimension(mtdId: string) {
    const dimensions = this.product.blankDimensions || [];
    const out = dimensions.find(d => d.materialTypeDimensionId === mtdId);
    if (!out) return 0;
    else return out.value;
  }

  public setBlankDimension(mtdId: string, value: number) {
    const dimensions = this.product.blankDimensions || [];
    let out = dimensions.find(d => d.materialTypeDimensionId === mtdId);
    if (!out) return;
    else {
      out.value = value;
    }
  }

  public getFinishedDimension(mtdId: string) {
    const dimensions = this.product.finishedDimensions || [];
    let out = dimensions.find(d => d.materialTypeDimensionId === mtdId);
    if (!out) return 0;
    else return out.value;
  }

  public setFinishedDimension(mtdId: string, value: number) {
    const dimensions = this.product.finishedDimensions || [];
    let out = dimensions.find(d => d.materialTypeDimensionId === mtdId);
    if (!out) return;
    else {
      out.value = value;
    }
  }


  public getStation(stationId: string): Station {
    if (!this.stationService.stationList) return null;

    return this.stationService.stationList.find(s => s.stationId == stationId);
  }

  public outsideProcessQuotes: { [key: string]: MaterialBid } = {}

  @ViewChild('outsideProcessTabGroup') outsideProcessTabGroup: MatTabGroup;
  @ViewChild('insideProcessTabGroup') insideProcessTabGroup: MatTabGroup;

  @Output() setPortal = new EventEmitter<Portal<any>>();

  public getScrollParent(node) {
    if (node == null) {
      return null;
    }
  
    if (node.scrollHeight > node.clientHeight) {
      return node;
    } else {
      return this.getScrollParent(node.parentNode);
    }
  }

  @ViewChildren('anchor') stepScrollAnchors: QueryList<ElementRef>;
  public onSelectedStep(index: number) {
    // setTimeout(() => {
    //   const step = this.stepScrollAnchors.toArray()[index];
    //   const anchorElement: HTMLElement = step.nativeElement;
    //   const parent: HTMLElement = document.querySelector('#planning-tab-group .mat-tab-body-content');
    //   const y = anchorElement.getBoundingClientRect().y - parent.getBoundingClientRect().y - 48;
    //   parent.scrollTo({ top: y, left: 0, behavior: 'smooth' })
    // }, 150)
  }

  public createProgrammingCNC = false;
  public assignedProgrammerCNC: User;
  public createProgrammingNC = false;
  public assignedProgrammerNC: User;
  public createProgrammingCMM = false;
  public assignedProgrammerCMM: User;

  public newPurchasedItem: PurchasedItem | null;
  @ViewChild('newPurchasedItemDialog', { static: true }) newPurchasedItemDialog: TemplateRef<any>;
  public async addPurchasedItem() {
    this.newPurchasedItem = null;
    const ref = this.dialog.open(this.newPurchasedItemDialog, {
      disableClose: true,
    });
    const r: boolean = await ref.afterClosed().toPromise();
    if (!r) return;
    else this.product.purchasedItems.push(<ProductPurchasedItem>{
      productPurchasedItemId: UtilityService.emptyGuid,
      purchasedItemId: this.newPurchasedItem.purchasedItemId,
      isAmortized: false,
      isNonRecurring: false,
      itemRepairType: WorkflowStepType.Standard,
      markupPercent: 18,
      productId: this.product.productId,
      quantity: 1,
      purchasedItem: this.newPurchasedItem
    });
  }

  public deletePurchasedItem(index: number) {
    this.product.purchasedItems = this.product.purchasedItems.filter((p, i) => i !== index);
  }



  public newPaint: Paint | null;
  @ViewChild('newPaintDialog', { static: true }) newPaintDialog: TemplateRef<any>;
  public async addPaint() {
    this.newPaint = null;
    const ref = this.dialog.open(this.newPaintDialog, {
      disableClose: true,
      data: null
    });
    const r: boolean = await ref.afterClosed().toPromise();
    if (!r) return;
    this.paintItems = [...this.paintItems, this.newPaint];
  }

  public deletePaint(index: number) {
    this.paintItems = this.paintItems.filter((p, i) => i !== index);
  }

  public assignedPlanner: User;

  public get preplanningData(): PreplanningData {
    return {
      product: this.product,
      paintList: this.paintItems,
      specs: this.specifications,
      createProgrammingCNC: this.createProgrammingCNC,
      assignedProgrammerCNC: this.assignedProgrammerCNC && this.assignedProgrammerCNC.userId,
      createProgrammingNC: this.createProgrammingNC,
      assignedProgrammerNC: this.assignedProgrammerNC && this.assignedProgrammerNC.userId,
      createProgrammingCMM: this.createProgrammingCMM,
      assignedProgrammerCMM: this.assignedProgrammerCMM && this.assignedProgrammerCMM.userId,
      assignedPlanner: this.assignedPlanner.userId,
    }
  }

  public togglePartsPerMaterialOverride(e) {
    e.preventDefault();
    this.product.partsPerMaterialOverride = !this.product.partsPerMaterialOverride;
    this.dirty = true;
  }
  public get blanksPerMaterial(): number {
    if (Product.getVolume(this.product) === 0) return 0;
    return Math.floor(Product.getVolume(this.product) / Product.getBlankVolume(this.product))
  }

  public togglePartsPerBlankOverride(e) {
    e.preventDefault();
    this.product.partsPerBlankOverride = !this.product.partsPerBlankOverride;
    this.dirty = true;
  }
  public get partsPerBlank(): number {
    if (Product.getFinishedVolume(this.product) === 0) return 0;
    return Math.floor(Product.getBlankVolume(this.product) / Product.getFinishedVolume(this.product))
  }

  public specifications: PreplanningMaterialSpec[] = [];
  public setSpecification(spec: MaterialSpecification, state: boolean) {
    console.log(spec, state);
    if (state === true) {
      this.specifications = [...this.specifications, {
        materialSpecificationId: spec.materialSpecificationId,
        isRequired: false,
      }];
    } else {
      this.specifications = this.specifications.filter(s => s.materialSpecificationId !== spec.materialSpecificationId);
    }
    this.dirty = true;
    console.log(this.specifications);
  }

  public get mv() {
    return Product.getVolume(this.product);
  }

  public get bv() {
    return Product.getBlankVolume(this.product);
  }

  public get fv() {
    return Product.getFinishedVolume(this.product);
  }

}
