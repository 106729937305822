import { UtilityService } from "../../common/services/utility.service";
import { InventoryItem } from "../../inventory/resources/inventory-item";
import { MachineAssignment } from "../../planning/resources/machine-assignment";
import { ShippingTicket } from "../../shipping/resources/shipping-ticket";
import { Paint } from "./paint";
import { SchedulingTicket } from "./schedulingTicket";
import { Station } from "./station";

export interface Workflow {
  workflowId: string;
  name: string;

  workflowSteps: WorkflowStep[];
}


export enum WorkflowStepType {
  Standard = 0,
  Repair = 1,
  Amend = 2
}

export enum WorkflowStepInventoryItemType {
  INPUT = 0,
  OUTPUT = 1,
  TOOLING = 2,
  INSPECTION_TOOLING = 3
}
export enum WorkflowStepPlanningStatus {
  UNPLANNED = 0,
  NEEDS_CHECK = 1,
  PLANNED = 2
}
export interface WorkflowStepInventoryItem
{
  workflowStepInventoryItemId: string;
  workflowStepId: string;
  inventoryItemId: string;
  inventoryItem: InventoryItem;

  type: WorkflowStepInventoryItemType;
  quantity: number;
}

export class WorkflowStep {
  workflowStepId: string;
  stepOrder: number;
  workflowId: string;
  planningStatus: WorkflowStepPlanningStatus;
  description: string;
  stationId: string;
  runTime?: number;
  runPrice?: number;
  runIsPerPart?: boolean;
  outsourceMarkup?: number;
  outsourceIsFinal?: boolean;
  processStarted?: Date;
  processCompleted?: Date;
  paintId?: string;
  paintedArea?: number;
  paintCoats?: number;
  paintCost?: number;
  paintPrice?: number;
  paintMinPrice?: number;
  stepType: WorkflowStepType;
  perPieceSetupTime?: number;
  specification: string;
  outsideProcessSpecifications: string[];

  inspectionIsCMM: boolean;
  hasFirstPartInspection: boolean;
  firstPartInspectionTime: number;
  firstPartInspectionRate: number;
  inspectionIsBatched: boolean;
  inspectionBatchSize: number;
  inspectionNotes: string;

  hasInspection: boolean;
  inspectionTime: number;
  inspectionRate: number;

  hasProgramming: boolean;
  programmingTime: number;
  programmingRate: number;

  outgoingShippingTicketId?: string;
  incomingShippingTicketId?: string;

  selectedQuote?: string;
  isStandalone: boolean;

  hasSetup: boolean;
  setupTime: number;

  paint: Paint;
  isFromCompare?: boolean = false;

  outgoingShippingTicket?: ShippingTicket;
  incomingShippingTicket?: ShippingTicket;

  workflowStepInventoryItems: WorkflowStepInventoryItem[];
  machineAssignments: MachineAssignment[];
  schedulingTicket: SchedulingTicket;

  public static calculateCost(step: WorkflowStep): number {
    if (step.paint) {
      let paintCost: number;
      const paintedArea = step.paintedArea * step.paintCoats;
      const calculatedPaintCost = (step.runPrice * paintedArea) + (step.paintCost * paintedArea);
      if (step.paintMinPrice > calculatedPaintCost) paintCost = step.paintMinPrice;
      else paintCost = calculatedPaintCost;

      return ((step.runIsPerPart || false) ? (1 / 60.0) : 1.0) * paintCost;
    }

    let finalCost: number;
    if (step.outsourceMarkup) {
      finalCost = (step.runPrice || 0) * (1 + ((step.outsourceMarkup || 0) / 100));
    } else {
      const oneTimeSetup = step.hasSetup ? ((step.setupTime * step.runPrice)) : 0;
      const perPartSetup = (step.hasSetup && step.runIsPerPart) ? ((step.perPieceSetupTime * (step.runPrice / 60))) : 0;
      const programmingCost = step.hasProgramming ? ((step.programmingTime || 0) * (step.programmingRate || 0)) : 0;
      
      const runTime = step.runIsPerPart ? ((step.runTime || 0) / 60): (step.runTime || 0);
      const mainCost = (step.runPrice || 0) * runTime;


      const firstPartInspectionCost = step.hasFirstPartInspection ? (((step.firstPartInspectionTime || 0) / 60) * (step.firstPartInspectionRate || 0)) : 0;
      
      finalCost = oneTimeSetup + perPartSetup + firstPartInspectionCost + programmingCost + mainCost;
    }

    return parseFloat(finalCost.toFixed(2))
  }

  public static getProgrammingCost(step: WorkflowStep) {
    return step.hasProgramming ? ((step.programmingTime || 0) * (step.programmingRate || 0)) : 0;
  }

  public static getBatchedInspectionCost(step: WorkflowStep, qty: number): number {
    if (step.hasInspection && step.inspectionIsBatched) {
      const batchCount = Math.ceil(qty / (step.inspectionBatchSize || 1));
      const batchedInspectionCost = ((step.inspectionTime || 0) / 60) * (step.inspectionRate || 0);
      return batchedInspectionCost * batchCount;
    } else return 0;
  }

  public static calculateCostForQty(step: WorkflowStep, qty: number): number {
    if (step.paint) {
      let paintCost: number;
      const paintedArea = step.paintedArea * step.paintCoats;
      const calculatedPaintCost = (step.runPrice * paintedArea) + (step.paintCost * paintedArea);
      if (step.paintMinPrice > calculatedPaintCost) paintCost = step.paintMinPrice;
      else paintCost = calculatedPaintCost;

      return ((step.runIsPerPart || false) ? (1 / 60.0) : 1.0) * paintCost * (step.runIsPerPart ? qty : 1);
    }

    let finalCost = 0;
    if (step.outsourceMarkup) {
      finalCost = (step.runPrice || 0) * (1 + ((step.outsourceMarkup || 0) / 100)) * (step.runIsPerPart ? qty : 1);
    } else {
      const runTime = step.runIsPerPart ? ((step.runTime || 0) / 60): (step.runTime || 0);
      const perRunCost = (step.runPrice || 0) * runTime;
      // One-time stuff
      const oneTimeSetupCost = step.hasSetup ? ((step.setupTime * step.runPrice)) : 0;
      finalCost += oneTimeSetupCost;
      // const programmingCost = this.getProgrammingCost(step);
      // finalCost += programmingCost;
      const firstPartInspectionCost = step.hasFirstPartInspection ? (((step.firstPartInspectionTime || 0) / 60) * (step.firstPartInspectionRate || 0)) : 0;
      finalCost += firstPartInspectionCost;
      if (!step.runIsPerPart) finalCost += perRunCost;
      // Per-qty stuff
      let perQtyCost = 0;
      const perPartSetupCost = (step.hasSetup && step.runIsPerPart) ? ((step.perPieceSetupTime * (step.runPrice / 60))) : 0;
      perQtyCost += perPartSetupCost
      if (step.runIsPerPart) perQtyCost += perRunCost;
      const unbatchedInspectionCost = (step.hasInspection) ? (((step.inspectionTime || 0) / 60) * (step.inspectionRate || 0)) : 0;
      if (!step.inspectionIsBatched) perQtyCost += (step.hasInspection) ? (((step.inspectionTime || 0) / 60) * (step.inspectionRate || 0)) : 0;
      finalCost += perQtyCost * (step.runIsPerPart ? qty : 1);
      const batchedInspectionCost = this.getBatchedInspectionCost(step, qty);
      finalCost += batchedInspectionCost;
    }
    return parseFloat(finalCost.toFixed(2))
  }

  public static calculatePerItemCost(step: WorkflowStep, qty: number): number {
    return WorkflowStep.calculateCostForQty(step, qty) / qty;
  }

  public static newEmptyStep(station: Station) {
    const newStep = <WorkflowStep>{
      workflowStepId: UtilityService.emptyGuid,
      stationId: station.stationId,
      runPrice: station.isOutsourceStep ? null : station.stdCostPerHour,
      runIsPerPart: station.perPart,
      isStandalone: station.isStandalone || false,
      hasSetup: station.hasSetup ? true : (station.isOutsourceStep ? false : true),
      setupTime: station.defaultSetupTime,
      outsourceMarkup: station.isOutsourceStep ? UtilityService.defaultMarkup : null,
      outsideProcessSpecifications: []
    };

    if (station.isPainting) {
      newStep.runTime = 1;
      newStep.runPrice = 0.065;
      newStep.paintCost = 0.125;
      newStep.paintMinPrice = 350;
    }
    return newStep;
  }

}
