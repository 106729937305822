import { AfterViewInit, Component, ElementRef, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild, forwardRef, EventEmitter } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, NgModel } from '@angular/forms';
import { MatAutocomplete, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { BehaviorSubject, Observable, ReplaySubject, Subject, Subscription, combineLatest, isObservable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

function escapeRegExp(string) {
  return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
}

@Component({
  selector: 'clientside-search',
  templateUrl: './clientside-search.component.html',
  styleUrls: ['./clientside-search.component.less'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ClientsideSearchComponent),
      multi: true
    }
  ]
})
export class ClientsideSearchComponent<T> implements AfterViewInit, ControlValueAccessor {

  constructor() { }

  @Input() dense: boolean = false;
  @Input() placeholder: string;
  @Input() label: string;
  @Input() readonly = false;
  @Input() required: boolean = false;
  @Input() disabled: boolean = false;
  @Input() noItemsText: string;
  @Input() item: T;
  @Input() reqSel: boolean;
  @Input() nullOption: boolean;
  @Input() canAdd: boolean;
  @Input() fieldClass: string;

  private subscription: Subscription;
  @Input() set items(v: T[] | Observable<T[]>) {
    if (this.subscription) this.subscription.unsubscribe();
    if (isObservable(v)) {
      this.subscription = v.subscribe(this.items$);
    } else {
      this.items$.next(v);
    }
  }
  protected readonly items$ = new ReplaySubject<T[]>(1);
  @Input() getSearchField: (item: T) => string = (i) => i as unknown as string ?? '';

  public searchText = '';
  @ViewChild('searchModel') searchModel: NgModel;
  
  public filteredItems$: Observable<T[]>

  private currentItems$ = new BehaviorSubject<T[]>([]);
  public addValid: Observable<boolean>;
  @ViewChild('searchField') searchField: ElementRef<HTMLInputElement>;
  @Input() clearAddedOnChange = true;
  ngAfterViewInit(): void {
    this.items$.subscribe(this.currentItems$);
    this.filteredItems$ = combineLatest([
      combineLatest([
        this.items$,
        this.added$
      ]).pipe(
        map(([items, added]) => {
          if (added) return [...items, added];
          else return items;
        })
      ),
      this.searchModel.valueChanges.pipe(startWith('')),
    ]).pipe(
      map(([results, filter]: [T[], string | any]) => {
        if (!filter || typeof filter !== 'string') return results;
        if (!results) return [];
        else return results.filter(i => {
          const field = this.getSearchField(i).toLowerCase();
          const keywords = filter.toLowerCase().split(' ');
          return keywords.every(kw => field.includes(kw));
        })
      })
    );

    this.items$.subscribe(() => {
      if (this.clearAddedOnChange) this.added$.next(null);
    })

    this.addValid = combineLatest([this.filteredItems$, this.searchModel.valueChanges])
      .pipe(
        map(([items, search]) => {
          return typeof search === 'string' && !!search.trim() && (!this.value || search.trim() !== this.getSearchField(this.value)) && !(
            items?.length === 1 && (this.getSearchField(items?.[0]).toLowerCase() === search.toLowerCase())
          );
        })
    )
  }

  public value: T | null = null;
  onChange = (_: T) => {};
  onTouched = (_: T) => {};

  writeValue(value: T) {
    this.value = value;
    this.onChange && this.onChange(value);
    this.searchText = this.getSearchField(value) ?? '';
  }

  public registerOnChange(fn: (value: T) => void): void {
    this.onChange = fn;
  }
  public registerOnTouched(fn: (value: T) => void): void {
    this.onTouched = fn;
  }

  public filterSearchText(item: any) {
    if (!item) return '';
    if (typeof item === 'string') return item;
    else {
      try {
        return this.getSearchField(item);
      } catch (e) {
        return '';
      }
    }
  }

  public getHighlitName(filter: string, str: string): string {
    if (!filter) { return str; }
    const split = filter.split(' ').sort((a, b) => b.length - a.length);
    let out = str;
    for (const keyword of split) {
      let kw = keyword.trim();
      if (!kw) continue;
      var re = new RegExp(escapeRegExp(keyword), 'gi');
      out = out.replace(re, "␚$&␚");
    }
    out = out.replace(/␚(.+?)␚/gi, "<b>$1</b>");
    return out;
  }

  public onBlur(event: FocusEvent, auto: MatAutocomplete) {
    const rt = event.relatedTarget as HTMLElement;
    if (rt && rt.classList?.contains('mat-option')) {
      return;
    }
    const value = this.searchText;

    let matchingOptions = (this.currentItems$.value ?? []).concat(this.added$.value ? this.added$.value : []).find(
      (option) => this.getSearchField(option) == value
    );
    if (!matchingOptions) {
      if (this.reqSel) {
        this.writeValue(null);
        this.searchText = '';
      }
    }
  }


  @Input() addConverter: (i: string) => T = (i) => (i as unknown as T);
  private added$ = new BehaviorSubject<T | null>(null);
  @Output() added = new EventEmitter<T>();
  public onAdd(item: T) {
    this.added$.next(item);
    this.added.emit(item);
  }

  public selected(event: MatAutocompleteSelectedEvent) {
    const { type, value } = event?.option?.value;
    this.writeValue(value);
    this.searchField.nativeElement.value = this.getSearchField(value);
    if (type === 'new') this.onAdd(value);
  }


}
