import { AfterViewInit, Component, EventEmitter, Input, OnChanges, OnInit, Output, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatTable } from '@angular/material/table';
import { UtilityService } from '../../../common/services/utility.service';
import { Order, QuoteLineItem } from '../../resources/order';
import { OrderService } from '../../services/order.service';
import { CustomerPurchaseOrder, CustomerPurchaseOrderLineItem, CustomerPurchaseOrderType } from '../../resources/customer-po';


type QuoteLineItemMod = (QuoteLineItem & {
  quantityOverride: boolean,
  quantityOverrideNote: null | string,
  originalLineItemId?: string
})

export type POLineItem = (QuoteLineItemMod & {
  poLineItemGuid: string,
  lineNumber: string,
  quantity: number,
  dueDate: Date,
  dueDateOverride: boolean,
  exceptions: {
    estimatingSheet: boolean
    pricingLotQuantities: boolean
    documents: boolean
    estimatedLeadTimeCorrect: boolean
    longLeadItems: boolean
    longLeadMaterial: boolean
    longLeadHardware: boolean
  },
  releaseForPoLineItemId?: string,
})

@Component({
  selector: 'po-line-item-builder',
  templateUrl: './po-line-item-builder.component.html',
  styleUrls: ['./po-line-item-builder.component.less']
})
export class PoLineItemBuilderComponent implements OnChanges, AfterViewInit {

  @Input() record: Order;
  @Input() poType: CustomerPurchaseOrderType;
  @Input() releaseForPO: CustomerPurchaseOrder = null;

  constructor(private orderSvc: OrderService) { }

  public isLoading = false;
  public quoteLineItems: QuoteLineItemMod[] = [];
  @Input() poLineItems: POLineItem[] = []
  @Output() poLineItemsChange = new EventEmitter<any[]>();
  @ViewChild('quoteLineItemsTable', { static: true }) quoteLineItemsTable: MatTable<any>;
  @ViewChild('poLineItemsForm', { static: true }) poLineItemsForm: NgForm;

  public printName(i: QuoteLineItem) {
    if (!i.station) return `${i.product.partNumber} Rev. ${i.product.revision}`;
    else return `${i.station.name} for ${i.product.partNumber} Rev. ${i.product.revision}`
  }

  public addPOLineItem(i: QuoteLineItemMod) {
    let quantity: number;
    if (this.isLTARelease) {
      quantity = 0;
    } else {
      quantity = i.quantity
    }
    const newItem: POLineItem = {
      poLineItemGuid: UtilityService.newGuid(),
      lineNumber: '',
      dueDate: null,
      dueDateOverride: false,
      exceptions: {
        estimatingSheet: false,
        pricingLotQuantities: false,
        documents: false,
        estimatedLeadTimeCorrect: false,
        longLeadItems: false,
        longLeadMaterial: false,
        longLeadHardware: false
      },
      releaseForPoLineItemId: this.poType === CustomerPurchaseOrderType.RELEASE ? i.originalLineItemId : null,
      ...i,
      quantity
    };
    this.poLineItems = [...this.poLineItems, newItem];
    this.poLineItemsChange.emit(this.poLineItems);
    console.log(this.quoteLineItems);
    console.log(this.poLineItems);
  }

  public deletePOLineItem(i: POLineItem) {
    this.poLineItems = this.poLineItems.filter(x => x.poLineItemGuid !== i.poLineItemGuid);
    this.poLineItemsChange.emit(this.poLineItems);
  }

  public getOrderedQuantity(i: QuoteLineItemMod) {
    return this.poLineItems.filter(x => x.quoteLineItemId == i.quoteLineItemId && typeof x.quantity == 'number').reduce((acc, i) => {
      return acc + i.quantity;
    }, 0)
  }

  public canAdd(i: QuoteLineItemMod) {
    if (!i.station) return true;
    const oq = this.getOrderedQuantity(i);
    return (oq < i.quantity);
  }

  public get isLTARelease() {
    return this.poType === CustomerPurchaseOrderType.RELEASE && this.releaseForPO?.type === CustomerPurchaseOrderType.LTA;
  }

  public getOrderedLTAQuantity(i: QuoteLineItemMod) {
    if (!i.originalLineItemId || !this.releaseForPO) return null;
    const previous = this.releaseForPO.releases.flatMap(release => release.lineItems).filter(li => li.originalLineItemId === i.originalLineItemId)
      .reduce((acc, x) => acc + x.quantity, 0);
    const current = this.poLineItems.filter(x => x.releaseForPoLineItemId == i.originalLineItemId).reduce((acc, i) => {
      return acc + i.quantity;
    }, 0)
    return previous + current;

  }

  public quantityMismatch(i: QuoteLineItemMod) {
    // The quantity checking rules for blanket orders seem to be the same as for standard POs - match the original exactly
    if (this.poType == CustomerPurchaseOrderType.RELEASE && this.releaseForPO?.type == CustomerPurchaseOrderType.BLANKET) {
      const ordered = this.getOrderedLTAQuantity(i);
      return ordered !== 0 && ordered !== i.quantity;
    }
    // But for LTA orders, any quantity is OK as long as there's enough "left" based on previous releases
    else if (this.isLTARelease) {
      return this.getOrderedLTAQuantity(i) > i.quantity;
    } else {
      const ordered = this.getOrderedQuantity(i);
      return ordered !== 0 && ordered !== i.quantity;
    }
  }


  public overrideQuantityWarning(i: QuoteLineItemMod) {
    i.quantityOverride = true;
  }

  public getEarliestPossibleDeliveryTime(i: POLineItem) {
    const date = new Date();
    date.setDate(date.getDate() + i.leadTimeDays);
    return date;
  }

  public dateIsSafe(i: POLineItem, date: Date): boolean {
    const earliest = this.getEarliestPossibleDeliveryTime(i);
    earliest.setHours(0);
    earliest.setMinutes(0);
    earliest.setSeconds(0);
    earliest.setMilliseconds(0);
    const d = new Date(date);
    d.setHours(0);
    d.setMinutes(0);
    d.setSeconds(0);
    d.setMilliseconds(0);
    return this.getEarliestPossibleDeliveryTime(i) <= d;
  }

  public dueDateMismatch(i: POLineItem) {
    return !i.station && i.dueDate && !this.dateIsSafe(i, i.dueDate);
  }

  public get hasWarnings() {
    // check for non-overriden quantity warnings
    if (this.quoteLineItems.some(q => !q.quantityOverride && this.quantityMismatch(q))) {
      return true;
    }
    // then check for non-overriden due date warnings
    if (this.poLineItems.some(i => !i.dueDateOverride && this.dueDateMismatch(i))) {
      return true;
    }
  }

  public get hasErrors() {
    // check for warnings first
    if (this.hasWarnings) return true;
    // then check if any po line item has invalid data
    return this.poLineItems.length === 0 || this.poLineItems.some(i =>
      !i.quantity || i.quantity < 1 ||
      ((this.poType !== CustomerPurchaseOrderType.BLANKET && this.poType !== CustomerPurchaseOrderType.LTA) && !i.dueDate) ||
      (!i.lineNumber || !(i.lineNumber.trim()))
    )
  }

  ngOnChanges() {
    const output = [];
    // Determine line items
    let lineItems: QuoteLineItemMod[];
    if (this.poType === 3) {
      if (!this.releaseForPO) lineItems = [];
      // Hide standalone items since releases are only for physical items
      else {
        lineItems = this.releaseForPO.lineItems
          .filter(poli => !!poli.quoteLineItem && !poli.stationId)
          .map(poli => ({
            ...poli.quoteLineItem,
            quantity: poli.quantity,
            lineItemNumber: poli.lineItemNumber,
            quantityOverride: false,
            quantityOverrideNote: null,
            originalLineItemId: poli.customerPurchaseOrderLineItemId
          }));
      }
    } else {
      lineItems = this.record.lineItems.map(i => ({
        ...i,
        quantityOverride: false,
        quantityOverrideNote: null
      }));
    }
    for (const lineItem of lineItems) {
      if (lineItem.station) {
        // check if we already have a line item with the same standalone station name & product
        if (output.length > 0 && output.some(o => o.station && o.station.stationId === lineItem.station.stationId && o.product.productId === lineItem.product.productId)) {
          continue;
        }
      }
      output.push({
        ...lineItem
      });
    }
    // Sort all stations to the bottom first, then sort by product, so all standalone steps are under their products in order.
    this.quoteLineItems = output.sort((a, b) => {
      return (a.station ? 1 : 0) - (b.station ? 1 : 0)
    }).sort((a, b) => { return a.product.partNumber.localeCompare(b.product.partNumber) });
    if (this.quoteLineItemsTable) this.quoteLineItemsTable.renderRows();

  }

  ngAfterViewInit(): void {
    this.poLineItemsForm.valueChanges.subscribe(() => this.poLineItemsChange.emit(this.poLineItems));
  }

  public get displayedQuoteLineItemColumns() {
    return [
      "description",
      "unitPrice",
      "extPrice",
      "leadTime",
      this.isLTARelease ? null : "quoted",
      this.poType === CustomerPurchaseOrderType.RELEASE ? "released" : "ordered",
      "error",
      "add"
    ].filter(i => !!i);
  }
  public get displayedPOLineItemColumns() {
    return [
      "lineNumber",
      "quantity",
      "description",
      "unitPrice",
      "extPrice",
      (this.poType === CustomerPurchaseOrderType.BLANKET || this.poType === CustomerPurchaseOrderType.LTA) ? null : "dueDate",
      "error",
      "delete"
    ].filter(i => !!i);
  };

}
