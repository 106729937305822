import { Component, Output, EventEmitter, Input, ViewChild, OnChanges, SimpleChanges, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, NgModel, UntypedFormControl } from '@angular/forms';
import { MatDatepicker } from '@angular/material/datepicker';
import { first } from 'rxjs/operators';

@Component({
  selector: 'date-picker',
  templateUrl: './date-picker.component.html',
  styleUrls: ['./date-picker.component.less'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DatePickerComponent),
      multi: true
    }
  ]
})
export class DatePickerComponent implements OnChanges, ControlValueAccessor {
  @Output() dateChange: EventEmitter<Date> = new EventEmitter<Date>();
  @Input() date?: Date = null;
  @Input() readonly: boolean = false;
  @Input() editable: boolean = false;
  @Input() placeholder: string = "Date";
  @Input() required: boolean = false;
  @Input() min?: Date = null;
  @Input() max?: Date = null;
  @Input() appearance?: 'default' | 'outline' = 'default';
  @Input() dense = false;
  @Input() error = false;
  @ViewChild('dateInput') dateInputField: NgModel;

  @ViewChild('datepicker') datePicker: MatDatepicker<any>;
  constructor() { }

  public get invalid(): boolean {
    return this.required ? (this.dateInputField && this.dateInputField.invalid) : false;
  }

  public get control(): UntypedFormControl {
    if (this.required) {
      return (this.dateInputField && this.dateInputField.control);
    }
  }

  public notifyChange(): void {
    if (this.date && this.date.getFullYear() == 2001) this.date.setFullYear(new Date().getFullYear());
    this.dateChange.emit(this.date);
    if (this.onChange) this.onChange(this.date);
  }

  public openDatepicker() {
    this.datePicker.open();
    this.datePicker.closedStream.pipe(first()).subscribe(_ => this.control.markAsTouched())
  }

  public markAsTouched() {
    this.control.markAsTouched();
  }

  writeValue(item: Date): void {
    this.date = item ? new Date(item) : item;
  }

  private onChange: (value: Date) => void;
  private onTouched: () => void;
  public registerOnChange(fn: (value: Date) => void): void {
    this.onChange = fn;
  }
  public registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  ngOnChanges(_: SimpleChanges): void {
    ;//Empty
  }

  public setToToday() {
    this.date = new Date();
    this.notifyChange();
  }
}
