<loading *ngIf="saving">Saving...</loading>
<loading *ngIf="loading"></loading>

<mat-sidenav-container>
  <mat-sidenav-content *ngIf="qualityTicket">
    <quality-assign
      class="my-1"
      [record]="qualityTicket"
      (startLoading)="this.loading = true;"
      (finishLoading)="this.getDetail();"
    ></quality-assign>
    <rma-sales-detail [id]="qualityTicket.rmaTicketID" [isQuality]="true" (rejected)="reject()" (approved)="approve()"></rma-sales-detail>
  </mat-sidenav-content>
</mat-sidenav-container>

<ng-template let-data #rejectReasonTemplate>
  <h2 mat-dialog-title>Rejecting RMA Request</h2>
<mat-dialog-content class="mat-typography">
    <p>Please explain why this RMA request was rejected.</p>
    <p>
      <textarea class="form-control form-control-sm" [(ngModel)]="data.reason" placeholder="Rejection Reason"></textarea>
    </p>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button class="btn btn-default btn-info btn-sm mr-3" [mat-dialog-close]="null">Cancel</button>
    <button class="btn btn-default btn-danger btn-sm mr-3" [disabled]="!data.reason" [mat-dialog-close]="data.reason">Reject</button>
  </mat-dialog-actions>
</ng-template>