import { UtilityService } from '../services/utility.service';

export class Message {
  messageId: string;
  messageText: string;
  messageType: number = MessageType.GENERAL;
  messagePayload: any = null;

  constructor() {
    this.messageId = UtilityService.newGuid();
  }
}

export class MessageType {
  static ERROR: number = 1;
  static INFO: number = 2;
  static SUCCESS: number = 3;
  static GENERAL: number = 4;
}
