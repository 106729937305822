import { Component, Input, OnInit, ViewChildren, QueryList, EventEmitter, Output } from '@angular/core';
import { Order, OrderStatus } from '../../resources/order';
import { OrderService } from '../../services/order.service';
import { QuantityTableComponent } from '../quantity-table/quantity-table.component';

@Component({
  selector: 'quote-info',
  templateUrl: './quote-info.component.html',
  styleUrls: ['./quote-info.component.less']
})
export class QuoteInfoComponent implements OnInit {
  @Input() record: Order;
  @Input() editing: boolean = false;
  public loading = false;
  public relatedEstimate: Order = null;
  @ViewChildren('quantityTable') components: QueryList<QuantityTableComponent>;
  @Output() acceptance = new EventEmitter<void>();

  constructor(private orderService: OrderService) { }

  public beginAcceptance() {
    this.acceptance.emit();
  }

  public save(): void {
    if (this.components) {
      this.components.toArray().forEach(p => p.savePricing());
    }
  }

  public updateProducts() {
    if (this.components) {
      this.components.toArray().forEach(p => p.doUpdate());
    }
  }

  private getEstimateDetail(estimateNumber: string): void {
    if (estimateNumber != null)
      this.orderService.getByOrderNumber(estimateNumber).subscribe(result => this.relatedEstimate = result);
  }

  public isEstimateReady(status: number): boolean {
    return status != OrderStatus.IN_PROCESS || status == OrderStatus.AWAITING_REVIEW;
  }

  public getStatusColorClass(status: number): string {
    return OrderStatus.getStatusColorClass(status);
  }

  public getStatusText(status: number): string {
    return OrderStatus.getStatusText(status);
  }

  public getDownloadableQuote() {
    this.loading = true;
    this.orderService.getQuoteFromLineItems(this.record).subscribe(doc => {
      this.loading = false;
      window.open(`/document/${doc.documentId}?inline=true`, "_blank")
    })
  }
  public getDownloadableQuoteOld() {
    this.loading = true;
    this.orderService.getQuote(this.record).subscribe(doc => {
      this.loading = false;
      window.open(`/document/${doc.documentId}?inline=true`, "_blank")
    })
  }

  public get allQuantitiesSelected(): boolean {
    return this.components && this.components.toArray().every(c => c.allQuantitiesSelected)
  }

  ngOnInit(): void {
    this.getEstimateDetail(this.record.estimateNumber);
  }
}
