import { Component, Input, Output, OnInit, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { UserService } from '../../../common/services/user.service';
import { User } from '../../../common/resources/user';

@Component({
  selector: 'salesperson-select',
  templateUrl: './salesperson-select.component.html',
  styleUrls: ['./salesperson-select.component.less']
})
export class SalespersonSelectComponent implements OnInit {

  public salesPeople: User[];
  @Input() salesPerson: User;
  @Output() salesPersonChange: EventEmitter<User> = new EventEmitter<User>();
  @Input() editable: boolean;
  @ViewChild('salespersonInputText', { static: true }) inputTxt: ElementRef;
  @ViewChild('autoCompleteTrigger', { static: true }) autoCompleteTrigger: MatAutocompleteTrigger;
  public searching: boolean = false;

  constructor(private usrService: UserService) { }

  getSalesPersonList(event: KeyboardEvent) {
    this.searching = true;
    this.usrService.getSalesPeople(this.inputTxt.nativeElement.value).subscribe(result => {
      this.searching = false;
      this.salesPeople = result.results;
    });
  }

  public testSelection(_: any): void {
    if (!this.salesPerson) {
      this.inputTxt.nativeElement.value = "";
      return;
    }
    this.inputTxt.nativeElement.value = this.salesPerson.fullName;
  }

  toggleDropdown(): void {
    event.stopImmediatePropagation();
    if (this.autoCompleteTrigger.panelOpen) {
      this.autoCompleteTrigger.closePanel();
    }
    else {
      this.getSalesPersonList(null);
      this.autoCompleteTrigger.openPanel();
    }
  }

  setSalesPerson(salesPerson: any) {
    this.salesPerson = salesPerson;
    if (salesPerson == null)
      return;

    this.salesPersonChange.emit(this.salesPerson);
  }

  ngOnInit(): void {
  }
}
