import { Component, Input, OnInit } from '@angular/core';
import { Station } from '../../../../order/resources/station';
import { WorkflowStep, WorkflowStepPlanningStatus } from '../../../../order/resources/workflow';
import { StationService } from '../../../../order/services/station.service';
import { WorkOrderOverview } from '../../../../planning/resources/work-order';

type StepStatus = 'EXECUTED' | 'IN_PROGRESS' | 'SCHEDULED' | 'NEEDS_SCHEDULING';

@Component({
  selector: 'oor-production',
  templateUrl: './oor-production.component.html',
  styleUrls: ['./oor-production.component.less']
})
export class OorProductionComponent implements OnInit {

  @Input() record: WorkOrderOverview;

  public get workflow() {
    return (this.record.workflowSteps ?? []).sort((a, b) => a.stepOrder - b.stepOrder);
  }

  public getStation(item: WorkflowStep): Station {
    if (this.stationList == null || item == null || item.stationId == null)
      return null;

    return this.stationList.find(r => r.stationId == item.stationId);
  }

  public getName(step: WorkflowStep) {
    return this.getStation(step)?.name ?? 'Unknown Station';
  }

  constructor(private stationService: StationService) { }

  public stationList: Station[]
  ngOnInit(): void {
    if (this.stationService.loaded) {
      this.stationList = this.stationService.stationList;
    }
    else {
      this.stationService.stationsLoaded.subscribe(
        _ => this.stationList = this.stationService.stationList
      );
    }
  }

  public getSchedulingTicket(step: WorkflowStep) {
    return step.schedulingTicket ?? null;
  }

  public isScheduled(step: WorkflowStep) {
    const tkt = this.getSchedulingTicket(step);
    if (!tkt) return false;
    else return tkt.schedulingStatus === 1;
  }

  public isExecuted(step: WorkflowStep) {
    if (!step.machineAssignments || step.machineAssignments.length === 0) return false;
    return step.machineAssignments.every(x => !!x.actualEnd);
  }

  public getStatus(step: WorkflowStep): StepStatus {
    if (this.isExecuted(step)) return 'EXECUTED';
    else if (this.isScheduled(step)) {
      if (step.machineAssignments.some(x => !!x.actualEnd)) return 'IN_PROGRESS';
      else return 'SCHEDULED';
    }
    else return 'NEEDS_SCHEDULING';
  }

  public getDateExecuted(step: WorkflowStep) {
    if (!this.isExecuted(step)) return null;
    return Math.max(...(step.machineAssignments?.filter(a => !!a.actualEnd).map(a => (new Date(a.actualEnd)).getTime()) ?? []))
  }

  public getStatusColorBackground(step: WorkflowStep) {
    switch (this.getStatus(step)) {
      case 'EXECUTED':
        return 'bg-success text-white'
      case 'IN_PROGRESS':
        return 'bg-warning text-dark'
      case 'SCHEDULED':
        return 'bg-info text-white'
      case 'NEEDS_SCHEDULING':
        return 'bg-danger text-white'
    }
  }

  public getStatusText(step: WorkflowStep) {
    switch (this.getStatus(step)) {
      case 'EXECUTED':
        return 'Executed'
      case 'IN_PROGRESS':
        return 'In Progress'
      case 'SCHEDULED':
        return 'Scheduled'
      case 'NEEDS_SCHEDULING':
        return 'Needs Scheduling'
    }
  }

  public getStatusIcon(step: WorkflowStep) {
    switch (this.getStatus(step)) {
      case 'EXECUTED':
        return 'done'
      case 'IN_PROGRESS':
        return 'more_horiz'
      case 'SCHEDULED':
        return 'schedule'
      case 'NEEDS_SCHEDULING':
        return 'error'
    }
  }

}
