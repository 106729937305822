import { Component, EventEmitter, Output } from '@angular/core';
import { SearchList } from '../../../common/components/search-list/search-list';
import { MachineAssignment } from '../../../planning/resources/machine-assignment';
import { FloorAlertService } from '../../services/floor-alert.service';
import { FloorService } from '../../services/floor.service';
import { FloorBuilding } from '../../resources/building';
import { OrderStatus } from '../../../order/resources/order';
import { StationService } from '../../../order/services/station.service';
import { Station } from '../../../order/resources/station';

@Component({
    selector: 'week-status-list',
    templateUrl: './week-status-list.component.html',
    styleUrls: ['./week-status-list.component.less']
})
export class WeekStatusListComponent extends SearchList<MachineAssignment> {
  @Output() selected: EventEmitter<MachineAssignment> = new EventEmitter<MachineAssignment>();

  private fbList: FloorBuilding[];

  constructor(private service: FloorAlertService, private floorSvc: FloorService, private stationSvc: StationService) {
    super('WeekStatusListComponent');
  }

  protected search(): void {
    this.results = null;
    this.service.getThisWeeksData(this.pageNumber).subscribe(results => {
      this.searchResults = results;
      this.results = results.results
    });
  }

  public onSelect(record: MachineAssignment): void {
    this.selected.emit(record);
  }

  public getBuildingById(id: string): FloorBuilding {
    return this.fbList.find(d => d.floorBuildingId == id);
  }

  public getStatusText(status: number): string {
    return OrderStatus.getStatusText(status);
  }

  public getStatusColorClass(status: number): string {
    return OrderStatus.getStatusColorClass(status);
  }

  public getStation(stationId: string): Station {
    if (!this.stationSvc.loaded) return null;

    return this.stationSvc.stationList.find(s => s.stationId == stationId);
  }

  ngOnInit(): void {
    super.ngOnInit();

    this.floorSvc.getAllFloorBuildings().subscribe(r => {
      this.fbList = r.results;
    });
  }
}
