<div>
  <a *ngIf="record && showEditor==null" class="position-fixed fixed-bottom btn btn-badge bg-danger has-icon" title="Close" (click)="close()"><i class="fas fa-times"></i></a>

  <loading *ngIf="!record"></loading>
  <loading *ngIf="saving" content="Saving..."></loading>

  <div class="p-0 container-fluid">

    <mat-sidenav-container class="p-0 m-0">
      <mat-sidenav disableClose (closedStart)="closeSideNav()" #insetnav mode="over" position="end">
        <machine-detail [date]="detailDataDate" *ngIf="showEditor=='machine'" [machineId]="selectedMachine?.machineId" [sidenav]="insetnav"></machine-detail>
        <machine-assignment-detail [record]="selectedAssignment" *ngIf="showEditor=='assignment'" [sidenav]="insetnav"></machine-assignment-detail>
      </mat-sidenav>
      <mat-sidenav-content>
        <div *ngIf="record">
          <div class="row">
            <div class="col-6 bg-secondary pt-2 pl-4">
              <div *ngIf="topDownView && canEditLayout()">
                <button class="btn btn-sm btn-light" (click)="toggleEdit()" *ngIf="!editing">
                  <i class="fas fa-edit fa-fw"></i>
                  Edit
                </button>
                <button class="btn btn-sm btn-info mr-4" (click)="addMachine()" *ngIf="editing">
                  <i class="fas fa-plus fa-fw"></i>
                  Add
                </button>
                <button class="btn btn-sm btn-success mr-2" (click)="saveLayout()" *ngIf="editing">
                  <i class="fas fa-edit fa-fw"></i>
                  Save
                </button>
                <button class="btn btn-sm btn-danger" (click)="cancelEdit()" *ngIf="editing">
                  <i class="fas fa-times fa-fw"></i>
                  Cancel
                </button>
              </div>
            </div>
            <div class="col-6 text-right bg-secondary">
              <button class="btn btn-sm btn-light" (click)="switchView()">
                <i class="fas fa-fw fa-street-view"></i>Switch View
              </button>
              <div class="btn-group m-2" *ngIf="building">
                <a class="btn btn-light btn-sm dropdown-toggle" href="#" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  {{record?.name || '--'}}
                </a>
                <div class="dropdown-menu" aria-labelledby="dropdownMenuLink" *ngIf="department!=null; else loadingDepartments">
                  <a *ngFor="let b of department.floorBuildings" [ngClass]="{'active':(b.floorBuildingId==record.floorBuildingId)}" href="javascript:void(0)" class="dropdown-item" (click)="switchDepartment(b)">{{b?.name || '--'}}</a>
                </div>
                <ng-template loadingDepartments>
                  <p class="text-center text-muted">
                    <i class="fas fa-circle-notch fa-spin fa-fw text-primary"></i>
                    Loading...
                  </p>
                </ng-template>
              </div>
            </div>
          </div>

          <div class="container-fluid mt-3">
            <div class="row" *ngIf="topDownView; else summaryView">
              <div class="col-9">
                <div class="floor-layout" (click)="clearMachineSelection()">
                  <h1 class="no-ui text-muted display-3" *ngIf="record.machines.length==0">- No Machines -</h1>
                  <floor-layout-block [ngClass]="{'selected':(machine==selectedMachine)}" (click)="selectMachine(machine)" [block]="machine" [editable]="editing" *ngFor="let machine of record.machines"></floor-layout-block>
                </div>
              </div>
              <div class="col-3 position-relative">
                <div *ngIf="!selectedMachine">
                  <h5 class="w-100 text-center p-2 bg-secondary">Alerts</h5>
                  <div class="p-1 small">
                    <filtered-alert-list (selected)="getAlertDetail($event)" [buildingId]="record?.floorBuildingId" [departmentId]="record?.departmentId"></filtered-alert-list>
                  </div>
                </div>
                <div *ngIf="selectedMachine && !editing">
                  <h5 class="w-100 text-center p-2 bg-secondary">{{selectedMachine.name}}</h5>
                  <h6 class="text-center text-primary">{{showHoursSum(selectedMachine.machineId) | number:'1.0-2'}} Scheduled Hours</h6>
                  <machine-assignment-list #machineAssignments (selected)="showAssignment($event)" [machineId]="selectedMachine.machineId"></machine-assignment-list>
                  <button class="openBtn btn btn-sm btn-primary position-absolute mb-4" (click)="openMachineDetails()">
                    <i class="fas fa-search fa-fw"></i>
                    Detail View
                  </button>
                </div>
                <div *ngIf="selectedMachine && editing">
                  <machine-data-form [(machine)]="selectedMachine"></machine-data-form>
                  <button class="removeBtn btn btn-sm btn-danger position-absolute mb-4" (click)="removeMachine()">
                    <i class="fas fa-times fa-fw"></i>
                    Remove
                  </button>
                  <button class="openBtn btn btn-sm btn-primary position-absolute mb-4" (click)="saveMachine()">
                    <i class="fas fa-save fa-fw"></i>
                    Save
                  </button>
                </div>
              </div>
            </div>
            <ng-template #summaryView>
              <div class="row">
                <div class="col-3 pl-0">
                  <h5 class="w-100 text-center p-2 bg-secondary">Building '{{record.name}}'</h5>
                  <div class="container-fluid row">
                    <div class="col-5">
                      Supervisor:
                    </div>
                    <div class="col-6">
                      {{record.manager ? record.manager.fullName : '-- None Assigned --'}}
                    </div>
                  </div>
                  <div class="container-fluid row">
                    <div class="col-5">
                      Status:
                    </div>
                    <div class="col-6" [ngClass]="{'text-success': (record.alertCount == 0), 'text-warning': (record.alertCount > 0)}">
                      {{(record.alertCount > 0) ? "IMPACTED" : "OPERATIONAL"}}
                    </div>
                  </div>
                  <div class="container-fluid row">
                    <div class="col-5">
                      Machines:
                    </div>
                    <div class="col-6">
                      {{record.machines.length - record.alertCount}} / {{record.machines.length}}
                    </div>
                  </div>
                  <div class="container-fluid row">
                    <div class="col-5">
                      M. Hours:
                    </div>
                    <div class="col-6">
                      <span *ngIf="hoursData">
                        {{hoursData.machineHours | number:'1.0-1'}}
                      </span>
                    </div>
                  </div>
                  <div class="container-fluid row">
                    <div class="col-5">
                      Op. Hours:
                    </div>
                    <div class="col-6">
                      <span *ngIf="hoursData">
                        {{hoursData.operatorHours | number:'1.0-1'}}
                      </span>
                    </div>
                  </div>
                </div>
                <div class="col-9 pr-0">
                  <h5 class="w-100 text-center p-2 bg-secondary">
                    <span *ngIf="isToday(detailDataDate)">Today - </span>{{detailDataDate | date:'shortDate'}}
                    <button *ngIf="!isToday(detailDataDate)" class="ml-4 btn btn-sm btn-secondary text-secondary" (click)="returnToToday()">
                      <i class="far fa-fw fa-calendar-alt"></i>
                      Return to Today
                    </button>
                  </h5>
                  <department-today-schedule *ngIf="department" [machines]="record.machines" #todayData (onNewAssignment)="showAssignmentDetails($event)" (onClick)="select($event)" [floorBuildingId]="building.floorBuildingId" [date]="detailDataDate"></department-today-schedule>
                </div>
              </div>
              <div class="row mt-3">
                <h5 class="w-100 text-center p-2 bg-secondary">
                  Future Schedule
                </h5>
                <div class="col-12">
                  <department-future-schedule #futureData (onClick)="switchDate($event)" [machineCount]="record.machines.length" [floorBuildingId]="building.floorBuildingId"></department-future-schedule>
                </div>
              </div>
            </ng-template>
          </div>
        </div>
      </mat-sidenav-content>
    </mat-sidenav-container>
  </div>
</div>
