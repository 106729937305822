<div>
  <p *ngIf="purchasedItems==null || purchasedItems.length==0" class="text-muted text-center font-italic p-3">-- No
    Purchased Items --</p>
  <div class="item small p-2" *ngFor="let item of purchasedItems" (click)="select(item)"
    [ngClass]="{'selected':selectedItem==item, 'text-danger':hasComparisonData(item)}">
    <ng-container *ngIf="item?.purchasedItem">
      {{item?.purchasedItem?.description}} (#{{item?.quantity||0}})
    </ng-container>
    <ng-container *ngIf="!item?.purchasedItem">
      <span class="text-muted font-italic">-- NO ITEM SELECTED --</span>
    </ng-container>
    <span *ngIf="item?.isNonRecurring">(One Time<span *ngIf="item?.isAmortized">, Amortized</span>)</span>
    <button (click)="delete(item)" title="Delete" *ngIf="editable" class="btn btn-danger btn-sm"><i
        class="fas fa-fw fa-times"></i></button>
  </div>
  <div class="text-center mt-3" *ngIf="editable">
    <button class="btn btn-primary btn-sm" (click)="addItem()">
      <i class="fas fa-fw fa-lg fa-plus"></i> Add Purchased Item
    </button>
  </div>
</div>
