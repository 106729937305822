import { Injectable, Inject, Output, EventEmitter, Directive } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { MessageService } from '../../common/services/message.service';
import { catchError, flatMap, mergeMap, tap } from 'rxjs/operators';
import { ErrorHandlerService } from '../../common/services/errorHandler.service'
import { SearchResult } from '../../common/resources/search-result';
import { httpService } from '../../common/services/http.service';
import { Department, Building, FloorBuilding, FloorMachine } from '../resources/building';
import { UtilityService } from '../../common/services/utility.service';
import { MessageType } from '../../common/resources/message';
import { MachineAssignment } from '../../planning/resources/machine-assignment';
import { Address } from '../../common/resources/address';
import { ToolCribTicket } from '../resources/tool-crib-ticket';

@Directive()
@Injectable({
  providedIn: 'root',
})
export class FloorService extends httpService {
  private apiBase: string = 'api/department';
  private apiUrl: string;

  @Output() departmentsUpdated: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(errorHandler: ErrorHandlerService, private messages: MessageService, private http: HttpClient, @Inject('BASE_URL') private baseUrl: string) {
    super(errorHandler, messages);
    this.serviceName = "Floor";

    this.apiUrl = this.baseUrl + this.apiBase;
  }

  public sendMachineStatusUpdateNotifications(record: FloorMachine): Observable<any> {
    return this.http.get<any>(this.baseUrl + 'api/floormachine/sendstatusupdates', { params: { machineId: record.machineId, status: record.status.toString() } }).pipe(
      catchError(this.handleError<any>("Send Machine Status Updates", null))
    );
  }

  public get(id: string): Observable<Department> {
    return this.http.get<Department>(this.apiUrl + `/${id}`).pipe(
      catchError(this.handleError<any>("Get Department by ID", null))
    );
  }

  public remove(building: Department): Observable<any> {
    return this.http.delete<Department>(this.apiUrl + '/' + building.departmentId).pipe(
      tap(_ => {
        this.messages.add("Floor Service: Department Removed Successfully", MessageType.SUCCESS, true);
        this.departmentsUpdated.emit(true);
      }),
      catchError(this.handleError<Department>("Remove Department", null))
    );
  }

  public save(building: Department): Observable<Department> {
    if (building.departmentId == UtilityService.emptyGuid) {
      //New Item
      return this.http.post<Department>(this.apiUrl + '/new', building).pipe(
        tap(_ => {
          this.messages.add("Floor Service: Department Saved Successfully", MessageType.SUCCESS, true);
          this.departmentsUpdated.emit(true);
        }),
        catchError(this.handleError<Department>("Save New Department", building))
      );
    }
    else {
      //Existing Item
      return this.http.post<Department>(this.apiUrl, building).pipe(
        tap(_ => {
          this.messages.add("Floor Service: Department Saved Successfully", MessageType.SUCCESS, true);
          this.departmentsUpdated.emit(true);
        }),
        catchError(this.handleError<Department>("Update Department", building))
      );
    }
  }

  public getUnassignedJobs(department: Department, page: number): Observable<SearchResult<MachineAssignment>> {
    return this.http.get<SearchResult<MachineAssignment>>(this.baseUrl + 'api/floorbuilding/getUnassignedJobs/' + department.departmentId, { params: { pageIndex: page.toString() } }).pipe(
      catchError(this.handleError<any>("Get Unassigned Jobs", null))
    );
  }

  public search(searchString?: string): Observable<SearchResult<Department>> {
    return this.http.get<SearchResult<Department>>(this.apiUrl + '/search', { params: { searchText: searchString || "", pageIndex: '0', orderBy: "name", direction: "asc" } }).pipe(
      catchError(this.handleError<any>("Get Departments Search Results", null))
    );
  }

  public getAllFloorBuildings(search: string = ""): Observable<SearchResult<FloorBuilding>> {
    return this.http.get<SearchResult<FloorBuilding>>(this.baseUrl + 'api/floorbuilding/search', { params: { searchText: search, pageIndex: '0', orderBy: "department", direction: "asc", pageSize: '10000' } }).pipe(
      catchError(this.handleError<any>("Get All Departments Results", null))
    );
  }

  public getMachineList(): Observable<SearchResult<FloorMachine>> {
    return this.http.get<SearchResult<FloorMachine>>(this.baseUrl + 'api/floormachine/search', { params: { searchText: "", pageIndex: '0', orderBy: "department", direction: "asc", pageSize: '10000' } }).pipe(
      catchError(this.handleError<any>("Get Machine List Results", null))
    );
  }

  public getFloorBuilding(id: string): Observable<FloorBuilding> {
    return this.http.get<SearchResult<FloorBuilding>>(this.baseUrl + `api/floorbuilding/${id}`).pipe(
      catchError(this.handleError<any>("Get Department Detail", null))
    );
  }

  public getMachine(id: string): Observable<FloorMachine> {
    return this.http.get<SearchResult<FloorMachine>>(this.baseUrl + `api/floorMachine/${id}`).pipe(
      catchError(this.handleError<any>("Get Machine Detail", null))
    );
  }

  public saveFloorBuildingUI(floorBuildings: FloorBuilding[]): Observable<any> {
    return this.http.post<any>(this.baseUrl + `api/floorbuilding/saveuiall`, floorBuildings).pipe(
      catchError(this.handleError<any>("Save Department UI Data", null))
    );
  }

  public removeMachine(machine: FloorMachine): Observable<any> {
    return this.http.delete<FloorMachine>(this.baseUrl + 'api/floormachine/' + machine.machineId).pipe(
      tap(_ => this.messages.add("Floor Service: Machine Removed Successfully", MessageType.SUCCESS, true)),
      catchError(this.handleError<FloorMachine>("Remove Machine", null))
    );
  }

  public saveMachine(machine: FloorMachine): Observable<FloorMachine> {
    //Prep work - we don't want to insert the Station again...
    machine.operationType = null;
    if (machine.machineId == UtilityService.emptyGuid) {
      //New Item
      return this.http.post<FloorMachine>(this.baseUrl + 'api/floormachine/new', machine).pipe(
        tap(_ => this.messages.add("Floor Service: Machine Saved Successfully", MessageType.SUCCESS, true)),
        catchError(this.handleError<FloorMachine>("Save New Machine", machine))
      );
    }
    else {
      //Existing Item
      return this.http.post<FloorMachine>(this.baseUrl + 'api/floormachine', machine).pipe(
        tap(_ => this.messages.add("Floor Service: Machine Saved Successfully", MessageType.SUCCESS, true)),
        catchError(this.handleError<FloorMachine>("Update Machine", machine))
      );
    }
  }

  public removeDepartment(department: FloorBuilding): Observable<any> {
    return this.http.delete<FloorBuilding>(this.baseUrl + 'api/floorbuilding/' + department.floorBuildingId).pipe(
      tap(_ => this.messages.add("Floor Service: Department Removed Successfully", MessageType.SUCCESS, true)),
      catchError(this.handleError<FloorBuilding>("Remove Department", null))
    );
  }

  public saveDepartment(department: FloorBuilding): Observable<FloorBuilding> {
    if (department.floorBuildingId == UtilityService.emptyGuid) {
      //New Item
      return this.http.post<FloorBuilding>(this.baseUrl + 'api/floorbuilding/new', department).pipe(
        tap(_ => this.messages.add("Floor Service: Department Saved Successfully", MessageType.SUCCESS, true)),
        catchError(this.handleError<FloorBuilding>("Save New Department", department))
      );
    }
    else {
      //Existing Item
      return this.http.post<FloorBuilding>(this.baseUrl + 'api/floorbuilding', department).pipe(
        tap(_ => this.messages.add("Floor Service: Department Saved Successfully", MessageType.SUCCESS, true)),
        catchError(this.handleError<FloorBuilding>("Update Department", department))
      );
    }
  }

  public saveMachines(department: FloorBuilding, machines: FloorMachine[]): Observable<any> {
    machines.forEach(m => m.operationType = null);//Keep the ID, remove the entity to avoid duplicte inserts
    return this.http.post<any>(this.baseUrl + `api/floorbuilding/saveMachineUi?floorBuildingId=${department.floorBuildingId}`, machines).pipe(
      catchError(this.handleError<any>("Save Machine UI Data", null))
    );
  }

  public getNextAssignment(machineId: string): Observable<MachineAssignment> {
    return this.http.get<MachineAssignment>(this.baseUrl + `api/MachineAssignment/next/${machineId}`).pipe(
      catchError(this.handleError<any>("Get Next Machine Assignment Detail", null))
    );
  }

  public getAssignment(assignmentId: string): Observable<MachineAssignment> {
    return this.http.get<MachineAssignment>(this.baseUrl + `api/MachineAssignment/${assignmentId}`).pipe(
      catchError(this.handleError<any>("Get Machine Assignment Detail", null))
    );
  }

  public getOperatorSchedule(operatorId: string, date?: Date): Observable<MachineAssignment[]> {
    date = date || new Date();
    return this.http.get<MachineAssignment[]>(this.baseUrl + `api/MachineAssignment/operatorSchedule/${operatorId}?date=${date.toLocaleDateString()}`).pipe(
      catchError(this.handleError<any>("Get Operator Assignment Detail", null))
    );
  }

  public getMachineSchedule(machineId: string, date?: Date): Observable<MachineAssignment[]> {
    date = date || new Date();
    return this.http.get<MachineAssignment[]>(this.baseUrl + `api/MachineAssignment/machineSchedule/${machineId}?date=${date.toLocaleDateString()}`).pipe(
      catchError(this.handleError<any>("Get Machine Assignment Detail", null))
    );
  }

  public getFloorBuildingTodayData(departmentId: string, date?: Date): Observable<any[]> {
    date = date || new Date();
    return this.http.get<MachineAssignment[]>(this.baseUrl + `api/MachineAssignment/buildingSchedule/${departmentId}?date=${date.toLocaleDateString()}`).pipe(
      catchError(this.handleError<any>("Get Department Schedule Detail", null))
    );
  }

  public getProductAssignments(productId: string, orderId: string): Observable<MachineAssignment[]> {
    return this.http.get<MachineAssignment[]>(this.baseUrl + `api/MachineAssignment/getassignmentsforproduct?productId=${productId}&orderId=${orderId}`).pipe(
      catchError(this.handleError<any>("Get Product Assignments", null))
    );
  }

  public getFloorBuildingFutureData(departmentId: string): Observable<any[]> {
    return this.http.get<MachineAssignment[]>(this.baseUrl + `api/MachineAssignment/buildingPlanning/` + departmentId).pipe(
      catchError(this.handleError<any>("Get Department Schedule Detail", null))
    );
  }

  public getMachineFutureData(machineId: string): Observable<any[]> {
    return this.http.get<MachineAssignment[]>(this.baseUrl + `api/MachineAssignment/machinePlanning/` + machineId).pipe(
      catchError(this.handleError<any>("Get Machine Schedule Detail", null))
    );
  }

  public getBuildingHours(fb: FloorBuilding): Observable<any> {
    return this.http.get<any>(this.baseUrl + `api/FloorBuilding/getBuildingHours/` + fb.floorBuildingId).pipe(
  );
  }

  public updateWorkOrderSchedule(machineId: string, workOrderId: string, oldDate: Date, newDate: Date): Observable<any> {
    return this.http.get<any>(this.baseUrl + `api/FloorBuilding/updateWOSchedule/${workOrderId}?machineId=${machineId}&old=${oldDate.toLocaleDateString()}&new=${newDate.toLocaleDateString()}`).pipe(
      catchError(this.handleError<any>("Update Workorder Date", null))
    );
  }

  public updateSchedule(machineAssignmentId: string, newDate: Date): Observable<any> {
    return this.http.get<any>(this.baseUrl + `api/FloorBuilding/updateSchedule/${machineAssignmentId}?new=${newDate.toISOString()}`).pipe(
      catchError(this.handleError<any>("Update Machine Assignment Schedule", null))
    );
  }

  public saveAssignment(_assignment: MachineAssignment): Observable<MachineAssignment> {
    //Prep work - we don't want to insert these again...
    const assignment = JSON.parse(JSON.stringify(_assignment));


    assignment.department = null;
    assignment.operation = null;
    assignment.machine = null;
    assignment.user = null;
    assignment.workOrder = null;

    if (assignment.machineId == UtilityService.emptyGuid) {
      //New Item
      return this.http.post<MachineAssignment>(this.baseUrl + 'api/machineassignment/new', assignment).pipe(
        tap(_ => this.messages.add("Floor Service: Assignemnt Saved Successfully", MessageType.SUCCESS, true)),
        catchError(this.handleError<MachineAssignment>("Save New Assignment", assignment))
      );
    }
    else {
      //Existing Item
      return this.http.post<MachineAssignment>(this.baseUrl + 'api/machineassignment', assignment).pipe(
        tap(_ => this.messages.add("Floor Service: Assignment Saved Successfully", MessageType.SUCCESS, true)),
        catchError(this.handleError<MachineAssignment>("Update Assignment", assignment))
      );
    }
  }

  public completeRun(assignment: MachineAssignment): Observable<MachineAssignment> {
    return this.http.post<MachineAssignment>(this.baseUrl + 'api/floormachine/completeRun/' + assignment.assignmentId, null).pipe(
      catchError(this.handleError<any>("Complete Run", null))
    );
  }

  public completeAssignment(assignment: MachineAssignment): Observable<MachineAssignment> {
    return this.http.post<MachineAssignment>(this.baseUrl + 'api/floormachine/completeAssignment/' + assignment.assignmentId, null).pipe(
      catchError(this.handleError<any>("Complete Assignment", null))
    );
  }

  public searchBuildings(searchString?: string, pageIndex = 0, pageSize = 50): Observable<SearchResult<Building>> {
    return this.http.get<SearchResult<Building>>(this.baseUrl + 'api/building/search', 
    { params: { searchText: searchString || "", pageIndex: pageIndex.toString(), pageSize: pageSize.toString(), orderBy: "name", direction: "asc" } }).pipe(
      catchError(this.handleError<any>("Get Buildings Search Results", null))
    );
  }

  public getBuilding(id: string): Observable<Building> {
    return this.http.get<Building>(this.baseUrl + 'api/building/' + id).pipe(
      catchError(this.handleError<any>("Get Building by ID", null))
    );
  }

  public saveBuilding(_building: Building): Observable<Building> {
    const building = JSON.parse(JSON.stringify(_building));
    building.rootInventoryLocation = null;
    building.address.nickname = building.name;
    if (building.buildingId == UtilityService.emptyGuid) {
      //New Item
      // The new Address will be saved automatically thanks to EFCore
      building.address.addressId = UtilityService.newGuid();
      return this.http.post<Building>(this.baseUrl + 'api/building/new', building).pipe(
        tap(_ => this.messages.add("Floor Service: Building Saved Successfully", MessageType.SUCCESS, true)),
        catchError(this.handleError<Building>("Save New Building", building))
      );
    }
    else {
      //Existing Item
      // Have to save the contained address manually when updating
      return this.http.post<Address>(this.baseUrl + 'api/address', building.address).pipe(
        mergeMap(() => {
          return this.http.post<Building>(this.baseUrl + 'api/building', building)
        }),
        tap(_ => this.messages.add("Building Service: Building Saved Successfully", MessageType.SUCCESS, true)),
        catchError(this.handleError<Building>("Update Building", building))
      );
    }
  }

  public removeBuilding(buildingId: string) {
    return this.http.delete<Building>(this.baseUrl + 'api/building/' + buildingId).pipe(
      tap(_ => {
        this.messages.add("Building Service: Building Deleted Successfully", MessageType.SUCCESS, true);
      }),
      catchError(this.handleError<Building>("Remove Building", null))
    );
  }

  public createFloorBuilding(department: Department, building: Building): Observable<Department> {
    return this.http.post<FloorBuilding>(this.baseUrl + 'api/floorBuilding/new', <FloorBuilding>{
      floorBuildingId: UtilityService.emptyGuid,
      departmentId: department.departmentId,
      buildingId: building.buildingId
    })
    .pipe(
      mergeMap(() => {
        return this.get(department.departmentId);
      }),
      catchError(this.handleError<any>("Assign Building to Department", null))
    );
  }

  public removeFloorBuilding(floorBuildingId: string) {
    return this.http.delete<FloorBuilding>(this.baseUrl + 'api/floorBuilding/' + floorBuildingId).pipe(
      tap(_ => {
        this.messages.add("Floor Service: Building Unassigned Successfully", MessageType.SUCCESS, true);
      }),
      catchError(this.handleError<FloorBuilding>("Remove Department", null))
    );
  }

  public getToolCribTodayTickets(buildingId: string) {
    return this.http.get<ToolCribTicket[]>(this.baseUrl + 'api/toolcrib/todayTickets?buildingId=' + buildingId).pipe(
      catchError(this.handleError<any>("Get Tool Crib Tickets", null))
    );
  }

  public fulfillToolCribTicket(ticket: ToolCribTicket, data: {
    inventoryItemLocationId: string,
    amount: number
  }[]): Observable<void> {
    return this.http.post<void>(this.baseUrl + 'api/toolcrib/fulfill/' + ticket.toolCribTicketId, data).pipe(
      tap(_ => {
        this.messages.add("Floor Service: Tool Crib Ticket Fulfilled", MessageType.SUCCESS, true);
      }),
      catchError(this.handleError<any>("Fulfill Tool Crib Ticket", null))
    );
  }

  public sendFirstArticle(assignment: MachineAssignment, timestamp: Date): Observable<void> {
    return this.http.post<void>(this.baseUrl + 'api/floormachine/sendFirstArticle/' + assignment.assignmentId, JSON.stringify(timestamp), { headers: { 'Content-Type': 'application/json' } }).pipe(
      tap(_ => {
        this.messages.add("Floor Service: First Article Sent", MessageType.SUCCESS, true);
      }),
      catchError(this.handleError<any>("Send First Article", null))
    );
  }

  public checkFirstArticle(assignment: MachineAssignment): Observable<{ status: 'waiting' | 'approved' | 'rejected', note?: string}> {
    return this.http.get<void>(this.baseUrl + 'api/floormachine/checkFirstArticle/' + assignment.assignmentId).pipe(
      catchError(this.handleError<any>("Check First Article Inspection Status", null))
    );
  }


}
