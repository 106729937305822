<mat-form-field class="w-100" appearance="outline" *ngLet="filteredItems$ | async as items">
  <mat-label *ngIf="placeholder">{{placeholder}}</mat-label>
  <input
    matInput
    [(ngModel)]="searchValue"
    #searchModel="ngModel"
    type="text"
    [placeholder]="placeholder || 'Search'"
    [matAutocomplete]="auto"
    [disabled]="disabled"
  >
  <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event.option.value)" [displayWith]="displayWith.bind(this)">
      <ng-container *ngIf="items">
        <mat-option *ngFor="let item of items" [value]="item">
            <span [innerHTML]="(item.partNumber ? item.partNumber : item.description) | highlight: searchValue"></span>
            <span class="badge badge-info ml-2" *ngIf="item.partNumber">
              Alternate PN for {{ item.description }}
            </span>
        </mat-option>
      </ng-container>
      <mat-option disabled *ngIf="!loading && (items)?.length===0">
        <button *ngIf="canAdd()" class="float-right btn btn-primary btn-sm mt-4" (click)="onAdd($event)">
          <i class="fas fa-fw fa-lg fa-plus"></i>
          {{addItemText}}
        </button>
        <p class="text-muted small text-center mt-3">
          <em>
            {{noItemsText}}
          </em>
        </p>
      </mat-option>
      <mat-option [class.d-none]="!loading && !!items" disabled class="searching-indicator">
        <div class="d-flex align-items-center justify-content-center w-100">
          <mat-spinner diameter="20" class="mr-2"></mat-spinner> Searching...
        </div>
      </mat-option>
  </mat-autocomplete>
</mat-form-field>