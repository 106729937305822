<loading *ngIf="saving">Saving...</loading>

<form ngForm *ngIf="record">
  <div class="row m-0 py-3 position-relative">
    <div class="col-12 mb-2">
      <vendor-search [required]="true" [(ngModel)]="record.vendor" (ngModelChange)="record.vendorId = $event?.vendorId" name="vendor"></vendor-search>
    </div>
    <div class="col-6 mb-2">
      <div class="mat-small-header">Quote Type</div>
      <div>
        Formal <mat-slide-toggle [(ngModel)]="record.isVerbal" name="isVerbal"></mat-slide-toggle> Verbal
      </div>
    </div>
    <div class="col-6">
      <vendor-contact-search *ngIf="record.isVerbal && record.vendor" [vendor]="record.vendor" [addAllowed]="true" [(selectedItem)]="record.contact" (selectedItemChange)="record.contactId = $event.contactId" (onAddItem)="newVendorContact($event)"></vendor-contact-search>
    </div>
  <div class="col-12">
    <mat-form-field appearance="outline" class="w-100">
      <mat-label>Lead Time (days)</mat-label>
      <input name="leadTimeDays" type="number" matInput min="0" required step="1" placeholder="Lead Time (days)" [(ngModel)]="record.leadTimeDays" #leadTime="ngModel" />
    </mat-form-field>
  </div>
  <div class="col-12">
    <div class="mat-small-header">Bid Type</div>
    <div class="input-group input-group-sm">
      <mat-radio-group name="bidType" [(ngModel)]="bidType" class="row align-items-center">
        <mat-radio-button class="col-6" value="total">Total</mat-radio-button>
        <mat-radio-button class="col-6" value="perItem">Per Item</mat-radio-button>
      </mat-radio-group>
    </div>
  </div>
  <div class="col-12 mb-2" *ngIf="bidType == 'total'">
    <mat-form-field appearance="outline" class="w-100">
      <mat-label>Total Bid</mat-label>
      <span matPrefix style="position: relative; bottom: 8px;" class="text-muted">$</span>
      <input matInput name="totalBid" style="text-align: right;" type="number" min="0" step=".01" placeholder="Total Bid" [(ngModel)]="record.totalBid" />
    </mat-form-field>
  </div>
  <div class="col-12 mb-2" *ngIf="bidType == 'perItem'">
    <mat-form-field appearance="outline" class="w-100">
      <mat-label>Per Item Bid</mat-label>
      <span matPrefix style="position: relative; bottom: 8px;" class="text-muted">$</span>
      <input matInput name="perItemBid" style="text-align: right;" type="number" min="0" step=".01" placeholder="Per Item Bid" [(ngModel)]="record.perItemBid" />
    </mat-form-field>
  </div>
  <div class="col-12">
    <mat-form-field appearance="outline" class="w-100">
      <mat-label>Quoted Quantity</mat-label>
      <input matInput name="quotedQuantity" type="number" min="0" step="1" placeholder="Quoted Quantity" [(ngModel)]="record.qty" />
    </mat-form-field>
  </div>
  </div>
  <div class="mt-2">
    <button mat-flat-button color="primary" class="mr-2" [disabled]="!isValid" (click)="done.emit(record)">Save Quote</button>
    <button mat-stroked-button (click)="cancel.emit()">Cancel</button>
  </div>
</form>

<ng-template #newVendorContactDialogTemplate let-contact>
  <h2 mat-dialog-title>New Vendor Contact</h2>
  <mat-dialog-content>
    <div class="row">
      <div class="col-6">
        <mat-form-field appearance="outline" class="w-100 mat-input-no-message">
          <mat-label>Contact Name</mat-label>
          <input matInput required placeholder="Contact Name" type="text" max="100"
           [(ngModel)]="contact.name" />
        </mat-form-field>
      </div>
      <div class="col-6">
        <mat-form-field appearance="outline" class="w-100 mat-input-no-message">
          <mat-label>Title</mat-label>
          <input matInput placeholder="Title" type="text" max="100"
            [(ngModel)]="contact.title" />
        </mat-form-field>
      </div>
    </div>
    <div class="row">
      <div class="col-8">
        <phone-number appearance="outline" [editable]="true" [(number)]="contact.phoneNumber">
        </phone-number>
      </div>
      <div class="col-4">
        <mat-form-field appearance="outline" class="w-100 mat-input-no-message">
          <mat-label>Ext.</mat-label>
          <input matInput placeholder="Extension" type="text" max="100"
        [(ngModel)]="contact.phoneExtension" />
        </mat-form-field>
      </div>
    </div>
    <div class="row">
      <div class="col-6">
        <phone-number placeholder="Fax Number" appearance="outline" [editable]="true" [(number)]="contact.faxNumber">
        </phone-number>
      </div>
      <div class="col-6">
        <phone-number placeholder="Cell Number" appearance="outline" [editable]="true" [(number)]="contact.cellNumber">
        </phone-number>
      </div>
    </div>
    <div class="row">
      <div class="col-6">
        <mat-form-field appearance="outline" class="w-100 mat-input-no-message">
          <mat-label>Work Email</mat-label>
          <input matInput placeholder="Work Email" type="email" max="100"
            [(ngModel)]="contact.email" />
        </mat-form-field>
      </div>
      <div class="col-6">
        <mat-form-field appearance="outline" class="w-100 mat-input-no-message">
          <mat-label>Personal Email</mat-label>
          <input matInput placeholder="Personal Email" type="email" max="100"
        [(ngModel)]="contact.personalEmail" />
        </mat-form-field>
      </div>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button mat-button class="btn btn-default btn-sm mr-3" [matDialogClose]="null">Cancel</button>
    <button mat-button [disabled]="!contact.name" [matDialogClose]="contact">Add Contact</button>
  </mat-dialog-actions>
</ng-template>