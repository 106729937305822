import { Component, Output, Input, EventEmitter, OnInit, OnChanges, SimpleChanges, ContentChild, TemplateRef } from '@angular/core';
import { User } from '../../../common/resources/user';
import { UserService } from '../../../common/services/user.service';

@Component({
  selector: 'filtered-user-list',
  templateUrl: './filtered-user-list.component.html',
  styleUrls: ['./filtered-user-list.component.less']
})
export class FilteredUserListComponent implements OnInit, OnChanges {
  @Input() selected: string;
  @Input() roleFlter: string;
  @Input() filterExpression: any;
  @Input() editable: boolean = false;
  @Input() emptyResultsMessage: string = "No Users Found!";
  @Output() selectedChange: EventEmitter<string> = new EventEmitter<string>();
  @ContentChild(TemplateRef) itemTemplate: TemplateRef<any>;
  public users: User[] = [];
  public selectedUser: User;

  constructor(private usrSvc: UserService) { }

  public selectUser(e: any): void {
    this.selected = e.value;
    this.selectedChange.emit(this.selected);
    this.updateData();
  }

  private parseRoles(): string[] {
    var initialRoles: string[] = this.roleFlter.split(",");
    var allRoles: string[] = [].concat(initialRoles);

    //trim
    initialRoles.forEach(a => a.trim());

    initialRoles.forEach(i => {
      if (i.startsWith('*')) {
        const suffix: string = i.substring(1);
        const matches: string[] = this.usrSvc.roles.filter(a => a.name.endsWith(suffix)).map(r => r.name);

        allRoles.splice(allRoles.findIndex(a => a == i), 1);
        allRoles = allRoles.concat(matches);
      }
    });

    return allRoles;
  }

  private updateData() {
    const roles = this.parseRoles();

    this.usrSvc.search("", 0, 'name', 'asc', roles.join(), true).subscribe(r => {
      if (this.filterExpression != undefined) {
        this.users = r.results.filter(this.filterExpression);
      }
      else {
        this.users = r.results;
      }
    });

    if (this.selected) {
      this.usrSvc.getUser(this.selected).subscribe(u => this.selectedUser = u);
    }
  }

  ngOnChanges(_: SimpleChanges): void {
    this.updateData();
  }

  ngOnInit(): void {
    this.updateData();
  }
}
