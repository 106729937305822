import { ChangeDetectionStrategy, Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Product } from '../../../resources/product';
import { OrderDetailService } from '../order-detail.service';
import { SelectionModel } from '@angular/cdk/collections';
import { WorkflowStep } from '../../../resources/workflow';
import { Observable, combineLatest, of, pipe } from 'rxjs';
import { filter, map, mergeMap, startWith } from 'rxjs/operators';
import { MaterialBid } from '../../../../purchasing/resources/materialBid';

type SearchedQuote = MaterialBid | { error: 'UNQUOTED' | 'NOT_FOUND' }

@Component({
  selector: 'order-detail-lead-time-breakdown',
  templateUrl: './order-detail-lead-time-breakdown.component.html',
  styleUrls: ['./order-detail-lead-time-breakdown.component.less'],
})
export class OrderDetailLeadTimeBreakdownComponent implements OnInit, OnChanges {

  constructor(
    public service: OrderDetailService,
  ) { }

  public get record() { return this.service.order }
  public get editing() { return this.service.editing }
  @Input() product: Product;

  public selectionModel = new SelectionModel<'PURCHASED' | 'OUTSOURCED' | 'LABOR' | 'CHILDREN'>(true);

  public get laborSteps() {
    return this.product.workflow.workflowSteps.filter(s => {
      const station = this.service.getStation(s);
      return !station.isOutsourceStep;
    })
  }

  public get outsourceSteps() {
    return this.product.workflow.workflowSteps.filter(s => {
      const station = this.service.getStation(s);
      return station.isOutsourceStep;
    })
  }

  public getLaborStepLeadTime(step: WorkflowStep) {
    var hours: number;
    if (step.runIsPerPart) {
      hours = (((step.runTime || 0) / 60) + ((step.hasSetup && step.perPieceSetupTime) ? (step.perPieceSetupTime / 60) : 0)) * this.service.getFirstQuantity(this.product);
    } else {
      hours = step.runTime || 0;
    }
    var setupTime = (step.hasSetup ? (step.setupTime ? step.setupTime : 0) : 0);

    return hours + setupTime;
  }

  public getTotalLaborDays(product: Product) {
    return Math.ceil(
      (product.workflow.workflowSteps.filter(s => {
        const station = this.service.getStation(s);
        return !station.isOutsourceStep;
      }).reduce((acc, x) => acc + this.getLaborStepLeadTime(x), 0)) / 8
    )
  }

  public get totalLaborDays() {
    return this.getTotalLaborDays(this.product);
  }

  public quoteLeadTimeObservable(quoteId: string) {
    return this.service.allQuotes.pipe(
      map(all => Object.values(all).flat()),
      map(flatQuotes => flatQuotes.find(q => q.materialBidId === quoteId)),
      filter(quote => !!quote)
    );
  }

  private searchQuote() {
    return pipe(
      mergeMap((quoteId: string) => {
        if (quoteId) return this.quoteLeadTimeObservable(quoteId);
        else return of({ error: 'UNQUOTED' as const });
      }),
      map((q) => {
        if (q) return q;
        else return { error: 'NOT_FOUND' as const };
      })
  )
  }

  public quoteLeadTimeObservableFiltered(quoteId: string) {
    return of(quoteId).pipe(this.searchQuote());
  }

  public $materialQuote: Observable<MaterialBid | { error: 'NOT_FOUND' | 'UNQUOTED' }>;
  public $purchasedItemsTotalLeadTime: Observable<number>;
  public $outsourcedTotalLeadTime: Observable<number>;
  public $subassembliesLeadTime: Observable<number>;
  public $laborLeadTime: Observable<number>;
  public $subtotalLeadTime: Observable<number>;
  public $totalLeadTime: Observable<number>;
  
  public initObservables(): void {
    const $product = this.service.productEdited.pipe(startWith(this.product), filter((p) => p?.productId === this.product.productId));
    this.$purchasedItemsTotalLeadTime = $product
      .pipe(
        mergeMap((product) => product.purchasedItems.length === 0 ? of([] as MaterialBid[]) : combineLatest(product.purchasedItems.map(p => this.quoteLeadTimeObservableFiltered(p.selectedQuote)))),
        map((items) => items.reduce((acc, x) => {
          if (x.hasOwnProperty('error')) return acc + 0;
          else return acc + (x as MaterialBid).leadTimeDays
        }, 0))
      )
    this.$outsourcedTotalLeadTime = $product
      .pipe(
        mergeMap((product) => {
          const steps = product.workflow.workflowSteps.filter(s => {
            const station = this.service.getStation(s);
            return station.isOutsourceStep;
          });
          return steps.length === 0 ? of([] as MaterialBid[]) : combineLatest(steps.map(p => this.quoteLeadTimeObservableFiltered(p.selectedQuote)))}
        ),
        map((items) => items.reduce((acc, x) => {
          if (x.hasOwnProperty('error')) return acc + 0;
          else return acc + (x as MaterialBid).leadTimeDays
        }, 0))
    );
    this.$subassembliesLeadTime = combineLatest([this.service.productEdited.pipe(startWith(null)), this.service.productChange.pipe(startWith(() => null))])
      .pipe(
        mergeMap((_) =>
         this.product.childAssemblies.length === 0 ? of([] as number[]) : combineLatest(this.product.childAssemblies.map(c => this.service.getProductLeadTimeObservable(c.productId)))),
        map((lt) => Math.max(...lt, 0))
    )
    this.$materialQuote = $product
        .pipe(
          map(p => p.selectedMaterialQuote),
          this.searchQuote()
        )
    this.$laborLeadTime = $product
          .pipe(
            map(p => this.getTotalLaborDays(p))
      )
    this.$subtotalLeadTime = combineLatest([this.$laborLeadTime, this.$materialQuote.pipe(map(q => {
      if (q.hasOwnProperty('error')) return 0;
      else return (q as MaterialBid).leadTimeDays;
    })), this.$purchasedItemsTotalLeadTime, this.$outsourcedTotalLeadTime, this.$subassembliesLeadTime])
        .pipe(map((leadTimes) => {
          return leadTimes.reduce((acc, x) => acc + x, 0);
        }))
    this.$totalLeadTime = combineLatest([$product, this.$subtotalLeadTime])
        .pipe(map(([product, subtotal]) => {
          return subtotal + (product.leadTimeBuffer ?? 0);
    }))
  }

  ngOnInit(): void {
    this.initObservables();
  }
  ngOnChanges(changes: SimpleChanges): void {
    this.initObservables();
  }

}
