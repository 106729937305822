const getAllMatches = (pattern, string) =>
      // make sure string is a String, and make sure pattern has the /g flag
      String(string).match(new RegExp(pattern, 'g'));

// this pattern is far from robust
const variablePattern = /[a-zA-Z$_π][0-9a-zA-Z$_]*/;

export default function evalInScope (expression, context) {

  context = {
    ...context,
    'π': Math.PI
  }

  const variables = getAllMatches(variablePattern, expression) || [];

  variables.forEach(v => {
    if (!context.hasOwnProperty(v)) {
      throw new Error(`Undefined variable ${v}`)
    }
  })

  // function and arguments are keywords, so I use abbreviated names
  const func = new Function(...variables, `return (${expression})`);

  const args = variables.map((arg) => (Object.hasOwnProperty.call(context, arg)
                                       ? context[arg]
                                       : undefined));

  return func(...args);
};