import { Component, OnInit, Input, Output, EventEmitter, ViewChild, AfterViewInit, ViewChildren, ContentChildren, QueryList, ElementRef, ContentChild, Self, Optional, TemplateRef } from '@angular/core';
import { MatColumnDef, MatHeaderCellDef, MatTable } from '@angular/material/table';
import { MatSort, MatSortable, Sort } from '@angular/material/sort';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { BehaviorSubject, Observable, Subject, Subscription, combineLatest, concat, of, timer } from 'rxjs'
import { debounce, debounceTime, filter, first, map, mergeMap, share, startWith, take, tap, throttleTime, pairwise } from 'rxjs/operators'
import { UserService } from '../../services/user.service';
import { Grouped, SearchResult } from '../../resources/search-result';
import { User } from '../../resources/user';
import { SelectionModel } from '@angular/cdk/collections';

const pick = <T, K extends keyof T>({ object, keys }: { object: T; keys: K[]; }): Pick<T, K> =>
    keys.reduce((obj, key) => {
        if (object && Object.prototype.hasOwnProperty.call(object, key)) {
            obj[key] = object[key]
        }
        return obj
    }, {} as Pick<T, K>);

export type FieldSearch = { field: string, string: string };
export type FilterParam = {
  categoryTitle: string,
  categoryName: string,
  options: {
    title: string,
    value: any,
    class: string
  }[]
}
export interface SearchData
{
  searchText: string,
  sort: Sort,
  page: PageEvent,
  forAllUsers?: boolean,
  fieldSearches?: FieldSearch[],
  filters?: { [key: string]: any[] },
  groupBy?: string | null
}

function isGrouped<Y>(x: Y | Grouped<Y>): x is Grouped<Y> {
  return x.hasOwnProperty('groupKey');
}

interface GroupHeader {
  groupName: string,
  groupKey: string,
  groupField: string
}

@Component({
  selector: 'search-list-new',
  templateUrl: './search-list-new.component.html',
  styleUrls: ['./search-list-new.component.less']
})
export class SearchListNewComponent<T> implements AfterViewInit {

  @Input() displayedColumns: string[];
  @Input() resultGetter: (d: SearchData) => Observable<SearchResult<T | Grouped<T>>>;
  @Input() pageSize = 14;
  public loading = false;
  @Input() showManagerToggle = false;
  @Input() alwaysManager = false;
  @Input() filterParams: FilterParam[];
  @Input() defaultFilters: { [key: string]: any[] } = {};
  @Input() defaultSort?: Sort;
  @Input() localStorageId: string;
  @Input() isManager: (u: User) => boolean = (_) => true;
  @Input() groupableFields: { name: string, title: string }[] = [];
  @Input() defaultGrouping: string | null = null;
  @Input() fieldsToPersist: (keyof SearchData)[] | null = null;

  public sort: MatSort;
  constructor(@Optional() @Self() private _matSort: MatSort, private userService: UserService) {
    if (_matSort) this.sort = _matSort;
  }

  private matSortChangeS = new Subject<Sort>();
  public onMatSort(e: Sort) {
    this.matSortChangeS.next(e);
  }

  @ViewChild('paginator') paginator: MatPaginator;
  paginateS = new Subject<PageEvent>();
  public onPage(e: PageEvent) {
    this.paginateS.next(e);
  }

  managerViewS = new Subject<boolean>();
  public onManagerView(e: boolean) {
    this.managerViewS.next(e);
  }

  public columnsReady = false;
  @ViewChild('table', { static: true }) matTable: MatTable<T>;
  @ContentChildren(MatColumnDef) columnDefs: QueryList<MatColumnDef>;

  public searchText = '';
  private searchTextS = new Subject<string>();
  public onSearchChange() {
    this.searchTextS.next(this.searchText);
  }

  public clearSearch() {
    this.searchText = '';
    this.onSearchChange();
  }

  public storeSearch(params: SearchData) {
    if (!this.localStorageId) return;
    if (this.fieldsToPersist) params = pick({
      object: params,
      keys: this.fieldsToPersist
    });
    localStorage.setItem(`search-list-new-${this.localStorageId}`, JSON.stringify(params));
    // store last known filter params to make sure we grab new defaults when they're added
    if (this.filterParams) {
      localStorage.setItem(`search-list-new-last-known-filter-params-${this.localStorageId}`, JSON.stringify(this.filterParams));
    }
  }

  public groupExpansionModel = new SelectionModel<string>();

  // When given a filter setup loaded from localstorage, check if any filter options have been added since we last stored filters, and if they're in the defaults, add them to the filter list 
  // Also delete options that have been removed
  private processDefaultFilters(filtersToProcess: typeof this.defaultFilters): typeof this.defaultFilters {
    try {
      const lastKnownFiltersString = localStorage.getItem(`search-list-new-last-known-filter-params-${this.localStorageId}`);
      if (!lastKnownFiltersString) return filtersToProcess;
      const lastKnownFilters: FilterParam[] = JSON.parse(lastKnownFiltersString);
      for (const lastKnownFilter of lastKnownFilters) {
        if (!filtersToProcess.hasOwnProperty(lastKnownFilter.categoryName)) continue;
        const newAvailable = this.filterParams?.find(p => p.categoryName === lastKnownFilter.categoryName);
        if (!newAvailable) {
          delete filtersToProcess[lastKnownFilter.categoryName];
          continue;
        }
        const newDefaults = this.defaultFilters?.[lastKnownFilter.categoryName];
        if (!newDefaults) continue;
        const lastKnownOptions = lastKnownFilter.options.map(o => o.value);
        const newDefaultOptions = newDefaults.filter(o => !lastKnownOptions.includes(o));
        filtersToProcess[lastKnownFilter.categoryName] = filtersToProcess[lastKnownFilter.categoryName].concat(newDefaultOptions);
        const newAvailableOptions = newAvailable.options.map(o => o.value);
        // delete removed filters
        filtersToProcess[lastKnownFilter.categoryName] = filtersToProcess[lastKnownFilter.categoryName].filter(v => newAvailableOptions.includes(v));
        console.log(filtersToProcess[lastKnownFilter.categoryName]);
      }
      return filtersToProcess;
    } catch (e) {
      return filtersToProcess;
    }
  }

  private removeInvalidFilters(filters: typeof this.defaultFilters) {
    for (const key of Object.keys(filters)) {
      if (Object.prototype.hasOwnProperty.call(filters, key)) {
        const found = this.filterParams?.filter(fp => fp.categoryName === key);
        if (!found) {
          console.log(`Deleting invalid filter ${key}`);
          delete filters[key];
        }        
      }
    }
    return filters;
  }

  private forceUpdateSubject = new Subject<void>();
  public forceUpdate() {
    this.forceUpdateSubject.next();
  }
  
  public results$: Observable<(T | GroupHeader)[]>;
  public resultCount$: Observable<number>;
  public managerView: boolean;
  @Output() search = new EventEmitter<SearchData>()
  async ngAfterViewInit() {

    this.columnDefs.forEach(d => {
      this.matTable.addColumnDef(d);
    });
    console.log(this.sort);
    if (this.sort) {
      this.sort.sortChange.subscribe(s => {
        this.onMatSort(s);
      });
    }
    this.columnsReady = true;

    let defaults: SearchData = {
      page: { length: 0, pageIndex: 0, pageSize: this.pageSize },
      sort: this.defaultSort,
      searchText: '',
      forAllUsers: false,
      fieldSearches: [],
      filters: this.defaultFilters
    };
    try {
      let parsed: Partial<SearchData> = JSON.parse(localStorage.getItem(`search-list-new-${this.localStorageId}`));
      if (parsed) {
        if (this.fieldsToPersist) parsed = pick({ object: parsed, keys: this.fieldsToPersist })
        if (parsed.filters) parsed.filters = this.processDefaultFilters(parsed.filters);
        defaults = { ...defaults, ...parsed };
      };
    } catch (e) {
      console.error('Failed to parse localstorage')
    }

    this.searchText = defaults.searchText;
    this.fieldSearches = defaults.fieldSearches;
    this.managerView = defaults.forAllUsers;
    this.sort.sort({ id: defaults.sort.active, start: defaults.sort.direction, disableClear: false });
    this.paginator.pageIndex = defaults.page.pageIndex;
    this.filters = { ...defaults.filters };


    const searchObservable = combineLatest([
      this.searchTextS.pipe(
        startWith(defaults.searchText),
      ),
      this.matSortChangeS.pipe(startWith(defaults.sort)),
      this.paginateS.pipe(startWith(defaults.page)),
      concat(
        this.userService.user.pipe(
          tap(() => this.loading = true),
          filter(x => !!x && !!x.userId),
          map((u) => {
            if (!this.isManager(u)) return false;
            return defaults.forAllUsers;
          }),
          take(1)
        ),
        this.managerViewS
      ),
      this.fieldSearchesS.pipe(startWith(defaults.fieldSearches)),
      this.filter$.pipe(
        startWith(defaults.filters),
        map(f => this.removeInvalidFilters(f))  
      ),
      this.groupBy$,
      this.forceUpdateSubject.pipe(startWith(null))
    ]).pipe(
      debounce(ev => this.loading ? timer(1000) : of({})),
      map(([searchText, sort, page, managerView, fieldSearches, filters, groupBy]) => (<SearchData>{
        searchText: this.searchText, sort, page,
        fieldSearches,
        forAllUsers: this.alwaysManager ? true : managerView,
        filters,
        groupBy
      })),
      // Use pairwise operator to compare with last value. startwith null so we emit on first input instead of second
      startWith(null),
      pairwise(),
      // Skip this if old value is null. Otherwise, check if any of the fields that could change the result count have changed.
      // If so, reset page to 0 to ensure we aren't paginated past the total results.
      map(([o, n]) => {
        if (o === null) return n;
        // Compare old and new values.
        if (
          o.searchText !== n.searchText ||
          JSON.stringify(o.fieldSearches) !== JSON.stringify(n.fieldSearches) ||
          JSON.stringify(o.filters) !== JSON.stringify(n.filters) ||
          o.forAllUsers !== n.forAllUsers
        ) {
          n.page.pageIndex = 0;
        }
        // do this to make sure we're not using the endpoint's default sort when grouping since that might look weird
        if (o.groupBy === null && n.groupBy !== null && n.sort.direction === '') {
          n.sort.active = n.groupBy;
          n.sort.direction = 'asc';
        }
        return n;
      }),
      tap(data => this.storeSearch(data)),
      tap(() => this.loading = true),
      mergeMap(data => {
        return this.resultGetter(data).pipe(
          tap(() => {
            // Wait until we get results to set the paginator's visual pageIndex to the index we actually searched with.
            // Looks kind of weird if we set this back in the map function when we checked if we should reset.
            if (this.paginator) this.paginator.pageIndex = data.page.pageIndex;
          })
        );
      }),
      tap(() => this.loading = false),
    );

    const searchSubject = new Subject<SearchResult<T | Grouped<T>>>();
    searchObservable.subscribe(searchSubject);

    this.results$ = searchSubject.pipe(
      filter((x) => !!x),
      map((x) => {
        const { results, pageSize } = x;
        if (results[0] && isGrouped(results[0])) {
          const flat = results.flatMap((r: Grouped<T>) => [<GroupHeader>{ groupKey: r.groupKey, groupName: r.groupName, groupField: r.groupField }, ...r.items]);
          return flat;
          // return flat.slice(0, pageSize);
        } else return results;
      })
    );
    this.resultCount$ = searchSubject.pipe(
      filter((x) => !!x),
      map((x) => x.resultCount)
    );

    this.groupBy$.next(defaults.groupBy);

  }


  @Input() fieldSearchFields: {
    field: string,
    code: string,
  }[] = [];
  @Input() fieldSearchNames: { [key: string]: string } = {};
  public fieldSearchAddName: string;
  public fieldSearchAddField = null;
  public fieldSearchAddText = '';

  public get fieldSearchAddWidth(): string {
    if (!this.fieldSearchAddText) return '1px';
    else return `${this.fieldSearchAddText.length}ch`
  }

  @ViewChild('fieldSearchInput') fieldSearchInput: ElementRef<HTMLInputElement>
  public checkFieldSearch() {
    if (this.fieldSearchFields.length === 0) return;
    for (const field of this.fieldSearchFields) {
      if (this.searchText.startsWith(`${field.code}:`)) {
        if (this.fieldSearches.some(fs => fs.field === field.field)) return;
        this.searchText = '';
        this.fieldSearchAddName = this.fieldSearchNames[field.field];
        this.fieldSearchAddField = field.field;
        setTimeout(() => {
          this.fieldSearchInput.nativeElement.focus();
        });
      }
    }
  }

  public fieldSearches: FieldSearch[] = [];
  private fieldSearchesS = new Subject<FieldSearch[]>();
  @ViewChild('searchInput') searchInput: ElementRef<HTMLInputElement>
  public processFieldSearch(refocus = false) {
    const trimmed = this.fieldSearchAddText.trim();
    if (trimmed) {
      this.fieldSearches = [...this.fieldSearches, {
        field: this.fieldSearchAddField,
        string: trimmed
      }];
      this.fieldSearchesS.next(this.fieldSearches);
    };
    this.fieldSearchAddField = null;
    this.fieldSearchAddName = null;
    this.fieldSearchAddText = '';
    if (refocus) this.searchInput.nativeElement.focus();
  }

  public deleteFieldSearch(field: string) {
    this.fieldSearches = this.fieldSearches.filter(fs => fs.field !== field);
    this.fieldSearchesS.next(this.fieldSearches);
  }

  public tryCancelFieldSearch() {
    if (this.fieldSearchAddText === '') {
      this.fieldSearchAddField = null;
      this.fieldSearchAddName = null;
      this.fieldSearchAddText = '';
    }
  }

  @Output() itemSelected = new EventEmitter<T>();
  public onSelect(record: T) {
    this.itemSelected.emit(record);
  }

  public filter$ = new Subject<{ [key: string]: any[] }>();
  public filters: {
    [key: string]: any[]
  } = {};
  public filterIsEnabled(category: string, value: string) {
    return this.filters[category]?.includes(value) ?? false;
  }
  public toggleFilter(category: string, value: string) {
    if (!this.filters[category]) this.filters[category] = [];
    if (this.filterIsEnabled(category, value)) this.filters[category] = this.filters[category].filter(v => v !== value);
    else this.filters[category] = [...this.filters[category], value];
    this.filter$.next(this.filters);
  }

  public get filterCount(): number | null {
    let output = null;
    for (const category in this.filters ?? {}) {
      const exists = this.filterParams.find(f => f.categoryName == category);
      if (!exists) continue;
      const filtered = this.filters[category];
      const possibleFilters = this.filterParams?.find(c => c.categoryName === category)?.options ?? [];
      const defaultFilters = this.defaultFilters?.[category] ?? [];
      if (filtered.length !== 0 && filtered.length !== possibleFilters.length && !filtered.every(f => defaultFilters.find(df => df === f))) {
        if (output === null) output = 0;
        output += filtered.length;
      }
    }
    return output;
  }

  public groupBy$ = new Subject<string | null>();
  public setGroupBy(groupBy: string | null) {
    this.groupBy$.next(groupBy);
  }

  public rowIsGroupHeader(_: number, x: T | GroupHeader) {
    return x.hasOwnProperty('groupKey');
  }

  public processDisplayedColumns(groupBy: string | null) {
    return this.displayedColumns.filter(c => c !== groupBy);
  }

  @ContentChild('groupHeaderTemplate') groupHeaderTemplate?: TemplateRef<any>;

  public static searchDataToParams(data: SearchData): URLSearchParams {
    const {
      forAllUsers,
      searchText,
      page,
      sort,
      fieldSearches,
      filters,
      groupBy
    } = data;
    const { pageIndex, pageSize } = page;
    const params = new URLSearchParams({ forAllUsers: (!!forAllUsers).toString(), searchText, pageIndex: (pageIndex || 0).toString(), pageSize: (pageSize ?? 10).toString(), orderBy: sort.active, direction: sort.direction || "desc" });
    if (groupBy) params.append('groupBy', groupBy);
    if (fieldSearches) {
      for (const fs of fieldSearches) {
        params.append(`fieldSearches[${fs.field}]`, fs.string)
      }
    }
    if (filters) {
      for (const category in filters) {
        if (!filters.hasOwnProperty(category)) continue;
        params.append(`filters[${category}]`, filters[category].join(','));
      }
    }
    return params;
  }

}
