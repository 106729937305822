<div class="row">
  <a *ngIf="record && sidenav && !showEditor" class="position-fixed fixed-bottom btn btn-badge bg-danger has-icon" title="Close" (click)="close()"><i class="fas fa-times"></i></a>

  <loading *ngIf="!record || loading"></loading>
  <loading *ngIf="saving">Saving...</loading>

  <mat-sidenav-container *ngIf="record">
    <mat-sidenav disableClose (closedStart)="closeSideNav()" #insetNav mode="over" position="end">
      <machine-assignment-detail [record]="selectedAssignment" *ngIf="showEditor=='assignment'" [sidenav]="insetNav"></machine-assignment-detail>
    </mat-sidenav>
    <mat-sidenav-content>

      <div class="container-fluid p-4 ml-2">

        <div class="p-0 m-0 row">
          <div class="col-3 pl-0">
            <div *ngIf="!editing">
              <h5 class="w-100 text-center p-2 bg-secondary">Properties</h5>
              <div class="container-fluid row">
                <div class="col-5">
                  Name:
                </div>
                <div class="col-6">
                  {{record.name}}
                </div>
              </div>
              <div class="container-fluid row">
                <div class="col-5">
                  Status:
                </div>
                <div class="col-6" [ngClass]="{'text-success':(record.status==0), 'text-warning':(record.status==1), 'text-danger':(record.status>1)}">
                  {{getStatusText()}}
                </div>
              </div>
              <div class="container-fluid row">
                <div class="col-5">
                  Max L:
                </div>
                <div class="col-6">
                  {{record.operatingLength}}&quot;
                </div>
              </div>
              <div class="container-fluid row">
                <div class="col-5">
                  Max H:
                </div>
                <div class="col-6">
                  {{record.operatingHeight}}&quot;
                </div>
              </div>
              <div class="container-fluid row">
                <div class="col-5">
                  Max D:
                </div>
                <div class="col-6">
                  {{record.operatingThickness}}&quot;
                </div>
              </div>
              <div class="container-fluid row">
                <div class="col-5">
                  Last Maint:
                </div>
                <div class="col-6">
                  {{record.lastMaintenance ? (record.lastMaintenance | date:'shortDate') : '--'}}
                </div>
              </div>
              <div class="container-fluid row">
                <div class="col-5">
                  Specifications:
                </div>
                <div class="col-6">
                  <div class="container-fluid p-0" *ngFor="let item of record.extendedData | keyvalue">
                    <div class="row">
                      <div class="col-4">{{item.key}}:</div>
                      <div class="col-8">{{item.value}}</div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="container-fluid row">
                <div class="col-12 text-right">
                  <button class="btn btn-sm btn-secondary text-secondary" (click)="toggleEditing()">
                    <i class="fas fa-edit fa-fw"></i>
                    Edit
                  </button>
                </div>
              </div>
            </div>
            <div *ngIf="editing">
              <machine-data-form [(machine)]="record"></machine-data-form>
              <div class="text-right pt-2">
                <button class="btn btn-sm btn-danger mr-2" (click)="cancelEditing()">
                  <i class="fas fa-fw fa-lg fa-times"></i>
                  Cancel
                </button>
                <button class="btn btn-sm btn-success" (click)="saveSetup()">
                  <i class="fas fa-fw fa-lg fa-save"></i>
                  Save
                </button>
              </div>
            </div>
          </div>
          <div class="col-9 pr-0">
            <h5 class="w-100 text-center p-2 bg-secondary">
              <span *ngIf="isToday(date)">Today - </span>{{date | date:'shortDate'}}
              <button *ngIf="!isToday(date)" class="ml-4 btn btn-sm btn-secondary text-secondary" (click)="returnToToday()">
                <i class="far fa-fw fa-calendar-alt"></i>
                Return to Today
              </button>
            </h5>
            <machine-today-schedule #todayChart [date]="date" (onClick)="setAssignment($event)" [machineId]="machineId"></machine-today-schedule>
          </div>
        </div>
        <div class="row ml-0 mr-0 mt-3">
          <h5 class="w-100 text-center p-2 bg-secondary">
            Future Schedule
          </h5>
          <div class="col-12">
            <machine-future-schedule #futureChart [machineId]="machineId" (onClick)="switchDate($event)"></machine-future-schedule>
          </div>
        </div>
      </div>

    </mat-sidenav-content>
  </mat-sidenav-container>>
</div>
