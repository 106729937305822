<div class="sortable-container" (drop)="onDrop($event, steps.length)" (dragover)="allowDrop($event,steps.length)">
  <div>
    <div class="float-right mt-1 mr-1" *ngIf="sortable">
      <button (click)="deleteItem()" [disabled]="!selectedItem || (isRepair && selectedItem.stepType == 0)" class="btn btn-light btn-sm" title="Delete"><i class="fas fa-fw fa-lg fa-times text-danger"></i></button>
      <button (click)="moveUp()" class="btn btn-light btn-sm ml-1 mr-1" [disabled]="!selectedItem || (isRepair && selectedItem.stepType == 0)" title="Move Up"><i class="fas fa-fw fa-lg fa-arrow-circle-up text-info"></i></button>
      <button (click)="moveDown()" class="btn btn-light btn-sm" [disabled]="!selectedItem || (isRepair && selectedItem.stepType == 0)" title="Move Down"><i class="fas fa-fw fa-lg fa-arrow-circle-down text-info"></i></button>
    </div>
    <h5 class="bg-secondary text-center p-2">Workflow</h5>
  </div>
  <ul class="sortable-list">
    <li class="section-heading text-muted bg-white small font-weight-bold text-center" *ngIf="isRepair && steps.length > 0">
      -- ORIGINAL WORKFLOW (LOCKED) --
    </li>
    <ng-container *ngFor="let item of steps; let i = index">
      <ng-container  *ngTemplateOutlet="stepTemplate; context:{ item: item, i: i }"></ng-container>
    </ng-container>
    <li class="section-heading text-success bg-white small font-weight-bold text-center" *ngIf="isRepair">
      -- REPAIR WORKFLOW --
    </li>
    <li class="text-center" *ngIf="(isRepair && repairPlan && repairPlan.workflow.workflowSteps.length == 0) || (!isRepair && steps.length==0)" (drop)="onDrop($event, 0)" (dragover)="allowDrop($event,0)">
      <div class="drop-zone text-white text-center font-italic" *ngIf="sortable && onDragOverIndex == 0">
        -- Drop Here to Add --
      </div>
      <p class="font-italic text-muted mt-3">-- No Workflow Items --</p>
      <p class="small text-muted"><i class="fas fa-lg fa-info-circle text-info mr-2"></i>Double-click or drag and drop available stations to add them in any order.</p>
    </li>
    <ng-container *ngIf="repairPlan">
      <ng-container *ngFor="let item of repairPlan.workflow.workflowSteps; let i = index">
        <ng-container  *ngTemplateOutlet="stepTemplate; context:{ item: item, i: i }"></ng-container>
      </ng-container>
    </ng-container>
  </ul>
</div>


<ng-template #stepTemplate let-item="item" let-i="i">
  <li [ngClass]="{'active': item == selectedItem, added:item.isFromCompare, locked: isRepair && item.stepType == 0, 'repair-step': item.stepType == 1, 'amend-step': item.stepType == 2}" [draggable]="this.sortable && !this.repairPlan" (click)="selectItem(item)"
        (drop)="onDrop($event, i)" (dragover)="allowDrop($event,i)" (dragstart)="onDragStart($event, item, i)">
      <div class="drop-zone text-white text-center font-italic" *ngIf="!this.repairPlan && onDragOverIndex == i">
        -- Drop Here to Add/Reorder --
      </div>
      <div>
        <i [title]="!item?.runIsPerPart?'Per Job':''" [ngClass]="{'fa-cogs':!item?.runIsPerPart}" class="fas fa-fw fa-lg text-muted"></i>
        <i [title]="getStation(item)?.isOutsourceStep?'Outsourced':''" [ngClass]="{'fa-sign-out-alt':getStation(item)?.isOutsourceStep}" class="fas fa-fw fa-lg text-muted"></i>
        <i [title]="getStation(item)?.isPainting?'Painting':''" [ngClass]="{'fa-fill-drip':getStation(item)?.isPainting}" class="fas fa-fw fa-lg text-muted"></i>
        <i [title]="item.outsourceIsFinal?'Tentative':''" [ngClass]="{'fa-question':(getStation(item)?.isOutsourceStep && !item.outsourceIsFinal)}" class="fas fa-fw fa-lg text-muted"></i>
        <span [ngClass]="{'text-danger':hasComparisonData(item)}">{{getStation(item)?.name}}<span class="font-weight-bold small" *ngIf="getStation(item)?.isOutsourceStep && item.outsideProcessSpecifications.length > 0"> ({{getSpecsString(item)}})</span></span>
        <span class="float-right small text-muted" *ngIf="!item.isStandalone">
          ({{calculateCost(item) | currency}} / {{calculatePercentage(item) | percent:'0.1-2'}})
        </span>
        <span class="float-right small text-muted font-weight-bold" *ngIf="item.isStandalone" matTooltip="Standalone Step" matTooltipPosition="after">
          <i class="fas fa-bars-staggered mr-1"></i>
          {{ ( item.runPrice * item.runTime ) | currency }}
        </span>
      </div>
    </li>
</ng-template>