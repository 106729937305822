import { CustomerPurchaseOrderLineItem } from "../../order/resources/customer-po";
import { ShippingTicket } from "../../shipping/resources/shipping-ticket";

export enum AccountReceivableStatus {
    AWAIT_WORK = 0,
    READY_INVOICE = 1,
    AWAIT_PAYMENT = 2,
    PAID = 3,
    CLOSED = 4
}

export interface AccountReceivable {
    accountReceivableId: string;
    invoiceNumber: string;
    customerPurchaseOrderLineItemId: string;
    shippingTicketId?: string;
    quantity: number;
    notes: string;
    status: AccountReceivableStatus;
    invoiceDate?: Date;
    paymentRequiredDate?: Date;
    paymentDate?: Date;
    shippingTicket: ShippingTicket;
    customerPurchaseOrderLineItem: CustomerPurchaseOrderLineItem;
}