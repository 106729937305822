<div class="container-fluid p-0">
  <h5 class="w-100 text-center p-2 bg-secondary">
    Edit Machine
  </h5>
  <div class="row m-0">
    <label>Name</label>
    <input minlength="3" [(ngModel)]="machine.name" required placeholder="Machine Name" type="text" max="50" class="form-control form-control-sm" appStaticEdit [editable]="true" />
  </div>
  <div class="row m-0">
    <label>Type</label>
    <div class="col-12 p-0">
      <div *ngIf="machine.operationType; else typeWait">
        <mat-select [(ngModel)]="machine.operationType" [compareWith]="compareStationIds" placeholder="Machine Type" (selectionChange)="changeType($event)">
          <mat-select-trigger>
            {{machine.operationType.name}}
          </mat-select-trigger>
          <mat-option [value]="station" *ngFor="let station of machineStations">{{station.name}}</mat-option>
        </mat-select>
      </div>
    </div>
    <ng-template #typeWait>
      <p class="text-center text-primary">
        <i class="fas fa-fw fa-lg fa-spin fa-circle-notch"></i>
      </p>
    </ng-template>
  </div>
  <div class="row m-0">
    <label>Status</label>
    <div class="col-12 p-0">
      <mat-select [(ngModel)]="machine.status" placeholder="Machine Status">
        <mat-select-trigger>
          {{getStatusText(machine.status)}}
        </mat-select-trigger>
        <mat-option [value]="status.statusValue" *ngFor="let status of machineStatuses">{{status.statusText}}</mat-option>
      </mat-select>
    </div>
  </div>
  <div class="row m-0">
    <label>Operating Length</label>
    <div class="input-group input-group-sm">
      <input [(ngModel)]="machine.operatingLength" required placeholder="Op Length" type="number" step="0.01" class="form-control form-control-sm" appStaticEdit [editable]="true" />
      <div class="input-group-append">
        <span class="input-group-text">
          in
        </span>
      </div>
    </div>
  </div>
  <div class="row m-0">
    <label>Operating Height</label>
    <div class="input-group input-group-sm">
      <input [(ngModel)]="machine.operatingHeight" required placeholder="Op Height" type="number" step="0.01" class="form-control form-control-sm" appStaticEdit [editable]="true" />
      <div class="input-group-append">
        <span class="input-group-text">
          in
        </span>
      </div>
    </div>    
  </div>
  <div class="row m-0">
    <label>Operating Thickness</label>
    <div class="input-group input-group-sm">
      <input [(ngModel)]="machine.operatingThickness" required placeholder="Op Thickness" type="number" step="0.01" class="form-control form-control-sm" appStaticEdit [editable]="true" />
      <div class="input-group-append">
        <span class="input-group-text">
          in
        </span>
      </div>
    </div>    
  </div>
  <div *ngIf="machine.operationType; else typeWait">
    <div class="row m-0" *ngFor="let item of machine.operationType.extendedFields | keyvalue">
      <label>{{item.key}}</label>
      <input [attr.type]="item.value" [(ngModel)]="machine.extendedData[item.key]" [placeholder]="item.key" class="form-control form-control-sm" appStaticEdit [editable]="true" />
    </div>
  </div>
</div>
