<button [@enterAnimation] *ngIf="allDone" mat-fab color="success" class="extended-fab-button position-fixed bg-danger text-white" style="width: 150px; bottom: 25px; right: calc(50% - 75px); z-index: 1030"
(click)="finish()"
>
  <mat-icon>check</mat-icon>
  <span class="extended-fab-button__text">Finish</span>
</button>

<loading *ngIf="saving">Saving...</loading>
<loading *ngIf="loading"></loading>

<mat-sidenav-container>
  <mat-sidenav disableClose #sidenav mode="over" position="end">
    <upload-file *ngIf="showEditor=='document'" (documentsLoaded)="addDocuments($event)" [sidenav]="sidenav"></upload-file>
  </mat-sidenav>
  <mat-sidenav-content>
    <mat-card style="min-height: 92vh;" *ngIf="ticket && record">
      <mat-card-content>
        <div class="d-flex flex-column" style="min-height: calc(92vh - 32px);">
          <div style="margin-bottom: 16px; padding: 0 16px;">
            <quality-assign
              class="my-1"
              [record]="ticket"
              (startLoading)="loading = true;"
              (finishLoading)="this.getDetail();"
            ></quality-assign>
          </div>
          <div style="margin-bottom: 16px">
            <mat-card class="border mat-elevation-z0">
              <mat-card-title>Ticket Details</mat-card-title>
              <mat-card-content>
                <div class="row">
                  <div class="col-6">
                    <div class="form-group">
                      <label>Received By</label>
                      <div class="ticket-info">{{ record.receivedOrSentBy.fullName }}</div>
                    </div>
                  </div>
                  <div class="col-6">
                    <div class="form-group">
                      <label>Ticket Number</label>
                      <div class="ticket-info">{{ record.subticketNumber }}</div>
                    </div>
                  </div>
                  <div class="col-6">
                    <div class="form-group">
                      <label>Item</label>
                      <div class="ticket-info">
                        <b>{{ getItemName(record.inventoryItem) }}</b>
                        <item-type-chip class="ml-1" [item]="record.inventoryItem"></item-type-chip>
                      </div>
                    </div>
                  </div>
                  <div class="col-6" *ngIf="record.purchaseOrder.vendor">
                    <div class="form-group">
                      <label>Vendor</label>
                      <div class="ticket-info">
                        <span class="d-inline-block mr-2">{{ record.purchaseOrder.vendor.name }}</span>
                        <button *ngIf="record.purchaseOrder.latestDocumentId" mat-raised-button color="primary" class="ml-1"
                        (click)="openPurchaseOrderDocument()"
                        >
                          <mat-icon matPrefix>receipt</mat-icon>
                          View Purchase Order
                        </button>
                      </div>
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="form-group">
                      <label>Inventory Lot</label>
                      <div class="d-flex align-items-center">
                        <mat-chip class="bg-dark text-white">
                          {{ ticket.shippingTicketPlaceLocation.inventoryItemLocation.internalLotNumber || 'No Lot Number' }}
                        </mat-chip>
                        <div class="mx-1"><b>{{ ticket.shippingTicketPlaceLocation.quantity }}</b> items in</div>
                        <b *ngIf="!locationParents">
                          {{ ticket.shippingTicketPlaceLocation.inventoryItemLocation.inventoryLocation.name }}
                          <i class="fa fa-spin fa-circle-notch text-primary mx-1"></i>
                        </b>
                        <div class="d-flex align-items-center small" *ngIf="locationParents">
                          <ng-container *ngFor="let ploc of locationParents; last as last">
                            <div>{{ ploc.name }}</div>
                            <mat-icon class="mat-icon-rtl-mirror mx-1" *ngIf="!last">
                              chevron_right
                            </mat-icon>
                          </ng-container>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </mat-card-content>
            </mat-card>
          </div>
          <div class="row flex-grow-1" *ngIf="checklist">
            <div class="col-12">
              <mat-card class="h-100 border mat-elevation-z0" *ngIf="checklist">
                <mat-card-title>Checklist</mat-card-title>
                <mat-stepper orientation="vertical" style="margin: 0 -16px;" [linear]="false">
                  <mat-step #firstStep label="Review paperwork" [completed]="checklist['paperwork']">
                    <h5>Scan paperwork below</h5>
                    <div class="row form-group p-3">
                      <div class="col-12 p-0 bg-light">
                        <h5 class="w-100 p-2 bg-secondary">Documents</h5>
                        <div class="p-2">
                          <file-display-grid (delete)="deleteDocument($event)" [editable]="true" [files]="ticket.qualityTicketDocuments"></file-display-grid>
                          <p *ngIf="ticket.qualityTicketDocuments.length==0" class="text-muted text-center"><em>-- No Documents --</em></p>
                          <a class="btn btn-badge bg-success has-icon float-right" title="Add Document" (click)="showEditor = 'document'; sidenav.open()"><i class="fas fa-plus mt-2"></i></a>
                        </div>
                      </div>
                    </div>
                    <mat-checkbox [(ngModel)]="checklist['paperwork']">
                      <div>All paperwork has been scanned and matches the relevant purchase order</div>
                      <div class="small text-muted font-italic">You can view a copy of the purchase order above if it is available.</div>
                    </mat-checkbox>
                  </mat-step>
                  <mat-step label="Input inventory data">
                      <p>Please input heat lot number and expiration date, if applicable</p>
                      <div class="row">
                        <div class="col-6">
                          <mat-form-field appearance="outline" class="w-100">
                            <mat-label>Heat Lot Number</mat-label>
                            <input type="text" matInput [(ngModel)]="heatLot">
                          </mat-form-field>
                        </div>
                        <div class="col-6">
                          <date-picker [(date)]="expirationDate" [placeholder]="'Expiration Date'" appearance="outline" [editable]="true"></date-picker>
                        </div>
                      </div>
                  </mat-step>
                  <mat-step label="Verify quantity" [completed]="checklist['quantity']">
                    <mat-checkbox [(ngModel)]="checklist['quantity']">
                      <div>The quantity of items in the lot is as listed (<b>{{ this.ticket.shippingTicketPlaceLocation.quantity }}</b>)</div>
                    </mat-checkbox>
                  </mat-step>
                  <mat-step label="Compare to vendor certificate" [completed]="checklist['cert']">
                    <ul>
                      <li *ngFor="let cert of record.purchaseOrder.vendor.vendorCertificates">
                        {{ cert.certificateName }}
                        <ul>
                          <li *ngFor="let doc of cert.vendorCertificateDocuments">
                            <a target="_blank" [href]="'/document/' + doc.documentDocumentId">{{doc.document?.name}}</a>
                          </li>
                        </ul>
                      </li>
                    </ul>
                    <mat-checkbox [(ngModel)]="checklist['cert']">
                      <div>Delivered items match vendor certificate (if any)</div>
                    </mat-checkbox>
                  </mat-step>
                  <mat-step label="Visual inspection of outside process" *ngIf="record.inventoryItem.productId" [completed]="checklist['visual']">
                    <mat-checkbox [(ngModel)]="checklist['visual']">
                      <div>Visual inspection of part matches the outside process described in the purchase order</div>
                      <div class="small text-muted font-italic">You can view a copy of the purchase order above if it is available.</div>
                    </mat-checkbox>
                  </mat-step>
                </mat-stepper>
              </mat-card>
            </div>
          </div>
        </div>
      </mat-card-content>
    </mat-card>
  </mat-sidenav-content>
</mat-sidenav-container>


