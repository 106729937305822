<div class="row">
  <div class="col-6">

    <div class="form-group">
      <label>Doing Business As</label>
      <company-search [editing]="editing" [(ngModel)]="record.company" (ngModelChange)="record.companyId = $event?.companyId"></company-search>
    </div>
    <div class="form-group">
      <label>Customer</label>
      <customer-select (addCustomer)="showAddCustomer($event)" [editable]="editing" [customer]="record.customer" (customerChange)="assignCustomer($event)"></customer-select>
    </div>
    <div class="form-group">
      <label>Order Number</label>
      <span class="d-block small" [ngClass]="{'text-muted':record.orderNumber==null}">{{record.orderNumber || '-- Not Yet Assigned  --'}}</span>
    </div>
    <div class="form-group">
      <label>Date Received</label>
      <date-picker [placeholder]="'Date Received'" [editable]="editing" [(date)]="record.receivedDate"></date-picker>
    </div>
    <div class="form-group">
      <label>Date Created</label>
      <span class="d-block small" [ngClass]="{'text-muted':record.createdDate==null}">{{(record.createdDate | date) || 'N / A'}}</span>
    </div>
  </div>
  <div class="col-6">
    <div class="form-group">
      <label>Contact</label>
      <p class="small font-italic text-muted" *ngIf="record.customerId==null && !customerLoading">Choose a Customer First...</p>
      <p class="small font-italic text-muted" *ngIf="customerLoading"><i class="fa fa-spin fa-spinner text-primary"></i> Loading...</p>
      <customer-contact-select *ngIf="record.customerId!=null && !customerLoading" (addContact)="showAddContact($event)" (selectedContactChange)="setContact($event)" [editable]="editing" [customerContactList]="record.customer?.customerContacts" [selectedContact]="record.customerContact"></customer-contact-select>
    </div>
    <div class="form-group" *ngIf="record.discriminator!='SalesOrder';else custPO">
      <label>Customer RFQ#</label>
      <input placeholder="Customer RFQ#" type="text" max="100" class="form-control form-control-sm" appStaticEdit [editable]="editing" [(ngModel)]="record.customerRfqnumber" />
    </div>
    <ng-template #custPO>
      <div class="form-group">
        <label>Customer PO#</label>
        <input placeholder="Customer PO#" type="text" max="100" class="form-control form-control-sm" appStaticEdit [editable]="editing" [(ngModel)]="record.poNumber" />
      </div>
    </ng-template>
    <div class="form-group">
      <label>
        Quote Required By
        <span *ngIf="record.requiredDate!=null" class="badge required p-1 pl-2 pr-2" [ngClass]="getRequiredColor(record.requiredDate)">
          <span class="fa-lg">{{weeksLeft(record.requiredDate, true)||'--'}} Week(s) {{(weeksLeft(record.requiredDate, false)||0) >= 0 ? 'From Today':'Overdue'}}</span>
        </span>
      </label>
      <date-picker [placeholder]="'Date Required'" [editable]="editing" [(date)]="record.requiredDate"></date-picker>
    </div>
    <div class="form-group" *ngIf="record.discriminator=='Estimate'">
      <label>
        Est. Estimate Completion Date
        <span *ngIf="record.estimatedEstimateCompletionDate!=null" class="badge required p-1 pl-2 pr-2" [ngClass]="getRequiredColor(record.estimatedEstimateCompletionDate)">
          <span class="fa-lg">{{weeksLeft(record.estimatedEstimateCompletionDate, true)||'--'}} Week(s) {{(weeksLeft(record.estimatedEstimateCompletionDate, false)||0) >= 0 ? 'From Today':'Overdue'}}</span>
        </span>
      </label>
      <date-picker [placeholder]="'Est. Estimate Completion Date'" [editable]="editing" [(date)]="record.estimatedEstimateCompletionDate"></date-picker>
    </div>
    <div class="form-group">
      <label>Sales Representative</label>
      <span class="d-block small" [ngClass]="{'text-muted':record.customer?.salesPerson?.fullName==null}">{{record.customer?.salesPerson?.fullName || '-- No Sales Representative Assigned  --'}}</span>
    </div>
    <div class="form-group" *ngIf="record.status!==20">
      <label>Date Quote Issued</label>
      <span class="d-block small" [ngClass]="{'text-muted':record.issuedDate==null}">{{(record.issuedDate | date) || 'N / A'}}</span>
    </div>

  </div>
  <ng-template #noneSelected>
    <div class="text-muted">--</div>
  </ng-template>
  <div class="col-12 container-fluid">
    <h5>Customer Address</h5>
    <div class="row">
      <div class="form-group col">
        <label>Selected Address</label>
        <p class="small font-italic text-muted" *ngIf="customerLoading"><i class="fa fa-spin fa-spinner text-primary"></i> Loading...</p>
        <mat-select [disabled]="!record.customerId" *ngIf="editing && !!record.customerId && !customerLoading"
          [(ngModel)]="record.shipToAddressId" (ngModelChange)="onShipToAddressChange($event)">
          <mat-option *ngFor="let cAddress of record?.customer?.customerAddresses" [value]="cAddress.address.addressId">
            {{ cAddress.address.nickname }}
          </mat-option>
        </mat-select>
        <div class="text-muted font-italic" *ngIf="editing && !record.customerId">
          -- Please select a customer first. --
        </div>
        <div *ngIf="!editing">
          <div *ngIf="record.shipToAddress?.nickname; else noneSelected">
            {{record.shipToAddress.nickname}}</div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="form-group col">
        <label>Address</label>
        <div *ngIf="record.shipToAddress?.streetAddress; else noneSelected">
          {{record.shipToAddress.streetAddress}}</div>
        <div *ngIf="record.shipToAddress?.streetAddress2">
          {{record.shipToAddress.streetAddress2}}</div>
      </div>
    </div>
    <div class="row">
      <div class="col-6">
        <div class="form-group">
          <label>City</label>
          <div *ngIf="record.shipToAddress?.city; else noneSelected">
            {{record.shipToAddress.city}}</div>
        </div>
      </div>
      <div class="col-2">
        <div class="form-group">
          <label>State</label>
          <div *ngIf="record.shipToAddress?.state; else noneSelected">
            {{record.shipToAddress.state}}</div>
        </div>
      </div>
      <div class="col-4">
        <div class="form-group">
          <label>Postal Code</label>
          <div *ngIf="record.shipToAddress?.postalCode; else noneSelected">
            {{record.shipToAddress.postalCode}}</div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-6 form-group">
        <label>Phone Number</label>
        <div *ngIf="record.shipToAddress?.phoneNumber; else noneSelected">
          {{record.shipToAddress.phoneNumber}}</div>
      </div>
      <div class="col-6 form-group">
        <label>Fax</label>
        <div *ngIf="record.shipToAddress?.faxNumber; else noneSelected">
          {{record.shipToAddress.faxNumber}}</div>
      </div>
    </div>
  </div>
</div>
<div class="row pb-2">
  <div class="col-12 p-0 bg-light">
    <h5 class="w-100 p-2 bg-secondary">Project / Order Description</h5>
    <div class="p-1">
      <textarea rows="1" appStaticEdit [editable]="editing" class="form-control form-control-sm" [(ngModel)]="record.projectDescription" placeholder="Project description (optional) - will appear on invoice."></textarea>
    </div>
  </div>
</div>
<div class="row pb-2">
  <div class="col-6 pl-0 pr-1 bg-light">
    <h5 class="w-100 p-2 pl-1 bg-secondary">Notes<small class="float-right">(Public)</small></h5>
    <div class="p-1">
      <textarea rows="6" appStaticEdit [editable]="editing" class="form-control form-control-sm" [(ngModel)]="record.publicNotes" placeholder="Client visible notes can go here"></textarea>
    </div>
  </div>
  <div class="col-6 pr-0 bg-light">
    <h5 class="w-100 p-2 bg-secondary">Notes<small class="float-right">(Private)</small></h5>
    <div class="p-1">
      <textarea rows="6" appStaticEdit [editable]="editing" class="form-control form-control-sm" [(ngModel)]="record.privateNotes" placeholder="Internal only notes can go here"></textarea>
    </div>
  </div>
  <div class="col-12 pl-0 pr-1 bg-light">
    <h5 class="w-100 p-2 pl-1 bg-secondary">Additional Project Considerations</h5>
    <div class="p-1">
      <textarea rows="6" appStaticEdit [editable]="editing" class="form-control form-control-sm" [(ngModel)]="record.quoteNotes" placeholder="Notes to show on the printed quote can go here"></textarea>
    </div>
  </div>
</div>
<div class="row">
  <div class="col-12 p-0 bg-light">
    <h5 class="w-100 p-2 bg-secondary">Documents</h5>
    <div class="p-2">
      <file-display-grid (delete)="deleteDocument($event)" [editable]="editing" [files]="record.documents"></file-display-grid>
      <p *ngIf="record.documents.length==0" class="text-muted text-center"><em>-- No Documents --</em></p>
      <a *ngIf="editing" class="btn btn-badge bg-success has-icon float-right" title="Add Document" (click)="openDocumentWindow()"><i class="fas fa-plus mt-2"></i></a>
    </div>
  </div>
</div>
