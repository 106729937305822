import { Component, OnInit, OnChanges, SimpleChanges, Input } from '@angular/core';
import { ChartComponent } from '../../../common/components/chart/chart.component';
import { MaterialBidService } from '../../services/material-bid.service';

@Component({
  selector: 'price-history-chart',
  templateUrl: './price-history-chart.component.html',
  styleUrls: ['./price-history-chart.component.less']
})
export class PriceHistoryChartComponent implements OnInit, OnChanges {
  @Input() materialId: string = null;
  @Input() stationId: string = null;
  @Input() purchasedItemId: string = null;

  public chartLabels: string[];
  public chartData: any[];
  public chartOptions: any;

  constructor(private bidService: MaterialBidService) { }

  private setupOptions(): void {
    this.chartOptions = {
      responsive: true,
      legend: { display: false      },
      scales: {
        yAxes: [{
          ticks: {
            // Include a dollar sign in the ticks
            callback: function (value, _index, _values) {
              return '$' + (value < 10 ? (value.toFixed(2)) : (Math.floor(value)));
            },
            labels: {
              fontFamily: "'Roboto', sans-serif",
              fontWeight: '500',
              fontColor: 'red'
            }
          }
        }],
        xAxes: [
          {
            ticks: {
              labels: {
                fontFamily: "'Roboto', sans-serif",
                fontWeight: '500',
              fontColor: 'red'
            }
            }
          }
        ]
      }
    };
  }

  private setupLabels(): void {
    this.chartLabels = ['3 yr', '1 yr', '6 mo', '3 mo', '1 mo', '1 wk'];
  }

  public loading = false;
  private getChartData(): void {
    var newData: any[] = [
      {
        label: 'Min',
        data: [],
        fill: 1,
        borderColor: ChartComponent.getColor(5),
        backgroundColor: `${ChartComponent.getColor(1)}33`
      },
      {
        label: 'Max',
        data: [],
        fill: false,
        borderColor: ChartComponent.getColor(0)
      },
      {
        label: 'Avg',
        data: [],
        fill: false,
        borderColor: ChartComponent.getColor(1)
      }
    ];

    this.loading = true;
    if (this.materialId || this.stationId || this.purchasedItemId) {
      this.bidService.comparePricing(this.materialId, this.stationId, this.purchasedItemId).subscribe(data => {
        for (var i in data) {
          if (data[i] && data[i][0]) {
            newData[0].data.push(data[i][0].min);
            newData[1].data.push(data[i][0].max);
            newData[2].data.push(data[i][0].avg);
          }
        }
  
        this.chartData = newData;
      });
    }
    this.loading = false;
  }

  ngOnChanges(_: SimpleChanges): void {
    this.setupLabels();
    this.getChartData();
    this.setupOptions();
  }

  ngOnInit(): void {
    this.setupLabels();
    this.getChartData();
    this.setupOptions();
  }
}
