<div class="sortable-container">
  <div>
    <h5 class="bg-secondary text-center p-2">Current Departments</h5>
  </div>
  <ul class="sortable-list">
    <li *ngIf="!departmentList">
      <p class="text-center">
        <i class="text-primary fas fa-spin fa-2x fa-circle-notch"></i>
      </p>
    </li>
    <li *ngFor="let department of departmentList" (click)="select(department)">
      <div>
        <span>{{department.name}}</span>
        <span class="float-right small text-muted">{{department.floorBuildings.length}} Buildings</span>
      </div>
    </li>
  </ul>
</div>
