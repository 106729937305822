<mat-form-field appearance="outline" class="w-100 chip-field" [class.dense-field]="dense">
  <mat-label *ngIf="!dense">Quantities</mat-label>
  <mat-chip-list #chipList class="quantity-chip-list" [required]="required">
    <mat-chip
      class="quantity-chip"
      *ngFor="let quantity of value; index as i"
      [removable]="!readonly"
      (removed)="remove(i)"
      selected
      >
      {{quantity.value}}
      <button matChipRemove *ngIf="!readonly">
        <mat-icon>close</mat-icon>
      </button>
    </mat-chip>
    <input
      [name]="name"
      [readonly]="readonly"
      [matChipInputFor]="chipList"
      [matChipInputSeparatorKeyCodes]="separatorKeyCodes"
      [matChipInputAddOnBlur]="true"
      (matChipInputTokenEnd)="add($event)"
      autocomplete="off"
      >
  </mat-chip-list>
</mat-form-field>