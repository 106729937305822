<div class="container-fluid">
  <div class="row border">
    <search-bar class="w-100" [showManagerBar]="false" [(searchString)]="searchString" (search)="doSearch($event)"></search-bar>

    <div *ngIf="!results"><loading></loading></div>

    <search-result *ngIf="results" [results]="searchResults" class="w-100" (newPage)="getPage($event)" (selected)="onSelect($event)">
      <thead header-template>
        <tr class="text-uppercase text-muted">
          <th (click)="sortBy('name')">
            Customer/Dept
            <i [ngClass]="getSortIconFor('name')"></i>
          </th>
          <th (click)="sortBy('orderNum')">
            Order/Req #
            <i [ngClass]="getSortIconFor('orderNum')"></i>
          </th>
          <th (click)="sortBy('required')">
            Required
            <i [ngClass]="getSortIconFor('required')"></i>
          </th>
          <th>
            Needed For
          </th>
        </tr>
      </thead>
      <ng-template let-row>
        <td>{{row.fromName}}</td>
        <td class="text-muted">{{row.number}}</td>
        <td>
          <span *ngIf="row.requiredDate!=null" class="badge required" [ngClass]="getRequiredColor(row.requiredDate)">
            {{row.requiredDate | date}}
            <span class="d-block small">{{weeksLeft(row.requiredDate, true)||'--'}} Week(s) {{(weeksLeft(row.requiredDate, false)||0) >= 0 ? 'From Today':'Overdue'}}</span>
          </span>
        </td>
        <td>
            <mat-chip-list>
                <mat-chip *ngIf="row.needsMaterials">Materials</mat-chip>
                <mat-chip *ngIf="row.needsOutsideProcesses">Processes</mat-chip>
                <mat-chip *ngIf="row.needsPurchasedItems">Hardware</mat-chip>
                <mat-chip *ngIf="false">Paint</mat-chip>
            </mat-chip-list>
        </td>
      </ng-template>
    </search-result>
  </div>

</div>
