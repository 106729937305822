import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { MessageService } from '../services/message.service';
import { catchError } from 'rxjs/operators';
import { ErrorHandlerService } from '../services/errorHandler.service'
import { httpService } from './http.service';

@Injectable({
  providedIn: 'root',
})
export class CounterService extends httpService {
  private apiBase: string = 'api/counter';
  private apiUrl: string;

  constructor(errorHandler: ErrorHandlerService, messages: MessageService, private http: HttpClient, @Inject('BASE_URL') private baseUrl: string) {
    super(errorHandler, messages);
    this.serviceName = "Counter";

    this.apiUrl = this.baseUrl + this.apiBase;
  }

  next(prefix: string): Observable<string> {
    return this.http.get<string>(this.apiUrl + `/getNext/${prefix}`).pipe(
      catchError(this.handleError<any>("Get Next Prefix", null))
    );
  }
}
