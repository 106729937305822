import { Injectable } from '@angular/core';
import IWidgetData from './widgets/interface';
import { UserService } from '../../common/services/user.service';
import allWidgets from './widgets';
import { User } from '../../common/resources/user';
import { BehaviorSubject, Observable } from 'rxjs';
import { take, filter, map, tap } from 'rxjs/operators'

export interface IWidgetInstance {
  widget: string
  dimensions?: {
    width?: number
    height?: number
  }
}

@Injectable({
  providedIn: 'root'
})
export class WidgetService {

  availableWidgets: BehaviorSubject<IWidgetData[]> = new BehaviorSubject([])

  constructor(private userService: UserService) {

    userService.user.subscribe(user => {
      this.availableWidgets.next(
        allWidgets.filter(widget => widget.access.some(perm => userService.canAccess(perm, user)) )
      )
    })


  }

  key(id: string): Observable<string> {
    return this.userService.user.pipe(filter(user => !!user.userId), map(user => `widgets-${id}-${user.userId}`))
  }

  widgetById(id: string) {
    return allWidgets.find(x => x.id === id)
  }

  /**
  * Gets widgets for a given key from localStorage (from API in future.)
  **/
  async getWidgets(id: string): Promise<IWidgetInstance[]> {
    // not sure how idiomatic using toPromise here is, but I wanted to avoid getting into observable hell
    const key = await this.key(id).pipe(take(1)).toPromise()
    // artificial delay to test loading spinner
    // await new Promise(resolve => setTimeout(() => resolve(), 5000))
    return JSON.parse(
      localStorage.getItem(key)
    ) || []
  }

  /**
  * Saves widgets for a given key to localStorage (to API in future.)
  **/
  async setWidgets(id: string, val: IWidgetInstance[]): Promise<void> {
    const key = await this.key(id).pipe(take(1)).toPromise()
    // artificial delay to test loading spinner
    // await new Promise(resolve => setTimeout(() => resolve(), 1000))
    localStorage.setItem(
      key,
      JSON.stringify(val)
    )
    return
  }

}
