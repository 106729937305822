import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { UtilityService } from '../../../common/services/utility.service';
import { InventoryItemLocation } from '../../../inventory/resources/inventory-item-location';
import { InventoryTreePickerComponent } from '../../../shipping/components/inventory-tree-picker/inventory-tree-picker.component';
import { Building } from '../../resources/building';
import { ToolCribTicket } from '../../resources/tool-crib-ticket';
import { FloorService } from '../../services/floor.service';

@Component({
  selector: 'tool-crib-fulfill',
  templateUrl: './tool-crib-fulfill.component.html',
  styleUrls: ['./tool-crib-fulfill.component.less']
})
export class ToolCribFulfillComponent implements OnInit, OnChanges {

  @Input() ticket: ToolCribTicket;
  @Input() building: Building;
  @Output() closed = new EventEmitter<void>();
  @Output() done = new EventEmitter<void>();
  @ViewChild('inventoryTreeSearch', { static: true }) inventoryTreeSearch: InventoryTreePickerComponent;
  public locationData: InventoryTreePickerComponent['locationsPicked'] = {};
  public saving = false;

  constructor(
    private floorService: FloorService,
    private utilityService: UtilityService
  ) { }


  public get locations() {
    return Object.values(this.locationData || {}).filter(l => l.amt > 0);
  }

  public get totalLocationsPicked(): number {
    return this.locations.reduce((acc, x) => acc + x.amt, 0);
  }

  public clearLocation(location: InventoryItemLocation) {
    if (this.locationData[location.inventoryItemLocationId]) this.locationData[location.inventoryItemLocationId].amt = 0;
  }

  public getWarnings(location: InventoryItemLocation): string[] {
    if (!this.inventoryTreeSearch) return [];
    else return this.inventoryTreeSearch.getWarnings(location);
  }

  public close() {
    this.closed.emit();
  }

  public async fulfill() {

    let message = `
    <p>Please confirm moving <b>${this.totalLocationsPicked}</b> units of <b>${this.ticket.inventoryItem.name}</b> to <b>${this.ticket.machineAssignment.machine.name}</b>.</p>
    `;

    const warningsExist = this.locations.some(l => this.getWarnings(l.loc).length > 0);
    if (warningsExist) {
      message += `<b class="text-danger"><i class="fas fa-warning mr-1"></i>Some of the lots being picked from have warnings. Please make sure to check them before confirming.</b>`
    }

    const r = await this.utilityService.showConfirmationPromise('Fulfill Tool Crib Ticket', message, warningsExist ? 5 : null);

    if (!r) return;
    
    this.saving = true;
    await this.floorService.fulfillToolCribTicket(this.ticket, this.locations.map(l => ({ inventoryItemLocationId: l.loc.inventoryItemLocationId, amount: l.amt }))).toPromise();
    this.saving = false;
    this.done.emit();
  }

  
  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.ticket && ((changes.ticket.previousValue && changes.ticket.previousValue.toolCribTicketId) !== (changes.ticket.currentValue && changes.ticket.currentValue.toolCribTicketId))) {
      this.locationData = {};
      this.inventoryTreeSearch.doFilter('');
    }
  }

  public locationControl = new UntypedFormControl({}, () => {
    if (this.ticket && (this.totalLocationsPicked || 0) < this.ticket.quantityRequired) return { location: true };
    else return null;
  });

}
