<div class="position-relative">
  <span *ngIf="editable" class="position-absolute dropdown-indicator" (click)="toggleDropdown()"><i class="fas"
      [ngClass]="{'text-muted fa-chevron-down':!searching, 'fa-lg fa-circle-notch fa-spin text-info': searching}"></i></span>
  <input *ngIf="required" required minlength="6" (focus)="onFocus()" (change)="setSelection()"
    #autoCompleteTrigger="matAutocompleteTrigger" #inputText="ngModel" matInput placeholder="Certificate Name"
    appStaticEdit [editable]="editable" (change)="setSelection()" [ngModel]="name"
    class="form-control form-control-sm" (keyup)="getList()" type="text" max="100" [matAutocomplete]="list" />
  <input *ngIf="!required" (change)="setSelection()" (focus)="onFocus()"
    #autoCompleteTrigger="matAutocompleteTrigger" #inputText="ngModel" matInput placeholder="Certificate Name"
    appStaticEdit [editable]="editable" (change)="setSelection()" [ngModel]="name"
    class="form-control form-control-sm" (keyup)="getList()" type="text" max="100" [matAutocomplete]="list" />
  <mat-autocomplete (opened)="getList()" autoActiveFirstOption #list="matAutocomplete"
    (optionSelected)="setSelection()">
    <mat-option disabled *ngIf="searching">
      <span class="text-center text-muted font-italic">
        <i class="fas fa-spin fa-circle-notch text-primary d-inline-flex mr-2"></i>
        Searching...
      </span>
    </mat-option>
    <mat-option *ngFor="let item of items" [value]="item">
      <span class="name">{{item}}</span>
    </mat-option>
  </mat-autocomplete>
</div>
