<div class="container-fluid">
  <!-- <a class="position-fixed fixed-bottom btn btn-badge" title="Create New Record" routerLink="/inventory/new">+</a> -->

  <div class="row border">
    <search-bar class="w-100" [(searchString)]="searchString" [showManagerBar]="hasPermission('QAManager')" [managerView]="managerView" (managerViewChanged)="setManagerView($event)" (search)="doSearch($event)">
    </search-bar>

    <div *ngIf="!results">
      <loading></loading>
    </div>


    <search-result *ngIf="results" [results]="searchResults" class="w-100" (newPage)="getPage($event)"
      (selected)="onSelect($event)">
      <thead header-template>
        <tr class="text-uppercase text-muted">
          <th (click)="sortBy('type')">
            Item Type
            <i [ngClass]="getSortIconFor('type')"></i>
          </th>
          <th (click)="sortBy('ticket')">
            Ticket Number
            <i [ngClass]="getSortIconFor('ticket')"></i>
          </th>
          <th (click)="sortBy('subticket')">
            Relevant Ticket
            <i [ngClass]="getSortIconFor('subticket')"></i>
          </th>
          <th (click)="sortBy('required')">
            Date Required
            <i [ngClass]="getSortIconFor('required')"></i>
          </th>
          <th (click)="sortBy('assigned')">
            Assigned To
            <i [ngClass]="getSortIconFor('assigned')"></i>
          </th>
          <th class="text-right">
            <ticket-type-filter [(selection)]="filterTypes" (selectionChange)="search()"></ticket-type-filter>
          </th>
        </tr>
      </thead>
      <ng-template let-row>
        <td>
          <mat-chip class="text-white text-uppercase font-weight-bold"
            matTooltipPosition="above"
            disableRipple
            [ngClass]="getTypeColorClass(row)"
          >
            <mat-icon class="mr-2">
              {{ getTypeIcon(row) }}
            </mat-icon>
            <span>{{getTypeText(row)}}</span>
          </mat-chip>
        </td>
        <td>{{row.qualityTicketNumber}}</td>
        <td>{{getSubticketNumber(row)}}</td>
        <td class="text-center">
          <span *ngIf="row.dateRequired!=null" class="badge required" [ngClass]="getRequiredColor(row.dateRequired)">
            {{row.dateRequired | date}}
            <span class="d-block small">{{weeksLeft(row.dateRequired, true)||'--'}} Week(s) {{(weeksLeft(row.dateRequired, false)||0) >= 0 ? 'From Today':'Overdue'}}</span>
          </span>
        </td>
        <td [ngClass]="{'text-muted font-italic small': !row.assignedUser}">{{row.assignedUser?.fullName || '-- Not Assigned --'}}</td>
        <td></td>
      </ng-template>
    </search-result>
  </div>
</div>
