<mat-form-field appearance="outline" [class.disabled]="disabled">
    <mat-label>{{ label }}</mat-label>
  <mat-select [ngModel]="status" (ngModelChange)="statusChange.emit($event)" [ngModelOptions]="{standalone: true}">
      <mat-select-trigger>
        <div style="pointer-events: none; padding: 2px 8px; height: 32px;" class="rounded" class="ml-auto rounded text-white d-flex align-items-center" [ngClass]="getStatusColorClass(status)">
          <mat-icon class="mr-1" [svgIcon]="getStatusIcon(status)"></mat-icon>
          <span class="button-text small">
            {{ getStatusText(status) }}
          </span>
        </div>
      </mat-select-trigger>
      <mat-option *ngFor="let num of possibleValues" [value]="num">
        <div style="pointer-events: none; padding: 2px 8px; height: 32px;" class="rounded" class="ml-auto rounded text-white d-flex align-items-center" [ngClass]="getStatusColorClass(num)">
          <mat-icon class="mr-1" [svgIcon]="getStatusIcon(num)"></mat-icon>
          <span class="button-text small">
            {{ getStatusText(num) }}
          </span>
        </div>
      </mat-option>
  </mat-select>
</mat-form-field>
