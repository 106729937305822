<div class="pt-3">
  <a class="position-fixed fixed-bottom btn btn-badge bg-danger has-icon" title="Close" (click)="close()"><i class="fas fa-times"></i></a>

  <loading *ngIf="saving" content="Saving..."></loading>

  <div class="container-fluid pb-4">

    <div class="p-0 m-0 row">
      <div class="col-4">
        <paint-list (selected)="select($event)"></paint-list>
        <div class="mt-2 text-right">
          <button class="btn btn-success" (click)="addPaint()">
            <i class="fas fa-fw la-lg fa-plus"></i>
            Add New
          </button>
        </div>
      </div>
      <div class="col-8">
        <div *ngIf="selectedPaint; else selectFirst">
          <div class="form-group">
            <label>Paint Name</label>
            <input #name="ngModel" minlength="3" [(ngModel)]="selectedPaint.name" required placeholder="Paint Name" type="text" max="100" class="form-control form-control-lg" appStaticEdit [editable]="true" />
          </div>
          <div class="container-fluid">
            <div class="row">
              <div class="col-8">
                <div class="form-group">
                  <label>Purchase Volume</label>
                  <mat-select class="form-control-sm" [(value)]="selectedPaint.purchaseVolume">
                    <mat-option *ngFor="let p of volumeList" [value]="p">{{getVolume(p)}}</mat-option>
                  </mat-select>
                </div>
                <div class="form-group">
                  <label>Coverage</label>
                  <div class="input-group input-group-sm">
                    <input type="number" appStaticEdit [editable]="true" min="0.0" placeholder="Coverage" [(ngModel)]="selectedPaint.coverage" class="form-control form-control-sm form-inline text-right">
                    <div class="input-group-append">
                      <span class="input-group-text">
                        in<sup>2</sup>/ {{getSize(selectedPaint)}}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-4">
                <div class="form-group">
                  <span class="swatch" [cpOutputFormat]="'rgba'" [cpPosition]="'bottom'" [cpCancelButtonClass]="'btn btn-default btn-xs mr-2 border'" [cpOKButtonClass]="'btn btn-primary btn-xs'" [cpOKButton]="true" [cpCancelButton]="true" [style.background]="selectedPaint.sampleColor" [(colorPicker)]="selectedPaint.sampleColor" [cpAlphaChannel]="'disabled'"></span>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <div class="form-group">
                  <label>Manufacturer</label>
                  <input [(ngModel)]="selectedPaint.manufacturer" placeholder="Manufacturer" type="text" class="form-control form-control-sm" appStaticEdit [editable]="true" />
                </div>
              </div>
              <div class="col">
                <div class="form-group">
                  <label>Series</label>
                  <input [(ngModel)]="selectedPaint.series" placeholder="Series" type="text" class="form-control form-control-sm" appStaticEdit [editable]="true" />
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <div class="form-group">
                  <label>Color Code</label>
                  <input [(ngModel)]="selectedPaint.colorCode" placeholder="Color Code" type="text" class="form-control form-control-sm" appStaticEdit [editable]="true" />
                </div>
              </div>
              <div class="col">
                <div class="form-group">
                  <label>Sheen</label>
                  <mat-select class="form-control-sm" [(value)]="selectedPaint.sheen">
                    <mat-option *ngFor="let sheen of paintSheens; let i = index" [value]="i">
                      {{sheen}}
                    </mat-option>
                  </mat-select>
                </div>
              </div>
            </div>
          </div>
          <div class="float-right mt-4 pt-4">
            <button class="btn btn-danger mr-3" *ngIf="canRemove()" (click)="remove()">
              <i class="fas fa-fw fa-lg fa-minus-circle"></i>
              Delete
            </button>
            <button class="btn btn-success" (click)="save()">
              <i class="fas fa-fw fa-lg fa-save"></i>
              Save
            </button>
          </div>
        </div>
        <ng-template #selectFirst>
          <div>
            <p class="text-muted text-center font-italic p-5">-- Select a Paint to view its detiails --</p>
          </div>
        </ng-template>
      </div>
    </div>
  </div>
</div>
