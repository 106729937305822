import { Component, Input } from '@angular/core';

@Component({
  selector: 'loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.less']
})
export class LoadingComponent {
  @Input() title?: string = "COTS 2";
  @Input() content?: string = "Loading...";

  constructor() { }
}
