import { Component, ViewChild } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { NavigationService } from '../../../common/services/navigation.service';

@Component({
  selector: 'admin-portal',
  templateUrl: './admin-portal.component.html',
  styleUrls: ['./admin-portal.component.less']
})
export class AdminPortalComponent {
  public showEditor: string = null;
  @ViewChild('sidenav', { static: true }) sidenav: MatSidenav;

  constructor(private navService: NavigationService) {
    this.navService.setPageTitle("Admin Portal");
  }
  
  public closeSideNav(): void {
    this.showEditor = null;
    this.navService.popBreadCrumb();
  }

  public showSidebar(sectionName: string): void {
    this.showEditor = sectionName;
    this.sidenav.open();
  }
  
  public docrash() {
    const xyz: any = undefined;
    xyz();
  }
}
