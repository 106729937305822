<h2 mat-dialog-title>Rejecting This Order</h2>
<mat-dialog-content class="mat-typography">
  <p>Please explain why this order was rejected, and what needs to be done to fix it.</p>
  <p>
    <textarea class="form-control form-control-sm" #note placeholder="Please tell us what needs to be done for this order to proceed..."></textarea>
  </p>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button class="btn btn-default btn-sm mr-3" [mat-dialog-close]="{note: note.value}">Okay</button>
</mat-dialog-actions>
