import { Component } from '@angular/core';
import { User } from '../../../common/resources/user';
import { UserService } from '../../../common/services/user.service';
import { NavigationService } from '../../../common/services/navigation.service';

@Component({
  selector: 'cots-nav-menu',
  templateUrl: './nav-menu.component.html',
  styleUrls: ['./nav-menu.component.less']
})
export class CotsNavMenuComponent {
  isExpanded = true;
  user: User;

  constructor(private navService: NavigationService, private userService: UserService) { }

  collapse() {
    this.navService.toggleExpanded(false);
  }

  ngOnInit() {
    this.navService.isExpanded.subscribe(state => this.isExpanded = state);
    this.userService.user.subscribe(u => this.user = u);
  }

  toggle() {
    this.navService.toggleExpanded();
  }

  canAccess(area: string): boolean {
    if (this.user != null && this.user.userId != null)
      return this.userService.canAccess(area);

    return false;
  }
}
