import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { MessageService } from '../../common/services/message.service';
import { catchError, tap } from 'rxjs/operators';
import { ErrorHandlerService } from '../../common/services/errorHandler.service'
import { SearchResult } from '../../common/resources/search-result';
import { httpService } from '../../common/services/http.service';
import { MicroTicket, MicroTicketComment, MicroTicketStatus } from '../resources/microticket';
import { MessageType } from '../../common/resources/message';
import { SearchData, SearchListNewComponent } from '../../common/components/search-list-new/search-list-new.component';
import { VirtualDocument } from '../../common/resources/virtual-document';

@Injectable({
  providedIn: 'root',
})
export class MicroTicketService extends httpService {
  private apiBase: string = 'api/microticket';
  private apiUrl: string;

  constructor(errorHandler: ErrorHandlerService, private messages: MessageService, private http: HttpClient, @Inject('BASE_URL') private baseUrl: string) {
    super(errorHandler, messages);
    this.serviceName = "Microticket";

    this.apiUrl = this.baseUrl + this.apiBase;
  }

  public getTickets(relatedTicketId: string, filterKey: string = null): Observable<MicroTicket[]> {
    return this.http.get<MicroTicket[]>(this.apiUrl + '/getTickets/' + relatedTicketId, { params: {
        filterKey: filterKey ? filterKey : undefined
    }}).pipe(
      catchError(this.handleError<any>("Get Microtickets Search Results", null))
    );
  }

  public searchTicketsGlobal(data: SearchData): Observable<MicroTicket[]> {
    const params = SearchListNewComponent.searchDataToParams(data);
    return this.http.get<MicroTicket[]>(this.apiUrl + '/search' + '?' + params.toString()).pipe(
      catchError(this.handleError<any>("Get Microtickets Search Results", null))
    );
  }

  public createTicket(data: MicroTicket): Observable<MicroTicket> {
    return this.http.post<MicroTicket>(this.apiUrl + '/new', { ...data, carbonCopies: data.carbonCopiesList.join(',') }).pipe(
      tap(_ => {
        this.messages.add("Microticket Service: Create Ticket", MessageType.SUCCESS, true);
      }),
      catchError(this.handleError<any>("Save Microticket", null))
    );
  }
  public saveTicket(data: MicroTicket): Observable<MicroTicket> {
    return this.http.post<MicroTicket>(this.apiUrl, { ...data, carbonCopies: data.carbonCopiesList.join(',') }).pipe(
      tap(_ => {
        this.messages.add("Microticket Service: Edit Ticket", MessageType.SUCCESS, true);
      }),
      catchError(this.handleError<any>("Save Microticket", null))
    );
  }

  public closeTicket(data: MicroTicket): Observable<MicroTicket> {
    return this.http.post<MicroTicket>(this.apiUrl + '/close/' + data.microTicketId, null).pipe(
      tap(_ => {
        this.messages.add("Microticket Service: Close Ticket", MessageType.SUCCESS, true);
      }),
      catchError(this.handleError<any>("Close Microticket", null))
    );
  }

  public markTicketInProgress(data: MicroTicket): Observable<MicroTicket> {
    return this.http.post<MicroTicket>(this.apiUrl + '/markInProgress/' + data.microTicketId, null).pipe(
      tap(_ => {
        this.messages.add("Microticket Service: Update Ticket", MessageType.SUCCESS, true);
      }),
      catchError(this.handleError<any>("Update Microticket", null))
    );
  }

  public markTicketComplete(data: MicroTicket): Observable<MicroTicket> {
    return this.http.post<MicroTicket>(this.apiUrl + '/markComplete/' + data.microTicketId, null).pipe(
      tap(_ => {
        this.messages.add("Microticket Service: Update Ticket", MessageType.SUCCESS, true);
      }),
      catchError(this.handleError<any>("Update Microticket", null))
    );
  }

  public reopen(data: MicroTicket): Observable<MicroTicket> {
    return this.http.post<MicroTicket>(this.apiUrl + '/reopen/' + data.microTicketId, null).pipe(
      tap(_ => {
        this.messages.add("Microticket Service: Update Ticket", MessageType.SUCCESS, true);
      }),
      catchError(this.handleError<any>("Update Microticket", null))
    );
  }

  public static getStatusText(status: MicroTicketStatus) {
    switch (status) {
      case MicroTicketStatus.OPEN: return 'Open';
      case MicroTicketStatus.IN_PROGRESS: return 'In Progress';
      case MicroTicketStatus.COMPLETE: return 'Complete';
      case MicroTicketStatus.CLOSED: return 'Closed';
    }
  }

  public static getStatusColorClass(status: MicroTicketStatus) {
    switch (status) {
      case MicroTicketStatus.OPEN: return 'bg-danger text-white';
      case MicroTicketStatus.IN_PROGRESS: return 'bg-warning text-dark';
      case MicroTicketStatus.COMPLETE: return 'bg-success text-white';
      case MicroTicketStatus.CLOSED: return 'bg-dark text-white';
    }
  }

  public createComment(data: MicroTicketComment) {
    return this.http.post<MicroTicket>(this.baseUrl + 'api/microTicketComment/new', data).pipe(
      tap(_ => {
        this.messages.add("Microticket Service: Add Comment to Ticket", MessageType.SUCCESS, true);
      }),
      catchError(this.handleError<any>("Save Microticket Comment", null))
    );
  }

  public addDocuments(ticket: MicroTicket, data: VirtualDocument[]) {
    return this.http.post<void>(this.apiUrl + '/addDocuments/' + ticket.microTicketId, data.map(d => d.documentId)).pipe(
      tap(_ => {
        this.messages.add("Microticket Service: Add Documents to Ticket", MessageType.SUCCESS, true);
      }),
      catchError(this.handleError<any>("Save Microticket Documents", null))
    );
  }

}
