import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, UntypedFormControl, Validators } from '@angular/forms';
import { MatSidenav } from '@angular/material/sidenav';
import { UtilityService } from '../../../common/services/utility.service';
import { Building } from '../../../floor/resources/building';
import { InventoryItemLocation } from '../../../inventory/resources/inventory-item-location';
import { InventoryLocation } from '../../../inventory/resources/inventory-location';
import { ShippingTicket } from '../../resources/shipping-ticket';
import { ShippingService } from '../../services/shipping.service';
import { InventoryTreePickerComponent } from '../inventory-tree-picker/inventory-tree-picker.component';

@Component({
  selector: 'shipping-check-out',
  templateUrl: './shipping-check-out.component.html',
  styleUrls: ['./shipping-check-out.component.less']
})
export class ShippingCheckOutComponent implements OnInit {

  @Input() sidenav: MatSidenav;
  @Input() ticket: ShippingTicket;
  @Input() building: Building;
  @Output() done = new EventEmitter<void>();
  @ViewChild('inventoryTreePicker', { static: true }) inventoryTreePicker: InventoryTreePickerComponent;

  public saving = false;

  constructor(private fb: FormBuilder, private utilityService: UtilityService, private shippingService: ShippingService) { }

  ngOnInit() {
  }

  public checklistForm = this.fb.group({
    item: new FormControl<boolean>(false, (c) => c.value === false ? { item: true } : null),
    quantity: new FormControl<boolean>(false, (c) => c.value === false ? { quantity: true } : null),
    origin: new FormControl<boolean>(false, (c) => c.value === false ? { origin: true } : null),
    poNumber: new FormControl<boolean>(false, (c) => c.value === false ? { poNumber: true } : null),
    woNumber: new FormControl<boolean>(false, (c) => c.value === false ? { woNumber: true } : null),
  });

  public inventoryInfoForm = this.fb.group({
    lotNumber: ['', []],
  });

  public locationControl = new UntypedFormControl({}, () => {
    if (this.ticket && (this.totalLocationsPicked || 0) < this.ticket.quantityRequired) return { location: true };
    else return null;
  });

  public locationData: InventoryTreePickerComponent['locationsPicked'] = {};

  public get locations() {
    return Object.values(this.locationData || {}).filter(l => l.amt > 0);
  }

  public get totalLocationsPicked(): number {
    return this.locations.reduce((acc, x) => acc + x.amt, 0);
  }

  public get itemTypeName() {
    const item = this.ticket.inventoryItem
    if (item.purchasedItemId) return 'item';
    else if (item.materialId) return 'material';
    else if (item.productId) return 'part';
  }

  public clearLocation(location: InventoryItemLocation) {
    if (this.locationData[location.inventoryItemLocationId]) this.locationData[location.inventoryItemLocationId].amt = 0;
  }

  public async abort() {
    const confirm = await this.utilityService.showConfirmationPromise(
      'Abort the check-in process?',
      'All your progress will be lost. Are you sure?'
    );
    if (confirm) {
      this.sidenav.close();
    }
  }

  public getTicketNumber = ShippingService.getTicketNumber;

  public get sendingTo() {
    if (this.ticket.destinationBuilding) return this.ticket.destinationBuilding.name;
    else if (this.ticket.purchaseOrder) return this.ticket.purchaseOrder.vendor && this.ticket.purchaseOrder.vendor.name;
    else if (this.ticket.workOrder) return this.ticket.workOrder.order && this.ticket.workOrder.order.customer && this.ticket.workOrder.order.customer.businessName;
  }

  public printed = false;
  public printLabel() {
    this.saving = true;
    this.shippingService.getShippingLabel(this.ticket, this.building).subscribe(doc => {
      this.saving = false;
      // TODO: print instead of opening
      // make some kind of check to see if connected to print station and just hide the print label button if not
      window.open(`/document/${doc.documentId}?inline=true`, "_blank")
    })
    this.printed = true;
  }

  public getWarnings(location: InventoryItemLocation): string[] {
    if (!this.inventoryTreePicker) return [];
    else return this.inventoryTreePicker.getWarnings(location);
  }

  public async finalizeAndCheckOut() {

    const warningsExist = this.locations.some(l => this.getWarnings(l.loc).length > 0);
    if (warningsExist) {
      const r = await this.utilityService.showConfirmationPromise('Finish checkout?', `<b class="text-danger"><i class="fas fa-warning mr-1"></i>Some of the lots being picked from have warnings. Please make sure to check them before confirming.</b>`, 5);
      if (!r) return;
    }

    this.saving = true;

    const pickLocations = {}
    for (const [key, val] of Object.entries(this.locationData)) {
      pickLocations[key] = val.amt
    }

    await this.shippingService.checkOutTicket(this.ticket.shippingTicketId, {
      pickLocations
    }).toPromise();
    this.saving = false;
    this.done.emit();
  }


}
