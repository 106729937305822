
export const RuleBuilder_Constants_RuleAction = {

  //this should match column ruleAction from DB table RuleBuilder
  ruleAction_1 : "Expire Quotes that haven't turned into Orders after" ,
  ruleAction_2 : "Inventory need to be at machine in" ,
  ruleAction_3 : "Did not receive Incoming Shipment" ,
  ruleAction_4 : "Estimate has not been approved after", 
  ruleAction_5 : "Create Quality ticket if vendor certificate expires within",
  ruleAction_6 : "Estimate is incomplete and due in",
  ruleAction_7 : "RFQ is pending",
};

export const RuleBuilder_Constants_RuleActionWhenUnit = {

  //this should match column ruleActionWhenUnit from DB table RuleBuilder
  ruleActionWhenUnit_Minutes: "minutes",
  ruleActionWhenUnit_Hours: "hours",
  ruleActionWhenUnit_Days: "days"

};

export const RuleBuilder_Constants_RuleActionToTake = {

  //this should match column actionToTake from DB table RuleBuilder
  ruleActionToTake_SendANotification: "Send a Notification",
  ruleActionToTake_ScheduleToSendANotification: "Schedule to send a notification",
  ruleActionToTake_ExpireQuotes: "Expire Quotes",

};

export const RuleBuilder_Constants_RuleActionToTakeWhenUnit = {

  //this should match column actionToTakeWhenUnit from DB table RuleBuilder
  ruleActionToTakeWhenUnit_Minute: "minute",
  ruleActionToTakeWhenUnit_Hour: "hour",
  ruleActionToTakeWhenUnit_Day: "day",
  ruleActionToTakeWhenUnit_Week: "week",

};
