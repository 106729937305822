import { Component, Output, EventEmitter, Input, OnInit } from '@angular/core';
import { User, UserRole } from '../../../common/resources/user';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { UserService } from '../../../common/services/user.service';

@Component({
  selector: 'permission-selection',
  templateUrl: './permission-selection.component.html',
  styleUrls: ['./permission-selection.component.less']
})
export class PermissionSelectionComponent implements OnInit {
  public availableGroups: UserRole[];
  private selectedGroups: PermissionSelection[] = [];
  @Output() selectionChanged: EventEmitter<PermissionSelection[]> = new EventEmitter<PermissionSelection[]>();
  @Input() selections: PermissionSelection[];

  constructor(private usrSvc: UserService) { }

  public setSelection(event: MatCheckboxChange, group: UserRole) {
    this.selectedGroups.find(g => g.id == group.id).selected = event.checked;
    this.selectionChanged.emit(this.selectedGroups);
  }

  public isChecked(group: UserRole): boolean {
    var permissionSet: PermissionSelection = this.selectedGroups.find(g => g.id == group.id);

    if (permissionSet == null) return false;

    return permissionSet.selected;
  }

  public clearSelection(): void {
    this.selectedGroups.forEach(g => g.selected = false);
    this.selectionChanged.emit(this.selectedGroups);
  }

  ngOnInit(): void {
    this.availableGroups = this.usrSvc.roles.sort((a, b) => a.niceName.localeCompare(b.niceName));
    this.availableGroups.forEach(i => this.selectedGroups.push(<PermissionSelection>{ id: i.id, group: i.niceName, selected: false }));
    if (this.selections) {
      this.selections.forEach(i => this.selectedGroups.find(g => g.group == i.group).selected = i.selected);
    }
  }
}

export class PermissionSelection {
  public id: string;
  public group: string = "";
  public selected: boolean = false;
}
