import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NavigationService } from '../../../common/services/navigation.service';
import { UserService } from '../../../common/services/user.service';
import { UtilityService } from '../../../common/services/utility.service';
import { StationService } from '../../../order/services/station.service';
import { VendorService } from '../../../supplier/services/vendor.service';
import { PurchasingService } from '../../services/purchasing.service';
import { PurchaseOrder, PurchaseOrderLineItem, PurchaseOrderStatus } from '../../resources/purchaseOrder';
import { Address } from '../../../common/resources/address';
import { Vendor } from '../../../supplier/resources/vendor';
import { ShippingService } from '../../../shipping/services/shipping.service';
import { ShippingCarrier } from '../../../shipping/resources/shipping-ticket';
import { NgForm } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { User } from '../../../common/resources/user';
import { map, share, shareReplay } from 'rxjs/operators';
import { Contact } from '../../../common/resources/contact';

@Component({
  selector: 'app-purchase-order',
  templateUrl: './purchase-order.component.html',
  styleUrls: ['./purchase-order.component.less']
})
export class PurchaseOrderComponent implements OnInit {

  private id: string;
  public record: PurchaseOrder;
  public editing = false;
  public saving = false;

  constructor(private stationSvc: StationService,
    private purchasingService: PurchasingService,
    private vendorService: VendorService,
    private router: Router,
    private route: ActivatedRoute,
    private navService: NavigationService,
    private utilitySvc: UtilityService,
    private userSvc: UserService,
    private shippingService: ShippingService,
    private dialog: MatDialog) {
    this.id = this.route.snapshot.paramMap.get('id');
  }

  private setPageTitle(): void {
    this.navService.setPageTitle("Purchase Order Detail");
    this.navService.pushBreadcrumb(this.record.purchaseOrderNumber || "New Purchase Order");
  }

  public getStatusColorClass(status: number) {
    return PurchaseOrderStatus.getStatusBackgroundColorClass(status);
  }

  public getStatusText(status: number) {
    return PurchaseOrderStatus.getStatusText(status);
  }

  public vendorAddresses: Address[];
  public async loadAddresses(vendor: Vendor) {
    this.vendorAddresses = null;
    if (vendor) {
      const a = await this.vendorService.getVendorAddresses(vendor.vendorId).toPromise();
      this.vendorAddresses = a.map(x => x.address);
      if (this.record.vendor && this.record.purchaseOrderId === UtilityService.emptyGuid && !this.record.vendorAddressId)
        if (this.record.vendor.primaryAddressId) {
          this.record.vendorAddressId = this.record.vendor.primaryAddressId;
          this.record.vendorAddress = this.record.vendor.primaryAddress
        }
        else if (this.vendorAddresses.length === 1) {
          this.record.vendorAddressId = this.vendorAddresses[0].addressId;
          this.record.vendorAddress = this.vendorAddresses[0]
        }
      console.log('loadedAddresses')
    }
  }

  @ViewChild('form') form: NgForm;
  public async getDetail() {
    if (this.id == "new" || this.id == UtilityService.emptyGuid) {
      this.record = null;
      this.editing = true;
      this.record = <PurchaseOrder>{
        purchaseOrderId: UtilityService.emptyGuid,
        status: PurchaseOrderStatus.IN_PROCESS,
        process: 0,
        destinationType: 1,
        datePlaced: new Date(),
        purchaseOrderLineItems: [],
        shippingHandlingFees: 0
      };
      this.userSvc.user.subscribe(u => {
        if (!u || !(u.userId)) return;
        this.record.assignedTo = u.userId;
        this.record.assignedUser = u;
      });
    } else {
      const detail = await this.purchasingService.getPurchaseOrderDetail(this.id).toPromise();
      if (!detail) return;
      this.record = detail;
      this.record.purchaseOrderLineItems = detail.purchaseOrderLineItems.sort((i, j) => {
        return i.lineNumber - j.lineNumber;
      }).map(li => {
        if (li.outsideProcessDescription) li.outsideProcessDescription.steps = li.outsideProcessDescription.steps.sort((a, b) => a.order - b.order);
        return li;
      });
      if (this.record.vendor) this.loadAddresses(this.record.vendor);
    }
    this.setPageTitle();  
  }

  public onVendorChange(vendor: Vendor) {
    this.record.vendorId = vendor.vendorId;
    this.record.taxRate = vendor.defaultTaxRate ?? 0;
    this.loadAddresses(vendor);
  }

  public onVendorAddressChange(addressId: string) {
    if (addressId) {
      this.record.vendorAddress = this.vendorAddresses.find(x => x.addressId === addressId);
      if (this.record.vendorAddress && this.record.vendorAddress.contactId)
        this.record.contactId = this.record.vendorAddress.contactId;
    }
  }

  public getAddressString(address: Address, attn: string = null): string {
    if (!address) return '';
    else return `${attn ? attn+'\n' : ''}${address.streetAddress}${address.streetAddress2 ? '\n'+address.streetAddress2 : ''}
${address.city}, ${address.state} ${address.postalCode}
` //city here?
  }

  get activeAddressAttn(): string {
    if (this.record.destinationType === 0)
      return null;
    else if (this.record.destinationType === 1)
      return this.record.destinationBuilding && "Anaheim Precision Manufacturing";
    else if (this.record.destinationType === 2)
      return this.record.destinationCustomer && this.record.destinationCustomer.businessName;
  }

  get activeAddress() {
    if (this.record.destinationType === 0) 
      return null;
    else if (this.record.destinationType === 1) 
      return this.record.destinationBuilding && this.record.destinationBuilding.address;
    else if (this.record.destinationType === 2) 
      return this.record.destinationCustomerAddress && this.record.destinationCustomerAddress.address;
  }

  public paymentTerms$: Observable<string[]>;
  public freightTerms$: Observable<string[]>;
  public shippingMethods$: Observable<string[]>;
  public shippingCarriers$: Observable<ShippingCarrier[]>;

  ngOnInit(): void {
    this.getDetail();
    this.paymentTerms$ = this.purchasingService.getPaymentTerms();
    this.freightTerms$ = this.purchasingService.getFreightTerms();
    this.shippingCarriers$ = this.shippingService.getShippingCarriers().pipe(map((v) => v.results));
    this.shippingMethods$ = this.purchasingService.getShippingMethods();
  }

  public openReport() {
    this.saving = true;
    this.purchasingService.getPOReport(this.record).subscribe(doc => {
      this.saving = false;
      window.open(`/document/${doc.documentId}?inline=true`, "_blank")
    })
  }

  public lastSaved: PurchaseOrder | null = null;
  public removedLineItems: PurchaseOrderLineItem[] = [];

  public edit() {
    this.lastSaved = JSON.parse(JSON.stringify(this.record));
    this.editing = true;
  }

  public async stopEditing() {
    const r = await this.utilitySvc.showConfirmationPromise('Really cancel editing?', 'All your changes will be lost.');
    if (!r) return;
    this.editing = false;
    if (this.id === 'new') this.router.navigate(['purchasing/orders'])
    this.record = JSON.parse(JSON.stringify(this.lastSaved));
  }

  public get isManager(): boolean {
    return this.userSvc.canAccess('PurchasingManager');
  }

  public get approvedByUser(): Observable<User> {
    if (!this.record?.approvedBy || !this.userSvc.loaded) return null;
    else return this.userSvc.getUser(this.record.approvedBy);
  }
 
  @ViewChild('statusConfirmationDialogTemplate') statusConfirmationDialogTemplate: TemplateRef<any>;
  public async save() {
    let isReady = false;
    if (this.record.status === 3 || this.record.status === 5) {
      const ref = this.dialog.open<any, any, { ready: boolean } | null>(this.statusConfirmationDialogTemplate);
      const r = await ref.afterClosed().toPromise();
      if (!r) return;
      isReady = r.ready;
    }
    
    try {
      this.saving = true;
      this.record = await this.purchasingService.savePurchaseOrder(this.record, this.removedLineItems, isReady).toPromise();
      this.record.purchaseOrderLineItems = this.record.purchaseOrderLineItems.sort((i, j) => {
        return i.lineNumber - j.lineNumber;
      }).map(li => {
        if (li.outsideProcessDescription) li.outsideProcessDescription.steps = li.outsideProcessDescription.steps.sort((a, b) => a.order - b.order);
        return li;
      });
      this.editing = false;
      // in case we added new stations or specs
      this.stationSvc.getStationList();
      if (this.id !== this.record.purchaseOrderId) {
        this.id = this.record.purchaseOrderId;
        this.router.navigate(['/purchasing/order', this.record.purchaseOrderId]);
      }
    } catch (e) {
      console.error(e);
    } finally {
      this.saving = false;
    }
  }

  @ViewChild('rejectDialogTemplate') rejectDialogTemplate: TemplateRef<any>;
  public async reject() {
    const ref = this.dialog.open<{ note: string}, any, { note: string } | null>(this.rejectDialogTemplate, {
      data: { note: '' }
    });
    const r = await ref.afterClosed().toPromise();
    if (r === null) return;
    this.saving = true;
    await this.purchasingService.rejectPO(this.record, r.note).toPromise();
    this.saving = false;
    this.getDetail();
  }

  public async approve() {
    const r = await this.utilitySvc.showConfirmationPromise('Approve PO?', 'Really approve this purchase order? Shipping tickets will be created.');
    if (!r) return;
    this.saving = true;
    await this.purchasingService.approvePO(this.record).toPromise();
    this.saving = false;
    this.getDetail();
  }

  @ViewChild('rejectionNoteDialogTemplate') rejectionNoteDialogTemplate: TemplateRef<any>;
  public viewRejectionNote() {
    this.dialog.open(this.rejectionNoteDialogTemplate);
  }

  public get addressInput() {
    return this.record.destinationType === 0 || (this.record.destinationType === 1 && this.record.destinationBuildingId)
  }

  public getShippingCarrierName(carrier: ShippingCarrier): string {
    return carrier?.name ?? '';
  }
 
  public generateShippingCarrier(name: string): ShippingCarrier {
    return {
      shippingCarrierId: UtilityService.emptyGuid,
      name,
      accountNumber: '',
      trackingProvider: ''
    }
  }

  public compareContacts = (a: Contact, b: Contact) => a?.contactId === b?.contactId;

  public async unreceive() {
    const r = await this.utilitySvc.showConfirmationPromise('Unreceive PO?', `
    <p>This will revert the status of all received shipping tickets and remove all inventory created by them.</p>
    <p>Note that if the inventory has been moved elsewhere since receiving, it will not be affected and may need to be removed manually.</p>
    `);
    if (!r) return;
    this.saving = true;
    await this.purchasingService.unreceivePO(this.record).toPromise();
    this.saving = false;
    this.record = null;
    this.getDetail();
  }

}