import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { take } from 'rxjs/operators';

@Component({
  selector: 'order-detail-redirector',
  templateUrl: './order-detail-redirector.component.html',
  styleUrls: ['./order-detail-redirector.component.less']
})
export class OrderDetailRedirectorComponent {

  constructor(
    private router: Router,
    private route: ActivatedRoute,
  ) {
    this.route.data.pipe(take(1)).subscribe(data => {
      const { discriminator } = data;
      const id = this.route.snapshot.paramMap.get("id");
      
      const localStorageKey = `order-detail-${discriminator}-new-ui`;
      try {
        const json = localStorage.getItem(localStorageKey);
        const value: boolean = JSON.parse(json);
        if (value === true) {
          // redirect to new ui
          this.router.navigate(['/', discriminator, 'newUi', id], {
            preserveFragment: true,
          });
        } else {
          this.router.navigate(['/', discriminator, 'oldUi', id], {
            preserveFragment: true,
          });
        }
      } catch (_) {
        this.router.navigate(['/', discriminator, 'oldUi', id], {
            preserveFragment: true,
          });
      } 
    })
  }

}
