import { Component, EventEmitter, Output, Input, ViewChild, ElementRef, OnInit } from '@angular/core';
import { FormControl } from "@angular/forms";
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { combineLatest, Observable, Subject, Subscription } from 'rxjs';
import { UserService } from '../../../common/services/user.service';
import { CustomerService } from '../../../customer/services/customer.service';
import { Customer } from '../../../customer/resources/customer';
import { debounceTime, filter, first, map, mergeMap, startWith, tap } from 'rxjs/operators';

@Component({
  selector: 'customer-select',
  templateUrl: './customer-select.component.html',
  styleUrls: ['./customer-select.component.less']
})
export class CustomerSelectComponent implements OnInit {
  @Input() customer: Customer;
  @Input() addDisabled = false;
  @Output() customerChange: EventEmitter<Customer> = new EventEmitter<Customer>();
  @Input() editable: boolean;
  @ViewChild('customerInputText', { static: true }) inputTxt: ElementRef;
  @ViewChild('autoCompleteTrigger', { static: true }) autoCompleteTrigger: MatAutocompleteTrigger;
  @Output() addCustomer: EventEmitter<string> = new EventEmitter<string>();
  public searching: boolean = false;
  private lastVal: string = null;


  public searchedCustomers: Observable<Customer[]>;
  public filteredCustomers: Observable<Customer[]>;

  customers: Customer[] = [];

  constructor(private customerService: CustomerService, private usrService: UserService) { }

  setValue(customer: Customer): void {
    this.customer = customer;
    this.customerChange.emit(this.customer);
    this.searchControl.setValue(customer.businessName);
  }

  toggleDropdown(e): void {
    e.stopImmediatePropagation();
    if (this.autoCompleteTrigger.panelOpen) {
      this.autoCompleteTrigger.closePanel();
    }
    else {
      this.autoCompleteTrigger.openPanel();
    }
  }

  canAdd(): boolean {
    return this.usrService.canAccess("CustomerUser");
  }

  onAddCustomer(): void {
    event.stopImmediatePropagation();
    this.addCustomer.emit(this.lastVal);
    this.autoCompleteTrigger.closePanel();
  }

  public searchControl = new FormControl<string>('');

  public opened = new Subject();

  ngOnInit() {

    if (this.customer) this.searchControl.setValue(this.customer.businessName);


    const typedOrClicked = combineLatest([
      this.searchControl.valueChanges.pipe(startWith('')),
      this.opened.pipe(first())
    ]).pipe(map(([str, _]) => str));
    
    this.searchedCustomers = typedOrClicked.pipe(
        startWith(''),
        tap(() => {
          this.searching = true
        }),
        debounceTime(250),
        mergeMap(value => {
            return this.customerService.search(value);
        }),
        tap((r) => {
          this.searching = false;
        }),
        map(value => value.results)
    );

    this.filteredCustomers = combineLatest([typedOrClicked
      .pipe(startWith(''), filter(v => typeof v === 'string')), this.searchedCustomers])
        .pipe(
          tap(([_, r]) => {
            //console.log('filteredresults arrived', r.length);
          }),
          map(([searchValue, parts]) => {
            if (!searchValue.trim()) return parts
            else return parts.filter(p => {
              return searchValue && p.businessName && p.businessName.toLowerCase().includes(searchValue.toLowerCase());
            })
          }),
    );
    

  }

}
