<div #previewContainer>
  <div class="d-flex align-items-center">
    <div class="flex-grow-1">
      <mat-form-field class="w-100 mat-input-no-message" appearance="outline">
        <mat-label>Part Number</mat-label>
        <input
          matInput
          [ngModel]="value?.product?.partNumber ?? ''"
          name="partNumber"
          (ngModelChange)="onPartNumberChange($event)"
          #partNumberSearchModel="ngModel"
          type="text"
          [matAutocomplete]="pnAuto"
          required
          [disabled]="disableEditProduct"
        >
        <mat-autocomplete #pnAuto="matAutocomplete"> 
            <ng-container *ngIf="filteredPartNumbers$ | async as items">
              <mat-option *ngFor="let item of items" [value]="item.partNumber" (click)="value && value.product = item">
                  <span [innerHTML]="item.partNumber | highlight: partNumberSearchModel.value ?? ''"></span>
              </mat-option>
            </ng-container>
          </mat-autocomplete>
          <div [class.invisible]="!loadingPartNumbers" matSuffix>
            <mat-spinner diameter="20" class="mr-2"></mat-spinner>
          </div>
      </mat-form-field>
    </div>
    <div class="ml-2">
      <mat-form-field appearance="outline" class="mat-input-no-message">
        <mat-label>Rev.</mat-label>
        <input
          matInput
          [ngModel]="value?.product?.revision ?? ''"
          name="rev"
          (ngModelChange)="onRevisionChange($event)"
          #revisionSearchModel="ngModel"
          type="text"
          [matAutocomplete]="revAuto"
          required
          [disabled]="disableEditProduct"
        >
        <mat-autocomplete #revAuto="matAutocomplete"> 
            <ng-container *ngIf="filteredRevisions$ | async as items">
              <mat-option *ngFor="let item of items" [value]="item.revision" (click)="value && value.product = item">
                  <span [innerHTML]="item.revision | highlight: revisionSearchModel.value ?? ''"></span>
              </mat-option>
            </ng-container>
          </mat-autocomplete>
      </mat-form-field>
    </div>
  </div>
  <div class="my-1 small text-muted font-italic d-flex align-items-center" *ngIf="disableEditProduct">
    <mat-icon class="mr-1">info</mat-icon>
    The product/rev for a saved process cannot be changed.
    <button mat-flat-button color="primary" class="ml-2 d-inline-flex" (click)="doClone()">
      <mat-icon matPrefix>content_copy</mat-icon>
      Clone process for new PN/rev
    </button>
  </div>
  <div class="my-1 small text-muted font-italic d-flex align-items-center" *ngIf="!disableEditProduct">
    <mat-icon class="mr-1">info</mat-icon>
    Product and rev will not be editable after saving.
  </div>
  <mat-divider class="my-2"></mat-divider>
  <div class="w-100" cdkDropList (cdkDropListDropped)="drop($event)">
    <div cdkDrag class="d-flex align-items-center w-100 drag-handle flex-wrap op-item" *ngFor="let step of value?.steps ?? []; let i = index"
    [cdkDragPreviewClass]="['mat-elevation-z3', 'bg-white', 'rounded', 'drag-preview']" [cdkDragPreviewContainer]="previewContainer"
    >
      <div class="font-weight-medium text-muted small mx-2" cdkDragHandle>
        OP{{step.order+1}}.
      </div>
      <clientside-search
        class="flex-grow-1"
        fieldClass="mat-input-no-message"
        label="Process"
        [ngModel]="step.station"
        [name]="'steps[' + i + '].station'"
        (ngModelChange)="onStationChange(step, $event)"
        [items]="stations$"
        [canAdd]="true"
        [getSearchField]="this.getStationName.bind(this)"
        [addConverter]="this.generateStation.bind(this)"
        [reqSel]="true"
        [required]="true"
      >
      </clientside-search>
      <specifications-input
        class="flex-grow-2 ml-2"
        fieldClass="mat-input-no-message"
        *ngIf="step.stationId" [stationId]="step.stationId"
        [(specifications)]="step.outsideProcessSpecifications"
        (specificationsChange)="onSpecsUpdate(step, $event)"
        [editable]="true"
        [nowrap]="true"
        [(names)]="this.step.outsideProcessSpecificationNames"
        (createSpec)="onCreateSpec(step, $event)"
        ></specifications-input>
      <button mat-icon-button class="text-muted ml-2" (click)="deleteStep(i)">
        <mat-icon>delete</mat-icon>
      </button>
      <mat-form-field appearance="outline" class="w-100 mat-input-no-message">
        <mat-label>Process Description</mat-label>
        <textarea matInput [(ngModel)]="step.description"
        [name]="'steps[' + i + '].description'"
        placeholder="Instructions for the processor"></textarea>
      </mat-form-field>
      <mat-divider class="w-100 my-2"></mat-divider>
      <div *cdkDragPlaceholder class="rounded bg-primary" style="height: 67px;">
      </div>
    </div>
    <button mat-stroked-button class="button-text w-100 mt-1" color="primary" (click)="addOp()">
      <mat-icon matPrefix>add</mat-icon>
      Add Another Operation
    </button>
  </div>
</div>