import { AfterViewInit, Component, Inject, Injector, Input, OnChanges, OnInit, SimpleChanges, TemplateRef, ViewChild, forwardRef } from '@angular/core';
import { OrderDetailService } from '../../order-detail.service';
import { UtilityService } from '../../../../../common/services/utility.service';
import { ProductPurchasedItem } from '../../../../resources/product';
import { OrderService } from '../../../../services/order.service';
import { NG_VALUE_ACCESSOR, ControlValueAccessor, NgForm } from '@angular/forms';
import { map } from 'rxjs/operators';
import { GenericSearchComponent } from '../../../../../common/components/generic-search/generic-search.component';
import { PurchasedItem } from '../../../../resources/purchased-item';
import { MaterialBid } from '../../../../../purchasing/resources/materialBid';
import { TaskStatus } from '../../../../../common/resources/estimatingtask';
import { EstimateProgressService } from '../../../../services/estimate-progress.service';
import { Subscription } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'product-purchased-items',
  templateUrl: './product-purchased-items.component.html',
  styleUrls: ['./product-purchased-items.component.less']
})
export class ProductPurchasedItemsComponent implements AfterViewInit {

  constructor(
    public service: OrderDetailService,
    public progressService: EstimateProgressService,
    private dialog: MatDialog
  ) { }

  @ViewChild('form') form: NgForm;
  private formSubscription: Subscription;
  ngAfterViewInit() {
    if (this.formSubscription) this.formSubscription.unsubscribe();
    this.formSubscription = this.form?.valueChanges.subscribe(() => {
      setTimeout(() => this.service.notifyEdited());
    });
  }

  private updateProgress() {
    if (!this.progressService.shouldShowIndicators()) return;
    const status = this.progressService.getSimpleTaskStatus(this.product.productId, "hardware", this.service.selectedTopLevelProduct.productId);
    if (status === TaskStatus.NOT_STARTED) this.progressService.setSimpleTaskStatus(this.product.productId, "hardware", this.service.selectedTopLevelProduct.productId, TaskStatus.IN_PROGRESS);
    this.service.notifyEdited();
  }

  public get record() { return this.service.order }
  public get product() { return this.service.selectedProduct }
  public get editing() { return this.service.editing }

  public get selectedItemId() { return this.service.selectedPurchasedItemId }
  public set selectedItemId(val: string) {
    this.service.selectedPurchasedItemId = val;
    window.location.hash = this.service.generateSubItemNavigationId(this.product.productId, "hardware", val);
  }
  public get selectedItem() {
    return this.product.purchasedItems.find(i => i.productPurchasedItemId === this.selectedItemId);
  }

  public createNew() {
    const newItem = <ProductPurchasedItem>{
      productPurchasedItemId: UtilityService.newGuid(),
      quantity: 1,
      isNonRecurring: false,
      isAmortized: false
    };
    this.service.addNewId(newItem.productPurchasedItemId);
    this.product.purchasedItems = [...this.product.purchasedItems, newItem];
    this.selectedItemId = newItem.productPurchasedItemId;
    this.updateProgress();
    this.service.notifyEdited();
  }

  public get firstQuantity(): number {
    if (this.product.quantityAsChild) return this.product.quantityAsChild;
    if (this.product.orderQuantity) return this.product.orderQuantity;
    if (this.product.quantitiesMap == null) return null;

    var quantities = this.product.quantitiesMap;

    if (quantities.length == 0) return null;

    return quantities[0].value;
  }


  public setItemPrice(purchasedItem: ProductPurchasedItem, quote: MaterialBid) {
    if (purchasedItem.isAmortized)
    {
        purchasedItem.costPer = quote.totalBid ? (quote.totalBid / this.firstQuantity) : quote.perItemBid;
    } else
    {
        purchasedItem.costPer = quote.totalBid ? (quote.totalBid / quote.qty) : quote.perItemBid;
    }
  }

  public setRepetitionType(item: ProductPurchasedItem, type: 'amortized' | 'nonrecurring' | 'everytime') {
    switch (type) {
      case 'amortized':
        item.isAmortized = true;
        item.isNonRecurring = true;
        break;
      case 'nonrecurring': 
        item.isAmortized = false;
        item.isNonRecurring = true;
        break;
      case 'everytime': 
        item.isAmortized = false;
        item.isNonRecurring = false;
        break;
      default:
        break;
    }
  }

  public deleteItem(item: ProductPurchasedItem) {
    this.product.purchasedItems = this.product.purchasedItems.filter(p => p.productPurchasedItemId !== item.productPurchasedItemId);
    this.service.notifyEdited();
  }

  public getHardwareTaskStatus() {
    return this.progressService.getSimpleTaskStatus(this.product.productId, 'hardware', this.service.selectedTopLevelProduct.productId)
  }

  public setHardwareTaskStatus(status: TaskStatus) {
    return this.progressService.setSimpleTaskStatus(this.product.productId, 'hardware', this.service.selectedTopLevelProduct.productId, status)
  }

  @ViewChild('newItemDialog') newItemDialog: TemplateRef<any>;
  public async addItem(name: string) {
    const dialogRef = this.dialog.open(this.newItemDialog, { data: { name }, disableClose: true, width: "50vw" });
    const finalName: string = await dialogRef.afterClosed().toPromise();
    if (!finalName) return;
    const purchasedItem: PurchasedItem = {
      purchasedItemId: UtilityService.newGuid(),
      description: finalName,
      purchasedItemCategory: null,
      purchasedItemCategoryId: UtilityService.emptyGuid,
      purchasedItemPartNumbers: []
    };
    this.selectedItem.purchasedItem = purchasedItem;
    this.service.addNewId(purchasedItem.purchasedItemId);
  }

  public getCost(item: ProductPurchasedItem) {
    let cost = item.costPer ?? 0;
    cost = cost * item.quantity;
    cost = cost * (1 + ((item.markupPercent ?? 0.0) / 100.0));
    if (item.isNonRecurring) cost = cost / this.firstQuantity;
    return cost;
  }


}


@Component({
  selector: 'purchased-item-search',
  templateUrl: '../../../../../common/components/generic-search/generic-search.component.html',
  styleUrls: ['../../../../../common/components/generic-search/generic-search.component.less'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => PurchasedItemSearch),
      multi: true
    }
  ]
})
export class PurchasedItemSearch extends GenericSearchComponent<PurchasedItem> implements OnChanges, ControlValueAccessor {

  @Input() readonly = false;
  placeholder = 'Purchased Item'

  constructor(private svc: OrderService, @Inject(Injector) injector: Injector) {
    super(injector);
  }

  doSearch(searchText: string) {
    return this.svc.searchPurchasedItems(searchText).pipe(
      map(r => r.results)
    )
  }

  public canAdd(): boolean { return true }

  public get addItemText(): string {
     return '';
  }

  public get noItemsText(): string {
    return 'No matching items found.';
  }

  public getSearchField(c: PurchasedItem): string {
    return c && c.description;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.selectedItem) {
      const change = changes.selectedItem;
      if (change.firstChange) return;
      this.searchValue = this.getSearchField(change.currentValue);
    }
  }

}