import { NestedTreeControl } from '@angular/cdk/tree';
import { Component, ElementRef, forwardRef, Input, OnInit, Self, ViewChild } from '@angular/core';
import { ControlValueAccessor, FormControl, NgControl, NgModel, NG_VALIDATORS, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatTreeNestedDataSource } from '@angular/material/tree';
import { debounceTime, tap } from 'rxjs/operators';
import { Building } from '../../../floor/resources/building';
import { InventoryItem } from '../../../inventory/resources/inventory-item';
import { InventoryItemLocation } from '../../../inventory/resources/inventory-item-location';
import { InventoryLocation } from '../../../inventory/resources/inventory-location';
import { InventoryService } from '../../../inventory/services/inventory.service';
import { WorkOrder } from '../../../planning/resources/work-order';
import { RMATicket } from '../../../rma/resources/rma-ticket';


type AsyncChildrenLocation = InventoryLocation & { loading?: boolean } | InventoryItemLocation;
type ValueType = {
  [key: string]: { loc: InventoryItemLocation, amt: number, breadcrumbs: string[] }
}



@Component({
  selector: 'inventory-tree-picker',
  templateUrl: './inventory-tree-picker.component.html',
  styleUrls: ['./inventory-tree-picker.component.less'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InventoryTreePickerComponent),
      multi: true
    }
  ]
})
export class InventoryTreePickerComponent implements OnInit, ControlValueAccessor {

  @Input() label: string;
  @Input() public max: number;
  @Input() building?: Building;

  @Input() noMachines?: boolean = false;
  @Input() takingItem?: InventoryItem;
  @Input() takingWorkOrderId?: string;
  @Input() takingRMATicketId?: string;

  range(n: number): any[] {
    return Array(n);
  }

  public isItemLocation(i: AsyncChildrenLocation): i is InventoryItemLocation {
    return i.hasOwnProperty('inventoryItemLocationId');
  }

  public getFitScore(i: InventoryItemLocation): number {
    let score = 0;
    if (!!this.takingWorkOrderId && i.workOrderId === this.takingWorkOrderId) score += 1;
    if (!!i.rmaTicketId && i.rmaTicketId === this.takingRMATicketId) score += 1;
    if (!!i.rmaTicketId && i.rmaTicketId !== this.takingRMATicketId) score -= 4;
    return score;
  }

  public getChildren(i: AsyncChildrenLocation) {
    return this.isItemLocation(i) ? [] :
      [...((i.inventoryItemLocations && i.inventoryItemLocations.sort((a, b) => this.getFitScore(b) - this.getFitScore(a))) || []), ...(i.children || [])]
  }

  public getWarnings(location: InventoryItemLocation): string[] {
    let output = [];
    // check for mismatched WO
    if (!!location.workOrderId && location.workOrderId !== this.takingWorkOrderId) output.push('workOrder');
    // check for mismatched RMA -- this should match 100% of the time
    if (location.rmaTicketId !== this.takingRMATicketId) output.push('rma');
    return output;
  }

  public getWarningTooltip(location: InventoryItemLocation): string {
    const warnings = this.getWarnings(location);
    let strings: string[] = [];
    if (warnings.includes('workOrder')) strings.push('WO# does not match.')
    if (warnings.includes('rma')) strings.push('RMA# does not match.')
    return strings.join(' ');
  }

  treeControl = new NestedTreeControl<AsyncChildrenLocation>(node => this.getChildren(node));
  dataSource = new MatTreeNestedDataSource<AsyncChildrenLocation>();

  locationFilterControl = new FormControl<string>('');

  searchingLocations = false;

  constructor(private service: InventoryService) {
    this.dataSource.data = [];
    this.searchingLocations = true;
  }

  async toggleNode(node: AsyncChildrenLocation) {
    if (this.isItemLocation(node)) return;
    if (this.treeControl.isExpanded(node)) {
      this.treeControl.collapse(node);
      return;
    }
    else if (!node.children) {
      node.loading = true;
      const children = await this.service.getLocationChildren(node).toPromise();
      node.loading = false;
      node.children = children;
    }
    let _data = this.dataSource.data;
    this.dataSource.data = null;
    this.dataSource.data = _data;
    this.treeControl.expand(node);
  }
  
  hasChild = (_: number, node: AsyncChildrenLocation) => !this.isItemLocation(node) && ((!!node.children && node.children.length > 0) || (!!node.inventoryItemLocations && node.inventoryItemLocations.length > 0));

  getLevel(data: AsyncChildrenLocation[], node: AsyncChildrenLocation) {
    let path = data.find(branch => {
      return this.treeControl
        .getDescendants(branch)
        .some(n => this.isItemLocation(node) ? 
          (this.isItemLocation(n) && n.inventoryItemLocationId == node.inventoryItemLocationId) :
          (!this.isItemLocation(n) && n.inventoryLocationId === node.inventoryLocationId)
        );
    });
    if (!path || this.isItemLocation(path) || !this.getChildren(path)) return 0;
    return path ? this.getLevel(this.getChildren(path), node) + 1 : 0 ; 
  }

  @ViewChild('locationTreeCard', { static: true }) locationTreeCard: ElementRef<HTMLDivElement>;

  public locationsPicked: ValueType = {};
  public get value() { return this.locationsPicked; }

  onChange = (value: ValueType) => {};
  onTouched = () => {};

  writeValue(value: ValueType) {
    this.locationsPicked = value;
    this.onChange(this.locationsPicked);
  }


  registerOnChange(fn: (location: ValueType) => void): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  public get availableMax() {
    if (!this.max) return null;
    const total = Object.values(this.locationsPicked).reduce((acc, x) => acc + x.amt, 0);
    return this.max - total;
  }
  
  
  public initializeLocation(iil: InventoryItemLocation) {
    this.locationsPicked[iil.inventoryItemLocationId] = {
      loc: iil,
      amt: 0,
      breadcrumbs: this.getLocationBreadcrumbsRecursive(iil.inventoryLocationId, this.dataSource.data).map(l => !this.isItemLocation(l) && l.name)
    };
  }

  public onAmountInputChange(event: Event, iil: InventoryItemLocation) {
    if (this.locationsPicked[iil.inventoryItemLocationId] === undefined) {
      this.initializeLocation(iil);
    }
    const tgt = event.target as HTMLInputElement;
    const isValidInteger = /^(0|[1-9]\d*)$/.test(tgt.value);
    const newValue = parseInt(tgt.value);
    const isValid = isValidInteger && (
      !this.max || 
      newValue <= (this.availableMax + this.locationsPicked[iil.inventoryItemLocationId].amt)
    ) && newValue >= 0;
    if (isValid) {
      if (this.max) this.locationsPicked[iil.inventoryItemLocationId].amt = Math.min(
        newValue,
        (this.availableMax + this.locationsPicked[iil.inventoryItemLocationId].amt)
      );
      this.locationsPicked[iil.inventoryItemLocationId].amt = Math.max(this.locationsPicked[iil.inventoryItemLocationId].amt, 0);
    }
    else {
      tgt.value = this.locationsPicked[iil.inventoryItemLocationId].amt.toString();
    }
    this.writeValue(this.locationsPicked);
  }
  
  public increaseLocation(iil: InventoryItemLocation) {
    if (this.locationsPicked[iil.inventoryItemLocationId] === undefined) {
      this.initializeLocation(iil);
    }
    let newValue = this.locationsPicked[iil.inventoryItemLocationId].amt + 1;
    if (this.max) newValue = Math.min(
      newValue,
      (this.availableMax + this.locationsPicked[iil.inventoryItemLocationId].amt)
    );
    if (this.takingItem) newValue = Math.min(
      newValue,
      iil.quantity
    );
    this.locationsPicked[iil.inventoryItemLocationId].amt = newValue;
    this.writeValue(this.locationsPicked);
  }

  public decreaseLocation(iil: InventoryItemLocation) {
    if (this.locationsPicked[iil.inventoryItemLocationId] === undefined) {
      this.initializeLocation(iil);
    }
    this.locationsPicked[iil.inventoryItemLocationId].amt = this.locationsPicked[iil.inventoryItemLocationId].amt - 1;
    this.locationsPicked[iil.inventoryItemLocationId].amt = Math.max(this.locationsPicked[iil.inventoryItemLocationId].amt, 0);
    this.writeValue(this.locationsPicked);
  }

  public clearLocation(iil: InventoryItemLocation) {
    if (this.locationsPicked[iil.inventoryItemLocationId]) {
      this.locationsPicked[iil.inventoryItemLocationId].amt = 0;
      this.writeValue(this.locationsPicked);
    }
  }

  public maxOutLocation(iil: InventoryItemLocation) {
    if (this.locationsPicked[iil.inventoryItemLocationId] === undefined) {
      this.initializeLocation(iil);
    }
    let newValue = (this.availableMax + this.locationsPicked[iil.inventoryItemLocationId].amt);
    if (this.takingItem) newValue = Math.min(
      newValue,
      iil.quantity
    );
    this.locationsPicked[iil.inventoryItemLocationId].amt = newValue;
    this.writeValue(this.locationsPicked);
  }

  public disabled = false;

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  expandAll(data: AsyncChildrenLocation[]) {
    data.forEach(
      x => { 
        const children = this.isItemLocation(x) ? [] : this.getChildren(x);
        // expand if there are children that are locations
        // or if there are no location children, but at least one of the item locations has 0 warnings
        const hasLocationChildren = children.some(c => !this.isItemLocation(c));
        const hasChildWithNoWarnings = children.some(c => this.isItemLocation(c) && this.getWarnings(c).length === 0);
        if (
          hasLocationChildren || hasChildWithNoWarnings
        ) {
          this.treeControl.expand(x);
          this.expandAll(this.getChildren(x));
        }
      }
    );
  }

  getLocationBreadcrumbsRecursive(id: string, data: AsyncChildrenLocation[] | null): AsyncChildrenLocation[] | null {
    if (data) {
      for (let item of data) {
        if (item.inventoryLocationId === id) {
          return [item];
        }
        const a = (!this.isItemLocation(item) && item.children) ? this.getLocationBreadcrumbsRecursive(id, item.children) : null;
        if (a !== null) {
          a.unshift(item);
          return a;
        }
      }
    }
    return null;
  }

  // Filtering code
  public locationFilter = '';

  async doFilter(filter: string) {
    const trimmedFilter = filter.toLowerCase().trim();
    const base = this.building && this.building.rootInventoryLocationId ? [this.building.rootInventoryLocationId] : []
    this.service.locationTreeFilter(trimmedFilter, base, this.takingItem, this.noMachines)
    .subscribe(locs => {
      this.dataSource.data = locs;
      this.treeControl.collapseAll();
      if (trimmedFilter || this.takingItem) this.expandAll(locs);
      else if (this.building && this.building.rootInventoryLocationId) {
        for (const node of this.dataSource.data) {
          this.toggleNode(node);
        }
      }
      this.searchingLocations = false;
    });
    
  }

  @ViewChild('locationFilterModel') locationFilterModel: NgModel;
  ngOnInit() {
    this.locationFilterControl.valueChanges.pipe(
      tap(() => this.searchingLocations = true),
      debounceTime(500),
    )
      .subscribe(newValue => this.doFilter(newValue));
    this.doFilter('');
  }

}
