<ng-container *ngLet="filteredItems$ | async as items">
  <mat-form-field class="w-100" [class.dense-field]="dense" appearance="outline" [floatLabel]="readonly ? 'always' : undefined" #formField>
    <mat-label *ngIf="label || placeholder">{{label ?? placeholder}}</mat-label>
    <input
      matInput
      [(ngModel)]="searchValue"
      #searchModel="ngModel"
      type="text"
      [placeholder]="readonly ? '--' : placeholder"
      [matAutocomplete]="auto"
      [disabled]="disabled"
      [readonly]="readonly"
      [required]="required"
    >
    <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event.option.value)" [displayWith]="displayWith.bind(this)"> 
        <ng-container>
          <mat-option *ngFor="let item of items" [value]="item">
              <span [innerHTML]="getSearchField(item) | highlight: searchValue ?? ''"></span>
          </mat-option>
        </ng-container>
        <mat-option *ngIf="!loading && items && items?.length===0">
          <button *ngIf="canAdd()" class="float-right btn btn-primary btn-sm mt-4" (click)="onAdd($event)">
            <i class="fas fa-fw fa-lg fa-plus"></i>
            {{addItemText}}
          </button>
          <p class="text-muted small text-center mt-3">
            <em>
              {{noItemsText}}
            </em>
          </p>
        </mat-option>
      </mat-autocomplete>
      <div [class.invisible]="!loading && !!items" matSuffix>
        <mat-spinner diameter="20" class="mr-2"></mat-spinner>
      </div>
  </mat-form-field>
</ng-container>