import { Component, OnInit, ViewChild, Input, ViewContainerRef, TemplateRef, Output, EventEmitter, OnDestroy } from '@angular/core';
import { WorkOrder } from '../../resources/work-order';
import { ActivatedRoute, Router } from '@angular/router';
import { PreplanningData, WorkOrderService, WorkOrderShippingAssignment } from '../../services/work-order.service';
import { MachineAssignment } from '../../resources/machine-assignment';
import { PlanningService } from '../../services/planning.service';
import { OrderStatus } from '../../../order/resources/order';
import { NavigationService } from '../../../common/services/navigation.service';
import { MatSidenav } from '@angular/material/sidenav';
import { MatTab, MatTabGroup } from '@angular/material/tabs';
import { Product } from '../../../order/resources/product';
import { OrderService } from '../../../order/services/order.service';
import { UserService } from '../../../common/services/user.service';
import { UtilityService } from '../../../common/services/utility.service';
import { User } from '../../../common/resources/user';
import { StationService } from '../../../order/services/station.service';
import { Station } from '../../../order/resources/station';
import { ProgrammingTicket, ProgrammingType } from '../../resources/programming-ticket';
import { ProductHierarchySortComponent, SortedProductHierarchy } from '../product-hierarchy-sort/product-hierarchy-sort.component';
import { AfterViewInit } from '@angular/core';
import { WorkflowStepItemsComponent } from '../workflow-step-items/workflow-step-items.component';
import { WorkflowStepPlanningStatus } from '../../../order/resources/workflow';

@Component({
  selector: 'planning-wo-detail',
  templateUrl: './planning-wo-detail.component.html',
  styleUrls: ['./planning-wo-detail.component.less']
})
export class PlanningWoDetailComponent implements OnInit, AfterViewInit {
  private id: string;
  @Input() record: WorkOrder;
  @Input() allProductsFlat: Product[];
  public editing: boolean = false;
  public saving: boolean = false;
  public showEditor: string = null;
  public assignments: MachineAssignment[];
  public shippingAssignments: WorkOrderShippingAssignment[];
  public editingProduct: Product;
  public assigningEmployee: User = null;
  public selectedProgrammingTicket: ProgrammingTicket = null;
  @ViewChild("sidenav") sidenav: MatSidenav;
  private stationList: Station[] = null;

  constructor(private route: ActivatedRoute, private woService: WorkOrderService, public stationService: StationService, private planningSvc: PlanningService, private navService: NavigationService, private orderSvc: OrderService, private userSvc: UserService, private utilitySvc: UtilityService, private router: Router) {
    this.id = this.route.snapshot.paramMap.get('id');
  }

  public setDisposition(disposition: number): void {
    event.preventDefault();
    this.record.status = disposition;

    this.saving = true;
    this.planningSvc.saveWorkOrder(this.record).subscribe(_ => {
      this.saving = false;
    });
  }

  @Output() editWorkflow = new EventEmitter<Product>();
  public openWorkflow(): void {
    this.editWorkflow.emit(this.editingProduct);
  }

  public getStatusText(disposition: number): string {
    return OrderStatus.getStatusText(disposition);
  }

  public userIsManager(): boolean {
    return (
      this.userSvc.canAccess("PlanningManager") || this.userSvc.canAccess("Developer")
    );
  }

  public get emptyGuid(): string {
    return UtilityService.emptyGuid;
  }

  public saveAssignment(): void {
    if (!this.record) return;

    this.saving = true;
    this.woService
      .assign(this.record.workOrderId, this.assigningEmployee)
      .subscribe((_) => {
        this.saving = false;
        if (this.assigningEmployee.userId === this.userSvc.userData.userId) this.getDetail();
        else {
          // redirect to list if assigning to someone else
          this.router.navigate(this.route.snapshot.url.slice(0, -1).map(x => x.path));
        }
      });
  }

  public selectProgrammingTicket(ticket: ProgrammingTicket): void {
    this.selectedProgrammingTicket = ticket;

    this.showEditor = 'programming';
    this.sidenav.toggle();
  }

  public async getDetail(inputWorkOrder?: WorkOrder) {
    this.editingProduct = null;
    
    const workOrder = inputWorkOrder || this.record;
    this.reassigningUser = workOrder.assignedTo;

    const [machineAssignments, shippingAssignments] = await Promise.all([
      this.woService.getAssignmentsForWorkOrder(workOrder).toPromise(),
      this.woService.getShippingAssignmentsForWorkOrder(workOrder).toPromise(),
    ]);

    this.record = workOrder;

    this.assignments = machineAssignments;
    this.shippingAssignments = shippingAssignments;

    this.editingProduct = await this.orderSvc.getProduct(this.record.productId).toPromise();
  }

  @Output() savePlanning = new EventEmitter<{ product: Product }>();
  public async saveChanges() {

    // const dialogRef = this.utilitySvc.dialogService.open(
    //   WoPlanningConfirmationDialog,
    //   { disableClose: true }
    // );

    // const result = await dialogRef.afterClosed().toPromise()
    this.saving = true;

    // if (!result.ready) {
    //   this.record.status = OrderStatus.IN_PROCESS;
    // }
    // else {
    //   //TODO: will be either fulfilled or waiting on programming...
    //   this.record.status = OrderStatus.PLANNED;
    //   this.record.datePlanningFinished = new Date();
    // }

    this.savePlanning.emit({ product: this.editingProduct });

  }

  @Output() preplanningFinished = new EventEmitter<PreplanningData>();
  public async finishPreplanning(preplanningData: PreplanningData, formData: any) {
    console.log(preplanningData);
    console.log(formData);
    const r = await this.utilitySvc.showConfirmationPromise('Approve this preplanning ticket?', 'It will move to full planning and programming.');
    if (!r) return;
    this.preplanning.dirty = false;
    this.preplanningFinished.emit(preplanningData);
  }

  @ViewChild('productHierarchySort') productHierarchySortComponent: ProductHierarchySortComponent; 
  public async reorderHierarchy(data: SortedProductHierarchy[]) {
    this.saving = true;
    await this.orderSvc.reorderHierarchy(data).toPromise();
    this.saving = false;
    this.productHierarchySortComponent.getDetail();
  }

  public closeSideNav(): void {
    this.showEditor = null;
    this.navService.popBreadCrumb();

    this.getDetail();
  }

  public toggleEditing(): void {
    this.editing = !this.editing;
  }

  public getStation(stationId: string): Station {
    return this.stationList.find(s => s.stationId == stationId);
  }
  public getStationName(stationId: string): string {
    return this.stationList.find(s => s.stationId == stationId).name;
  }

  getStatusColorClass(status: number): string {
    return OrderStatus.getStatusColorClass(status);
  }

  ngOnInit(): void {
    if (this.stationService.loaded) {
      this.stationList = this.stationService.stationList;
    }
    else {
      this.stationService.stationsLoaded.subscribe(
        _ => this.stationList = this.stationService.stationList
      );
    }

    this.getDetail();
  }

  @ViewChild('tabGroup') tabGroup: MatTabGroup;
  @ViewChild('stepItems') programmingTab: MatTab;
  @ViewChild('stepItems') stepItems: WorkflowStepItemsComponent;
  @ViewChild('preplanning') preplanning: any;
  ngAfterViewInit(): void {
    console.log('tabGroup', this.tabGroup);
    console.log('preplanning', this.preplanning);
  }

  @ViewChild('outlet', { read: ViewContainerRef }) outletRef: ViewContainerRef;
  @ViewChild('content', { read: TemplateRef, static: true }) contentRef: TemplateRef<any>;
  public rerender() {
    this.outletRef.clear();
    this.outletRef.createEmbeddedView(this.contentRef);
  }

  public get dirty() {
    return (this.stepItems && this.stepItems.dirty) || (this.preplanning && this.preplanning.dirty);
  }

  public reassigningUser: User;

  @Output() reassign = new EventEmitter<User>();
  public reassignWO() {
    this.reassign.emit(this.reassigningUser);
  }

  public programmingTypeToString(type: ProgrammingType): string {
    if (type === ProgrammingType.CMMProgramming) return 'CMM'
    else if (type === ProgrammingType.NCProgramming) return 'NC'
    else if (type === ProgrammingType.CNCProgramming) return 'CNC'
  }

  public goToProgramming() {
    const i = this.tabGroup._tabs.toArray().findIndex(t => t.textLabel === 'Programming');
    console.log('goToProgramming', i)
    this.tabGroup.selectedIndex = i;
  }

  public newProgrammerAssign: User;
  public newProgrammerDueDate: Date;

  public stepsToProgram: string[] = [];
  public get programmingSetupDone() {
    return this.newProgrammerAssign;
  }

  public get programStepsList() {
    return this.record.product.workflow.workflowSteps.sort((s1, s2) => {
      if (this.stepsToProgram.includes(s1.workflowStepId)) return -1;
      else return 0;
    })
  }

  public async createProgramming() {
    this.saving = true;
    const tkt = await this.planningSvc.createProgrammingTicket(this.record, {
      assignedProgrammer: this.newProgrammerAssign.userId,
      programmingDueDate: this.newProgrammerDueDate,
      highlightedProgrammingSteps: this.stepsToProgram
    }).toPromise();
    this.record.programmingTickets = [tkt];
    this.saving = false;
  }

  // This function handles getting updated product data from the sidenav workflow edit function without overwriting user changes on this screen
  public productUpdated(product: Product) {
    // Steps:
    product.workflow.workflowSteps = product.workflow.workflowSteps.map(step => {
      const equivalentStep = this.editingProduct.workflow.workflowSteps.find(s => s.workflowStepId === step.workflowStepId);
      // Keep current description
      step.description = equivalentStep.description;
      // Keep WSIIs (though I don't think this is necessary)
      step.workflowStepInventoryItems = equivalentStep.workflowStepInventoryItems;
      // Keep inspection data
      step.inspectionIsCMM = equivalentStep.inspectionIsCMM;
      step.hasFirstPartInspection = equivalentStep.hasFirstPartInspection;
      step.firstPartInspectionTime = equivalentStep.firstPartInspectionTime;
      step.firstPartInspectionRate = equivalentStep.firstPartInspectionRate;
      step.hasInspection = equivalentStep.hasInspection;
      step.inspectionIsBatched = equivalentStep.inspectionIsBatched;
      step.inspectionBatchSize = equivalentStep.inspectionBatchSize;
      step.inspectionTime = equivalentStep.inspectionTime;
      step.inspectionRate = equivalentStep.inspectionRate;
      step.inspectionNotes = equivalentStep.inspectionNotes;
      return step;
    });
    // Deep clone and replace editing product
    this.editingProduct = JSON.parse(JSON.stringify(product));
  }

  @Output() revertPreplanning = new EventEmitter<void>();
  public async returnToPreplanning() {
    const r = await this.utilitySvc.showConfirmationPromise('Are you sure?', 'Really return to preplanning?');
    if (!r) return;
    this.stepItems.dirty = false;
    this.revertPreplanning.emit();
  }

  @Output() donePlanning = new EventEmitter<void>();
  public async finishPlanning() {
    const r = await this.utilitySvc.showConfirmationPromise('Are you sure?', 'Really finish planning and release part to floor?');
    if (!r) return;
    this.donePlanning.emit();
  }

  public get allPlanned() {
    return this.editingProduct?.workflow.workflowSteps.every(s => s.planningStatus === WorkflowStepPlanningStatus.PLANNED);
  }

  public get editable() {
    return this.record?.status === 0 || this.record?.status === 21;
  }

}


@Component({
  selector: "wo-planning-confirmation-dialog",
  templateUrl: "wo-planning-confirmation.dialog.html",
})
export class WoPlanningConfirmationDialog { }
