import { Shift }  from "./shift";
import { Time } from "@angular/common";
import { ShiftAssignmentRecurrence } from "./shift-assignment-recurrence";


export class EmployeeShiftAssignment {
  employeeShiftAssignmentId?: number;

  title: string;
  color: string;

  shiftId: number;
  shift: Shift;

  startDate?: Date;
  endDate?: Date;

  resourceId: string;   //UserId

  notes: string;
  jsonConfiguration: string;


  shiftAssignmentRecurrenceId: number;
  shiftAssignmentRecurrence: ShiftAssignmentRecurrence;
}
