import { Component, OnInit, ViewChild, ChangeDetectorRef, Input, OnChanges, SimpleChanges, TemplateRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Vendor, VendorContact, VendorMaterialGroup, VendorCertificate, VendorServiceOffering, VendorAddress, VendorQualityClause } from '../../../supplier/resources/vendor';
import { MatSidenav } from '@angular/material/sidenav';
import { NgForm, NgModel } from '@angular/forms';
import { Customer } from '../../../customer/resources/customer';
import { NavigationService } from '../../../common/services/navigation.service';
import { UtilityService } from '../../../common/services/utility.service';
import { StationService } from '../../../order/services/station.service';
import { Station } from '../../../order/resources/station';
import { MaterialGroup } from '../../../order/resources/material';
import { MaterialService } from '../../../order/services/material.service';
import { VendorService } from '../../../supplier/services/vendor.service';
import { StepperSelectionEvent } from '@angular/cdk/stepper';
import { Contact } from '../../../common/resources/contact';
import { QualityClause } from '../../resources/quality-clause';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'vendor-detail',
  templateUrl: './vendor-detail.component.html',
  styleUrls: ['./vendor-detail.component.less']
})
export class VendorDetailComponent implements OnInit, OnChanges {
  private id: string;
  private loaded: boolean = false;
  public record: Vendor;
  public editing: boolean = false;
  public contactsView: boolean = false;
  public newContact: VendorContact;
  public materialGroupList: MaterialGroup[];
  public outsourcedStationsList: Station[];
  public qualityClauseList: QualityClause[];
  public approvedSupplierList: Customer[] = null;
  public selectedCert: VendorCertificate = null;
  public showEditor: string = null;
  public saving: boolean = false;
  public selectedTab: number = 0;
  @Input() vendorId: string = null;
  @Input() sidenav: MatSidenav = null;
  @ViewChild('insetnav') insetnav: MatSidenav;
  @ViewChild('name') vendorName: NgModel;

  constructor(private materialService: MaterialService,
    private vendorService: VendorService, private route: ActivatedRoute, private router: Router, private navService: NavigationService, private utilitySvc: UtilityService, private changeDetector: ChangeDetectorRef, private stationSvc: StationService, private dialog: MatDialog) {
    this.id = this.route.snapshot.paramMap.get('id');
  }

  private getDetail(): void {
    this.record = null;
    if (this.id == "new" || this.id == UtilityService.emptyGuid) {
      this.editing = true;
      this.record = <Vendor>{
        vendorId: UtilityService.emptyGuid,
        vendorCertificates: [],
        vendorMaterialGroups: [],
        vendorAddresses: [],
        vendorContacts: [],
        vendorServiceOfferings: [],
        approvedSupplierList: [],
        vendorQualityClauses: [],
        defaultTaxRate: 0
      };

      this.approvedSupplierList = [];

      this.setPageTitle();
      this.loaded = true;
    }
    else {
      this.vendorService.getDetail(this.id).subscribe(detail => {
        this.record = detail;
        this.setPageTitle();

        if (this.record.primaryAddressId) {
          this.selectedAddress = this.record.vendorAddresses.find(va => va.addressId === this.record.primaryAddressId);
        } else if (this.record.vendorAddresses.length > 0) {
          this.selectedAddress = this.record.vendorAddresses[0]
        }

        this.vendorService.getApprovedVendorCustomers(this.record).subscribe(result => this.approvedSupplierList = result);
        this.loaded = true;
      });
    }

  }

  public delete(): void {
    if (this.record && this.record.vendorId != UtilityService.emptyGuid) {
      this.utilitySvc.showConfirmation("Are you sure?", "<p>Are you sure you want to remove this vendor?</p><p class='text-muted'>This can also affect purchase orders and other items that potentially use this vendor.</p>", r => {
        if (r) {
          this.saving = true;
          this.vendorService.delete(this.record).subscribe(_ => {
            this.router.navigate(['/supplier']);
            this.saving = false;
          });
        }
      })
    }
  }

  public setSelectedTab(e: StepperSelectionEvent): void {
    this.selectedTab = e.selectedIndex;
  }

  public goToCustomer(customer: Customer) {
    this.router.navigate(['/customer', customer.customerId]);
  }
  
  public toggleEditing(): void {
    if (this.id == "new" || this.id == UtilityService.emptyGuid) {
      if (this.sidenav) {
        this.sidenav.close();
      }
      else {
        this.router.navigate(['/supplier']);
      }
    }

    this.getDetail();
    this.editing = !this.editing;
  }

  public toggleContactsView(): void {
    this.contactsView = !this.contactsView;

    if (this.contactsView) {
      this.navService.pushBreadcrumb("Contacts");
    }
    else {
      this.navService.popBreadCrumb();
    }
  }

  private setPageTitle(): void {
    if (!this.sidenav) this.navService.setPageTitle("Supplier Detail");
    if (this.loaded) this.navService.popBreadCrumb();
    this.navService.pushBreadcrumb(this.record.name || "New Supplier");
  }

  public getOverallScore(): number {
    return Math.floor(((this.record.onTimeScore || 0) + (this.record.qualityScore || 0) + (this.record.subjectiveScore || 0)) / 3);
  }

  public getStatusColorClass(date: Date): string {
    return VendorService.getStatusColorClass(date);
  }

  public getScoreStyles(score: number, fill?: boolean): any {
    return VendorService.getScoreStyles(score, fill || false);
  }

  public onCertificateUpdated(_cert: VendorCertificate): void {
    this.insetnav.close();
    this.getDetail();
  }

  public deleteCert(cert: VendorCertificate): void {
    event.stopImmediatePropagation();

    this.utilitySvc.showConfirmation("Are you Sure?", "<p>Are you sure you want to remove this certificate?</p><p class='text-muted font-weight-bold'>This cannot be undone.</p>", (r => {
      if (r) {
        this.saving = true;
        this.vendorService.removeCertificate(cert).subscribe(_ => {
          this.saving = false;
          this.getDetail();
        });
      }
    }));
  }

  public closeSideNav(): void {
    this.showEditor = this.selectedCert = null;
    this.navService.popBreadCrumb();
  }

  public addCertification(): void {
    this.openCertificate(<VendorCertificate>{ vendorCertificateId: UtilityService.emptyGuid });
  }

  public openCertificate(cert: VendorCertificate): void {
    if (this.vendorName.invalid) {
      this.vendorName.control.markAsTouched();

      this.utilitySvc.showAlert("Vendor Name is Required", "<p>Please make sure a vendor name is entered before continuing.</p>");
      return;
    }

    this.selectedCert = cert;
    this.showEditor = "cert";
    this.navService.pushBreadcrumb(cert.certificateName);
    this.insetnav.toggle();
  }

  public hasMaterialCapability(groupId: string): boolean {
    return this.record.vendorMaterialGroups.findIndex(g => g.materialGroupMaterialGroupId == groupId) >= 0;
  }

  private setMaterialCapability(groupId: string, state: boolean): void {
    var capabilityExists = this.hasMaterialCapability(groupId);
    if (state && !capabilityExists) {
      //Add it
      this.record.vendorMaterialGroups.push(<VendorMaterialGroup>{ materialGroupMaterialGroupId: groupId, vendorVendorId: this.record.vendorId });

      this.saving = true;
      this.vendorService.addCapability(this.record, groupId).subscribe(_ => this.saving = false);
    }
    else if (!state && capabilityExists) {
      //Remove it
      this.record.vendorMaterialGroups.splice(this.record.vendorMaterialGroups.findIndex(g => g.materialGroupMaterialGroupId == groupId), 1);

      this.saving = true;
      this.vendorService.removeCapability(this.record, groupId).subscribe(_ => this.saving = false);
    }
  }

  public toggleMaterialCapability(groupId: string): void {
    event.stopImmediatePropagation();

    if (this.editing) {
      if (this.vendorName.invalid) {
        this.vendorName.control.markAsTouched();

        this.utilitySvc.showAlert("Vendor Name is Required", "<p>Please make sure a vendor name is entered before continuing.</p>");
        return;
      }

      if (this.record.vendorId == UtilityService.emptyGuid) {
        this.saving = true;
        this.vendorService.save(this.record).subscribe(detail => {
          this.record = detail;
          this.saving = false;

          this.toggleMaterialCapability(groupId);
        });
      }
      else {
        this.setMaterialCapability(groupId, !this.hasMaterialCapability(groupId));

        this.changeDetector.detectChanges();
      }
    }
  }

  private setOutsourceCapability(stationId: string, state: boolean): void {
    var capabilityExists = this.hasOutsourceCapability(stationId);
    if (state && !capabilityExists) {
      //Add it
      this.record.vendorServiceOfferings.push(<VendorServiceOffering>{ stationStationId: stationId, vendorVendorId: this.record.vendorId });

      this.saving = true;
      this.vendorService.addServiceOffering(this.record, stationId).subscribe(_ => this.saving = false);
    }
    else if (!state && capabilityExists) {
      //Remove it
      this.record.vendorServiceOfferings.splice(this.record.vendorServiceOfferings.findIndex(s => s.stationStationId == stationId), 1);

      this.saving = true;
      this.vendorService.removeServiceOffering(this.record, stationId).subscribe(_ => this.saving = false);
    }
  }

  public toggleOutsourceCapability(stationId: string): void {
    event.stopImmediatePropagation();

    if (this.editing) {
      if (this.vendorName.invalid) {
        this.vendorName.control.markAsTouched();

        this.utilitySvc.showAlert("Supplier Name is Required", "<p>Please make sure a supplier name is entered before continuing.</p>");
        return;
      }

      if (this.record.vendorId == UtilityService.emptyGuid) {
        this.saving = true;
        this.vendorService.save(this.record).subscribe(detail => {
          this.record = detail;
          this.saving = false;

          this.toggleOutsourceCapability(stationId);
        });
      }
      else {
        this.setOutsourceCapability(stationId, !this.hasOutsourceCapability(stationId));

        this.changeDetector.detectChanges();
      }
    }
  }

  public hasOutsourceCapability(stationId: string): boolean {
    return this.record.vendorServiceOfferings.findIndex(s => s.stationStationId == stationId) >= 0;
  }

  public clearContact(): void {
    this.newContact = <VendorContact>{ 
      vendorContactId: UtilityService.emptyGuid,
      useForQuoting: false,
      contact: {
        contactId: UtilityService.emptyGuid,
        name: "",
        title: "",
        email: "",
        note: "",
        phoneExtension: "",
        phoneNumber: "",
        faxNumber: ""
      }
    };
  }

  public async saveContact(contact: VendorContact) {
    if (this.vendorName.invalid) {
      this.vendorName.control.markAsTouched();
      this.utilitySvc.showAlert("A Valid Supplier Name is Required", "<p>Please add a vendor name before proceeding.</p>");
      return;
    }
    if (!contact.contact.name || contact.contact.name.length < 2) {
      this.utilitySvc.showAlert("A Valid Contact Name is Required", "<p>Please add a name before proceeding.</p>");
      return;
    }

    this.saving = true;
    if (this.record.vendorId == UtilityService.emptyGuid) {
      const detail = await this.vendorService.save(this.record).toPromise();
      this.record = detail;
      this.id = detail.vendorId;
    }
    this.vendorService.saveContact(this.record, contact).subscribe(_ => {
      this.saving = false;
      this.clearContact();
      this.getDetail();
    });
  }

  public saveChanges(finishEditingWhenDone?: boolean): void {
    finishEditingWhenDone = finishEditingWhenDone || false;

    if (this.vendorName.invalid) {
      this.vendorName.control.markAsTouched();
      this.utilitySvc.showAlert("A Valid Supplier Name is Required", "<p>Please add a vendor name before proceeding.</p>");
      return;
    }

    if (!!this.selectedAddress && this.addressForm) {
      if (this.addressForm.invalid) {
        for(let i in this.addressForm.controls) {
          this.addressForm.controls[i].markAsTouched();
        }
        this.utilitySvc.showAlert("Invalid Address", "<p>Please correct errors in the selected address before proceeding.</p>");
        return;
      }
  
      if (this.addressForm.dirty) {
        this.utilitySvc.showAlert("Invalid Address", "<p>Please save the selected address before proceeding.</p>");
        return;
      }
    }

    this.saving = true;
    this.vendorService.save(this.record)
      .subscribe(detail => {
        this.record = detail;
        this.saving = false;
        if (finishEditingWhenDone) {
          this.editing = false;
          if (this.sidenav) {
            this.sidenav.close();
          }
        }
      });
  }

  public deleteContact(contact: VendorContact): void {
    this.utilitySvc.showConfirmation("Confirm Delete Contact",
      `<p>Are you sure you want to delete the contact '<em>${contact.contact.name}</em>'?</p><p class='font-weight-bold text-center'>This cannot be undone.</p>`
      , (result => {
        if (result === true) {
          this.saving = true;
          this.vendorService.deleteContact(contact).subscribe(_ => {
            this.saving = false;
            this.getDetail();
          });
        }
      }));
  }

  public vendorCanDo(typ: string): boolean {
    switch (typ) {
      case 'materials':
        return this.record.vendorMaterialGroups && this.record.vendorMaterialGroups.filter(g => g.materialGroup.groupName != 'Hardware').length > 0;
      case 'purchases':
        return this.record.vendorMaterialGroups && this.record.vendorMaterialGroups.find(g => g.materialGroup.groupName == 'Hardware') != null;
      case 'outsourced':
        return this.record.vendorServiceOfferings && this.record.vendorServiceOfferings.length > 0;
      default:
        return false;
    }
  }

  public getQcClauseApplication(clause: QualityClause): {
    hardware: boolean,
    material: boolean,
    process: boolean,
    override: boolean,
  } {
    const override = this.record.vendorQualityClauses.find(x => x.qualityClauseQualityClauseId === clause.qualityClauseId);
    if (!override) return {
      override: false,
      hardware: clause.hardwareDefault,
      material: clause.materialDefault,
      process: clause.processDefault,
    }; else return {
      override: override.forHardware !== clause.hardwareDefault || 
        override.forMaterial !== clause.materialDefault ||
        override.forProcess !== clause.processDefault,
      hardware: override.forHardware,
      material: override.forMaterial,
      process: override.forProcess,
    }
  }

  @ViewChild('qcClauseDialogTemplate', { static: true }) qcClauseDialogTemplate: TemplateRef<any>;
  public async editQcClause(clause: QualityClause) {
    const existingOverride = this.record.vendorQualityClauses.find(x => x.qualityClauseQualityClauseId === clause.qualityClauseId);
    const data = existingOverride || {
      vendorVendorId: this.record.vendorId,
      qualityClauseQualityClauseId: clause.qualityClauseId,
      qualityClause: clause,
      forHardware: clause.hardwareDefault,
      forMaterial: clause.materialDefault,
      forProcess: clause.processDefault,
    }
    const dialogRef = this.dialog.open(this.qcClauseDialogTemplate, {
      disableClose: true,
      width: '400px',
      data,
    });
    const result = await dialogRef.afterClosed().toPromise();
    const { action, newData }: { action: string, newData?: VendorQualityClause } = result;
    if (action === 'cancel') return;
    else if (action === 'clear') {
      this.saving = true;
      await this.vendorService.resetVendorClause(this.record, clause.qualityClauseId).toPromise();
      this.record.vendorQualityClauses = this.record.vendorQualityClauses.filter(x => x.qualityClauseQualityClauseId !== clause.qualityClauseId);
      this.saving = false;
      return;
    } else if (action === 'save') {
      this.saving = true;
      await this.vendorService.updateVendorClause(this.record, clause, newData).toPromise();
      this.saving = false;
      this.getDetail();
      return;
    }

  }

  ngOnInit(): void {
    this.clearContact();
    if (this.vendorId) this.id = this.vendorId;

    this.getDetail();
    this.materialService.getMaterialGroups().subscribe(r => this.materialGroupList = r.results);

    this.vendorService.searchQualityClauses('', 0, 1000).subscribe(r => this.qualityClauseList = r.results);

    this.stationSvc.stationsLoaded.asObservable().subscribe(r => {
      if (r) this.outsourcedStationsList = this.stationSvc.stationList.filter(s => s.isOutsourceStep);
    });
    if (this.stationSvc.loaded) { this.outsourcedStationsList = this.stationSvc.stationList.filter(s => s.isOutsourceStep); }
  }

  ngOnChanges(_: SimpleChanges): void {
    if (this.vendorId) this.id = this.vendorId;

    this.getDetail();
  }

  // 
  selectedAddress: VendorAddress | null = null;

  previousAddressState: VendorAddress | null = null;

  public get creatingAddress() {
    return this.selectedAddress && this.selectedAddress.vendorAddressId === UtilityService.emptyGuid;
  }
  @ViewChild('addressForm') addressForm: NgForm;
  public async switchToAddress(address: VendorAddress) {
    if (this.selectedAddress && address.vendorAddressId === this.selectedAddress.vendorAddressId) return;
    if (this.addressForm.dirty || this.creatingAddress) {
      const response = await this.utilitySvc.showConfirmationPromise(
        'Change address?', 
        'If you switch addresses, all changes made to the current address will be lost.',
        );
      if (response === false) return;
      else if (this.creatingAddress) {
        this.record.vendorAddresses = this.record.vendorAddresses.filter(x => x.vendorAddressId !== UtilityService.emptyGuid);
      }
    }
    if (this.previousAddressState) Object.assign(this.selectedAddress, this.previousAddressState);
    this.selectedAddress = address;
    this.previousAddressState = JSON.parse(JSON.stringify(address));
    this.addressForm.form.markAsPristine();
    this.addressForm.form.markAsUntouched();
  }
  public async newAddress() {
    if (this.creatingAddress) return;
    if (this.addressForm.dirty) {
      const response = await this.utilitySvc.showConfirmationPromise(
        'Create new address?', 
        'If you switch addresses, all changes made to the current address will be lost.',
        );
      if (response === false) return;
    }
    this.previousAddressState = null;

    const addressNumber = this.record.vendorAddresses.length + 1;

    this.selectedAddress = {
      vendorId: this.record.vendorId,
      vendorAddressId: UtilityService.emptyGuid,
      addressId: UtilityService.emptyGuid,
      address: {
        addressId: UtilityService.emptyGuid,
        nickname: `Address ${addressNumber}`,
        streetAddress: "",
        streetAddress2: "",
        city: "",
        state: "",
        postalCode: "",
        phoneNumber: "",
        faxNumber: "",
        contact: null,
        contactId: null,
      }
    }
    this.record.vendorAddresses.push(this.selectedAddress);
    this.addressForm.form.markAsPristine();
    this.addressForm.form.markAsUntouched();
  }

  public setAddressContactId(c: Contact) {
    this.selectedAddress.address.contactId = c && c.contactId;
  }

  public async saveAddress(a: VendorAddress) {
    if (this.vendorName.invalid) {
      this.vendorName.control.markAsTouched();
      this.utilitySvc.showAlert("A Valid Supplier Name is Required", "<p>Please add a vendor name before proceeding.</p>");
      return;
    }
    this.saving = true;
    if (this.record.vendorId == UtilityService.emptyGuid) {
      const detail = await this.vendorService.save(this.record).toPromise();
      this.record = detail;
      this.id = detail.vendorId;
    }
    const newAddress = await this.vendorService.saveAddress(this.record, a).toPromise();
    this.record = await this.vendorService.getDetail(this.id).toPromise();
    Object.assign(this.selectedAddress, newAddress);
    this.addressForm.form.markAsPristine();
    this.saving = false;
  } 

  public async deleteCurrentAddress() {
    if (this.creatingAddress) {
      this.selectedAddress = null;
      this.record.vendorAddresses = this.record.vendorAddresses.filter(x => x.vendorAddressId !== UtilityService.emptyGuid);
    } else {
      const confirm = await this.utilitySvc.showConfirmationPromise('Delete address?', 'Are you sure? This cannot be undone.');
      if (!confirm) return;
      this.saving = true;
      await this.vendorService.deleteAddress(this.selectedAddress).toPromise();
      this.record.vendorAddresses = this.record.vendorAddresses.filter(x => x.vendorAddressId !== this.selectedAddress.vendorAddressId);
      this.selectedAddress = null;
      this.saving = false;
    }
  }

  public onPrimaryAddressClick(e: MouseEvent, id: string) {
    e.preventDefault();
    e.stopPropagation();
    this.record.primaryAddressId = id;
  }


}
