import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { NavigationService } from '../../../common/services/navigation.service';
import { UtilityService } from '../../../common/services/utility.service';
import { MaterialService } from '../../../order/services/material.service';
import { Material, MaterialGroup, MaterialSpecification, MaterialMaterialSpecification, MaterialHardness, MaterialType, MaterialAlloy, MaterialDimension } from '../../../order/resources/material';
import { Router } from '@angular/router';
import evalInScope from '../../../../../util/evalInScope'


@Component({
  selector: 'material-editor',
  templateUrl: './material-editor.component.html',
  styleUrls: ['./material-editor.component.less']
})
export class MaterailEditorComponent implements OnInit {
  public selectedMaterial: Material = null;
  public saving: boolean = false;
  @Input() sidenav: MatSidenav;
  @ViewChild('insetnav', { static: true }) insetNav: MatSidenav;

  public showEditor: string = null;

  constructor(private navService: NavigationService, private materialSvc: MaterialService, private utilitySvc: UtilityService, private router: Router) {
    this.navService.clearBreadCrumbs();
    this.navService.pushBreadcrumb("Material Editor");
  }

  public close(): void {
    if (this.sidenav)
      this.sidenav.close();
    else
      this.router.navigate(['/admin']);
  }

  public select(material: Material): void {
    this.selectedMaterial = material;
    this.setupDimensions(material)
  }

  public get generatedName(): string {
    return Material.generatedName(this.selectedMaterial);
  }

  public addMaterial(): void {
    this.selectedMaterial = <Material>{
      materialId: UtilityService.emptyGuid,
      materialGroupId: null,
      materialMaterialSpecifications: [],
      materialDimensions: [],
    };
  }

  public async save() {

    if (!this.selectedMaterial.materialGroupId || !this.selectedMaterial.materialTypeId || !this.selectedMaterial.materialHardnessId || !this.selectedMaterial.materialMaterialSpecifications) {
      this.utilitySvc.showAlert("Please Complete All Fields!", "<p>Materials must be completely defined before they can be saved.</p><p>Not finding what you're looking for? Most specifications can be added from this screen.</p>");
      return;
    }

    if (this.selectedMaterial.materialGroup == null || this.selectedMaterial.materialGroupId == null) {
      this.utilitySvc.showAlert("A Material Group is Required", "<p>Please select a material group before saving.</p><p class='text-muted'>You can add material groups if you need to.</p>");
      return;
    }

    this.saving = true;

    // Take a deep copy of the MMSs. While saving, we're removing the materialSpecification property,
    // and we want to hold them here so we can send them in separately
    let saveList = <MaterialMaterialSpecification[]>JSON.parse(JSON.stringify(this.selectedMaterial.materialMaterialSpecifications, (key, value) => {
      // Remove material to take out a circular dependency
      if (key === 'material') {
        return null;
      } else {
        return value;
      }
    }));

    // Same for Material Dimensions
    let dimensionSaveList = <MaterialDimension[]>JSON.parse(JSON.stringify(this.selectedMaterial.materialDimensions, (key, value) => {
      // Remove material to take out a circular dependency
      if (key === 'material') {
        return null;
      } else {
        return value;
      }
    }));

    // Deep copy the material for sending to the service - otherwise we get a lot of errors in the component from properties being nulled
    const materialCopy = <Material>JSON.parse(JSON.stringify(this.selectedMaterial, (key, value) => {
      // Remove material to take out a circular dependency
      if (key === 'material') {
        return null;
      } else {
        return value;
      }
    }));
    // Save the material
    const initialMaterial = await this.materialSvc.save(materialCopy).toPromise();
    const { materialMaterialSpecifications } = await this.materialSvc.saveMaterialMaterialSpecifications(saveList, initialMaterial).toPromise();
    const { materialDimensions } = await this.materialSvc.bulkSaveMaterialDimensions(dimensionSaveList, initialMaterial).toPromise();
    initialMaterial.materialMaterialSpecifications = materialMaterialSpecifications;
    initialMaterial.materialDimensions = materialDimensions;
    this.selectedMaterial = initialMaterial;
    this.saving = false;
  }

  public canRemove(): boolean {
    return this.selectedMaterial != null && this.selectedMaterial.materialId != UtilityService.emptyGuid;
  }

  public remove(): void {
    this.utilitySvc.showConfirmation(`Remove Material '${Material.generatedName(this.selectedMaterial)} '?`, "<p>Are you sure you want to remove this material?</p><p class='text-muted'>Removing materials will impact ALL orders and workflows using this material.</p>", r => {
      if (r) {
        this.saving = true;
        this.materialSvc.remove(this.selectedMaterial.materialId).subscribe(_ => {
          this.selectedMaterial = null;
          this.saving = false;
        });
      }
    });
  }

  public closeSideNav(): void {
    this.showEditor = null;
    this.navService.clearBreadCrumbs();
    this.navService.pushBreadcrumb("Material Editor");
  }

  public addMaterialType(): void {
    this.showEditor = 'type';
    this.insetNav.open();
  }

  public addMaterialHardness(): void {
    this.showEditor = 'hardness';
    this.insetNav.open();
  }

  public addMaterialSpec(): void {
    this.showEditor = 'spec';
    this.insetNav.open();
  }

  public addMaterialGroup(): void {
    this.showEditor = 'group';
    this.insetNav.open();
  }

  public addMaterialAlloy(): void {
    this.showEditor = 'alloy';
    this.insetNav.open();
  }

  public setGroup(group: MaterialGroup) {
    this.selectedMaterial.materialGroupId = group.materialGroupId;
    this.selectedMaterial.materialGroup = group;
  }

  public setSpecs(specs: MaterialMaterialSpecification[]) {
    for (let spec of specs) {
      spec.materialId = this.selectedMaterial.materialId;
      spec.material = this.selectedMaterial;
    }
    this.selectedMaterial.materialMaterialSpecifications = specs;
  }

  public async newSpec(name: string) {
    this.saving = true;
    const createdSpec = await this.materialSvc.saveMaterialSpecification({ 
      materialSpecificationId: UtilityService.emptyGuid,
      name,
    }).toPromise();
    this.setSpecs([...this.selectedMaterial.materialMaterialSpecifications, {
      materialMaterialSpecificationId: UtilityService.emptyGuid,
      material: null,
      materialId: null,
      materialSpecification: createdSpec,
      materialSpecificationId: createdSpec.materialSpecificationId,
    }])
    this.saving = false;
  }

  public setType(materialType: MaterialType) {
    this.selectedMaterial.materialTypeId = materialType.materialTypeId;
    this.selectedMaterial.materialType = materialType;
    this.setupDimensions(this.selectedMaterial);
  }

  public setHardness(hardness: MaterialHardness) {
    this.selectedMaterial.materialHardnessId = hardness.materialHardnessId;
    this.selectedMaterial.materialHardness = hardness;
  }

  public setAlloy(alloy: MaterialAlloy) {
    this.selectedMaterial.materialAlloyId = alloy.materialAlloyId;
    this.selectedMaterial.materialAlloy = alloy;
  }

  public setupDimensions(material: Material) {
    if (!material.materialType) return;
    const { materialTypeDimensions } = material.materialType;
    // remove dimensions that are not represented in the materialTypeDimensions
    material.materialDimensions = material.materialDimensions.filter(md => materialTypeDimensions.findIndex(mtd => mtd.materialTypeDimensionId === md.materialTypeDimensionId) > -1)
    // add blank dimensions for all materialTypeDimensions that aren't represented yet
    materialTypeDimensions.forEach(mtd => {
      if (!material.materialDimensions.some(md => md.materialTypeDimensionId === mtd.materialTypeDimensionId)) {
        material.materialDimensions.push({
          materialId: material.materialId,
          material,
          materialTypeDimensionId: mtd.materialTypeDimensionId,
          materialTypeDimension: mtd,
          value: 0
        })
      }
    })
  }

  public get selectedVolume(): number {
    const cleanFormula = this.selectedMaterial.materialType.volumeFormula? this.selectedMaterial.materialType.volumeFormula.trim().toLowerCase().replace(/\^/g, '**') : null
    if (!cleanFormula) return
    if (!this.selectedMaterial.materialType.materialTypeDimensions) return

    const scope = (this.selectedMaterial.materialType.materialTypeDimensions.map(mtd => {
      const label = mtd.dimensionType.label.toLowerCase()
      const dimension = this.selectedMaterial.materialDimensions.find(
        md => md.materialTypeDimensionId === mtd.materialTypeDimensionId
      )
      if (!dimension) return [label, 0]
      const { siEquivalent } = dimension.materialTypeDimension.dimensionUnit
      const value = dimension.value
      return [label, value]
    }))
      .reduce((obj, [key, val]) => {
        obj[key] = val
        return obj
      }, {})

    const result = evalInScope(cleanFormula, scope)
    return result
  }

  ngOnInit(): void {
    this.materialSvc.typeDimensionsUpdated.subscribe(materialType => {
      if (this.selectedMaterial.materialTypeId === materialType.materialTypeId) {
        this.selectedMaterial.materialType = materialType
        this.setupDimensions(this.selectedMaterial)
      }
    })
  }
}
