<loading *ngIf="saving" content="Saving..."></loading>

<div class="border rounded position-relative d-flex flex-column" style="height: 100%;" *ngIf="record" [ngStyle]="{filter: record.status === 15 ? 'grayscale(1) blur(1px)' : '', 'pointer-events': record.status === 15 ? 'none' : 'all' }">
  <div class="p-3 mt-4 d-flex align-items-center">
    <h3>WO # {{ record.workOrderNumber }}</h3>
    <div class="ml-auto">
      <employee-search [readonly]="!editable" label="WO Assigned To" [(selectedItem)]="reassigningUser"></employee-search>
    </div>
    <button *ngIf="editable" mat-stroked-button class="button-text ml-1 my-1 d-block" style="height: 59px;" [disabled]="!reassigningUser || reassigningUser.userId === record.userId" [class.text-primary]="reassigningUser && reassigningUser.userId !== record.userId" (click)="reassignWO()">
      Reassign
    </button>
  </div>
  <div style="    position: absolute;
  z-index: 9999;
  right: 16px;
  top: -29px;">
    <ng-container *ngIf="(tabGroup?.selectedIndex === 0 && record.status === 21) || record.status == 0">
      <div class="d-flex">
        <button mat-fab color="primary" class="extended-fab-button bg-success text-white mr-3" *ngIf="!stepItems?.dirty && record.status === 0 && allPlanned" (click)="finishPlanning()">
          <mat-icon>logout</mat-icon>
          <span class="extended-fab-button__text">Finish Planning</span>
        </button>
        <button mat-fab color="primary" class="extended-fab-button bg-primary text-white mr-3" *ngIf="!stepItems?.dirty && record.status === 0" (click)="returnToPreplanning()">
          <mat-icon>undo</mat-icon>
          <span class="extended-fab-button__text">Return to Preplan</span>
        </button>
        <button mat-fab class="extended-fab-button bg-success text-white mr-3" *ngIf="stepItems?.dirty" (click)="saveChanges()">
          <mat-icon>logout</mat-icon>
          <span class="extended-fab-button__text">Save Changes</span>
        </button>
        <button mat-fab class="extended-fab-button bg-success text-white mr-3" *ngIf="preplanning?.form.valid && record.status === 21" (click)="finishPreplanning(preplanning?.preplanningData, preplanning?.form.value)">
          <mat-icon>logout</mat-icon>
          <span class="extended-fab-button__text">Finish Preplanning</span>
        </button>
        <button mat-fab color="primary" class="extended-fab-button bg-primary text-white" (click)="openWorkflow()">
          <mat-icon>edit</mat-icon>
          <span class="extended-fab-button__text">Edit Part Info</span>
        </button>
      </div>
    </ng-container>
  </div>
  <ng-container #outlet [ngTemplateOutlet]="content">
  </ng-container>
</div>

<ng-template #content>
  <mat-tab-group #tabGroup class="flex-grow-1" id="planning-tab-group" style="height: 0;">
    <mat-tab label="Preplanning" *ngIf="(record.status === 21 || record.status === 15) && stationService.stationsLoaded">
      <preplanning-detail #preplanning [parentComponent]="this" [(workOrder)]="record" [product]="editingProduct" *ngIf="editingProduct"></preplanning-detail>
      <div class="w-100 h-100 d-flex align-items-center justify-content-center" *ngIf="!editingProduct">
        <mat-spinner diameter="120" class="text-primary"></mat-spinner>
      </div>
    </mat-tab>
    <mat-tab label="Workflow Planning" *ngIf="!(record.status === 21 || record.status === 15)">
      <div class="w-100 h-100 d-flex align-items-center justify-content-center" *ngIf="!editingProduct">
        <mat-spinner diameter="120" class="text-primary"></mat-spinner>
      </div>
      <div class="h-100-t px-3" *ngIf="editingProduct">
        <workflow-step-items #stepItems [childProducts]="allProductsFlat" (workOrderEdit)="openWorkflow()" [product]="editingProduct"
          [steps]="editingProduct.workflow.workflowSteps" [repairPlan]="editingProduct.productRepairPlan"
          [workOrder]="record" [(machineAssignments)]="assignments" [editing]="editable" (programTab)="goToProgramming()"></workflow-step-items>
      </div>
    </mat-tab>
    <mat-tab label="Order Info">
      <div class="p-4">
        <order-info-detail [record]="record.order"></order-info-detail>
      </div>
    </mat-tab>
    <mat-tab label="Part Documentation">
      <div class="pt-4">
        <file-display-grid [editable]="false" [files]="editingProduct?.documents"></file-display-grid>
        <p *ngIf="editingProduct?.documents?.length==0" class="text-muted text-center">
          <em>-- No Documents --</em>
        </p>
      </div>
    </mat-tab>
    <mat-tab label="Material" *ngIf="!(record.status === 21 || record.status === 15)">
      <div class="pt-4 h-100">
        <material-select [disabled]="true" [material]="editingProduct?.material" #materialSelect>
        </material-select>
      </div>
    </mat-tab>
    <!-- <mat-tab #programmingTab label="Programming" *ngIf="!(record.status === 21 || record.status === 15)">
      <div class="p-3">
        <div *ngIf="record.programmingTickets[0]; else noTickets">
          <div class="row">
            <div class="col-12">
              <h4>Programming Ticket Data</h4>
            </div>
            <div class="col-4 form-group">
              <label class="d-block">Status</label>
              <div class="badge" [ngClass]="getStatusColorClass(record.programmingTickets[0].status)">
                {{ getStatusText(record.programmingTickets[0].status) }}
              </div>
            </div>
            <div class="col-4 form-group">
              <label class="d-block">Assigned To</label>
              <div>
                {{ record.programmingTickets[0].assignedUser.fullName }}
              </div>
            </div>
            <div class="col-4 form-group">
              <label class="d-block">Due Date</label>
              <div>
                {{ (record.programmingTickets[0].dueDate | date: 'shortDate') || '--' }}
              </div>
            </div>
          </div>
        </div>
        <ng-template #noTickets>
          <div class="px-3 py-1">
            <div class="row mb-2 align-items-center">
              <div class="col-8 m-0">
                <h4>
                  Create Programming Ticket for Part
                </h4>
              </div>
              <div class="col-4">
                <button mat-button class="button-text" class.bg-primary="programmingSetupDone" class.text-white="programmingSetupDone" [disabled]="!programmingSetupDone" (click)="createProgramming()">
                  Create Programming Ticket
                </button>
              </div>
            </div>
            <div class="mb-2 row">
              <div class="col-8">
                <employee-search [label]="'Assigned Programmer'" [(selectedItem)]="newProgrammerAssign"></employee-search>
              </div>
              <div class="col-4">
                <date-picker appearance="outline" [editable]="true" [(date)]="newProgrammerDueDate" [placeholder]="'Programming Due Date'"></date-picker>
              </div>
            </div>
            <h5>
              Highlight Possible Steps for Programming
            </h5>
            <div class="border rounded" style="height: 500px; overflow-y: scroll">
              <mat-selection-list class="program-list p-0" [(ngModel)]="stepsToProgram" #programList>
                <mat-list-option [value]="step.workflowStepId" *ngFor="let step of programStepsList" class="border-bottom" [class.option-selected]="programList.selectedOptions.isSelected(option)" #option>
                  <div class="program-station-text d-flex align-items-center">
                    <mat-icon class="mr-1">{{ getStation(step.stationId)?.isOutsourceStep ? 'logout' : 'settings' }}</mat-icon>
                    {{ getStation(step.stationId)?.name }}
                    <mat-icon class="ml-3 mr-1 text-muted" *ngIf="step.hasProgramming">info</mat-icon>
                    <span class="text-muted small font-italic" *ngIf="step.hasProgramming">Has Programming Estimated</span>
                    <span class="text-muted small font-italic" *ngIf="!step.hasProgramming && step.hasInspection && step.inspectionIsCMM">Has CMM Programming Estimated</span>
                  </div>
                </mat-list-option>
              </mat-selection-list>
            </div>
          </div>
        </ng-template>
      </div>
    </mat-tab> -->
  </mat-tab-group>
</ng-template>