import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { NavigationService } from '../../../common/services/navigation.service';
import { Building, Department } from '../../../floor/resources/building';
import { UtilityService } from '../../../common/services/utility.service';
import { NgModel } from '@angular/forms';
import { FloorService } from '../../../floor/services/floor.service';
import { Router } from '@angular/router';

@Component({
  selector: 'department-editor',
  templateUrl: './department-editor.component.html',
  styleUrls: ['./department-editor.component.less']
})
export class DepartmentEditorComponent implements OnInit {
  public selectedDepartment: Department = null;
  public saving: boolean = false;
  public buildings: Building[];
  @Input() sidenav: MatSidenav;
  @ViewChild('name') name: NgModel;

  constructor(private navService: NavigationService, private floorService: FloorService, private utilitySvc: UtilityService, private router: Router) {
    this.navService.clearBreadCrumbs();
    this.navService.pushBreadcrumb("Department Editor");
  }

  public close(): void {
    if (this.sidenav)
      this.sidenav.close();
    else
      this.router.navigate(['/admin']);
  }

  public select(bld: Department): void {
    this.selectedDepartment = bld;
  }

  public addDepartment(): void {
    this.selectedDepartment = <Department>{
      departmentId: UtilityService.emptyGuid,
      name: 'New Department'
    };
  }

  public save(): void {
    if (this.name.invalid) {
      this.name.control.markAsTouched();
      this.utilitySvc.showAlert("A Valid Department Name is Required", "<p>Please add a department name before saving.</p>");
      return;
    }

    this.saving = true;
    this.floorService.save(this.selectedDepartment).subscribe(result => {
      this.selectedDepartment = result;
      this.saving = false;
    });
  }

  public canRemove(): boolean {
    return this.selectedDepartment != null && this.selectedDepartment.departmentId != UtilityService.emptyGuid;
  }

  public remove(): void {
    this.utilitySvc.showConfirmation(`Remove Department '${this.selectedDepartment.name}'?`, "<p>Are you sure you want to remove this department?</p><p class='text-muted'>Removing departments will impact ALL departments and planning that use this department.</p>", r => {
      if (r) {
        this.saving = true;
        this.floorService.remove(this.selectedDepartment).subscribe(_ => {
          this.selectedDepartment = null;
          this.saving = false;
        });
      }
    });
  }

  public get buildingsSorted() {
    return this.buildings && this.buildings.sort((a, b) => {
      const aIn = this.buildingIsInDepartment(a);
      const bIn = this.buildingIsInDepartment(b);
      const aN = aIn ? 1 : 0;
      const bN = bIn ? 1 : 0;
      return bN - aN;
    });
  }

  public buildingIsInDepartment(building: Building) {
    return this.selectedDepartment.floorBuildings.some(fb => fb.buildingId === building.buildingId);
  }

  public async addBuildingToDepartment(building: Building) {
    const r = await this.utilitySvc.showConfirmationPromise(`Add building ${building.name} to department ${this.selectedDepartment.name}?`, 
    "");
    if (!r) return;
    this.saving = true;
    const newDeptData = await this.floorService.createFloorBuilding(this.selectedDepartment, building).toPromise();
    this.selectedDepartment.floorBuildings = newDeptData.floorBuildings;
    this.saving = false;
  }

  public async removeBuildingFromDepartment(building: Building) {
    const r = await this.utilitySvc.showConfirmationPromise(
    `Remove building ${building.name} from department ${this.selectedDepartment.name}?`, 
    "<p>Are you sure you want to do this?</p><p class='text-danger'>This will impact ALL machines and planning that use this department; <b>a lot of data may be lost.</b></p>",
    3);
    if (!r) return;
    const floorBuilding = this.selectedDepartment.floorBuildings.find(
      fb => fb.buildingId === building.buildingId
    );
    if (!floorBuilding) return;
    this.saving = true;
    await this.floorService.removeFloorBuilding(floorBuilding.floorBuildingId).toPromise();
    this.selectedDepartment.floorBuildings = this.selectedDepartment.floorBuildings.filter(
      fb => fb.floorBuildingId !== floorBuilding.floorBuildingId
    );
    this.saving = false;
  }

  ngOnInit() {
    this.floorService.searchBuildings('').subscribe(r => {
      this.buildings = r.results;
    })
  }

}
