<div class="container-fluid">
  <!-- <a class="position-fixed fixed-bottom btn btn-badge" title="Create New Record" routerLink="/inventory/new">+</a> -->

  <div class="row border">
    <search-bar class="w-100" [(searchString)]="searchString" [showManagerBar]="false" (search)="doSearch($event)">
    </search-bar>

    <div *ngIf="!results">
      <loading></loading>
    </div>

    <search-result *ngIf="results" [results]="searchResults" class="w-100" (newPage)="getPage($event)"
      (selected)="onSelect($event)">
      <thead header-template>
        <tr class="text-uppercase text-muted">
          <th (click)="sortBy('type')">
            Item Type
            <i [ngClass]="getSortIconFor('type')"></i>
          </th>
          <th (click)="sortBy('name')">
            Name
            <i [ngClass]="getSortIconFor('name')"></i>
          </th>
          <th (click)="sortBy('labels')">
            Labels
            <i [ngClass]="getSortIconFor('labels')"></i>
          </th>
          <th (click)="sortBy('notes')">
            Notes
            <i [ngClass]="getSortIconFor('notes')"></i>
          </th>
          <th class="text-right">
            <inventory-location-selection (selectionChanged)="setLocationFilter($event)" [locationList]="locationList"
              [selectedList]="selectedList"></inventory-location-selection>
          </th>
        </tr>
      </thead>
      <ng-template let-row>
        <td>
          <item-type-chip [item]="row"></item-type-chip>
        </td>
        <td>{{row.name}}</td>
        <td>
          <mat-chip-list #chipList aria-label="Inventory Item Labels">
            <mat-chip title="Inventory Item Label" class="text-secondary small" *ngFor="let label of row.labels">
              {{label}}
            </mat-chip>
          </mat-chip-list>
        </td>
        <td>{{row.notes}}</td>
        <td></td>
      </ng-template>
    </search-result>
  </div>

</div>
