import { Component, EventEmitter, Output, Input, ViewChild, ElementRef, Directive } from '@angular/core';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { Subscription, Observable } from 'rxjs';
import { SearchResult } from '../../resources/search-result';


@Directive()
export abstract class GenericSelectComponent<T> {
  @Input() selection: T;
  @Output() selectionChange: EventEmitter<T> = new EventEmitter<T>();
  @Input() editable: boolean;
  @ViewChild('inputText', { static: true }) inputTxt: ElementRef;
  @ViewChild('autoCompleteTrigger', { static: true }) autoCompleteTrigger: MatAutocompleteTrigger;
  @Output() onAddItem: EventEmitter<string> = new EventEmitter<string>();
  private request: Subscription = null;
  private lastVal: string = null;
  public searching: boolean = false;

  public values: T[] = [];

  constructor() { }

  protected abstract onSelect(value: T): void;

  public abstract formatValue(item: T): string;

  public abstract formatSearchResult(item: T): string;

  public abstract get addItemText(): string;

  public abstract get noItemsText(): string;

  public abstract get placeholderText(): string;

  public abstract canAdd(): boolean;

  public abstract testSelection(_: any): void;

  protected abstract searchFunction(text: string): Observable<SearchResult<T>>;

  public setValue(v: T): void {
    this.selection = v;
    if (v == null)
      return;

    this.onSelect(this.selection);

    this.selectionChange.emit(this.selection);
  }

  public toggleDropdown(): void {
    event.stopImmediatePropagation();
    if (this.autoCompleteTrigger.panelOpen) {
      this.autoCompleteTrigger.closePanel();
    }
    else {
      this.search(null);
      this.autoCompleteTrigger.openPanel();
    }
  }

  public onAdd(): void {
    event.stopImmediatePropagation();
    this.onAddItem.emit(this.lastVal);
    this.autoCompleteTrigger.closePanel();
  }

  public search(_: any) {
    this.searching = true;
    if (this.request != null) {
      this.request.unsubscribe();
    }

    this.lastVal = this.inputTxt.nativeElement.value
    this.request = this.searchFunction(this.lastVal)
      .subscribe(result => {
        this.searching = false;
        this.values = result.results
      });
  }
}