<div class="pt-3">
  <a class="position-fixed fixed-bottom btn btn-badge bg-danger has-icon" title="Close" (click)="close()"><i class="fas fa-times"></i></a>

  <loading *ngIf="saving" content="Saving..."></loading>

  <div class="container-fluid pb-4">

    <div class="p-0 m-0 row">
      <div class="col-4">
        <material-group-list (selected)="select($event)"></material-group-list>
        <div class="mt-2">
          <div class="text-right">
            <button class="btn btn-success" (click)="add()">
              <i class="fas fa-fw la-lg fa-plus"></i>
              Add Material Group
            </button>
          </div>
        </div>
      </div>
      <div class="col-8">
        <div *ngIf="!selected">
          <p class="text-muted text-center font-italic p-5">-- Select a Material Group to View its Details --</p>
        </div>
        <div *ngIf="selected">
          <div class="container-fluid">
            <div class="row">
              <div class="col">
                <div class="form-group">
                  <label>Group Name</label>
                  <input type="text" required min="3" [(ngModel)]="selected.groupName" class="form-control" placeholder="Group Name" />
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <div class="form-group">
                  <label>Optimal Material Removal Rate</label>
                  <div class="input-group input-group-sm">
                    <input type="number" step="0.01" min="0" [(ngModel)]="selected.removalRate" class="form-control form-control-sm" placeholder="Removal Rate" />
                    <div class="input-group-append">
                      <span class="input-group-text"># / min</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="float-right mt-4 pt-4">
            <button class="btn btn-danger mr-3" *ngIf="canRemove()" (click)="remove()">
              <i class="fas fa-fw fa-lg fa-minus-circle"></i>
              Delete
            </button>
            <button class="btn btn-success" [disabled]="!selected.groupName" (click)="save()">
              <i class="fas fa-fw fa-lg fa-save"></i>
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
