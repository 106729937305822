<div class="container-fluid">
  <div class="row border">
    <search-bar class="w-100" [showManagerBar]="hasPermission('PlanningManager')" [managerView]="managerView" (managerViewChanged)="setManagerView($event)" [(searchString)]="searchString" (search)="doSearch($event)"></search-bar>

    <div *ngIf="!results"><loading></loading></div>

    <search-result *ngIf="results" [results]="searchResults" class="w-100" (newPage)="getPage($event)" (selected)="onSelect($event)">
      <thead header-template>
        <tr class="text-uppercase text-muted">
          <th (click)="sortBy('number')">
            Ticket Number
            <i [ngClass]="getSortIconFor('number')"></i>
          </th>
          <th (click)="sortBy('customer')">
            Customer
            <i [ngClass]="getSortIconFor('customer')"></i>
          </th>
          <th (click)="sortBy('poNumber')">
            PO #
            <i [ngClass]="getSortIconFor('poNumber')"></i>
          </th>
          <th (click)="sortBy('rootWO')">
            Root WO#
            <i [ngClass]="getSortIconFor('rootWO')"></i>
          </th>
          <th (click)="sortBy('required')">
            Required
            <i [ngClass]="getSortIconFor('required')"></i>
          </th>
          <th (click)="sortBy('status')">
            Status
            <i [ngClass]="getSortIconFor('status')"></i>
          </th>
        </tr>
      </thead>
      <ng-template let-row>
        <td>{{row.planningTicketNumber}}</td>
        <td>{{row.businessName}}</td>
        <td>{{row.purchaseOrderNumber}}</td>
        <td>{{row.rootWorkOrderNumber}}</td>
        <td class="text-center">
          <span *ngIf="row.requiredDate!=null" class="badge required" [ngClass]="getRequiredColor(row.requiredDate)">
            {{row.requiredDate | date}}
            <span class="d-block small">{{weeksLeft(row.requiredDate, true)||'--'}} Week(s) {{(weeksLeft(row.requiredDate, false)||0) >= 0 ? 'From Today':'Overdue'}}</span>
          </span>
          <span *ngIf="row.requiredDate==null" class="text-muted">
            --
          </span>
        </td>
        <td class="text-center">
          <span class="badge status text-uppercase" [ngClass]="getStatusColorClass(row.status)">
            {{getStatusText(row.status)}}
          </span>
        </td>
      </ng-template>
    </search-result>
  </div>

</div>
