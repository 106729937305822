<div>
    <a *ngIf="record && !editing && showEditor==null" class="position-fixed fixed-bottom btn btn-badge has-icon"
        title="Edit Record" (click)="toggleEditing()"><i class="fas fa-edit"></i></a>
    <a *ngIf="record && editing && showEditor==null"
        class="position-fixed fixed-bottom btn btn-badge bg-danger has-icon mr-6" title="Cancel Edit"
        (click)="toggleEditing()"><i class="fas fa-times"></i></a>
    <a *ngIf="record && record.vendorId && editing && showEditor==null"
        class="position-fixed fixed-bottom btn btn-badge bg-success has-icon" title="Save Record"
        (click)="saveChanges(true)"><i class="fas fa-save"></i></a>

    <loading *ngIf="!record"></loading>
    <loading *ngIf="saving" content="Saving..."></loading>

    <div class="row m-0">
        <mat-sidenav-container *ngIf="record">
            <mat-sidenav disableClose (closedStart)="closeSideNav()" #sidenav mode="over" position="end">
                <upload-file *ngIf="showEditor=='document'" (documentsLoaded)="addDocuments($event)"
                    [sidenav]="sidenav"></upload-file>
                <vendor-detail *ngIf="showEditor=='addVendor'" [vendorId]="'new'" [sidenav]="sidenav"></vendor-detail>
                <supplier-detail *ngIf="showEditor=='workOrder'" [id]="selectedOrderOrReqId" [type]="selectedQuoteType"
                    [parentSidenav]="sidenav"></supplier-detail>
            </mat-sidenav>
            <mat-sidenav-content>

                <div class="col-12">
                    <div class="mt-2 row">
                        <div class="col">
                            <p *ngIf="!record.vendorId" class="mt-4 text-muted"><i
                                    class="fas fa-lg fa-fw text-info fa-info-circle mr-2"></i>To create a bulk quote,
                                first select a vendor.</p>
                            <span class="font-weight-bold text-muted">Vendor</span>
                            <div>
                                <vendor-drop-select [(selection)]="record.vendor" (selectionChange)="setVendor($event)"
                                    (onAddItem)="addVendor()" [editable]="editing && this.record.status === 0"></vendor-drop-select>
                            </div>
                            <span class="font-weight-bold text-muted">Doing Business As</span>
                            <div>
                                <company-search [editing]="editing" [(ngModel)]="record.company" (ngModelChange)="record.companyId = $event?.companyId"></company-search>
                            </div>
                        </div>
                        <div class="col text-center" *ngIf="record.vendorId">
                            <span class="font-weight-bold text-muted">View</span>
                            <div>
                                <mat-checkbox [disabled]="!vendorCanDo('materials')" class="mr-4"
                                    [checked]="isSelected('materials')" (change)="setSelection($event, 'materials')">
                                    Materials</mat-checkbox>
                                <mat-checkbox [disabled]="!vendorCanDo('outsourced')" class="mr-4"
                                    [checked]="isSelected('outsourced')" (change)="setSelection($event, 'outsourced')">
                                    Processes</mat-checkbox>
                                <mat-checkbox [disabled]="!vendorCanDo('purchases')" [checked]="isSelected('purchases')"
                                    (change)="setSelection($event, 'purchases')">Hardware</mat-checkbox>
                            </div>
                        </div>
                        <div class="col text-right" *ngIf="record.vendorId">
                            <span class="font-weight-bold text-muted">Show</span>
                            <div class="toggle">
                                <span>All Items</span>
                                <mat-slide-toggle #vendorOnly [checked]="true"
                                    (change)="toggleView($event.checked)">Vendor Capable</mat-slide-toggle>
                            </div>
                        </div>
                    </div>
                    <div class="table table-striped" *ngIf="record.vendorId">
                        <div
                            class="row bg-secondary text-secondary small font-weight-bold border-top border-bottom p-2">
                            <div class="col text-center">Material/Item</div>
                            <div class="col text-center">Needed For</div>
                            <div class="col text-center">Qty Required</div>
                            <div class="col text-right"><button class="btn btn-sm btn-info"><i
                                        class="fas fa-fw fa-print mr-1"></i>Export</button></div>
                        </div>
                        <div>
                            <div *ngIf="isSelected('materials')">
                                <div class="bg-secondary text-uppercase text-white text-center p-1 m-2">Materials</div>
                                <div *ngIf="materials?.length>0; else emptySet">
                                    <div *ngFor="let material of materials" class="small stripe">
                                        <ng-container *ngTemplateOutlet="rowTemplate; context: {material: material}">
                                        </ng-container>
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="isSelected('outsourced')">
                                <div class="bg-secondary text-uppercase text-white text-center p-1 m-2">Processes</div>
                                <div *ngIf="outsourced?.length>0; else emptySet">
                                    <div *ngFor="let outsource of outsourced" class="small stripe">
                                        <ng-container *ngTemplateOutlet="rowTemplate; context: {material: outsource}">
                                        </ng-container>
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="isSelected('purchases')">
                                <div class="bg-secondary text-uppercase text-white text-center p-1 m-2">Hardware</div>
                                <div *ngIf="purchases?.length>0; else emptySet">
                                    <div *ngFor="let purchase of purchases" class="small stripe">
                                        <ng-container *ngTemplateOutlet="rowTemplate; context: {material: purchase}">
                                        </ng-container>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row form-group p-3" *ngIf="record.vendorId">
                    <div class="col-12 p-0 bg-light">
                        <h5 class="w-100 p-2 bg-secondary">Notes</h5>
                        <div class="p-2">
                            <textarea #notes placeholder="Notes" (change)="setNote(notes)"
                                class="form-control form-control-sm" appStaticEdit [editable]="editing"
                                [value]="record.bids[0]?.note"></textarea>
                        </div>
                    </div>
                </div>
                <div class="row form-group p-3" *ngIf="record.vendorId">
                    <div class="col-12 p-0 bg-light">
                        <h5 class="w-100 p-2 bg-secondary">Documents</h5>
                        <div class="p-2">
                            <file-display-grid (delete)="deleteDocument($event)" [editable]="editing"
                                [files]="record.bids[0]?.materialBidDocuments"></file-display-grid>
                            <p *ngIf="record.bids[0]?.materialBidDocuments?.length==0" class="text-muted text-center">
                                <em>-- No Documents --</em></p>
                            <a class="btn btn-badge bg-success has-icon float-right" *ngIf="editing"
                                title="Add Document" (click)="openDocumentWindow()"><i class="fas fa-plus mt-2"></i></a>
                        </div>
                    </div>
                </div>
            </mat-sidenav-content>
        </mat-sidenav-container>
    </div>
</div>


<ng-template #rowTemplate let-material="material">
    <div class="row p-1 cursor-pointer" [ngClass]="{
        'bg-primary text-white': material.isOpen,
        'text-muted': !vendorCanDoItem(material.item)
    }" (click)="material.isOpen=!material.isOpen" *ngIf="(vendorCanDoItem(material.item) || !showVendorOnly || id != 'new') && (getSelectedWOCount(material.item) > 0 || this.record.status === 0)">
        <div class="col">
            <i class="text-muted fas fa-fw" [ngClass]="{'fa-plus': !material.isOpen, 'fa-minus': material.isOpen}"></i>
            {{getItemName(material.item)}}
        </div>
        <div class="col">
          {{getSelectedWOCount(material.item)}}/{{material.data.length}}
          Workorders/Reqs Selected</div>
        <div class="col">{{getSelectedQty(material.item)}}/{{getTotalQty(material.data)}} Qty Selected</div>
    </div>
    <div *ngIf="material.isOpen" class="ml-4 bg-white">
        <div class="row bg-secondary text-secondary font-weight-bold">
            <div class="col">WO/REQ#</div>
            <div class="col">Selected/Total Qty</div>
            <div class="col">Quoted Price</div>
            <div class="col">Lead Time</div>
            <div class="col">Required By</div>
        </div>
        <div class="row stripe" *ngFor="let woReq of material.data">
            <div class="col">
                <a href="javascript:void(0)" (click)='openRelevantOrder(woReq)'>{{getReqNumber(woReq)}}</a>
            </div>
            <div class="col form-inline">
                <mat-checkbox class="mr-2" #useMe [ngClass]="{'d-none':!editing || id!='new'}" [checked]="getBidFor(woReq)!=null" (change)="toggleReq($event, selectedQty, woReq)"></mat-checkbox>
                <input appStaticEdit [value]="getBidFor(woReq)?.qty || 0" [editable]="editing" class="pl-2 form-control form-control-sm text-right" (change)="setQty(selectedQty, woReq)" #selectedQty type="number" step="1" min="0" max="woReq.totalQuantity" value="0" [disabled]="!useMe.checked" />
                &nbsp;of {{woReq.qty}}
            </div>
            <div class="col p-1">
                <div class="input-group input-group-sm">
                    <div class="input-group-prepend">
                        <span class="input-group-text"><strong>$</strong></span>
                    </div>
                    <input type="number" #quoteVal min="0" step=".01" (change)="setQuote(quoteVal, woReq)" [value]="getBidFor(woReq)?.totalBid" appStaticEdit [editable]="editing" placeholder="Quoted Price" class="pl-2 form-control form-control-sm" />
                </div>
            </div>
            <div class="col p-1">
                <div class="input-group input-group-sm">
                    <input type="number" #leadTime min="0" step="1" (change)="setLeadTime(leadTime, woReq)" [value]="getBidFor(woReq)?.leadTimeDays" appStaticEdit [editable]="editing" placeholder="Lead Time" class="form-control form-control-sm text-right" />
                    <div class="input-group-append">
                        <span class="input-group-text"><strong>Days</strong></span>
                    </div>
                </div>
            </div>
            <div class="col">
                <date-picker placeholder="Date Required" #requiredBy (dateChange)="setRequiredDate(requiredBy, woReq, material.data)" [date]="getBidFor(woReq, material.data)?.requiredBy" [editable]="editing"></date-picker>
            </div>
        </div>
    </div>
</ng-template>


<ng-template #noData><span class="text-muted font-italic">--</span></ng-template>
<ng-template #emptySet>
    <div class="text-muted text-center font-italic small">-- No Records --</div>
</ng-template>