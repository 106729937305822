<div class="container-fluid main">
  <div class="row">
    <div class="col-6">
      <a class="m-2 clickable buildings" routerLink="/purchasing/prerequisites">
        <h3>Prerequisites</h3>
      </a>
    </div>
    <div class="col-6">
      <a class="m-2 clickable buildings" routerLink="/purchasing/orders">
        <h3>Purchase Orders</h3>
      </a>
    </div>
    <div class="col-6">
      <a class="m-2 clickable buildings" routerLink="/purchasing/orders/unassigned">
        <h3>Unassigned POs</h3>
      </a>
    </div>
    <div class="col-6">
      <a class="m-2 clickable buildings" routerLink="/purchasing/bulkquotes">
        <h3>Bulk Quotes</h3>
      </a>
    </div>
  </div>
</div>