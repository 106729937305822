import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { Observable } from 'rxjs';
import { NavigationService } from '../../../common/services/navigation.service';
import { ShippingService } from '../../../shipping/services/shipping.service';
import { Building } from '../../../floor/resources/building';
import { InspectionTicket, InspectionType } from '../../resources/inspection-ticket';
import { FloorService } from '../../../floor/services/floor.service';
import { StationService } from '../../../order/services/station.service';
import { Station } from '../../../order/resources/station';
import { QualityService } from '../../service/quality.service';
import { Router } from '@angular/router';
import { OrderStatus } from '../../../order/resources/order';
import { NgModel } from '@angular/forms';
import { debounceTime, tap } from 'rxjs/operators';

const BUILDING_ID_KEY = 'inspection-building';

@Component({
  selector: 'inspection-queue',
  templateUrl: './inspection-queue.component.html',
  styleUrls: ['./inspection-queue.component.less']
})
export class InspectionQueueComponent implements OnInit {

  $buildings: Observable<Building[]>
  currentBuilding: Building;

  @ViewChild('sidenav') sidenav: MatSidenav;
  public todayTickets: InspectionTicket[];

  constructor(
    navService: NavigationService,
    shippingService: ShippingService,
    private qualityService: QualityService,
    private stationService: StationService,
    private floorService: FloorService,
    private router: Router
  ) {
    navService.setPageTitle('Floor');
    navService.pushBreadcrumb("Inspection");
    this.$buildings = shippingService.$buildings;
  }

  public getStation(stationId: string): Station {
    if (!this.stationService.stationList) return null;

    return this.stationService.stationList.find(s => s.stationId == stationId);
  }

  public selectBuilding(b: Building) {
    if (!this.currentBuilding || this.currentBuilding.buildingId != b.buildingId) {
      this.todayTickets = null;
      this.qualityService.getInspectionTodayTickets(b.buildingId).subscribe(tickets => { this.todayTickets = tickets }); 
    }
    this.currentBuilding = b;
    localStorage.setItem(BUILDING_ID_KEY, b.buildingId);
  }

  public selectTicket(ticket: InspectionTicket) {
    this.router.navigate(['/quality/inspection/', ticket.inspectionTicketId])
  }

  public getExpectedTime(ticket: InspectionTicket) {
    if (ticket.inspectionType == InspectionType.FIRST_PART) return ticket.machineAssignment.scheduledStart;
    if (ticket.inspectionType == InspectionType.FINAL_INSPECTION) return ticket.machineAssignment.scheduledEnd;
  }

  public getInspectionTypeText(ticket: InspectionTicket) {
    if (ticket.inspectionType == 0 && !ticket.requiresReport) return 'First Part Inspection';
    if (ticket.inspectionType == 0 && ticket.requiresReport) return 'FAIR';
    if (ticket.inspectionType == 1) return 'Lot Inspection';
  }

  public getInspectionMethodText(ticket: InspectionTicket) {
    if (ticket.isCMM) return 'CMM Inspection';
    else return 'Manual Inspection';
  }

  public isToday(dateParam: Date) {
    const date = new Date(dateParam);
    const now = new Date();
    return date.getFullYear() === now.getFullYear() &&
    date.getMonth() === now.getMonth() &&
    date.getDate() === now.getDate();
  }

  public isOverdue(dateParam: Date) {
    const date = new Date(dateParam);
    const now = new Date();
    // one hour after ready = overdue
    return (now.getTime() - date.getTime()) > (60 * 60 * 1000)
  }

  public getStatusBackgroundColorClass(status: OrderStatus) {
    switch (status) {
      case OrderStatus.QUEUED:
        return 'bg-warning';
      case OrderStatus.IN_PROCESS:
        return 'bg-danger';
      case OrderStatus.AWAIT_PROGRAMMING:
        return 'bg-light';
      default:
        return 'bg-dark';
    }
  }

  public getStatusColorClass(status: OrderStatus) {
    switch (status) {
      case OrderStatus.QUEUED:
        return 'text-white';
      case OrderStatus.IN_PROCESS:
        return 'text-white';
      case OrderStatus.AWAIT_PROGRAMMING:
        return 'text-dark';
      default:
        return 'text-white';
    }
  }

  public getStatusText(status: OrderStatus) {
    switch (status) {
      case OrderStatus.QUEUED:
        return 'Await WO';
      case OrderStatus.IN_PROCESS:
        return 'Ready';
      case OrderStatus.AWAIT_PROGRAMMING:
        return 'Programming';
      case OrderStatus.HISTORY:
        return 'History';
      default:
        return '';
    }
  }

  public searching = false;
  public searchFilter = '';
  public doSearch() {
    this.searching = true;
    this.qualityService.getInspectionTodayTickets(this.searchFilter).subscribe(tickets => {
      this.todayTickets = tickets;
      this.searching = false;
    }); 
  }
  @ViewChild('filterModel') filterModel: NgModel;
  ngAfterViewInit() {
    if (!this.filterModel || !this.filterModel.valueChanges) return;
    this.filterModel.valueChanges.pipe(
      tap(() => this.searching = true),
      debounceTime(250)
    ).subscribe(() => this.doSearch());
  }

  ngOnInit() {
    // const lastBuildingUsed = localStorage.getItem(BUILDING_ID_KEY);
    // if (lastBuildingUsed) {
    //   this.floorService.getBuilding(lastBuildingUsed).subscribe(b => {
    //     if (b) this.selectBuilding(b);
    //   })
    // }
    this.qualityService.getInspectionTodayTickets(this.searchFilter).subscribe(tickets => {
      this.todayTickets = tickets;
    }); 
  }

}
