<div class="btn-group" *ngIf="editable; else readonly">  
  <a class="btn btn-light btn-sm dropdown-toggle" href="#" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
    {{selection?selection.name:'Select Building...'}}
  </a>
  <div class="dropdown-menu" aria-labelledby="dropdownMenuLink">
    <a *ngFor="let building of buildings" href="#" class="dropdown-item" (click)="selectBuilding(building)">{{building.name}}</a>
  </div>
</div>

<ng-template #readonly>
  <span class="text-muted small">{{selection?selection.name:'-- No Building Selected --'}}</span>
</ng-template>
