import { Component, Input, OnInit, OnChanges, SimpleChanges } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { UtilityService } from '../../../common/services/utility.service';
import { ShippingService } from '../../services/shipping.service';
import { InventoryService } from '../../../inventory/services/inventory.service';
import { InventoryItemLocation } from '../../../inventory/resources/inventory-item-location';
import { NavigationService } from '../../../common/services/navigation.service';
import { ShippingInventoryItem } from '../../resources/shipping-ticket';

@Component({
  selector: 'shipping-pick-location-editor',
  templateUrl: './shipping-pick-location-editor.component.html',
  styleUrls: ['./shipping-pick-location-editor.component.less']
})
export class ShippingPickLocationEditorComponent implements OnInit, OnChanges {
  @Input() ticketId: string;
  @Input() inventoryItemId: string;
  @Input() requiredQuantity: number;
  @Input() picklLocations: ShippingInventoryItem[];
  @Input() sidenav: MatSidenav;

  public saving: boolean = false;
  public locations: InventoryItemLocation[] = null;
  private selections: any = {};

  constructor(private utilitySvc: UtilityService, private shippingService: ShippingService, private inventorySvc: InventoryService, navService: NavigationService) {
    navService.pushBreadcrumb("Requisition Items");
  }

  private getData(): void {
    this.locations = null;
    this.selections = {};
    this.inventorySvc.getItemLocations(this.inventoryItemId).subscribe(results => {
      this.locations = results;

      if (this.picklLocations) {
        this.picklLocations.forEach(l => {
          this.selections[l.inventoryItemLocationId] = l.quantity;
        });
      }
    });
  }

  public cancel(): void {
    this.sidenav.close();
  }

  public save(): void {
    if (this.totalSelected > this.requiredQuantity) {
      this.utilitySvc.showAlert("Invalid Quantity", "<p>You cannot requisition more than is required for shipment.</p><p class='text-muted'>Please check your quantities before continuing.</p>");
      return;
    }

    this.saving = true;

    var newPickLocations: ShippingInventoryItem[] = [];
    const keys = Object.keys(this.selections);
    for (var key of keys) {
      newPickLocations.push(<ShippingInventoryItem>{
        shippingTicketInventoryItemId: UtilityService.newGuid(),
        inventoryItemLocationId: key,
        shippingTicketId: this.ticketId,
        quantity: this.selections[key]
      });
    }

    this.shippingService.savePickLocations(this.ticketId, newPickLocations).subscribe(_ => {
      this.saving = false;

      this.sidenav.close();
    });

  }

  public setQuantity(locationId: string, event: any): void {
    const value = parseInt(event.target.value);
    if (isNaN(value) || value < 0 || value > event.target.max || Math.ceil(value) != value)
      return; //Bad input, just ignore it...

    this.selections[locationId] = value;
  }

  public get totalSelected(): number {
    const keys = Object.keys(this.selections);
    var total = 0;
    for (var key of keys) {
      total += this.selections[key];
    }

    return total;
  }

  ngOnInit() {
    this.getData();
  }

  ngOnChanges(_changes: SimpleChanges): void {
    this.getData();
  }
}
