<search-list-new
  matSort
  [defaultSort]="{
    id: 'required',
    start: 'desc'
  }"
  [showManagerToggle]="false"
  [resultGetter]="search.bind(this)"
  [displayedColumns]="['partNumber', 'quoteNumber']"
  (itemSelected)="onSelect($event)"
  localStorageId="microticket-queue"
>
  <ng-container matColumnDef="partNumber">
    <mat-header-cell *matHeaderCellDef>Part Number</mat-header-cell>
    <mat-cell *matCellDef="let row">
      <span class="font-weight-bold mat-light-text">
        {{row.product.partNumber}} Rev. {{ row.product.revision }}
      </span>
    </mat-cell>
  </ng-container>
  <ng-container matColumnDef="quoteNumber">
    <mat-header-cell *matHeaderCellDef>AQN</mat-header-cell>
    <mat-cell *matCellDef="let row">
      <span class="font-weight-bold mat-light-text">
        {{row.orderSegment?.quoteNumber }}
      </span>
    </mat-cell>
  </ng-container>
</search-list-new>
