<div class="row">
  <div class="col-6">
    <mat-card-title>Quoted Items</mat-card-title>
  </div>
  <div class="col-6">
    <mat-card-title>Ordered Items</mat-card-title>
  </div>
</div>
<div class="row" style="height: 58vh;">
  <div class="col-6" style="height: 100%">
    <mat-card class="border rounded mat-elevation-z0 p-0 h-100"
      style="cursor: unset; overflow: hidden; height: 100% !important; overflow-y: scroll;">
      <table mat-table [dataSource]="quoteLineItems" #quoteLineItemsTable="matTable" class="w-100 sticky-table">

        <ng-container matColumnDef="description">
          <th mat-header-cell *matHeaderCellDef>Item</th>
          <td mat-cell *matCellDef="let item" class="font-weight-bold"
            style="font-size: 0.75em; max-width: 200px; text-overflow: ellipsis;">
            {{
            printName(item)
            }}
          </td>
        </ng-container>

        <ng-container matColumnDef="unitPrice">
          <th mat-header-cell *matHeaderCellDef>Unit Price</th>
          <td mat-cell *matCellDef="let item"> {{item.unitPrice | currency }} </td>
        </ng-container>

        <ng-container matColumnDef="extPrice">
          <th mat-header-cell *matHeaderCellDef>Ext. Price</th>
          <td mat-cell *matCellDef="let item">{{item.extPrice | currency }} </td>
        </ng-container>

        <ng-container matColumnDef="leadTime">
          <th mat-header-cell *matHeaderCellDef>Lead Time</th>
          <td mat-cell *matCellDef="let item">
            <abbr
              [title]="'Earliest Possible Delivery Date: ' + (getEarliestPossibleDeliveryTime(item) | date: 'longDate')"
              *ngIf="!item.station">{{item.leadTimeDays}} days</abbr>
          </td>
        </ng-container>

        <ng-container matColumnDef="quoted">
          <th mat-header-cell *matHeaderCellDef>Quoted</th>
          <td mat-cell *matCellDef="let item" style="text-align: center;"> {{item.quantity}} </td>
        </ng-container>

        <ng-container matColumnDef="ordered">
          <th mat-header-cell *matHeaderCellDef>Ordered</th>
          <td mat-cell *matCellDef="let item" style="text-align: right;"> {{getOrderedQuantity(item)}}/{{item.quantity}}
          </td>
        </ng-container>

        <ng-container matColumnDef="released">
          <th mat-header-cell *matHeaderCellDef>Released</th>
          <td mat-cell *matCellDef="let item" style="text-align: right;"> {{getOrderedLTAQuantity(item)}}/{{item.quantity}}
          </td>
        </ng-container>

        <ng-container matColumnDef="error">
          <th mat-header-cell *matHeaderCellDef style="text-align: center;">&nbsp;</th>
          <td mat-cell *matCellDef="let item" style="min-width:60px !important;">
            <button mat-icon-button class="text-danger" *ngIf="quantityMismatch(item) && !item.quantityOverride"
              matTooltip="Quantity mismatch detected. Click to override warning" matTooltipPosition="after"
              (click)="item.quantityOverride = true">
              <mat-icon>report</mat-icon>
            </button>
            <span *ngIf="!quantityMismatch(item) && !item.quantityOverride">
              &nbsp;
            </span>
            <button mat-icon-button class="text-muted" *ngIf="item.quantityOverride"
              matTooltip="Quantity mismatch warning overriden. Click to undo" matTooltipPosition="after"
              (click)="item.quantityOverride = false">
              <mat-icon>report_off</mat-icon>
            </button>
          </td>
        </ng-container>

        <ng-container matColumnDef="add">
          <th mat-header-cell *matHeaderCellDef style="width: 10%">&nbsp;</th>
          <td mat-cell *matCellDef="let item">
            <button mat-flat-button color="primary" class="small-button" (click)="addPOLineItem(item)">
              <mat-icon>arrow_forward</mat-icon>
            </button>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedQuoteLineItemColumns"></tr>
        <tr mat-row *matRowDef="let item; columns: displayedQuoteLineItemColumns;"></tr>
      </table>
      <div *ngIf="isLoading" style="display: flex; justify-content: center; align-items: center; background: white;">
        <mat-progress-bar color="primary" mode="indeterminate">
        </mat-progress-bar>
      </div>
    </mat-card>
  </div>
  <div class="col-6" style="height: 100%">
    <mat-card class="border rounded mat-elevation-z0 p-0 h-100"
      style="cursor: unset; overflow: hidden; height: 100% !important; overflow-y: scroll;">
      <table mat-table [dataSource]="poLineItems" #poLineItemsTable="matTable" class="w-100 sticky-table" ngForm
        #poLineItemsForm="ngForm">

        <div *ngIf="poLineItems.length === 0" class="w-100 text-align-center text-muted font-italic small">Add PO line
          items using the quote line items to the left.</div>

        <ng-container matColumnDef="lineNumber">
          <th mat-header-cell *matHeaderCellDef style="width: 13.553314%">Line Item #</th>
          <td mat-cell *matCellDef="let item" class="font-weight-bold">
            <mat-form-field appearance="outline" class="dense-field" style="width: 70px;">
              <input autofocus tabindex="1" matInput type="text" [name]="item.poLineItemGuid + '-lineNumber'"
                [(ngModel)]="item.lineNumber" required>
            </mat-form-field>
          </td>
        </ng-container>

        <ng-container matColumnDef="quantity">
          <th mat-header-cell *matHeaderCellDef style="width: 9.12242292%">Quantity</th>
          <td mat-cell *matCellDef="let item" class="font-weight-bold">
            <mat-form-field appearance="outline" class="dense-field" style="width: 50px;">
              <input class="quantity-input" tabindex="1" matInput type="number" [min]="1" [minValidator]="1"
                notNullValidator [name]="item.poLineItemGuid + '-quantity'" [(ngModel)]="item.quantity" required>
            </mat-form-field>
          </td>
        </ng-container>

        <ng-container matColumnDef="description">
          <th mat-header-cell *matHeaderCellDef>Item</th>
          <td mat-cell *matCellDef="let item" class="font-weight-bold"
            style="font-size: 0.75em; max-width: 150px; text-overflow: ellipsis;">
            {{
            printName(item)
            }}
          </td>
        </ng-container>

        <ng-container matColumnDef="unitPrice">
          <th mat-header-cell *matHeaderCellDef style="width: 9.38509657%">Unit Price</th>
          <td mat-cell *matCellDef="let item"> {{item.unitPrice | currency }} </td>
        </ng-container>

        <ng-container matColumnDef="extPrice">
          <th mat-header-cell *matHeaderCellDef style="width: 10.7249589%">Ext. Price</th>
          <td mat-cell *matCellDef="let item">{{item.unitPrice * (item.quantity || 0) | currency }} </td>
        </ng-container>

        <ng-container matColumnDef="dueDate">
          <th mat-header-cell *matHeaderCellDef>Due Date</th>
          <td mat-cell *matCellDef="let item" class="font-weight-bold">
            <date-picker appearance="outline" [dense]="true" placeholder="" [(date)]="item.dueDate"
              (dateChange)="poLineItemsChange.emit(this.poLineItems)" [editable]="true"
              [error]="dueDateMismatch(item) && !item.dueDateOverride" required></date-picker>
          </td>
        </ng-container>

        <ng-container matColumnDef="error">
          <th mat-header-cell *matHeaderCellDef style="text-align: center;">&nbsp;</th>
          <td mat-cell *matCellDef="let item" style="min-width:50px !important;">
            <button mat-icon-button class="text-danger" *ngIf="dueDateMismatch(item) && !item.dueDateOverride"
              style="width: 30px;" matTooltip="Due date mismatch detected. Click to override warning"
              matTooltipPosition="after" (click)="item.dueDateOverride = true">
              <mat-icon>report</mat-icon>
            </button>
            <span *ngIf="!dueDateMismatch(item) && !item.dueDateOverride">
              &nbsp;
            </span>
            <button mat-icon-button class="text-muted" *ngIf="item.dueDateOverride" style="width: 30px;"
              matTooltip="Due date mismatch warning overriden. Click to undo" matTooltipPosition="after"
              (click)="item.dueDateOverride = false">
              <mat-icon>report_off</mat-icon>
            </button>
          </td>
        </ng-container>

        <ng-container matColumnDef="delete">
          <th mat-header-cell *matHeaderCellDef style="width: 10%">&nbsp;</th>
          <td mat-cell *matCellDef="let item">
            <button mat-button class="bg-danger text-white small-button" (click)="deletePOLineItem(item)">
              <mat-icon>delete_forever</mat-icon>
            </button>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedPOLineItemColumns"></tr>
        <tr mat-row *matRowDef="let item; columns: displayedPOLineItemColumns;"></tr>
      </table>
    </mat-card>
  </div>
</div>