<loading *ngIf="!myResourcesEmployee" content="Loading..."></loading>
<loading *ngIf="!arrStaffUtilization" content="Loading..."></loading>

<div *ngIf="true">

  <div class="container-fluid">
    <div class="row">
      <div class="col-4">
        <label>Department</label>
        <mat-select [(value)]="selectedDepartment" placeholder="Select Department...">
          <mat-option value="0">All</mat-option>
          <mat-option *ngFor="let department of departments" [value]="department.departmentId">
            {{department.name}}
          </mat-option>
        </mat-select>
      </div>
      <div class="col-4">
        <label>Building</label>
        <mat-select [(value)]="selectedBuilding" placeholder="Select Building...">
          <mat-option value="0">All</mat-option>
          <mat-option *ngFor="let building of buildings" [value]="building.departmentId">
            {{building.name}}
          </mat-option>
        </mat-select>
      </div>
    </div>
  </div>

  <div class="row form-group">
    <div class="col-12">
      <search-bar class="w-100 noMarginTopBottom" [(searchString)]="searchString" (search)="doSearch($event)" [showManagerBar]="false"></search-bar>
    </div>
    <div class="col-12">
      <div class="md-switching-timeline-view-cont">

        <mbsc-eventcalendar id="staffUtilization" [data]="arrStaffUtilization" [options]="calendarOptions" [view]="calView" [resources]="myResourcesEmployee" [invalid]="myInvalids" [extendDefaultEvent]="defaultShift"
                            [headerTemplate]="myHeaderTemplate" [resourceTemplate]="myResourceTemplate" (onEventCreateFailed)="eventUpdateFail($event)" (onEventUpdateFailed)="eventUpdateFail($event)"
                            [scheduleEventContentTemplate]="myScheduleEventContentTemplate" [theme]="windows">

          <ng-template #myHeaderTemplate>
            <div class="my-calendar-nav-class">
              <mbsc-calendar-nav class="md-work-week-nav"></mbsc-calendar-nav>
            </div>
            <div class="md-work-week-picker">
              <mbsc-segmented-group [(ngModel)]="view" (change)="changeView()">
                <mbsc-segmented value="day">Day</mbsc-segmented>
                <mbsc-segmented value="workweek">Work week</mbsc-segmented>
                <mbsc-segmented value="week">Week</mbsc-segmented>
                <mbsc-segmented value="month">Month</mbsc-segmented>
              </mbsc-segmented-group>
            </div>
            <div class="my-calendar-nav-class-2">
              <div class="border-class"><mbsc-calendar-prev class="md-work-week-prev"></mbsc-calendar-prev></div>
              <div class="border-class"><mbsc-calendar-today class="md-work-week-today"></mbsc-calendar-today></div>
              <div class="border-class"><mbsc-calendar-next class="md-work-week-next"></mbsc-calendar-next></div>
            </div>
          </ng-template>

        </mbsc-eventcalendar>


        <ng-template #myScheduleEventContentTemplate let-data>
          <div class="my-event-title">{{data.title}}</div>
        </ng-template>

        <ng-template #myResourceTemplate let-resource>
          <div class="md-work-week-cont">
            <div class="md-work-week-name" [innerHTML]="resource.name"></div>
            <div class="md-work-week-title" [innerHTML]="resource.title"></div>
            <img class="md-work-week-avatar" [src]="resource.img" />
            <div class="md-work-week-title" [innerHTML]="resource.scheduledHours + ' / ' + resource.maxHours + ' hours'"></div>
          </div>
        </ng-template>

      </div>
    </div>
  </div>

</div>


