<div style="height: 100%;display: flex;flex-direction: column;">
  <div class="row">
    <div class="col">
      <div class="text-right bg-secondary w-100">
        <div class="btn-group m-2">
          <a class="btn btn-light btn-sm dropdown-toggle" href="#" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            {{($currentBuilding | async)?.name || "--"}}
          </a>
          <div class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuLink">
            <p class="text-center text-muted" *ngIf="!($buildings | async)">
              <i class="fas fa-circle-notch fa-spin fa-fw text-primary"></i>
              Loading...
            </p>
            <p class="text-center text-muted font-italic" *ngIf="($buildings | async)?.length==0">
              <i class="fas fa-exclamation-triangle fa-fw text-danger"></i>
              <small>No Departments Found!</small>
            </p>
            <a *ngFor="let b of ($buildings | async)" [ngClass]="{'active': (($currentBuilding | async)?.buildingId==b.buildingId)}" class="dropdown-item" (click)="selectBuilding(b)">{{b.name}}</a>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div [class.d-none]="!($currentBuilding | async)" style="flex-grow: 1;height: 0;">
    <ng-content></ng-content>
  </div>
  <div *ngIf="!($currentBuilding | async)" class="m-4 bg-light text-muted d-flex align-items-center justify-content-center" style="flex-grow: 1;height: 0;">
    Please select a building above.
  </div>
</div>
