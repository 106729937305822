<!-- <div>Dirty = {{ dirty }}</div>
<div>materialFullyInput = {{ materialFullyInput }}</div> -->

<mat-form-field [class.loading-disabled]="loadingData" [class.material-search-form-field-disabled]="disabled"
  appearance="outline" class="w-100 material-search-form-field" (mousedown)="focusCurrentInput($event)">
  <mat-label>Material</mat-label>
  <div *ngIf="loadingData" class="d-flex align-items-center">
    <i class="text-primary fas fa-spin fa-circle-notch"></i>
    <div class="ml-2 text-muted font-italic mat-caption">Loading material data...</div>
  </div>
  <span class="text-muted" *ngIf="materialNotInput && disabled">No Material Input</span>
  <mat-chip-list #chipList aria-label="Fruit selection" [selectable]="!disabled" [hidden]="loadingData">
    <input [disabled]="disabled" [(ngModel)]="searchInput" [disabled]="disabled" matInput #mainInputs #groupInput
    (ngModelChange)="resizeInput(groupInput)" (keydown)="handleBackspace($event)" *ngIf="state === 'GROUP'"
    [matAutocomplete]="groupAutocomplete" #groupTrigger="matAutocompleteTrigger"
    (focus)="groupTrigger.openPanel(); resizeInput(groupInput, searchInput)" [matChipInputFor]="chipList">
    <mat-chip [disableRipple]="disabled" #mainChips id="GROUP_CHIP" class="material-select-input-chip"
      (focus)="handleFocus('GROUP', materialParameters.group.groupName)" *ngIf="materialParameters.group !== undefined"
      [class.d-none]="state === 'GROUP'">{{
      materialParameters.group.groupName
    }}</mat-chip>
    <input [(ngModel)]="searchInput" [disabled]="disabled" matInput #mainInputs #typeInput (ngModelChange)="resizeInput(typeInput)"
      (keydown)="handleBackspace($event)" *ngIf="state === 'TYPE'" [matAutocomplete]="typeAutocomplete"
      #typeTrigger="matAutocompleteTrigger" (focus)="typeTrigger.openPanel(); resizeInput(typeInput, searchInput)"
      [matChipInputFor]="chipList" [placeholder]="disabled ? '' : 'Click here to begin inputting a material...'">
    <mat-chip [disableRipple]="disabled" #mainChips id="TYPE_CHIP" class="material-select-input-chip"
      (focus)="handleFocus('TYPE', materialParameters.type.name)" *ngIf="materialParameters.type !== undefined"
      [class.d-none]="state === 'TYPE'">
      {{materialParameters.type.name}}
    </mat-chip>
    <input [(ngModel)]="searchInput" [disabled]="disabled" matInput #mainInputs #castnumberInput (ngModelChange)="resizeInput(castnumberInput)"
      (keydown)="handleBackspace($event)" *ngIf="state === 'CASTNUMBER'" [matAutocomplete]="castnumberAutocomplete"
      #castNumberTrigger="matAutocompleteTrigger" (focus)="castNumberTrigger.openPanel(); resizeInput(castnumberInput, searchInput)"
      [matChipInputFor]="chipList" [placeholder]="disabled ? '' : 'Click here to begin inputting a material...'">
    <mat-chip [disableRipple]="disabled" #mainChips id="CASTNUMBER_CHIP" class="material-select-input-chip"
      (focus)="handleFocus('CASTNUMBER', materialParameters.castnumber)" *ngIf="materialParameters.castnumber"
      [class.d-none]="state === 'CASTNUMBER'">
      {{materialParameters.castnumber}}
    </mat-chip>
    <mat-chip [disableRipple]="disabled" id="NO_NUMBER_CHIP"
      class="material-select-input-chip material-select-null font-italic" [class.d-none]="materialParameters.castnumber || this.state ===
          'CASTNUMBER' || disabled" (focus)="handleFocus('CASTNUMBER')">No Casting Number</mat-chip>
    <input [disabled]="disabled" [(ngModel)]="searchInput" [disabled]="disabled" matInput #mainInputs #alloyInput
    (ngModelChange)="resizeInput(alloyInput)" (keydown)="handleBackspace($event)" *ngIf="state === 'ALLOY'"
    [matAutocomplete]="alloyAutocomplete" #alloyTrigger="matAutocompleteTrigger"
    (focus)="alloyTrigger.openPanel(); resizeInput(alloyInput, searchInput)" [matChipInputFor]="chipList">
    <mat-chip [disableRipple]="disabled" #mainChips class="material-select-input-chip" id="ALLOY_CHIP"
      [class.material-select-null]="materialParameters.alloy === null"
      (focus)="handleFocus('ALLOY', materialParameters.alloy ? materialParameters.alloy.name : '')"
      *ngIf="materialParameters.alloy !== undefined" [class.d-none]="state === 'ALLOY'">{{
          materialParameters.alloy ? materialParameters.alloy.name : '--'
          }}
    </mat-chip>
    <input [disabled]="disabled" [(ngModel)]="searchInput" [disabled]="disabled" matInput #mainInputs #hardnessInput
      (ngModelChange)="resizeInput(hardnessInput)" (keydown)="handleBackspace($event)" *ngIf="state === 'HARDNESS'"
      [matAutocomplete]="hardnessAutocomplete" #hardnessTrigger="matAutocompleteTrigger"
      (focus)="hardnessTrigger.openPanel(); resizeInput(hardnessInput, searchInput)" [matChipInputFor]="chipList">
    <mat-chip [disableRipple]="disabled" #mainChips class="material-select-input-chip" id="HARDNESS_CHIP"
      [class.material-select-null]="materialParameters.hardness === null"
      (focus)="handleFocus('HARDNESS', materialParameters.hardness ? materialParameters.hardness.name : '')"
      *ngIf="materialParameters.hardness !== undefined" [class.d-none]="state === 'HARDNESS'">{{
          materialParameters.hardness ? materialParameters.hardness.name : '--'
          }}</mat-chip>
    <mat-chip [disableRipple]="true" class="material-select-input-chip"
    *ngIf="disabled && dimensions && materialParameters.type && materialParameters.type.displayTemplate">
    {{ dimensionsDisplay }}
    </mat-chip>
    <mat-chip [disableRipple]="disabled" id="NO_SPECS_CHIP"
      class="material-select-input-chip material-select-null font-italic" [class.d-none]="!(materialParameters.specs.length === 0 && this.hasFinishedSpecs) || this.state ===
          'SPECS' || disabled" (focus)="handleFocus('SPECS')">-- No Specifications --</mat-chip>
    <ng-container *ngIf="materialParameters.specs.length > 0 || this.state === 'SPECS'">
      <span style="display: inline-block; margin: 4px">(</span>
      <ng-container *ngFor="let spec of materialParameters.specs; last as isLast">
        <mat-chip [disableRipple]="disabled" #specChips class="material-select-input-chip" (removed)="removeSpec(spec)"
          (focus)="handleFocus('SPECS')">
          {{spec.name}}
          <mat-icon matChipRemove *ngIf="!disabled">cancel</mat-icon>
        </mat-chip>
        <span class="inline-input-text" *ngIf="!isLast || this.state === 'SPECS'">/</span>
      </ng-container>
      <input [disabled]="disabled" [(ngModel)]="searchInput" [disabled]="disabled" matInput #mainInputs #specsInput
        (ngModelChange)="resizeInput(specsInput)" *ngIf="state === 'SPECS'" [matAutocomplete]="specsAutocomplete"
        #specsTrigger="matAutocompleteTrigger" (focus)="specsTrigger.openPanel(); resizeInput(specsInput, searchInput)"
        [matChipInputFor]="chipList">
      <span class="inline-input-text">)</span>
    </ng-container>

    <!--
          We don't want the user actually typing in this input so we preventDefault the beforeinput event.
          It's just here for visual consistency and so the user can backspace into previous fields
        -->
    <input [disabled]="disabled" autocomplete="off" [(ngModel)]="searchInput" [disabled]="disabled" (beforeinput)="$event.preventDefault()"
      matInput #mainInputs #doneInput *ngIf="state === 'DONE'" [matChipInputFor]="chipList">
  </mat-chip-list>
  <mat-autocomplete (opened)="setPanelMargin(groupAutocomplete)" (closed)="inputExit()"
    #groupAutocomplete="matAutocomplete" (optionSelected)="optionSelected($event.option.value)">
    <mat-option disabled class="small text-muted font-italic text-center header-option">Material Group</mat-option>
    <mat-option *ngFor="let group of doFilter(this.materialGroups)" [value]="group">
      <span [innerHTML]="group.groupName | highlight: searchInput"></span>
    </mat-option>
    <mat-option *ngIf="doFilter(this.materialGroups).length === 0" disabled>
      <div class="d-flex justify-content-center align-items-center p-1">
        <span class="body-2 text-muted font-italic">No matching groups found.</span>
        <button mat-flat-button color="primary" class="text-uppercase mx-3" (click)="createNew('GROUP')">Create
          new</button>
      </div>
    </mat-option>
  </mat-autocomplete>
  <mat-autocomplete (opened)="setPanelMargin(typeAutocomplete)" (closed)="inputExit()" #typeAutocomplete="matAutocomplete" (optionSelected)="optionSelected($event.option.value)">
    <mat-option disabled class="small text-muted font-italic text-center header-option">Material Form</mat-option>
    <mat-option *ngFor="let type of doFilter(this.materialTypes)" [value]="type">
      <span [innerHTML]="type.name | highlight: searchInput"></span></mat-option>
    <mat-option *ngIf="doFilter(this.materialTypes).length === 0" disabled>
      <div class="d-flex justify-content-center align-items-center p-1">
        <span class="body-2 text-muted font-italic">No matching types found.</span>
        <button mat-flat-button color="primary" class="text-uppercase mx-3" (click)="createNew('TYPE')">Create
          new</button>
      </div>
    </mat-option>
  </mat-autocomplete>
  <mat-autocomplete (opened)="setPanelMargin(castnumberAutocomplete)" (closed)="inputExit()" #castnumberAutocomplete="matAutocomplete" (optionSelected)="optionSelected($event.option.value)">
    <mat-option disabled class="small text-muted font-italic text-center header-option">Casting/Extrusion/Forging Number</mat-option>
    <mat-option class="material-select-null text-white font-italic" [value]="''">N/A</mat-option>
    <mat-option *ngFor="let cn of doFilter(this.castingNumbers)" [value]="cn">
      <span [innerHTML]="cn | highlight: searchInput"></span>
    </mat-option>
    <mat-option *ngIf="!!searchInput && !castingNumbers.includes(searchInput)" [value]="searchInput">
      <span [innerHTML]="searchInput | highlight: searchInput"></span> <mat-chip class="ml-1">New</mat-chip>
    </mat-option>
  </mat-autocomplete>
  <mat-autocomplete (opened)="setPanelMargin(alloyAutocomplete)" (closed)="inputExit()"
    #alloyAutocomplete="matAutocomplete" (optionSelected)="optionSelected($event.option.value)">
    <mat-option disabled class="small text-muted font-italic text-center header-option">Material Alloy</mat-option>
    <mat-option class="material-select-null text-white font-italic" [value]="null">N/A</mat-option>
    <mat-option *ngFor="let alloy of doFilter(this.materialAlloys)" [value]="alloy">
      <span [innerHTML]="alloy.name | highlight: searchInput"></span></mat-option>
    <mat-option *ngIf="doFilter(this.materialAlloys).length === 0" disabled>
      <div class="d-flex justify-content-center align-items-center p-1">
        <span class="body-2 text-muted font-italic">No matching alloys found.</span>
        <button mat-flat-button color="primary" class="text-uppercase mx-3" (click)="createNew('ALLOY')">Create
          new</button>
      </div>
    </mat-option>
  </mat-autocomplete>
  <mat-autocomplete (opened)="setPanelMargin(hardnessAutocomplete)" (closed)="inputExit()"
    #hardnessAutocomplete="matAutocomplete" (optionSelected)="optionSelected($event.option.value)">
    <mat-option disabled class="small text-muted font-italic text-center header-option">Material Hardness/Temper
    </mat-option>
    <mat-option class="material-select-null text-white font-italic" [value]="null">N/A</mat-option>
    <mat-option *ngFor="let hardness of doFilter(this.materialHardnesses)" [value]="hardness">
      <span [innerHTML]="hardness.name | highlight: searchInput"></span>
    </mat-option>
    <mat-option *ngIf="doFilter(this.materialHardnesses).length === 0" disabled>
      <div class="d-flex justify-content-center align-items-center p-1">
        <span class="body-2 text-muted font-italic">No matching hardnesses found.</span>
        <button mat-flat-button color="primary" class="text-uppercase mx-3" (click)="createNew('HARDNESS')">Create
          new</button>
      </div>
    </mat-option>
  </mat-autocomplete>
  

  <mat-autocomplete (opened)="setPanelMargin(specsAutocomplete)" #specsAutocomplete="matAutocomplete"
    (optionSelected)="specSelected($event.option.value)" (closed)="inputExit(true)">
    <mat-option disabled class="small text-muted font-italic text-center header-option">Material Specifications
    </mat-option>
    <mat-option value="SPECS_FINISH" class="bg-success text-white font-italic">Done Inputting Specifications
    </mat-option>
    <mat-option *ngFor="let spec of doFilterSpecs(this.materialSpecifications)" [value]="spec">
      <span [innerHTML]="spec.name | highlight: searchInput"></span>
    </mat-option>
    <mat-option *ngIf="doFilter(this.materialSpecifications).length === 0" disabled>
      <div class="d-flex justify-content-center align-items-center p-1">
        <span class="body-2 text-muted font-italic">No matching specifications found.</span>
        <button mat-flat-button color="primary" class="text-uppercase mx-3" (click)="createNew('SPECS')">Create
          new</button>
      </div>
    </mat-option>
  </mat-autocomplete>
  <!-- <mat-hint>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et
    dolore magna aliqua.</mat-hint> -->

</mat-form-field>

<div class="dimension-selection" [class.dimension-selection-hidden]="!canShowDimensions || disabled">
  <div class="dimension-selection-inner" *ngIf="materialParameters.type && dimensions">
    <h5 class="mat-title text-center">Raw Material Dimensions</h5>
    <div class="row justify-content-center">
      <div class="col-12 d-flex justify-content-center">
        <mat-form-field class="" appearance="outline">
          <mat-label>Density</mat-label>
          <input type="number" autocomplete="off" matInput [(ngModel)]="materialDensity"
            (ngModelChange)="setDirty()" min="0" step="0.1">
          <div matSuffix class="unit-suffix">lbs/in<sup>3</sup></div>
        </mat-form-field>
      </div>
      <div class="col-12 mb-3 d-flex justify-content-center align-items-center text-muted user-select-none"
        *ngIf="hasWidthAndLength">
        <mat-icon class="mx-2">info</mat-icon>
        <span class="font-italic small">Remember: <b>length</b> should <b class="text-danger">always</b> be longer than
          <b>width</b>.</span>
      </div>
      <div class="col-3" *ngFor="let dimension of dimensions">
        <mat-form-field class="w-100" appearance="outline" *ngIf="dimension && dimension.materialTypeDimension">
          <mat-label>{{ dimension?.materialTypeDimension?.dimensionType?.label }}</mat-label>
          <input type="number" autocomplete="off" matInput [(ngModel)]="dimension.value"
            (ngModelChange)="setDirty()" min="0" step="0.1">
          <div matSuffix class="unit-suffix">{{ dimension.materialTypeDimension.dimensionUnit.abbreviation }}</div>
        </mat-form-field>
      </div>
    </div>
  </div>
  <div class="dimension-selection-inner dimensions-bottom mat-small text-muted">
    <div>
      <b>Material Volume:</b> {{ calculatedVolume | number: '0.2' }}<b>in<sup>3</sup></b>
    </div>
    <div class="mx-2 font-weight-bold">
      ·
    </div>
    <div>
      <b>Raw Material Weight:</b> {{ (calculatedVolume * materialDensity) | number: '0.2' }}<b>lbs</b>
    </div>
  </div>
</div>

<ng-template #newTypeDialog>
  <ng-container *ngIf="!savingNew">
    <h2 matDialogTitle>New Material Form</h2>
    <mat-dialog-content style="max-height: 65vh;">
      <div *ngIf="creatingNew === 'TYPE'">
        <material-type-editor #matTypeEditor [forSearch]="true" (typeCreated)="onNewItem($event)"
          (forSearchCancel)="newItemCancel()">
        </material-type-editor>
      </div>
    </mat-dialog-content>
    <mat-dialog-actions class="py-4" align="end">
      <button mat-button (click)="newItemCancel()">Cancel</button>
      <button mat-raised-button color="primary" (click)="matTypeEditor.save()"
        [disabled]="!(newType && newType.name && matTypeEditor && matTypeEditor.volumeFormulaIsValid)">Save Form</button>
    </mat-dialog-actions>
  </ng-container>
  <div *ngIf="savingNew" class="d-flex flex-column align-items-center py-2">
    <div>
      <mat-spinner [color]="'warn'"></mat-spinner>
    </div>
    <div class="text-muted font-italic mt-4">
      Saving...
    </div>
  </div>
</ng-template>

<ng-template #newItemDialog let-data>
  <ng-container *ngIf="!savingNew">
    <h2 matDialogTitle>New {{ creatingNew | titlecase }}</h2>
    <mat-dialog-content>
      <div class="container-fluid">
        <div class="row">
          <div class="col">
            <mat-form-field class="w-100" appearance="outline">
              <mat-label>{{ creatingNew | titlecase }} Name</mat-label>
              <input matInput type="text" autocomplete="off" [(ngModel)]="data.newItem[data.nameKey]">
            </mat-form-field>
          </div>
        </div>
      </div>
    </mat-dialog-content>
    <mat-dialog-actions align="end">
      <button mat-button (click)="newItemCancel()">Cancel</button>
      <button mat-raised-button color="primary" [disabled]="!data.newItem[data.nameKey]?.trim()" (click)="onNewItem(data.newItem)">Create
        {{ creatingNew | titlecase }}</button>
    </mat-dialog-actions>
  </ng-container>
  <div *ngIf="savingNew" class="d-flex flex-column align-items-center py-2">
    <div>
      <mat-spinner [color]="'warn'"></mat-spinner>
    </div>
    <div class="text-muted font-italic mt-4">
      Saving...
    </div>
  </div>
</ng-template>
