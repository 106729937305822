import { Directive, Input, HostListener, OnInit } from "@angular/core";
import { MatAutocompleteTrigger } from "@angular/material/autocomplete";
import { MatOptionSelectionChange } from "@angular/material/core";

@Directive({
    selector: '[matAutocompleteNoDisplay]',
  })
export class MatAutocompleteNoDisplayDirective implements OnInit {
  @Input('matAutocompleteNoDisplay') matAutoCompleteTrigger!: MatAutocompleteTrigger;
  constructor() {}
  ngOnInit(): void {
    const trigger = this.matAutoCompleteTrigger;
    // @ts-ignore
    trigger._setValueAndClose = (event: MatOptionSelectionChange)=>{
      trigger.closePanel()
      if (!event) return;
      // @ts-ignore
      const toSelect = event ? event.source : trigger._pendingAutoselectedOption;
      trigger.autocomplete.optionSelected.emit({ option: toSelect, source: trigger.autocomplete });
    }
  }
  
}