export interface VirtualDocument {
  documentId: string;
  name: string;
  mimeType: string;
  tags: string[];
  imageAsDataUrl: string;
}

export function getMimeTypeIcon(type: string): string {
  if (type.startsWith("image"))
    return "file-image";

  if (type.endsWith("/pdf") || type.indexOf("/pdf;") > 0)
    return "file-pdf-box";

  if (type.indexOf("word") > 0 || type.indexOf("wordprocessing") > 0)
    return "file-word";

  if (type.indexOf("excel") > 0 || type.indexOf("spreadsheet") > 0)
    return "file-excel";

  if (type.indexOf("zip") > 0 || type.indexOf("compressed") > 0)
    return "zip-box";

  else return "file";
}