import { Component } from '@angular/core';
import { NavigationService } from '../../../common/services/navigation.service';

@Component({
  selector: 'nav-header',
  templateUrl: './nav-header.component.html',
  styleUrls: ['./nav-header.component.less']
})

export class NavHeaderComponent {
  heading: string = 'COTS 2';
  breadcrumbs: string[] = [];

  constructor(private navService: NavigationService) { }

  ngOnInit() {
    this.navService.pageTitle.subscribe(t => this.heading = t);
    this.navService.pageBreadcrumbs.subscribe(b => this.breadcrumbs = b);
  }
}
