<div class="vendor-selection">
  <loading *ngIf="searching"></loading>

  <div class="p-0 container-fluid" *ngIf="!searching">
    <mat-sidenav-container class="p-0 m-0">
      <mat-sidenav disableClose (closedStart)="closeSideNav()" #insetnav mode="over" position="end">
        <vendor-contact-screen *ngIf="showEditor=='contact'" [sidenav]="insetnav" [orderId]="orderId" [order]="order" [requisitionId]="requisitionId" [product]="product" [typeId]="typeId" [vendors]="selected"></vendor-contact-screen>
      </mat-sidenav>
      <mat-sidenav-content>
        <div class="row form-group">
          <div class="col-12 display-5 p-4">Available Vendors: {{getProductInfo()}}</div>
        </div>
        <div class="row">
          <div class="col-12 form-group">
            <div class="m-4 min-vh-80">
              <table class="table search-results">
                <thead>
                  <tr>
                    <th>Vendor Name</th>
                    <th>Overall</th>
                    <th>On-Time</th>
                    <th>Quality</th>
                    <th>Subjective</th>
                    <th>&nbsp;</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let vendor of vendors" (click)="toggleSelection(vendor)">
                    <th>{{vendor.name}}</th>
                    <th>{{vendor.overallScore}}</th>
                    <th>{{vendor.onTimeScore}}</th>
                    <th>{{vendor.qualityScore}}</th>
                    <th>{{vendor.subjectiveScore}}</th>
                    <th><mat-checkbox [checked]="selected.indexOf(vendor) !== -1" [disableRipple]="true" (click)="$event.preventDefault()"></mat-checkbox></th>
                  </tr>
                  <tr *ngIf="vendors.length==0">
                    <td colspan="100%" class="text-center pt-4">
                      <span class="display-2"><i class="fas fa-fw fa-lg fa-exclamation-triangle text-danger"></i> NO VENDORS FOUND</span>
                      <span class="d-block text-muted">You can add vendors and/or capabilities to existing vendors from the <a [routerLink]='["/vendors"]'>Vendor</a> sub-module.</span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="position-absolute buttons mb-4 pb-4">
            <button class="btn btn-danger mr-2" (click)="exit()">
              <i class="fas fa-times fa-lg fa-fw"></i>
              Cancel
            </button>
            <button class="btn btn-success" [disabled]="selected.length==0" (click)="goContact()">Select ({{selected.length}})</button>
          </div>
        </div>
      </mat-sidenav-content>
    </mat-sidenav-container>

  </div>
</div>
