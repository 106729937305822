import { Component, OnInit, ViewChild } from '@angular/core';
import { WorkOrder } from '../../resources/work-order';
import { ActivatedRoute, Router } from '@angular/router';
import { PreplanningData, WorkOrderService, WorkOrderShippingAssignment } from '../../services/work-order.service';
import { MachineAssignment } from '../../resources/machine-assignment';
import { PlanningService } from '../../services/planning.service';
import { OrderStatus } from '../../../order/resources/order';
import { NavigationService } from '../../../common/services/navigation.service';
import { MatSidenav } from '@angular/material/sidenav';
import { Product } from '../../../order/resources/product';
import { OrderService } from '../../../order/services/order.service';
import { UserService } from '../../../common/services/user.service';
import { UtilityService } from '../../../common/services/utility.service';
import { User } from '../../../common/resources/user';
import { StationService } from '../../../order/services/station.service';
import { Station } from '../../../order/resources/station';
import { ProgrammingTicket } from '../../resources/programming-ticket';
import { ProductHierarchySortComponent, SortedProductHierarchy } from '../product-hierarchy-sort/product-hierarchy-sort.component';
import { PreplanningDetailComponent } from '../preplanning-detail/preplanning-detail.component';
import { PlanningTicket } from '../../resources/planning-ticket';
import { PlanningWoDetailComponent } from '../planning-wo-detail/planning-wo-detail.component';
import { SelectionModel } from '@angular/cdk/collections';

@Component({
  selector: 'planning-detail',
  templateUrl: './planning-detail.component.html',
  styleUrls: ['./planning-detail.component.less']
})
export class PlanningDetailComponent implements OnInit {
  private id: string;
  public ticket: PlanningTicket;
  public rootWorkOrder: WorkOrder;
  public selectedItemId: string;
  public selectedWorkOrder: WorkOrder;
  public editing: boolean = false;
  public loading: boolean = false;
  public saving: boolean = false;
  public showEditor: string = null;
  public assigningEmployee: User = null;
  public selectedProgrammingTicket: ProgrammingTicket = null;
  @ViewChild('productHierarchySort') productHierarchySortComponent: ProductHierarchySortComponent; 
  @ViewChild("sidenav") sidenav: MatSidenav;
  private stationList: Station[] = null;

  constructor(private route: ActivatedRoute, private woService: WorkOrderService, public stationService: StationService, private planningSvc: PlanningService, private navService: NavigationService, private orderSvc: OrderService, private userSvc: UserService, private utilitySvc: UtilityService, private router: Router) {
    this.id = this.route.snapshot.paramMap.get('id');
  }

  public openWorkOrder(): void {
    this.showEditor = "part";
    this.navService.pushBreadcrumb("Edit Product");
    this.sidenav.open();
  }

  public getStatusText(disposition: number): string {
    return OrderStatus.getStatusText(disposition);
  }

  public userIsManager(): boolean {
    return (
      this.userSvc.canAccess("PlanningManager") || this.userSvc.canAccess("Developer")
    );
  }

  public get emptyGuid(): string {
    return UtilityService.emptyGuid;
  }


  public async saveAssignment() {
    if (!this.ticket) return;

    this.saving = true;
    await this.planningSvc.assign(this.ticket.planningTicketId, this.assigningEmployee).toPromise();
    this.saving = false;
    if (this.assigningEmployee.userId === this.userSvc.userData.userId) {
      await this.getDetail();
      this.productHierarchySortComponent.getDetail();
    }
    else {
      // redirect to list if assigning to someone else
      this.router.navigate(this.route.snapshot.url.slice(0, -1).map(x => x.path));
    }
  }

  public selectProgrammingTicket(ticket: ProgrammingTicket): void {
    this.selectedProgrammingTicket = ticket;

    this.showEditor = 'programming';
    this.sidenav.toggle();
  }

  public loadedWorkOrdersMap: { [key: string]: WorkOrder } = {};
  public allChildrenFlat: Product[] = []
  public async getDetail() {
    let expansion: string[]
    if (this.productHierarchySortComponent) expansion = this.productHierarchySortComponent.expansionModel.selected;


    this.ticket = null;

    const ticket = await this.planningSvc.getDetail(this.id).toPromise();
    let loadedWorkOrder: WorkOrder; 
    if (!this.selectedItemId) {
      loadedWorkOrder = await this.woService.getDetail(ticket.rootWorkOrderId).toPromise();
      this.selectedItemId = loadedWorkOrder.productId;
      this.selectedWorkOrder = loadedWorkOrder;
      this.rootWorkOrder = loadedWorkOrder;
    } else {
      let tgt = ticket.workOrders.find(wo => wo.productId === this.selectedItemId);
      loadedWorkOrder = await this.woService.getDetail(tgt.workOrderId).toPromise();
      this.selectedWorkOrder = loadedWorkOrder;
    }

    const allChildren = await this.orderSvc.getAllChildProducts(this.rootWorkOrder.productId).toPromise();
    const flatten = (product?: Product) => {
      return [product, ...product.childAssemblies.flatMap(childProduct => flatten(childProduct))]
    }
    this.allChildrenFlat = allChildren.flatMap(c => flatten(c))

    this.ticket = ticket;
    this.loadedWorkOrdersMap = {};
    this.loadedWorkOrdersMap[loadedWorkOrder.workOrderId] = loadedWorkOrder;

    if (expansion) {
      setTimeout(() => {
        this.productHierarchySortComponent.expansionModel.select(...expansion)
      })
    }

    this.navService.clearBreadCrumbs();
    this.navService.setPageTitle("Planning Detail");

    this.navService.pushBreadcrumb(this.ticket.planningTicketNumber);

  }

  public async reorderHierarchy(data: SortedProductHierarchy[]) {
    this.saving = true;
    await this.orderSvc.reorderHierarchy(data).toPromise();
    this.saving = false;
    await this.getDetail();
    this.productHierarchySortComponent.getDetail();
  }

  public closeSideNav(): void {
    this.showEditor = null;
    this.navService.popBreadCrumb();
    // this.getDetail();
  }

  public toggleEditing(): void {
    this.editing = !this.editing;
  }

  public getStationName(stationId: string): string {
    return this.stationList.find(s => s.stationId == stationId).name;
  }

  getStatusColorClass(status: number): string {
    return OrderStatus.getStatusColorClass(status);
  }


  ngOnInit(): void {
    if (this.stationService.loaded) {
      this.stationList = this.stationService.stationList;
    }
    else {
      this.stationService.stationsLoaded.subscribe(
        _ => this.stationList = this.stationService.stationList
      );
    }

    this.getDetail();
  }

  @ViewChild('woDetail') woDetailComponent: PlanningWoDetailComponent;
  public async onSelectItem(productId: string) {
    if (this.selectedItemId === productId) return;

    if (this.woDetailComponent.dirty) {
      const r = await this.utilitySvc.showConfirmationPromise('Unsaved Changes', `
        <p>You have unsaved changes on this part. If you switch to another part, they may be lost.</p>
        <p>Really switch?</p>
      `);
      if (!r) {
        this.selectedItemId = this.selectedItemId;
        this.productHierarchySortComponent.selectedItemId = this.selectedItemId;
        return;
      };
    }

    this.selectedItemId = productId;
    const tgtWO = this.ticket.workOrders.find(w => w.productId === productId);
    if (this.loadedWorkOrdersMap[tgtWO.workOrderId]) this.selectedWorkOrder = this.loadedWorkOrdersMap[tgtWO.workOrderId];
    else {
      this.loading = true;
      const wo = await this.woService.getDetail(tgtWO.workOrderId).toPromise();
      this.loadedWorkOrdersMap[tgtWO.workOrderId] = wo;
      this.selectedWorkOrder = wo;
      this.loading = false;
    }
    this.woDetailComponent.getDetail(this.selectedWorkOrder);
    this.woDetailComponent.rerender();
  }
  
  public async finishPreplanning(preplanningData: PreplanningData) {
    this.saving = true;
    await this.woService.finishPreplanning(this.selectedWorkOrder, preplanningData).toPromise();
    this.saving = false;
    this.getDetail();
  }

  public async returnToPreplanning() {
    this.saving = true;
    await this.woService.returnToPreplanning(this.selectedWorkOrder).toPromise();
    this.saving = false;
    this.getDetail();
  }

  public savePlanning(data: { product: Product }) {
    this.saving = true;

    this.planningSvc.saveWorkOrderDetails(this.selectedWorkOrder, this.woDetailComponent.assignments, this.woDetailComponent.shippingAssignments, data.product).subscribe(_ => {
      this.saving = false;
      this.editing = false;

      this.getDetail();
    });
  }

  public donePlanning() {
    this.saving = true;
    this.planningSvc.finishPlanning(this.selectedWorkOrder).subscribe(() => {
      this.saving = false;
      this.editing = false;
      this.getDetail();
    })
  }

  public editingProduct: Product;
  public async editWorkflow(prod: Product) {
    this.loading = true;
    // deep clone editing product
    const p: Product = JSON.parse(JSON.stringify(prod));
    this.loading = false;
    this.editingProduct = p;
    this.showEditor = 'part';
    this.sidenav.open();
  }

  public onProductUpdate(prod: Product) {
    this.woDetailComponent.productUpdated(prod);
  }

  public async reassignWO(assigningUser: User) {
    if (this.selectedWorkOrder.userId == assigningUser.userId) return;
    this.saving = true;
    await this.woService.assign(this.selectedWorkOrder.workOrderId, assigningUser).toPromise();
    this.saving = false;
    this.selectedWorkOrder.userId = assigningUser.userId;
    this.selectedWorkOrder.assignedTo = assigningUser;
    // this forces update in the hierarchy view
    const replaceWO = this.ticket.workOrders.find(x => x.workOrderId === this.selectedWorkOrder.productId);
    replaceWO.userId = assigningUser.userId;
    replaceWO.assignedTo = assigningUser;
    this.ticket.workOrders = [...this.ticket.workOrders];
  }




}