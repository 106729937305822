<search-list-new
  [displayedColumns]="['customer', 'invoiceNumber', 'po', 'lineItem', 'price', 'invoiceStatus', 'orderStatus']"
  [fieldSearchFields]="[
    { field: 'customer', code: 'customer' },
    { field: 'po', code: 'po' },
    { field: 'lineItem', code: 'li' }
  ]"
  [fieldSearchNames]="{
    customer: 'Customer',
    po: 'Purchase Order Number',
    lineItem: 'Line Item Number'
  }"
  (itemSelected)="onSelect($event)"
  [resultGetter]="search.bind(this)"
  localStorageId="accounts-receivable-queue"
>
  <ng-container matColumnDef="customer">
    <mat-header-cell *matHeaderCellDef class="sortable">Customer</mat-header-cell>
    <mat-cell *matCellDef="let row">
      <span class="font-weight-bold mat-light-text">
        {{row.customerPurchaseOrderLineItem.customerPurchaseOrder.customer.businessName}}
      </span>
    </mat-cell>
  </ng-container>
  <ng-container matColumnDef="invoiceNumber">
    <mat-header-cell *matHeaderCellDef class="sortable">Invoice Number</mat-header-cell>
    <mat-cell *matCellDef="let row">
      {{row.invoiceNumber || '--'}}
    </mat-cell>
  </ng-container>
  <ng-container matColumnDef="po">
    <mat-header-cell *matHeaderCellDef class="sortable">PO #</mat-header-cell>
    <mat-cell *matCellDef="let row">
      {{row.customerPurchaseOrderLineItem.customerPurchaseOrder.purchaseOrderNumber}}
    </mat-cell>
  </ng-container>
  <ng-container matColumnDef="lineItem">
    <mat-header-cell *matHeaderCellDef class="sortable">Line Item #</mat-header-cell>
    <mat-cell *matCellDef="let row">
      {{row.customerPurchaseOrderLineItem.lineItemNumber}}
    </mat-cell>
  </ng-container>
  <ng-container matColumnDef="price">
    <mat-header-cell *matHeaderCellDef class="sortable">Value</mat-header-cell>
    <mat-cell *matCellDef="let row">
      {{(row.customerPurchaseOrderLineItem.unitPrice) * row.quantity | currency}}
    </mat-cell>
  </ng-container>
  <ng-container matColumnDef="invoiceStatus">
    <mat-header-cell *matHeaderCellDef class="sortable">Invoice Status</mat-header-cell>
    <mat-cell *matCellDef="let row">
      <mat-chip class="status button-text" disableRipple [ngClass]="getInvoiceStatusColorClass(row.status)">
        {{getInvoiceStatusText(row.status)}}
      </mat-chip>
    </mat-cell>
  </ng-container>
  <ng-container matColumnDef="orderStatus">
    <mat-header-cell *matHeaderCellDef class="sortable">Work Order Status</mat-header-cell>
    <mat-cell *matCellDef="let row">
      <mat-chip class="status button-text" disableRipple [ngClass]="getOrderStatusColorClass(row.customerPurchaseOrderLineItem.workOrder.status)">
        {{getOrderStatusText(row.customerPurchaseOrderLineItem.workOrder.status)}}
      </mat-chip>
    </mat-cell>
  </ng-container>
</search-list-new>