<div [class.quantity-table-root]="!product.parentAssemblyId">
  <!-- <div *ngIf="!product.parentAssemblyId" [class.invisible]="!childrenLoaded || !allQuantitiesSelected || !totalAssemblyPrice || !costBreakdown">
    <h5 class="font-weight-bold mb-3">Total Assembly Price: {{ totalAssemblyPrice | currency }}</h5>
  </div> -->
  <div class="row" *ngIf="!product.parentAssemblyId">
    <div class="col-12">
      <table>
        <thead>
          <tr>
            <th colspan="8" class="product-name">
              {{product?.partNumber}} / Rev {{product?.revision}} <span *ngIf="product?.productRepairPlan" class="ml-1 badge badge-danger">REPAIR</span>
            </th>
          </tr>
        </thead>
        <tbody *ngIf="costBreakdown">
          <tr>
            <th>Order Quantity</th>
            <th *ngIf="quantityEditable">Show on Quote</th>
            <th>Quantity</th>
            <th style="min-width: 100px">Cost</th>
            <th>Additional Markup %</th>
            <th style="min-width: 100px">Price</th>
            <th style="min-width: 100px">Unit Price</th>
            <th style="min-width: 100px">Ext. Price</th>
            <th *ngIf="quantityEditable" style="min-width: 43px">Lead Time</th>
            <th style="min-width: 43px"></th>
          </tr>
          <!-- Default/global row -->
          <tr>
            <td>
              <input *ngIf="editable" type="radio" [name]="product.productId" [value]="null" [(ngModel)]="product.orderQuantity" (ngModelChange)="setDirty()">
              <span class="font-weight-bold" *ngIf="!editable && product.orderQuantity === null">✗</span>
              <div class="small" *ngIf="editable || product.orderQuantity === null">none</div>
            </td>
            <td *ngIf="quantityEditable">
              <input *ngIf="editable" type="checkbox" [ngModel]="allShownOnQuote" (change)="toggleShowAllOnQuote($event); setDirty()">
              <div class="small" *ngIf="editable">select all</div>
            </td>
            <td colspan="2" class="column-gray">—</td>
            <td>
              <input type="number" min="0" step="1" [disabled]="!editable || !pricingEditable" [(ngModel)]="globalMarkup" (ngModelChange)="setDirty()" (input)="globalMarkupChange($event.target.value)" (keydown)="preventNegative($event)" class="markup-input" [class.font-weight-bold]="globalMarkupActive">
              %
            </td>
            <td colspan="4" class="column-gray">—</td>
            <td colspan="1" *ngIf="quantityEditable" class="column-gray">—</td>
          </tr>
          <!--  -->
          <tr *ngFor="let qty of quantityMap" [class.selected-qty]="product.orderQuantity === qty.value">
            <td>
              <input *ngIf="editable" type="radio" [name]="product.productId" [value]="qty.value" [(ngModel)]="product.orderQuantity" (ngModelChange)="setDirty()">
              <span class="font-weight-bold" *ngIf="!editable && product.orderQuantity === qty.value">✓</span>
            </td>
            <td *ngIf="quantityEditable">
              <input [disabled]="!editable" type="checkbox" [(ngModel)]="qty.showOnQuote" (ngModelChange)="setDirty()">
            </td>
            <td class="qty-value">
              {{qty.value * parentQty}}
              <span class="small font-weight-normal" *ngIf="this.product.parentAssemblyId">({{qty.value}} × {{ parentQty === 0 ? '?' : parentQty }})</span>
            </td>
            <td class="price">{{ getCostForQuantity(qty.value) | currency }}</td>
            <td>
              <input input type="number" min="0" step="1"
              [class.text-success]="qty.priceOverride"
              [class.font-weight-bold]="qty.priceOverride"
               [disabled]="!editable || !pricingEditable || qty.priceOverride"  [(ngModel)]="qty.markup" (ngModelChange)="setDirty()" (input)="singleMarkupChange()" (keydown)="preventNegative($event)" class="markup-input" [class.text-muted]="globalMarkupActive">
              %
            </td>
            <td class="price" [class.font-weight-bold]="product.orderQuantity === qty.value" [class.p-1]="product.orderQuantity === qty.value">
              <span *ngIf="!qty.priceOverride">{{ getCostForQuantity(qty.value) * ( 1 + qty.markup / 100) | currency }}</span>
              <span *ngIf="qty.priceOverride" class="text-success">{{ qty.priceOverride | currency }}</span>
              <button class="btn btn-xs btn-success ml-1" (click)="editPriceOverride(qty)" *ngIf="editable && pricingEditable">
                <i class="fas fa-edit fa-fw"></i>
              </button>
              <button class="btn btn-xs btn-danger ml-1" (click)="setOverride(qty.value, null, '')" *ngIf="editable && pricingEditable && qty.priceOverride">
                <i class="fas fa-undo fa-fw"></i>
              </button>
              <button class="btn btn-xs btn-info ml-1" (click)="showOverrideNote(qty.overrideNote)" *ngIf="!editable && pricingEditable && qty.priceOverride">
                <i class="fas fa-sticky-note fa-fw"></i>
              </button>
            </td>
            <td class="price">
              {{ getUnitPriceForQuantity(qty) | currency }}
            </td>
            <td class="price">
              {{ getExtPriceForQuantity(qty) | currency }}
            </td>
            <td *ngIf="quantityEditable">
              <span *ngIf="leadTimeMap[qty.value] == null || leadTimeMap[qty.value] == undefined">
                <i class="fa fa-spin fa-circle-notch text-primary"></i>
              </span>
              <span *ngIf="leadTimeMap[qty.value] != null && leadTimeMap[qty.value] != undefined">
                {{ leadTimeMap[qty.value] }} working days
              </span>
            </td>
            <td>
              <button class="btn btn-xs btn-danger" (click)="deleteQty(qty.value)" [disabled]="!editable">
                <i class="fas fa-times fa-fw"></i>
              </button>
            </td>
          </tr>
          <tr *ngIf="editable">
            <td colspan="7">
              <input placeholder="Add quantity" class="qty-input" type="number" min="1" [(ngModel)]="addingQty">
            </td>
            <td>
              <button class="btn btn-sm btn-primary" (click)="addQty()" [disabled]="!addingQty">
                <i class="fas fa-plus fa-fw"></i>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  <table *ngIf="product.parentAssemblyId">
    <thead>
      <tr>
        <th colspan="4" class="product-name">
          Sub-assembly: {{product?.partNumber}} / Rev {{product?.revision}}
        </th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <th>Quantity</th>
        <th style="min-width: 100px">Cost</th>
        <th>Markup %</th>
        <th style="min-width: 100px">Price</th>
      </tr>
      <tr>
        <td *ngIf="editable">
          <input type="number" min="1" max="100" [(ngModel)]="product.quantityAsChild" (ngModelChange)="setDirty()">
          <span class="small font-weight-normal">× {{ parentQty}} = {{ product.quantityAsChild * parentQty }}</span>
        </td>
        <td *ngIf="!editable" class="qty-value">
          {{ parentQty === 0 ? '?' : product.quantityAsChild * parentQty }}
          <span class="small font-weight-normal" *ngIf="this.product.parentAssemblyId">({{product.quantityAsChild}} × {{ parentQty === 0 ? '?' : parentQty }})</span>
        </td>
        <td class="price">
          <span *ngIf="!parentQty">?</span>
          <span *ngIf="parentQty">{{ getCostForQuantity(product.quantityAsChild) | currency }}</span>
        </td>
        <td>{{ (parentMarkup !== null && parentMarkup !== undefined) ? parentMarkup + '%' : '?' }}</td>
        <td class="price">{{ parentQty === 0 ? '?' : (getCostForQuantity(product.quantityAsChild) * ( 1 + parentMarkup / 100) | currency) }}</td>
      </tr>
    </tbody>
  </table>


  <div>
    <a *ngIf="this.product.childAssemblies && hasChildren()" (click)="showChildren = !showChildren" class="children-collapser">
      <i class="fas fa-minus d-inline-block mr-2" *ngIf="showChildren"></i>
      <i class="fas fa-plus d-inline-block mr-2" *ngIf="!showChildren"></i>
      {{ this.product.childAssemblies.length }} child assembl{{ this.product.childAssemblies.length !== 1 ? 'ies' : 'y' }}
    </a>
    <div class="container-fluid ml-4 mt-2" *ngIf="standaloneSteps && standaloneSteps.length > 0">
      <div class="row">
        <div class="col-6">
          <table>
            <thead>
              <tr>
                <th colspan="2" class="product-name">Standalone Steps</th>
              </tr>
              <tr>
                <th>Name</th>
                <th>Cost</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let step of standaloneSteps">
                <td>{{ getStation(step)?.name }}</td>
                <td>{{ (step.runIsPerPart ? ((step.runTime || 0) / 60) * step.runPrice : ((step.runTime || 0)) * step.runPrice) | currency }}<span class="font-weight-bold small" *ngIf="step.runIsPerPart"> per part</span></td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div *ngIf="this.product.childAssemblies && hasChildren()" class="container-fluid ml-4 mt-2 children-container" [class.children-container-hidden]="!showChildren">
      <div class="position-relative">
        <div class="position-absolute child-arrow">
          <i class="fas fa-arrow-right"></i>
        </div>
        <quantity-table
          #quantityTable
          *ngFor="let child of product.childAssemblies"
          [editable]="editable"
          [product]="child"
          [parentQty]="getNextParentQty(child)"
          [parentMarkup]="childMarkup"
          [breakdown]="getBreakdownForProduct(child)"
          [leadTime]="leadTime"
          >
        </quantity-table>
      </div>
    </div>
  </div>
</div>


<ng-template #overridePriceDialog let-data>
  <h2 matDialogTitle>Modify Price</h2>
  <mat-dialog-content>
    <p>Please provide a modified price for quantity <b>{{data.qty.value}}</b> and notes/reasoning for it.</p>
    <div class="form-group">
      <label>Price</label>
      <div class="input-group input-group-sm">
        <div class="input-group-prepend">
          <span class="input-group-text disabled"><strong>$</strong></span>
        </div>
        <input type="number" [(ngModel)]="data.newPrice" [placeholder]="data.basePrice | number" class="form-control form-control-sm form-inline">
      </div>
    </div>
    <div class="form-group">
      <label>Notes</label>
      <textarea class="form-control form-control-sm form-inline" [(ngModel)]="data.note"></textarea>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button mat-button matDialogClose color="secondary">Cancel</button>
    <button mat-button matDialogClose color="secondary" (click)="setOverride(data.qty.value, null, '')">Clear</button>
    <button mat-button matDialogClose color="danger" [disabled]="!data.newPrice || !data.note" (click)="setOverride(data.qty.value, data.newPrice, data.note)">Confirm</button>
  </mat-dialog-actions>
</ng-template>