import { Component, Output, EventEmitter, Input, OnInit, OnChanges } from '@angular/core';
import { InventoryLocation } from '../../../inventory/resources/inventory-location';
import { MatRadioChange } from '@angular/material/radio';
import { QualityTicketType } from '../../resources/quality-ticket';
import { InventoryService } from '../../../inventory/services/inventory.service';
import { MatCheckboxChange } from '@angular/material/checkbox';

@Component({
  selector: 'ticket-type-filter',
  templateUrl: './ticket-type-filter.component.html',
  styleUrls: ['./quality-ticket-list.component.less']
})
export class TicketTypeFilterComponent {
  @Input() selection: QualityTicketType[];
  @Output() selectionChange: EventEmitter<QualityTicketType[]> = new EventEmitter<QualityTicketType[]>();

  constructor(private service: InventoryService) { }

  public filterTypes = [
    {
      type: QualityTicketType.ContractReview,
      name: "Contract Review"
    },
    {
      type: QualityTicketType.ReceivedShippingTicket,
      name: "Received Shipment"
    },
    {
      type: QualityTicketType.SupplierCertExpiration,
      name: "Cert Expiration"
    },
    {
      type: QualityTicketType.RMAEvaluation,
      name: "RMA Disposition"
    },
    {
      type: QualityTicketType.RMAApproval,
      name: "RMA Request"
    },
  ];

  change(event: MatCheckboxChange, type: QualityTicketType) {
    const enabled = event.checked;
    if (enabled) this.selection = [...this.selection, type];
    else this.selection = this.selection.filter(t => t !== type);
    this.selectionChange.emit(this.selection);
  }

  allTypesEnabled() {
    return this.selection.length > 0 && this.filterTypes.every(t => this.selection.includes(t.type));
  }

  someTypesEnabled(): boolean {
    const amt = this.filterTypes.filter(t => this.selection.includes(t.type)).length;
    return amt > 0 && amt < this.filterTypes.length;
  }

  setAll(enabled: boolean) {
    if (!enabled) this.selection = [];
    else this.selection = this.filterTypes.map(t => t.type);
    this.selectionChange.emit(this.selection);
  }
}
