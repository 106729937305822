import { Component, forwardRef, OnChanges, Input, Inject, Injector, SimpleChanges } from "@angular/core";
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from "@angular/forms";
import { map } from "rxjs/operators";
import { Customer } from "../../../../customer/resources/customer";
import { CustomerService } from "../../../../customer/services/customer.service";
import { GenericSearchComponent } from "../../generic-search/generic-search.component";

@Component({
  selector: 'customer-search',
  templateUrl: '../../generic-search/generic-search.component.html',
  styleUrls: ['../../generic-search/generic-search.component.less'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CustomerSearch),
      multi: true
    }
  ]
})
export class CustomerSearch extends GenericSearchComponent<Customer> implements OnChanges, ControlValueAccessor {

  @Input() readonly = false;
  placeholder = 'Customer'

  constructor(private customerSvc: CustomerService, @Inject(Injector) injector: Injector) {
    super(injector);
  }

  doSearch(searchText: string) {
    return this.customerSvc.search(searchText).pipe(
      map(r => r.results)
    )
  }

  public canAdd(): boolean { return false }

  public get addItemText(): string {
     return '';
  }

  public get noItemsText(): string {
    return 'No matching customers found.';
  }

  public getSearchField(c: Customer): string {
    return c && c.businessName;
  }

}