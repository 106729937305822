import { Component } from '@angular/core';
import { Customer } from './../../../customer/resources/customer';
import { CustomerService } from './../../../customer/services/customer.service';
import { Router } from '@angular/router';
import { NavigationService } from './../../../common/services/navigation.service';
import { UtilityService } from './../../../common/services/utility.service';
import { SearchList } from '../../../common/components/search-list/search-list';

@Component({
  selector: 'customer-list',
  templateUrl: './customer-list.component.html',
  styleUrls: ['./customer-list.component.less']
})
export class CustomerListComponent extends SearchList<Customer> {

  constructor(navService: NavigationService, private customerService: CustomerService, private router: Router) {
    super('customer');
    navService.setPageTitle("Customers");
  }

  protected search(): void {
    this.results = null;
    this.customerService.search(this.searchString, this.pageNumber, this.sortTerm, this.sortDirection).subscribe(results => {
      this.searchResults = results;
      this.results = results.results
    });
  }

  public getRequiredColor(date: string): string {
    return UtilityService.getDateRequiredColor(date);
  }

  public onSelect(record: Customer): void {
    this.router.navigate(['/customer', record.customerId]);
  }
}
