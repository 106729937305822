<loading *ngIf="loading" content="Loading..."></loading>
<loading *ngIf="saving" content="Saving..."></loading>

<button mat-fab color="warn" class="position-fixed" style="bottom: 15px; right: 10px; z-index: 1030" (click)="close()">
  <mat-icon>close</mat-icon>
</button>

<div class="container-fluid py-2 d-flex flex-column">
  <div class="row h-100">
    <div class="col-4 h-100 d-flex flex-column">
      <mat-form-field appearance="outline" dense class="w-100 dense-field">
        <mat-label>Filter Items</mat-label>
        <input matInput type="text" [(ngModel)]="filter" #filterModel="ngModel">
      </mat-form-field>
      <button mat-flat-button [disabled]="isCreating" color="primary" class="mb-3 new-item-button button-text"
        (click)="createNewItem()">
        New Item
      </button>
      <ul (scroll)="onListScroll($event)"
        class="list-group list-group-hover list-group-striped overflow-y-scroll relative flex-spread">
        <div *ngIf="searching" class="searching-indicator">
          <i class="fa fa-lg fa-spin fa-circle-notch text-primary"></i>
          <div class="text-muted font-italic mt-1">Searching...</div>
        </div>
        <li (click)="setSelectedItem(item)" class="list-group-item mat-caption"
          [class.item-selected]="selectedItem && selectedItem.purchasedItemId === item.purchasedItemId"
          *ngFor="let item of items; index as i" [innerHTML]="item.description | highlight: filter">
        </li>
      </ul>
    </div>
    <div class="col-8 h-100">
      <mat-card class="h-100 mat-elevation-z0 border border-secondary" [class.p-0]="!selectedItem">
        <div *ngIf="!selectedItem"
          class="bg-light p-3 w-100 h-100 d-flex align-items-center justify-content-center text-muted font-italic">
          Choose or
          create a purchased item from the left
          panel.</div>
        <ng-container *ngIf="!!selectedItem">
          <mat-card-header>
            <mat-card-title>
              {{ isCreating ? 'Creating' : 'Editing' }}
              Purchased Item/Hardware</mat-card-title>
            <mat-card-subtitle>{{selectedItem?.description || '&nbsp;'}}</mat-card-subtitle>
          </mat-card-header>
          <mat-card-content>
            <mat-tab-group style="margin: 0 -16px;">
              <mat-tab label="Item Information">
               <form #itemForm="ngForm" class="p-2">
                <mat-form-field appearance="outline" class="w-100">
                <mat-label>Item Description</mat-label>
                <input autocomplete="off" matInput type="text" [(ngModel)]="selectedItem.description" name="description"
                  required>
                <mat-error *ngIf="itemForm.controls['description']?.errors?.required">
                  Description is required.
                </mat-error>
              </mat-form-field>
              <mat-form-field appearance="outline" class="w-100" *ngIf="!categoryIsNew">
                <mat-label>Item Category</mat-label>
                <mat-icon *ngIf="selectedItem?.purchasedItemCategory?.isTooling" matPrefix class="text-muted">
                  construction
                </mat-icon>
                <input type="text" placeholder="Item Category" matInput
                  (input)="categories.filter = $event.target.value"
                  [(ngModel)]="selectedItem.purchasedItemCategory" (ngModelChange)="selectedItem.purchasedItemCategoryId = $event?.purchasedItemCategoryId" #categoryModel="ngModel" name="category" [notNullValidator]
                  [requireSelection]="auto"
                  [matAutocomplete]="auto">
                <mat-autocomplete #auto="matAutocomplete" [displayWith]="categoryDisplayFn">
                  <mat-option *ngFor="let category of (categoriesData | async)" [value]="category">
                    <mat-icon *ngIf="category.isTooling" matPrefix class="text-muted">
                      construction
                    </mat-icon>
                    {{category.name}}
                  </mat-option>
                  <mat-option *ngIf="(categoriesData | async).length === 0 && categories.filter" class="text-muted" (click)="newCategory(categories.filter)" (keydown.enter)="newCategory(categories.filter)">
                    <mat-icon matPrefix>add</mat-icon> <b>Add "{{categories.filter}}"</b>
                  </mat-option>
                </mat-autocomplete>
                <mat-error *ngIf="!categoryModel.valid">
                  Category is required.
                </mat-error>
              </mat-form-field>
              <mat-form-field appearance="outline" class="w-100" *ngIf="categoryIsNew">
                <mat-label>Item Category</mat-label>
                <div matPrefix class="badge bg-primary text-white button-text mr-1" style="position: relative; top: -5px;">New</div>
                <mat-icon *ngIf="selectedItem?.purchasedItemCategory?.isTooling" matPrefix class="text-muted">
                  construction
                </mat-icon>
                <input readonly type="text" placeholder="Item Category" matInput
                  (input)="categories.filter = $event.target.value"
                  [(ngModel)]="selectedItem.purchasedItemCategory.name" name="category" [notNullValidator]>
                <button matSuffix mat-icon-button (click)="selectedItem.purchasedItemCategory = null; categoryIsNew = false"><mat-icon>close</mat-icon></button>
              </mat-form-field>
              <div class="border rounded position-relative mt-3" *ngIf="!isCreating">
                <div class="pseudo-label">Alternate Part Numbers</div>
                <button mat-fab class="bg-primary text-white mat-elevation-z2" style="
                  position: absolute;
                  top: -28px;
                  right: 28px;
                  " (click)="createAlternatePartNumber()">
                  <mat-icon>add</mat-icon>
                </button>
                <div class="w-100 h-100 overflow-hidden rounded">
                  <table class="w-100" mat-table [dataSource]="selectedItem.purchasedItemPartNumbers">
                    <ng-container matColumnDef="index">
                      <th mat-header-cell *matHeaderCellDef>#</th>
                      <td mat-cell class="text-muted" *matCellDef="let index = index">{{index + 1}}</td>
                    </ng-container>
                    <ng-container matColumnDef="partNumber">
                      <th mat-header-cell *matHeaderCellDef>Part Number</th>
                      <td mat-cell *matCellDef="let partNumber">
                        {{ partNumber.partNumber }}
                      </td>
                    </ng-container>
                    <ng-container matColumnDef="vendor">
                      <th mat-header-cell *matHeaderCellDef>Vendor</th>
                      <td mat-cell *matCellDef="let partNumber">
                        <span *ngIf="partNumber.vendor">
                          {{ partNumber.vendor.name }}
                        </span>
                        <span *ngIf="!partNumber.vendor" class="text-muted">
                          --
                        </span>
                      </td>
                    </ng-container>
                    <ng-container matColumnDef="delete">
                      <th mat-header-cell *matHeaderCellDef style="width: 76px;">&nbsp;</th>
                      <td mat-cell *matCellDef="let partNumber">
                        <button mat-icon-button class="text-danger" matRippleColor="rgb(220,53,69,.15)!"
                          (click)="deleteAlternatePartNumber(partNumber)">
                          <mat-icon>delete</mat-icon>
                        </button>
                      </td>
                    </ng-container>
                    <tr mat-header-row *matHeaderRowDef="['index', 'partNumber', 'vendor', 'delete']"></tr>
                    <tr mat-row *matRowDef="let row; columns: ['index', 'partNumber', 'vendor', 'delete'];"></tr>
                  </table>
                </div>
                <div *ngIf="selectedItem.purchasedItemPartNumbers.length === 0"
                  class="w-100 text-muted small font-italic py-3 text-center">No alternate part numbers exist for this
                  purchased item.</div>
              </div>
              </form>
              </mat-tab>
              <mat-tab label="Pricing History">
                  <purchasing-line-item-history [itemId]="this.selectedItem.purchasedItemId"></purchasing-line-item-history>
              </mat-tab>
            </mat-tab-group>
          </mat-card-content>
          <mat-card-actions align="end">
            <button type="button" mat-flat-button color="warn" class="mr-2" *ngIf="!isCreating"
              (click)="deleteSelected()">
              Delete
            </button>
            <button type="submit" mat-flat-button color="primary" class="mr-5"
              [disabled]="itemForm.invalid || itemForm.pristine" (click)="saveSelected()">
              Save
            </button>
          </mat-card-actions>
        </ng-container>
      </mat-card>
    </div>
  </div>
</div>


<ng-template #newPartNumberDialogTemplate let-data>
  <h2 mat-dialog-title>New Part Number</h2>
  <mat-dialog-content>
    <div class="row">
      <div class="col-12">
        <mat-form-field appearance="outline" class="w-100 mat-input-no-message">
          <mat-label>Part Number</mat-label>
          <input matInput required placeholder="Part Number" type="text" max="100" [(ngModel)]="data.partNumber" />
        </mat-form-field>
      </div>
      <div class="col-12">
        <vendor-search placeholder="Associated Vendor (optional)" [(selectedItem)]="data.vendor"
          (selectedItemChange)="data.vendorId = $event.vendorId"></vendor-search>
      </div>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button mat-button class="btn btn-default btn-sm mr-3" [matDialogClose]="null">Cancel</button>
    <button mat-button [disabled]="!data.partNumber" [matDialogClose]="data">Add Part Number</button>
  </mat-dialog-actions>
</ng-template>

<ng-template #newCategoryDialogTemplate let-data>
  <h2 mat-dialog-title>New Purchased Item Category</h2>
  <mat-dialog-content>
    <div class="row">
      <div class="col-8">
        <mat-form-field appearance="outline" class="w-100 mat-input-no-message">
          <mat-label>Category Name</mat-label>
          <mat-icon matPrefix [class.invisible]="!data.isTooling" class="mr-1 text-muted">
            construction
          </mat-icon>
          <input matInput type="text" [(ngModel)]="data.name" />
        </mat-form-field>
      </div>
      <div class="col-4 d-flex align-items-center">
        <mat-checkbox class="mat-checkbox-no-margin" [(ngModel)]="data.isTooling">Is Tooling</mat-checkbox>
      </div>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button mat-raised-button color="primary" [disabled]="!data.name" [matDialogClose]="data">Add Category</button>
    <button mat-button class="btn btn-default btn-sm mr-3" [matDialogClose]="null">Cancel</button>
  </mat-dialog-actions>
</ng-template>