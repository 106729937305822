import { Component, Input, EventEmitter, Output, ElementRef, ViewChild } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { DocumentService } from '../../../common/services/document.service';
import { UtilityService } from '../../../common/services/utility.service';
import { VirtualDocument } from '../../../common/resources/virtual-document';
import { Subject, Observable } from 'rxjs';

import {WebcamImage, WebcamInitError, WebcamUtil} from 'ngx-webcam';

@Component({
  selector: 'webcam-snapshot',  
  templateUrl: './webcam-snapshot.component.html',
  styleUrls: ['./webcam-snapshot.component.less']
})
export class WebcamSnapshotComponent {
  @Input() sidenav: MatSidenav;
  @Input() documentTags: string[] = [];
  @Input() saveDocuments: boolean = true;  
  @Input() photoFilenamePrefix: string = "Photo";
  @Output() photosTaken: EventEmitter<VirtualDocument[]> = new EventEmitter<VirtualDocument[]>();

  saving: boolean = false;
  public currentFileName: string = "Saving Photos...";

  // Carousel
  public indexes: number[] = []

  // Webcam
  public showWebcam = true;
  public allowCameraSwitch = true;
  public multipleWebcamsAvailable = false;
  public deviceId: string;
  public errorString: string = null;

  public errors: WebcamInitError[] = [];
  public videoOptions: MediaTrackConstraints = {
    // width: {ideal: 1024},
    // height: {ideal: 576}
  };

  private trigger: Subject<void> = new Subject<void>(); // webcam snapshot trigger
  private nextWebcam: Subject<boolean|string> = new Subject<boolean|string>();  // switch to next / previous / specific webcam; true/false: forward/backwards, string: deviceId
  
  // Documents
  public photos: WebcamImage[] = [];
  public documents: VirtualDocument[] = [];

  constructor(private utilitySvc: UtilityService, private documentService: DocumentService)
  {
  }

  ngAfterViewInit() {
    WebcamUtil.getAvailableVideoInputs()
    .then((mediaDevices: MediaDeviceInfo[]) => {
      this.multipleWebcamsAvailable = mediaDevices && mediaDevices.length > 1;
    });
  }

  public enableWebcam(): void {
    this.showWebcam = true;
  }

  cancelEditing(): void {
    if (this.photos.length > 0) {
      this.utilitySvc.showConfirmation("Are you Sure?",
        "<p>You have photos that have not yet been uploaded.</p><p class='text-muted'>Push 'Okay' to discard these photos.</p>",
        (r => {
          if (r == true) {
            this.closeEditor();
          }
        }));
    }
    else {
      this.closeEditor();
    }
  }

  doUploads(): void {
    this.saving = true;

    if (this.saveDocuments) {
      if (this.photos.length > 0) {
        const photo = this.photos.pop();
        const documentFile = this.dataURLtoFile(photo.imageAsDataUrl, `${this.photoFilenamePrefix}.jpg`);
        documentFile.tags = this.documentTags;
        documentFile.imageAsDataUrl = photo.imageAsDataUrl;
        this.uploadDocument(documentFile).subscribe(d => {
          this.documents.push(d);
          this.doUploads();
        });
      } else {
        this.photosTaken.emit(this.documents);
        this.closeEditor();
      }
    } else {
      const documentFiles = this.photos.map<any>(p => {
        const docFile = this.dataURLtoFile(p.imageAsDataUrl, `${this.photoFilenamePrefix}.jpg`);
        docFile.tags = this.documentTags;
        docFile.imageAsDataUrl = p.imageAsDataUrl;
        return docFile;
      });
      
      this.photosTaken.emit(documentFiles);
      this.closeEditor();
    }
  }

  private uploadDocument(document: any): Observable<VirtualDocument> {
    this.currentFileName = `Saving '${document.name}'`;
    var subject = new Subject<VirtualDocument>();
    this.documentService.upload(document)
      .subscribe(r => {
        subject.next(r);
      });

    return subject;
  }

  private dataURLtoFile(dataurl, filename): any {
    var arr = dataurl.split(','),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[arr.length - 1]), 
        n = bstr.length, 
        u8arr = new Uint8Array(n);
    while(n--) {
        u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, {type:mime});
  }

  public deletePhoto(photos: any): void {
    const index = this.photos.indexOf(photos, 0);
    if (index > -1) {
      this.photos.splice(index, 1);
    }
  }

  private closeEditor(): void {
    this.showWebcam = false;
    this.photos = [];
    this.documents = [];

    this.saving = false;

    if (this.sidenav)
      this.sidenav.close();
  }

  public handleInitError(error: WebcamInitError): void {
    if ((error.mediaStreamError && error.mediaStreamError.name === "NotAllowedError") ||
        error.message == "Permission dismissed" || error.message == "Permission denied") {
      this.errorString = "Camera access must be allowed to take photos.";
    } else if (error.mediaStreamError && error.mediaStreamError.name === "NotFoundError") {
      this.errorString = "Unable to find webcam.";
    } else {
      this.errorString = error.message;
    }
  }

  public triggerSnapshot(): void {
    this.trigger.next();
  }

  public handleImage(webcamImage: WebcamImage): void {
    this.photos.push(webcamImage);
  }

  public get triggerObservable(): Observable<void> {
    return this.trigger.asObservable();
  }

  public showNextWebcam(directionOrDeviceId: boolean|string): void {
    this.nextWebcam.next(directionOrDeviceId);
  }

  public get nextWebcamObservable(): Observable<boolean|string> {
    return this.nextWebcam.asObservable();
  }

  public cameraWasSwitched(deviceId: string): void {
    this.deviceId = deviceId;
  }
}
