import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { OrderDetailService } from '../../order-detail.service';
import { Station } from '../../../../resources/station';
import { WorkflowStep } from '../../../../resources/workflow';
import { UtilityService } from '../../../../../common/services/utility.service';
import { TaskStatus } from '../../../../../common/resources/estimatingtask';
import { EstimateProgressService } from '../../../../services/estimate-progress.service';
import { Subject, Subscription, merge } from 'rxjs';
import { NgForm } from '@angular/forms';
import { take } from 'rxjs/operators';
import { ProductWorkflowStepFormComponent } from './product-workflow-step-form/product-workflow-step-form.component';

@Component({
  selector: 'product-workflow',
  templateUrl: './product-workflow.component.html',
  styleUrls: ['./product-workflow.component.less']
})
export class ProductWorkflowComponent {

  constructor(
    public service: OrderDetailService,
    public utilityService: UtilityService,
    public progressService: EstimateProgressService
  ) { }

  public get record() { return this.service.order }
  public get product() { return this.service.selectedProduct }
  public get editing() { return this.service.editing }

  public get selectedStepId() { return this.service.selectedWorkflowStepId }
  public set selectedStepId(val: string) {
    this.service.selectedWorkflowStepId = val;
    window.location.hash = this.service.generateSubItemNavigationId(this.product.productId, "workflow", val);
  }
  public get selectedStep() {
    return this.product.workflow?.workflowSteps?.find(s => s.workflowStepId === this.selectedStepId);
  }

  private updateProgress() {
    if (!this.progressService.shouldShowIndicators()) return;
    const status = this.progressService.getSimpleTaskStatus(this.product.productId, "workflow", this.service.selectedTopLevelProduct.productId);
    if (status === TaskStatus.NOT_STARTED) this.progressService.setSimpleTaskStatus(this.product.productId, "workflow", this.service.selectedTopLevelProduct.productId, TaskStatus.IN_PROGRESS);
    this.service.notifyEdited();
  }

  addStep(index: number, station: Station) {
    const newStep = WorkflowStep.newEmptyStep(station);
    newStep.workflowStepId = UtilityService.newGuid();
    this.service.addNewId(newStep.workflowStepId);
    newStep.workflowId = this.product.workflow.workflowId;
    const newSteps = this.product.workflow.workflowSteps.slice();
    newSteps.splice(index, 0, newStep);
    this.product.workflow.workflowSteps = [...newSteps];
    this.product.workflow.workflowSteps.forEach((v, i) => v.stepOrder = i);
    this.selectedStepId = newStep.workflowStepId;
    this.service.notifyEdited();
    this.updateProgress();
  }

  public async deleteStep() {
    if (!this.selectedStep) return;
    const r = await this.utilityService.showConfirmationPromise('Are you sure?', 'Really delete this step? This cannot be undone.');
    if (!r) return;
    const stepId = this.selectedStepId;
    this.selectedStepId = null;
    this.product.workflow.workflowSteps = this.product.workflow.workflowSteps.filter(s => s.workflowStepId !== stepId)
    this.service.notifyEdited();
  }

  public getWorkflowTaskStatus() {
    return this.progressService.getSimpleTaskStatus(this.product.productId, 'workflow', this.service.selectedTopLevelProduct.productId)
  }

  public setWorkflowTaskStatus(status: TaskStatus) {
    return this.progressService.setSimpleTaskStatus(this.product.productId, 'workflow', this.service.selectedTopLevelProduct.productId, status)
  }

}
