<div>
  <div *ngIf="!workOrder">
    <a *ngIf="product && !editing && showEditor==null && canEdit"
       class="position-fixed fixed-bottom btn btn-badge has-icon" title="Edit" (click)="toggleEditing()">
      <i class="fas fa-edit"></i>
    </a>
    <a *ngIf="showEditor==null" class="mr-6 position-fixed fixed-bottom btn btn-badge bg-danger has-icon" title="Cancel"
       (click)="cancelEditing()"><i class="fas fa-times"></i></a>
    <a *ngIf="product && editing && showEditor==null"
       class="position-fixed fixed-bottom btn btn-badge bg-success has-icon" title="Save" (click)="saveChanges(true)">
      <i class="fas fa-save"></i>
    </a>
  </div>

  <loading *ngIf="!product"></loading>
  <loading *ngIf="saving" content="Saving..."></loading>
  <loading *ngIf="loading"></loading>

  <similar-part-overlay [(isOpen)]="similarPartOverlayOpen" *ngIf="shouldShowSimilarProducts()" [product]="product" (preview)="showProductPreview($event)" (assign)="assignSimilarProduct($event)"></similar-part-overlay>

  <div class="p-0 container-fluid">
    <mat-sidenav-container class="p-0 m-0">
      <mat-sidenav disableClose (openedStart)="sidebarOpening()" (closedStart)="sidebarClosing()" #insetnav mode="over" position="end">
        <product-detail
        [order]="record"
        [customer]="customer" [orderId]="orderId" [canEdit]="canEdit" [(product)]="selectedPart" *ngIf="showEditor=='part'"
                        [sidenav]="insetnav" (productUpdate)="onSubproductUpdate(selectedPart)" [department]="this.department" [shouldPromptHistory]="shouldPromptHistory"></product-detail>
        <product-detail [canEdit]="false" [(product)]="previewPart" *ngIf="showEditor=='previewPart'" [sidenav]="insetnav" [shouldPromptHistory]="false"></product-detail>
        <upload-file *ngIf="showEditor=='document'" (documentsLoaded)="addDocuments($event)" [sidenav]="insetnav">
        </upload-file>
        <product-finder (partSelected)="addNewPart($event)" *ngIf="showEditor=='addProduct'" [sidenav]="insetnav">
        </product-finder>
        <quote-detail [forceVendor]="forceVendor" [data]="newQuoteData" [customer]="customer" *ngIf="showEditor=='quickquote'" [sidenav]="insetnav"></quote-detail>
        <quote-detail [id]="detailedQuoteId" [customer]="customer" *ngIf="showEditor=='quotedetail'" [sidenav]="insetnav"></quote-detail>
        <paint-editor *ngIf="showEditor=='paintEditor'" [sidenav]="insetnav"></paint-editor>
        <material-editor *ngIf="showEditor=='materials'" [sidenav]="insetnav"></material-editor>
        <station-editor *ngIf="showEditor=='stations'" [sidenav]="insetnav" (saved)="onNewStation()"></station-editor>
      </mat-sidenav>
      <mat-sidenav-content>
        <mat-stepper [selectedIndex]="selectedTab" (selectionChange)="setSelectedTab($event)"
                                [linear]="false" #stepper *ngIf="product">
          <ng-template matStepperIcon="edit">
            <i class="fas fa-check fa-fw"></i>
          </ng-template>
          <ng-template matStepperIcon="done">
            <i class="fas fa-check fa-fw"></i>
          </ng-template>
          <mat-step label="General Info">
            <div class="mb-3 mt-1 mr-5 p-2 bg-light">
              <button disabled style="visibility:hidden" class="btn btn-sm btn-default">[placeholder]</button>
              <button class="btn btn-sm btn-default mr-2 float-right" matStepperNext>Next</button>
            </div>
            <div>
              <div *ngIf="!workOrder && !rapidEdit" class="col-3 float-right border-left border-secondary min-vh-80 p-0 bg-light">
                <div class="text-center border-bottom border-dark">
                  <h5 class="bg-secondary m-0 p-2">Associated Parts</h5>
                </div>
                <div class="m-3 partTree" *ngIf="childrenLoaded">
                  <product-tree (selected)="selectProduct($event)" (delete)="deletePart($event)"
                                (deleteChild)="deletePartChild($event)" [product]="product" [editing]="editing"
                                *ngFor="let product of product.childAssemblies"></product-tree>
                  <p *ngIf="product.childAssemblies.length==0" class="font-italic text-muted text-center">
                    -- No
                    Subassemblies --
                  </p>
                  <p *ngIf="editing" class="text-center">
                    <button class="btn btn-sm btn-primary" (click)="addPart()">
                      <i class="fas fa-plus"></i> Add
                      Subcomponent
                    </button>
                  </p>
                  <p *ngIf="editing" class="text-center">
                    <button class="btn btn-sm btn-primary" (click)="copyPart()">
                      <i class="fas fa-plus"></i> Copy Existing Part
                    </button>
                  </p>
                </div>
                <div class="bg-secondary p-1 total font-weight-bold text-muted small">
                  Total Cost:
                  <i *ngIf="totalCost==null; else showCost" title="Calculating..."
                     class="text-info fas fa-spin fa-lg fa-circle-notch"></i>
                  <ng-template #showCost>
                    <span>{{totalCost | currency}}</span>
                  </ng-template>
                </div>
              </div>
              <div class="row">
                <div class="col-12 mb-3" *ngIf="incompleteAssemblyInfo as reviewInfo">
                  <div class="border rounded p-3"
                    [class.border-warning]="reviewInfo.status == 1"
                    [class.border-success]="reviewInfo.status == 3"
                  >
                    <div class="d-flex align-items-center font-weight-medium">
                      <mat-icon class="mr-2 text-warning" svgIcon="alert" *ngIf="reviewInfo.status == 1"></mat-icon>
                      <span *ngIf="reviewInfo.status == 1">This assembly has been marked by a reviewer as missing info</span>
                      <span *ngIf="reviewInfo.status == 3">This assembly was marked by a reviewer as missing info, but has been corrected</span>
                      <button mat-stroked-button *ngIf="reviewInfo.status == 1 && !editing" class="ml-auto" (click)="markAsCorrected()">Mark as Corrected</button>
                    </div>
                    <p class="small mb-0 mt-2">
                      {{ reviewInfo.note }}
                    </p>
                  </div>
                </div>
                <div class="col-8 d-flex align-items-center">
                  <div class="form-group">
                    <label>Part Number</label>
                    <input required #partNumber="ngModel" minlength="2" placeholder="Part Number" type="text" max="100"
                           class="form-control" appStaticEdit [editable]="editing" [(ngModel)]="product.partNumber" />
                  </div>
                  <button mat-stroked-button disabled class="text-success" *ngIf="product.productStandard?.productId === product.productId" matTooltip="This copy of the product is what will be cloned when adding it to an RFQ from the main products list." matTooltipPosition="after">
                    <mat-icon matPrefix>check</mat-icon>
                    Canonical
                  </button>
                  <button mat-stroked-button class="text-primary" *ngIf="product.productStandard?.productId !== product.productId" (click)="makeCanonical()">
                    Make Canonical
                  </button>
                </div>
                <div class="col-4">
                  <div class="form-group">
                    <label>Revision</label>
                    <input required #partRevision="ngModel" minlength="1" placeholder="Revision" type="text" max="20"
                           class="form-control" appStaticEdit [editable]="editing" [(ngModel)]="product.revision" />
                  </div>
                </div>
                <div class="col-4">
                  <div class="form-group">
                    <label>Customer Quote Line No</label>
                    <input placeholder="Customer Quote Line No" type="text" max="100" class="form-control" appStaticEdit
                           [editable]="editing" [(ngModel)]="product.customerQuoteLineNo" />
                  </div>
                </div>
                <div class="col-4">
                  <div class="form-group">
                    <label>
                      Date Required
                      <span *ngIf="product.dateRequired!=null" class="badge required p-1 pl-2 pr-2"
                            [ngClass]="getRequiredColor(product.dateRequired)">
                        <span class="fa-lg">
                          {{weeksLeft(product.dateRequired, true)||'--'}} Week(s)
                          {{(weeksLeft(product.dateRequired, false)||0) >= 0 ? 'From Today':'Overdue'}}
                        </span>
                      </span>
                    </label>
                    <date-picker [placeholder]="'Date Required'" [editable]="editing" [(date)]="product.dateRequired">
                    </date-picker>
                  </div>
                </div>
                <div class="col-4">
                  <div class="form-group">
                    <label *ngIf="product.parentAssemblyId==null">Quantities</label>
                    <label *ngIf="product.parentAssemblyId!=null">Quantity</label>
                    <input *ngIf="product.parentAssemblyId!=null" placeholder="Assembly Qty" type="number" min="0"
                           max="10000" class="form-control" appStaticEdit [editable]="editing"
                           [(ngModel)]="product.quantityAsChild" />
                    <multiple-quantity-input *ngIf="product.parentAssemblyId==null" [editable]="editing"
                                             [(quantities)]="product.quantitiesMap"></multiple-quantity-input>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-12">
                  <div class="form-group">
                    <label>Lead Time</label>
                    <div *ngIf="!updatingLeadTime">{{ leadTime }} business days</div>
                    <div *ngIf="updatingLeadTime">
                      <i class="fa fa-lg fa-spin fa-spinner text-primary"></i> Updating...
                    </div>
                  </div>
                </div>
              </div>
              <div class="row pb-2">
                <div class="col-12 p-0 bg-light">
                  <h5 class="w-100 p-2 bg-secondary">Description</h5>
                  <div class="p-1">
                    <textarea rows="1" appStaticEdit [editable]="editing" class="form-control form-control-sm"
                              [(ngModel)]="product.description"
                              placeholder="Product description (optional) - will appear on invoice."></textarea>
                  </div>
                </div>
              </div>
              <div class="row pb-2">
                <div class="col-6 pl-0 pr-1 bg-light">
                  <h5 class="w-100 p-2 pl-1 bg-secondary">Notes<small class="float-right">(Public)</small></h5>
                  <div class="p-1">
                    <textarea rows="6" appStaticEdit [editable]="editing" class="form-control form-control-sm"
                              [(ngModel)]="product.note" placeholder="Client visible notes can go here"></textarea>
                  </div>
                </div>
                <div class="col-6 pr-0 bg-light">
                  <h5 class="w-100 p-2 bg-secondary">Notes<small class="float-right">(Private)</small></h5>
                  <div class="p-1">
                    <textarea rows="6" appStaticEdit [editable]="editing" class="form-control form-control-sm"
                              [(ngModel)]="product.privateNote" placeholder="Internal only notes can go here"></textarea>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-12 p-0 bg-light">
                  <h5 class="w-100 p-2 bg-secondary">Documents</h5>
                  <div class="p-2">
                    <file-display-grid (delete)="deleteDocument($event)" [editable]="editing"
                                       [files]="product?.documents"></file-display-grid>
                    <p *ngIf="product.documents?.length==0" class="text-muted text-center">
                      <em>-- No Documents --</em>
                    </p>
                    <a *ngIf="editing" class="btn btn-badge bg-success has-icon float-right" title="Add Document"
                       (click)="openDocumentWindow()"><i class="fas fa-plus mt-2"></i></a>
                  </div>
                </div>
              </div>
            </div>
          </mat-step>
          <mat-step label="Materials">
            <div class="mb-3 mt-1 mr-5 p-2 bg-light">
              <button class="btn btn-sm btn-default ml-2" matStepperPrevious>Back</button>
              <button class="btn btn-sm btn-default mr-2 float-right" matStepperNext>Next</button>
            </div>
            <div>
              <div class="row mb-3" [ngClass]="{materialAdded: product.material && product.material.isFromCompare}">
                <div class="col-12 d-flex pb-2" *ngIf="progressService.shouldShowIndicators()">
                  <div class="ml-auto">
                    <estimating-progress-status label="Material Status" [status]="getTaskStatus('material')" (statusChange)="setTaskStatus('material', $event)" [disabled]="!editing"></estimating-progress-status>
                  </div>
                </div>
                <div class="col-12">
                  <mat-radio-group class="mb-3" *ngIf="(editing && !isRepair)" [(ngModel)]="newMaterialType"
                                   (change)="
                    materialSelect && materialSelect.resetMaterialParameters();
                    product.material = null;
                    product.materialId = null;
                  ">
                    <mat-radio-button class="mr-1" value="new">Build Material</mat-radio-button>
                    <mat-radio-button value="search">Search Existing Materials</mat-radio-button>
                  </mat-radio-group>
                  <material-select *ngIf="newMaterialType === 'new' || !editing || isRepair" [disabled]="!editing || isRepair" [material]="product.material" #materialSelect>
                  </material-select>
                  <material-simple-search [class.d-none]="newMaterialType !== 'search' || !editing || isRepair" [(ngModel)]="product.material" (ngModelChange)="product.materialId = ($event && $event.materialId)" name="materialSearch"></material-simple-search>
                </div>
              </div>
              <div class="row" *ngIf="product.material || (materialSelect && materialSelect.materialFullyInput)">
                <div class="col-3 p-0 bg-light">
                  <h5 class="w-100 p-2 bg-secondary text-center border-bottom border-secondary">Pricing</h5>
                  <div class="p-3">
                    <div class="form-group">
                      <label>Units</label>
                      <div class="text-center">
                        <mat-button-toggle-group name="useMetric" [disabled]="!editing || isRepair" [(ngModel)]="product.useMetric"
                                                 aria-label="Units">
                          <mat-button-toggle class="btn btn-sm" [value]="false">Imperial</mat-button-toggle>
                          <mat-button-toggle class="btn btn-sm" [value]="true">Metric</mat-button-toggle>
                        </mat-button-toggle-group>
                      </div>
                    </div>
                    <div class="form-group">
                      <label>Material Cost</label>
                      <div class="text-center">
                        <mat-radio-group [disabled]="!editing || isRepair" [(ngModel)]="product.materialCostIsLotBased"
                                         aria-label="Select an option">
                          <mat-radio-button [value]="true">Lot Based</mat-radio-button>
                          <mat-radio-button [value]="false" class="ml-2">By Dimension</mat-radio-button>
                        </mat-radio-group>
                      </div>
                    </div>
                    <div class="form-group">
                      <label>Material Markup</label>
                      <div class="text-center">
                        <div class="input-group">
                          <input type="number" appStaticEdit [editable]="(editing && !isRepair)" step="0.5"
                                 [(ngModel)]="product.materialMarkup" class="form-control form-inline text-right">
                          <div class="input-group-append">
                            <span class="input-group-text" [ngClass]="{'disabled':!editing || isRepair}"><strong>%</strong></span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="form-group">
                      <label># Test Pieces</label>
                      <div class="text-center">
                        <input type="number" appStaticEdit [editable]="(editing && !isRepair)" [(ngModel)]="product.numberTestPeices"
                               class="form-control form-inline">
                      </div>
                    </div>
                    <div class="form-group">
                      <div *ngIf="isPurchasingUser()">
                        <existing-quote-summary [partNumber]="product.partNumber" [editable]="(editing && !isRepair)" (delete)="deleteQuote($event, 'material')" (edit)="showQuoteDetail($event.materialBidId)" [orderId]="orderId" [filterId]="product?.materialId" (selectionChanged)="saveMaterialQuoteSelection($event)" (copy)="copyOldQuote($event)"
                                                [(selectionId)]="product.selectedMaterialQuote"></existing-quote-summary>
                      </div>

                      <div class="text-right" *ngIf="(editing && !isRepair)">
                        <button class="btn btn-sm btn-primary" [disabled]="!product.material"
                                (click)="showQuickQuote(product.material,null,null)">
                          <i class="fas fa-fw fa-lg fa-quote-right"></i>
                          Quick Quote
                        </button>
                      </div>
                    </div>
                    <div class="form-group" *ngIf="product.material">
                      <label>Material Price History</label>
                      <price-history-chart [materialId]="product?.materialId"></price-history-chart>
                    </div>
                  </div>
                </div>
                <div class="col-6">
                  <div class="form-group row">
                    <div class="col-4">
                      <label>
                        Material Lot Cost
                        <button class="btn btn-link py-0" *ngIf="isPurchasingUser() && product?.selectedMaterialQuote!=null" (click)="showQuoteDetail(product?.selectedMaterialQuote)">
                          View Quote
                        </button>
                      </label>
                      <div class="text-center">
                        <div class="input-group input-group-sm">
                          <div class="input-group-prepend">
                            <span class="input-group-text disabled"><strong>$</strong></span>
                          </div>
                          <input type="number" step="0.01" appStaticEdit [editable]="false"
                                 [(ngModel)]="product.materialLotCost" class="form-control form-control-sm form-inline">
                        </div>
                      </div>
                    </div>
                    <div class="col-4">
                      <label>
                        Blanks per Unit
                        <button mat-icon-button *ngIf="editing && !product.partsPerMaterialOverride" (click)="togglePartsPerMaterialOverride($event)" matTooltip="Override" matTooltipPosition="after">
                          <mat-icon>edit</mat-icon>
                        </button>
                        <button mat-icon-button *ngIf="editing && product.partsPerMaterialOverride" (click)="togglePartsPerMaterialOverride($event)" matTooltip="Remove Override" matTooltipPosition="after">
                          <mat-icon>close</mat-icon>
                        </button>
                      </label>
                      <input type="number" min="1" step="1" appStaticEdit *ngIf="editing && product.partsPerMaterialOverride" [editable]="editing"
                             [(ngModel)]="product.partsPerMaterial" class="form-control form-control-sm form-inline">
                      <input type="number" step="0.01" appStaticEdit *ngIf="!editing || !product.partsPerMaterialOverride" [editable]="false"
                             [(ngModel)]="product.partsPerMaterialOverride ? product.partsPerMaterial : piecesFromLot" class="form-control form-control-sm form-inline">
                      <div class="small text-muted ml-1" [class.invisible]="product.partsPerMaterialOverride">
                        (calculated from blank dimensions)
                      </div>
                    </div>
                    <div class="col-4">
                      <label>
                        Total Material Cost <small>(for primary qty)</small>
                      </label>
                      <div class="text-center">
                        <div class="input-group input-group-sm">
                          <div class="input-group-prepend">
                            <span class="input-group-text disabled"><strong>$</strong></span>
                          </div>
                          <input type="text" appStaticEdit [editable]="false"
                                 [ngModel]="(materialCost | number:'1.2-2')" class="form-control form-control-sm form-inline">
                        </div>
                      </div>
                      <div class="small text-muted ml-1">
                        <div *ngIf="this.product.materialCostIsLotBased">
                          (<b>{{ product.materialLotCost * materialMarkupFactor | currency }}</b> per every <b>{{ piecesFromLotAfterOverride }} parts</b>)
                        </div>
                        <div *ngIf="!this.product.materialCostIsLotBased">
                          (<b>{{ (product.materialLotCost / piecesFromLotAfterOverride ) * materialMarkupFactor | currency }}</b> per part)
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="form-group">
                    <label>Raw Weight</label>
                    <p class="small text-muted" *ngIf="rawWeight!=null">
                      {{rawWeight | number:'1.2-2'}} {{product.useMetric?'kg':'lb'}}
                    </p>
                    <p class="small text-muted" *ngIf="rawWeight==null">
                      -- Not Enough Data --
                    </p>
                  </div>
                  <!-- dimensions -->
                  <div>
                    <div class="d-flex align-items-center" *ngFor="let mtd of product.material.materialType.materialTypeDimensions">
                      <div class="form-group mb-0 flex-grow-1">
                        <label>Blank {{ mtd.dimensionType.label }}</label>
                        <div class="text-center">
                          <div class="input-group input-group-sm">
                            <input type="number" min="0.001" step="0.001" appStaticEdit [editable]="(editing && !isRepair)"
                                   [ngModel]="getBlankDimension(mtd.materialTypeDimensionId)"
                                   (ngModelChange)="setBlankDimension(mtd.materialTypeDimensionId, $event)"
                                   class="form-control form-control-sm form-inline text-right">
                            <div class="input-group-append">
                              <span class="input-group-text" [ngClass]="{'disabled':!editing || isRepair}">
                                {{ mtd.dimensionUnit.abbreviation }}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <i class="fas fa-arrow-right text-muted mx-2 align-self-end mb-2"></i>
                      <div class="form-group mb-0 flex-grow-1">
                        <label>Finished {{ mtd.dimensionType.label }}</label>
                        <div class="text-center">
                          <div class="input-group input-group-sm">
                            <input type="number" min="0.001" step="0.001" appStaticEdit [editable]="(editing && !isRepair)"
                                   [ngModel]="getFinishedDimension(mtd.materialTypeDimensionId)"
                                   [max]="getBlankDimension(mtd.materialTypeDimensionId)"
                                   (ngModelChange)="setFinishedDimension(mtd.materialTypeDimensionId, $event)"
                                   class="form-control form-control-sm form-inline text-right">
                            <div class="input-group-append">
                              <span class="input-group-text" [ngClass]="{'disabled':!editing || isRepair}">
                                {{ mtd.dimensionUnit.abbreviation }}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="d-flex align-items-center">
                      <div class="form-group mb-0 flex-grow-1">
                        <label>Blank Weight</label>
                        <p class="text-muted" *ngIf="blankWeight!=null">
                          {{blankWeight | number:'1.2-6'}} {{product.useMetric?'kg':'lb'}}
                        </p>
                        <p class="text-muted" *ngIf="blankWeight==null">
                          -- Not Enough Data --
                        </p>
                      </div>
                      <i class="fas fa-arrow-right text-muted mx-2 align-self-end mb-4"></i>
                      <div class="form-group mb-0 flex-grow-1">
                        <label>Finished Weight</label>
                        <p class="text-muted" *ngIf="finishedWeight!=null">
                          {{finishedWeight | number:'1.2-6'}} {{product.useMetric?'kg':'lb'}}
                        </p>
                        <p class="text-muted" *ngIf="finishedWeight==null">
                          -- Not Enough Data --
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-3 p-0 bg-light">
                  <h5 class="w-100 p-2 bg-secondary text-center border-bottom border-secondary">Difficulty</h5>
                  <button class="btn btn-sm btn-outline-dark reset-complexity-btn" (click)="resetComplexity()"
                          *ngIf="(editing && !isRepair)" [class.invisible]="!product.complexity">
                    <i class="fas fa-undo"></i>
                    Reset
                  </button>
                  <div class="p-3 text-center">
                    <div class="position-relative">
                      <span class="small text-muted b-0">Easy</span>
                      <span class="small text-muted t-0">Hard</span>
                      <mat-slider tickInterval="1" min="0" max="9" step="1" vertical [disabled]="!editing || isRepair"
                                  [ngClass]="'level-'+complexity" [(ngModel)]="complexity"></mat-slider>
                    </div>
                    <div class="small text-muted">
                      {{materialRemoved | number:'1.0-2'}} % Material Removed
                    </div>
                    <div class="text-muted small text-left pt-3">
                      <div class="font-weight-bold">Average Removal Time</div>
                      <div class="pl-2 pt-1">
                        Lathe: <span class="font-weight-bold">XX min</span> (YY in<sup>3</sup>/min)
                      </div>
                      <div class="pl-2">
                        Router: <span class="font-weight-bold">XX min</span> (YY in<sup>3</sup>/min)
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </mat-step>
          <mat-step label="Workflow" #workflowStep>
            <div class="mb-3 mt-1 mr-5 p-2 bg-light">
              <button class="btn btn-sm btn-default ml-2" matStepperPrevious>Back</button>
              <button class="btn btn-sm btn-default mr-2 float-right" matStepperNext>Next</button>
            </div>
            <div class="row">
              <div class="col-12 d-flex pb-2" *ngIf="progressService.shouldShowIndicators()">
                <div class="ml-auto">
                  <estimating-progress-status label="Workflow Status" [status]="getTaskStatus('workflow')" (statusChange)="setTaskStatus('workflow', $event)" [disabled]="!editing"></estimating-progress-status>
                </div>
              </div>
              <div class="col-3 border-right border-secondary min-vh-80 p-0 bg-light">
                <div class="text-center border-bottom border-dark">
                  <h5 class="bg-secondary m-0 p-2">Step Detail</h5>
                </div>
                <div class="mt-1" *ngIf="selectedWorkflowStep && isRepair && canEditWorkflowStep">
                  <h3 class="small font-weight-bold text-center">Repair Step Type</h3>
                  <mat-radio-group [(ngModel)]="selectedWorkflowStep.stepType">
                    <div class="px-2 py-1">
                      <mat-radio-button [value]="1">
                        One-time
                        <i class="fas fa-circle-question ml-1" matTooltipPosition="after" matTooltip="One-time repair step, will not be added to product workflow in future orders."></i>
                      </mat-radio-button>
                    </div>
                    <div class="px-2 py-1">
                      <mat-radio-button [value]="2">
                        Amend Workflow
                        <i class="fas fa-circle-question ml-1" matTooltipPosition="after" matTooltip="Step will be permanently added to product's workflow after approval."></i>
                      </mat-radio-button>
                    </div>
                  </mat-radio-group>
                </div>
                <div class="p-2">
                  <p *ngIf="selectedWorkflowStep==null" class="pt-5 text-muted small text-center font-italic">
                    -- Select a Workflow Action --
                  </p>
                  <div *ngIf="selectedWorkflowStep!=null">
                    <h6 class="text-center m-3">
                      {{getStation(selectedWorkflowStep?.stationId)?.name}}
                    </h6>
                    <div class="form-group" *ngIf="getStation(selectedWorkflowStep?.stationId)?.isOutsourceStep">
                      <specifications-input [editable]="canEditWorkflowStep" [stationId]="selectedWorkflowStep.stationId"
                                               [(specifications)]="selectedWorkflowStep.outsideProcessSpecifications" (specificationsChange)="setWorkflowDirty()"
                                               (createSpec)="onCreateSpec($event)"
                                               ></specifications-input>
                    </div>
                    <div class="form-group">
                      <div class="text-center">
                        <div class="col">
                          <mat-checkbox [disabled]="!canEditWorkflowStep" [(ngModel)]="selectedWorkflowStep.isStandalone" [ngClass]="{'text-danger':selectedWorkflowStep?.compareTo_isStandalone!=null}">
                            Standalone Step
                          </mat-checkbox>
                          <span class="text-danger" *ngIf="selectedWorkflowStep?.compareTo_isStandalone!=null">&nbsp;(<i class="fas fa-fw" [ngClass]="{'fa-square':!(selectedWorkflowStep?.compareTo_isStandalone),'fa-square-check':(selectedWorkflowStep?.compareTo_isStandalone)}"></i>)</span>
                        </div>
                      </div>
                    </div>
                    <div class="form-group">
                      <div class="text-center">
                        <mat-radio-group (change)="setWorkflowDirty()"
                                         [disabled]="!canEditWorkflowStep || selectedWorkflowStep.isStandalone"
                                         [(ngModel)]="selectedWorkflowStep.runIsPerPart" aria-label="Select an option">
                          <mat-radio-button [value]="true" [ngClass]="{'text-danger':selectedWorkflowStep?.compareTo_runIsPerPart===true}">Per Part</mat-radio-button>
                          <mat-radio-button [value]="false" [ngClass]="{'text-danger':selectedWorkflowStep?.compareTo_runIsPerPart===false}" class="ml-2">Per Job</mat-radio-button>
                        </mat-radio-group>
                      </div>
                    </div>
                    <div>
                      <mat-checkbox [disabled]="!canEditWorkflowStep" [(ngModel)]="selectedWorkflowStep.hasSetup" [ngClass]="{'text-danger':selectedWorkflowStep?.compareTo_hasSetup!=null}">
                        Needs Setup
                      </mat-checkbox>
                      <span class="text-danger" *ngIf="selectedWorkflowStep?.compareTo_hasSetup!=null">&nbsp;(<i class="fas fa-fw" [ngClass]="{'fa-square':!(selectedWorkflowStep?.compareTo_hasSetup),'fa-square-check':(selectedWorkflowStep?.compareTo_hasSetup)}"></i>)</span>
                      <div class="form-group" style="min-height: 80px;" *ngIf="selectedWorkflowStep.hasSetup">
                        <label [ngClass]="{'text-danger':selectedWorkflowStep?.compareTo_setupTime}">Standard Setup Time</label>
                        <div class="input-group input-group-sm">
                          <span *ngIf="selectedWorkflowStep?.compareTo_setupTime" class="small text-danger compareTo">{{selectedWorkflowStep.compareTo_setupTime}}</span>
                          <input min="0.0" step="0.01" [(ngModel)]="selectedWorkflowStep.setupTime" placeholder="Station Setup Time"
                                 type="number" class="text-right form-control form-control-sm form-inline" appStaticEdit
                                 [editable]="true" [disabled]="!selectedWorkflowStep.hasSetup || !canEditWorkflowStep" />
                          <div class="input-group-append">
                            <span class="input-group-text" [ngClass]="{'disabled':!canEditWorkflowStep}">
                              hrs
                            </span>
                          </div>
                        </div>
                      </div>
                      <div class="form-group" style="min-height: 80px;" *ngIf="selectedWorkflowStep.hasSetup && selectedWorkflowStep.runIsPerPart">
                        <label>Per-Piece Setup Time</label>
                        <div class="input-group input-group-sm">
                          <input min="0.0" step="1" [(ngModel)]="selectedWorkflowStep.perPieceSetupTime" placeholder="Per-Piece Setup Time"
                                 type="number" class="text-right form-control form-control-sm form-inline" appStaticEdit
                                 [editable]="true" [disabled]="!selectedWorkflowStep.hasSetup || !canEditWorkflowStep" />
                          <div class="input-group-append">
                            <span class="input-group-text" [ngClass]="{'disabled':!canEditWorkflowStep}">
                              min
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="form-group"
                         *ngIf="!(getStation(selectedWorkflowStep?.stationId)?.isOutsourceStep) && !getStation(selectedWorkflowStep?.stationId)?.isPainting">
                      <label [ngClass]="{'text-danger':selectedWorkflowStep?.compareTo_runTime}">Run Time</label>
                      <div class="input-group input-group-sm">
                        <span *ngIf="selectedWorkflowStep?.compareTo_runTime" class="small text-danger compareTo">{{selectedWorkflowStep.compareTo_runTime}}</span>
                        <input type="number" (change)="setWorkflowDirty()" appStaticEdit [editable]="canEditWorkflowStep"
                               [(ngModel)]="selectedWorkflowStep.runTime"
                               class="form-control form-control-sm form-inline text-right">
                        <div class="input-group-append">
                          <span class="input-group-text" [ngClass]="{'disabled':!canEditWorkflowStep}">
                            {{selectedWorkflowStep?.runIsPerPart ? 'min':'hr'}}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div class="form-group" *ngIf="getStation(selectedWorkflowStep?.stationId)?.isOutsourceStep">
                      <label [ngClass]="{'text-danger':selectedWorkflowStep?.compareTo_outsourceMarkup}">Outsource Markup</label>
                      <div class="input-group input-group-sm">
                        <span *ngIf="selectedWorkflowStep?.compareTo_outsourceMarkup" class="small text-danger compareTo">{{selectedWorkflowStep.compareTo_outsourceMarkup}}</span>
                        <input type="number" (change)="setWorkflowDirty()" appStaticEdit [editable]="canEditWorkflowStep"
                               [(ngModel)]="selectedWorkflowStep.outsourceMarkup"
                               class="form-control form-control-sm form-inline text-right">
                        <div class="input-group-append">
                          <span class="input-group-text" [ngClass]="{'disabled':!canEditWorkflowStep}">
                            %
                          </span>
                        </div>
                      </div>
                    </div>
                    <div class="form-group">
                      <ng-container *ngIf="!getStation(selectedWorkflowStep?.stationId)?.isPainting">
                        <label [ngClass]="{'text-danger':selectedWorkflowStep?.compareTo_runPrice}">
                          {{getStation(selectedWorkflowStep?.stationId)?.isOutsourceStep ? '' : 'Run '}}Rate
                          <button class="btn btn-link"
                                  *ngIf="isPurchasingUser() && getStation(selectedWorkflowStep?.stationId)?.isOutsourceStep && selectedWorkflowStep.selectedQuote != null"
                                  (click)="showQuoteDetail(selectedWorkflowStep.selectedQuote)">
                            View Quote
                          </button>
                        </label>
                        <div class="input-group input-group-sm">
                          <div class="input-group-prepend">
                            <span class="input-group-text"
                                  [ngClass]="{'disabled':(!canEditWorkflowStep || getStation(selectedWorkflowStep?.stationId)?.isOutsourceStep)}">
                              $
                            </span>
                          </div>
                          <input type="number" (change)="setWorkflowDirty()" appStaticEdit
                                 [editable]="canEditWorkflowStep && !(getStation(selectedWorkflowStep?.stationId)?.isOutsourceStep)"
                                 [(ngModel)]="selectedWorkflowStep.runPrice"
                                 class="form-control form-control-sm form-inline">
                          <div class="input-group-append"
                               *ngIf="!(getStation(selectedWorkflowStep?.stationId)?.isOutsourceStep)">
                            <span *ngIf="selectedWorkflowStep?.compareTo_runPrice" class="small text-danger compareTo before">{{selectedWorkflowStep.compareTo_runPrice}}</span>
                            <span class="input-group-text" [ngClass]="{'disabled':!canEditWorkflowStep}">
                              / hr
                            </span>
                          </div>
                        </div>
                        <div *ngIf="getStation(selectedWorkflowStep?.stationId)?.isOutsourceStep">
                          <div *ngIf="isPurchasingUser()">
                            <existing-quote-summary [partNumber]="product.partNumber" [editable]="canEditWorkflowStep" (delete)="deleteQuote($event, 'workflow')" (edit)="showQuoteDetail($event.materialBidId)" (selectionChanged)="saveWorkflowQuoteSelection($event)" (copy)="copyOldQuote($event)"
                                                    [orderId]="orderId" [filterId]="selectedWorkflowStep?.stationId"
                                                    [(selectionId)]="selectedWorkflowStep.selectedQuote"></existing-quote-summary>
                          </div>

                          <div class="text-right" *ngIf="canEditWorkflowStep">
                            <button class="btn btn-sm btn-primary"
                                    (click)="showQuickQuote(null,selectedWorkflowStep,null)">
                              <i class="fas fa-fw fa-lg fa-quote-right"></i>
                              Quick Quote
                            </button>
                          </div>
                          <div>
                            <label>Process Price History</label>
                            <price-history-chart [stationId]="selectedWorkflowStep?.stationId"></price-history-chart>
                          </div>
                        </div>
                      </ng-container>
                      <div class="pt-2" *ngIf="getStation(selectedWorkflowStep?.stationId)?.isPainting">
                        <div class="form-group">
                          <label [ngClass]="{'text-danger':selectedWorkflowStep?.compareTo_paint}">Paint Selection</label>
                          <div class="container-fluid row p-0 m-0">
                            <div class="col-2">
                              <span class="d-inline-block paintSwatch"
                                    [style.background]="selectedWorkflowStep.paint?.sampleColor"></span>
                            </div>
                            <div class="col-10 p-0">
                              <paint-select [editable]="canEditWorkflowStep" (addPaint)="addPaint($event)"
                                            [paint]="selectedWorkflowStep.paint" (paintChange)="assignPaint($event)"></paint-select>
                            </div>
                          </div>
                          <div class="container-fluid row p-0 m-0 border-top border-bottom border-left border-right border-danger pt-2" *ngIf="selectedWorkflowStep?.compareTo_paint">
                            <div class="col-2">
                              <span class="d-inline-block paintSwatch border-danger"
                                    [style.background]="selectedWorkflowStep.compareTo_paint?.sampleColor"></span>
                            </div>
                            <div class="col-10 p-0">
                              {{selectedWorkflowStep.compareTo_paint?.name}}
                            </div>
                          </div>
                        </div>
                        <div class="form-group">
                          <label [ngClass]="{'text-danger':selectedWorkflowStep?.compareTo_paintedArea}">Painted Area</label>
                          <div class="input-group input-group-sm">
                            <span *ngIf="selectedWorkflowStep?.compareTo_paintedArea" class="small text-danger compareTo">{{selectedWorkflowStep.compareTo_paintedArea}}</span>
                            <input type="number" (change)="setWorkflowDirty()" appStaticEdit [editable]="canEditWorkflowStep"
                                   [(ngModel)]="selectedWorkflowStep.paintedArea"
                                   class="form-control form-control-sm form-inline text-right">
                            <div class="input-group-append">
                              <span class="input-group-text" [ngClass]="{'disabled':!canEditWorkflowStep}">
                                in<sup>2</sup>
                              </span>
                            </div>
                          </div>
                        </div>
                        <div class="form-group">
                          <label [ngClass]="{'text-danger':selectedWorkflowStep?.compareTo_paintCoats}">Number of Coats</label>
                          <span *ngIf="selectedWorkflowStep?.compareTo_paintCoats" class="small text-danger compareTo">{{selectedWorkflowStep.compareTo_paintCoats}}</span>
                          <input type="number" (change)="setWorkflowDirty()" appStaticEdit [editable]="canEditWorkflowStep"
                                 [(ngModel)]="selectedWorkflowStep.paintCoats"
                                 class="form-control form-control-sm form-inline text-right">
                        </div>
                        <div class="form-group">
                          <label [ngClass]="{'text-danger':selectedWorkflowStep?.compareTo_runPrice}">Labor Cost</label>
                          <div class="input-group input-group-sm">
                            <div class="input-group-prepend">
                              <span class="input-group-text" [ngClass]="{'disabled':!canEditWorkflowStep}">
                                $
                              </span>
                            </div>
                            <span *ngIf="selectedWorkflowStep?.compareTo_runPrice" class="small text-danger compareTo pl-4 ml-2">{{selectedWorkflowStep.runPrice}}</span>
                            <input type="number" (change)="setWorkflowDirty()" appStaticEdit [editable]="canEditWorkflowStep"
                                   [(ngModel)]="selectedWorkflowStep.runPrice"
                                   class="form-control form-control-sm form-inline text-right">
                            <div class="input-group-append">
                              <span class="input-group-text" [ngClass]="{'disabled':!canEditWorkflowStep}">
                                / in<sup>2</sup>
                              </span>
                            </div>
                          </div>
                        </div>
                        <div class="form-group">
                          <label [ngClass]="{'text-danger':selectedWorkflowStep?.compareTo_paintCost}">Paint Cost</label>
                          <div class="input-group input-group-sm">
                            <div class="input-group-prepend">
                              <span class="input-group-text" [ngClass]="{'disabled':!canEditWorkflowStep}">
                                $
                              </span>
                            </div>
                            <span *ngIf="selectedWorkflowStep?.compareTo_paintCost" class="small text-danger compareTo pl-4 ml-2">{{selectedWorkflowStep.paintCost}}</span>
                            <input type="number" (change)="setWorkflowDirty()" appStaticEdit [editable]="canEditWorkflowStep"
                                   [(ngModel)]="selectedWorkflowStep.paintCost"
                                   class="form-control form-control-sm form-inline text-right">
                            <div class="input-group-append">
                              <span class="input-group-text" [ngClass]="{'disabled':!canEditWorkflowStep}">
                                / in<sup>2</sup>
                              </span>
                            </div>
                          </div>
                        </div>
                        <div class="form-group">
                          <label [ngClass]="{'text-danger':selectedWorkflowStep?.compareTo_paintMinPrice}">Minimum Charge</label>
                          <div class="input-group input-group-sm">
                            <div class="input-group-prepend">
                              <span class="input-group-text" [ngClass]="{'disabled':!canEditWorkflowStep}">
                                $
                              </span>
                            </div>
                            <input type="number" (change)="setWorkflowDirty()" step="0.01" appStaticEdit [editable]="canEditWorkflowStep"
                                   [(ngModel)]="selectedWorkflowStep.paintMinPrice"
                                   class="form-control form-control-sm form-inline">
                            <span *ngIf="selectedWorkflowStep?.compareTo_paintMinPrice" class="small text-danger compareTo before">{{selectedWorkflowStep.compareTo_paintMinPrice}}</span>
                          </div>
                        </div>
                      </div>
                      <!--
                      <div class="form-group" *ngIf="getStation(selectedWorkflowStep?.stationId)?.isOutsourceStep">
                        <div class="text-right mr-2 mt-1">
                          <mat-radio-group (change)="setWorkflowDirty()" [disabled]="!canEditWorkflowStep" [(ngModel)]="selectedWorkflowStep.outsourceIsFinal" aria-label="Select an option">
                            <mat-radio-button [value]="true">Quoted</mat-radio-button>
                            <mat-radio-button [value]="false" class="ml-2">Tentative</mat-radio-button>
                          </mat-radio-group>
                        </div>
                      </div>
                      -->
                      <div class="bg-light mt-2" *ngIf="shippingAssignments!=null && (getStation(selectedWorkflowStep?.stationId)?.isOutsourceStep || getStation(selectedWorkflowStep?.stationId)?.isShipping)">
                        <div class="w-100 p-1 bg-secondary text-center text-secondary">Shipping</div>
                        <div class="p-1">
                          <h6 class="mt-2 font-weight-bold text-secondary">Shipping Ticket</h6>
                          <div *ngIf="selectedWorkflowStep?.outgoingShippingTicket">
                            <a class="text-primary text-underline" [href]="'/shipping/ticket/' + selectedWorkflowStep?.outgoingShippingTicket?.shippingTicketId" target="_blank">{{ getShippingTicketNumber(selectedWorkflowStep?.outgoingShippingTicket) }} <i class="fas fa-fw fa-lg fa-external-link-alt text-underline pl-2"></i></a>
                            <div class="my-1" *ngIf="getStation(selectedWorkflowStep?.stationId)?.isOutsourceStep">
                              <div *ngIf="selectedWorkflowStep?.outgoingShippingTicket?.purchaseOrder">
                                <span class="badge badge-small mr-1">Vendor</span>
                                {{ selectedWorkflowStep?.outgoingShippingTicket?.purchaseOrder?.vendor?.name }}
                              </div>
                              <div *ngIf="!selectedWorkflowStep?.outgoingShippingTicket?.purchaseOrder">
                                <span class="text-muted" *ngIf="!selectedWorkflowStep?.selectedQuote">-- No Quote Accepted --</span>
                                <span class="text-muted" *ngIf="selectedWorkflowStep?.selectedQuote">-- No Purchase Order Issued --</span>
                              </div>
                            </div>
                            <div>
                              <label>Shipping From</label>
                              <div>
                                <building-dropdown [editable]="canEditWorkflowStep"
                                                   [selectedBuildingId]="getShippingAssignment(selectedWorkflowStep)?.sendingBuildingId"
                                                   (selectedBuildingChange)="setShippingAssignmentOutgoing(selectedWorkflowStep, $event)">
                                </building-dropdown>
                              </div>
                            </div>
                            <div>
                              <label>Shipping Date</label>
                              <div>
                                <div class="small text-muted" *ngIf="!getShippingAssignment(selectedWorkflowStep) || !getShippingAssignment(selectedWorkflowStep)?.sendingBuildingId">Select a building first.</div>
                                <date-picker *ngIf="getShippingAssignment(selectedWorkflowStep) && getShippingAssignment(selectedWorkflowStep).sendingBuildingId" [placeholder]="'Departure Date'" [editable]="canEditWorkflowStep && !!getShippingAssignment(selectedWorkflowStep)" [(date)]="getShippingAssignment(selectedWorkflowStep).sendingDateTime"></date-picker>
                              </div>
                            </div>
                            <div *ngIf="!getStation(selectedWorkflowStep?.stationId)?.isOutsourceStep">
                              <label>Shipping To</label>
                              <div>
                                <building-dropdown [editable]="canEditWorkflowStep"
                                                   [selectedBuildingId]="getShippingAssignment(selectedWorkflowStep)?.receivingBuildingId"
                                                   (selectedBuildingChange)="setShippingAssignmentIncoming(selectedWorkflowStep, $event)">
                                </building-dropdown>
                              </div>
                            </div>
                            <div *ngIf="!getStation(selectedWorkflowStep?.stationId)?.isOutsourceStep">
                              <label>Arrival Date</label>
                              <div>
                                <div class="small text-muted" *ngIf="!getShippingAssignment(selectedWorkflowStep) || !getShippingAssignment(selectedWorkflowStep)?.receivingBuildingId">Select a building first.</div>
                                <date-picker *ngIf="getShippingAssignment(selectedWorkflowStep) && getShippingAssignment(selectedWorkflowStep).receivingBuildingId" [placeholder]="'Arrival Date'" [editable]="canEditWorkflowStep && !!getShippingAssignment(selectedWorkflowStep)" [(date)]="getShippingAssignment(selectedWorkflowStep).receivingDateTime"></date-picker>
                              </div>
                            </div>
                          </div>
                          <div *ngIf="!selectedWorkflowStep?.outgoingShippingTicket" class="text-muted">
                            -- No Shipping Ticket --
                          </div>
                          <div class="mt-2" *ngIf="getStation(selectedWorkflowStep?.stationId)?.isOutsourceStep">
                            <h6 class="font-weight-bold text-secondary">Return Shipping Ticket</h6>
                            <div *ngIf="selectedWorkflowStep?.incomingShippingTicket">
                              <a class="text-primary text-underline" [href]="'/shipping/ticket/' + selectedWorkflowStep?.incomingShippingTicket?.shippingTicketId" target="_blank">{{ getShippingTicketNumber(selectedWorkflowStep?.incomingShippingTicket) }} <i class="fas fa-fw fa-lg fa-external-link-alt text-underline pl-2"></i></a>

                              <div>
                                <label>Shipping To</label>
                                <div>
                                  <building-dropdown [editable]="canEditWorkflowStep"
                                                     [selectedBuildingId]="getShippingAssignment(selectedWorkflowStep)?.receivingBuildingId"
                                                     (selectedBuildingChange)="setShippingAssignmentIncoming(selectedWorkflowStep, $event)">
                                  </building-dropdown>
                                </div>
                              </div>
                              <div>
                                <label>Arrival Date</label>
                                <div>
                                  <div class="small text-muted" *ngIf="!getShippingAssignment(selectedWorkflowStep) || !getShippingAssignment(selectedWorkflowStep)?.receivingBuildingId">Select a building first.</div>
                                  <date-picker *ngIf="getShippingAssignment(selectedWorkflowStep) && getShippingAssignment(selectedWorkflowStep).receivingBuildingId" [placeholder]="'Arrival Date'" [editable]="canEditWorkflowStep && !!getShippingAssignment(selectedWorkflowStep)" [(date)]="getShippingAssignment(selectedWorkflowStep).receivingDateTime"></date-picker>
                                </div>
                              </div>
                            </div>
                            <div *ngIf="!selectedWorkflowStep?.outgoingShippingTicket" class="text-muted">
                              -- No Shipping Ticket --
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <ng-container *ngIf="!(getStation(selectedWorkflowStep?.stationId)?.isOutsourceStep)">
                      <div class="w-100 p-1 bg-secondary text-center text-secondary">
                        <mat-checkbox [disabled]="!canEditWorkflowStep" [(ngModel)]="selectedWorkflowStep.hasProgramming" (ngModelChange)="onToggleProgramming($event)" class="d-inline-block mr-1"></mat-checkbox>
                        <h5 class="m-0 d-inline-block">Programming</h5>
                      </div>
                      <div *ngIf="selectedWorkflowStep.hasProgramming">
                        <div class="form-group">
                          <label>Programming Run Time</label>
                          <div class="input-group input-group-sm">
                            <input type="number" (change)="setWorkflowDirty()" appStaticEdit [editable]="canEditWorkflowStep"
                                  [(ngModel)]="selectedWorkflowStep.programmingTime"
                                  class="form-control form-control-sm form-inline text-right">
                            <div class="input-group-append">
                              <span class="input-group-text" [ngClass]="{'disabled':!canEditWorkflowStep}">
                                hr
                              </span>
                            </div>
                          </div>
                        </div>
                        <div class="form-group">
                          <label>Programming Run Rate</label>
                          <div class="input-group input-group-sm">
                            <div class="input-group-prepend">
                              <span class="input-group-text"
                                    [ngClass]="{'disabled':(!canEditWorkflowStep || getStation(selectedWorkflowStep?.stationId)?.isOutsourceStep)}">
                                $
                              </span>
                            </div>
                            <input type="number" (change)="setWorkflowDirty()" appStaticEdit
                                   [editable]="canEditWorkflowStep && !(getStation(selectedWorkflowStep?.stationId)?.isOutsourceStep)"
                                   [(ngModel)]="selectedWorkflowStep.programmingRate"
                                   class="form-control form-control-sm form-inline">
                            <div class="input-group-append">
                              <span class="input-group-text" [ngClass]="{'disabled':!canEditWorkflowStep}">
                                / hr
                              </span>
                            </div>
                          </div>
                        </div>
                        <div class="font-weight-bold small text-right text-muted mb-1">
                          Total Programming Cost: {{ (this.selectedWorkflowStep.programmingRate || 0) * (this.selectedWorkflowStep.programmingTime || 0) | currency }}
                        </div>
                      </div>
                      <div class="w-100 p-1 bg-secondary text-center text-secondary">
                        <h5 class="m-0">Inspection</h5>
                      </div>
                      <div class="p-1 border rounded">
                        <mat-checkbox *ngIf="!(getStation(selectedWorkflowStep?.stationId)?.isOutsourceStep)" [disabled]="!canEditWorkflowStep" [(ngModel)]="selectedWorkflowStep.inspectionIsCMM" (ngModelChange)="setWorkflowDirty()">Inspection Is CMM<mat-icon class="text-muted" matTooltipPosition="after" matTooltip="Whether inspection requires a coordinate-measuring machine. Affects both first-part and full inspections. No effect if neither inspection type is performed.">info_outline</mat-icon></mat-checkbox>
                        <div class="p-1 bg-light rounded">
                          <div class="w-100 p-1 bg-secondary text-center text-secondary">First Part Inspection
                            <mat-checkbox [disabled]="!canEditWorkflowStep" [(ngModel)]="selectedWorkflowStep.hasFirstPartInspection" (ngModelChange)="onToggleFirstPartInspection($event)" class="float-right"></mat-checkbox>
                          </div>
                          <ng-container *ngIf="selectedWorkflowStep.hasFirstPartInspection">
                            <div class="form-group">
                              <label>Inspection Run Time</label>
                              <div class="input-group input-group-sm">
                                <input type="number" (change)="setWorkflowDirty()" appStaticEdit [editable]="canEditWorkflowStep"
                                      [(ngModel)]="selectedWorkflowStep.firstPartInspectionTime"
                                      class="form-control form-control-sm form-inline text-right">
                                <div class="input-group-append">
                                  <span class="input-group-text" [ngClass]="{'disabled':!canEditWorkflowStep}">
                                    min
                                  </span>
                                </div>
                              </div>
                            </div>
                            <div class="form-group">
                              <label>Inspection Run Rate</label>
                              <div class="input-group input-group-sm">
                                <div class="input-group-prepend">
                                  <span class="input-group-text"
                                        [ngClass]="{'disabled':(!canEditWorkflowStep || getStation(selectedWorkflowStep?.stationId)?.isOutsourceStep)}">
                                    $
                                  </span>
                                </div>
                                <input type="number" (change)="setWorkflowDirty()" appStaticEdit
                                       [editable]="canEditWorkflowStep && !(getStation(selectedWorkflowStep?.stationId)?.isOutsourceStep)"
                                       [(ngModel)]="selectedWorkflowStep.firstPartInspectionRate"
                                       class="form-control form-control-sm form-inline">
                                <div class="input-group-append">
                                  <span class="input-group-text" [ngClass]="{'disabled':!canEditWorkflowStep}">
                                    / hr
                                  </span>
                                </div>
                              </div>
                            </div>
                            <div class="w-100 bg-secondary text-muted p-1 text-right small font-weight-bold">
                              Total: {{ this.getFirstPartInspectionCost() | currency }}
                            </div>
                          </ng-container>
                        </div>
                        <div class="p-1 bg-light rounded">
                          <div class="w-100 p-1 bg-secondary text-center text-secondary">Full Inspection
                            <mat-checkbox [disabled]="!canEditWorkflowStep" [(ngModel)]="selectedWorkflowStep.hasInspection" (ngModelChange)="onToggleInspection($event)" class="float-right"></mat-checkbox>
                          </div>
                          <ng-container *ngIf="selectedWorkflowStep.hasInspection">
                            <div class="d-flex align-items-center py-1">
                              <mat-slide-toggle [(ngModel)]="selectedWorkflowStep.inspectionIsBatched">
                                Batch
                              </mat-slide-toggle>
                              <div class="form-group mb-0 ml-auto" *ngIf="selectedWorkflowStep.inspectionIsBatched">
                                <div class="input-group input-group-sm">
                                  <div class="input-group-prepend">
                                    <span class="input-group-text" [ngClass]="{'disabled':!canEditWorkflowStep}">
                                      every
                                    </span>
                                  </div>
                                  <input type="number" min="1" step="1" (change)="setWorkflowDirty()" appStaticEdit [editable]="canEditWorkflowStep" style="width: 7ch !important"
                                        [(ngModel)]="selectedWorkflowStep.inspectionBatchSize"
                                        class="form-control form-control-sm form-inline text-right">
                                  <div class="input-group-append">
                                    <span class="input-group-text" [ngClass]="{'disabled':!canEditWorkflowStep}">
                                      parts
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="form-group">
                              <label>Inspection Run Time</label>
                              <div class="input-group input-group-sm">
                                <input type="number" (change)="setWorkflowDirty()" appStaticEdit [editable]="canEditWorkflowStep"
                                      [(ngModel)]="selectedWorkflowStep.inspectionTime"
                                      class="form-control form-control-sm form-inline text-right">
                                <div class="input-group-append">
                                  <span class="input-group-text" [ngClass]="{'disabled':!canEditWorkflowStep}">
                                    min
                                  </span>
                                </div>
                              </div>
                            </div>
                            <div class="form-group">
                              <label>Inspection Run Rate</label>
                              <div class="input-group input-group-sm">
                                <div class="input-group-prepend">
                                  <span class="input-group-text"
                                        [ngClass]="{'disabled':(!canEditWorkflowStep || getStation(selectedWorkflowStep?.stationId)?.isOutsourceStep)}">
                                    $
                                  </span>
                                </div>
                                <input type="number" (change)="setWorkflowDirty()" appStaticEdit
                                       [editable]="canEditWorkflowStep && !(getStation(selectedWorkflowStep?.stationId)?.isOutsourceStep)"
                                       [(ngModel)]="selectedWorkflowStep.inspectionRate"
                                       class="form-control form-control-sm form-inline">
                                <div class="input-group-append">
                                  <span class="input-group-text" [ngClass]="{'disabled':!canEditWorkflowStep}">
                                    / hr
                                  </span>
                                </div>
                              </div>
                            </div>
                            <div class="w-100 bg-secondary text-muted p-1 text-right small font-weight-bold">
                              Total: {{ this.getFullInspectionCost() | currency }}
                            </div>
                          </ng-container>
                        </div>
                        <div class="w-100 bg-secondary text-muted p-1 text-right small font-weight-bold">
                          Grand Total: {{ (this.getFirstPartInspectionCost() + this.getFullInspectionCost()) | currency }}
                        </div>
                      </div>
                    </ng-container>
                    <div class="bg-light">
                      <div class="w-100 p-1 bg-secondary text-center text-secondary">Notes</div>
                      <div class="p-1">
                        <textarea rows="6" (change)="setWorkflowDirty()" appStaticEdit [editable]="canEditWorkflowStep"
                                  class="form-control form-control-sm" [(ngModel)]="selectedWorkflowStep.description"
                                  placeholder="Station/Process Notes"></textarea>
                      </div>
                    </div>
                    <!-- TODO: Lead Time from Quote here -->
                    <div class="form-group text-right" *ngIf="canEditWorkflowStep">
                      <button class="btn btn-success" (click)="saveSelectedWorkflowStep()">
                        <i class="fas fa-save fa-lg fa-fw"></i> Save
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-4 p-0 pl-2">
                <workflow-list *ngIf="product?.workflow" (added)="workflowStepAdded($event)"
                              [product]="product"
                               (stepsChange)="orderWorkflow($event)" [sortable]="editing" [quantity]="getQuantity()" (delete)="removeWorkflowStep($event)"
                               (selected)="setStep($event)" [(steps)]="product.workflow.workflowSteps"
                               [(repairPlan)]="product.productRepairPlan"></workflow-list>
                <div class="text-right">
                  <span class="d-inline-block pr-4 small text-muted">Standalone Steps</span>
                  <span class="small font-weight-bold">{{getStandaloneTotal() | currency}}</span>
                </div>
                <div class="text-right">
                  <span class="d-inline-block pr-4 small text-muted">Labor and Outsourcing Total (per part/total, minus standalone)</span>
                  <span class="small font-weight-bold">{{getLaborTotal() | currency}} / {{getLaborTotal() * getQuantity() | currency}}</span>
                </div>
                <div class="text-center mt-2">
                  <div class="text-center">
                    <h5 class="bg-secondary m-0 p-2">Cost Comparison</h5>
                  </div>
                  <p *ngIf="product?.workflow?.workflowSteps?.length==0" class="p-4 text-muted small">
                    <i class="fas fa-info-circle text-info fa-fw fa-lg"></i>
                    Add Stations to Your Workflow to see the Cost Comparison
                  </p>
                  <chart *ngIf="product?.workflow?.workflowSteps?.length>0" width="100%"
                         height="300px" chartType="doughnut" [dataSet]="chartData" [options]="chartOptions"></chart>
                </div>
              </div>
              <div class="col-4 p-0 pl-2">
                <station-list (staged)="addToWorkflow($event)" [addButton]="true" [editing]="editing" (addNew)="openStationEditor()" #stationList></station-list>
              </div>
            </div>
          </mat-step>
          <mat-step label="Purchased Items">
            <div class="mb-3 mt-1 mr-5 p-2 bg-light">
              <button class="btn btn-sm btn-default ml-2" matStepperPrevious>Back</button>
              <button class="btn btn-sm btn-default mr-2 float-right" (click)="stepper.reset()">General Info</button>
            </div>
            <div class="row">
              <div class="col-12 d-flex pb-2" *ngIf="progressService.shouldShowIndicators()">
                <div class="ml-auto">
                  <estimating-progress-status label="Hardware Status" [status]="getTaskStatus('hardware')" (statusChange)="setTaskStatus('hardware', $event)" [disabled]="!editing"></estimating-progress-status>
                </div>
              </div>
              <div class="col-3 border-right border-secondary min-vh-80 p-0 bg-light">
                <div class="text-center border-bottom border-dark">
                  <h5 class="bg-secondary m-0 p-2">Purchased Items</h5>
                </div>
                <div class="p-2">
                  <div class="mx-1 text-muted small font-weight-bold text-center" *ngIf="isRepair && product.purchasedItems.length > 0">
                    ORIGINAL ITEMS (LOCKED)
                  </div>
                  <purchased-item-list [ngStyle]="{ opacity: isRepair ? '0.7' : '1' }" [editable]="editing && !isRepair" (added)="addPurchasedItem($event)"
                                       (deleted)="removePurchasedItem($event)" [(purchasedItems)]="product.purchasedItems"
                                       (selectionChanged)="setSelectedPurchasedItem($event)" [(selectedItem)]="this.selectedPurchasedItem"></purchased-item-list>
                  <div class="mx-1 text-success small font-weight-bold text-center" *ngIf="isRepair">
                    REPAIR ITEMS
                  </div>
                  <purchased-item-list *ngIf="isRepair" [editable]="editing" (added)="addPurchasedItem($event)"
                                       (deleted)="removePurchasedItem($event)" [(purchasedItems)]="product.productRepairPlan.purchasedItems"
                                       (selectionChanged)="setSelectedPurchasedItem($event)" [(selectedItem)]="this.selectedPurchasedItem"></purchased-item-list>
                </div>
              </div>
              <form #purchasedItemForm="ngForm" class="col-9">
                <p class="text-muted text-center font-italic p-5" *ngIf="selectedPurchasedItem==null">
                  -- Select an Item to View its Details --
                </p>
                <div *ngIf="selectedPurchasedItem!=null">
                  <div class="row form-group">
                    <div class="col-9">
                      <label>Item</label>
                      <purchased-item-select [class.d-none]="!canEditPurchasedItem" name="purchasedItem" notNullValidator
                                             [(ngModel)]="selectedPurchasedItem.purchasedItem" (ngModelChange)="onPurchasedItemChosen()">
                      </purchased-item-select>
                      <div *ngIf="!canEditPurchasedItem">{{selectedPurchasedItem.purchasedItem?.description}}</div>
                    </div>
                    <div class="col-3">
                      <label [ngClass]="{'text-danger':selectedPurchasedItem?.compareTo_quantity}">Quantity</label>
                      <span *ngIf="selectedPurchasedItem?.compareTo_quantity" class="small text-danger compareTo">&nbsp;({{selectedPurchasedItem.compareTo_quantity}})</span>
                      <input name="quantity" type="number" step="1" [minValidator]="1" notNullValidator
                             class="form-control form-control-sm" appStaticEdit [editable]="canEditPurchasedItem"
                             [(ngModel)]="selectedPurchasedItem.quantity" placeholder="Qty">
                    </div>
                  </div>
                  <div class="row form-group" *ngIf="isRepair">
                    <div class="col-12">
                      <div class="text-right">
                        <mat-radio-group name="itemRepairType" [disabled]="!canEditPurchasedItem" aria-label="Select an option"
                                         [(ngModel)]="selectedPurchasedItem.itemRepairType">
                          <mat-radio-button [value]="1">
                            One-time
                            <i class="fas fa-circle-question ml-1" matTooltipPosition="after" matTooltip="One-time repair step, will not be added to product workflow in future orders."></i>
                          </mat-radio-button>
                          <mat-radio-button [value]="2">
                            Amend Workflow
                            <i class="fas fa-circle-question ml-1" matTooltipPosition="after" matTooltip="Step will be permanently added to product's workflow after approval."></i>
                          </mat-radio-button>
                        </mat-radio-group>
                      </div>
                    </div>
                  </div>
                  <div class="row form-group">
                    <div class="col-6">
                      <label for="purchased-item-cost">
                        Item Cost (per)
                        <button class="btn btn-link" *ngIf="isPurchasingUser() && selectedPurchasedItem?.selectedQuote != null"
                                (click)="showQuoteDetail(selectedPurchasedItem?.selectedQuote)">
                          View Quote
                        </button>
                      </label>
                      <div class="input-group input-group-sm">
                        <div class="input-group-prepend">
                          <span class="input-group-text disabled">$</span>
                        </div>
                        <input name="costPer" type="number" step="0.01" class="form-control form-control-sm"
                               appStaticEdit [editable]="false" placeholder="Cost"
                               [(ngModel)]="selectedPurchasedItem.costPer">
                      </div>
                    </div>
                    <div class="col-6">
                      <label [ngClass]="{'text-danger':selectedPurchasedItem?.compareTo_markupPercent}" for="pitem-outsource-markup">Markup</label>
                      <div class="input-group input-group-sm">
                        <span *ngIf="selectedPurchasedItem?.compareTo_markupPercent" class="small text-danger compareTo">{{selectedPurchasedItem.compareTo_markupPercent}}</span>
                        <input name="markupPercent" type="text" class="form-control form-control-sm text-right"
                               appStaticEdit [editable]="canEditPurchasedItem" placeholder="Markup"
                               [(ngModel)]="selectedPurchasedItem.markupPercent">
                        <div class="input-group-append">
                          <span class="input-group-text" [ngClass]="{'disabled':!canEditPurchasedItem}">%</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row form-group">
                    <div class="col-6">
                      <div class="text-right">
                        <mat-radio-group name="isNonRecurring" [disabled]="!canEditPurchasedItem" aria-label="Select an option"
                                         [(ngModel)]="selectedPurchasedItem.isNonRecurring">
                          <mat-radio-button [value]="true" [ngClass]="{'text-danger':selectedPurchasedItem?.compareTo_isNonRecurring===true}">Non-recurring</mat-radio-button>
                          <mat-radio-button [value]="false" [ngClass]="{'text-danger':selectedPurchasedItem?.compareTo_isNonRecurring===false}" class="ml-2">Every Time</mat-radio-button>
                        </mat-radio-group>
                      </div>
                    </div>
                    <div class="col-6" *ngIf="selectedPurchasedItem.isNonRecurring">
                      <div class="text-right">
                        <mat-radio-group name="isAmortized" [disabled]="!canEditPurchasedItem" aria-label="Select an option"
                                         [(ngModel)]="selectedPurchasedItem.isAmortized">
                          <mat-radio-button [value]="true">Amortized</mat-radio-button>
                          <mat-radio-button [value]="false" class="ml-2">Non-Amortized</mat-radio-button>
                        </mat-radio-group>
                      </div>
                    </div>
                  </div>
                  <div class="row form-group" *ngIf="selectedPurchasedItem?.purchasedItem">
                    <div class="col">
                      <div *ngIf="isPurchasingUser()">
                        <existing-quote-summary [editable]="canEditPurchasedItem" (delete)="deleteQuote($event, 'purchased')" (edit)="showQuoteDetail($event.materialBidId)" [orderId]="orderId" [filterId]="selectedPurchasedItem.purchasedItemId" (selectionChanged)="savePurchasedItemQuoteSelection($event)" (copy)="copyOldQuote($event)"
                                                [(selectionId)]="selectedPurchasedItem.selectedQuote"></existing-quote-summary>
                      </div>
                    </div>
                  </div>
                  <div class="row form-group d-flex justify-content-end" *ngIf="canEditPurchasedItem">
                    <div class="col-6" *ngIf="selectedPurchasedItem?.purchasedItem && purchasedItemForm.valid">
                      <button class="btn btn-sm btn-primary"
                              (click)="showQuickQuote(null,null,selectedPurchasedItem?.purchasedItem)">
                        <i class="fas fa-fw fa-lg fa-quote-right"></i>
                        Quick Quote
                      </button>
                    </div>
                    <div class="col-6 text-right">
                      <button class="btn btn-success" type="button"
                              (click)="purchasedItemForm.valid && saveSelectedPurchasedItem()"
                              [disabled]="purchasedItemForm?.pristine || purchasedItemForm?.invalid">
                        <i class="fas fa-save fa-lg fa-fw"></i> Save
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </mat-step>
          <mat-step label="Edit History" *ngIf="!product.parentAssemblyId">
            <product-history #historyComponent [product]="product"></product-history>
          </mat-step>
        </mat-stepper>
      </mat-sidenav-content>
    </mat-sidenav-container>
  </div>
</div>


<ng-template #partHistoryDialog>
  <h2 mat-dialog-title>Part History Note</h2>
  <mat-dialog-content class="mat-typography">
    <p>For history purposes, please write a note to describe the changes you have made to the product.</p>
    <p>
      <textarea class="form-control form-control-sm" #note placeholder="Please describe changes made to the product..."></textarea>
    </p>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button class="btn btn-default btn-sm mr-3" [mat-dialog-close]="null">Skip History Entry</button>
    <button class="btn btn-default btn-sm mr-3" [mat-dialog-close]="{note: note.value}">Confirm</button>
  </mat-dialog-actions>
</ng-template>