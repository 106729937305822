import { Component, Inject, Injector, OnChanges } from "@angular/core";
import { GenericSearchComponent } from "../generic-search/generic-search.component";
import { map } from 'rxjs/operators';
import { ChangeDetectionStrategy, SimpleChanges } from "@angular/core";
import { Input } from "@angular/core";
import { Contact } from "../../resources/contact";
import { VendorService } from "../../../supplier/services/vendor.service";
import { Vendor } from "../../../supplier/resources/vendor";
import { NgForm } from "@angular/forms";

@Component({
  selector: 'vendor-contact-search',
  templateUrl: '../../../common/components/generic-search/generic-search.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VendorContactSearchComponent extends GenericSearchComponent<Contact> implements OnChanges {

  @Input() vendor: Vendor;
  @Input() addAllowed: boolean = false;
  @Input() label: string;

  public get placeholder() {
    if (this.label) return this.label;
    else return 'Contact';
  }

  constructor(private vendorSvc: VendorService, @Inject(Injector) injector: Injector) {
    super(injector);
  }

  doSearch(searchText: string) {
    return this.vendorSvc.searchContacts(this.vendor, searchText)
  }

  public canAdd(): boolean { return this.addAllowed }

  public get addItemText(): string {
     return 'New Contact';
  }

  public get noItemsText(): string {
    return 'No matching contacts found.';
  }

  public getSearchField(c: Contact): string {
    return c && c.name;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.selectedItem) {
      const change = changes.selectedItem;
      if (change.firstChange) return;
      this.searchValue = this.getSearchField(change.currentValue);
    }
  }

}