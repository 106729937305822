import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { UtilityService } from '../../common/services/utility.service';
import { MessageService } from '../../common/services/message.service';
import { catchError, map, mergeMap, tap } from 'rxjs/operators';
import { MessageType } from '../../common/resources/message'
import { ErrorHandlerService } from '../../common/services/errorHandler.service'
import { SearchResult } from '../../common/resources/search-result';
import { httpService } from '../../common/services/http.service';
import { Vendor, ApprovedVendor } from '../../supplier/resources/vendor';
import { Contact } from '../../common/resources/contact';
import { Address } from '../../common/resources/address';
import { ReportEmbed } from '../resources/report-embed';
import { ReportEmbedParams } from '../resources/report-embed-detail';

@Injectable({
  providedIn: 'root',
})
export class ReportEmbedService extends httpService {
  private apiBase: string = 'api/reportembed';
  private apiUrl: string;

  constructor(errorHandler: ErrorHandlerService, private messages: MessageService, private http: HttpClient, @Inject('BASE_URL') private baseUrl: string) {
    super(errorHandler, messages);
    this.serviceName = "ReportEmbed";

    this.apiUrl = this.baseUrl + this.apiBase;
  }

  search(searchString?: string, page?: number, sortBy?: string, sortDirection?: string): Observable<SearchResult<ReportEmbed>> {
    return this.http.get<SearchResult<ReportEmbed>>(this.apiUrl + '/search', { params: { searchText: searchString, pageIndex: (page || 0).toString(), orderBy: sortBy || "name", direction: sortDirection || "asc" } }).pipe(
      catchError(this.handleError<any>("Get Report Search Results", null))
    );
  }

  getDetail(id: string): Observable<ReportEmbed> {
    return this.http.get<ReportEmbed>(this.apiUrl + '/' + id).pipe(
      catchError(this.handleError<any>("Get Customer Detail", null))
    );
  }

  getEmbedParams(id: string): Observable<ReportEmbedParams> {
    return this.http.get<ReportEmbedParams>(this.apiUrl + '/getreportembed/' + id).pipe(
      catchError(this.handleError<any>("Get Customer Detail", null))
    );
  }

  save(item: ReportEmbed) {
    if (item.reportEmbedId == UtilityService.emptyGuid) {
      //New Item
      return this.http.post<ReportEmbed>(this.apiUrl + '/new', item).pipe(
        tap(_ => this.messages.add("Report Embed Service: Report Embed Saved Successfully", MessageType.SUCCESS, true)),
        catchError(this.handleError<ReportEmbed>("Save New Report Embed", item))
      );
    }
    else {
      //Existing Item
      return this.http.post<ReportEmbed>(this.apiUrl, item).pipe(
        tap(_ => this.messages.add("Report Embed Service: Report Embed Saved Successfully", MessageType.SUCCESS, true)),
        catchError(this.handleError<ReportEmbed>("Update Report Embed", item))
      );
    }
  }

}
