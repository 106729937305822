import { Product } from "../../order/resources/product";
import { Station } from "../../order/resources/station";

export interface OutsideProcessDescriptionStep {
  outsideProcessDescriptionId: string,
  outsideProcessDescriptionStepId: string,
  order: number,
  stationId: string,
  station: Station,
  outsideProcessSpecifications: string[];
  outsideProcessSpecificationNames: string[];
  description: string;
}

export interface OutsideProcessDescription {
  outsideProcessDescriptionId: string,
  productId: string,
  product: Product,
  steps: OutsideProcessDescriptionStep[],
  numericalId?: number,
  readonly name?: string
  readonly outsideProcessDescriptionNumber?: string
}

export function getOutsideProcessOpsOnly(op: OutsideProcessDescription) {
  let stepStrings = op.steps.map((step, i) => {
    let specString = step.outsideProcessSpecificationNames.join(' //// ');
    if (step.outsideProcessSpecificationNames.length > 0) specString = `(${specString})`;
    return `OP${i+1}: ${step.station.name} ${specString}\n${step.description} `;
  })

  return stepStrings.join('\n\n')
}

export function getOutsideProcessDescriptionString(op: OutsideProcessDescription) {
  if (!op) return '';
  let output = `${op.product?.partNumber ?? ''} Rev. ${op.product?.revision ?? ''}`;

  output += `\n${getOutsideProcessOpsOnly(op)}`;

  return output;
}

export function getOutsideProcessDescriptionShort(op: OutsideProcessDescription) {
  const stepStrings = op.steps.map((step) => step.station.name);
  return `${op.product.partNumber} Rev. ${op.product.revision} - ${stepStrings.join(', ')}`;
}