import { Component, Inject, Injector, OnInit } from '@angular/core';
import { map } from 'rxjs/operators';
import { GenericSearchComponent } from '../../../common/components/generic-search/generic-search.component';
import { PseudoPurchasedItemPartNumber } from '../../resources/purchaseOrder';
import { PurchasingService } from '../../services/purchasing.service';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'purchased-item-part-number-select',
  templateUrl: './purchased-item-part-number-select.component.html',
  styleUrls: ['./purchased-item-part-number-select.component.less']
})
export class PurchasedItemPartNumberSelectComponent extends GenericSearchComponent<PseudoPurchasedItemPartNumber> {

  constructor(private purchasingSvc: PurchasingService, @Inject(Injector) injector: Injector) {
    super(injector);
  }

  public get placeholder() {
    return 'Purchased Item';
  }

  doSearch(searchText: string) {
    return this.purchasingSvc.searchPurchasedItemsByPartNumber(searchText, 0, 100).pipe(
      map(x => x.results)
    );
  }

  public canAdd(): boolean { return true }

  public get addItemText(): string {
     return 'New Item';
  }

  public get noItemsText(): string {
    return 'No matching items found.';
  }

  public searchValueField(c: PseudoPurchasedItemPartNumber) {
    return c.partNumber ? c.partNumber : c.description;
  };

  public getSearchField(c: PseudoPurchasedItemPartNumber): string {
    return c ? (c.partNumber ? `${c.partNumber} ${c.description}` : c.description) : '';
  }

}
