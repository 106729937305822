import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'shortNumber'
})
export class ShortNumberPipe implements PipeTransform {

  // Transforms numbers to abbreviated form, such as:
  // 123 = 123
  // 12345 = 12.3k
  // 1234567 = 1.23m
  transform(val: number): string {
    if (isNaN(val)) return val.toString();

    const letters = ['', 'k', 'm' ,'b', 't', 'qa', 'qi', 'sx', 'sp', 'oc', 'no', 'dc'];

    const power = Math.log10(Math.abs(val));
    let thousands = Math.floor(power / 3);
    const rounded = val / Math.pow(1000, thousands);

    // edge case:
    // val=999501 would result in rounded=999.501, and then toPrecision would make it "1.00e+3k" yuck
    // Instead, let it round up to the next tier and show as 1.00M
    if (rounded >= 999.5 || rounded <= -999.5) {
        thousands += 1;
    }

    // failsafe in case the number is higher than we haver letters (unlikely). don't abbreviate at all to make the situation obvious
    if (thousands > (letters.length-1)) {
        return val.toString();
    }

    const numberPart = rounded.toPrecision(3);
    const letterPart = letters[thousands];

    return `${numberPart}${letterPart}`;
  }
}
