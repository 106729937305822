import { Component, Inject, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { NavigationService } from '../../../common/services/navigation.service';
import { UtilityService } from '../../../common/services/utility.service';
import { Material } from '../../../order/resources/material';
import { OrderStatus } from '../../../order/resources/order';
import { Paint } from '../../../order/resources/paint';
import { Product, ProductPurchasedItem } from '../../../order/resources/product';
import { Station } from '../../../order/resources/station';
import { Workflow, WorkflowStep, WorkflowStepType } from '../../../order/resources/workflow';
import { OrderService } from '../../../order/services/order.service';
import { StationService } from '../../../order/services/station.service';
import { WorkOrder } from '../../../planning/resources/work-order';
import { WorkOrderService } from '../../../planning/services/work-order.service';
import { MaterialBid } from '../../resources/materialBid';
import { PurchaseOrder } from '../../resources/purchaseOrder';
import { PurchasingService } from '../../services/purchasing.service';

type ProductCacheInstance = {
  originalProduct: Product,
  new: boolean,
  partNumber: string,
  rev: string,
  purchasedItems: ProductPurchasedItem[],
  workflowSteps: WorkflowStep[],
  setChildren?: string[],
  materialId: string | null,
}

type ProductCache = {
  [key: string]: ProductCacheInstance
}

@Component({
  selector: 'app-unassigned-po-detail',
  templateUrl: './unassigned-po-detail.component.html',
  styleUrls: ['./unassigned-po-detail.component.less']
})
export class UnassignedPoDetailComponent implements OnInit {

  constructor(private service: PurchasingService,
    private woService: WorkOrderService,
    private orderService: OrderService,
    private route: ActivatedRoute,
    private router: Router,
    private dialog: MatDialog,
    private navService: NavigationService) {
    this.id = this.route.snapshot.paramMap.get('id');
  }

  public saving = false;

  public id: string;
  public detail: {
    bid: MaterialBid,
    availableQty: number,
    expanded?: boolean,
    assignableWOs: {
      wo: WorkOrder,
      unfulfilled: number,
      override?: boolean,
    }[]
  }[];
  public poDetail: PurchaseOrder;

  public assignmentMap: {
    // key: bid ID
    [key: string]: {
        // key: work order ID, value: qty assigned
        [key: string]: number
    }
  } = {};

  public productCache: ProductCache = {};

  public materialOperations: {
    bid: MaterialBid,
    wo: WorkOrder,
    type: "replace" | "sibling" | "child",
    materialId: string,
    childPartNumber?: string,
    childPartRev?: string,
    parentPartNumber?: string,
    parentPartRev?: string
  }[] = [];

  public getNumberAssigned(materialBidId: string): number {
    const bidAssignment = this.assignmentMap[materialBidId];
    if (!bidAssignment) return 0;
    return Object.values(bidAssignment).filter(x => !!x).reduce((acc, x) => acc + x, 0);
  }

  public getTotalAssigned() {
    return this.detail.map(x => x.bid.materialBidId).reduce((acc, x) => acc + this.getNumberAssigned(x), 0)
  }

  public getWOAssignment(materialBidId: string, workOrderId: string) {
    const bidAssignment = this.assignmentMap[materialBidId];
    if (!bidAssignment) return 0;
    return bidAssignment[workOrderId] || 0;
  }

  public setWOAssignment(materialBidId: string, workOrderId: string, value: number) {
    const bidData = this.detail.find(x => x.bid.materialBidId === materialBidId);
    const workOrderData = bidData.assignableWOs.find(x => x.wo.workOrderId === workOrderId);

    if (!this.assignmentMap[materialBidId]) this.assignmentMap[materialBidId] = {};

    const maxAssignment = this.getMaxAssignable(workOrderData.wo, bidData.bid, bidData.availableQty, workOrderData.unfulfilled);

    let finalValue = Math.min(value, maxAssignment);
    if (finalValue < 0) finalValue = 0;

    this.assignmentMap[materialBidId][workOrderId] = finalValue;
    

  }

  public toggleWO(value: boolean, materialBidId: string, workOrderId: string) {
    if (!this.assignmentMap[materialBidId]) this.assignmentMap[materialBidId] = {};
    
    if (value === false) {
      this.assignmentMap[materialBidId][workOrderId] = undefined;
    } else {
      const bidData = this.detail.find(x => x.bid.materialBidId === materialBidId);
      const workOrderData = bidData.assignableWOs.find(x => x.wo.workOrderId === workOrderId);
      const maxAssignment = this.getMaxAssignable(workOrderData.wo, bidData.bid, bidData.availableQty, workOrderData.unfulfilled);
      this.assignmentMap[materialBidId][workOrderId] = maxAssignment;
    }
  }

  async getDetail() {
    this.detail = null;
    this.detail = await this.service.getAssignableWOsforPO(this.id).toPromise();
  }


  async submit() {
    this.saving = true;

    // Purchased items to be added
    const purchasedItemPromises = Object.values(this.productCache).flatMap(p => 
      p.purchasedItems.filter(i => i.productPurchasedItemId === UtilityService.emptyGuid)
    ).map(ppi => this.orderService.saveProductPurchasedItem(ppi).toPromise())
    await Promise.all(purchasedItemPromises);
    // Workflow steps to be added and sorted
    const workflowPromises = Object.values(this.productCache).map(async (p) => {
      const stepPromises = p.workflowSteps.map(async (step) => {
        if (step.workflowStepId === UtilityService.emptyGuid) {
          const newStep = await this.orderService.saveWorkflowStep(p.originalProduct, step).toPromise();
          step.workflowStepId = newStep.workflowId;
        }
        return step;
      });
      const newSteps = await Promise.all(stepPromises);
      await this.orderService.orderWorkflowSteps(newSteps.map(x => x.workflowStepId)).toPromise();
    });
    await Promise.all(workflowPromises);
    // Materials to be added
    const materialPromises = this.materialOperations.map(async ({
      wo, type, materialId,
      childPartNumber, childPartRev,
      parentPartNumber, parentPartRev
    }) => {
      if (type === "replace") {
        const productData = await this.orderService.getProduct(wo.productId).toPromise();
        productData.materialId = materialId;
        await this.orderService.savePart(productData).toPromise();
      } else if (type === "child") {
        const childProduct = <Product>{
          productId: UtilityService.emptyGuid,
          partNumber: childPartNumber,
          revision: childPartRev,
          materialId,
          parentAssemblyId: wo.productId,
          childAssemblies: [],
          documents: [],
          workflow: <Workflow>{
            workflowId: UtilityService.newGuid(),
            workflowSteps: []
          }
        };
        const newChildProduct = await this.orderService.createPart(childProduct).toPromise();
        await this.woService.newWorkOrderFromProduct(wo.order, newChildProduct).toPromise();
      } else if (type === "sibling") {
        const productData = await this.orderService.getProduct(wo.productId).toPromise();
        const parentProduct = <Product>{
          productId: UtilityService.emptyGuid,
          partNumber: parentPartNumber,
          revision: parentPartRev,
          parentAssemblyId: productData.parentAssemblyId,
          childAssemblies: [],
          documents: [],
          workflow: <Workflow>{
            workflowId: UtilityService.newGuid(),
            workflowSteps: []
          }
        };
        const newParentProduct = await this.orderService.createPart(parentProduct).toPromise();
        const siblingProduct = <Product>{
          productId: UtilityService.emptyGuid,
          partNumber: childPartNumber,
          revision: childPartRev,
          materialId,
          parentAssemblyId: newParentProduct.productId,
          childAssemblies: [],
          documents: [],
          workflow: <Workflow>{
            workflowId: UtilityService.newGuid(),
            workflowSteps: []
          }
        };
        productData.parentAssemblyId = newParentProduct.productId;
        const [newSiblingProduct, ] = await Promise.all([
          this.orderService.createPart(siblingProduct).toPromise(),
          this.orderService.savePart(productData).toPromise()
        ]);
        await this.woService.newWorkOrderFromProduct(wo.order, newParentProduct).toPromise();
        await this.woService.newWorkOrderFromProduct(wo.order, newSiblingProduct).toPromise();
      }
    });
    await Promise.all(materialPromises);
    

    await this.service.assignWOsToPO(this.poDetail.purchaseOrderId, Object.entries(this.assignmentMap)
      .map(([bidId, woMap]) => ({
        materialBidId: bidId,
        workOrders: Object.entries(woMap).map(([woId, qty]) => ({
          workOrderId: woId,
          assignedAmount: qty
        }))
      }))).toPromise();
    this.saving = false;
    await this.getDetail();
    this.assignmentMap = {};
    if (this.detail.length === 0) {
      this.router.navigate(['/purchasing/orders/unassigned/'])
    }
  }

  public getProductInfo(bid: MaterialBid): string {
    if (bid.materialId) {
      //Material
      return Material.generatedName(bid.material);
    }
    else if (bid.stationId) {
      //station
      return bid.station.name;
    }
    else {
      //purchased item
      return bid.purchasedItem.description;
    }
  }

  public getMaxAssignable(wo: WorkOrder, bid: MaterialBid, bidAvailable: number, woNeeded: number) {

    const thisAssignmentAmount = this.getWOAssignment(bid.materialBidId, wo.workOrderId);
    const finalBidAvailable = bidAvailable - (this.getNumberAssigned(bid.materialBidId) - thisAssignmentAmount);

    return Math.min(finalBidAvailable, woNeeded);
  }

  public otherWOResults: WorkOrder[];
  public otherWOResultsSearching = false;

  public async searchWOs(searchString: string, bid: MaterialBid) {
    this.otherWOResultsSearching = true;
    const searchResults = await this.woService.search(true, searchString).toPromise();
    let filteredResults = searchResults.results.filter(wo => wo.status !== OrderStatus.FULFILLED && wo.status !== OrderStatus.HISTORY);
    // we don't want to let the user edit the material on the same WO more than once, I don't think?
    if (bid.materialId) filteredResults = filteredResults.filter(wo => !this.materialOperations.some(op => op.wo.workOrderId === wo.workOrderId));
    this.otherWOResults = filteredResults;
    this.otherWOResultsSearching = false;
  }

  @ViewChild('otherWOSearchDialog', { static: true }) otherWOSearchDialog: TemplateRef<any>;
  @ViewChild('purchasedItemOverrideTemplate', { static: true }) purchasedItemOverrideTemplate: TemplateRef<any>;
  @ViewChild('materialOverrideTemplate', { static: true }) materialOverrideTemplate: TemplateRef<any>;
  public async assignOtherWO(bid: MaterialBid) {
    this.otherWOResults;
    const dialogRef = this.dialog.open(this.otherWOSearchDialog, {
      width: "60%",
      height: "600px",
      data: {
        bid
      }
    })
    const result = await dialogRef.afterClosed().toPromise();
    if (!result) return;
    const wo: WorkOrder = result;

    let productData: ProductCacheInstance;
    this.saving = true;
    if (this.productCache[wo.productId]) productData = this.productCache[wo.productId];
    else {
      const prod = await this.orderService.getProduct(wo.productId).toPromise();
      productData = {
        originalProduct: prod,
        new: false,
        partNumber: prod.partNumber,
        rev: prod.revision,
        materialId: prod.materialId,
        purchasedItems: prod.purchasedItems,
        workflowSteps: prod.workflow.workflowSteps
      }
      this.productCache[wo.productId] = productData;
    }
    this.saving = false;

    let unfulfilled: number = 1;
    if (!!bid.purchasedItemId) {
      const qtyDialogRef = this.dialog.open(this.purchasedItemOverrideTemplate, {
        disableClose: true,
        data: {
          bid
        }
      })
      const result = await qtyDialogRef.afterClosed().toPromise();
      if (!result) return;
      unfulfilled = result.qty;
      productData.purchasedItems.push({
        productPurchasedItemId: UtilityService.emptyGuid,
        productId: wo.productId,
        purchasedItemId: bid.purchasedItemId,
        selectedQuote: bid.materialBidId,
        isAmortized: false,
        isNonRecurring: false,
        quantity: unfulfilled,
        markupPercent: 18,
        costPer: bid.perItemBid || (bid.totalBid / bid.qty),
        itemRepairType: WorkflowStepType.Standard,
      });
    } else if (!!bid.stationId) {
      const stationDialogRef = this.dialog.open(OverrideWorkflowPrompt, {
        disableClose: true,
        width: '80%',
        data: {
          steps: JSON.parse(JSON.stringify(productData.workflowSteps)),
          stationToInsert: bid.station,
          bid
        }
      });
      const workflowResult = await stationDialogRef.afterClosed().toPromise();
      if (workflowResult === null) return;
      else productData.workflowSteps = workflowResult;
      this.productCache[wo.productId] = productData;
    } else if (!!bid.materialId) {
      const materialDialogRef = this.dialog.open(this.materialOverrideTemplate, {
        disableClose: true,
        data: {
          bid, wo
        }
      });
      const materialResult = await materialDialogRef.afterClosed().toPromise();
      if (materialResult === null) return;
      const { option,
        childPartNumber,
        childPartRev,
        parentPartNumber,
        parentPartRev
      } = materialResult;
      this.materialOperations.push({
        bid,
        wo,
        type: option,
        materialId: bid.materialId,
        childPartNumber,
        childPartRev,
        parentPartNumber,
        parentPartRev
      })
    } else {
      throw new Error('invalid bid!');
    }

    this.saving = true;
    const productQty = await this.service.getTotalProductQty(wo.product.productId).toPromise();
    unfulfilled = unfulfilled * productQty;
    this.saving = false;


    const bidData = this.detail.find(x => x.bid.materialBidId === bid.materialBidId);
    const existingItem = bidData.assignableWOs.find(a => a.wo.workOrderId === wo.workOrderId);
    if (existingItem) {
      existingItem.override = true;
      existingItem.unfulfilled += unfulfilled;
    } else {
      bidData.assignableWOs.push({
        wo,
        unfulfilled,
        override: true
      });
    }
    this.toggleWO(true, bid.materialBidId, wo.workOrderId);
  }

  ngOnInit() {
    this.service.getAssignableWOsforPO(this.id).subscribe(x => this.detail = x);
    this.service.getPurchaseOrderDetail(this.id).subscribe(x => {
      this.navService.setPageTitle("Unattached POs");
      this.poDetail = x;
      this.navService.pushBreadcrumb(this.poDetail.purchaseOrderNumber);
    });
  }

  public isInteger = Number.isInteger;

}


@Component({
  selector: 'override-workflow-prompt',
  templateUrl: './override-workflow-prompt.component.html',
  styleUrls: ['./unassigned-po-detail.component.less']
})
export class OverrideWorkflowPrompt implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: {
      stationToInsert: Station;
      steps: WorkflowStep[];
      bid: MaterialBid;
    },
    private stationService: StationService, private utilitySvc: UtilityService
  ) {}

  public editingItem: WorkflowStep;

  ngOnInit() {
    if (this.stationService.loaded) {
      this.stationList = this.stationService.stationList;
    }
    else {
      this.stationService.stationsLoaded.subscribe(
        _ => this.stationList = this.stationService.stationList
      );
    }

    this.sortWorkflow();

    const station = this.data.stationToInsert;
    this.editingItem = <WorkflowStep>{
      workflowStepId: UtilityService.emptyGuid,
      stationId: station.stationId,
      runPrice: this.data.bid.totalBid,
      runIsPerPart: station.perPart,
      isStandalone: station.isStandalone || false,
      hasSetup: station.hasSetup ? true : (station.isOutsourceStep ? false : true),
      setupTime: station.defaultSetupTime || 0,
      outsourceMarkup: UtilityService.defaultMarkup,
      stepOrder: this.data.steps.length,
      selectedQuote: this.data.bid.materialBidId
    };

    if (station.isPainting) {
      this.editingItem.runTime = 1;
      this.editingItem.runPrice = 0.065;
      this.editingItem.paintCost = 0.125;
      this.editingItem.paintMinPrice = 350;
    }

    this.data.steps.push(this.editingItem);

  }

  private stationList: Station[] = null;

  public draggedIndex: number = -1;
  public onDragOverIndex: number = -1;
  private checkInterval: any = null;

  public getStation(item: WorkflowStep): Station {
    if (this.stationList == null || item == null || item.stationId == null)
      return null;

    return this.stationList.find(r => r.stationId == item.stationId);
  }

  public onDrop($event: any, index: number) {
    $event.stopImmediatePropagation();
    // index is of the element on which the item is dropped
    this.handleDrop(index);
  }

  public allowDrop($event: any, index: number) {
    $event.stopImmediatePropagation();
    // index is of the item on which the item is currently hovered
    this.onDragOverIndex = index;
    $event.preventDefault();
  }

  public onDragStart(_event: any, station: Station, index: number) {
    sessionStorage.setItem('station-list-drag-data', JSON.stringify(station));
    this.draggedIndex = index;

    if (this.checkInterval == null) {
      this.checkInterval = setInterval(_ => {
        if (sessionStorage.getItem('station-list-drag-data') == null) {
          this.draggedIndex = -1;
          this.onDragOverIndex = -1;
        }
      }, 500);
    }
  }

  public handleDrop(droppedIndex: number) {
      var item = this.data.steps[this.draggedIndex];
      if (item == null) {
        return;
      }
      else {
        this.data.steps.splice(this.draggedIndex, 1);
      }

      this.data.steps.splice(droppedIndex, 0, item);
      this.draggedIndex = -1;
      this.onDragOverIndex = -1;
  }

  public swapElements(oldIndex: number, newIndex: number) {
    const temp = this.data.steps[oldIndex];
    this.data.steps[oldIndex] = this.data.steps[newIndex];
    this.data.steps[newIndex] = temp;
    this.data.steps.forEach((step, i) => {
      step.stepOrder = i;
    })
  }

  public moveUp() {
    const index = this.data.steps.indexOf(this.editingItem);
    if (index === 0) {
      return;
    }
    this.swapElements(index, index - 1);
  }

  public moveDown() {
    const index = this.data.steps.indexOf(this.editingItem);
    if (index === this.data.steps.length - 1) {
      return;
    }
    this.swapElements(index, index + 1);
  }

  private sortWorkflow(): void {
    this.data.steps = this.data.steps.sort((a, b) => { return a.stepOrder - b.stepOrder; });
  }

  public assignPaint(paint: Paint): void {
    if (paint) {
      this.editingItem.paintId = paint.paintId;
      this.editingItem.paint = paint;
    }
  }

  public addPaint(_name: string): void {
    // this.showEditor = "paintEditor";
    // this.navService.pushBreadcrumb("Add Paints");
    // this.insetnav.toggle();
  }

}