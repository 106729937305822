import { Component } from '@angular/core';
import { GenericSelectComponent } from '../../common/components/generic-select/generic-select.component';
import { Vendor } from '../resources/vendor';
import { UserService } from '../../common/services/user.service';
import { PurchasingService } from '../../purchasing/services/purchasing.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'vendor-drop-select',
  templateUrl: '../../common/components/generic-select/generic-select.component.html',
  styleUrls: ['../../common/components/generic-select/generic-select.component.less']
})
/** vendor-drop-select component*/
export class VendorDropSelectComponent extends GenericSelectComponent<Vendor>  {
  /** vendor-drop-select ctor */
  constructor(private usrSvc: UserService, private vendorSvc: PurchasingService) {
    super();
  }
  protected onSelect(value: Vendor): void {
    //empty
  }
  public formatValue(item: Vendor): string {
    if (!item) return '';
    return item.name;
  }
  public formatSearchResult(item: Vendor): string {
    return `${item.name || ""}`;
  }
  public get addItemText(): string {
    return "Add Vendor";
  }
  public get noItemsText(): string {
    return "-- No Matching Vendors Found --";
  }
  public get placeholderText(): string {
    return "Search Vendors";
  }
  public canAdd(): boolean {
    return this.usrSvc.canAccess("PurchasingManager");
  }
  public testSelection(_: any): void {
    if (!this.selection) {
      this.inputTxt.nativeElement.value = "";
      return;
    }
    this.inputTxt.nativeElement.value = this.selection.name;
  }
  protected searchFunction(text: string): Observable<any> {
    return this.vendorSvc.search(text);
  }
}
