import { Injectable, Inject } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, catchError, tap } from 'rxjs/operators';
import { SearchResult } from '../../common/resources/search-result';
import { ShippingTicket, ShippingTicketStatus, ShippingCarrier, ShippingDocument, ShippingInventoryItem, ShippingClass } from '../resources/shipping-ticket';
import { httpService } from '../../common/services/http.service';
import { HttpClient } from '@angular/common/http';
import { MessageService } from '../../common/services/message.service';
import { ErrorHandlerService } from '../../common/services/errorHandler.service';
import { UtilityService } from '../../common/services/utility.service';
import { MessageType } from '../../common/resources/message';
import { VirtualDocument } from '../../common/resources/virtual-document';
import { FloorService } from '../../floor/services/floor.service';
import { Building } from '../../floor/resources/building';
import { InventoryLocation } from '../../inventory/resources/inventory-location';

const BUILDING_ID_KEY = 'shipping-building';

@Injectable({
  providedIn: 'root'
})
export class ShippingService extends httpService {
  private apiBase: string = 'api/';
  private apiUrl: string;


  public $buildings: Observable<Building[]>;
  public $currentBuilding = new BehaviorSubject<Building>(null);

  constructor(errorHandler: ErrorHandlerService, private messages: MessageService, floorService: FloorService, private http: HttpClient, @Inject('BASE_URL') private baseUrl: string) {
    super(errorHandler, messages)
    this.serviceName = "Shipping";

    this.apiUrl = this.baseUrl + this.apiBase;

    this.$buildings = floorService.searchBuildings('', 0, 200).pipe(
      map(r => r.results)
    );

    const lastBuildingUsed = localStorage.getItem(BUILDING_ID_KEY);
    if (lastBuildingUsed) {
      floorService.getBuilding(lastBuildingUsed).subscribe(b => {
        if (b) this.$currentBuilding.next(b);
      })
    }

  }

  public setCurrentBuilding(b: Building) {
    this.$currentBuilding.next(b);
    localStorage.setItem(BUILDING_ID_KEY, b.buildingId);
  }

  public searchOutgoing(overrideView?: boolean, searchString?: string, page?: number, sortBy?: string, sortDirection?: string, filter?: string): Observable<SearchResult<ShippingTicket>> {
    return this.http.get<SearchResult<ShippingTicket>>(this.apiUrl + 'shipping/search/outgoing', { params: { searchText: searchString, forAllUsers: ((overrideView || false) ? "true" : "false"), pageIndex: (page || 0).toString(), orderBy: sortBy || "name", direction: sortDirection || "asc", filter: filter || '' } }).pipe(
      catchError(this.handleError<any>("Get Outgoing Shipping Tickets Search Results", null))
    );
  }

  public searchIncoming(overrideView?: boolean, searchString?: string, page?: number, sortBy?: string, sortDirection?: string, filter?: string): Observable<SearchResult<ShippingTicket>> {
    return this.http.get<SearchResult<ShippingTicket>>(this.apiUrl + 'shipping/search/incoming', { params: { searchText: searchString, forAllUsers: ((overrideView || false) ? "true" : "false"), pageIndex: (page || 0).toString(), orderBy: sortBy || "name", direction: sortDirection || "asc", filter: filter || '' } }).pipe(
      catchError(this.handleError<any>("Get Outgoing Shipping Tickets Search Results", null))
    );
  }

  public getDetail(ticketId: string): Observable<ShippingTicket> {
    return this.http.get<ShippingTicket>(this.apiUrl + 'shippingticket/' + ticketId).pipe(
      catchError(this.handleError<any>("Get Shipping Ticket Detail", null))
    );
  }

  public getBatchSuggestions(ticketId: string): Observable<ShippingTicket[]> {
    return this.http.get<ShippingTicket>(this.apiUrl + 'shipping/getBatchSuggestions/' + ticketId).pipe(
      catchError(this.handleError<any>("Get Shipping Batch Suggestions", null))
    );
  }

  public assignTicket(ticketId: string, employeeId: string) {
    return this.http.post<ShippingTicket>(this.apiUrl + 'shipping/assignticket/' + ticketId + '/' + employeeId, {}).pipe(
      tap(_ => this.messages.add("Shipping Service: Ticket Assigned Successfully", MessageType.SUCCESS, true)),
      catchError(this.handleError<ShippingTicket>("Assign Ticket"))
    );
  }

  public getTicketsForFulfillment(workOrderId: string): Observable<ShippingTicket[]> {
    return this.http.get<ShippingTicket[]>(this.apiUrl + 'shipping/getTicketsForFulfillment/' + workOrderId).pipe(
      catchError(this.handleError<any>("Get Tickets for Fulfillment", null))
    );
  }

  public getTicketsInBatch(batchId: string): Observable<ShippingTicket[]> {
    return this.http.get<ShippingTicket[]>(this.apiUrl + 'shipping/getTicketsInBatch/' + batchId).pipe(
      catchError(this.handleError<any>("Get Tickets in Batch", null))
    );
  }

  public getShippingCarriers(searchString?: string): Observable<SearchResult<ShippingCarrier>> {
    return this.http.get<SearchResult<ShippingCarrier>>(this.apiUrl + 'shippingcarrier/search', { params: { searchText: searchString || '', pageIndex: '0', orderBy: "id", direction: "asc" } }).pipe(
      catchError(this.handleError<SearchResult<ShippingCarrier>>("Search Shipping Methods"))
    );
  }

  public splitTicket(ticketId: string, newQty: number): Observable<ShippingTicket[]> {
    return this.http.get<ShippingTicket[]>(`${this.apiUrl}shipping/splitTicket/${ticketId}?newQty=${newQty}`).pipe(
      catchError(this.handleError<any>("Split Shipping Ticket", null))
    );
  }

  public saveBatch(batchId: string, ticketIds: string[]): Observable<any> {
    batchId = batchId || UtilityService.newGuid();
    return this.http.post<string[]>(this.apiUrl + 'shipping/saveBatch/' + batchId, ticketIds).pipe(
      tap(_ => this.messages.add("Shipping Service: Update Batch Items", MessageType.SUCCESS, true)),
      catchError(this.handleError<string[]>("Update Batch Items", ticketIds))
    );
  }

  public savePickLocations(ticketId: string, locations: ShippingInventoryItem[]): Observable<any> {
    return this.http.post<ShippingInventoryItem[]>(this.apiUrl + 'shipping/savePickLocations/' + ticketId, locations).pipe(
      tap(_ => this.messages.add("Shipping Service: Update Item Pick Locations", MessageType.SUCCESS, true)),
      catchError(this.handleError<ShippingInventoryItem[]>("Update Pick Locations", locations))
    );
  }

  public addDocuments(ticket: ShippingTicket, documents: VirtualDocument[]): Observable<ShippingDocument[]> {
    return this.http.post<ShippingDocument[]>(this.apiUrl + 'shipping/adddocuments?ticketId=' + ticket.shippingTicketId, documents.map(d => d.documentId)).pipe(
      tap(_ => this.messages.add("Shipping Service: Documents Updated", MessageType.SUCCESS, true)),
      catchError(this.handleError<any>("Add Documents to Shipping Ticket", null))
    );
  }

  public removeDocument(ticket: ShippingTicket, document: VirtualDocument): Observable<any> {
    return this.http.get<any>(this.apiUrl + 'shipping/removedocument?ticketId=' + ticket.shippingTicketId + '&documentId=' + document.documentId).pipe(
      tap(_ => this.messages.add("Shipping Service: Document Removed Successfully", MessageType.SUCCESS, true)),
      catchError(this.handleError<any>("Remove Document from Shipping Ticket", null))
    );
  }

  public save(item: ShippingTicket): Observable<ShippingTicket> {
    item.shippingCarrier = null;
    item.employee = null;

    if (item.shippingTicketId == UtilityService.emptyGuid) {
      return this.http.post<ShippingTicket>(this.apiUrl + 'shippingticket/new', item).pipe(
        tap(_ => this.messages.add("Shipping Service: Ticket Saved Successfully", MessageType.SUCCESS, true)),
        catchError(this.handleError<ShippingTicket>("Save New Shipping Ticket", item))
      );
    }
    else {
      return this.http.post<ShippingTicket>(this.apiUrl + 'shippingticket', item).pipe(
        tap(_ => this.messages.add("Shipping Service: Ticket Saved Successfully", MessageType.SUCCESS, true)),
        catchError(this.handleError<ShippingTicket>("Update Shipping Ticket", item))
      );
    }
  }

  public getShippingCarrierDetail(shippingCarrierId: string): Observable<ShippingCarrier> {
    return this.http.get<ShippingTicket>(this.apiUrl + 'shippingcarrier/' + shippingCarrierId).pipe(
      catchError(this.handleError<any>("Get Shipping Method Detail", null))
    );
  }

  public saveShippingCarrier(method: ShippingCarrier): Observable<ShippingCarrier> {
    if (method.shippingCarrierId == UtilityService.emptyGuid) {
      return this.http.post<ShippingCarrier>(this.apiUrl + 'shippingcarrier/new', method).pipe(
        tap(_ => this.messages.add("Shipping Service: Carrier Saved Successfully", MessageType.SUCCESS, true)),
        catchError(this.handleError<ShippingCarrier>("Save New Shipping Carrier", method))
      );
    }
    else {
      return this.http.post<ShippingCarrier>(this.apiUrl + 'shippingcarrier', method).pipe(
        tap(_ => this.messages.add("Shipping Service: Carrier Saved Successfully", MessageType.SUCCESS, true)),
        catchError(this.handleError<ShippingCarrier>("Update Shipping Carrier", method))
      );
    }
  }

  public checkInTicket(ticketId: string, data: {
    amountReceived: number,
    backorderDate: Date,
    receivedDate: Date,
    lots: { 
      lotNumber: string, qty: number, location: InventoryLocation
    }[],
    documents: VirtualDocument[]
  }) {
    return this.http.post<ShippingTicket>(this.apiUrl + 'shipping/checkin/' + ticketId, data).pipe(
      tap(_ => this.messages.add("Shipping Service: Ticket Checked In Successfully", MessageType.SUCCESS, true)),
      catchError(this.handleError<ShippingTicket>("Check In Ticket"))
    );
  }

  public checkOutTicket(ticketId: string, data: {
    pickLocations: {
      [key: string]: number
    }
  }) {
    return this.http.post<ShippingTicket>(this.apiUrl + 'shipping/checkout/' + ticketId, data).pipe(
      tap(_ => this.messages.add("Shipping Service: Ticket Checked Out Successfully", MessageType.SUCCESS, true)),
      catchError(this.handleError<ShippingTicket>("Check Out Ticket"))
    );
  }

  public getBuildingSchedule(buildingId?: string): Observable<{
    day: string,
    incoming: number,
    outgoing: number
  }[]> {
    buildingId = buildingId || (this.$currentBuilding.value && this.$currentBuilding.value.buildingId);
    return this.http.get<any[]>(this.baseUrl + `api/Shipping/buildingSchedule/${buildingId}`).pipe(
      catchError(this.handleError<any>("Get Shipping Schedule", null))
    );
  }

  public getBuildingQueue(buildingId: string, direction: "incoming" | "outgoing" | "history", managerView: boolean, searchText: string = '', pageIndex = 0): Observable<{
    day: string,
    overdue: boolean,
    tickets: ShippingTicket[]
  }[]> {
    return this.http.get<any[]>(this.baseUrl + `api/Shipping/queue/${buildingId}`, {
      params: { direction, managerView: managerView.toString(), searchText, pageIndex: pageIndex.toString() }
    }).pipe(
      catchError(this.handleError<any>("Get Shipping Queue", null))
    );
  }

  public getBuildingHistory(buildingId: string, forAllUsers: boolean, searchString = null, pageIndex = 0, pageSize = 50): Observable<SearchResult<ShippingTicket>> {
    return this.http.get<any[]>(this.baseUrl + `api/Shipping/queueHistory/${buildingId}`, { params: { searchText: searchString, forAllUsers: ((forAllUsers || false) ? "true" : "false"), pageIndex: (pageIndex || 0).toString() } }).pipe(
      catchError(this.handleError<any>("Get Shipping History", null))
    );
  }

  public getInventoryLabel(ticket: ShippingTicket, lotNumber: string, qty: string, receivedDate: Date): Observable<VirtualDocument> {
    const date = new Date(receivedDate).toISOString().split('T')[0].replaceAll("-", '');
    return this.http.get<Document>(this.baseUrl + 'api/Shipping/inventoryLabel/' + ticket.shippingTicketId, { params: { lotNumber, qty, receivedDate: date } }).pipe(
      catchError(this.handleError<any>("Get Inventory Label", null))
    );
  }

  public getShippingLabel(ticket: ShippingTicket, building: Building): Observable<VirtualDocument> {
    return this.http.get<Document>(this.baseUrl + 'api/Shipping/shippingLabel/' + ticket.shippingTicketId, { params: { buildingId: building.buildingId } }).pipe(
      catchError(this.handleError<any>("Get Inventory Label", null))
    );
  }

  public static getTicketNumber(ticket: ShippingTicket): string {
    if (!ticket) return '';
    if (ticket.subticketNumber) return ticket.subticketNumber;

    if (ticket.rmaTicket) {
      return `${ticket.rmaTicket.rmaNumber}//${ticket.ticketNumber.toString().padStart(3, '0')}`;
    }
    else if (ticket.purchaseOrder) {
      return `${ticket.purchaseOrder.purchaseOrderNumber}//${ticket.ticketNumber.toString().padStart(3, '0')}`;
    }
    else if (ticket.workOrder) {
      return `${ticket.workOrder.workOrderNumber}//${ticket.ticketNumber.toString().padStart(3, '0')}`;
    }
    else if (ticket.legacyWorkOrderOrDeptCode) {
      return `${ticket.legacyWorkOrderOrDeptCode}//${ticket.ticketNumber.toString().padStart(3, '0')}`;
    }

    return '[Bad Data!]';
  }

  public static getStatusColorClassChip(status: ShippingTicketStatus): string {
    switch (status) {
      case ShippingTicketStatus.AWAIT_WO: return 'bg-secondary text-dark';
      case ShippingTicketStatus.UNASSIGNED: return 'bg-danger text-white';
      case ShippingTicketStatus.IN_PROCESS: return 'bg-info text-white';
      case ShippingTicketStatus.PACKAGED: return 'bg-info text-white';
      case ShippingTicketStatus.SHIPPED: return 'bg-success text-white';
      case ShippingTicketStatus.RECEIVED: return 'bg-success text-white';
      case ShippingTicketStatus.VOID: return 'bg-dark text-white';
      default: return null;
    }
  }

  public static getStatusColorClass(status: ShippingTicketStatus): string {
    switch (status) {
      case ShippingTicketStatus.AWAIT_WO: return 'badge-secondary';
      case ShippingTicketStatus.UNASSIGNED: return 'badge-danger';
      case ShippingTicketStatus.IN_PROCESS: return 'badge-info';
      case ShippingTicketStatus.PACKAGED: return 'badge-info';
      case ShippingTicketStatus.SHIPPED: return 'badge-success';
      case ShippingTicketStatus.RECEIVED: return 'badge-success';
      case ShippingTicketStatus.VOID: return 'badge-dark';
      default: return null;
    }
  }

  public static getStatusText(status: ShippingTicketStatus): string {
    switch (status) {
      case ShippingTicketStatus.AWAIT_WO: return 'Awaiting Work Order';
      case ShippingTicketStatus.UNASSIGNED: return 'Unassigned';
      case ShippingTicketStatus.IN_PROCESS: return 'In Process';
      case ShippingTicketStatus.PACKAGED: return 'Packaged';
      case ShippingTicketStatus.SHIPPED: return 'Shipped';
      case ShippingTicketStatus.RECEIVED: return 'Received';
      case ShippingTicketStatus.VOID: return 'Void';
      default: return null;
    }
  }

  static getShippingClassText(shippingClass: ShippingClass): string {
    switch (shippingClass) {
      case ShippingClass.INBOUND: return 'Inbound';
      case ShippingClass.OUTGOING: return 'Outgoing';
      case ShippingClass.INTERNAL: return 'Internal';
    }
  }


}
