import { Component, OnInit, Input, EventEmitter, Output, ViewChild, ElementRef } from '@angular/core';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { CustomerContact } from '../../../customer/resources/customer';

@Component({
  selector: 'customer-contact-select',
  templateUrl: './customer-contact-select.component.html',
  styleUrls: ['./customer-contact-select.component.less']
})
export class CustomerContactSelectComponent implements OnInit {
  filteredContactList: CustomerContact[] = [];
  @Input() customerContactList: CustomerContact[] = [];
  @Input() selectedContact: CustomerContact;
  @Input() editable: boolean;
  @Output() selectedContactChange: EventEmitter<CustomerContact> = new EventEmitter<CustomerContact>();
  @ViewChild('countryInputText', { static: true }) inputTxt: ElementRef;
  @ViewChild('autoCompleteTrigger', { static: true }) autoCompleteTrigger: MatAutocompleteTrigger;
  @Output() addContact: EventEmitter<string> = new EventEmitter<string>();

  constructor() { }

  toggleDropdown(): void {
    event.stopImmediatePropagation();
    if (this.autoCompleteTrigger.panelOpen) {
      this.autoCompleteTrigger.closePanel();
    }
    else {
      this.filterList();
      this.autoCompleteTrigger.openPanel();
    }
  }

  filterList(): void {
    this.filteredContactList = this.customerContactList.filter(c => c.contact && c.contact.name.includes(this.inputTxt.nativeElement.value));
  }

  onAddContact(): void {
    this.autoCompleteTrigger.closePanel();
    event.stopImmediatePropagation();
    this.addContact.emit(this.inputTxt.nativeElement.value);
  }

  setSelection(event: CustomerContact) {
    this.selectedContact = event;
    this.selectedContactChange.emit(this.selectedContact);
  }

  public testSelection(_: any): void {
    if (!this.selectedContact || !this.selectedContact.contact) {
      this.inputTxt.nativeElement.value = "";
      return;
    }
    this.inputTxt.nativeElement.value = this.selectedContact.contact.name;
  }

  ngOnInit(): void {
  }
}
