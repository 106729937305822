

export const employee_shift_assignment_Constants = {

  CUSTOM_SHIFT_NAME_VALUE: "Custom Shift", //Note: This value should match value in DB dbo.Shift.Name
   
};


export const shift_assignment_recurrence_Constants = {

  RECURRENCE_END_OPTION_NEVER: "Never", //Note: This value should match value in DB dbo.ShiftAssignmentRecurrence.recurrenceEndOption
  RECURRENCE_END_OPTION_ON: "On",
  RECURRENCE_END_OPTION_AFTER: "After",

}
