<loading *ngIf="!record"></loading>
<loading *ngIf="saving" content="Saving..."></loading>

<div class="position-fixed" style="bottom: 15px; right: 10px; z-index: 1030" *ngIf="!sidenav.opened && record && !saving">
  <button mat-fab color="warn" (click)="cancelEditing()">
    <mat-icon>close</mat-icon>
  </button>
  <button mat-fab class="bg-success text-white ml-2 mt-4" *ngIf="!editing" (click)="editing = true">
    <mat-icon matPrefix>edit</mat-icon>
  </button>
  <button mat-fab class="bg-success text-white ml-2 mt-4" *ngIf="editing" (click)="complete()">
    <mat-icon matPrefix>save</mat-icon>
  </button>
</div>

<mat-sidenav-container>
  <mat-sidenav class="p-3" #sidenav mode="over" position="end">
    <upload-file *ngIf="showEditor=='document'" (documentsLoaded)="addDocuments($event)" [sidenav]="sidenav">
    </upload-file>
  </mat-sidenav>
  <mat-sidenav-content>
    <div class="w-100 d-flex flex-column p-3" style="height: 93vh;" *ngIf="record">
      <div class="border rounded p-3" *ngIf="record">
        <div class="row">
          <div class="col-6 form-group">
            <label>Work Order</label>
            <div>{{ record.workOrder.workOrderNumber }} </div>
          </div>
          <div class="col-6 form-group">
            <label>Customer</label>
            <div>{{ record.workOrder.order.customer.businessName }} </div>
          </div>
          <div class="col-12 form-group">
            <label>Due Date</label>
            <div>{{ (record.dueDate | date: 'dateShort') || '--' }}</div>
            <span *ngIf="record.dueDate!=null" class="badge required" [ngClass]="getRequiredColor(record.dueDate)">
              {{record.dueDate | date}}
              <span class="d-block small">{{weeksLeft(record.dueDate, true)||'--'}} Week(s) {{(weeksLeft(record.dueDate, false)||0) >= 0 ? 'From Today':'Overdue'}}</span>
            </span>
          </div>
        </div>
      </div>
      <div class="border rounded mt-3 flex-grow-1">
        <mat-tab-group style="height: 100%;">
          <mat-tab label="Programming">
            <div class="row m-0 p-3 h-100-t">
              <div class="col-12">
                <mat-form-field class="w-100 h-100-t" appearance="outline">
                  <mat-label>Programming Notes</mat-label>
                  <textarea matInput placeholder="Programming Notes" [disabled]="!editing" [(ngModel)]="record.programmingNotes" rows="5" style="resize: none;"></textarea>
                </mat-form-field>
              </div>
              <div class="col-12 form-group">
                <label>Programming Files</label>
                <div class="border rounded p-3 position-relative h-100-t" style="min-height: 8em;">
                <file-display-grid [editable]="true" [files]="record.programmingFiles"></file-display-grid>
                  <div *ngIf="record.programmingFiles.length==0">
                    <p class="font-italic text-muted text-center">-- No Uploaded Programming Files --</p>
                  </div>
                  <button mat-fab *ngIf="editing" class="bg-primary text-white mat-elevation-z2" (click)="openDocumentWindow()" style="
                    position: absolute;
                    right: 16px;
                    top: -24px;
                    ">
                    <mat-icon>edit</mat-icon>
                  </button>
                </div>
              </div>
            </div>
          </mat-tab>
          <mat-tab label="Workflow Data">
            <div class="row m-0 py-2 pr-2 h-100-t">
              <div class="col-4">
                <workflow-list [product]="record.workOrder.product" [steps]="record.workOrder.product.workflow.workflowSteps" [sortable]="false" (selected)="selectedStep = $event"></workflow-list>
              </div>
              <div class="col-8 d-flex flex-column py-3" *ngIf="selectedStep">
                <div *ngIf="selectedStep.specification && getSpecifications(selectedStep).length > 0">
                  <div class="pseudo-label">Specifications</div>
                  <div class="w-100">
                    <mat-chip class="bg-primary text-white mr-2 d-inline-block" *ngFor="let spec of getSpecifications(selectedStep)">
                      {{ spec }}
                    </mat-chip>
                  </div>
                </div>
                <mat-form-field appearance="outline" class="w-100 form-field-readonly flex-grow-1">
                  <mat-label>Workflow Notes</mat-label>
                  <textarea matInput disabled [(ngModel)]="selectedStep.description" style="resize: none; height: 100%;"></textarea>
                </mat-form-field>
              </div>
              <div class="col-8 bg-light text-muted d-flex align-items-center justify-content-center" *ngIf="!selectedStep">
                Please select a workflow step on the right to view workflow information.
              </div>
            </div>
          </mat-tab>
          <mat-tab label="Drawings and Documents">
            <div class="p-3 h-100-t">
              <file-display-grid [editable]="false" [files]="record.workOrder.product.documents"></file-display-grid>
              <p *ngIf="record.workOrder.product.documents?.length==0" class="text-muted text-center">
                <em>-- No Documents --</em>
              </p>
            </div>
          </mat-tab>
        </mat-tab-group>
      </div>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>

<ng-template #confirmationDialogTemplate>
  <h2 mat-dialog-title>Are You Finished?</h2>
  <mat-dialog-content class="mat-typography">
    <p>Is this programming ticket complete, or is there still more to be done?</p>
    <p class="m-3">
      <mat-radio-group aria-label="Select an option">
        <mat-radio-button value="1" #ready >Programming is Done</mat-radio-button>
        <mat-radio-button value="2" #needswork class="ml-3">Still Needs More Work</mat-radio-button>
      </mat-radio-group>
    </p>
  </mat-dialog-content>
  <mat-dialog-actions align="end" *ngIf="needswork.checked || ready.checked">
    <button class="btn btn-default btn-sm mr-3" [mat-dialog-close]="{ready: ready.checked}">Okay</button>
    <button class="btn btn-primary btn-sm" [mat-dialog-close]="null">Cancel</button>
  </mat-dialog-actions>
</ng-template>