<table>
  <thead>
    <tr>
      <th>
        Step
      </th>
      <th>
        Status
      </th>
      <th>
        Progress
      </th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let progressStep of workflowProgress"
      [class.bg-success]="getStatus(progressStep) === 'Complete'"
      [class.bg-warning]="getStatus(progressStep) === 'Assigned'"
      [class.bg-light]="getStatus(progressStep) === 'Unassigned'"
    >
      <td>
        {{ getStation(progressStep.workflowStep).name }}
      </td>
      <td>
        {{ getStatus(progressStep) }}
      </td>
      <td>
        <span *ngIf="progressStep.machineAssignmentId">
          {{ progressStep.readyQty }} / {{ progressStep.machineAssignment.requestedQty }}
        </span>
        <span *ngIf="!progressStep.machineAssignmentId">
          --
        </span>
      </td>
    </tr>
  </tbody>
</table>