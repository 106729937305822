import { Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { MaterialBid } from '../../../../../../purchasing/resources/materialBid';
import { Order } from '../../../../../resources/order';
import { Contact } from '../../../../../../common/resources/contact';
import { UtilityService } from '../../../../../../common/services/utility.service';
import { VendorContact } from '../../../../../../supplier/resources/vendor';
import { MatDialog } from '@angular/material/dialog';
import { VendorService } from '../../../../../../supplier/services/vendor.service';

@Component({
  selector: 'product-quoting-new-quote',
  templateUrl: './product-quoting-new-quote.component.html',
  styleUrls: ['./product-quoting-new-quote.component.less']
})
export class ProductQuotingNewQuoteComponent implements OnInit {
  @Input() order: Order;
  @Input() record: MaterialBid;

  constructor(private dialog: MatDialog, private vendorService: VendorService) { }

  ngOnInit(): void {
  }

  public getTypeId(): string {
    return (this.record.material && this.record.material.materialGroupId) || this.record.stationId || 'purchased';
  }

  public getTypeName(): string {
    return (this.record.material && this.record.material.materialGroup.groupName) || (this.record.station && this.record.station.name) || 'Hardware';
  }

  public get contacts(): Contact[] {
    return this.record?.vendor?.vendorContacts?.map(vc => vc.contact) ?? [];
  }

  public bidType: 'total' | 'perItem' = 'total'

  public saving = false;
  @ViewChild('newVendorContactDialogTemplate', { static: true }) newVendorContactDialogTemplate: TemplateRef<any>;
  public async newVendorContact(name: string) {
    const dialogRef = this.dialog.open<Contact, any, Contact>(this.newVendorContactDialogTemplate, { 
      disableClose: false,
      minWidth: 500,
      data: {
        contactId: UtilityService.emptyGuid,
        name,
        title: '',
        phoneNumber: '', phoneExtension: '',
        cellNumber: '', fax: '',
        email: '', personalEmail: ''
    }});
    
    const result = await dialogRef.afterClosed().toPromise();
    if (!result) return;
    this.saving = true;
    const createdContact = await this.vendorService.saveContact(this.record.vendor, <VendorContact>{
      vendorContactId: UtilityService.emptyGuid,
      useForQuoting: true,
      contact: result,
    }).toPromise();
    this.record.vendor.vendorContacts = [...this.record.vendor.vendorContacts, createdContact];
    this.record.contact = createdContact.contact;
    this.record.contactId = createdContact.contactId;
    this.saving = false;

  }

  public get isValid() {
    return this.record?.vendor &&
      (this.record?.perItemBid || this.record?.totalBid) &&
      this.record?.leadTimeDays
  }

  @Output() done = new EventEmitter<MaterialBid>();
  @Output() cancel = new EventEmitter<void>();

}
