<div class="row m-0 h-100-t" *ngIf="product">
  <div class="col-9 p-0">
    <mat-tab-group [animationDuration]="'0ms'" class="mat-tab-group-colgrow mat-tab-group-headerstyle flex-grow-1 product-tab-group h-100" [(selectedIndex)]="selectedTab" (selectedIndexChange)="selectedTabChange.emit($event)">
      <mat-tab>
        <ng-template mat-tab-label>
          <mat-icon class="mr-2">receipt_long</mat-icon>
          Part Info
        </ng-template>
        <div>
          <product-detail-form></product-detail-form>
        </div>
      </mat-tab>
      <mat-tab>
        <ng-template mat-tab-label>
          <mat-icon svgIcon="file-document-multiple" class="mr-2"></mat-icon>
          Documents
        </ng-template>
        <order-detail-documents [documents]="convertDocuments(product.documents)" (documentsChange)="updateDocuments($event); service.notifyEdited(product)" [editing]="editing" [uploadFunction]="this.service.batchUploadDocuments.bind(service)"></order-detail-documents>
      </mat-tab>
      <mat-tab>
        <ng-template mat-tab-label>
          <mat-icon svgIcon="cube" class="mr-2"></mat-icon>
          Material
        </ng-template>
        <div>
          <product-material></product-material>
        </div>
      </mat-tab>
      <mat-tab>
        <ng-template mat-tab-label>
          <mat-icon svgIcon="format-list-numbered" class="mr-2"></mat-icon>
          Workflow
        </ng-template>
        <div class="h-100-t">
          <product-workflow></product-workflow>
        </div>
      </mat-tab>
      <mat-tab>
        <ng-template mat-tab-label>
          <mat-icon svgIcon="package-variant" class="mr-2"></mat-icon>
          Purchased Items
        </ng-template>
        <ng-template matTabContent>
          <div class="h-100-t">
            <product-purchased-items></product-purchased-items>
          </div>
        </ng-template>
      </mat-tab>
    </mat-tab-group>
  </div>
  <div class="col-3 p-0 h-100 d-flex flex-column" style="position: relative; z-index: 6; overflow: hidden">
    <div style="height: 48px; z-index: 10" class="bg-dark position-relative">
      &nbsp;
    </div>
    <div class="mat-elevation-z6 position-relative hierarchy-container flex-spread ml-3" style="z-index: 9">
      <product-hierarchy-sort [planning]="false" [single]="true" #hierarchySort [editing]="editing" [products]="service.selectedTopLevelProduct ? [service.selectedTopLevelProduct] : []" (productsChange)="onHierarchyChange($event)" [(selectedItemId)]="service.selectedProductId" (created)="onCreated($event)" (cloned)="onCloned($event)"
        [expansionModel]="service.productHierarchyExpansionModel"
        (productMoved)="onProductMoved($event)"
        (productDeleted)="onProductDeleted($event)"
      >
        <ng-template #postName let-item let-expanded="expanded">
          <div class="bg-white rounded tree-progress-indicators d-flex align-items-center ml-2" *ngIf="progressService.shouldShowIndicators()">
            <div class="microtask-indicator-tree" [ngClass]="getMicroTaskStatusClass(getSimpleTaskStatus(item.productData, 'material'))">&nbsp;</div>
            <div class="microtask-indicator-tree" [ngClass]="getMicroTaskStatusClass(getSimpleTaskStatus(item.productData, 'workflow'))">&nbsp;</div>
            <div class="microtask-indicator-tree" [ngClass]="getMicroTaskStatusClass(getSimpleTaskStatus(item.productData, 'hardware'))">&nbsp;</div>
            <div class="microtask-indicator-tree" *ngIf="item.children.length > 0 && !expanded" [ngClass]="getMicroTaskStatusClass(getSubassembliesStatus(item.productData))">&nbsp;</div>
          </div>
        </ng-template>
      </product-hierarchy-sort>
    </div>
  </div>
</div>