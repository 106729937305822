import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatAutocomplete, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { VirtualDocument } from '../../../common/resources/virtual-document';
import { DocumentService } from '../../services/document.service';

interface DocumentRelation {
  document?: VirtualDocument
}

@Component({
  selector: 'file-display-grid',
  templateUrl: './file-display-grid.component.html',
  styleUrls: ['./file-display-grid.component.less']
})
export class FileDisplayGridComponent implements OnInit {
  @Input() files: DocumentRelation[];
  @Input() editable: boolean = false;
  @Input() openInline: boolean = false;
  @Input() previewImages: boolean = false;
  @Output() delete: EventEmitter<VirtualDocument> = new EventEmitter<VirtualDocument>();
  public separatorKeyCodes: number[] = [ENTER, COMMA];
  filteredTags: string[];
  private static standardTags: string[] = ['Machine Setup', 'Billing', 'Miscellaneous', 'Shop Aids'];

  @ViewChild('tagInput') tagInput: ElementRef<HTMLInputElement>;
  @ViewChild('auto') matAutocomplete: MatAutocomplete;

  constructor(private docSvc: DocumentService) { }

  openDocument(doc: VirtualDocument): void {
    if (!doc) return;
    if (doc.documentId != null)
      window.open(`/document/${doc.documentId}` + (this.openInline ? '/?inline=true' : ''), "_blank")
  }

  onDelete(doc: VirtualDocument): void {
    event.stopImmediatePropagation();
    this.delete.emit(doc);
  }

  isImage(doc: VirtualDocument): boolean {
    if (!this.previewImages || doc.mimeType == null) return false;

    return doc.mimeType.startsWith('image');
  }

  getImageThumbnail(doc: VirtualDocument): string {
    return `/document/${doc.documentId}?thumbnail=true`;
  }

  getFileIcon(doc: any): string {
    if (!doc) return null;
    var type: string = doc.mimeType || doc.type; //Can be a form document!

    if (type.startsWith("image"))
      return "fas fa-file-image";

    if (type.endsWith("/pdf") || type.indexOf("/pdf;") > 0)
      return "fas fa-file-pdf";

    if (type.indexOf("word") > 0 || type.indexOf("wordprocessing") > 0)
      return "fas fa-file-word";

    if (type.indexOf("excel") > 0 || type.indexOf("spreadsheet") > 0)
      return "fas fa-file-excel";

    if (type.indexOf("zip") > 0 || type.indexOf("compressed") > 0)
      return "fas fa-file-archive";

    if ((<string>(doc.name)).endsWith(".csv"))
      return "fas fa-file-csv";

    if (type.indexOf("audio") > 0)
      return "fas fa-file-audio";

    return "fas fa-file-alt";
  }

  addNewTag(doc: VirtualDocument, event: MatChipInputEvent): void {
    // Add tag only when MatAutocomplete is not open
    // To make sure this does not conflict with OptionSelected Event
    if (!this.matAutocomplete.isOpen) {
      const input = event.input;
      const value = event.value;

      // Add our tag
      if ((value || '').trim()) {
        doc.tags = doc.tags || [];
        doc.tags.push(value.trim());
      }

      // Reset the input value
      if (input) {
        input.value = '';
      }

      this.tagInput.nativeElement.value = '';
      this.tagInputChanged();

      this.setTags(doc);
    }
  }

  removeTag(doc: VirtualDocument, t: string): void {
    doc.tags = doc.tags || [];
    const index = doc.tags.indexOf(t);

    if (index >= 0) {
      doc.tags.splice(index, 1);
    }

    this.setTags(doc);
  }

  addTag(doc: VirtualDocument, event: MatAutocompleteSelectedEvent): void {
    doc.tags = doc.tags || [];
    doc.tags.push(event.option.viewValue);
    this.tagInput.nativeElement.value = '';
    this.tagInputChanged();

    this.setTags(doc);
  }

  ignoreOpen(): void {
    event.stopImmediatePropagation();
    this.tagInputChanged();
  }

  tagInputChanged(): void {
    var searchFor = this.tagInput.nativeElement.value.toLowerCase();
    this.filteredTags = FileDisplayGridComponent.standardTags.filter((v, i, a) => v.toLowerCase().indexOf(searchFor) == 0);
  }

  private setTags(doc: VirtualDocument) {
    if (doc.documentId)
      this.docSvc.setTags(doc).subscribe(_ => void (0));
  }

  ngOnInit(): void {
  }
}
