import { Component, OnInit, Input } from '@angular/core';
import { InventoryItem } from '../../resources/inventory-item';


export type ItemType = 'PRODUCT' | 'MATERIAL' | 'PURCHASED' | 'PROCESS' | 'TOOLING' | 'PAINT';

@Component({
  selector: 'item-type-chip',
  templateUrl: './item-type-chip.component.html',
  styleUrls: ['./item-type-chip.component.less']
})
export class ItemTypeChipComponent implements OnInit {

  @Input('override') override: ItemType | null = null;
  @Input('disabled') disabled: boolean = false;
  @Input('chipClasses') chipClasses = '';

  constructor() { }

  ngOnInit() {
  }

  @Input() item: unknown & {
    stationId?: string,
    productId?: string,
    materialNeedId?: string,
    materialId?: string,
    purchasedItemId?: string,
    paintId?: string,
    outsideProcessDescriptionId?: string,
  };

  @Input() iconOnly = false;


  get itemType(): ItemType | 'BAD' {
    if (this.override) return this.override;
    if (!this.item) return 'BAD';
    if (this.item.stationId) return 'PROCESS';
    if (this.item.outsideProcessDescriptionId) return 'PROCESS';
    else if (this.item.productId) return 'PRODUCT';
    else if (this.item.materialNeedId) return 'MATERIAL';
    else if (this.item.materialId) return 'MATERIAL';
    else if (this.item.purchasedItemId) return 'PURCHASED';
    else if (this.item.paintId) return 'PAINT';
    else return 'BAD';
  }

  get itemTypeIcon(): string {
    if (this.itemType === 'TOOLING') return 'wrench';
    if (this.itemType === 'PRODUCT') return 'cog';
    else if (this.itemType === 'PROCESS') return 'logout-variant';
    else if (this.itemType === 'MATERIAL') return 'cube';
    else if (this.itemType === 'PURCHASED') return 'package-variant';
    else if (this.itemType === 'PAINT') return 'format-color-fill';
    else return '???';
  }

  get itemTypeName(): string {
    if (this.itemType === 'TOOLING') return 'Tooling';
    if (this.itemType === 'PRODUCT') return 'Part/Assembly';
    else if (this.itemType === 'PROCESS') return 'Process';
    else if (this.itemType === 'MATERIAL') return 'Raw Material';
    else if (this.itemType === 'PURCHASED') return 'Purchased/Hardware';
    else if (this.itemType === 'PAINT') return 'Paint';
    else return 'Bad Data!';
  }

}
