<div class="d-flex flex-column" style="height: 100%; position: relative;">
  <div [matMenuTriggerFor]="contextMenu" #menuTrigger="matMenuTrigger" style="position: fixed;" [style]="{
    left: menuPosition.x + 'px',
    top: menuPosition.y + 'px'
  }">
  </div>
  <mat-menu #contextMenu>
    <button mat-menu-item (click)="menuAddChild(menuItem)" *ngIf="menuItem || single">
      <mat-icon>add_box</mat-icon>
      <span *ngIf="menuItem">
        Add Child to <b>{{menuItem.name}}</b>
      </span>
      <span *ngIf="!menuItem && single">
        Add Child to <b>{{products[0].partNumber}} Rev. {{products[0].revision}}</b>
      </span>
    </button>
    <button mat-menu-item (click)="menuAddRoot()" *ngIf="!menuItem && !single">
      <mat-icon>add_box</mat-icon>
      <span>
        Add Part
      </span>
    </button>
    <button mat-menu-item *ngIf="menuItem && menuItem.parentId" (click)="menuDuplicateChild(menuItem)">
      <mat-icon>content_copy</mat-icon>
      Duplicate <b>{{menuItem.name}}</b>
    </button>
    <button mat-menu-item *ngIf="menuItem && menuItem.parentId" (click)="menuDelete(menuItem)">
      <mat-icon class="text-danger">delete</mat-icon>
      Delete <b>{{menuItem.name}}</b>
    </button>
  </mat-menu>
  <div class="d-flex align-items-center p-3 w-100 border border-bottom" *ngIf="single">
    <h3>Assembly</h3>
    <i class="ml-1 text-primary fas fa-spin fa-circle-notch"  *ngIf="loading"></i>
    <button mat-stroked-button class="ml-auto" [disabled]="loading" *ngIf="!sorting && editing" (click)="sorting = true">
      <mat-icon matPrefix>edit</mat-icon>
      Edit Hierarchy
    </button>
    <!-- todo: send an event -->
    <button mat-stroked-button class="ml-auto" [disabled]="loading" *ngIf="sorting" (click)="doneSorting()">
      <mat-icon matPrefix>check</mat-icon>
      Done Editing
    </button>
  </div>
  <div class="d-flex align-items-center p-3 w-100 border-bottom" *ngIf="!single">
    <div style="font-weight: 500; font-size: .95em">Associated Parts</div>
    <i class="ml-1 text-primary fas fa-spin fa-circle-notch"  *ngIf="loading"></i>
    <button mat-stroked-button class="ml-auto" [disabled]="loading" *ngIf="!sorting && editing" (click)="sorting = true">
      <mat-icon matPrefix>edit</mat-icon>
      Edit Hierarchy
    </button>
    <!-- todo: send an event -->
    <button mat-stroked-button class="ml-auto" [disabled]="loading" *ngIf="sorting" (click)="doneSorting()">
      <mat-icon matPrefix>check</mat-icon>
      Done Editing
    </button>
  </div>
  <div class="p-3 small text-muted font-italic w-100 text-center" style="pointer-events: none; position: absolute; top: 64px;" *ngIf="dataSource.data.length === 0 && editing && !loading">
    Right click to add parts...
  </div>
  <div class="p-3 small text-muted font-italic w-100 text-center" style="pointer-events: none; position: absolute; top: 64px;" *ngIf="dataSource.data.length === 0 && !editing && !loading">
    Order has no parts.
  </div>
  <mat-tree
    #tree
    *ngIf="!loading"
    style="
      height: 0;
      flex-grow: 1;
      overflow-y: scroll;
    "
    [dataSource]="dataSource"
    [treeControl]="treeControl"
    cdkDropList
    (cdkDropListSorted)="dragSorted($event)" 
    (cdkDropListDropped)="drop($event)"
    (contextmenu)="openMenu($event, null)"
  >
    <mat-tree-node
      *matTreeNodeDef="let node; when: isAdding"
      matTreeNodePadding
      (contextmenu)="$event.stopPropagation()"
      (focusout)="cancelAdding($event, node)"
      tabindex="0"
      id="adding-text-field-container"
    >
      <div class="px-1 w-100 d-flex align-items-center">
        <button mat-icon-button disabled *ngIf="!node.loading"></button>
        <button mat-icon-button disabled *ngIf="node.loading">
          <i class="fas fa-circle-notch fa-spin fa-fw text-primary mr-2" *ngIf="node.loading"></i>
        </button>
        <input class="adding-text-field" style="padding-left: 13px;" [(ngModel)]="addingItem.productData.partNumber" (keydown.enter)="saveAdding(node)" type="text" id="adding-text-field" *ngIf="!node.loading">
        <button mat-flat-button class="ml-2" (click)="saveAdding(node)" [disabled]="!addingItem.productData.partNumber" *ngIf="!node.loading">
          <mat-icon>save</mat-icon>
        </button>
        <span *ngIf="node.loading" class="text-muted">
          {{ addingItem.productData.partNumber }}
        </span>
      </div>
    </mat-tree-node>
    <mat-tree-node
      *matTreeNodeDef="let node"
      [class.node-selected]="selectedItemId === node.itemId" [class.node-clickable]="!sorting" (click)="!sorting && selectItem(node.itemId)"
      matRipple
      [matRippleDisabled]="sorting"
      matRippleColor="rgba(0,0,0,.04)" 
      [class.tree-hidden]="hiddenChildren.includes(node.itemId)"
      matTreeNodeToggle
      (click)="expansionModel.toggle(node.itemId)"
      cdkDrag
      [cdkDragData]="node"
      [cdkDragDisabled]="node.isTooling || (this.single && node.level === 0) || !sorting"
      (cdkDragStarted)="dragStartedWithChildren($event)"
      (cdkDragMoved)="dragMoved($event)"
      (cdkDragEnded)="dragEnd()"
      (contextmenu)="openMenu($event, node)"
      [ngStyle]="{ 'padding-left': ((node.level * 40) + 13) + 'px' }"
    >
      <div [ngStyle]="{ 'padding-left': ((node.level * 40) + 13) + 'px' }" *cdkDragPlaceholder>
        <div class="mat-tree-node rounded bg-primary"></div>
      </div>
      <!-- <div class="mat-tree-node" *cdkDragPreview>
        <span><ng-container *ngTemplateOutlet="nodeNameTemplate; context: { $implicit: node }">
              </ng-container></span>
      </div> -->
      <mat-icon class="text-muted drag-handle" cdkDragHandle [class.invisible]="!sorting || (single && node.level === 0)" *ngIf="!node.isTooling">
        drag_handle
      </mat-icon>
      <button mat-icon-button disabled *ngIf="!isNewParent(node.itemId)"></button>
      <button mat-icon-button disabled *ngIf="isNewParent(node.itemId)">
        <mat-icon class="mat-icon-rtl-mirror">
          expand_more
        </mat-icon>
      </button>
      <mat-icon class="text-muted" *ngIf="node.isTooling">
        construction
      </mat-icon>
      <ng-container *ngTemplateOutlet="nodeNameTemplate; context: { $implicit: node, expanded: false }">
              </ng-container>
    </mat-tree-node>
    <mat-tree-node
      *matTreeNodeDef="let node; when: hasChild"
      [class.node-selected]="selectedItemId === node.itemId"
      [class.node-clickable]="!sorting"
      (click)="!sorting && selectItem(node.itemId)"
      matRipple
      [matRippleDisabled]="sorting"
      matRippleColor="rgba(0,0,0,.04)"
      [class.tree-hidden]="hiddenChildren.includes(node.itemId)"
      cdkDrag
      [cdkDragDisabled]="(this.single && node.level === 0) || !sorting"
      (cdkDragMoved)="dragMoved($event)"
      (cdkDragEnded)="dragEnd()"
      (cdkDragStarted)="dragStartedWithChildren($event)"
      [cdkDragData]="node"
      (contextmenu)="openMenu($event, node)"
      [ngStyle]="{ 'padding-left': ((node.level * 40) + 13) + 'px' }"
    >
      <div [ngStyle]="{ 'padding-left': ((node.level * 40) + 13) + 'px' }" *cdkDragPlaceholder>
        <div class="mat-tree-node rounded bg-primary"></div>
      </div>
      <mat-icon class="text-muted drag-handle" cdkDragHandle [class.invisible]="!sorting || (this.single && node.level === 0)">
        drag_handle
      </mat-icon>
      <button mat-icon-button matTreeNodeToggle (click)="$event.stopPropagation(); expansionModel.toggle(node.productData.productId)"
        *ngIf="!isNewParent(node.itemId)"
        [attr.aria-label]="'toggle ' + node.filename">
        <mat-icon class="mat-icon-rtl-mirror">
          {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
        </mat-icon>
      </button>
      <button mat-icon-button disabled *ngIf="isNewParent(node.itemId)">
        <mat-icon class="mat-icon-rtl-mirror">
          expand_more
        </mat-icon>
      </button>
      <mat-icon class="text-muted" *ngIf="node.isTooling">
        construction
      </mat-icon>
      <ng-container *ngTemplateOutlet="nodeNameTemplate; context: { $implicit: node, expanded: treeControl.isExpanded(node) }">
              </ng-container>
    </mat-tree-node>
  </mat-tree>
</div>

<ng-template #nodeNameTemplate let-node let-expanded="expanded">
  <div class="d-flex align-items-center w-100 pr-2">
    <div>
      <div mat-line>
        {{ node.productData.partNumber + ' Rev. ' + node.productData.revision }} 
        <span class="badge ml-1 bg-dark text-white" *ngIf="!node.productData?.parentAssemblyId">{{ getMainQuantity(node.productData) }}<span *ngIf="node.productData.quantitiesMap.length > 1">+</span></span>
        <span class="badge ml-1 bg-dark text-white" *ngIf="node.productData?.parentAssemblyId || node.isTooling">{{ node.productData.quantityAsChild ?? 0 }}</span>
      </div>
      <div mat-line style="font-size: 0.75em;" class="text-muted" *ngIf="node.workOrder">
        WO #{{ node.workOrder.workOrderNumber }}
        <span class="badge ml-1 bg-dark text-white" *ngIf="node.isWorkOrder">{{ node.workOrder.purchasedItemQuantity ?? 0 }}</span>
      </div>
    </div>
    <div class="ml-auto d-flex flex-column align-items-end" *ngIf="node.workOrder">
      <div mat-line class="small text-muted d-flex justify-content-end align-items-center" *ngIf="node.workOrder.assignedTo">
        <mat-icon class="mr-1" style="height: 18px;
        font-size: 18px;
        width: 18px;">person</mat-icon>
        {{ node.workOrder.assignedTo.fullName }}
      </div>
      <div mat-line class="badge status text-uppercase small" [ngClass]="getStatusColorClass(node.workOrder.status)">
        {{getStatusText(node.workOrder.status)}}
      </div>
    </div>
    <ng-template *ngIf="postNameTemplate" [ngTemplateOutlet]="postNameTemplate" [ngTemplateOutletContext]="{ $implicit: node, expanded: expanded }"></ng-template>
  </div>
</ng-template>
