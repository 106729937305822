<button class="heading-menu" [matMenuTriggerFor]="types">Filter Ticket Types<i class="fas fa-fw fa-caret-right"></i></button>
<mat-menu #types="matMenu" class="p-3">
    <div class="location-label" (click)="$event.stopPropagation()">
        <mat-checkbox class="example-margin"
            [checked]="allTypesEnabled()"
            [indeterminate]="someTypesEnabled()"
            (change)="setAll($event.checked)">
            Select All
        </mat-checkbox>
    </div>
    <div class="location-label" (click)="$event.stopPropagation()" *ngFor="let type of filterTypes">
        <mat-checkbox
            [checked]="selection.includes(type.type)"
            (change)="change($event, type.type)">
            {{type.name}}
        </mat-checkbox>
    </div>
</mat-menu>