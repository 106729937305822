<div *ngIf="!editable; else canEdit">
  <span *ngIf="selectedUser; else notSet">{{selectedUser.fullName}}</span>
  <ng-template #notSet>
    <span class="font-italic text-secondary small">Not Set</span>
  </ng-template>
</div>

<ng-template #canEdit>
  <mat-select [(ngModel)]="selected" placeholder="Select User ..." (selectionChange)="selectUser($event)">
    <mat-select-trigger>
      {{selectedUser?.fullName || 'Select User ...'}}
    </mat-select-trigger>
    <mat-option *ngIf="!users">
      <p class="text-center text-muted">
        <i class="fas fa-circle-notch fa-spin fa-fw text-primary"></i>
        Loading...
      </p>
    </mat-option>
    <mat-option *ngIf="users && users.length==0">
      <p class="text-center text-muted">
        <i class="fas fa-exclamation-triangle fa-fw text-danger"></i>
        <small>{{emptyResultsMessage}}</small>
      </p>
    </mat-option>
    <mat-option [value]="u.userId" *ngFor="let u of users">
      <ng-template [ngTemplateOutlet]="userTemplate" [ngTemplateOutletContext]="{ $implicit: u }"></ng-template>
    </mat-option>
  </mat-select>
</ng-template>

<ng-template #userTemplate let-row>
  {{row.fullName}}
  <span class="float-right">
    <ng-template [ngTemplateOutlet]="itemTemplate" [ngTemplateOutletContext]="{ $implicit: row }"></ng-template>
  </span>
</ng-template>
