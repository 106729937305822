import { Component, Input, Output, OnInit, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { MaterialService } from '../../../order/services/material.service';
import { MaterialAlloy } from '../../../order/resources/material';

@Component({
  selector: 'material-alloy-select',
  templateUrl: './material-alloy-select.component.html',
  styleUrls: ['./material-alloy-select.component.less']
})
export class MaterialAlloySelectComponent implements OnInit {
  public materialAlloys: MaterialAlloy[];
  @Input() materialAlloy: MaterialAlloy;
  @Output() materialAlloyChange: EventEmitter<MaterialAlloy> = new EventEmitter<MaterialAlloy>();
  @Input() editable: boolean;
  @ViewChild('materialInputText', { static: true }) inputTxt: ElementRef;
  @ViewChild('autoCompleteTrigger', { static: true }) autoCompleteTrigger: MatAutocompleteTrigger;
  @Output() addAlloy: EventEmitter<string> = new EventEmitter<string>();

  constructor(private materialService: MaterialService) { }

  public getMaterialAlloyList(_event: KeyboardEvent) {
    this.materialService.getMaterialAlloys(this.inputTxt.nativeElement.value)
      .subscribe(result => {
        this.materialAlloys = result.results;
      });
  }

  public toggleDropdown(): void {
    event.stopImmediatePropagation();
    if (this.autoCompleteTrigger.panelOpen) {
      this.autoCompleteTrigger.closePanel();
    }
    else {
      this.getMaterialAlloyList(null);
      this.autoCompleteTrigger.openPanel();
    }
  }

  public onAddAlloy(): void {
    event.stopImmediatePropagation();
    this.addAlloy.emit(this.inputTxt.nativeElement.value);
    this.autoCompleteTrigger.closePanel();
  }

  public setAlloy(m: MaterialAlloy) {
    this.materialAlloy = m;
    if (m == null)
      return;

    this.materialAlloyChange.emit(m);
  }

  public testSelection(_: any): void {
    if (!this.materialAlloy) {
      this.inputTxt.nativeElement.value = "";
      return;
    }
    this.inputTxt.nativeElement.value = this.materialAlloy.name;
  }

  ngOnInit(): void {
  }
}
