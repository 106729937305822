import { AfterViewInit, Component, ElementRef, forwardRef, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ControlValueAccessor, NgModel, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatAutocomplete, MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { debounceTime, tap } from 'rxjs/operators';
import { UtilityService } from '../../../common/services/utility.service';
import { PurchasedItem } from '../../resources/purchased-item';
import { OrderService } from '../../services/order.service';

const PAGESIZE = 20;

@Component({
  selector: 'purchased-item-select',
  templateUrl: './purchased-item-select.component.html',
  styleUrls: ['./purchased-item-select.component.less'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => PurchasedItemSelectComponent),
      multi: true
    }
  ]
})
export class PurchasedItemSelectComponent implements OnInit, AfterViewInit, ControlValueAccessor {

  constructor(private service: OrderService, private dialog: MatDialog) { }

  ngOnInit() {
  }

  @ViewChild('newPurchasedItemDialog', { static: true }) newPurchasedItemDialog: TemplateRef<any>;
  @ViewChild('itemsAutocomplete', { static: true }) itemsAutocomplete: MatAutocomplete;
  @ViewChild('autoCompleteTrigger', { static: true }) autoCompleteTrigger: MatAutocompleteTrigger;
  @ViewChild('searchStringModel', { static: true }) searchStringModel: NgModel;
  @ViewChild('searchInput', { static: true }) searchInput: ElementRef<HTMLInputElement>;

  public searching = false;
  public currentPageIndex = 0;
  public items: PurchasedItem[] = [];

  public canScroll = true;

  public searchString = '';

  getOptionText(item: PurchasedItem) {
    return item && item.description;
  }


  public async onSearch() {
    // mat-autocomplete likes setting the ngmodel to the value object, so we skip if it does that...
    if (!!this.searchString && typeof this.searchString !== 'string') {
      if (!this.value) this.searchString = '';
      else return;
    }
    this.searching = true;
    const { results, resultCount } = await this.service.searchPurchasedItems(this.searchString || '', 0, PAGESIZE).toPromise();
    this.searching = false;
    this.items = results;
    this.currentPageIndex = 0;

    this.canScroll = this.items.length < resultCount;

  }

  public async onScrollBottom() {
    this.currentPageIndex += 1;
    this.searching = true;
    const { results, resultCount } = await this.service.searchPurchasedItems(this.searchString, this.currentPageIndex, PAGESIZE).toPromise();
    this.searching = false;

    this.items = this.items.concat(results);

    this.canScroll = this.items.length < resultCount;
  }

  public untouched = true;

  public onFocus() {
    if (this.untouched) {
      this.untouched = false;
      this.onSearch();
    };
  }


  private onPanelScroll(e: Event) {
    const panel = e.target as HTMLDivElement;
    panel.addEventListener('scroll', (e: Event) => {
      const maxScrollPosition = panel.scrollHeight - panel.clientHeight;
      const scrollThreshold = maxScrollPosition - 80;
      if (panel.scrollTop > scrollThreshold && !this.searching && this.canScroll) this.onScrollBottom();
    });
  }

  private currentPanel: ElementRef<HTMLDivElement> | null = null;

  public optionSelected(i: PurchasedItem) {
    this.writeValue(i);
  }

  public onSearchChange() {
    if (!this.value) {
      this.onSearch();
    } else {
      if (this.searchString !== this.value.description) {
        this.writeValue(null);
        this.onSearch();
      }
    }
  }

  savingNew = false;
  newItemDialogInstance: MatDialogRef<any>;

  public createNewPurchasedItem() {
    this.autoCompleteTrigger && this.autoCompleteTrigger.closePanel();
    this.newItemDialogInstance = this.dialog.open(this.newPurchasedItemDialog, {
      minWidth: 400,
      data: <PurchasedItem>{
        purchasedItemId: UtilityService.emptyGuid,
        description: this.searchString,
      } 
    });
  }

  public newItemCancel() {
    this.newItemDialogInstance && this.newItemDialogInstance.close();
  }

  public newItemSave(item: PurchasedItem) {
    this.savingNew = true;
    this.service.savePurchasedItem(item).subscribe(i => {
      this.newItemDialogInstance && this.newItemDialogInstance.close();
      this.writeValue(i);
      this.items = [];
      this.untouched = true;
      this.savingNew = false;
    });
  }

  value: PurchasedItem | null = null;

  onChange = (item: PurchasedItem | null) => {};
  onTouched = () => {};

  writeValue(item: PurchasedItem | null) {
    this.value = item;
    if (item)
      setTimeout(() => this.searchInput.nativeElement.value = (item && item.description) || '');
    if (!this.value) this.untouched = true;
    this.onChange(this.value);
  }

  registerOnChange(fn: (item: PurchasedItem | null) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  searchInputChange = new Subject<void>();

  ngAfterViewInit() {

    if (this.value) this.searchInput.nativeElement.value = this.value.description;

    this.searchInputChange.pipe(
      tap(() => this.writeValue(null)),
      tap(() => this.searching = true),
      debounceTime(300)
    ).subscribe(() => this.onSearch());

    this.itemsAutocomplete.opened.subscribe(x => {
      // wait until next tick or panel will be undefined
      setTimeout(() => {
        const newPanel = this.itemsAutocomplete.panel;
        if (!!newPanel && (this.currentPanel && this.currentPanel.nativeElement) !== newPanel.nativeElement) {
          if (this.currentPanel) this.currentPanel.nativeElement.removeEventListener('scroll', this.onPanelScroll.bind(this));
          this.currentPanel = newPanel;
          this.currentPanel.nativeElement.addEventListener('scroll', this.onPanelScroll.bind(this));
        }
      });
    });
  }

}
